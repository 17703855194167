import { BASE_URL, BASE_CORE_URL } from './../config/index'

//#region to get the alertlist,alertcount,todays alert list  
//With load balancing - Root - all alerts, admin - only children alerts, operator - only assigned alerts
export const getAlertList = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj
            //userUniqueId: userUniqueId
        ),
    };

    const url = BASE_URL + "getalertlist ";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("Get alert list Error", { error });
        });
}
//#endregion

//#region to get alerts details with that particular alertId
export const getAlertDetailsById = async (alertId) => {
    // Simple POST request with a JSON body using fetch
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            alertId: alertId,
            uniqueId: LoggedInUser.UserUniqueID || LoggedInUser.userUniqueID
        }),
    };

    const url = BASE_URL + "getalertdetailsbyalertid ";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("Get alert list Error", { error });
        });
}
//#endregion

//#region calling for attach alert evidences
export const attachalertevidences = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "attachalertevidences ";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("attachalertevidences Error", { error });
        });
}
//#endregion

//#region calling for attach refernence alerts
export const attachreferencealerts = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "attachreferencealerts";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("attachreferencealerts Error", { error });
        });
}
//#endregion

//#region calling for delete evidence
export const deletealertevidences = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "deletealertevidences";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("deletealertevidences Error", { error });
        });
}
//#endregion

//#region calling for create alert comment
export const createalertcomment = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "createalertcomment";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("createalertcomment Error", { error });
        });
}
//#endregion

//#region to get the alert activity logs
export const getalertactivitylogs = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "getalertactivitylogs";
    // return await fetch(url,reqoption).then((resp) => resp.json()).then(function (data){console.log(data)})
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("getalertactivitylogs Error", { error });
        });
}
//#endregion

// export const TwoWayCommunationRequest = async (obj) => {
//     // Simple POST request with a JSON body using fetch
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(obj),
//     };

//     const url = "" + "TwoWayCommunationRequest";
//     return await fetch(url, requestOptions)
//         .then((resp) => resp.json())
//         .then(function (data) {
//             return data;
//         })
//         .catch(function (error) {
//             console.error("TwoWayCommunationRequest Error", { error });
//         });
// }

//#region  this is old api of Hooter
export const HooterOnOffRequest = async (privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName) => {
    // const requestOptions = {
    //     method: "GET",
    //     mode: 'no-cors',
    //     headers: { "Content-Type": "application/json" },
    // };

    let url = null
    //Process name is diff according to panel brand e.g.: smartI, HikVision
    if (cctvTypeNameProps == null) {
        console.log("HooterClick", privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName);
        if (panelBrand) {
            console.log("HooterClick", privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName);
            if (panelBrand.trim().toLowerCase() === 'smarti') {
                // if (action == 'on') {
                //     url = "http://localhost:4530/"+processName+"/ActivateSmartiHooter?strIp=" + privateIpAddress+"&strPort="+port; 
                // }
                // else if (action == 'off') {
                //     url = "http://localhost:4530/"+processName+"/DeActivateSmartiHooter?strIp=" + privateIpAddress+"&strPort="+port; 
                // }  
                if (action == 'on') {
                    url = "http://localhost:4530/HikVisionCommandProcessService/ActivateSmartiHooter?strIp=" + privateIpAddress + "&strPort=5000";
                }
                else if (action == 'off') {
                    url = "http://localhost:4530/HikVisionCommandProcessService/DeActivateSmartiHooter?strIp=" + privateIpAddress + "&strPort=5000";
                }
            }
            else if (panelBrand.trim().toLowerCase() === 'hikvision') {

            }
            else if (panelBrand.trim().toLowerCase() === 'securico') {

            }
            else if (panelBrand.trim().toLowerCase() === 'vignaharta') {

            }
            else if (panelBrand.trim().toLowerCase() === 'rax') {

            }

        }
        console.log("HooterClick", privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName);
        // if (action == 'on') {
        //     // url = "http://localhost:4530/HikVisionCommandProcessService/ActivateSmartiHooter?strIp=115.242.36.169&strPort=5000";  //public ip
        //     url = "http://localhost:4530/HikVisionCommandProcessService/ActivateSmartiHooter?strIp=192.168.0.202&strPort=5000"; //private ip
        // }
        // else if (action == 'off') {
        //     // url="http://localhost:4530/HikVisionCommandProcessService/DeActivateSmartiHooter?strIp=115.242.36.169&strPort=5000"//public ip
        //     url="http://localhost:4530/HikVisionCommandProcessService/DeActivateSmartiHooter?strIp=192.168.0.202&strPort=5000" //private ip
        // } 
    } else if (cctvTypeNameProps == 'Camera') {
        if (action == 'on') {

            if (brandName == "CP Plus" || brandName == "dahua") {

                url = `http://${privateIpAddress}/cgi-bin/configManager.cgi?action=setConfig&AlarmOut[0].Mode=1&AlarmOut[0].Name=noname`
            }
        }
        else if (action == 'off') {

            url = `http://${privateIpAddress}/cgi-bin/configManager.cgi?action=setConfig&AlarmOut[0].Mode=0&AlarmOut[0].Name=noname`
        }
    }
    console.log("HooterClick", privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName);
    // if (url) {
    let obj = {
        "strBrandName": brandName,
        "strIp": privateIpAddress,
        "strPort": port,
        "strUserName": user,
        "strPassword": password,
        "strFunction": "hooter",
        "strCommand": action,
        "strParamOne": "",
        "strParamTwo": "",
        "strParamThree": "",
        "strParamFour": "",
        "strParamFive": ""
    }
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken'),
        },
        body: JSON.stringify(obj),
    };

    const urlData = BASE_CORE_URL + "ProcessCmdCameras/ProcessCmdCameras";
    return await fetch(urlData, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("ProcessCmdCameras Error", { error });
        });
    // }
}
//#endregion 

// #region - new Api of hooter 
// camera tile Hooter Button on-off Click Api calling
export const HooterOnOffClick = async (pDeviceId, pMode) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "ProcessCmdCameras/HooterControl?pDeviceId=" + pDeviceId + "&pMode=" + pMode;
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("HooterControl() Exception: ", error);
        });
}
//#endregion

//#region hooterPanelDetails(services)
export const GetHooterPanelDetails = async (pDeviceId) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "ProcessCmdCameras/GetPanelDetails?pDeviceId=" + pDeviceId;
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("GetPanelDetails() Exception: ", error);
        });
}
//#endregion

//#region Api Calling Hooter Services 
export const LocalHooterRequest = async (privateIpAddress, communicationPort, action, user, password, brandName, branchId) => {
    const requestOptions = {
        method: "GET",
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
    };

    let url = null
    if (brandName != 0) {
        if (brandName.includes(" ")) {
            brandName = brandName.replace(/\s/g, "")
        }
    }
    if (action == 'on') {

        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/hooterstart--${branchId}`

    }
    else if (action == 'off') {

        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/hooterstop--${branchId}`
    }

    if (url) {
        return await fetch(url, requestOptions)
            .then((resp) => {
                return resp.json();
            })
            .then(function (data) {
                return data;
            });

    }
}
//#endregion

// #region  - new Api of two - way 
// camera tile two-way Button on-off Click Api calling
export const TwoWayCommunicationRequest = async (privateIpAddress, communicationPort, action, user, password, brandName) => {
    const requestOptions = {
        method: "GET",
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
    };

    let url = null
    if (brandName != 0) {
        if (brandName.includes(" ")) {
            brandName = brandName.replace(/\s/g, "")
        }
    }
    //Process name is diff according to nvr/dvr brand e.g.: smartI, HikVision
    if (action == 'start') {
        // url = "http://localhost:4530/TwoWayCommandProcessService/StartTwoWay?strIp=" + privateIpAddress + "&strPort=" + port + "&strUser=" + user + "&strPass=" + password + "&strCMD=StartTwoWay";

        // http://localhost:3005/192.168.0.174/37777/admin/admin@123/cpplus/twowaystart
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/twowaystart`

    }
    else if (action == 'stop') {
        // url = "http://localhost:4530/TwoWayCommandProcessService/StopTwoWay"

        // http://localhost:3005/192.168.0.174/37777/admin/admin@123/cpplus/twowaystop
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/twowaystop`
    }
    /*if (brandName) {

        if (brandName.trim().toLowerCase() === 'smarti') {

        }
        else if (brandName.trim().toLowerCase() === 'hikvision') {
            if (action == 'start') {
                url = "http://localhost:4530/TwoWayCommandProcessService/StartTwoWay?strIp=" + privateIpAddress + "&strPort=" + 25001 + "&strUser=" + user + "&strPass=" + password + "&strCMD=StartTwoWay";
            }
            else if (action == 'stop') {
                url = "http://localhost:4530/TwoWayCommandProcessService/StopTwoWay"
            }
        }
        else if (brandName.trim().toLowerCase() === 'securico') {

        }
        else if (brandName.trim().toLowerCase() === 'vignaharta') {

        }
        else if (brandName.trim().toLowerCase() === 'rax') {

        }
      
    }*/

    // if (action == 'start') {
    //     url = "http://localhost:4530/HikVisionCommandProcessService/StartHikTwoWay?strIp=192.168.0.238&strPort=8000&strUser=admin&strPass=123456&strCMD=StartTwoWay"; 
    // }
    // else if (action == 'stop') {
    //     url="http://localhost:4530/HikVisionCommandProcessService/StopHikTwoWay"
    // } 

    if (url) {
        return await fetch(url, requestOptions)
            .then((resp) => {
                return resp.json();
            })
            .then(function (data) {
                return data;
            });

    }
}
// #endregion

// #region Camera tile Screenshot onclick Api Calling 
export const ScreenShot = async (privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName) => {
    // if (url) {
    let obj = {
        "strBrandName": brandName,
        "strIp": privateIpAddress,
        "strPort": port,
        "strUserName": user,
        "strPassword": password,
        "strFunction": "screenshot",
        "strCommand": action,
        "strParamOne": "",
        "strParamTwo": "",
        "strParamThree": "",
        "strParamFour": "",
        "strParamFive": ""
    }
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken'),
        },
        body: JSON.stringify(obj),
    };

    const urlData = BASE_CORE_URL + "ProcessCmdCameras/ProcessCmdCameras";
    return await fetch(urlData, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        // .then(async (resp) => {
        //     if (resp.ok) {
        //         const data = await resp.blob(); 
        //         return data;
        //     } else {
        //         throw new Error("Network response was not ok");
        //     }
        // })
        .catch(function (error) {
            console.error("ProcessCmdCameras Error", { error });
        });
    // }
}
// #endregion

//#region filter alert on basis of alert id
export const FilterAlert = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };
    const url = BASE_URL + "filteralerts ";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("FilterAlert Error", { error });
        });
}
//#endregion

//#region videoexport calling API
export const VideoExport = async (brandName, privateIpAddress, httpPort, user, password, startTime, endTime) => {
    //    // if (url) {
    let obj = {
        "strBrandName": brandName,
        "strIp": privateIpAddress,
        "strPort": "" + httpPort,
        "strUserName": user,
        "strPassword": password,
        "strFunction": "videoexport",
        "strCommand": "string",
        "strParamOne": startTime,
        "strParamTwo": endTime,
        "strParamThree": "",
        "strParamFour": "",
        "strParamFive": ""
    }
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken'),
        },
        body: JSON.stringify(obj),
    };
    const urlData = BASE_CORE_URL + "ProcessCmdCameras/ProcessCmdCameras";
    return await fetch(urlData, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("ProcessCmdCameras Error", { error });
        });
    // }
}
//#endregion

//#region alert creation in camera/mosaic page starts
export const alertCreation = async (deviceId, UserUniqueID, alertSeverity, alertCodeId, alertType, alertTime, alertSource, systemDeviceid, aleralertPlaybackTimeTime) => {

    let alertCreationObject = {
        "ParentCameraID": deviceId,
        "UserUniqueID": UserUniqueID,
        "AlertSeverity": alertSeverity,
        "alertCodeId": alertCodeId,
        "AlertType": alertType,
        "AlertTime": alertTime,
        "AlertSource": alertSource,
        "SystemDeviceID": systemDeviceid,
        "AlertPlaybackTime": aleralertPlaybackTimeTime,
    }

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken'),
        },
        body: JSON.stringify(alertCreationObject),
    }

    const urlData = BASE_URL + "createalert";

    return await fetch(urlData, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("Alert creation failed", { error });
        });
}
//#endregion

//#region API for getting prepost video details
export const getPrePostDetails = async (alertId) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "PrePost/GetFileForAlert?alertId=" + alertId;

    return await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
//#endregion

//#region API for Two way for calling local api 
export const localFileDownload = async (privateIpAddress, communicationPort, user, password, brandName, fileName, startTime, endTime, channelNo) => {
    const requestOptions = {
        method: "GET",
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
    };

    let hardCodeBrandName = "CPPlus";
    let hardCodeComPort = "25001"
    let hardCodeStartTime = "2024-04-03 11:00:06";
    let hardCodeEndTime = "2024-04-03 11:02:06"
    let hardCodeFileName = "hardCodeFileName.mp4"
    let hardCodeChannelNo = "3"

    // let url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/export/${fileName}/${hardCodeStartTime}/${hardCodeEndTime}/${channelNo}`
    // let url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/export/${fileName}/${startTime}/${endTime}/${channelNo}`

    let url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${encodeURIComponent(user)}/${encodeURIComponent(password)}/${encodeURIComponent(brandName)}/export/${fileName}/${startTime}/${endTime}/${channelNo}`

    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch(err => console.log(err))
}
//#endregion

//#region API to check if the file is downloaded or not

export const checkFileDownloaded = async (fileName) => {
    const requestOptions = {
        method: "GET",
        mode: 'cors',
        headers: { "Content-Type": "application/json" },
    };

    let url = `http://localhost:3005/CheckFile/${fileName}`

    return await fetch(url, requestOptions)
    .then((res) => {
        return res.json();
    }
    )
    .then((data) => {
        return data;
    })
    .catch(err => console.log(err))
}

//#end region

//#region API for calling panel arm
export const armPanelCommand = async (privateIpAddress, communicationPort, user, password, brandName, serialNo) => {
    const requestOptions = {
        method: "GET",
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
    };

    let url = null
    if (brandName != 0) {
        if (brandName.includes(" ")) {
            brandName = brandName.replace(/\s/g, "")
        }
    }
    if(serialNo == null){
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/Arm`
    }else{
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/Arm-${serialNo}`
    }

    if (url) {
        return await fetch(url, requestOptions)
            .then((resp) => {
                return resp.json();
            })
            .then(function (data) {
                return data;
            });

    }
}
//#endregion

//#region API for calling panel disarm
export const disarmPanelCommand = async (privateIpAddress, communicationPort, user, password, brandName, serialNo) => {
    const requestOptions = {
        method: "GET",
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
    };

    let url = null
    if (brandName != 0) {
        if (brandName.includes(" ")) {
            brandName = brandName.replace(/\s/g, "")
        }
    }
    if(serialNo == null){
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/DisArm`
    }else{
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/DisArm-${serialNo}`
    }

    if (url) {
        return await fetch(url, requestOptions)
            .then((resp) => {
                return resp.json();
            })
            .then(function (data) {
                return data;
            });

    }
}
//#endregion

//#region API for calling panel reset
export const resetPanelCommand = async (privateIpAddress, communicationPort, user, password, brandName, serialNo) => {
    const requestOptions = {
        method: "GET",
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
    };

    let url = null
    if (brandName != 0) {
        if (brandName.includes(" ")) {
            brandName = brandName.replace(/\s/g, "")
        }
    }
    if(serialNo == null){
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/Reset`
    }else{
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/Reset-${serialNo}`
    }
    if (url) {
        return await fetch(url, requestOptions)
            .then((resp) => {
                return resp.json();
            })
            .then(function (data) {
                return data;
            });

    }
}

//#endregion

//#region API for calling panel reset
export const activateOutputAPI = async (privateIpAddress, communicationPort, user, password, brandName, serialNo) => {
    const requestOptions = {
        method: "GET",
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
    };

    let url = null
    if (brandName != 0) {
        if (brandName.includes(" ")) {
            brandName = brandName.replace(/\s/g, "")
        }
    }
    if(serialNo == null){
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/hooterstart`
    }else{
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/hooterstart-${serialNo}`
    }
    if (url) {
        return await fetch(url, requestOptions)
            .then((resp) => {
                return resp.json();
            })
            .then(function (data) {
                return data;
            });

    }
}

//#endregion

//#region API for calling panel reset
export const deactivateOutputAPI = async (privateIpAddress, communicationPort, user, password, brandName, serialNo) => {
    const requestOptions = {
        method: "GET",
        mode: 'no-cors',
        headers: { "Content-Type": "application/json" },
    };

    let url = null
    if (brandName != 0) {
        if (brandName.includes(" ")) {
            brandName = brandName.replace(/\s/g, "")
        }
    }
    if(serialNo == null){
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/hooterstop`
    }else{
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/hooterstop-${serialNo}`
    }
    if (url) {
        return await fetch(url, requestOptions)
            .then((resp) => {
                return resp.json();
            })
            .then(function (data) {
                return data;
            });

    }
}

//#endregion


//#region API for calling panel reset
export const connectPanel = async (privateIpAddress, communicationPort, user, password, brandName) => {
    const requestOptions = {
        method: "GET",
        mode: 'cors',
        headers: { "Content-Type": "application/json" },
    };

    let url = null
    if (brandName != 0) {
        if (brandName.includes(" ")) {
            brandName = brandName.replace(/\s/g, "")
        }
    }
    url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/conn`
    if (url) {
        return await fetch(url, requestOptions)
            .then((resp) => {
                return resp.json();
            })
            .then(function (data) {
                return data;
            });
    }
}

//#endregion