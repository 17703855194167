import React from "react";
import { Link } from "react-router-dom";
import { ROOT } from "./CONSTANTS";

export const NotFound = () => {
  return (
    <>
        <div className="no-access-page">
          <h1><strong>4 0 4!</strong></h1>
          <h3>Page Not Found.</h3> <Link to={ROOT}>Home</Link>
        </div>
      </>
  );
};
