import React from 'react'

import { Doughnut } from 'react-chartjs-2';

const SensorStatus = ({ isDarkTheme, sensorStatus, sensorCount, bgcolour, sensorsum }) => {
    const data1 = {
        labels: sensorStatus,
        datasets: [{
            label: 'State Count',
            data: sensorCount,
            backgroundColor: bgcolour,
            borderColor: bgcolour,
        }]
    }

    const option1 = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            }
        },
        cutout: "75%",
    }

    const stackedText = {
        id: 'stackedText',
        afterDatasetsDraw(chart, args, options) {
            const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart;
            console.log(data.datasets[0].data[0]);
            ctx.save();
            const fontHeight = 30;
            const halfFontHeight = fontHeight / 2;

            ctx.font = ` ${halfFontHeight}px sans-serif`;
            ctx.fillStyle = isDarkTheme ? '' : '#BABABA';
            ctx.textAlign = 'center';
            ctx.fillText('Total', width / 2, height / 2 - 10);

            ctx.restore();

            ctx.font = `bolder ${fontHeight}px sans-serif`;
            ctx.fillStyle = isDarkTheme ? '' : '#BABABA';
            ctx.textAlign = 'center';
            var text = sensorsum;
            ctx.fillText(text, width / 2, height / 2 + 20);
        }
    }
    return (
        <Doughnut key={`${sensorsum}_${isDarkTheme}`} data={data1} plugins={[stackedText]} options={option1} />
    )
}

export default SensorStatus