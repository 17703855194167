import { createSlice } from "@reduxjs/toolkit";
import { addMinutes, subMinutes, differenceInMinutes } from "date-fns";

const initialState = {
  modalState: {
    showInfoFilterModal: false,
  },
  deviceListSearchText: "",
  devicesList: [],
  cameraDetails: null,
  cameraPlaybackList: [],
  visibleMultiPlaybackView: false,
  filterInfoName: "",
  infoState: {
    customerInfo: {
      master: [],
      child: [],
      customerSearch: "",
    },
    siteInfo: {
      master: [],
      child: [],
      siteSearch: "",
    },
    interfaceSubTypeInfo: {
      master: [],
      child: [],
      interfaceSubTypeSearch: "",
    },
    interfaceNameInfo: {
      master: [],
      child: [],
      interfaceNameSearch: "",
    },
  },
};

export const cameraFeatures = createSlice({
  name: "camera",
  initialState,
  reducers: {

    //#region Reseting the individual objects

    resetCamerStates() {
      return {
        ...initialState,
      };
    },
    resetDevicesList(state) {
      return {
        ...state,
        devicesList: initialState.devicesList,
      };
    },
    resetCameraDetails(state) {
      return {
        ...state,
        cameraDetails: initialState.cameraDetails,
      };
    },
    resetCameraPlaybackList(state) {
      return {
        ...state,
        cameraPlaybackList: initialState.cameraPlaybackList,
      };
    },

    //#endregion

    //#region Toggle modal visibility

    toggleInfoFilterModal(state, action) {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          showInfoFilterModal: action.payload,
        },
      };
    },

    //#endregion

    //set device list
    setDevicesList(state, action) {
      return {
        ...state,
        devicesList: action.payload,
      };
    },

    //set camera details
    setCameraDetails(state, action) {
      return {
        ...state,
        cameraDetails: action.payload,
      };
    },

    //Set device list search text for filtering device list data
    setDeviceListSearchText(state, action) {
      return {
        ...state,
        deviceListSearchText: action.payload,
      };
    },

    //Set camera playback list for sync replay
    setCameraPlaybackList(state, action) {
      return {
        ...state,
        cameraPlaybackList: action.payload,
      };
    },

    //toggle multi-playback view
    showMultiPlaybackView(state, action) {
      return {
        ...state,
        visibleMultiPlaybackView: action.payload,
      };
    },

    setCustomerMasterInfo(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          customerInfo: {
            ...state.infoState.customerInfo,
            master: action.payload,
          },
        },
      };
    },

    setCustomerChildInfo(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          customerInfo: {
            ...state.infoState.customerInfo,
            child: action.payload,
          },
        },
      };
    },

    setSiteMasterInfo(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          siteInfo: { ...state.infoState.siteInfo, master: action.payload },
        },
      };
    },
    setSiteChildInfo(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          siteInfo: { ...state.infoState.siteInfo, child: action.payload },
        },
      };
    },
    setInterfaceTypeMasterInfo(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          interfaceSubTypeInfo: {
            ...state.infoState.interfaceSubTypeInfo,
            master: action.payload,
          },
        },
      };
    },
    setInterfaceTypeChildInfo(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          interfaceSubTypeInfo: {
            ...state.infoState.interfaceSubTypeInfo,
            child: action.payload,
          },
        },
      };
    },
    setInterfaceNameMasterInfo(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          interfaceNameInfo: {
            ...state.infoState.interfaceNameInfo,
            master: action.payload,
          },
        },
      };
    },
    setInterfaceNameChildInfo(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          interfaceNameInfo: {
            ...state.infoState.interfaceNameInfo,
            child: action.payload,
          },
        },
      };
    },

    setCustomerSearch(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          customerInfo: {
            ...state.infoState.customerInfo,
            customerSearch: action.payload,
          },
        },
      };
    },
    setSiteSearch(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          siteInfo: { ...state.infoState.siteInfo, siteSearch: action.payload },
        },
      };
    },
    setInterfaceTypeSearch(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          interfaceSubTypeInfo: {
            ...state.infoState.interfaceSubTypeInfo,
            interfaceSubTypeSearch: action.payload,
          },
        },
      };
    },
    setInterfaceNameSearch(state, action) {
      return {
        ...state,
        infoState: {
          ...state.infoState,
          interfaceNameInfo: {
            ...state.infoState.interfaceNameInfo,
            interfaceNameSearch: action.payload,
          },
        },
      };
    },
    setFilterInfoName(state, action) {
      return {
        ...state,
        filterInfoName: action.payload,
      };
    },
  },
});

export const cameraActions = cameraFeatures.actions;
export default cameraFeatures.reducer;
