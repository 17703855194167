import React from 'react'

import { DeleteQc, EditQc } from '../../assets/images'

import { mixpanel } from '../../utils/MixPanelUtil'

export default function EventTemplateList({ template, onDeleteTemplate, history }) {
    
    const AddMixpanelEvent = (action, templatename) => {
        try {
            try {
                if (action == "Manage") {
                    mixpanel.track("Clicks on Quick Configuration " + action)
                } else {
                    mixpanel.track("Clicks on Quick Configuration " + action, { "AlertTemplateName": templatename })
                }
            } catch (error) {
                console.error(error)
            }
        } catch (error) {
            console.error(error)
        }
    }

    return <>
    <div className="location-list-item template-list-item pointerItem"
        onClick={() => history.push({
            pathname: '/configuration',
            state: {
                isAlertTemplate: true,
                params: template.AlertTemplateId
            }
        })}
    >
        <span className="template-name dashboard_configuration_templateName">{template.TemplateName}</span>
        <span className="qc-actions">
            <img src={EditQc} id={template.AlertTemplateId} alt="edit-qc" onClick={() => {
                AddMixpanelEvent("Edit", template.TemplateName)
            }} />
            <img src={DeleteQc} id={template.AlertTemplateId} onClick={(e) => {
                AddMixpanelEvent("Delete", template.TemplateName)
                onDeleteTemplate(template.AlertTemplateId)
                e.stopPropagation();
            }}
                alt="delete-qc" />
        </span>
    </div>
</>
}
