import { subMinutes } from "date-fns";

export function checkspecialcharacters(_title) {
  try {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(_title)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
}

export function ConvertDate(date) {
  var _smonth = date.getMonth() + 1;
  var sdates =
    date.getFullYear() +
    "-" +
    ("0" + _smonth).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2);
  var stime =
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2);
  var finalDate = sdates + " " + stime;
  return finalDate;
}

export function ConvertDateTwo(date) {
  var _smonth = date.getMonth() + 1;
  var sdates =
    date.getFullYear() +
    "_" +
    ("0" + _smonth).slice(-2) +
    "_" +
    ("0" + date.getDate()).slice(-2);
  var stime =
    ("0" + date.getHours()).slice(-2) +
    "_" +
    ("0" + date.getMinutes()).slice(-2) +
    "_" +
    ("0" + date.getSeconds()).slice(-2);
  var finalDate = sdates + "_" + stime;
  return finalDate.toString();
}

export function ConvertDateThree(date) {
  var sdate =
    ("0" + date.getDate()).slice(-2) +
    "_" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "_" +
    date.getFullYear();
  var stime =
    ("0" + date.getHours()).slice(-2) +
    "_" +
    ("0" + date.getMinutes()).slice(-2) +
    "_" +
    ("0" + date.getSeconds()).slice(-2);
  var formattedDate = sdate + "_" + stime;
  return formattedDate;
}

export function ConvertDateToUTC(date) {
  var _smonth = date.getUTCMonth() + 1;
  var sdates =
    date.getUTCFullYear() +
    "-" +
    ("0" + _smonth).slice(-2) +
    "-" +
    ("0" + date.getUTCDate()).slice(-2);
  var stime =
    ("0" + date.getUTCHours()).slice(-2) +
    ":" +
    ("0" + date.getUTCMinutes()).slice(-2) +
    ":" +
    ("0" + date.getUTCSeconds()).slice(-2);
  var finalDate = sdates + " " + stime;
  return finalDate;
}

export function parseTimeIntoDate(time) {
  let dt = time.split(":");
  let newDate = new Date();
  newDate.setHours(dt[0]);
  newDate.setMinutes(dt[1]);
  return newDate;
}

export function validateTime(fromTime, toTime) {
  if (fromTime.getTime() > toTime.getTime()) {
    return false;
  }
  return true;
}

export const to12HrFormat = (date) => {
  const str = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  let finalDT = `${date.toLocaleDateString()} ${str}`;
  return finalDT;
};

export const frontDateConverter = (d) => {
  let first = d.split(" ");
  let arr = first[0].split("-");
  let temp = `${arr[2]}-${arr[1]}-${arr[0]} ${first[1]}`;
  return temp;
};

export const onlyDateConverter = (d) => {
  return d.split("-")[2] + "-" + d.split("-")[1] + "-" + d.split("-")[0];
};

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}

export const isCapitalCase = (pass) => {
  const pattern = /[A-Z]/;
  return pattern.test(pass);
};

export const isSpecialCharacter = (pass) => {
  const pattern = /[\W_]/;
  return pattern.test(pass);
};

export const isNumberInclude = (pass) => {
  const pattern = /\d/;
  return pattern.test(pass);
};

export const isMinCharacter = (pass) => {
  return pass.length >= 8;
};

export const isMaxCharacter = (pass) => {
  return pass.length <= 15 && pass.length >= 1;
};

export const formatDatetoDay = (d) => {
  let daydummy = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let formatedate = onlyDateConverter(d);
  const f = new Date(formatedate);
  let daydate = f.getDay();
  return daydummy[daydate];
};

export function AllDateFormat(date) {
  var sdate =
    ("0" + date.getDate()).slice(-2) +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    date.getFullYear();
  var stime =
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2);
  var formattedDate = sdate + " " + stime;
  return formattedDate;
}

export function logOutMain() {
  localStorage.removeItem("authToken");
  localStorage.removeItem("authCode");
  localStorage.removeItem("user");
  localStorage.removeItem("userPassword");
  localStorage.removeItem("UserID");
  localStorage.removeItem("sessionExpired");
  localStorage.removeItem("hootWayData");
  localStorage.removeItem("theme");
  sessionStorage.removeItem("Page_Refresh_State");
  sessionStorage.removeItem("mosaicWindowKey");
  sessionStorage.removeItem("alertWindowKey");
  localStorage.removeItem("PlaySound");
}

export const multiTimeZoneHelper = (difference) => {
  if (difference > 0) {
    difference = -1 * difference;
  } else {
    difference = Math.abs(difference);
  }
  let current_date = new Date();
  let now_utc_date = new Date(current_date.toUTCString().slice(0, -4));
  let converted_date = subMinutes(now_utc_date, difference);
  return converted_date;
};

//Parse semi colon separated entities string into object
export const parseSCSEntitiesToObect = (input) => {
  const result = {};

  // Split the string by semicolons
  const pairs = input?.split(";");

  pairs.forEach((pair) => {
    // Split each pair by colon
    const [key, value] = pair?.split(":");

    // Store in the result object as strings
    result[key] = value !== undefined ? value : ""; // Assign empty string for undefined values
  });

  return result;
};

//Convert object to semi colon separated entities string
export const stringifyObjToSCSEntities = (input) => {
  // Convert the object to the desired string format
  let result = Object.entries(input)
    .map(([key, value]) => `${key}:${value}`)
    .join(";");

  return result;
};

//Check if an object or array is not null or empty
//Returns true if finds null or empty object, false otherwise
export const isNotNullOrEmpty = (obj) => {
  return (
    obj !== null &&
    typeof obj === "object" &&
    (Array.isArray(obj) ? obj.length > 0 : Object.keys(obj).length > 0)
  );
};

// get comma seperated Ids
export const getCSIds = (selectedData) => {
  if (selectedData?.length > 0) {
    let ids = selectedData?.map((item) => item.value)?.join(",");
    return ids;
  } else {
    return null;
  }
};

//get comma seperated devices Ids
export const getCSDeviceIds = (devicesList) => {
  if (devicesList?.length > 0) {
    let deviceIds = devicesList?.map((item) => item.DeviceId)?.join(",");
    return deviceIds;
  } else {
    return null;
  }
};

export const formatJSDateObject = (dateStr) => {
  // Given date string
  // const dateStr = "Fri Aug 30 2024 12:10:22 GMT+0530 (India Standard Time)";

  // Parse the date string into a JavaScript Date object
  const date = new Date(dateStr);

  // Function to format a number with leading zeroes if needed
  // const pad = (num) => num.toString().padStart(2, "0");

  // Adjust the date to a different time zone if needed (for example, convert to GMT or another time zone)
  // Assuming you want to convert the time to GMT/UTC
  // const utcDate = new Date(date.toUTCString());

  // Get individual components
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Format the date and time in the desired format
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  // console.log(formattedDate); // Output: "29-08-2024 20:11:00"

  return formattedDate;
};

export const formatJSDateInDDMMYYYY = (dateStr) => {
  // Given date string
  // const dateStr = "Fri Aug 30 2024 12:10:22 GMT+0530 (India Standard Time)";

  // Parse the date string into a JavaScript Date object
  const date = new Date(dateStr);

  // Function to format a number with leading zeroes if needed
  // const pad = (num) => num.toString().padStart(2, "0");

  // Adjust the date to a different time zone if needed (for example, convert to GMT or another time zone)
  // Assuming you want to convert the time to GMT/UTC
  // const utcDate = new Date(date.toUTCString());

  // Get individual components
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Format the date and time in the desired format
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

  // console.log(formattedDate); // Output: "29-08-2024 20:11:00"

  return formattedDate;
};

//Replace the time in date object by the time string passed as paramters
export const replaceTimeToDate = (date, timeString) => {
  // Split the timeString into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split("_").map(Number);

  let newDate = new Date(date);

  // Set the new time on the new Date object
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(seconds);
  newDate.setMilliseconds(0);

  return newDate;
};

export const subtractMinutes = (date, minutesToSubtract) => {
  // Create a new Date object to avoid mutating the original
  let newDate = new Date(date);

  // Subtract minutes
  newDate.setMinutes(newDate.getMinutes() - minutesToSubtract);

  return newDate;
};

export const addMinutesToDate = (date, minutesToAdd) => {
  // Create a new Date object to avoid mutating the original
  let newDate = new Date(date);

  // Subtract minutes
  newDate.setMinutes(newDate.getMinutes() + minutesToAdd);

  return newDate;
};

// Function to calculate the difference in minutes between two Date objects
export const getMinutesDifference = (date1, date2) => {
  // Calculate the difference in milliseconds
  const diffInMs = date2.getMinutes() - date1.getMinutes();

  console.log("diff minutes => ", diffInMs);
  // Convert milliseconds to minutes
  // const diffInMinutes = diffInMs.getMinutes();

  // return diffInMinutes;
};
