import React, { useEffect, useState } from 'react'
import { addMinutes, differenceInSeconds } from 'date-fns'
import { downloadVideo } from '../../services/downloadService';
import Swal from 'sweetalert2'

const AVAILABLE = "Available";
const EXPIRED = "Expired";
const INPROGRESS = "In progress";
const ERROR = "ERROR";

const DownloadItem = ({ requestId, requestDate, status, expiryDate, site, device, fromTime, toTime }) => {
    const [expiryTime, setExpiryTime] = useState("");
    const [statusItem, setStatusItem] = useState(status)
    const [isItExpired, setIsItExpired] = useState(false);

    useEffect(() => {
        // if (status == AVAILABLE) {
        if (status == 1) {
            setStatusItem(AVAILABLE)
            // } else if (status == EXPIRED) {
        } else if (status == 2) {
            setStatusItem(ERROR)
        } else {
            setStatusItem(INPROGRESS)
        }
    }, [status])

    useEffect(() => {
        if (!isItExpired) {
            let str = expiryDate.replaceAll("-", "_").replaceAll(":", "_")
            let arr = str.split(" ")[0] + "_" + str.split(" ")[1];
            let tempArr = arr.split("_")
            let expDate = new Date(tempArr[2], tempArr[1] - 1, tempArr[0], tempArr[3], tempArr[4], tempArr[5]);
            expDate = addMinutes(expDate, 60);
            let timer = setInterval(() => {
                let currentDate = new Date();
                let diffInSeconds = differenceInSeconds(expDate, currentDate);
                let d = new Date(Math.abs(diffInSeconds) * 1000).toISOString().substring(11, 19)
                if (diffInSeconds > 0) {
                    setExpiryTime(d);
                }
                else {
                    setExpiryTime(EXPIRED)
                    setStatusItem(EXPIRED)
                    setIsItExpired(true);
                    return () => {
                        clearInterval(timer);
                    }
                }
            }, 1000)

            return () => {
                clearInterval(timer);
            }
        }
    }, [expiryDate, isItExpired]);

    const downloadFile = () => {
        debugger
        downloadVideo(requestId)
            .then(async (res) => {
                try {
                    if (res.Status == "success") {
                        const base64Response = await fetch(`data:video/mp4;base64,${res.Data}`);
                        const blob = await base64Response.blob();

                        var link = window.document.createElement("a");
                        // link.href = URL.createObjectURL(blob, { type: "video/mp4" });
                        link.href = URL.createObjectURL(blob);
                        link.download = requestId + ".mp4";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        Swal.fire({ text: res.Message })
                    }
                } catch (error) {
                    console.log(error)
                }
            }).catch(err => {
                console.log(err)
            })
    }

    return (
        <tr>
            <td>{requestId}</td>
            <td>{requestDate}</td>
            <td className={
                statusItem == AVAILABLE ? "Low ReportTableAlertSeverity"
                    : statusItem == EXPIRED ? "expired ReportTableAlertSeverity"
                        : "inprogress ReportTableAlertSeverity"}>
                {statusItem}
            </td>

            <td>{site}</td>
            <td>{device}</td>
            <td>{fromTime}</td>
            <td>{toTime}</td>

            {/* <td className='ReportTableAlertSeverity hrsname'>{expiryTime}</td> */}
            <td>{expiryTime}</td>

            <td style={{ width: "10%" }}>
                {
                    statusItem == AVAILABLE
                        ? <a className="" onClick={() => downloadFile(requestId)}>Download</a>
                        : statusItem == INPROGRESS
                        ? <a className={expiryTime == EXPIRED || statusItem == INPROGRESS ? "linkdisabled" : ""}>In Progress</a>
                        : statusItem == ERROR
                        ? <a className="linkdisabled">ERROR</a>
                        : <a className={expiryTime == EXPIRED || statusItem == INPROGRESS ? "linkdisabled" : ""}>Expired</a>
                }

            </td>
        </tr>
    )
}

export default DownloadItem