import React from 'react'
import { MultiSelect } from 'react-multi-select-component'
 
const FilterToolDDLTemp = ({ visibleSavedfilter, savedFilterProps, filterList }) => {
 
    const valueRenderer = (selected, _options, filterProps) => (
        <>
            {selected?.length ? (
                <div style={{ width: "100%", display: "flex" }}>
                    {filterProps?.labelledBy}: {selected[0].label}{""}
                    {selected.length > 1 &&
                        <span className="filterSelectedCount ml-2">{`+ ${selected.length - 1}`}</span>
                    }
                </div>
            ) : (
                <div>{filterProps?.labelledBy}</div>
            )}
        </>
    )
 
    return (
        <>
            {visibleSavedfilter && <><MultiSelect {...savedFilterProps} valueRenderer={savedFilterProps?.valueRenderer !== null ? savedFilterProps?.valueRenderer : (selected, _options) => valueRenderer(selected, _options, savedFilterProps)} /><h4>OR</h4></>}
            {filterList?.length > 0 && filterList?.map(filterProps => (<><MultiSelect {...filterProps} valueRenderer={filterProps?.valueRenderer !== null ? filterProps?.valueRenderer : (selected, _options) => valueRenderer(selected, _options, filterProps)} /></>))}
        </>
    )
}
 
export default FilterToolDDLTemp;