import React, { useEffect, useState } from 'react'

import { Row, Col, OverlayTrigger } from "react-bootstrap";
import { DeleteQc, EditQc } from '../../../../assets/images';
import AddCameraDeviceForm from './AddCameraDeviceForm';
import CameraBulkUpload from '../Common/CameraBulkUpload';
import AddPanelDeviceForm from './AddPanelDeviceForm';
import PanelBulkUpload from '../Common/PanelBulkUpload';
import ConfigurePanelZones from '../Common/ConfigurePanelZones';
import AssociateCameraOnSensor from '../Common/AssociateCameraOnSensor';


const AddDevices = ({
    cameradeviceslist,
    paneldeviceslist,
    sites,
    selectedDevice,
    OnSaveDevice,
    onDeviceSelection,
    onAddNewDevice,
    isDeviceEdit,
    setIsDeviceEdit,
    devicesmasterlist,
    OnAddIDSPanel,
    onSensorSelection,
    associatedDevices,
    getdeviceslistdata,
    onAssociate,
    selectedSensor,
    associateCamSecVisibility,
    setBulkDevToUpload,
    bulkDevToUpload,
    OnAddBulkDevicesClick,
    selectedHeaders,
    onPanelBulkUpload,
    setSelectedHeaders,
    bulkUpload,
    setBulkUpload,
    isSingleOrBulkSelection,
    setIsSingleOrBulkSelection,
    checkForAlertsAndDelDevice,
    selectedZone,
    setselectedZone,
    clearImportedPanelHeaders,
    selectedCamHeaders,
    setSelectedCamHeaders,
    clearImportedCamHeaders,
    deviceBulkUploadResponse,
    setImportedFileData,
    panelBulkUploadResponse,
    setPanelBulkUploadResponse,
    panelBulkUploadError,
    setPanelBulkUploadError,
    setDeviceBulkUploadResponse,
    selectedItems,
    setSelectedItems,
    isSubmitting,
    importedFileHeaders,
    setImportedFileHeaders,
    importedPanelHeaders,
    setImportedPanelHeaders,
  }) => {
    const [devicetype, setdevicetype] = useState(true);
  
    const [refreshDeviceForm, setRefreshDeviceForm] = useState(false);
  
    //#region bulk upload props
    const hiddenFileInput = React.useRef(null);
  
    //#endregion bulk upload props
  
    const handleSingleUploadClick = () => {
      setBulkUpload(false);
      setIsSingleOrBulkSelection("single_device");
    };
    //#region bulk upload methods
    const handleBulkUploadClick = (e) => {
      setBulkUpload(true);
      setBulkDevToUpload([]);
      setIsSingleOrBulkSelection("bulk_upload");
    };
  
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };
  
    useEffect(() => {
      setRefreshDeviceForm(true);
    }, [selectedDevice]);
  
    return (
      <div className="LeftAddSide">
        <div className="LeftAddSideHeight">
          <div className="AddDevices1">
            <div>
              <Row>
                <Col lg={7} className="headerContainer-1">
                  <h1 className="MainHeaderName mb-0">1. Manage Devices</h1>
                </Col>
                <Col lg={5} className="headerContainer-2">
                  <div style={{ float: "right", padding: "8px" }}>
                    <div
                      className={
                        devicetype == true
                          ? "active-all-light active-block active-text"
                          : "inactive-text"
                      }
                      onClick={() => {
                        onAddNewDevice();
                        setdevicetype(true);
                      }}
                    >
                      NVR / DVR
                    </div>
                    <div
                      className={
                        devicetype == false
                          ? "active-all-light active-block active-text"
                          : "inactive-text"
                      }
                      onClick={() => {
                        onAddNewDevice();
                        setdevicetype(false);
                      }}
                    >
                      Panel
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <div className="DevicesListScroll">
                <div className="DevicesHeader">
                  <p className="mb-0" style={{ width: "40%" }}>
                    Devices
                    <br />
                  </p>
                  <div className="bulkDevices1">
                    <div className="HeaderSiteList mb-0">
                      <span
                        className={
                          isSingleOrBulkSelection == "single_device"
                            ? "active-all-light active-block active-text button-common-margin"
                            : "inactive-text button-common-margin"
                        }
                        onClick={() => handleSingleUploadClick()}
                      >
                        Single Device
                      </span>
                      <span
                        className={
                          isSingleOrBulkSelection == "bulk_upload"
                            ? "active-all-light active-block active-text"
                            : "inactive-text"
                        }
                        onClick={() => handleBulkUploadClick()}
                      >
                        Bulk Upload
                      </span>
                    </div>
                  </div>
                </div>
                {devicetype && (
                  <>
                    <div className="site-group">
                      <div className="DeviceDivScroll">
                        {cameradeviceslist &&
                          cameradeviceslist.map((item) => {
                            return (
                              <div
                                id={item.ParentCameraID}
                                key={item.ParentCameraID}
                                className={`cameraList user-list-item ${selectedDevice &&
                                  item.ParentCameraID ==
                                  selectedDevice?.ParentCameraID &&
                                  "selected-user"
                                  }`}
                                onClick={(e) => onDeviceSelection(item)}
                              >
                                <p className="mb-0">{item.CameraName}jj</p>
                                <div style={{ float: "right" }}>
                                  <img
                                    onClick={(e) => onDeviceSelection(item)}
                                    src={EditQc}
                                    className="m-1"
                                    alt="edit icon"
                                  />
                                  <img
                                    className="m-1"
                                    onClick={() => checkForAlertsAndDelDevice(item)}
                                    src={DeleteQc}
                                    alt="delete icon"
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="AddMore" style={{ float: "left" }}>
                        <a
                          className="UserAddPlus mt-1"
                          onClick={() => onAddNewDevice()}
                        >
                          Add New NVR/DVR +
                        </a>
                      </div>
                    </div>
                    {!bulkUpload && (
                      <AddCameraDeviceForm
                        onSubmit={OnSaveDevice}
                        sites={sites}
                        selectedDevice={selectedDevice}
                        isDeviceEdit={isDeviceEdit}
                        setIsDeviceEdit={setIsDeviceEdit}
                        devicesmasterlist={devicesmasterlist}
                        isSubmitting={isSubmitting}
                      />
                    )}
                    {bulkUpload && (
                      <>
                        <CameraBulkUpload
                          selectedCamHeaders={selectedCamHeaders}
                          OnAddBulkDevicesClick={OnAddBulkDevicesClick}
                          setSelectedCamHeaders={setSelectedCamHeaders}
                          clearImportedCamHeaders={clearImportedCamHeaders}
                          deviceBulkUploadResponse={deviceBulkUploadResponse}
                          setImportedFileData={setImportedFileData}
                          setDeviceBulkUploadResponse={setDeviceBulkUploadResponse}
                          importedFileHeaders={importedFileHeaders}
                          setImportedFileHeaders={setImportedFileHeaders}
                        />
                      </>
                    )}
                  </>
                )}
                {!devicetype && (
                  <>
                    <div className="site-group">
                      <div className="DeviceDivScroll DeviceDivScroll-Panel">
                        {paneldeviceslist &&
                          paneldeviceslist.map((item) => {
                            return (
                              <div
                                id={item.ParentCameraID}
                                key={item.ParentCameraID}
                                className={`cameraList user-list-item ${selectedDevice &&
                                  item.ParentCameraID ==
                                  selectedDevice?.ParentCameraID &&
                                  "selected-user"
                                  }`}
                                onClick={(e) => onDeviceSelection(item)}
                              >
                                <p className="mb-0">{item.CameraName}</p>
                                <div style={{ float: "right" }}>
                                  <img
                                    style={{ paddingRight: "10px" }}
                                    onClick={(e) => onDeviceSelection(item)}
                                    src={EditQc}
                                    alt="edit icon"
                                    className="m-1"
                                  />
                                  <img
                                    src={DeleteQc}
                                    alt="delete icon"
                                    onClick={() =>
                                      OnAddIDSPanel(
                                        null,
                                        item.ParentCameraID,
                                        "deletePanel"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="AddMore" style={{ float: "left" }}>
                        <a
                          className="UserAddPlus mt-1"
                          onClick={() => onAddNewDevice()}
                        >
                          Add New Panel +
                        </a>
                      </div>
                    </div>
                    {!bulkUpload && (
                      <AddPanelDeviceForm
                        onSubmit={OnSaveDevice}
                        sites={sites}
                        selectedDevice={selectedDevice}
                        isDeviceEdit={isDeviceEdit}
                        devicesmasterlist={devicesmasterlist}
                        OnAddIDSPanel={OnAddIDSPanel}
                      />
                    )}
                    {bulkUpload && (
                      <>
                        <PanelBulkUpload
                          selectedHeaders={selectedHeaders}
                          onPanelBulkUpload={onPanelBulkUpload}
                          setSelectedHeaders={setSelectedHeaders}
                          clearImportedPanelHeaders={clearImportedPanelHeaders}
                          setImportedFileData={setImportedFileData}
                          panelBulkUploadResponse={panelBulkUploadResponse}
                          setPanelBulkUploadResponse={setPanelBulkUploadResponse}
                          panelBulkUploadError={panelBulkUploadError}
                          setPanelBulkUploadError={setPanelBulkUploadError}
                          setBulkUpload={setBulkUpload}
                          setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                          importedPanelHeaders={importedPanelHeaders}
                          setImportedPanelHeaders={setImportedPanelHeaders}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="ButtonDiv">
              <button className="AlertViewButton  mr-3" hidden>
                <p>Previous</p>
              </button>
            </div>
          </div>
  
          {!devicetype && associateCamSecVisibility && (
            <ConfigurePanelZones
              selectedDevice={selectedDevice}
              devicesmasterlist={devicesmasterlist}
              OnAddIDSPanel={OnAddIDSPanel}
              setselectedZone={setselectedZone}
              selectedZone={selectedZone}
            />
          )}
          {!devicetype && associateCamSecVisibility && (
            <AssociateCameraOnSensor
              selectedDevice={selectedDevice}
              onSensorSelection={onSensorSelection}
              associatedDevices={associatedDevices}
              onAssociate={onAssociate}
              selectedSensor={selectedSensor}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          )}
        </div>
      </div>
    );
  };

export default AddDevices