import { BASE_CORE_URL } from "../config";

//#region Api Calling for Add DownloadRequest 
export const addDownload = async (obj) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
        body: JSON.stringify(obj)
    }
    let url = BASE_CORE_URL + "DownloadRequest/Add";

    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log("addDownload", data);
            return data;
        }).catch(err => console.log(err))
}
//#endregion

//#region Api Calling for get DownloadList 
export const getDownloadList = async (pageno) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "DownloadRequest/Get?userId=" + localStorage.getItem('UserID') + "&pageNumber=" + pageno;

    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log("getDownloadList", data);
            return data;
        }).catch(err => console.log(err))
}
//#endregion

//#region Api Calling for DownloadRequest Download video
export const downloadVideo = async (id) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "DownloadRequest/DownloadVideo?RequestId=" + id;

    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log("downloadVideo", data);
            return data;
        }).catch(err => console.log(err))
}
//#endregion

//#region Api Calling for getDownloadRequest Details
export const getDownloadDetails = async (userId, pageNumber, searchText) => {
    const requestOptions = {
        method: "GET",
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
    }

    let url = BASE_CORE_URL + `DownloadRequest/Get?userId=${userId}&pageNumber=${pageNumber}&searchText=${searchText}`
    console.log("getDownloadDetails CallAPI", url);
    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log("Get getDownloadDetails", data);
            return data;
        })
        .catch(err => console.log(err));
}
//#endregion