import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterName: "",
  savedFilters: {
    options: [],
    selected: null,
  },
  customer: {
    options: [],
    selected: [],
  },
  site: {
    options: [],
    selected: [],
  },
  interfaceSubType: {
    options: [],
    selected: [],
  },
  interface: {
    options: [],
    selected: [],
  },
};

const filterFeatures = createSlice({
  name: "filter",
  initialState,
  reducers: {
    //#region Set options to the individual objects
    setSavedFiltersList(state, action) {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          options: action.payload,
        },
      };
    },
    setCustomer(state, action) {
      return {
        ...state,
        customer: {
          ...state.customer,
          options: action.payload,
        },
      };
    },
    setSite(state, action) {
      return {
        ...state,
        site: {
          ...state.site,
          options: action.payload,
        },
      };
    },
    setInterfaceSubType(state, action) {
      return {
        ...state,
        interfaceSubType: {
          ...state.interfaceSubType,
          options: action.payload,
        },
      };
    },
    setInterface(state, action) {
      return {
        ...state,
        interface: {
          ...state.interface,
          options: action.payload,
        },
      };
    },
    //#endregion

    //#region Set selected options to individual objects
    setSavedFilterSelected(state, action) {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          selected: action.payload,
        },
      };
    },
    setCustomerSelected(state, action) {
      return {
        ...state,
        customer: {
          ...state.customer,
          selected: action.payload,
        },
      };
    },
    setSiteSelected(state, action) {
      return {
        ...state,
        site: {
          ...state.site,
          selected: action.payload,
        },
      };
    },
    setInterfaceSubTypeSelected(state, action) {
      return {
        ...state,
        interfaceSubType: {
          ...state.interfaceSubType,
          selected: action.payload,
        },
      };
    },
    setInterfaceSelected(state, action) {
      return {
        ...state,
        interface: {
          ...state.interface,
          selected: action.payload,
        },
      };
    },

    //#endregion

    //#region Reseting the individual objects
    resetSavedFilter(state) {
      return {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          options: initialState.savedFilters.options,
          selected: initialState.savedFilters.selected,
        },
      };
    },
    resetCustomer(state) {
      return {
        ...state,
        customer: {
          ...state.customer,
          options: initialState.customer.options,
          selected: initialState.customer.selected,
        },
      };
    },
    resetSite(state) {
      return {
        ...state,
        site: {
          ...state.site,
          options: initialState.site.options,
          selected: initialState.site.selected,
        },
      };
    },
    resetInterfaceSubType(state) {
      return {
        ...state,
        interfaceSubType: {
          ...state.interfaceSubType,
          options: initialState.interfaceSubType.options,
          selected: initialState.interfaceSubType.selected,
        },
      };
    },
    resetInterface(state) {
      return {
        ...state,
        interface: {
          ...state.interface,
          options: initialState.interface.options,
          selected: initialState.interface.selected,
        },
      };
    },
    resetFilterName(state) {
      return {
        ...state,
        filterName: initialState.filterName,
      };
    },
    //#endregion

    //set filter name for saving filter
    setFilterName(state, action) {
      return {
        ...state,
        filterName: action.payload,
      };
    },
    resetFilterStates() {
      return {
        ...initialState,
      };
    },
  },
});

export const filterActions = filterFeatures.actions;
export default filterFeatures.reducer;
