import React from 'react'
import { DeviceGrayIcon, InfoBlue, InfoBlueWhite } from '../../assets/images'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const HMOfflineDev = ({ isDarkTheme, data, tooltip }) => {

  return (
    <div className="StatusText1">
      <div className="StatusValueDiv">
        <p className="statusValueP">
          <span>{data?.Count.slice(0, -3)}</span>
          <OverlayTrigger placement="bottom" overlay={tooltip}>
            <span className="InfoImg">
              <button style={{ background: "transparent" }}>
                {
                  isDarkTheme ?
                    <img alt="InfoBlue" src={InfoBlue} /> :
                    <img alt="InfoWhite" src={InfoBlueWhite} height="17" />
                }
              </button>
            </span>
          </OverlayTrigger>
        </p>
        <div className="mb-1">
          <div className="OfflineDeviceDiv">
            <img alt="Device" src={DeviceGrayIcon} />
            <p className="offlineDeviceText">{data?.DeviceName}</p>
          </div>
        </div>
      </div>
    </div>)
}

export default HMOfflineDev