import React, { useEffect, useState, useImperativeHandle } from 'react'
import { useForm } from 'react-hook-form';

import Swal from "sweetalert2";

const AddCameraDeviceForm = React.forwardRef((props, ref) => {
    let {
        onSubmit,
        sites,
        selectedDevice,
        devicesmasterlist,
        isSubmitting,
    } = { ...props };

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        watch
    } = useForm({ defaultValue: { ...selectedDevice } });
    const [selectedBrand, setSelectedBrand] = useState('');
    const [channelNumberPlaceholder, setChannelNumberPlaceholder] = useState('Channel Number* e.g. 1');

    useEffect(() => {
        if (selectedDevice && selectedDevice != null) {
            setValue("SiteID", selectedDevice.SiteID) ||
                setValue("cameraName", selectedDevice.CameraName) ||
                setValue("privateIPAddress", selectedDevice.privateIPAddress) ||
                setValue("communicationPort", selectedDevice.communicationPort) ||
                setValue("httpPort", selectedDevice.httpPort) ||
                setValue("userName", selectedDevice.camUserName) ||
                setValue("password", selectedDevice.camPassword) ||
                setValue("port", selectedDevice.camPort) ||
                setValue("channelNumber", selectedDevice.channelNum) ||
                // setValue("playbackChannelNumber", selectedDevice.playbackChannelNum) ||
                setValue("liveStreamTypeNumber", selectedDevice.SubTypeLive)
            setValue("playbackStreamTypeNumber", selectedDevice.SubTypePlayback
            ) ||
                setValue("rTSPURL", selectedDevice.RTSPURL && selectedDevice.RTSPURL.replace("%40", "@")) ||
                setValue("location", selectedDevice.Location) ||
                setValue("ParentCameraID", selectedDevice.ParentCameraID) ||
                setValue("CameraBrandId", selectedDevice.cameraBrandId)

            if (selectedDevice.isTranscoded == "1") {
                setValue("isTranscoded", 1);
            } else {
                setValue("isTranscoded", 0);
            }

            if (selectedDevice.isPlaybackTranscoded == "1") {
                setValue("isPlaybackTranscoded", 1);
            } else {
                setValue("isPlaybackTranscoded", 0);
            }

            if (selectedDevice.isTwoWayEnabled == "1") {
                setValue("isTwoWayEnabledDevice", 1);
            } else {
                setValue("isTwoWayEnabledDevice", 0);
            }
            // setValue("isTranscoded", selectedDevice.isTranscoded);
        }
    }, [selectedDevice]);

    useEffect(() => {
        if (watch("CameraBrandId") && watch("privateIPAddress") && watch("userName") && watch("password") && watch("port") && watch("channelNumber")) {
            let temp = "";
            if (getValues("CameraBrandId") == 2) {
                temp =
                    "rtsp://" +
                    getValues("userName") +
                    ":" +
                    getValues("password") +
                    "@" +
                    getValues("privateIPAddress") +
                    ":" +
                    getValues("port") +
                    "/Streaming/Channels/" +
                    getValues("channelNumber");
            } else if (getValues("CameraBrandId") == 1 || getValues("CameraBrandId") == 4) {
                temp =
                    "rtsp://" +
                    getValues("userName") +
                    ":" +
                    getValues("password") +
                    "@" +
                    getValues("privateIPAddress") +
                    ":" +
                    getValues("port") +
                    "/cam/realmonitor?channel=" +
                    getValues("channelNumber") +
                    "&subtype=1"; //+getValues("channelNumber")
            } else if (getValues("CameraBrandId") == 3) {
                temp =
                    "rtsp://" +
                    getValues("userName") +
                    ":" +
                    getValues("password") +
                    "@" +
                    getValues("privateIPAddress") +
                    ":" +
                    getValues("port") +
                    "/avstream/channel=" +
                    getValues("channelNumber") +
                    "/stream=0.sdp";
            }
            setValue("rTSPURL", temp)
        } else {
            setValue("rTSPURL", "")
        }
    }, [watch("CameraBrandId"), watch("privateIPAddress"), watch("userName"), watch("password"), watch("port"), watch("channelNumber")]);

    useImperativeHandle(ref, () => ({
        isDataChanged() {
            Swal.fire({ text: getValues("SiteID") });
            return (
                getValues("SiteID") != selectedDevice.SiteID ||
                getValues("cameraName") != selectedDevice.CameraName ||
                getValues("privateIPAddress") != selectedDevice.privateIPAddress ||
                getValues("communicationPort") != selectedDevice.communicationPort ||
                getValues("httpPort") != selectedDevice.httpPort ||
                getValues("userName") != selectedDevice.userName ||
                getValues("password") != selectedDevice.password ||
                getValues("port") != selectedDevice.port ||
                getValues("channelNumber") != selectedDevice.channelNum ||
                // getValues("playbackChannelNumber") != selectedDevice.playbackChannelNum ||
                getValues("liveStreamTypeNumber") != selectedDevice.SubTypeLive ||
                getValues("playbackStreamTypeNumber") != selectedDevice.SubTypePlayback ||
                getValues("rTSPURL") != selectedDevice.RTSPURL ||
                getValues("location") != selectedDevice.Location ||
                getValues("ParentCameraID") != selectedDevice.ParentCameraID ||
                getValues("CameraBrandId") != selectedDevice.cameraBrandId ||
                getValues("isTranscoded") != selectedDevice.isTranscoded ||
                getValues("isPlaybackTranscoded") != selectedDevice.isPlaybackTranscoded ||
                getValues("isTwoWayEnabledDevice") != selectedDevice.isTwoWayEnabled

            );
        },
    }));

    function resetrtspurl(e) {
        try {
            if (getValues("CameraBrandId") == 2) {
                e.value =
                    "rtsp://" +
                    getValues("userName") +
                    ":" +
                    // getValues("password").replace("@", "%40") +
                    getValues("password") +
                    "@" +
                    getValues("privateIPAddress") +
                    ":" +
                    getValues("port") +
                    "/Streaming/Channels/" +
                    getValues("channelNumber");
            } else if (getValues("CameraBrandId") == 1 || getValues("CameraBrandId") == 4) {
                e.value =
                    "rtsp://" +
                    getValues("userName") +
                    ":" +
                    // getValues("password").replace("@", "%40") +
                    getValues("password") +
                    "@" +
                    getValues("privateIPAddress") +
                    ":" +
                    getValues("port") +
                    "/cam/realmonitor?channel=" +
                    getValues("channelNumber") +
                    "&subtype=1";
                //+getValues("channelNumber")
            } else if (getValues("CameraBrandId") == 3) {
                e.value =
                    "rtsp://" +
                    getValues("userName") +
                    ":" +
                    // getValues("password").replace("@", "%40") +
                    getValues("password") +
                    "@" +
                    getValues("privateIPAddress") +
                    ":" +
                    getValues("port") +
                    "/avstream/channel=" +
                    getValues("channelNumber") +
                    "/stream=0.sdp";
            } else {
                e.placeholder = "Select brand name first";
            }
        } catch (error) {
            console.error(error);
        }
    }
    const handleOptionChange = (option) => {
        setSelectedBrand(option);
        // Determine the placeholder for the next input based on the selected option
        if (+option === 1) {
            setChannelNumberPlaceholder("Channel Number* e.g. 101");
        } else if (+option === 2) {
            setChannelNumberPlaceholder("Channel Number* e.g. 1");
        } else {
            setChannelNumberPlaceholder("Channel Number* e.g. 1");
        }
    };

    const onNumberOnlyChange = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isValid = new RegExp("[0-9]").test(keyValue);
        if (!isValid) {
            event.preventDefault();
            return;
        }
    };

    return (
        <div className="SiteList">
            <div>
                <form
                    onSubmit={handleSubmit((e) =>
                        onSubmit(e, selectedDevice.ParentCameraID)
                    )}
                >
                    <div className="DeviceTextBox cam-device-form">
                        <select
                            {...register("CameraBrandId")}
                            className="SiteNameTextBox"
                            placeholder="Select Brand Name"
                            defaultValue={selectedDevice.cameraBrandId}
                            onChange={(e) => {
                                setValue("CameraBrandId", e.target.value);
                                handleOptionChange(e.target.value)
                            }}
                        >
                            <option key="0" disabled={false} value="">Select Brand Name*</option>

                            {devicesmasterlist &&
                                devicesmasterlist.cameraTypeMasterList &&
                                devicesmasterlist.cameraTypeMasterList.map((val) => (
                                    <option value={val.brandId} key={val.brandId}>
                                        {val.brandName}
                                    </option>
                                ))}
                        </select>
                        <input
                            {...register("privateIPAddress")}
                            className="SiteNameTextBox"
                            placeholder="Private IP Address* e.g. 192.168.0.54"
                            defaultValue={selectedDevice.privateIPAddress}
                        />
                        <input
                            {...register("communicationPort")}
                            className="SiteNameTextBox "
                            placeholder="Communication Port* e.g. 8080"
                            // pattern="[0-9]*"
                            // inputMode="numeric"  
                            onKeyPress={onNumberOnlyChange}
                            defaultValue={selectedDevice.communicationPort}
                        />
                        <input
                            {...register("httpPort")}
                            className="SiteNameTextBox "
                            placeholder="Http Port* e.g. 443"
                            // pattern="[0-9]*"
                            // inputMode="numeric"  
                            onKeyPress={onNumberOnlyChange}
                            defaultValue={selectedDevice.httpPort}
                        />

                        <input
                            {...register("userName")}
                            className="SiteNameTextBox"
                            placeholder="User Name* e.g. Admin"
                            defaultValue={selectedDevice.camUserName}
                        />
                        <input
                            {...register("password")}
                            className="SiteNameTextBox"
                            placeholder="Password* e.g. Abc@123"
                            defaultValue={selectedDevice.camPassword}
                        />
                        <input
                            {...register("port")}
                            className="SiteNameTextBox"
                            placeholder="RTSP Port* e.g. 554"
                            // pattern="[0-9]*"
                            // inputMode="numeric"
                            onKeyPress={onNumberOnlyChange}
                            defaultValue={selectedDevice.camPort}
                        />
                        <input
                            {...register("channelNumber")}
                            className="SiteNameTextBox"
                            // placeholder="Channel Number* e.g. 1"
                            placeholder={channelNumberPlaceholder}
                            defaultValue={selectedDevice.channelNum}
                        />
                        <input
                            {...register("rTSPURL")}
                            className="SiteNameTextBox"
                            onClick={(e) => resetrtspurl(e.target)}
                            disabled={false}
                            placeholder="RTSP URL* e.g. rtsp://Admin:Abc@123@192.168.0.58:554/Streaming/Channels/1"
                            defaultValue={selectedDevice.RTSPURL}
                        />
                        <input
                            {...register("cameraName")}
                            className="SiteNameTextBox"
                            placeholder="Device Name* e.g. Lobby Camera"
                            defaultValue={selectedDevice.CameraName}
                        />
                        <select
                            {...register("SiteID")}
                            className="SiteNameTextBox"
                            placeholder="Select Branch*"
                            defaultValue={selectedDevice.SiteID}
                            onChange={(e) => setValue("SiteID", e.target.value)}
                        >
                            <option key="0" disabled={false} value="0">Select Branch*</option>
                            {sites &&
                                sites.map((site) => (
                                    <option value={site.SiteID} key={site.SiteID}>
                                        {site.SiteName}
                                    </option>
                                ))}
                        </select>
                        <div className="SiteNameText onlyforEnableCheckbox">
                            <input
                                className="checkbox_custom"
                                type="checkbox"
                                {...register("isTranscoded")}
                                onChange={(e) => {
                                    setValue("isTranscoded", Number(e.target.checked))

                                }}
                                defaultValue={Number(selectedDevice.isTranscoded)}
                            />
                            <p className="mb-0">
                                Enable Transcoding to H.264 <sup>(Optional)</sup>
                            </p>
                        </div>
                    </div>
                    <div className="ButtonDiv">
                        {selectedDevice.ParentCameraID == 0 ? (
                            <button type="submit" className="NextMoveButton addUpdateCamera-btn manageDevices-addCamera-btn"
                                disabled={isSubmitting} >
                                <span className="ViewUpdate">Add Camera</span>
                            </button>
                        ) : (
                            <button type="submit" className="NextMoveButton addUpdateCamera-btn manageDevices-updateCamera-btn">
                                <span className="ViewUpdate">Update Camera</span>
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
});

export default AddCameraDeviceForm