import React, { useEffect, useState } from "react";
import { DatePicker, DateRangePicker } from "rsuite";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import { differenceInDays, subDays, subMonths } from "date-fns";
import Skeleton from "react-loading-skeleton";

import { SearchIcon, SearchIconWhite } from "../../assets/images";

import { ConvertDate, multiTimeZoneHelper } from "../../utils/utils";
import { MULTI_TIME_ZONE } from "../../config";
import FilterTool from "./FilterTool";
import {
  getAlertActivityReport,
  getAlertExportStatus,
} from "../../services/reportService";
import ActivityReportTable from "./ActivityReportTable";
import { useDataAccessPagination } from "../../CustomHooks/useDataAccessPagination";
import { Pagination } from "react-bootstrap";

const TODAY = "TD";
const YESTERDAY = "YD";
const SEVENDAY = "7D";
const ONEMONTH = "1M";
const THREEMONTH = "3M";
const NONE = "NONE";

export default function AlertsActivityReport(props) {
  // useState Start
  const [alertData, setAlertData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [activeTab, setActiveTab] = useState(TODAY);
  const [alertCount, setAlertCount] = useState(0);
  const [initialAlertDate, setinitialAlertDate] = useState([]);
  const [inputDate, setInputDate] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [displayStartDate, setDisplayStartDate] = useState("");
  const [displayEndDate, setDisplayEndDate] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [siteIds, setSiteIds] = useState("");
  const [customerIds, setCustomerIds] = useState("");
  const [resetClicked, setResetClicked] = useState(false);
  const [show, setShow] = useState(false);
  const [totalDevices, setTotalDevices] = useState(0);
  const [typingStart, setTypingStart] = useState(false);
  const [clientSearch, setClientSearch] = useState("");
  const [alertIdSearch, setAlertIdSearch] = useState("");
  const [siteNameSearch, setSiteNameSearch] = useState("");
  const [deviceNameSearch, setDeviceNameSearch] = useState("");
  const [deviceTypeSearch, setDeviceTypeSearch] = useState("");
  const [alertTypeSearch, setAlertTypeSearch] = useState("");
  const [severitySearch, setSeveritySearch] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [timeZoneSearch, setTimeZoneSearch] = useState("");

  const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);

  const getAlertFirstDate = () => {
    let d;
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (MULTI_TIME_ZONE) {
      d = multiTimeZoneHelper(loggedInUser.OffsetMinutes);
    } else {
      d = new Date();
    }
    return d;
  };

  const getAlertSecondDate = () => {
    let e;
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (MULTI_TIME_ZONE) {
      e = multiTimeZoneHelper(loggedInUser.OffsetMinutes);
    } else {
      e = new Date();
    }
    return e;
  };

  // useEffect Start
  useEffect(() => {
    if (resetClicked) {
      let loggedInUser = JSON.parse(localStorage.getItem("user"));
      getAlertData(loggedInUser.UserUniqueID, fromDate, endDate, 1, searchString, customerIds, siteIds);
      setCurrentPageNo(1)
      setResetClicked(false);
    }
  }, [resetClicked]);

  useEffect(() => {
    if (siteIds != "" || customerIds != "") {
      let loggedInUser = JSON.parse(localStorage.getItem("user"));
      getAlertData(loggedInUser.UserUniqueID, fromDate, endDate, 1, searchString, customerIds, siteIds);
      setCurrentPageNo(1)
    }
  }, [siteIds, customerIds]);

  useEffect(() => {
    if (fromDate === "" || endDate === "") {
      let loggedInUser = JSON.parse(localStorage.getItem("user"));
      let start = getAlertFirstDate();
      let end = getAlertSecondDate();
      let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
      setFromDate(startDate);
      let endCurrDate = ConvertDate(end);
      setEndDate(endCurrDate);
      displayAlertDateSet(startDate, endCurrDate);
      getAlertData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString, customerIds, siteIds);
      setAbbreviation(loggedInUser.Abbreviation);
    } else {
      let loggedInUser = JSON.parse(localStorage.getItem("user"));
      getAlertData(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo, searchString, customerIds, siteIds);
      setAbbreviation(loggedInUser.Abbreviation);
    }
    let tempDate = getAlertFirstDate();
    let c = alertCount + 1;
    setAlertCount(c);
    setinitialAlertDate([subMonths(tempDate, 1), tempDate]);
  }, [currentPageNo]);

  const displayAlertDateSet = (startDate, endCurrDate) => {
    let one = startDate.split(" ");
    let tempOne = `${one[0].split("-")[2]}-${one[0].split("-")[1]}-${one[0].split("-")[0]
      }`;
    setDisplayStartDate(`${tempOne} ${one[1]}`);
    let two = endCurrDate.split(" ");
    let tempTwo = `${two[0].split("-")[2]}-${two[0].split("-")[1]}-${two[0].split("-")[0]
      }`;
    setDisplayEndDate(`${tempTwo} ${two[1]}`);
  };

  const getAlertData = (uniqueId, startDate, endDate, pageNumber, searchString, customerIds, siteIds) => {
    try {
      setTableLoading(true);
      getAlertActivityReport(uniqueId, startDate, endDate, pageNumber, searchString, customerIds, siteIds)
        .then((res) => {
          console.log("getAlertActivityReport", res);
          if (res.Status == "success") {
            setAlertData(res.Data.AlertReportModels);
            setTotalPages(parseInt(res.Data.Total));
            setTotalDevices(parseInt(res.Data.Total));
            setTableLoading(false);
          } else {
            setAlertData([]);
            setTotalPages(0);
            setTotalDevices(0);
            setTableLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setTableLoading(false);
        });
    } catch (error) { }
  };

  const today = () => {
    let start = getAlertFirstDate();
    let end = getAlertSecondDate();
    let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate);
    setInputDate([]);
    displayAlertDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    getAlertData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1);
    setActiveTab(TODAY);
  };

  const yesterday = () => {
    let start = getAlertFirstDate();
    let end = getAlertSecondDate();
    let startDate = ConvertDate(
      subDays(new Date(start.setHours(0, 0, 0, 0)), 1)
    );
    setFromDate(startDate);
    let endCurrDate = ConvertDate(
      subDays(new Date(end.setHours(24, 0, 0, 0)), 1)
    );
    setEndDate(endCurrDate);
    setInputDate([]);
    displayAlertDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    getAlertData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1);
    setActiveTab(YESTERDAY);
  };

  const sevenDay = () => {
    let start = getAlertFirstDate();
    let end = getAlertSecondDate();
    let startDate = ConvertDate(
      subDays(new Date(start.setHours(0, 0, 0, 0)), 6)
    );
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate);
    setInputDate([]);
    displayAlertDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    getAlertData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1);
    setActiveTab(SEVENDAY);
  };

  const oneMonth = () => {
    let start = getAlertFirstDate();
    let end = getAlertSecondDate();
    let startDate = ConvertDate(
      subMonths(new Date(start.setHours(0, 0, 0, 0)), 1)
    );
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate);
    setInputDate([]);
    displayAlertDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    getAlertData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1);
    setActiveTab(ONEMONTH);
  };

  const onHandleAlertDateTimeOkClick = (e) => {
    const [startDate, endDate] = [e[0], e[1]];
    if (startDate && endDate) {
      const daysDifference = differenceInDays(endDate, startDate);
      if (daysDifference > 30) {
        ErrorToast("Maximum Date range cannot exceeded 30 days.");
        return;
      }
    }
    let sDate = ConvertDate(e[0]);
    setFromDate(sDate);
    let eDate = ConvertDate(e[1]);
    setEndDate(eDate);
    setInputDate([e[0], e[1]])
    displayAlertDateSet(sDate, eDate);
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    getAlertData(loggedInUser.UserUniqueID, sDate, eDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1)
    setActiveTab(NONE);
  };

  useEffect(() => {
    if (clientSearch.length > 0) {
      setTypingStart(true);
    }
    if (clientSearch === "" && typingStart) {
      searchListDetails();
    }
  }, [clientSearch]);

  useEffect(() => {
    if (alertIdSearch.length > 0) {
      setTypingStart(true);
    }
    if (alertIdSearch === "" && typingStart) {
      searchListDetails();
    }
  }, [alertIdSearch]);

  useEffect(() => {
    if (siteNameSearch.length > 0) {
      setTypingStart(true);
    }
    if (siteNameSearch === "" && typingStart) {
      searchListDetails();
    }
  }, [siteNameSearch]);

  useEffect(() => {
    if (deviceNameSearch.length > 0) {
      setTypingStart(true);
    }
    if (deviceNameSearch === "" && typingStart) {
      searchListDetails();
    }
  }, [deviceNameSearch]);

  useEffect(() => {
    if (deviceTypeSearch.length > 0) {
      setTypingStart(true);
    }
    if (deviceTypeSearch === "" && typingStart) {
      searchListDetails();
    }
  }, [deviceTypeSearch]);

  useEffect(() => {
    if (alertTypeSearch.length > 0) {
      setTypingStart(true);
    }
    if (alertTypeSearch === "" && typingStart) {
      searchListDetails();
    }
  }, [alertTypeSearch]);

  useEffect(() => {
    if (severitySearch.length > 0) {
      setTypingStart(true);
    }
    if (severitySearch === "" && typingStart) {
      searchListDetails();
    }
  }, [severitySearch]);

  useEffect(() => {
    if (operatorName.length > 0) {
      setTypingStart(true);
    }
    if (operatorName === "" && typingStart) {
      searchListDetails();
    }
  }, [operatorName]);

  useEffect(() => {
    if (timeZoneSearch.length > 0) {
      setTypingStart(true);
    }
    if (timeZoneSearch === "" && typingStart) {
      searchListDetails();
    }
  }, [timeZoneSearch]);

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ",";

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  };

  const exportAlertHandler = () => {
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    getAlertExportStatus(loggedInUser.UserUniqueID, fromDate, endDate, 1, searchString, customerIds, siteIds).then((res) => {
      console.log("getAlertExportStatus", res);
      if (res.Status == "success") {
        let stemp = res.Data;
        if (res.Data == 0 || res.Data == null) {
          Swal.fire({ text: "No records available to export." });
          return;
        }
        let header = {
          ClientName: "Customer Name",
          AlertId: "Alert ID",
          SiteName: "Site Name",
          DeviceName: "Device Name",
          DeviceType: "Device Type",
          AlertType: "Alert Type",
          Severity: "Severity",
          ActucalTime: "Generated at",
          AckOn: "Acknowledge at",
          OperatorName: "Operator",
          ClosedOn: "Alert Closed at",
          TimeToCloseAlert: "Resolution time",
          Abbreviation: "Timezone",
        };
        stemp.unshift(header);
        stemp = stemp.map((item, i) => {
          delete item["TimeZoneId"];
          delete item["OffsetMinutes"];
          delete item["ActucalTimeUTC"];
          delete item["TimeToCloseAlertUTC"];
          delete item["AckOnUTC"];
          delete item["ClosedOnUTC"];
          return item;
        });
        console.log("Alertstemp", stemp);
        let jsonObject = JSON.stringify(stemp);
        let csv = convertToCSV(jsonObject);
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let link = document.createElement("a");
        let fileN = "Alerts Activity Report.csv";
        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileN);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }else{
        Swal.fire({ text: "No records available to export." });
        return;
      }
    });
  };

  const getSearchAlertDataReports = (searchText) => {
    try {
      setTableLoading(true);
      let loggedInUser = JSON.parse(localStorage.getItem("user"));
      getAlertActivityReport(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo, searchText, customerIds, siteIds)
        .then((res) => {
          console.log("getAlertActivityReport", res);
          if (res.Status == "success") {
            setAlertData(res.Data.AlertReportModels);
            setTotalPages(parseInt(res.Data.Total));
            setTotalDevices(parseInt(res.Data.Total));
            setCurrentPageNo(1);
            setPageCounter(1);
            setTableLoading(false);
          } else {
            setAlertData([]);
            setTotalPages(0);
            setTotalDevices(0);
            setCurrentPageNo(1);
            setPageCounter(1);
            setTableLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };

  const searchListDetails = (data, type) => {
    let obj = {
      "customername": clientSearch,
      "alertid": alertIdSearch,
      "sitename": siteNameSearch,
      "devicename": deviceNameSearch,
      "devicetype": deviceTypeSearch,
      "alerttype": alertTypeSearch,
      "severity": severitySearch,
      "operatorname": operatorName,
      "timezoneabbreviation": timeZoneSearch,
    };
    let stringText = JSON.stringify(obj);
    let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";");
    setSearchString(tempStringText);
    getSearchAlertDataReports(tempStringText);
  };

  const PaginationComponent = (currentPageNo) => {
    let numbers = [];
    if (
      maxPageAllowed - 2 != 0 &&
      (currentPageNo === maxPageAllowed ||
        currentPageNo === maxPageAllowed - 1 ||
        currentPageNo === maxPageAllowed - 2)
    ) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 2}
          active={maxPageAllowed - 2 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
        >
          {maxPageAllowed - 2}
        </Pagination.Item>
      );
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>
      );
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed}
          active={maxPageAllowed == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed)}
        >
          {maxPageAllowed}
        </Pagination.Item>
      );
    } else if (
      maxPageAllowed - 1 != 0 &&
      (currentPageNo === maxPageAllowed || currentPageNo === maxPageAllowed - 1)
    ) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>
      );
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed}
          active={maxPageAllowed == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed)}
        >
          {maxPageAllowed}
        </Pagination.Item>
      );
    } else {
      for (let i = currentPageNo; i < currentPageNo + 3; i++) {
        if (i <= maxPageAllowed) {
          numbers.push(
            <Pagination.Item
              key={i}
              active={i == currentPageNo}
              onClick={() => setCurrentPageNo(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
      }
    }
    return numbers;
  };

  return (
    <div className="MainContentMargin">
      <div className="col-lg-12 HMHeader mt-3 mb-3">
        <div className="NameHeading1" style={{ width: "100%" }}>
          <p className="config-tool-head" style={{ float: "left" }}>
            Reports &gt; {`Alert Report`}
          </p>
          <div
            style={{ display: "flex", alignItems: "flex-start", float: "right", }}
          >
            {/* <CustomerTool /> */}
            <FilterTool isDarkTheme={props.isDarkTheme} setSiteIds={setSiteIds} setCustomerIds={setCustomerIds} setResetClicked={setResetClicked}
            />
            <div
              style={{ display: "grid", gridAutoFlow: "column", gridGap: "10px", alignItems: "center", }}
            >
              <button onClick={today} type="button" className={`${activeTab == TODAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>TD</button>
              <button onClick={yesterday} type="button" className={`${activeTab == YESTERDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>YD</button>
              <button onClick={sevenDay} type="button" className={`${activeTab == SEVENDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>7D</button>
              <button onClick={oneMonth} type="button" className={`${activeTab == ONEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>30D</button>
              {/* <button onClick={threeMonth} type="button" className={`${activeTab == THREEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>90D</button> */}
              <DateRangePicker
                key={alertCount}
                className="mr-1"
                format="dd-MM-yyyy HH:mm:ss"
                cleanable={false}
                editable={false}
                defaultCalendarValue={initialAlertDate}
                value={inputDate}
                ranges={[]}
                placeholder="Select date range"
                placement="bottomEnd"
                onOk={(e) => {
                  onHandleAlertDateTimeOkClick(e);
                }}
                disabledDate={(current) => {
                  let d;
                  if (MULTI_TIME_ZONE) {
                    let loggedInUser = JSON.parse(localStorage.getItem("user"));
                    d = multiTimeZoneHelper(loggedInUser.OffsetMinutes);
                  } else {
                    d = new Date();
                  }
                  return current && current > d;
                }}
              />
              <button type="button" onClick={exportAlertHandler} class="btn btn-outline-primary"> Export</button>
            </div>
          </div>
        </div>
      </div>

      <div className="TabulerReportMainDiv">
        <div className="DeviceTableHeader">
          <span className="HeaderSpanHR">Alert Report</span>
          <span className="mr-4">
            <span className="FromDateFontSize">From : </span>
            {displayStartDate}
            <span className="ml-3 FromDateFontSize">To: </span>
            {displayEndDate}
          </span>
        </div>

        <div className="card-body p-0">
          <div className="table-wrapper">
            <table className="table mb-2" id="event-activity-report">
              <thead className="ReportTableHeader ReportTableHeadReport">
                <tr>
                  <th>
                    <p>Alert ID (s)</p>
                    <div className="SearchTextDiv StatusSearchText">
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        value={alertIdSearch}
                        onChange={(e) => setAlertIdSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th>
                    <p>Customer Name (s)</p>
                    <div className="SearchTextDiv StatusSearchText">
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        value={clientSearch}
                        onChange={(e) => setClientSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th>
                    <p>Site Name (s)</p>
                    <div className="SearchTextDiv StatusSearchText">
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        value={siteNameSearch}
                        onChange={(e) => setSiteNameSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th>
                    <p>Device Name (s)</p>
                    <div className="SearchTextDiv StatusSearchText">
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        value={deviceNameSearch}
                        onChange={(e) => setDeviceNameSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th>
                    <p>Device Type (s)</p>
                    <div className="SearchTextDiv StatusSearchText">
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        value={deviceTypeSearch}
                        onChange={(e) => setDeviceTypeSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th>
                    <p>Alert Type (s)</p>
                    <div className="SearchTextDiv StatusSearchText">
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        value={alertTypeSearch}
                        onChange={(e) => setAlertTypeSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th>
                    <p>Severity (s)</p>
                    <div className="SearchTextDiv StatusSearchText">
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        value={severitySearch}
                        onChange={(e) => setSeveritySearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th>
                    <p>Timezone (s)</p>
                    <div className="SearchTextDiv StatusSearchText">
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        value={timeZoneSearch}
                        onChange={(e) => setTimeZoneSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th style={{ verticalAlign: "middle" }}>
                    <p>Generated at</p>
                    <div className="SearchTextDiv StatusSearchText" style={{ display: "none" }}>
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        disabled={true}
                      // value={eventDescriptionSearch}
                      // onChange={(e) => setEventDescriptionSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th style={{ verticalAlign: "middle" }}>
                    <p>Acknowledge at</p>
                    <div className="SearchTextDiv StatusSearchText" style={{ display: "none" }}>
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        disabled={true}
                      //   value={timeZoneSearch}
                      //   onChange={(e) => setTimeZoneSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                        //   onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                        //   onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th>
                    <p>Operator (s)</p>
                    <div className="SearchTextDiv StatusSearchText">
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        value={operatorName}
                        onChange={(e) => setOperatorName(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                          onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th style={{ verticalAlign: "middle" }}>
                    <p>Alert Closed at</p>
                    <div className="SearchTextDiv StatusSearchText" style={{ display: "none" }}>
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        disabled={true}
                      //   value={timeZoneSearch}
                      //   onChange={(e) => setTimeZoneSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                        //   onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                        //   onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>

                  <th style={{ verticalAlign: "middle" }}>
                    <p>Resolution time</p>
                    <div className="SearchTextDiv StatusSearchText" style={{ display: "none" }}>
                      <input
                        className="SearchViewInput"
                        type="search"
                        placeholder="Search"
                        disabled={true}
                      //   value={timeZoneSearch}
                      //   onChange={(e) => setTimeZoneSearch(e.target.value)}
                      />
                      {props.isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                        //   onClick={() => searchListDetails()}
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite}
                          height="15"
                        //   onClick={() => searchListDetails()}
                        />
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style={{border : "1px solid #d4d4d4"}}>
                {tableLoading ? (
                  <tr onClick={() => setShow(!show)}>
                    {
                      <>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                        <td>
                          <Skeleton className="width80pct" />
                        </td>
                      </>
                    }
                  </tr>
                ) : (
                  <>
                    {alertData.length > 0 ? (
                      alertData?.map((item) => (
                        <ActivityReportTable props={item} />
                      ))
                    ) : (
                      <div className="noRecordFoundTable">
                        <p className="noRecordFoundTextTable">
                          No Record Available.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>

          {totalDevices == 0 || totalPages == 0 || totalPages <= limit ? (
            <div className="PaginationDiv mr-2 mb-2 mt-2">
              <h5 className="PageNumbers">
                {totalDevices == 0 ? 0 : 1} - {totalDevices}
              </h5>
              <span>of</span>
              <h5 className="PageNumbers">{totalDevices}</h5>
              <Pagination>
                <Pagination.Prev disabled />
                <Pagination.Item active disabled>
                  {1}
                </Pagination.Item>
                <Pagination.Next disabled />
              </Pagination>
            </div>
          ) : (
            <div className="PaginationDiv mr-2 mb-2 mt-2">
              <h5 className="PageNumbers">
                {totalDevices == 0
                  ? 0
                  : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                -
                {currentPageNo * limit > totalDevices
                  ? totalDevices
                  : currentPageNo * limit}
              </h5>
              <span>of</span>
              <h5 className="PageNumbers">{totalDevices}</h5>

              <Pagination>
                <Pagination.Prev
                  onClick={prevClick}
                  disabled={currentPageNo == 1}
                />
                {PaginationComponent(currentPageNo)}
                <Pagination.Next
                  onClick={nextClick}
                  disabled={currentPageNo == maxPageAllowed}
                />
              </Pagination>
            </div>
          )}
          {/* <CommonTable 
            headers={tableHeaders}
            data={data}
            tableLoading={tableLoading}
            show={show}
            setShow={setShow}
            isDarkTheme={props.isDarkTheme}
            searchListDetails={searchListDetails}
            totalDevices={totalDevices}
          /> */}
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
