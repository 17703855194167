import React from 'react'
import { useState } from 'react'

const TimerWidget = ({ maxCount, minCount, type, defaultValue, handleCallback, 
    min,
    max,
    maxLength,
    handleTimerCB }) => {
    
    const [count, setCount] = useState(defaultValue);

    const timeValidation = (e) => {
        let val = e.target.value;
        if(val > parseInt(max)){
            setCount(max)
        }else if(val < parseInt(min)){
            setCount(0)
        }else{
            setCount(val);
        }
        handleTimerCB(val, type);
    }

    return (
    <div className='timerDiv'>
        <input className='timerDiv__input' type='number' value={count} 
            onChange={timeValidation} 
            min={min}
            max={max}
            maxLength={maxLength}
        />
    </div>
  )
}

export default TimerWidget