import React, { useEffect, useRef, useState, useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Avatar from 'react-avatar'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'

import '../Topbar/topbar.css'
import { RefreshIcon, MonitoringHubLogo, MonitoringHubWhiteLogo, MenuIcon, SunLight, LightSun, DarkMoon, LightSunSelected, DarkMoonSelected, MoonDark } from '../../assets/images';
import { CheckForPrivilege } from '../../pages/Profile/PrivilegeExtension'
import { LogoutDotNet, backgroundThemeFunction } from '../../services/userAuthServices'
import { DrawerContext } from '../../Context/DrawerContext'
import { ConversationStateContext } from '../../Context/ConversationStateContext'

import NotificationIcon from '../../pages/Notification/NotificationIcon'
import CurrentTime from './CurrentTime'
import { DOWNLOAD, HOMEPAGE, PROFILE } from '../../navigation/CONSTANTS'
import { deleteAllRecord } from '../../utils/IndexedDBStorageUtil'
import { HIDE_STUFF } from '../../config'

export default function Topbar({ profile, isDarkTheme, setIsDarkTheme, childWindow, alertChildWindow, setChildWindow, setIsPlaySound, isplaySound, toggleHide }) {
    /**
     * * For closing notification window on outside clicks
     * * https://www.codingdeft.com/posts/react-on-click-outside/
     */
    const ref = useRef()

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [tabopen, settabopen] = useState(false)
    const [notificationPrivilege] = useState(CheckForPrivilege("NOTIFICATION_VIEW"))
    const [profilePrivilege] = useState(CheckForPrivilege("PROFILE_VIEW"))
    const [abbreviation,setAbbreviation] = useState("");
    let loggedInUserGlobal = JSON.parse(localStorage.getItem('user'))

    const [selectButton, setSelectButton] = useState();

    const [openDropdown, setOpenDropdown] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isMenuOpen])

useEffect (()=>{
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    setAbbreviation(loggedInUser?.Abbreviation)
},[]);

    useEffect(() => {
        try {
            // console.log("DB subcribe")
            //getertmessage().on('value', ertmess)
            //mixpanel.track("Clicks on Notification Pop Up")
        } catch (error) {
            console.error(error)
        }
        return () => {
            try {
                // console.log("DB unsubcribe")
                //getertmessage().off('value', ertmess);
            } catch (error) {
                console.error(error)
            }
        }
    }, [])

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        document.body.className = savedTheme;
        setIsDarkTheme(savedTheme !== 'darkTheme');
    }, []);

    useEffect(() => {
        const soundPlay = localStorage.getItem('PlaySound');
        let temp = soundPlay == "true" ? true : false
        setIsPlaySound(temp)
    }, []);


    let history = useHistory();

    const { conversationstate, setconversationState } = useContext(ConversationStateContext)
    const { collapsed, setCollapsed } = useContext(DrawerContext);

    const ertmess = (snapshot) => {
        try {
            let temp = snapshot.val();
            //if(temp != "Notification"){
            setconversationState([temp])
            //}
        } catch (error) {
            console.error(error)
        }
    }

    const onLogout = async () => {
        try {
            Swal.fire({
                icon: 'question',
                title: 'Do you want to logout?',
                showCancelButton: true,
                confirmButtonText: `Logout`,
                confirmButtonColor: '#027aaa',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    LogoutDotNet().then(user => {
                        history.push('/login');
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('authCode')
                        localStorage.removeItem('user');
                        // localStorage.removeItem('user');
                        localStorage.removeItem('userPassword');
                        localStorage.removeItem('UserID');
                        localStorage.removeItem('sessionExpired');
                        localStorage.removeItem('hootWayData');
                        // localStorage.removeItem('theme');
                        sessionStorage.removeItem('Page_Refresh_State');
                        sessionStorage.removeItem('mosaicWindowKey');
                        sessionStorage.removeItem('alertWindowKey');
                        localStorage.removeItem('PlaySound');
                        deleteAllRecord();
                        console.log("WINDOW-CHILDWINDOW", childWindow);
                        console.log("WINDOW-CHILDWINDOW", alertChildWindow);
                        let tempWindow = window.open('','mosaicWindow');
                        let tempAlertWindow = window.open('','alertWindow');
                        if(childWindow != null){
                            childWindow.close();
                        }
                        if(alertChildWindow != null){
                            alertChildWindow.close();
                        }
                        if(tempWindow != null){
                            tempWindow.close();
                        }
                        if(tempAlertWindow != null){
                            tempAlertWindow.close();
                        }
                    })
                }
            })
        } catch (error) {
            console.error({ error })
        }
    }

    const MinimizeSidebar = () => {
        setCollapsed(!collapsed);
    }

    const toggleTheme = (darkClicked) => {
        if(isDarkTheme != darkClicked){
            return;
        }
        setIsDarkTheme(!isDarkTheme);
        localStorage.setItem('theme', isDarkTheme ? 'darkTheme' : 'lightTheme');
        document.body.className = isDarkTheme ? 'darkTheme' : 'lightTheme';
        let userId = JSON.parse(localStorage.getItem("UserID"))
        let darkThemeNumber = (isDarkTheme ? 0 : 1)
        backgroundThemeFunction(userId, darkThemeNumber)
    };

    const toggleSound = () => {
        setIsPlaySound(!isplaySound)
        if (isplaySound) {
            localStorage.setItem('PlaySound', false)
        } else {
            localStorage.setItem('PlaySound', true)
        }


    };

    return (
        <header className="topbar">
            <div className="topbarLeft">
                {/* <button onClick={MinimizeSidebar}>
                    <img src={MenuIcon} style={{ marginTop: "-1px" }} />
                </button> */}
                <div className='hamburgerMainDiv'>
                    <div className={`hamburger ${!collapsed ? 'is-active' : ''}`} id="hamburger-1" onClick={MinimizeSidebar}>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </div>
                </div>
                <span className="topLogo2">
                    {
                        isDarkTheme ?
                            <MonitoringHubLogo alt="MonitoringHubLogo" onClick={(e) => { history.push(HOMEPAGE) }} className="cognyfloLogo" /> :
                            <MonitoringHubWhiteLogo alt="MonitoringHubLogo" onClick={(e) => { history.push(HOMEPAGE) }} className="cognyfloLogo" />
                    }

                </span>
            </div>

            <div className="topbarRight">
                {
                    toggleHide
                        ?
                        <div className='alertSoundText'>
                            <p style={{ margin: "0px", marginRight: "10px" }}>Alert Sound</p>
                            <div className='togglecheckbox' style={{ paddingTop: "5px" }}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isplaySound}
                                        onChange={toggleSound}
                                    // style={{ marginRight: "3px" }} 
                                    />
                                    <span />
                                </label>
                            </div>
                        </div>
                        :
                        <div className='alertSoundText'>
                            <p style={{ margin: "0px", marginRight: "10px" }}>Alert Sound</p>
                            <div className='togglecheckbox disabletogglecheckbox' style={{ paddingTop: "5px" }}>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>
                                            Alert sound not configured
                                        </Tooltip>
                                    }
                                >
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={false}
                                            disabled={true}
                                        />
                                        <span />
                                    </label>
                                </OverlayTrigger>
                            </div>
                        </div>
                }
                
                <div className='topbarHorizontalLine'></div>

                {/*  For toggle button of theme */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <input type="checkbox" id="darkmode-toggle" className='ThemeCheckbox' checked={!isDarkTheme} onClick={toggleTheme} />
                    <label className='ThemeLabel' htmlFor="darkmode-toggle">
                        <svg version="1.1" class="sun" x="0px" y="0px" viewBox="0 0 496 496">
                            <rect x="152.994" y="58.921" transform="matrix(0.3827 0.9239 -0.9239 0.3827 168.6176 -118.5145)" width="40.001" height="16" />
                            <rect x="46.9" y="164.979" transform="matrix(0.9239 0.3827 -0.3827 0.9239 71.29 -12.4346)" width="40.001" height="16" />
                            <rect x="46.947" y="315.048" transform="matrix(0.9239 -0.3827 0.3827 0.9239 -118.531 50.2116)" width="40.001" height="16" />
                            <rect x="164.966" y="409.112" transform="matrix(-0.9238 -0.3828 0.3828 -0.9238 168.4872 891.7491)" width="16" height="39.999" />
                            <rect x="303.031" y="421.036" transform="matrix(-0.3827 -0.9239 0.9239 -0.3827 50.2758 891.6655)" width="40.001" height="16" />
                            <rect x="409.088" y="315.018" transform="matrix(-0.9239 -0.3827 0.3827 -0.9239 701.898 785.6559)" width="40.001" height="16" />
                            <rect x="409.054" y="165.011" transform="matrix(-0.9239 0.3827 -0.3827 -0.9239 891.6585 168.6574)" width="40.001" height="16" />
                            <rect x="315.001" y="46.895" transform="matrix(0.9238 0.3828 -0.3828 0.9238 50.212 -118.5529)" width="16" height="39.999" />
                            <path d="M248,88c-88.224,0-160,71.776-160,160s71.776,160,160,160s160-71.776,160-160S336.224,88,248,88z M248,392
                                c-79.4,0-144-64.6-144-144s64.6-144,144-144s144,64.6,144,144S327.4,392,248,392z"/>
                            <rect x="240" width="16" height="72" />
                            <rect x="62.097" y="90.096" transform="matrix(0.7071 0.7071 -0.7071 0.7071 98.0963 -40.6334)" width="71.999" height="16" />
                            <rect y="240" width="72" height="16" />
                            <rect x="90.091" y="361.915" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -113.9157 748.643)" width="16" height="71.999" />
                            <rect x="240" y="424" width="16" height="72" />
                            <rect x="361.881" y="389.915" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 397.8562 960.6281)" width="71.999" height="16" />
                            <rect x="424" y="240" width="72" height="16" />
                            <rect x="389.911" y="62.091" transform="matrix(0.7071 0.7071 -0.7071 0.7071 185.9067 -252.6357)" width="16" height="71.999" />
                        </svg>
                        <svg version="1.1" class="moon" x="0px" y="0px" viewBox="0 0 49.739 49.739">
                            <path d="M25.068,48.889c-9.173,0-18.017-5.06-22.396-13.804C-3.373,23.008,1.164,8.467,13.003,1.979l2.061-1.129l-0.615,2.268
                            c-1.479,5.459-0.899,11.25,1.633,16.306c2.75,5.493,7.476,9.587,13.305,11.526c5.831,1.939,12.065,1.492,17.559-1.258v0
                            c0.25-0.125,0.492-0.258,0.734-0.391l2.061-1.13l-0.585,2.252c-1.863,6.873-6.577,12.639-12.933,15.822
                            C32.639,48.039,28.825,48.888,25.068,48.889z M12.002,4.936c-9.413,6.428-12.756,18.837-7.54,29.253
                            c5.678,11.34,19.522,15.945,30.864,10.268c5.154-2.582,9.136-7.012,11.181-12.357c-5.632,2.427-11.882,2.702-17.752,0.748
                            c-6.337-2.108-11.473-6.557-14.463-12.528C11.899,15.541,11.11,10.16,12.002,4.936z"/>
                        </svg>
                    </label> */}

                    <div  className='themeMainDiv'>
                            <>
                        <button onClick={() => toggleTheme(false)} className={`${isDarkTheme ? 'lightThemebutton' : 'darkThemebutton'}`}>
                            <img src={selectButton == "lightTheme" ? LightSun : LightSunSelected} alt='' />  
                        </button>
                        <button onClick={() => toggleTheme(true)} className={`${isDarkTheme ? 'darkThemebutton' : 'lightThemebutton' }`}>
                            <img src={selectButton == "darkTheme" ? DarkMoonSelected : MoonDark} alt='' />
                        </button>
                            </>
                        
                    </div>
                 {/* <div className='ml-4'>
                  {`(${loggedInUserGlobal?.Abbreviation})`}
                </div> */}
                </div>

                <div className='topbarHorizontalLine'></div>

                <CurrentTime />

                <div className='topbarHorizontalLine'></div>
                <div className="topDate">{`(${loggedInUserGlobal?.Abbreviation})`}</div>

                <div className="topRefresh" hidden>
                    <RefreshIcon height={20} />
                </div>

                <div className='topbarHorizontalLine'></div>

                {
                    notificationPrivilege &&
                    <NotificationIcon
                        iconref={ref}
                        isMenuOpen={isMenuOpen}
                        setIsMenuOpen={setIsMenuOpen}
                        settabopen={settabopen} />
                }

                <div className='topbarHorizontalLine'></div>

                <div className="topProfilePic">
                    {
                        (profile || true) &&
                        <>
                        {/* New dropdown list div start*/}
                            <div style={{display:"none"}}>
                                <div className='ProfileCustomDropdown'>
                                    <Avatar
                                        name={profile?.FullName}
                                        round={true}
                                        size={35}  onClick={() => setOpenDropdown(!openDropdown)}/>
                                    
                                    {openDropdown && ( 
                                    <div className='TopbarDropdownDiv' >
                                        <div className='newtopbarDropdownMenu'>
                                            {profilePrivilege &&
                                                <>
                                                    <div  className='ThemeToggleBtnDiv'>
                                                        <a className='dropdownText' onClick={() => history.push(PROFILE)}>
                                                            View Profile
                                                        </a>
                                                    </div>
                                                </>
                                            }
                                            {
                                                // !HIDE_STUFF
                                                false
                                                    ?
                                                <>
                                                    <div  className='ThemeToggleBtnDiv'>
                                                        <a className='dropdownText' onClick={() => history.push(DOWNLOAD)}>
                                                            Download Request
                                                        </a>
                                                    </div>
                                                </>
                                                : null
                                            }
                                            <div  className='ThemeToggleBtnDiv'>
                                                <a className='dropdownText' onClick={onLogout}>
                                                    Logout
                                                </a>
                                            </div>
                                            <div className='ThemeToggleBtnDiv'>
                                                <a className='dropdownText'>
                                                    Theme
                                                </a>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div  className='themeMainDiv'>
                                                            <>
                                                        <button onClick={() => toggleTheme(false)} className={`${isDarkTheme ? 'lightThemebutton' : 'darkThemebutton'}`}>
                                                            <img src={selectButton == "lightTheme" ? LightSun : LightSunSelected} alt='' />  
                                                        </button>
                                                        <button onClick={() => toggleTheme(true)} className={`${isDarkTheme ? 'darkThemebutton' : 'lightThemebutton' }`}>
                                                            <img src={selectButton == "darkTheme" ? DarkMoonSelected : MoonDark} alt='' />
                                                        </button>
                                                            </>                                                            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                            
                        {/* New dropdown list div ends*/}

                            <Dropdown className="dropdown profileDpDwn w-0">
                                <Dropdown.Toggle as={ProfileDropDown} id="dropdown-custom-components">
                                    <Avatar
                                        name={profile?.FullName}
                                        round={true}
                                        size={35} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='topbarDropdownMenu'>
                                    {profilePrivilege &&
                                        <>
                                            <Dropdown.Item eventKey="1" onClick={() => history.push(PROFILE)}>View Profile</Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    }
                                    {
                                        // !HIDE_STUFF
                                        false
                                            ?
                                            <>
                                                <Dropdown.Item eventKey="2" onClick={() => history.push(DOWNLOAD)}>Download Request</Dropdown.Item>
                                                <Dropdown.Divider />
                                            </>
                                            : null
                                    }
                                    {/* <Dropdown.Item eventKey="3">
                                    Alert Sound
                                    <div className='togglecheckbox' style={{float: "right", paddingTop: "2px"}}>
                                        <label>
                                            <input 
                                            checked
                                            // disabled={!canEdit} 
                                            // checked={enableSound} 
                                            // onChange={() => setEnableSound(!enableSound)} 
                                            type="checkbox" 
                                            style={{ marginRight: "3px" }} 
                                            />
                                            <span />
                                        </label>
                                    </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider /> */}
                                    <Dropdown.Item eventKey="4" onClick={onLogout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    }
                </div>
            </div>
        </header>
    )
}


// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const ProfileDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        className="profileDpDwn profile-name m-1"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

