import React, { useEffect } from "react";
import { CheckmarkSuccessCircle, DropArrow, SearchIcon, SearchIconWhite } from "../../assets/images";
import { useState } from "react";

const EmailSetting = ({isDarkTheme}) => {
  return (
    <div className="col-lg-12 mt-2">
    <div className="SiteMainContainer">
      <div className="SiteLeftContainer">
        <div className="m-2 HeaderSiteList">
          <input
            id="Search_txt"
            className="SearchViewInput1"
            type="text"           
            placeholder="Search Customer"
          />
          <div className="SearchTextDivConfigTool">
            {isDarkTheme ? (
              <img
                className="EyeIcon"
                src={SearchIcon}
                alt="search icon"
              />
            ) : (
              <img
                className="EyeIcon"
                src={SearchIconWhite}
                height="15"
                alt="search icon"
              />
            )}
          </div>
        </div>
        <div className="NotificationList">
            <div  className="OneList">
                <p className="ListofText">Message one</p>
            </div>
        </div>
      </div>
      <div className="SiteRightContainer">
      <form>
          <div className="PrepostSettingForm PrePostSettingDiv">
            <h4 className="prepostCustomername"> Email setting </h4>
            <div className="inputMainContainer w40dot75rem mb-0">
              <div className="onlyforEnableCheckbox ml-0">
                 
              </div>
            </div>
          </div>
        </form>

        <div style={{ textAlign: "center" }}>
            <button className="DataAccessEdit" style={{ width: "100px" }}>
                <span className="ViewUpdate">Edit</span>
            </button>
            <button className="DataAccessEdit" style={{ width: "100px" }}>
                <span className="ViewUpdate">Save</span>
            </button>
            <button className="DataAccessEdit"  style={{ width: "100px" }}>
                <span className="ViewUpdate">Cancel</span>
            </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default EmailSetting