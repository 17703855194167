import React, { useEffect, useState } from 'react'

import { Modal } from 'react-bootstrap'
import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer'

import DefaultNode from '../Workflow/DecisisonNode'

export default function WFPreview({
    show,
    onClose,
    workflowName,
    elements,
    nodesDraggable
}) {
    const [tempElement, setTempElement] = useState([]);
    useEffect(() => {
        let tempFinalFlowData = elements.map((item) => {
            if (item.type == "default" && item.data.label.includes("ERT")) {
                item.data.label = item.data.label.replaceAll("ERT", "FR")
                return item;
            } else {
                return item;
            }
        })
        setTempElement(tempFinalFlowData);
    }, []);

    return (
        <Modal size="xl" show={show} onHide={onClose} backdrop="static" className='ViewWorkFlowModel'>
            <Modal.Body>
                <ReactFlowProvider>
                    <Modal.Header closeButton style={{ borderBottom: "none" }}>{workflowName}</Modal.Header>
                    <ReactFlow
                        className='workflowPreview'
                        elements={tempElement}
                        nodesDraggable={nodesDraggable}
                        nodeTypes={nodeTypes}
                    >
                    </ReactFlow>
                </ReactFlowProvider>
            </Modal.Body>
        </Modal>
    )
}

const nodeTypes = {
    default: DefaultNode
};
