import React from "react";
import "../../pages/HealthMonitoring/HealthDisplaySite.css";
import {
  Camera,
  DVRNVR,
  Panel,
  Sensors,
  Router,
  NewSearchIcon,
  Sites,
  HDD,
  SearchIcon,
  Information,
} from "../../assets/images";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import CameraHealthDetails from "./CameraHealthDetails";
import DVRNVRHealthDetails from "./DVRNVRHealthDetails";
import HDDHealthDetails from "./HDDHealthDetails";

const headings = [
  "Device Name",
  "Current Status",
  "Last Update",
  "Issue",
  "IP Address",
  "Port",
  "Channel No.",
];

const HealthDisplaySite = () => {
  return (

<>
        <div className="">
          <div className="col-lg-12 mt-2">
            <div className="NameHeading1">
              <p className="config-tool-head">
                Health Monitoring &gt; {"Site Name"}
              </p>
            </div>
          </div>

          <div className="cardBox">
            <div className="kpicard">
              <div className="img-bg">
                <img src={Sites} alt="Camera icon" />
              </div>

              <div className="ps-1 kpidetailsdiv">
                <p className="devices-text my-0">
                  Total Devices- <span className="devices-counts">004</span>
                </p>
                <div className="statusmaindiv">
                  <div>
                    <p className="online-status my-0">Online</p>
                    <p className="ol-offline-status-count">123</p>
                  </div>
                  <div>
                    <p className="offline-status my-0">Offline</p>
                    <p className="ol-offline-status-count">123</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="kpicard">
              <div className="img-bg">
                <img src={DVRNVR} alt="Camera icon" />
              </div>

              <div className="ps-1 kpidetailsdiv">
                <p className="devices-text my-0">
                  DVR/NVR(s) <span className="devices-counts">004</span>
                </p>
                <div className="statusmaindiv">
                  <div>
                    <p className="online-status my-0">Online</p>
                    <p className="ol-offline-status-count">123</p>
                  </div>
                  <div>
                    <p className="offline-status my-0">Offline</p>
                    <p className="ol-offline-status-count">123</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="kpicard">
              <div className="img-bg">
                <img src={Camera} alt="Camera icon" />
              </div>
              <div className="ps-1 kpidetailsdiv">
                <p className="devices-text my-0">
                  Camera(s) <span className="devices-counts">004</span>
                </p>
                <div className="statusmaindiv">
                  <div>
                    <p className="online-status my-0">Online</p>
                    <p className="ol-offline-status-count">123</p>
                  </div>
                  <div>
                    <p className="offline-status my-0">Offline</p>
                    <p className="ol-offline-status-count">123</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="kpicard">
              <div className="img-bg">
                <img src={HDD} alt="Camera icon" />
              </div>

              <div className="ps-1 kpidetailsdiv">
                <p className="devices-text my-0">
                  HDD(s) <span className="devices-counts"> 004 </span>
                </p>
                <div className="statusmaindiv">
                  <div>
                    <p className="online-status my-0">Online</p>
                    <p className="ol-offline-status-count">123</p>
                  </div>
                  <div>
                    <p className="offline-status my-0">Offline</p>
                    <p className="ol-offline-status-count">123</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}

          <div className="col-lg-12">
            <div className="Main-Base">
              <div className="Card-Table-Navigation ">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item hmnav-item">
                    <a
                      className="nav-link active"
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Camera(s)
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      DVR/NVR(s)
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-contact-tab"
                      data-toggle="pill"
                      href="#pills-contact"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      HDD(s)
                    </a>
                  </li>
                </ul>
              </div>

              <CameraHealthDetails />

              {/* <DVRNVRHealthDetails />

              <HDDHealthDetails /> */}
              {/* <div className="card-body p-0">
                <table
                  className="table table-text-ext table-fixed report-table healthmonitoringTable"
                  id="alert-activity-report"
                >
                  <thead className="ReportTableHeader ReportTableHeadReport">
                    <tr>
                      <th>
                        <p>Device Name</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <input
                            className="SearchViewInput"
                            type="text"
                            placeholder="Search"
                          />
                          <img
                            className="EyeIcon"
                            alt="Search-icon"
                            src={SearchIcon}
                          />
                        </div>
                      </th>
                      <th>
                        <p>Current Status</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <select
                            required
                            className="SiteNameTextBox ArrowSelect HMTableSelect"
                          >
                            <option key="0" disabled={false} value="0">
                              - Select Data -
                            </option>
                          </select>
                        </div>
                      </th>
                      <th>
                        <p>Last Update</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <input
                            className="SearchViewInput"
                            type="text"
                            placeholder="Search"
                          />
                          <img
                            className="EyeIcon"
                            alt="Search-icon"
                            src={SearchIcon}
                          />
                        </div>
                      </th>
                      <th>
                        <p>Issue</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <input
                            className="SearchViewInput"
                            type="text"
                            placeholder="Search"
                          />
                          <img
                            className="EyeIcon"
                            alt="Search-icon"
                            src={SearchIcon}
                          />
                        </div>
                      </th>
                      <th>
                        <p>IP Address</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <input
                            className="SearchViewInput"
                            type="text"
                            placeholder="Search"
                          />
                          <img
                            className="EyeIcon"
                            alt="Search-icon"
                            src={SearchIcon}
                          />
                        </div>
                      </th>
                      <th>
                        <p>Port</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <select
                            required
                            className="SiteNameTextBox ArrowSelect HMTableSelect"
                          >
                            <option key="0" disabled={false} value="0">
                              - Select Data -
                            </option>
                          </select>
                        </div>
                      </th>
                      <th>
                        <p>Channel No.</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <select
                            required
                            className="SiteNameTextBox ArrowSelect HMTableSelect"
                          >
                            <option key="0" disabled={false} value="0">
                              - Select Data -
                            </option>
                          </select>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="devices-tablename">Cam One</td>
                      <td className="online-color">Online</td>
                      <td>401</td>
                      <td>Tesco</td>
                      <td>400077</td>
                      <td>Mumbai</td>
                      <td>Ghatkopar</td>
                    </tr>
                    <tr>
                      <td className="devices-tablename">Cam One</td>
                      <td className="offline-color">Offline</td>
                      <td>401</td>
                      <td>Tesco</td>
                      <td>400077</td>
                      <td>Mumbai</td>
                      <td>Ghatkopar</td>
                    </tr>
                    <tr>
                      <td className="devices-tablename">Cam One</td>
                      <td className="online-color">Online</td>
                      <td>401</td>
                      <td>Tesco</td>
                      <td>400077</td>
                      <td>Mumbai</td>
                      <td>Ghatkopar</td>
                    </tr>
                    <tr>
                      <td className="devices-tablename">Cam One</td>
                      <td className="offline-color">Offline</td>
                      <td>401</td>
                      <td>Tesco</td>
                      <td>400077</td>
                      <td>Mumbai</td>
                      <td>Ghatkopar</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="horizontal-Line"></div>
              <div>
                <div className="viewTimelineMsg">
                  <img src={Information} alt="information icon" />
                  <h5>Select a device from table to view timeline.</h5>
                  <LineChart />
                </div>
              </div> */}
              
            </div>
          </div>
        </div>
      </>
  );
};

// const LineChart = () => {
//   const options1 = {
//     exporting: {
//         enabled: false,
//     },
//     chart: {
//         type: "columnrange",
//         inverted: true,
//         width: 1093,
//         height: 296
//     },
//     title: {
//         text: "",
//         // margin: 20
//     },
//     credits: {
//         enabled: false
//     },
//     yAxis: {
//         min: Date.UTC(2010, 0, 24, 0, 0, 0),
//         max: Date.UTC(2010, 0, 24, 0, 0, 0),
//         type: "datetime",
//         tickPositioner: function () {
//             var info = this.tickPositions.info;
//             var positions = [];
//             let i;
//             for (
//                 i = Date.UTC(2010, 0, 1, 0, 0, 0);
//                 i <= Date.UTC(2010, 0, 2, 0, 0, 0);
//                 i += 3600 * 1000 * 2
//             ) {
//                 positions.push(i);
//             }
//             positions.info = info;
//             return positions;
//         },
//         lineWidth: 0,
//         dateTimeLabelFormats: {
//             day: "%H:%M",
//         },
//         title: {
//             enabled: false,
//         },
//     },
//     xAxis: {
//         categories: ["DVR 4", "DVR 3", "DVR 2", "DVR 1"],
//     },
//     legend: {
//         enabled: false,
//     },
//     plotOptions: {
//         series: {
//             pointWidth: 5,
//         },
//     },

//     series: [
//         {
//             data: [
//                 {
//                     x: 0,
//                     low: Date.UTC(2010, 0, 0, 1, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 12, 30, 0),
//                     /* linestyle: 'dotted-line', */
//                     color: "#0CBA3B",
//                 },
//                 {
//                     x: 0,
//                     low: Date.UTC(2010, 0, 1, 12, 30, 0),
//                     high: Date.UTC(2010, 0, 1, 14, 0, 0),
//                     linestyle: "dotted-line",
//                     color: "#C60000",
//                 },
//                 {
//                     x: 0,
//                     low: Date.UTC(2010, 0, 1, 14, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 24, 0, 0),
//                     linestyle: "dotted-line",
//                     color: "#0CBA3B",
//                 },

//                 {
//                     x: 1,
//                     low: Date.UTC(2010, 0, 0, 0, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 2, 0, 0),
//                     color: "#0CBA3B",
//                 },
//                 {
//                     x: 1,
//                     low: Date.UTC(2010, 0, 1, 2, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 6, 0, 0),
//                     color: "#C60000",
//                 },
//                 {
//                     x: 1,
//                     low: Date.UTC(2010, 0, 1, 6, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 10, 30, 0),
//                     color: "#0CBA3B",
//                 },
//                 {
//                     x: 1,
//                     low: Date.UTC(2010, 0, 1, 10, 30, 0),
//                     high: Date.UTC(2010, 0, 1, 17, 0, 0),
//                     color: "#C60000",
//                 },
//                 {
//                     x: 1,
//                     low: Date.UTC(2010, 0, 1, 17, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 24, 0, 0),
//                     color: "#0CBA3B",
//                 },

//                 {
//                     x: 2,
//                     low: Date.UTC(2010, 0, 0, 0, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 2, 0, 0),
//                     color: "#0CBA3B",
//                 },
//                 {
//                     x: 2,
//                     low: Date.UTC(2010, 0, 1, 2, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 11, 30, 0),
//                     color: "#C60000",
//                 },
//                 {
//                     x: 2,
//                     low: Date.UTC(2010, 0, 1, 11, 30, 0),
//                     high: Date.UTC(2010, 0, 1, 24, 0, 0),
//                     color: "#0CBA3B",
//                 },

//                 {
//                     x: 3,
//                     low: Date.UTC(2010, 0, 0, 0, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 2, 0, 0),
//                     color: "#0CBA3B",
//                 },
//                 {
//                     x: 3,
//                     low: Date.UTC(2010, 0, 1, 2, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 6, 0, 0),
//                     color: "#C60000",
//                 },
//                 {
//                     x: 3,
//                     low: Date.UTC(2010, 0, 1, 6, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 10, 30, 0),
//                     color: "#0CBA3B",
//                 },
//                 {
//                     x: 3,
//                     low: Date.UTC(2010, 0, 1, 10, 30, 0),
//                     high: Date.UTC(2010, 0, 1, 17, 0, 0),
//                     color: "#C60000",
//                 },
//                 {
//                     x: 3,
//                     low: Date.UTC(2010, 0, 1, 17, 0, 0),
//                     high: Date.UTC(2010, 0, 1, 24, 0, 0),
//                     color: "#0CBA3B",
//                 },
//             ],
//         },
//     ],
// }

// return (
//     <div >
//         <HighchartsReact
//             // LineChart={Highcharts}
//             options={options1}
//         />
//     </div>
// )
// }

export default HealthDisplaySite;
