import React, { useState, useEffect} from 'react'
import { addMinutes, subMinutes, differenceInMinutes } from 'date-fns'
import { multiTimeZoneHelper } from '../../utils/utils'
import { MULTI_TIME_ZONE } from '../../config'


const CurrentTime = () => {
    const [currentDT, setCurrentDT] = useState(new Date())
    let loggedInUserGlobal = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        let timerId = setInterval(() => {
            if(MULTI_TIME_ZONE){
              let loggedInUser = JSON.parse(localStorage.getItem('user'))
              let offSetMinutes = loggedInUser.OffsetMinutes;
              setCurrentDT(multiTimeZoneHelper(offSetMinutes));
            }else{
              setCurrentDT(new Date());
            }
        }, 1000);
        return () => clearInterval(timerId);
    }, [])
    
  return (
    <div className='mainDivDateTime'>
      {/* <div className="topDateTimezone">{`(${loggedInUserGlobal?.Abbreviation})`}</div> */}
    <div className="topDate">{currentDT.toDateString()}</div>
    {/* <div className="topDate">{currentDT.toUTCString().slice(0,-12)}</div> */}
    <div className="topTime">{currentDT.toLocaleTimeString('en', { hour: 'numeric', hour12: true, minute: 'numeric', second: 'numeric' })}</div>
    {/* <div className='topbarHorizontalLine'></div>
    <div className="topDateTimezone">{`(${loggedInUserGlobal?.Abbreviation})`}</div> */}
    </div>
  )
}

export default CurrentTime