import React from 'react'
import { SearchIcon, SearchIconWhite } from '../../../assets/images'

const InfoComponent = ({ headerName, search, searchHandler, isDarkTheme, infoArr }) => {
    return (
        <div className="SiteLeftContainer ml-2 mr-2"
        >
            <div className="infoFilterHeader">
                <p>{headerName}</p>
            </div>
            <div className="m-2 HeaderSiteList">
                <input
                    id="Search_txt_site"
                    className="SearchViewInput1"
                    type="text"
                    placeholder={`Search ${headerName}`}
                    value={search}
                    onChange={(e) => searchHandler(e)}
                />
                <div className="SearchTextDivConfigTool">
                    {isDarkTheme ? (
                        <img
                            className="EyeIcon"
                            src={SearchIcon}
                            alt="search icon"
                        />
                    ) : (
                        <img
                            className="EyeIcon"
                            src={SearchIconWhite}
                            alt="search icon"
                            height="15"
                        />
                    )}
                </div>
            </div>
            <div className="SaveFilterInfoList">
                {infoArr &&
                    infoArr?.length > 0
                    ? (
                        <div>{
                            infoArr.map((item) => (
                                <p className="ListofText">
                                    {item.Values}
                                </p>
                            ))
                        }

                        </div>
                    ) : (
                        <div className="no-data-found">
                            <p>No {headerName} Found.</p>
                        </div>
                    )}
                {!infoArr && (
                    <div className="no-data-found">
                        No {headerName} Available.
                    </div>
                )}
            </div>
        </div>
    )
}

export default InfoComponent
