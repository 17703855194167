import React, { useState, useEffect, useRef, useContext } from 'react'
import { Switch } from 'react-router-dom';
import { useAuth } from '../../navigation/Auth/ProvideAuth'
import ConfigSideBar from './ConfigSideBar'
import {
    Getmastertablelist, Getsitelist, Addsitedetails, Updatesitedetails,
    GetEventTemplateList, GetSeverityTypeList, deletesite, deviceconfigcrud, Alerttemplatecrud, getCountryDetails, getStateDetails, getInterfaceTypeData, getInterfaceSubTypeData, getInterfaceList, addInterfaceDetails, updateInterfaceDetails,deleteInterfaceDetails, bulkUploadCamera, bulkUploadPanel, getCCTVType, Getsitetypelist, SiteList, GetDeviceList, GetAlertTemplate, getAlertTemplateByIdAPI, AddAlertTemplateApI, UpdateAlertTemplateApI, getPanelZoneType, getAlertTemplateByDeviceIdAPI, getCustomerList, removeDeviceFromTemp, seperateAlertTemplateByDevice, splitAlertTemplateByDevice, GetDataToFilter, getZoneTypeAlertTemp, DeleteAlertTemplate2, getAllPanelList,
    bulkUploadACS
} from '../../services/configurationServices'

import { checkspecialcharacters, ConvertDate, validateTime, ConvertDateToUTC } from '../../utils/utils'
import { createNewUser, deleteUser, GetPrivilegeGroupList, Getuserslist, registerEmailUser, updateUser, userGroupAndSiteGroupCRUD } from '../../services/userManagementservice'
import { checkalertassociated } from '../../services/cameraService'
import UserTemplateStep from './User/UserTemplateStep'
import ReviewStep from './ReviewStep'
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { useHistory, useLocation, } from 'react-router-dom'
import AlertTemplateStep from './AlertTemplate/AlertTemplateStep'
import { loadWorkflows } from '../../services/workflowService'
import Swal from 'sweetalert2'
import WFPreview from '../Workflow/WFPreview'
import { IsPhoneNoValid } from '../../utils/validations'
import { mixpanel } from '../../utils/MixPanelUtil'
import { nearbyresource } from '../../services/chatFlowService'
import {
    getOutgoers,
} from "react-flow-renderer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConversationStateContext } from '../../Context/ConversationStateContext'
import { BASE_URL, MULTI_TIME_ZONE } from '../../config'
import { ErrorToast } from '../../CustomHooks/ErrorToast'

import DrawerProvider, { DrawerContext } from '../../Context/DrawerContext';

import firebase from "firebase/app";
import 'firebase/database'
import NotificationContainer from '../NotificationMenu/NotificationContainer'
import { ProPostSetting } from './AlertPrePost/ProPostSetting'
import EmailSetting from './EmailSetting'
import PanelSetting from '../PanelControl/PanelSetting'
import SoundSetting from './SoundSetting/SoundSetting'
import SiteTemplateStep from './Site/SiteTemplateStep'
import ConfigHeader from './Root/ConfigHeader'
import { GetmultiTimeZone } from '../../services/multiTimeZoneService'

let sidebarList = {
    siteTemplate: { name: "Site Management", values: ["Site Configuration", "Interface", "Manage Devices", "Manage Site Groups"] },
    alertTemplate: { name: "Alert Template", values: ["Add Alert Template", "Manage Workflow"] },
    users: { name: "Users", values: ["Add User", "FR Assignment", "Data Access Group"] },
    notification: { name: "Notification Setting" },
    prePostSetting: { name: "Alert Pre Post Setting" },
    emailSetting: {name: "Email Setting"},
    panelSetting: { name: "Panel Setting" },
    soundSetting: { name: "Sound Setting" },
    review: { name: "Review", values: ["Site Template", "Devices", "Alert Template", "Users"] }
}

let loggedInUser = null
let userid = null

let siteObj = {
    "SiteID": 0,
    "SiteName": '',
    "BranchID": '',
    "SiteAddress": '',
    "clientID": 0,
    "AddCustomerName": '',
    "575": 0,
    "StateName": 0,
    "CityName": '',
    "SiteRegionName": '',
    "SitePincode": '',
    "SiteArea": '',
    "SiteModeID":0
}

let interfaceObj = {
    "siteid": 0,
    "InterfaceId": 0,
    "InterfaceName": '',
    "InterfaceType": 0,
    "InterfaceSubType": 0,
    "InterfaceIP": '',
    "InterfacePort": '',
    "InterfaceUsername": '',
    "InterfacePassword": '',
    "UserId": 0,
    "EntityTypeID": 0,
    "InterfaceTwoWayEnabled": 0,
    "PlaybackTimeStampsToUTC": 0,
    "PlaybackLongDateFormat": 0,
    "CheckInternalHooter": 0,
    "CheckExternalHooter": 0,
    "IsHooterEnabled": 0,
    "LiveRTSPURL": '',
    "PlaybackRTSPURL": '',
    "CCTVType": '',
    "TimeZoneId":0
}

let deviceObj = {
    "ParentCameraID": 0,
    "cameraName": '',
    "privateIPAddress": '',
    "SiteID": 0,
    "userName": '',
    "password": '',
    "port": '',
    "channelNumber": '',
    // "playbackChannelNumber": '',
    "liveStreamTypeNumber": '',
    "playbackStreamTypeNumber": '',
    "rTSPURL": '',
    "location": '',
    "PanelMasterId": 0,
    "CameraBrandId": 0,
    "communicationPort" : '',
    "httpPort" : '',
    "isTranscoded" : '',
    "isPlaybackTranscoded" : '',
    "isTwoWayEnabledDevice" : '',
    "interfaceId" : '',
    "CCTVTypeID": 0,
    "TimeZoneIdDevice": 0,
    "isPTZ": "",
    "isPlaybackUTC": "",
    "identifierName": ""
}

let userObj = {
    AlertInnerCount: 0,
    AlertOuterCount: 0,
    DeviceCount: '',
    Email: '',
    FirstName: '',
    LastName: '',
    OperatorCount: 0,
    Password: '',
    Phone: '',
    PrivilegeGrpID: 0,
    PrivilegeGrpTitle: '',
    ProfileImgPath: '',
    ProfileName: '',
    RoleName: 'Operator',
    UserID: 0,
    UserName: '',
    UserRoleID: 0,
    isMobileLoginAllowed: false,
    isWebLoginAllowed: false,
    dataAccessGroupId: null,
    isEnabled: false,
    TimeZoneId: 0

}

let templateObj = {
    Description: "",
    EventAssociated: "",
    AlertCodeId: 0,
    EventCodeWithName: "",
    EventGroupId: 0,
    EventGroupTitle: "",
    AlertTemplateId: 0,
    FromTime: "00:00",
    FullTime: false,
    TemplateName: "",
    Instruction: "",
    SeverityId: 0,
    SeverityTitle: "",
    ToTime: "00:00",
    DeviceTypeId: 0,
    ClientId: 0,
    IDSPanelZoneTypeId: 0,
    SiteId: 0,
    DeviceId: 0,
    WorkflowId: 0
}

let newAlertTemplateObj = {
    templateId : "",
    templateName: "",
    templateClientId: "0",
    templateSiteId: [],
    templateDeviceTypeId: "0",
    templateDeviceSubTypeId: "0",
    templateDevice: [],
    templateAlertType: "0",
    scheduleType: "NA",
    schedulerObj : {},
    tempSelectedSites:[],
    tempSelectedDevices:[],
    // templateAlertCodeId:"",
    // templateDescription:"",
    // templateCreatedOn:"",
    // templateCreatedBy:"",
    // templateModifiedOn:"",
    // templateModifiedBy:"",
    // LstDeviceAlertTemplateConfig:[],

}

let siteGrpObj = {
    siteGroupID: 0,
    groupTitle: "",
    createdBy: 0,
    CreatedOn: "00:00"
}

let ertObj = {
    EmergencyContactID: 0,
    // SiteID: 0,
    ContactName: null,
    EmailID: null,
    Phone: null,
    SMS: false,
    Email: false,
    WhatsApp: false,
    IsCall: false,
    InApp: false,
    UserID: 0,
    ContactLevel: null,
    Designation: null,
    ERTLevelId: 0,
    LevelNumber: null
}

let panelHeadObj = {
    brand: null,
    ipaddress: null,
    branch: null,
    name: null,
    zonetype: null,
    zonenumber: null,
    zonename: null,
    timezone: null
}

let zoneObj = {
    iDSPanelZoneTypeID: 0,
    zoneNo: '',
    zoneName: '',
    idsPanelZonesID: 0
}

let camHeadObj = {
    brand: null,
    ipaddress: null,
    branch: null,
    username: null,
    password: null,
    port: null,
    channelnumber: null,
    // playbackChannelnumber: null,
    liveStreamTypeNumber: null,
    playbackStreamTypeNumber: null,
    devicename: null,
}

let acsHeadObj = {
    brand: null,
    branch: null,
    ipaddress: null,
    portnumber: null,
    username: null,
    password: null, 
    controllername: null,
    devicename: null,
    identifier: null,
    timezone: null
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ConfigurationContainer({ isDarkTheme ,getAllfromParent }) {
    const [initialTemplate, setInitialTemplate] = useState(sidebarList.siteTemplate)
    //based on the path in the url we will open the page
    let query = useQuery();
    let params = query.get("alerttemplateid")
    let location = useLocation();
    let initTemplate = query.get("initialTemplate") //from dashboard
    //site template-add user
    const userOptions = [
        { value: "CustomerSPOC", label: "Customer SPOC" },
        { value: "ERTMember", label: "ERT Member" },
    ];

    //#region useState start
    const [editMode, setEditMode] = useState(false);
    const [devicetypeid, setdevicetypeid] = useState(0)
    const [devicesmasterlist, setdevicesmasterlist] = useState(null)
    const [deviceslist, setDeviceslist] = useState(null)
    const [paneldeviceslist, setpaneldeviceslist] = useState(null)
    const [acsDevicesList, setAcsDevicesList] = useState(null)
    const [acsMainDevicesList, setAcsMainDevicesList] = useState(null)
    const [panelMainDevicesList, setPanelMainDevicesList] = useState(null);
    const [cameradeviceslist, setcameradeviceslist] = useState(null)
    const [cameraMainDevicesList, setCameraMainDevicesList] = useState(null)
    const [sites, setSites] = useState(null)
    const [filteredSites, setFilteredSites] = useState(null)
    const [users, setUsers] = useState(null)
    const [filteredUsers, setFilteredUsers] = useState(null)
    const [groups, setGroups] = useState(null)
    const [eventTemplates, setEventTemplates] = useState(null)
    const [filteredEventTemplates, setFilteredEventTemplates] = useState(null)
    const [alertCodes, setAlertCodes] = useState(null)
    const [severities, setSeverities] = useState(null)
    const [workflows, setWorkflows] = useState(null)
    const [isSiteEdit, setIsSiteEdit] = useState(true)
    const [isAlertTemplateEdit, setIsAlertTemplateEdit] = useState(true)
    const [isUserEdit, setIsUserEdit] = useState(true)
    const [isDeviceEdit, setIsDeviceEdit] = useState(true)
    const [isNotValidPassword, setIsNotValidPassword] = useState(true);
    const [changePassword, setChangePassword] = useState(false);
    const [isPassUpdated, setIsPassUpdated] = useState(false);
    const [userGroups, setUserGroups] = useState(null)
    const [filteredUserGroups, setFilteredUserGroups] = useState(null)
    const [siteGroups, setSiteGroups] = useState(null)
    const [selectedSiteGroup, setSelectedSiteGroup] = useState(siteGrpObj)
    const [filteredSiteGroups, setFilteredSiteGroups] = useState(null)
    const [sitesToBind, setSitestoBind] = useState(null) // Manage site groups - site list is changing according to selected site list and non assined site list
    const [selectedSites, setSelectedSites] = useState({})
    const [selectedUserRole, setSelectedUserRole] = useState(userOptions)
    const [siteUserFieldsVisibility, setSiteUserFieldsVisibility] = useState(false)
    const [selectedDataAccessGroupId, setSelectedDataAccessGroupId] = useState(null);
    const [usersToBind, setUserstoBind] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState({})
    const [parentIsEncoding, setParentIsEncoding] = useState(false);
    const [parentIsPlaybackEncoding, setParentIsPlaybackEncoding] = useState(false);
    const [parentIsEnablingTwoWayDevice, setParentIsEnablingTwoWayDevice] = useState(false);
    const [parentIsEnablingPTZ, setParentIsEnablingPTZ] = useState(false);
    const [parentIsEnablingUTCPlayback, setParentIsEnablingUTCPlayback] = useState(false);
    const [parentIsEnablingTwoWayInterface, setParentIsEnablingTwoWayInterface] = useState(false);
    const [parentIsEnablingPlaybackTimeStampsToUTC, setParentIsEnablingPlaybackTimeStampsToUTC] = useState(false);
    const [parentIsEnablingPlaybackLongDateFormat, setParentIsEnablingPlaybackLongDateFormat] = useState(false);
    const [parentIsEnablingInternalHooter, setParentIsEnablingInternalHooter] = useState(false);
    const[parentIsEnablingExternalHooter,setParentIsEnablingExternalHooter] = useState(false);
    const[parentPanelIsEnablingHooter,setParentPanelIsEnablingHooter]=useState(false);
    const [selectedUserTypeList, setselectedUserTypeList] = useState('User List')
    const [selectedSiteTypeList, setselectedSiteTypeList] = useState('Site List')
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [clients, setClients] = useState(null)
    const [ertLevels, setERTLevels] = useState(null)
    const [selectedERT, setSelectedERT] = useState(ertObj)
    const [emeContactList, setEmeContactList] = useState(null)
    const [isERTSectionVisible, setERTSectionVisible] = useState(false)
    const [country, setCountry] = useState(null)
    const [countryState, setCountryState] = useState(null)
    const [interfaceTypeData, setInterfaceTypeData] = useState(null)
    const [interfaceSubTypeData, setInterfaceSubTypeData] = useState(null)
    const [interfaceEntitySubTypeData, setInterfaceEntitySubTypeData] = useState(null)
    const [interfaceDataList, setInterfaceDataList] = useState(null)
    const [interfaceMainDataList, setInterfaceMainDataList] = useState(null)
    const [isInterfaceEdit, setIsInterfaceEdit] = useState(true)
    const [cctvTypeData,setCctvTypeData] = useState(null)
    const [notifSaveBtnClicked, setNotifSaveBtnClicked] = useState(false);
    const [notifDeleteBtnClicked, setNotifDeleteBtnClicked] = useState(false);
    const[siteTypes,setSiteTypes]=useState([])
    const [siteList,setSiteList]=useState([])
    const[deviceList,setDeviceList]=useState([])
    const[alertTemplateList,setalertTemplateList]=useState([])
    const[panelZoneType,setPanelZoneType]=useState([])
    const [bulkDevToUpload, setBulkDevToUpload] = useState([])
    const [isHooterEnabledInterface, setIsHooterEnabledInterface] = useState('')
    const [fcmToken, setFCMToken] = useState(null)
    const [selectedSite, setSelectedSite] = useState(siteObj)
    const [tempSelectedSite, setTempSelectedSite] = useState(siteObj);
    const [selectedUser, setSelectedUser] = useState(userObj)
    const [selectedTemplate, setSelectedTemplate] = useState(templateObj)
    const [selectedDevice, setSelectedDevice] = useState(deviceObj)
    const [isItemClick, setIsItemClick] = useState(false)
    const [selectedInterface, setSelectedInterface] = useState(interfaceObj)
    const [selectedSensor, setSelectedSensor] = useState(null)
    const [associatedDevices, setAssociatedDevices] = useState(null)
    const [associateCamSecVisibility, setAssociateCamSecVisibility] = useState(false)
    const [selectedItems, setSelectedItems] = useState([]); // to keep selected camera list associated to zone
    const [selectedZone, setselectedZone] = useState(zoneObj)
    const [pageState, setPageState] = useState(null)
    const [selectedSeverity, setSelectedSeverity] = useState(0)
    const [isFullTime, setIsFullTime] = useState(false)
    const [fromTime, setFromTime] = useState(new Date());
    const [toTime, setToTime] = useState(new Date());
    const [showWF, setShowWF] = useState(false)
    const [selectedWF, setSelectedWF] = useState(null)
    const [timezone,setTimeZone]=useState([])

    //#region Menu,subMenu & MainMenu start
       const [selectedSubMenu, setSelectedSubMenu] = useState('Site Configuration')
       const [selectedMenu, setSelectedMenu] = useState('Site Management')
       // Rupesh Revised Code
       const [selectedMainMenu, setSelectedMainMenu] = useState("")
       //#endregion Menu,subMenu & MainMenu end

    //#region panel bulk import
        const [selectedHeaders, setSelectedHeaders] = useState(panelHeadObj)
        const [bulkUpload, setBulkUpload] = useState(false) //for Bulk Upload section visibility
        const [isSingleOrBulkSelection, setIsSingleOrBulkSelection] = useState('single_device')
        const [clearImportedPanelHeaders, setClearImportedPanelHeaders] = useState(false)
        const [panelBulkUploadResponse, setPanelBulkUploadResponse] = useState([])
        const [panelBulkUploadError, setPanelBulkUploadError] = useState([])
        //#endregion panel bulk import
    
    //#region Camera Bulk upload start
        const [selectedCamHeaders, setSelectedCamHeaders] = useState(camHeadObj)
        const [clearImportedCamHeaders, setClearImportedCamHeaders] = useState(false)
        const [deviceBulkUploadResponse, setDeviceBulkUploadResponse] = useState([])
        const [importedFileData, setImportedFileData] = useState(null);
        const [importedFileHeaders, setImportedFileHeaders] = useState(null);
        const [importedPanelHeaders, setImportedPanelHeaders] = useState(null);
        
        //#endregion Camera bulk upload end

        //#region ACS Bulk Upload Start
        const [selectedAcsHeaders, setSelectedAcsHeaders] = useState(acsHeadObj)
        const [clearImportedAcsHeaders, setClearImportedAcsHeaders] = useState(false)
        const [acsBulkUploadResponse, setAcsBulkUploadResponse] = useState([])
        const [acsBulkUploadError, setAcsBulkUploadError] = useState([])
        const [importedAcsHeaders, setImportedAcsHeaders] = useState(null);
        //#endregion

        const [nodes, setNodes] = useState([]);
        const [isNodeSelected, setIsNodeSelected] = useState(false);
        const [selectedNode, setSelectedNode] = useState(null);
        const [disableAdd, setDisableAdd] = useState(false);
        const [reactFlowInstance, setReactFlowInstance] = useState(null);
    
        const [addNewClicked, setAddNewClicked] = useState(false);
        const [isNotifFormEnabled, setIsNotifFormEnabled] = useState(true);
        const [isEmptyForm, setIsEmptyForm] = useState(true);
        const [inEditMode, setInEditMode] = useState(false);
        const [showEditIcon, setShowEditIcon] = useState(false);
        const [rtspValueChanged, setRtspValueChanged] = useState(false)
    
        const [isAlertTempFormEnabled, setIsAlertTempFormEnabled] = useState(true);
        const [isEmptyFormAlert, setIsEmptyFormAlert] = useState(true);
        const [addNewClickedAlert, setAddNewClickedAlert] = useState(false);
        const [alertSaveBtnClicked, setAlertSaveBtnClicked] = useState(false);
        const [alertDeleteBtnClicked, setAlertDeleteBtnClicked] = useState(false);
        const [alertTemplateObj, setAlertTemplateObj] = useState(newAlertTemplateObj);
        const [totalTemplateCount, setTotalTemplateCount] = useState(0);
        const [totalDeviceCount, setTotalDeviceCount] = useState(0);
        const [isAlertUpdated, setIsAlertUpdated] = useState(false);
        const [deviceTemplateList, setDeviceTemplateList] = useState([]);
        const [devTemClicked, setDevTemClicked] = useState(false);
        const [activityLogShow, setActivityLogShow] = useState(false);
        const [selTempDevice, setSelTempDevice] = useState(null);
        const [customerData, setCustomerData] = useState([]);
        const [displayButtons, setDisplayButtons] = useState(true);
        const [isStore, setIsStore] = useState(false);
        const [zoneList, setZoneList] = useState([]);
        const [templateDelete, setTemplateDelete] = useState(false)
        const[unknownBrandUrl, setUnknownBrandUrl] = useState("")
        const [buttonDisable, setButtonDisable] = useState(false);    
        const [siteFormObj, setSiteFormObj] = useState({
            Customer: "",
            Site: "",
            Country: "",
            State: "",
            City: "",
            Region: "",
            Pincode: "",
            Address: "",
            Area: "",
            BranchID: "",
        })
        const [inputValidationError, setInputValidationError] = useState(null);
        const [savebtnClick, setSavebtnClick] = useState(false);
    //#endregion

    //#region Refs
    // In order to gain access to the child component instance,
    // you need to assign it to a `ref`, so we call `useRef()` to get one
    const siteRef = useRef()
    const alertTemplateRef = useRef()
    const userRef = useRef()
    let auth = useAuth();
    
    //#endregion
    //#endregion
    //#region global State start
    const { collapsed, setCollapsed } = useContext(DrawerContext)
    const { isOperatorWidgetClicked, setIsOperatorWidgetClicked } = useContext(ConversationStateContext)
    //#endregion global State end

    //#region useEffect start
    // useEffect(() => {
    //     onSubPageNavigate("Site Configuration")
    //     setSelectedSubMenu("Site Configuration")
    // }, [tempSelectedSite])

    useEffect(() => {
        if (selectedUser.UserID != 0) {
            onSubPageNavigate("Add User")
            setSelectedSubMenu("Add User")
        }
    }, [selectedUser])

    useEffect(() => {
        if (selectedTemplate.AlertTemplateId != 0) {
            onSubPageNavigate("Add Alert Template")
            setSelectedSubMenu("Add Alert Template")
        }
    }, [selectedTemplate])

    useEffect(() => {
        if (isOperatorWidgetClicked) {
            setSelectedMainMenu("Users")
            setSelectedSubMenu("Add User")
            setPageState("Add User")
        }
        return () => setIsOperatorWidgetClicked(false)
    }, [isOperatorWidgetClicked])

    useEffect((userUniqueId) => {
        (async () => {
            loggedInUser = JSON.parse(localStorage.getItem('user'))
            userid = localStorage.getItem('UserID')
            var token = await auth.getFCMToken()
            setFCMToken(token)
            await getidsmasterslist()
            getSites()
            getUsers()
            fetchGroups()
            AlertTemplateOperations(null, 0, "getAlertTemplateList")
            getMasterList()
            FetchSeverityTypeList()
            await getWorkflows()
            ChangesConfigTab()
            // fetchUserGroups() //09112021
            getsiteGroupList() //10112021
            // getAssignedNonAssignedSiteList()//10112021
            onSiteGroupSelection()
            // siteGroupOnClick()
            getCliets()
            getCountry()
            getERTLEvels()
            getInterfaceType()
            getInterfaceSubType()
            getCCTVTypeMasterData()
            getSiteType()
            GetAlertTemplateList("alert_template_list","1","","");
            PanelZoneType()
            getTimeZoneList()
        })();

    }, [])

    useEffect(() => {
        getInterfaceSubType(selectedInterface.InterfacetypeId);
    },[selectedInterface])

    useEffect(() => {
        let params = location?.state?.params
        if (params == null) {
            // setInitialTemplate(sidebarList.siteTemplate)
        } else if (params != 0) {
            // if (params ) {
            let currentTemplate = filteredEventTemplates?.filter(template => template.AlertTemplateId == params);
            if (currentTemplate?.length === 1) {
                getAlertTemplateById(params);
                onSelectTemplate(currentTemplate[0], true);
                setSelectedMenu('Alert Template')
                setSelectedSubMenu("Add Alert Template");
            }
            // }
            setInitialTemplate(sidebarList.alertTemplate)
            //find template by id and set selected template
            if (eventTemplates != null) {
                let data = eventTemplates.find(template => template.AlertTemplateId == params)
                if (data != undefined)
                    setdevicetypeid(data.DeviceTypeId)
                setSelectedTemplate(data)
            }

        }
        if (initTemplate == "users") {
            setInitialTemplate(sidebarList.users)
            // onSubPageNavigate("Add User")
        }
    }, [eventTemplates, filteredEventTemplates])

    //#endregion

    const onLoad = (_reactFlowInstance) => {
        setReactFlowInstance(_reactFlowInstance);
        // _reactFlowInstance.fitView()
    };
    const onArrange = (elements) => {
        try {
            let arrangedData = [];

            let data = elements.filter(element => element.type == "default" || element.type == "input");
            if (data.length == 0) return;

            let element = data[0];
            for (let i = 0; i <= data.length; i++) {
                let outnode = getOutgoers(element, elements)
                outnode.map((value, index, array) => {
                    let found = arrangedData.find(item => item.id == value.id)

                    if (found == undefined) {
                        element = value
                        arrangedData.push(value)
                    }
                })
            }

            let edges = elements.filter(element => element.type == "edge");

            let arrangedElements = [{
                id: '1',
                type: 'input',
                data: {
                    label: (
                        <> 
                            <div className='NodeTitleDescr'>
                                <p className='NodeHeading'>{nodes[0].name}</p>
                                <p className='NodeDescreption'>Sub Heading</p>
                            </div>
                            <div className='nodeDeleteIcon'>
                                <img style={{ width: "12px" }} />
                            </div>
                        </>
                    )
                },
                position: { x: 250, y: 5 },
                databaseId: nodes[0].id,
                parentId: nodes[0].parentId
            },
            ...arrangedData,
            ...edges
            ]
            // setArrangedData(arrangedElements);
            return arrangedElements;

        } catch (error) {
            console.log(error);
        }
    }

    const fetchPayload = () => {
        if (reactFlowInstance) {
            const flow = reactFlowInstance.toObject();
            return flow.elements;
            let data = {}
            //   let flowData = onArrange(flow.elements);
            //   if (flowData) {
            //       let finalFlowData = flowData.map((value, index, array) => {
            //         return value
            //       })
            //       flow.elements = finalFlowData
            // }
            data = {
                metadata: { ...flow, }
            }
            return data;
        }
    }

    const onSelectSite = async (siteid, isItemClick) => {
        setIsItemClick(isItemClick)
        if (siteid === 0 || isItemClick == false) {
            setSelectedSite(selectedSite);
            setIsSiteEdit(true)
            if (pageState != "Site Configuration")
                setPageState('Site Configuration')
            setSelectedERT(ertObj)
            setEmeContactList(null)
            return
        }
        //for already present site
        setERTSectionVisible(true)
        setSiteUserFieldsVisibility(false)
        setSelectedUserRole([])
        let site = sites.find(item => item.SiteID == siteid);
        if (site !== undefined) {
            try {
                getStateDetails(site.CountryId).then((resp) => {
                    if (resp != undefined) {
                        if (resp.status == "ok") {
                            setCountryState(resp.message)
                        } else {
                            setCountryState(null)
                        }
                    }
                })
            } 
            catch (error) {
                console.error(error)
            }
            setSelectedSite(site)
            setIsSiteEdit(false)
            setSelectedERT(ertObj) //clear ert form
            setSelectedInterface(interfaceObj);
            setEmeContactList(site.EmergencyContactList)
            return
        }
    }

    const onSelectInterface = async (interfaceid, isItemClick) => {
        setIsItemClick(isItemClick)
        if (interfaceid === 0 || isItemClick == false) {
            setSelectedInterface(selectedInterface);
            setIsInterfaceEdit(true)
            if (pageState != "Site Configuration")
                setPageState('Site Configuration')
            setSelectedERT(ertObj)
            setEmeContactList(null)
            return
        }
        //for already present site
        setERTSectionVisible(true)
        setSiteUserFieldsVisibility(false)
        setSelectedUserRole([])
        let interfaceDevice = interfaceDataList.find(
            item => item.InterfaceId == interfaceid)

        if (interfaceDevice !== 0) {
            setSelectedInterface(interfaceDevice)
            setIsInterfaceEdit(false)
            setSelectedERT(ertObj) //clear ert form
            return
        }
    }

    const showErrorToast = (msg) => {
        toast.error(msg, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const showSuccessToast = (msg) => {
        toast.success(msg, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const showWarningToast = (msg) => {
        toast.warn(msg, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    const onSaveSite = async (data, id) => {
        setButtonDisable(true)
        let clientID = data.clientID
        let sitename = data.SiteName
        let countryName = data.CountryName
        let stateName = data.StateName
        let cityName = data.CityName
        let siteRegionName = data.SiteRegionName
        let sitePincode = data.SitePincode
        let siteaddress = data.SiteAddress
        let siteaArea = data.SiteArea
        let branchid = data.BranchID
        let SiteModeID = parseInt(data.SiteModeID)
        let SiteMode = data.SiteMode
        
        // let siteFormVariable = siteFormObj
        let validationError = "";

        if (clientID === '' || clientID == null || clientID == 0) {
            // showErrorToast('Please select customer name!')
            validationError += "customer-Please select customer name!,";
            // return
            // siteFormVariable["Customer"] = "Please select customer name!"
        }
        if (sitename === '') {
            // showErrorToast('Please enter site name!');
            validationError += "site-Please enter site name!,";
            // return
            // siteFormVariable["Site"] = "Please enter site name!"

        }
        if (countryName === '' || countryName == null || countryName == 0 || countryName == undefined) {
            // showErrorToast('Please enter country name!');
            validationError += "country-Please select country name!,"
            // return
            // siteFormVariable["Country"] = "Please select country name!"
        }
        if (stateName === '' || stateName == null || stateName == 0 || stateName == undefined) {
            // showErrorToast('Please enter state name!');
            validationError += "state-Please select state name!,"
            // return
            // siteFormVariable["State"] = "Please select state name!"
        }
        if (cityName === '' || cityName === undefined) {
            // showErrorToast('Please enter city name!');
            validationError += "city-Please enter city name!,"
            // return
        // siteFormVariablesiteFormObj["City"] = "Please enter city name!"
        }
        if (siteRegionName === '' || siteRegionName === undefined) {
            // showErrorToast('Please enter region name!');
            validationError += "region-Please enter region name!,"
            // return
            // siteFormVariable["Region"] = "Please enter region name!"
        }
        if (sitePincode === '' || sitePincode === undefined) {
            // showErrorToast('Please enter pincode!');
            validationError += "pincode-Please enter pincode!,"
            // return
            // siteFormVariable["Pincode"] = "Please enter pincode!"
        }
        if (siteaddress === '' || siteaddress === undefined) {
            // showErrorToast('Please enter address!');
            validationError += "address-Please enter address!,"
            // return
            // siteFormVariable["Address"] = "Please enter address!"
        }
        if (siteaArea === '' || siteaArea === undefined) {
            // showErrorToast('Please enter area!');
            validationError += "area-Please enter area!,"
            // return
            // siteFormVariable["Area"] = "Please enter area!"
        }
        if (branchid === '' || branchid === undefined) {
            // showErrorToast('Please enter branch ID!');
            validationError += "branchid-Please enter branch ID!,"
            // return
            // siteFormVariable["BranchID"] = "Please enter branch ID!"
        }
        if (checkspecialcharacters(sitename)) {
            showErrorToast('Special characters are not allowed in site name!');
            setButtonDisable(false)
            return
        }
        setInputValidationError(validationError);
        setSavebtnClick(true)
        if (validationError != "") {
            setButtonDisable(false)
            return;
        }
        let myCurrentDatetime = new Date();
        let datetime = ConvertDate(myCurrentDatetime)

        var _lat = 0
        var _long = 0
        var objNearByResources = {
            "SearchPlace": siteaddress,
            "radius": 0,
            "lat": 0,
            "long": 0,
            "SearchType": "PlaceDetails"
        }
        await nearbyresource(objNearByResources).then((resp1) => {
            if (resp1.status == "Ok") {
                if (resp1.message.length > 0) {
                    _lat = resp1.message[0].siteLattitude
                    _long = resp1.message[0].siteLongitude
                }
            }
        })

        let tempCountryName = country.filter(item => {
            return item.CountryName == countryName
        })

        let tempCountryStateName = countryState.filter(item => item.StateName === stateName)
        if (id == 0) {
            try {
                var createdtime = ConvertDateToUTC(myCurrentDatetime)
                mixpanel.track("Clicks on Add Sites", {
                    "SiteName": sitename,
                    "BranchID": branchid,
                    "SiteAddress": siteaddress,
                    "clientID": clientID,
                    "CreatedOn": createdtime
                })
            } catch (error) {
                console.log(error)
            }

            let obj = {
                "action": "addSiteDetails",
                "UserID": loggedInUser.UserID || loggedInUser.userId,
                "clientID": clientID,
                "SiteName": sitename,
                "countryMasterId": tempCountryName[0].CountryMasterId,
                "stateId": tempCountryStateName[0].StateID,
                "city": cityName,
                "region": siteRegionName,
                "pincode": sitePincode,
                "SiteAddress": siteaddress,
                "area": siteaArea,
                "BranchID": branchid,
                "Lattitude": _lat,
                "Longitude": _long,
                "EmergencyContactList": [],
                "CreatedOn": datetime,
                "SiteModeID":SiteModeID
            }
            if(SiteModeID != 0){
                Swal.fire({
                    title: 'Note',
                    text: "You are adding this site as a retail store, once added you will not be able to change it.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#027aaa',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok!',
                    allowOutsideClick: false    
                }).then((result) => {
                    if(result.isConfirmed){
                        Addsitedetails(obj).then((resp) => {
                            if (resp.status === "Ok") {
                                getSites()
                                showSuccessToast(resp.message)
                                setERTSectionVisible(true)
                                setSiteUserFieldsVisibility(false)
                                setSelectedUserRole([])
                                setEmeContactList(null);
                                setButtonDisable(false)
                                let tempObj = {
                                    "SiteID": resp.siteObj[0].SiteID,
                                    "SiteName": resp.siteObj[0].SiteName,
                                    "BranchID": resp.siteObj[0].BranchID,
                                    "Address": resp.siteObj[0].SiteAddress,
                                    "clientID": resp.siteObj[0].clientID,
                                    "AddCustomerName": '',
                                    "CountryId": tempCountryName[0].CountryMasterId,
                                    "StateId": tempCountryStateName[0].StateID,
                                    "City": cityName,
                                    "Region": siteRegionName,
                                    "pincode": sitePincode,
                                    "Area": siteaArea,
                                    "SiteModeID" : SiteModeID,
                                }
                                setSelectedSite(tempObj)
                                setIsSiteEdit(false)
                                setSelectedERT(ertObj) //clear ert form
                                window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
                                
                                setTimeout(() => {
                                    let siteDiv = document.getElementById('siteListDiv');
                                    siteDiv.scrollTo({
                                        top: siteDiv.scrollHeight,
                                        behavior: 'smooth'
                                    })    
                                }, 1000);
                            }
                            else {
                                showErrorToast(resp.message)
                                setButtonDisable(false);
                            }
                        })
                    }
                }).catch(err => console.log(err));
            }else{
                Addsitedetails(obj).then((resp) => {
                    if (resp.status === "Ok") {
                        getSites()
                        showSuccessToast(resp.message)
                        setERTSectionVisible(true)
                        setSiteUserFieldsVisibility(false)
                        setSelectedUserRole([])
                        setEmeContactList(null);
                        setButtonDisable(false)
                        let tempObj = {
                            "SiteID": resp.siteObj[0].SiteID,
                            "SiteName": resp.siteObj[0].SiteName,
                            "BranchID": resp.siteObj[0].BranchID,
                            "Address": resp.siteObj[0].SiteAddress,
                            "clientID": resp.siteObj[0].clientID,
                            "AddCustomerName": '',
                            "CountryId": tempCountryName[0].CountryMasterId,
                            "StateId": tempCountryStateName[0].StateID,
                            "City": cityName,
                            "Region": siteRegionName,
                            "pincode": sitePincode,
                            "Area": siteaArea,
                            "SiteModeID" : SiteModeID,
                        }
                        setSelectedSite(tempObj)
                        setIsSiteEdit(false)
                        setSelectedERT(ertObj) //clear ert form
                        window.scrollTo({ top: 500, left: 0, behavior: 'smooth' });
                        setTimeout(() => {
                            let siteDiv = document.getElementById('siteListDiv');
                            siteDiv.scrollTo({
                                top: siteDiv.scrollHeight,
                                behavior: 'smooth'
                            })    
                        }, 1000);
                    }
                    else {
                        showErrorToast(resp.message)
                        setButtonDisable(false)
                    }
                })
            }
            
        } else {
            //update
            //Check if siteid was set or not before
            var obj = {
                "UserID": loggedInUser.UserID || loggedInUser.userId,
                "SiteID": id,
                "clientID": clientID,
                "SiteName": sitename,
                "countryMasterId": tempCountryName[0].CountryMasterId,
                "stateId": tempCountryStateName[0].StateID,
                "city": cityName,
                "region": siteRegionName,
                "pincode": sitePincode,
                "SiteAddress": siteaddress,
                "area": siteaArea,
                "BranchID": branchid,
                "Lattitude": _lat,
                "Longitude": _long,
                "UpdatedOn": datetime,
                "SiteModeID": SiteModeID,
               // "SiteMode" : SiteMode
            }
            if(selectedSite.SiteModeID == null && (SiteModeID != 0)){
                // It means that we are changing the site type to retail.
                Swal.fire({
                    title: 'Note',
                    text: "You are updating this site as a retail store, once updated you will not be able to change it.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#027aaa',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok!',
                    allowOutsideClick: false    
                }).then((result) => {
                    if(result.isConfirmed){
                        Updatesitedetails(obj).then((resp) => {
                            if (resp.status === "Ok") {
                                setIsSiteEdit(false)
                                getSites()
                                showSuccessToast(resp.message)
                                setButtonDisable(false)
                            }
                            else {
                                showErrorToast(resp.message)
                                setButtonDisable(false)
                            }
                        })
                    }   
                }).catch(err => console.log(err))
            }else{
                Updatesitedetails(obj).then((resp) => {
                    if (resp.status === "Ok") {
                        setIsSiteEdit(false)
                        getSites()
                        setButtonDisable(false)
                        showSuccessToast(resp.message)
                    }
                    else {
                        showErrorToast(resp.message)
                        setButtonDisable(false)
                    }
                })
            }

        }
    }

    const onInterfaceFormSubmit = async (interfaceDetailsData, id,rtspValueEntered,playbackValueEntered) => {
        setButtonDisable(true);
        let interfaceTwoWayEnabled;
        let playbackTimeStampsToUTCEnabled;
        let playbackLongDateFormatEnabled;
        if(parentIsEnablingTwoWayInterface){
            interfaceTwoWayEnabled = "1"
        }else{
            interfaceTwoWayEnabled = "0";
        }
        let interfaceInternalHooter;
        if (parentIsEnablingInternalHooter){
            interfaceInternalHooter = "1"
        }else {
            interfaceInternalHooter = "0";
        }

        let interfaceExternalHooter;
        if (parentIsEnablingExternalHooter){
            interfaceExternalHooter = "1"
        }else {
            interfaceExternalHooter = "0";
        }

        let interfacePanelEnableHooter;
        if(parentPanelIsEnablingHooter){
            interfacePanelEnableHooter = "1"
        }else{
            interfacePanelEnableHooter = "0";
        }
        if(parentIsEnablingPlaybackTimeStampsToUTC){
            playbackTimeStampsToUTCEnabled = "1"
        }else{
            playbackTimeStampsToUTCEnabled = "0";
        }

        if(parentIsEnablingPlaybackLongDateFormat){
            playbackLongDateFormatEnabled = "1"
        }else{
            playbackLongDateFormatEnabled = "0";
        }

        let interfaceName = interfaceDetailsData.InterfaceName
        let interfaceType = interfaceDetailsData.InterfaceType
        let interfaceTypeId = interfaceDetailsData.InterfaceTypeId
        let interfaceSubType = interfaceDetailsData.InterfaceSubType
        let interfaceSubtTypeId = interfaceDetailsData.InterfaceSubTypeId
        let interfaceEntitySubType = interfaceDetailsData.EntityTypeID
        let interfaceIP = interfaceDetailsData.InterfaceIP
        let interfacePort = interfaceDetailsData.InterfacePort
        let interfaceUsername = interfaceDetailsData.InterfaceUsername
        let interfacePassword = interfaceDetailsData.InterfacePassword
        let liveStreamURL = rtspValueEntered
        let playbackStreamURL = playbackValueEntered
        let UserId = localStorage.getItem("UserID");
        let interfaceTimeZoneId = interfaceDetailsData.TimeZoneId;

        let validationError = "";

        if (interfaceName === '' || interfaceName == null || interfaceName === 0) {
            // showErrorToast('Please enter interface name!')
            // return
            validationError += "interface-Please select interface name!,";
        }
        if (interfaceType === '' || interfaceType == null || interfaceType == 0 || interfaceType == undefined) {
            // showErrorToast('Please enter interface type!');
            // return
            validationError += "interface type-Please select interface type!,"
        }

        if (interfaceSubType === '' || interfaceSubType == null || interfaceSubType == 0 || interfaceSubType == undefined) {
            // showErrorToast('Please enter interface sub type!');
            // return
            validationError += "interface sub type-Please select interface sub type!,"
        }
       
        console.log("validationError", interfaceType)
        if (interfaceType !== "Alarm Panel" && interfaceType !== "ACS") {
            if (interfaceEntitySubType == undefined || interfaceEntitySubType == '' || interfaceEntitySubType == 0 || interfaceEntitySubType == "Entity Subtype*") {
                // showErrorToast('Please select entity subtype!');
                // return;
                validationError += "interface entity subtype-Please select entity subtype!,"
            }
        }
        if (interfaceIP === '' || interfaceIP === undefined) {
            // showErrorToast('Please enter interface IP!');
            // return
            validationError += "interface IP-Please enter interface IP!,"
        } else {
            const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    
            if (pattern.test(interfaceIP) == false) {
                // showErrorToast('Please enter valid interface IP!');
                // return
                validationError += "interface IP-Please enter Valid interface IP!,"
            }
        }
        if (interfacePort === '' || interfacePort === undefined) {
            // showErrorToast('Please enter interface port!');
            // return
            validationError += "interface port-Please enter interface port!,"
        }
        if (interfaceUsername === '' || interfaceUsername === undefined) {
            // showErrorToast('Please enter interface username!');
            // return
            validationError += "interface username-Please enter interface username!,"
        }
        if (interfacePassword === '' || interfacePassword === undefined) {
            // showErrorToast('Please enter interface password!');
            // return
            validationError += "interface password-Please enter interface password!,"
        }
        if(MULTI_TIME_ZONE){
            if(interfaceTimeZoneId == undefined || interfaceTimeZoneId == 0){
                validationError += "interface timezone-Please select timezone!,"
            }     
        }
        if ((interfaceSubType == 'Unknown' && liveStreamURL === '') || (interfaceSubType == 'Unknown' &&liveStreamURL === undefined)) {
            // showErrorToast('Please enter live stream url!');
            // return
            validationError += "interface livestream format-Please enter live stream url!,"
        }
        if ((interfaceSubType == 'Unknown' && playbackStreamURL === '') || (interfaceSubType == 'Unknown' &&playbackStreamURL === undefined)) {
            // showErrorToast('Please enter playback stream url!');
            // return
            validationError += "interface playbackstream format-Please enter playback stream url!,"
        }
        // if (checkspecialcharacters(interfaceName)) {
        //     showErrorToast('Special characters are not allowed in interface name!');
        //     return
        // }
        console.log("validationError", validationError)
        setInputValidationError(validationError);
        setSavebtnClick(true)
        if (validationError != "") {
            setButtonDisable(false);
            return;
        }

        let tempInterfaceType = interfaceTypeData.filter(item => {
            return item.Interfacetypename === interfaceType
        })

        let tempInterfaceSubType = interfaceSubTypeData.filter(item => {
            return item.Interfacesubtypename === interfaceSubType
        })

        let entitySubType = ""
        if (interfaceType !== "Alarm Panel" && interfaceType !== "ACS") {

            let tempEntitySubType = cctvTypeData.filter(item => {
                return item.CCTVTypeID == interfaceEntitySubType
            })
            entitySubType = tempEntitySubType[0].CCTVTypeID
        }
        if (interfaceType == "CCTV" && interfaceDetailsData.CCTVType == "Camera") {
            if (interfaceInternalHooter == "0" && interfaceExternalHooter == "0") {

                interfacePanelEnableHooter = "0"
            } else {
                interfacePanelEnableHooter = "1"
            }
        }
        if (id === 0) {
            let addUpdateInterfaceDetailsObj = {
                "InterfaceName": interfaceName,
                "InterfacetypeId": tempInterfaceType[0].InterfacetypeId,
                "InterfacesubtypeId": tempInterfaceSubType[0].InterfacesubtypeId,
                "InterfaceIP": interfaceIP,
                "InterfacePort": interfacePort,
                "UserName": interfaceUsername,
                "Password": interfacePassword,
                "siteid": selectedSite.SiteID,
                "Interfacetypename": interfaceType,
                "Interfacesubtypename": interfaceSubType,
                "UserId": UserId,
                "IsTwoWayEnabled": interfaceTwoWayEnabled,
                "CheckInternalHooter": interfaceInternalHooter,
                "CheckExternalHooter":interfaceExternalHooter,
                "IsHooterEnabled":interfacePanelEnableHooter,
                "CheckPlaybackUTCTime" : playbackTimeStampsToUTCEnabled,
                "CheckPlaybackLongTime":playbackLongDateFormatEnabled,
                "LiveRTSPURL": liveStreamURL,
                "PlaybackRTSPURL": playbackStreamURL,
                "EntityTypeID": entitySubType,
                "TimeZoneId": interfaceTimeZoneId
            }
            addInterfaceDetails(addUpdateInterfaceDetailsObj).then((resp) => {
                if (resp.Status === "success") {
                    let tempEntitySubType
                    let entitySubType = 0
                    let entityValue = ""
                    if (tempInterfaceType[0].InterfacetypeId == 1) {
                        tempEntitySubType = cctvTypeData.filter(item => {
                            return item.CCTVTypeID == interfaceEntitySubType
                        })
                        entitySubType = tempEntitySubType[0].CCTVTypeID
                        entityValue = tempEntitySubType[0].CCTVType
                    }
                    let tempInterfaceObj = {
                        "InterfaceId": resp.Data,
                        "InterfaceName": interfaceName,
                        "InterfacetypeId": tempInterfaceType[0].InterfacetypeId,
                        "InterfacesubtypeId": tempInterfaceSubType[0].InterfacesubtypeId,
                        "InterfaceIP": interfaceIP,
                        "InterfacePort": interfacePort,
                        "UserName": interfaceUsername,
                        "Password": interfacePassword,
                        "siteid": selectedSite.SiteID,
                        "Interfacetypename": interfaceType,
                        "Interfacesubtypename": interfaceSubType,
                        "UserId": UserId,
                        "IsTwoWayEnabled": interfaceTwoWayEnabled,
                        "CheckInternalHooter": interfaceInternalHooter,
                        "CheckExternalHooter": interfaceExternalHooter,
                        "IsHooterEnabled": interfacePanelEnableHooter,
                        "CheckPlaybackUTCTime": playbackTimeStampsToUTCEnabled,
                        "CheckPlaybackLongTime": playbackLongDateFormatEnabled,
                        "LiveRTSPURL": liveStreamURL,
                        "PlaybackRTSPURL": playbackStreamURL,
                        "EntityTypeID": entitySubType,
                        "CCTVType": entityValue,
                        "TimeZoneId": interfaceTimeZoneId
                    }
                    getInterfaceData()
                    setButtonDisable(false);
                    showSuccessToast(resp.Message)
                    setSelectedInterface(tempInterfaceObj)
                    setIsInterfaceEdit(false)
                    setParentPanelIsEnablingHooter(false)
                }
                else {
                    showErrorToast(resp.Message)
                    setButtonDisable(false);
                }
            })
        } else {
            let tempEntitySubType
            let entitySubType = ""
            let entityValue = ""
            if (tempInterfaceType[0].InterfacetypeId == 1) {
                tempEntitySubType = cctvTypeData.filter(item => {
                    return item.CCTVTypeID == interfaceEntitySubType
                })
                entitySubType = tempEntitySubType[0].CCTVTypeID
                entityValue = tempEntitySubType[0].CCTVType
            }
            let addUpdateInterfaceDetailsObj = {
                "InterfaceId": id,
                "InterfaceName": interfaceName,
                "InterfacetypeId": tempInterfaceType[0].InterfacetypeId,
                "InterfacesubtypeId": tempInterfaceSubType[0].InterfacesubtypeId,
                "InterfaceIP": interfaceIP,
                "InterfacePort": interfacePort,
                "UserName": interfaceUsername,
                "Password": interfacePassword,
                "siteid": selectedSite.SiteID,
                "Interfacetypename": interfaceType,
                "Interfacesubtypename": interfaceSubType,
                "UserId": UserId,
                "IsTwoWayEnabled": interfaceTwoWayEnabled,
                "CheckInternalHooter": interfaceInternalHooter,
                "CheckExternalHooter":interfaceExternalHooter,
                "IsHooterEnabled":interfacePanelEnableHooter,
                // "IsTwoWayEnabled": interfaceTwoWayEnabled,
                "CheckPlaybackUTCTime" : playbackTimeStampsToUTCEnabled,
                "CheckPlaybackLongTime":playbackLongDateFormatEnabled,
                "LiveRTSPURL": liveStreamURL,
                "PlaybackRTSPURL": playbackStreamURL,
                "EntityTypeID":  entitySubType,
                "TimeZoneId": interfaceTimeZoneId
            }
            updateInterfaceDetails(addUpdateInterfaceDetailsObj).then((resp) => {
                if (resp.Status === "success") {
                    let objInterface = {
                        "InterfaceId": id,
                        "InterfaceName": interfaceName,
                        "InterfacetypeId": tempInterfaceType[0].InterfacetypeId,
                        "InterfacesubtypeId": tempInterfaceSubType[0].InterfacesubtypeId,
                        "InterfaceIP": interfaceIP,
                        "InterfacePort": interfacePort,
                        "UserName": interfaceUsername,
                        "Password": interfacePassword,
                        "siteid": selectedSite.SiteID,
                        "Interfacetypename": interfaceType,
                        "Interfacesubtypename": interfaceSubType,
                        "UserId": UserId,
                        "IsTwoWayEnabled": interfaceTwoWayEnabled,
                        "CheckInternalHooter": interfaceInternalHooter,
                        "CheckExternalHooter": interfaceExternalHooter,
                        "IsHooterEnabled": interfacePanelEnableHooter,
                        // "IsTwoWayEnabled": interfaceTwoWayEnabled,
                        "CheckPlaybackUTCTime": playbackTimeStampsToUTCEnabled,
                        "CheckPlaybackLongTime": playbackLongDateFormatEnabled,
                        "LiveRTSPURL": liveStreamURL,
                        "PlaybackRTSPURL": playbackStreamURL,
                        "EntityTypeID": entitySubType,
                        "CCTVType": entityValue,
                        "TimeZoneId": interfaceTimeZoneId
                    }
                    setSelectedInterface(objInterface)
                    setButtonDisable(false);
                    getInterfaceData()
                    showSuccessToast(resp.Message)
                    setIsInterfaceEdit(false)
                }
                else {
                    showErrorToast(resp.Message)
                    setButtonDisable(false);
                }
            })
        }
    }

    const onSelectTemplate = async (template, isTemplateItemSelected) => {
        setPageState('Add Alert Template')
        setIsItemClick(isTemplateItemSelected) // fill form on click  - true
        if (isAlertTemplateEdit) {
            if (alertTemplateRef.current != undefined || alertTemplateRef.current != null) {
                if (alertTemplateRef.current.isDataChanged()) {
                    await Swal.fire({
                        title: 'Are you sure?',
                        text: "Do you want to cancel the changes made?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#027aaa',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Leave!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            selectTemplate(template)
                            setIsAlertTempFormEnabled(false);
                            setIsEmptyFormAlert(false);
                        }
                    })
                } else {
                    selectTemplate(template)
                    setIsAlertTempFormEnabled(false);
                    setIsEmptyFormAlert(false);
                }
            } else {
                selectTemplate(template)
                setIsAlertTempFormEnabled(false);
                setIsEmptyFormAlert(false);
            }
        } else {
            selectTemplate(template)
            setIsAlertTempFormEnabled(false);
            setIsEmptyFormAlert(false);
        }
    }

    const selectTemplate = (template) => {
        if (template.AlertTemplateId == 0) {
            setIsAlertTemplateEdit(true)
        } else {
            setIsAlertTemplateEdit(false)
        }
        setdevicetypeid(template.DeviceTypeId)
        setSelectedTemplate(template)
    }
    //#region API consumptions

    const getidsmasterslist = async () => {
        try {
            var obj = {
                "action": "getDevicesMasterList",
                "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID
            }
            await deviceconfigcrud(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message != null) {
                        setdevicesmasterlist(resp.message)
                    }
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getCustomerData = (deviceTypeId, alertType, showAll) => {
        try {
            getCustomerList(deviceTypeId, alertType, showAll)
                .then((res) => {
                    if(res.Status == "success"){
                        setCustomerData(res.Data);
                    }
                })
                .catch(err => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    const getSites = () => {
        try {
            Getsitelist().then((resp) => {
                if (resp != undefined) {
                    if (resp.status == "Ok") {
                        setSites(resp.message)
                        setFilteredSites(resp.message)
                        // setSitestoBind(resp.message) //10112021
                    } else {
                        setSites(null)
                        // setSitestoBind(null) //10112021
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const getCliets = () => {
        try {
            var obj = {
                "action": "getClients"
            }
            Addsitedetails(obj).then((resp) => {
                if (resp != undefined) {
                    if (resp.status == "Ok") {
                        setClients(resp.message)
                    } else {
                        setClients(null)
                        // setSitestoBind(null) //10112021
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const getCountry = () => {
        try {
            getCountryDetails().then((resp) => {
                if (resp != undefined) {
                    if (resp.status == "Ok") {
                        setCountry(resp.message)
                    } else {
                        setCountry(null)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const getSiteType = () => {
        try {
            Getsitetypelist().then((resp) => {
                //debugger
                if (resp != undefined) {
                  if (resp.Status == "success") {
                    setSiteTypes(resp.Data)
                  } else {
                    setSiteTypes(null)
                  }
                }
              })
        } catch (error) {
            console.error(error)
        }
    }

const getSiteList = (id,deviceTypeId,alertType, showAll, isStore) =>{
        try{
            SiteList(id,deviceTypeId,alertType, showAll).then((resp) => {
                if (resp != undefined) {
                    if (resp.Status == "success") {
                        if(isStore){
                            let tempSite = resp.Data.map((site) => ({
                                value: site.SiteId,
                                label: site.SiteName,
                                disabled: true
                            }))
                            setSiteList(tempSite)
                        }else{
                            let tempSite
                            if(deviceTemplateList.length > 0){
                                tempSite = resp.Data.map((site) => ({
                                    value: site.SiteId,
                                    label: site.SiteName,
                                    disabled: true
                                }))
                            }else{
                                tempSite = resp.Data.map((site) => ({
                                    value: site.SiteId,
                                    label: site.SiteName
                                }))
                            } 
                            setSiteList(tempSite)    
                        }
                    } else {
                        setSiteList([])
                    }
                  }
            })
        }  catch (error) {
            console.error(error)
        }
    }

const getDeviceList = (siteIds,deviceTypeId,IDSPanelZoneTypeID, alertType, showAll, selectedSites, devTemList, isStore) => {
   // debugger
    let checkType
    if(siteIds.length > 0){
        let checkType = typeof siteIds[0];
    }
    let siteString
    if(typeof siteIds[0] == "string"){
        siteString = siteIds.map((item) => {
            return item;
        }).join(',');
    }else{
        siteString = siteIds.map((item) => item.value).join(',');
    } 
    try {
        GetDeviceList(siteString,deviceTypeId,IDSPanelZoneTypeID, alertType, showAll).then ((resp) => {
            if (resp != undefined) {
                if (resp.Status == "success") {
                    let tempDevice;
                    if(devTemList.length > 0){
                        if(isStore){
                            tempDevice = resp.Data.map((site) => ({
                                value: site.DeviceId,
                                label: site.CameraName,
                                disabled: true
                            }))
                        }else{
                            tempDevice = resp.Data.map((site) => {
                                let obj;
                                if(selTempDevice.Id == site.DeviceId){
                                    obj = {
                                        value: site.DeviceId,
                                        label: site.CameraName,
                                        disabled: false
                                    }
                                }else{
                                    obj = {
                                        value: site.DeviceId,
                                        label: site.CameraName,
                                        disabled: true
                                    }
                                }
                                return obj;
                            })
                        }
                    }else{
                        if(isStore){
                            tempDevice = resp.Data.map((site) => ({
                                value: site.DeviceId,
                                label: site.CameraName,
                                disabled: true
                            }))
                        }else{
                            tempDevice = resp.Data.map((site) => ({
                                value: site.DeviceId,
                                label: site.CameraName,
                                disabled: false
                            }))    
                        }
                    }
                    setDeviceList(tempDevice)
                } else {
                    setDeviceList([])
                }
              }
        })
    }catch (error){
        console.error(error)
    }
}

const PanelZoneType = () => {
    try {
        getPanelZoneType().then((resp) =>{
            if (resp != undefined) {
                if (resp.Status == "success") {
                  setPanelZoneType(resp.Data)
                } else {
                    setPanelZoneType(null)
                }
              }
            
        }) 
    } catch (error) {
        console.error(error)
    
    }
}
const getTimeZoneList = () => {
    try {
        GetmultiTimeZone().then((resp) => {
            //debugger
            if (resp != undefined) {
              if (resp.Status == "success") {
                setTimeZone(resp.Data)
              } else {
                setTimeZone(null)
              }
            }
          })
    } catch (error) {
        console.error(error)
    }
}
const GetAlertTemplateList = (pType,pageNumber,searchText,filterText,tabClicked) =>{
    try {
        GetAlertTemplate(pType,pageNumber,searchText,filterText).then((resp) => {     
            if (resp != undefined) {
              if (resp.Status == "success") {
                setTemplateDelete(false)
                setalertTemplateList(resp.Data.LstTemplate)
                if(pType == "alert_template_list"){
                    setTotalTemplateCount(resp.Data.TotalLength)
                }else{
                    setTotalDeviceCount(resp.Data.TotalLength)
                }
                if(tabClicked){
                       if(resp.Data.LstTemplate.length > 0){
                        setDevTemClicked(true)
                        setDisplayButtons(false); 
                        getAlertTemplateByDeviceId(resp.Data.LstTemplate[0].Id)
                        setSelTempDevice(resp.Data.LstTemplate[0]);
                       }
                }
              } else {
                setalertTemplateList([])
              }
            }
          })
    } catch (error) {
        console.error(error)
    }
}

const getAlertTemplateById = (id) => {
    try {
        getAlertTemplateByIdAPI(id)
            .then((res) => {
                if(res.Status == "success"){
                    let obj = {
                        templateId : res.Data.AlertTemplateId,
                        templateName: res.Data.TemplateName,
                        templateClientId: res.Data.ClientId,
                        templateSiteId: [],
                        templateDeviceTypeId: res.Data.DeviceTypeId,
                        templateDeviceSubTypeId: res.Data.LstIDSPanelZoneTypeId.length > 0 ? res.Data.LstIDSPanelZoneTypeId[0] : "0",
                        templateDevice: [],
                        templateAlertType: res.Data.AlertCodeId,
                        scheduleType: res.Data.IsAlertFlag,
                        schedulerObj : JSON.parse(res.Data.ScheduleMetadata),
                        tempSelectedSites: res.Data.LstSiteId,
                        tempSelectedDevices:res.Data.LstDeviceId
                    }
                    setAlertTemplateObj(obj);
                    setIsAlertTempFormEnabled(false);
                    setIsEmptyFormAlert(false);
                    setIsAlertTemplateEdit(false);
                    setDevTemClicked(false);
                    if(res.Data.IsMobileAlert == "True"){
                        setIsStore(true);
                    }else{
                        setIsStore(false);
                    }
                    // if(false){
                    //     setIsStore(true);
                    // }  
                }
            })
            .catch(err => {
                console.log(err);
            })
    } catch (error) {
        console.log(error);
    }
}

const getAlertTemplateByDeviceId = (id) => {
    try {
        getAlertTemplateByDeviceIdAPI(id)
            .then((res) => {
                if(res.Status == "success"){
                    setDeviceTemplateList(res.Data);
                    setDevTemClicked(true);
                    setDisplayButtons(false);
                    setIsAlertTempFormEnabled(false);
                }
            })
            .catch(err => {
                console.log(err);
            })
    } catch (error) {
        console.log(error);
    }
}

    const getERTLEvels = () => {
        try {
            var obj = {
                "action": "getERTLevels"
            }
            Addsitedetails(obj).then((resp) => {
                if (resp != undefined) {
                    if (resp.status == "Ok") {
                        setERTLevels(resp.message)
                    } else {
                        setERTLevels(null)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const getdeviceslistdata = (action) => {
        try {
            var obj = {
                "action": "get",
                "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID,
                "interfaceId": selectedInterface.InterfaceId
            }
            deviceconfigcrud(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message != null) {
                        if (resp.message.length > 0) {
                            var acsdev = resp.message.filter(x => x.DeviceTypeID == 3 || x.DeviceTypeID == 4 || x.DeviceTypeID == 5)
                            var paneldev = resp.message.filter(x => x.DeviceTypeID == 2)
                            var cameradev = resp.message.filter(x => x.DeviceTypeID == 1)
                            if(resp.message[0].DeviceTypeID == 2){
                            onDeviceSelection(resp.message[0])
                            }
                            setDeviceslist(resp.message)
                            setIsHooterEnabledInterface(resp.message[0].isHooterEnabledInterface)
                            setcameradeviceslist(cameradev)
                            setCameraMainDevicesList(cameradev);
                            setpaneldeviceslist(paneldev)
                            setPanelMainDevicesList(paneldev);
                            setAcsDevicesList(acsdev)
                            setAcsMainDevicesList(acsdev);
                            if (action && action == 'addPanelDevice') {
                                if (deviceObj && deviceObj.ParentCameraID) {
                                    var currentPanel = paneldev.find(x => x.ParentCameraID == deviceObj.ParentCameraID)
                                    onDeviceSelection(currentPanel)
                                }
                            }
                            if (paneldev && selectedDevice.ParentCameraID != 0) { //set updated zone details on update zone
                                var ispanelExist = paneldev.find(x => x.ParentCameraID == selectedDevice.ParentCameraID)
                                if (ispanelExist) {
                                    setSelectedDevice(ispanelExist)
                                    if (selectedZone) {
                                        var setZone = ispanelExist.zonesList.find(x => x.idsPanelZonesID == selectedZone.idsPanelZonesID)
                                        if (setZone) {
                                            setselectedZone(setZone)
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        setDeviceslist(null)
                        setcameradeviceslist(null)
                        setCameraMainDevicesList(null);
                        setpaneldeviceslist(null)
                        setAcsDevicesList(null)
                        setAcsMainDevicesList(null);
                        setPanelMainDevicesList(null);
                    }
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getUsers = async () => {
        try {
            var obj = {
                "action": "getUserList",
                "userId": loggedInUser?.UserID
            }
            await Getuserslist(obj).then(async (resp) => {
                let templist = []
                if (resp.status == "Ok") {
                    templist = resp.message
                    setUsers(templist)
                    setFilteredUsers(templist)
                } else if (resp.Status === "success") {
                    setUsers(resp.Data);
                    setFilteredUsers(resp.Data);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const fetchGroups = async () => {
        let response = await GetPrivilegeGroupList(userid)
        if (response && response.status == "Ok") {
            setGroups(prev => response.message)
            return response.message
        }
    }

    const getEventTemplatesList = () => {
        GetEventTemplateList().then((resp) => {
            if (resp.length > 0) {
                setEventTemplates(resp)
                setFilteredEventTemplates(resp)
            }
        })
    }

    const getMasterList = async () => {
        let obj = {
            "action": "getMasterTableList",
            "UserUniqueID": loggedInUser.UserUniqueID || loggedInUser.userUniqueID
        }
        await Getmastertablelist(obj).then((resp) => {
            if (resp.status == "Ok") {
                setAlertCodes(resp.message[1].alertCodetData)
            }
        })
    }

    function FetchSeverityTypeList() {
        let data = GetSeverityTypeList().then((resp) => {
            if (resp.status == "Ok") {
                setSeverities(resp.message);
            }
        })
    }

    const getWorkflows = async () => {
        await loadWorkflows(userid).then((resp) => {
            if (resp && resp.status == "Ok") {
                setWorkflows(resp.message)
            }
        })
    }

    const deleteSite = async (siteid) => {
        var myCurrentDatetime = new Date();
        var _datetime = ConvertDate(myCurrentDatetime);
        let obj = {
            "SiteID": siteid,
            "UserID": userid,
            "SiteDeleteTime": _datetime
        }
        await deletesite(obj).then((resp) => {
            if (resp.status == "Ok") {
                getSites()
                let siteObj = {
                    "SiteID": 0,
                    "SiteName": '',
                    "BranchID": '',
                    "SiteAddress": '',
                    "clientID": 0,
                    "AddCustomerName": '',
                    "CountryName": 0,
                    "StateName": 0,
                    "CityName": '',
                    "SiteRegionName": '',
                    "SitePincode": '',
                    "SiteArea": '',
                }
                setSelectedSite(siteObj)
                setIsSiteEdit(true)
                showSuccessToast(resp.message)
                return
            } else if (resp.status === "Failed") {
                Swal.fire({ text: resp.message })
            }
            else {
                Swal.fire({ text: "Something went wrong, please try again later!" })
            }
        })
    }

    const deleteInterface = async (siteid) => {
        setButtonDisable(true)
        var myCurrentDatetime = new Date();
        var _datetime = ConvertDate(myCurrentDatetime);
        let obj = {
            "InterfaceId": siteid,
            "UserId": userid,
            "SiteDeleteTime": _datetime
        }
        await deleteInterfaceDetails(obj).then((resp) => {
            if (resp.Status == "success") {
                getInterfaceData()
                let interfaceObj = {
                    "siteid": 0,
                    "InterfaceId": 0,
                    "InterfaceName": '',
                    "InterfaceType": 0,
                    "InterfaceSubType": 0,
                    "InterfaceIP": '',
                    "InterfacePort": '',
                    "InterfaceUsername": '',
                    "InterfacePassword": '',
                    "UserId": 0,
                    "TimeZoneId":0
                }
                setButtonDisable(false)
                setSelectedInterface(interfaceObj)
                setIsInterfaceEdit(true)
                showSuccessToast(resp.Message)
                return
            } else if (resp.Status === "failed") {
                Swal.fire({ text: resp.Message })
                setButtonDisable(false)
            }
            else {
                Swal.fire({ text: "Something went wrong, please try again later!" })
                setButtonDisable(false)
            }
        }).catch((err) => {
            console.log(err);
            setButtonDisable(false)
        })
    }

    //#region tej start

    /* Interface */
    const getInterfaceType = () => {
        try {
            getInterfaceTypeData().then((resp) => {
                if (resp != undefined) {
                    if (resp.Status == "success") {
                        setInterfaceTypeData(resp.Data)
                    } else {
                        setInterfaceTypeData(null)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const getZonesAT = (siteIds, showAll, alertCodeId) => {
        let siteString
    if(typeof siteIds[0] == "string"){
        siteString = siteIds.map((item) => {
            return item;
        }).join(',');
    }else{
        siteString = siteIds.map((item) => item.value).join(',');
    } 
        try {
            getZoneTypeAlertTemp(siteString, showAll, alertCodeId)
                .then((res) => {
                    if(res.Status == "success"){
                        setZoneList(res.Data);
                    }
                })
                .catch(err => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    const getInterfaceSubType = (interfaceType) => {
        try {
            if(interfaceType != undefined){
                getInterfaceSubTypeData(interfaceType).then((resp) => {
                    if (resp != undefined) {
                        if (resp.Status == "success") {
                            setInterfaceSubTypeData(resp.Data)
                        } else {
                            setInterfaceSubTypeData(null)
                        }
                    }
                });
            }
        } catch (error) {
            console.error(error)
        }
    }
    const getInterfaceData = () => {
        let siteID = selectedSite.SiteID;
        try {
            getInterfaceList(siteID).then((resp) => {
                if (resp != undefined) {
                    if (resp.Status == "success") {
                        setInterfaceDataList(resp.Data)
                        setInterfaceMainDataList(resp.Data)
                    } else {
                        setInterfaceDataList(null)
                        setInterfaceMainDataList(resp.Data)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const getCCTVTypeMasterData = () => {
        try {
            getCCTVType().then((resp) => {
                if (resp != undefined) {
                    if (resp.Status == "success") {
                        setCctvTypeData(resp.Data)
                    } else {
                        setCctvTypeData(null)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    //tej 10112021
    const getsiteGroupList = async () => {
        try {
            var obj = {
                "action": "getSiteGroup"
            }
            let response = await userGroupAndSiteGroupCRUD(obj)
            if (response && response.status == "Ok") {
                setSiteGroups(prev => response.message)
                setFilteredSiteGroups(prev => response.message)
                setDefaultSelSiteGroup(response.message[0])
                return response.message
            }
            else if (response == null) {
                setSiteGroups(null)
                setFilteredSiteGroups(null)
                setDefaultSelSiteGroup(null)
                return null
            }
        } catch (error) {
            console.log(error)
        }
    }

    function setDefaultSelSiteGroup(firstGroup) {
        try {
            var defaultSelGroup = firstGroup
            if (defaultSelGroup) {
                onSiteGroupSelection(defaultSelGroup)//set default selected group as first grp
            }

        } catch (error) {
            console.log("setDefaultSelSiteGroup", error)
        }
    }

    //tej 10112021
    const onSiteGroupSelection = (siteGroup) => {
        try {
            setSelectedSites(null)
            setSitestoBind(null)
            if (siteGroup && siteGroup.siteGroupID) {
                setSelectedSiteGroup(siteGroup)
                var obj = {
                    "action": "getAssigned_NonAssignedSites",
                    "siteGroupId": siteGroup.siteGroupID
                }
                userGroupAndSiteGroupCRUD(obj).then((response) => {
                    if (response && response.status == "Ok") {
                        const selectedSiteArr = []
                        setSitestoBind(response.message)
                        for (let i = 0; i < response.message.length; i++) {
                            selectedSiteArr.push({ 'id': response.message[i].SiteID, 'name': response.message[i].isSelected })
                        }
                        setSelectedSites(selectedSiteArr.reduce((obj, data) => ({ ...obj, [data.id]: data.name }), {}))
                    }
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    //tej 09112021
    const fetchUserGroups = async () => {
        var obj = {
            "action": "getUserGroup"
        }
        let response = await userGroupAndSiteGroupCRUD(obj)
        if (response.status == "Ok") {
            setUserGroups(prev => response.message)
            setFilteredUserGroups(prev => response.message)
            if (response.message && response.message.length > 0) {
                setDefaultSelUserGroup(response.message[0])
            }
            return response.message
        }
    }

    function setDefaultSelUserGroup(firstGroup) {
        try {
            var defaultSelGroup = firstGroup
            if (defaultSelGroup) {
                // onUserGroupSelection(defaultSelGroup)//set default selected group as first grp
                siteGroupOnClick(defaultSelGroup)
            }

        } catch (error) {
            console.log("setDefaultSelUserGroup", error)
        }
    }

    function setDefaultSelFRSiteGroup(firstGroup) {
        try {
            var defaultSelGroup = firstGroup
            if (defaultSelGroup) {
                siteGroupOnClick(defaultSelGroup)//set default selected group as first grp
            }
        } catch (error) {
            console.log("setDefaultSelSiteGroup", error)
        }
    }
    //tej 1112021
    const updateSitesToSiteGroup = async () => {
        if (selectedSiteGroup == null || selectedSiteGroup.siteGroupID == 0) {
            showErrorToast('Please select site group!')
            return
        }
        var updatedOn = ConvertDateToUTC(new Date())
        var siteIdCollection = []
        if (sitesToBind && sitesToBind.length > 0) {
            for (let i = 0; i < sitesToBind.length; i++) {
                const element = sitesToBind[i];
                if (element.isSelected === true) {
                    siteIdCollection.push(element.SiteID)
                }

            }
        }
        var assinedSites = 0
        if (siteIdCollection.length > 0) {
            assinedSites = siteIdCollection
        }
        let siteGroupObj = {
            "action": "assignSiteToGroup",
            "siteGroupID": selectedSiteGroup.siteGroupID,
            "assinedSites": assinedSites,
            "createdBy": userid,
            "createdOn": updatedOn
        }
        if (assinedSites.length > 0) {
            await userGroupAndSiteGroupCRUD(siteGroupObj).then((res) => {
                if (res.status == "Ok") {
                    onSiteGroupSelection(selectedSiteGroup)
                    showSuccessToast(res.message)
                } else {
                    showErrorToast(res.message)
                }
            })
        }
        else {
            showErrorToast('Please select at least one site!')
        }

    }

    //#region Site ERT S
    const siteERTOnClick = (ert) => {
        try {
            if (ert && ert.EmergencyContactID) {
                // setSelectedERT(ert)
                var obj = {
                    "action": "getERTDetails",
                    "emergencyContactID": ert.EmergencyContactID
                }
                Addsitedetails(obj).then((response) => {
                    if (response.status == "Ok") {
                        if (response.message && response.message.length > 0) {
                            setSelectedERT(response.message[0])
                            setSiteUserFieldsVisibility(true)
                        }

                    }
                })
            }
        } catch (error) {
            console.log("siteGroupOnClick" + error.toString())
        }
    }

    const getERTListBySite = (siteID, action, ertID) => {
        if (siteID) {
            var obj = {
                "action": "getERTListBySiteID",
                "siteID": siteID
            }
            Addsitedetails(obj).then((res) => {
                if (res && res.status == "Ok") {
                    setEmeContactList(res.message)
                    if (action && action == 'updateERT') {
                        const selERT = res.message.find(x => x.EmergencyContactID == ertID)
                        if (selERT) {
                            setSelectedERT(selERT)
                        }
                    }
                    else if (action && action == 'deleteERT') {
                        clearERTMemberData() //clear form
                    }

                }
            })
            return
        }
    }

    const onAddERTMemberClick = (ert, siteId, optionSelected) => {
        if (siteId) {
            let contactLevel = ert.contactLevel
            let designation = ert.designation
            let email = ert.email
            let isEmail = ert.isEmail
            let mobile = ert.mobile
            let contactName = ert.name
            let emergencyContactID = ert.emergencyContactID
            let isERT = 0
            let isCustomerSPOC = 0
            if (optionSelected) {
                var isert = optionSelected.find(x => x.value == 'ERTMember')
                var iscustSpoc = optionSelected.find(x => x.value == 'CustomerSPOC')
                if (isert) {
                    isERT = 1
                }
                if (iscustSpoc) {
                    isCustomerSPOC = 1
                }
            }

            var phone = new RegExp(/^[7-9][0-9]{9}$/)

            if (designation === null || designation === '' || designation.trim() === '') {
                showErrorToast('Please enter designation!'); return
            }
            if (isERT === 0 && isCustomerSPOC === 0) { showErrorToast('Please select user role!'); return }
            if (contactLevel === 0 || contactLevel === '' || (contactLevel === null && isERT == 1)) { showErrorToast('Please select contact level!'); return }
            if (contactName === null || contactName === '' || contactName.trim() === '') { showErrorToast('Please enter contact name!'); return }
            if (mobile === 0 || mobile === '' || mobile === null || (mobile && mobile.trim() === '')) { showErrorToast('Please enter mobile number!'); return }
            else if (!phone.test(mobile)) {
                showErrorToast("Please enter a valid phone number!"); return
            }

            if (isEmail && isEmail === true) {
                if (email === null || email === '' || email.trim() === '') { showErrorToast('Please enter email to configure email!'); return }
            }
            var createdtime = ConvertDateToUTC(new Date())

            if (emergencyContactID == 0) {
                var ertObj = {
                    "action": "addERTMember",
                    "siteID": siteId,
                    "contactLevel": contactLevel,
                    "designation": designation,
                    "name": contactName,
                    "mobile": mobile,
                    "email": email,
                    "isEmail": isEmail,
                    "createdOn": createdtime,
                    "createdBy": userid,
                    "isERT": isERT,
                    "isCustomerSPOC": isCustomerSPOC
                }
                Addsitedetails(ertObj).then((response) => {
                    if (response && response.status == "Ok") {
                        try {
                            Getsitelist().then((resp) => {
                                if (resp != undefined) {
                                    if (resp.status == "Ok") {
                                        if (resp.message && resp.message.length > 0) {
                                            var ertList = resp.message.find(x => x.SiteID === siteId)
                                            if (ertList) {
                                                setEmeContactList(ertList['EmergencyContactList'])

                                                //once we add new ert member, form should not get cleared.Here, we are finding new added member and assigning to the state
                                                if (ertList['EmergencyContactList'] && ertList['EmergencyContactList'].length > 0) {
                                                    const lastObj = ertList['EmergencyContactList'][ertList['EmergencyContactList'].length - 1]
                                                    if (lastObj) {
                                                        setSelectedERT(lastObj)
                                                    }
                                                }
                                            }
                                            setSites(resp.message)
                                            setFilteredSites(resp.message)
                                        }

                                    } else {
                                        setSites(null)
                                    }
                                }
                            })
                            showSuccessToast(response.message)
                            // Swal.fire({text:response.message})
                        } catch (error) {
                            console.error(error)
                        }

                    }
                    else if (response && response.status == "Failed") {
                        // Swal.fire({text:response.message})
                        showErrorToast(response.message)
                    }
                    else {
                        showErrorToast('Something went wrong while adding ert member in site, please try again later!')
                        // Swal.fire({text:"Something went wrong while adding ert member in site, please try again later!"})
                    }
                })

            }
            else {
                var ertObj = {
                    "action": "updateERTMember",
                    "emergencyContactID": emergencyContactID,
                    "siteID": siteId,
                    "contactLevel": contactLevel,
                    "designation": designation,
                    "name": contactName,
                    "mobile": mobile,
                    "email": email,
                    "isEmail": isEmail,
                    "createdOn": createdtime,
                    "createdBy": userid,
                    "isERT": isERT,
                    "isCustomerSPOC": isCustomerSPOC
                }
                Addsitedetails(ertObj).then((response) => {
                    if (response && response.status == "Ok") {
                        getERTListBySite(siteId, 'updateERT', emergencyContactID)
                        showSuccessToast(response.message)
                        // Swal.fire({text:response.message})
                    }
                    else if (response && response.status == "Failed") {
                        // Swal.fire({text:response.message})
                        showErrorToast(response.message)
                    }
                    else {
                        showErrorToast('Something went wrong while adding ert member in site, please try again later!')
                        // Swal.fire({text:"Something went wrong while adding ert member in site, please try again later!"})
                    }
                })

            }



        }
        else {
            showErrorToast('Please select site!')
            // Swal.fire({ text: 'Please select site!' })
        }
    }

    const onUserRoleChange = (e) => {
        setSelectedUserRole(e)
        if (e && e.length == 0) {
            setSiteUserFieldsVisibility(false)
        }
        else {
            setSiteUserFieldsVisibility(true)
        }

    }

    const clearERTMemberData = () => {
        let ertObj1 = {
            EmergencyContactID: 0,
            ContactName: null,
            EmailID: null,
            Phone: null,
            SMS: false,
            Email: false,
            WhatsApp: false,
            IsCall: false,
            InApp: false,
            UserID: 0,
            ContactLevel: null,
            Designation: null,
            ERTLevelId: 0,
            LevelNumber: null
        }
        setSelectedERT(ertObj1)
        setSiteUserFieldsVisibility(false)
        setSelectedUserRole([])
    }

    const addNewERTMemberClick = () => {
        clearERTMemberData()
    }

    const onDeleteERT = (siteId, ert) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this member!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#027aaa',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                var obj = {
                    "action": "deleteERTMember",
                    "emergencyContactID": ert.EmergencyContactID
                }
                Addsitedetails(obj).then((resp) => {
                    if (resp && resp.status == "Ok") {
                        getERTListBySite(siteId, 'deleteERT', ert.EmergencyContactID)

                        // Swal.fire({ text: resp.message })
                        showSuccessToast(resp.message)
                    } else {
                        showErrorToast('Error occurred while deleting the ERT member!')
                        // Swal.fire({ text: 'Error occurred while deleting the ERT member!' })
                    }
                })
            }
        })
    }

    //tej 24122021 --Users -> FR association section
    const siteGroupOnClick = (siteGroup) => {
        try {
            setUserstoBind(null)
            if (siteGroup && siteGroup.siteGroupID) {
                setSelectedSiteGroup(siteGroup)
                var obj = {
                    "action": "getAssigned_NonAssigned_SiteGroupFRs",
                    "siteGroupId": siteGroup.siteGroupID
                }
                userGroupAndSiteGroupCRUD(obj).then((response) => {
                    if (response.status == "Ok") {
                        if (response.message && response.message.length > 0) {
                            setUserstoBind(response.message)
                            //using for levels
                            const abc = []
                            for (let i = 0; i < response.message.length; i++) {
                                abc.push({ 'id': response.message[i].userID, 'name': response.message[i].isSelected })

                            }
                            setSelectedUsers(abc.reduce((obj, data) => ({ ...obj, [data.id]: data.name }), {}))
                        }

                    }
                })
                // }
            }
        } catch (error) {
            console.log("siteGroupOnClick" + error.toString())
        }
    }

    const onAddSelectedUsers = async () => {
        if (selectedSiteGroup == null || selectedSiteGroup.siteGroupID == 0) {
            Swal.fire({ text: 'Please select site group!' })
            return
        }
        var updatedOn = ConvertDateToUTC(new Date())

        var assignedUsers = []
        if (usersToBind && usersToBind.length > 0) {
            for (let i = 0; i < usersToBind.length; i++) {
                const element = usersToBind[i];

                let tempuserObj = {
                    userID: element.userID,
                    contactLevel: element.contactLevel,
                };
                assignedUsers.push(tempuserObj)

            }
        }
        if (assignedUsers && assignedUsers.length > 0) {
            let siteGroupObj = {
                "action": "assignFRUsersToSiteGroup",
                "siteGroupID": selectedSiteGroup.siteGroupID,
                "assinedUsers": assignedUsers,
                "createdBy": userid,
                "createdOn": updatedOn
            }
            await userGroupAndSiteGroupCRUD(siteGroupObj).then((res) => {
                if (res.status == "Ok") {
                    // getsiteGroupList()
                    siteGroupOnClick(selectedSiteGroup)
                    // Swal.fire({ text: res.message })
                    showSuccessToast(res.message)
                } else {
                    // Swal.fire({ text: res.message })
                    showErrorToast(res.message)
                }

            })
        }
        else {
            Swal.fire({ text: 'Please select atleast one user!' })
        }

    }

    //#region Search impl s
    function onSearchChange(e, type, interfaceType) {
        var searchText = e//.target.value
        if (type == "User List") {
            if (searchText) {
                let filteredLstBySearch
                filteredLstBySearch = users.filter(x =>
                    x.FullName.toLowerCase().includes(searchText.toLowerCase())
                )
                setFilteredUsers(filteredLstBySearch)
            }
            else {
                setFilteredUsers(users)
            }
        }
        else if (type == "User Group") {
            if (searchText) {
                let filteredLstBySearch = userGroups.filter(x => x.groupTitle.toLowerCase().includes(searchText.toLowerCase()))
                setFilteredUserGroups(filteredLstBySearch)
            }
            else {
                setFilteredUserGroups(userGroups)
            }
        }
        else if (type == "Site List") {
            if (searchText) {
                let filteredLstBySearch = sites.filter(x => x.SiteName.toLowerCase().includes(searchText.toLowerCase()))
                setFilteredSites(filteredLstBySearch)
            }
            else {
                setFilteredSites(sites)
            }
        }
        else if (type == "Site Group") {
            if (searchText) {
                let filteredLstBySearch = siteGroups.filter(x => x.groupTitle.toLowerCase().includes(searchText.toLowerCase()))
                setFilteredSiteGroups(filteredLstBySearch)
            }
            else {
                setFilteredSiteGroups(siteGroups)
            }
        }
        else if (type == "Alert Template") {
            if (searchText) {
                let filteredLstBySearch = eventTemplates.filter(x => x.TemplateName.toLowerCase().includes(searchText.toLowerCase()))
                setFilteredEventTemplates(filteredLstBySearch)
            }
            else {
                setFilteredEventTemplates(eventTemplates)
            }
        }
        else if (type == "Device List") {
            if (interfaceType == "NVRDVR") {
                if (searchText) {
                    let filteredLstBySearch = cameraMainDevicesList.filter(x => x.CameraName.toLowerCase().includes(searchText.toLowerCase()))
                    setcameradeviceslist(filteredLstBySearch)
                } else {
                    setcameradeviceslist(cameraMainDevicesList)
                }
            }
            else if(interfaceType == "ACS"){
                if(searchText){
                    let filteredLstBySearch = acsMainDevicesList.filter(x => x.CameraName.toLowerCase().includes(searchText.toLowerCase()))
                    setAcsDevicesList(filteredLstBySearch);
                }else{
                    setAcsDevicesList(acsMainDevicesList);
                }
            } 
            else {
                if (searchText) {
                    let filteredLstBySearch = panelMainDevicesList.filter(x => x.CameraName.toLowerCase().includes(searchText.toLowerCase()))
                    setpaneldeviceslist(filteredLstBySearch)
                } else {
                    setpaneldeviceslist(panelMainDevicesList)
                }
            }
        }
        else if (type == "interface") {
            if (searchText) {
                let filteredLstBySearch = interfaceMainDataList.filter(x => x.InterfaceName.toLowerCase().includes(searchText.toLowerCase()))
                setInterfaceDataList(filteredLstBySearch)
            }
            else {
                setInterfaceDataList(interfaceMainDataList)
            }
        }
    }
    //#endregion Search impl e

    const OnSaveDevice = async (data, parentCameraID) => {
        console.log("OnSaveDevice", data);
        let privateIPAddress = data.privateIPAddress
        let cameraName = data.cameraName
        let userName = data.userName
        let password = data.password
        let port = data.port
        let channelNumber = data.channelNumber
        let liveStreamTypeNumber = data.liveStreamTypeNumber
        let playbackStreamTypeNumber = data.playbackStreamTypeNumber
        let rtspURL = data.rTSPURL
        let siteId = data.SiteID
        let CameraBrandId = data.CameraBrandId
        let communicationPort = data.communicationPort
        let httpPort = data.httpPort
        let timeZoneIdDevice = parseInt(data.TimeZoneIdDevice)
        let isPTZ = data.isPTZ
        let isPlaybackUTC = data.isPlaybackUTC
        let identifierName = data.identifierName
        
        let EnableTranscoding
        if(parentIsEncoding){
            EnableTranscoding = 1
        }else{
            EnableTranscoding = 0;
        }

        let EnablePlaybackTranscoding
        if(parentIsPlaybackEncoding){
            EnablePlaybackTranscoding = 1
        }else{
            EnablePlaybackTranscoding = 0;
        }

        let Enabletwowaydevice
        if(parentIsEnablingTwoWayDevice){
            Enabletwowaydevice = 1
        }else{
            Enabletwowaydevice = 0;
        }

        let EnablePTZ
        if(parentIsEnablingPTZ){
            EnablePTZ = 1
        }else{
            EnablePTZ = 0
        }

        let EnablePlaybackUTC
        if(parentIsEnablingUTCPlayback){
            EnablePlaybackUTC = 1
        }else{
            EnablePlaybackUTC = 0
        }
        let CCTVTypeID = data.CCTVTypeID
        let myCurrentDatetime = new Date();
        let datetime = ConvertDate(myCurrentDatetime)
        let userUniqueId = loggedInUser.UserUniqueID || loggedInUser.userUniqueID
/* 
 e.value =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password").replace("@", "%40") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/cam/realmonitor?channel=" +
          getValues("channelNumber") +
          "&subtype=1";)
*/
        let tempCameraBrandId = devicesmasterlist.cameraTypeMasterList.filter(item => {
            return item.brandId == CameraBrandId
        })
        let cameraBrandName = tempCameraBrandId[0].brandName
        //Old
        // if(cameraBrandName == "Hikvision"){
        //     liveStreamTypeNumber = liveStreamTypeNumber == "Main" ? 1 : liveStreamTypeNumber == "Sub" ? 2 : ""
        //     playbackStreamTypeNumber = playbackStreamTypeNumber == "Main" ? 1 : playbackStreamTypeNumber == "Sub" ? 2 : ""
        // }else{
        //     liveStreamTypeNumber = liveStreamTypeNumber == "Main" ? 0 : liveStreamTypeNumber == "Sub" ? 1 : ""
        //     playbackStreamTypeNumber = playbackStreamTypeNumber == "Main" ? 0 : playbackStreamTypeNumber == "Sub" ? 1 : ""
        // }

        //New
        if (cameraBrandName == "Hikvision" || cameraBrandName == "Hikvision-Hybrid") {
            liveStreamTypeNumber = liveStreamTypeNumber == "Main" ? "01" : liveStreamTypeNumber == "Sub" ? "02" : liveStreamTypeNumber == "Ter" ? "03" : ""
            playbackStreamTypeNumber = playbackStreamTypeNumber == "Main" ? "01" : playbackStreamTypeNumber == "Sub" ? "02" : playbackStreamTypeNumber == "Ter" ? "03" : ""
        } else {
            liveStreamTypeNumber = liveStreamTypeNumber == "Main" ? "0" : liveStreamTypeNumber == "Sub" ? "1" : liveStreamTypeNumber == "Ter" ? "2" : ""
            playbackStreamTypeNumber = playbackStreamTypeNumber == "Main" ? "0" : playbackStreamTypeNumber == "Sub" ? "1" : playbackStreamTypeNumber == "Ter" ? "2" : ""
        }
        if (!rtspValueChanged) {
            if (cameraBrandName == "Hikvision") {
                //for hikvision
                // rtsp://admin:Admin@123@192.168.0.44:554/Streaming/Channels/102
                let password2 = password.replaceAll("@", "%40")

                // rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/Streaming/Channels/${channelNumber}`
                rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/Streaming/Channels/${channelNumber}${liveStreamTypeNumber}`
            }

            if (cameraBrandName == "CP Plus") {
                //for CP Plus
                // rtsp://admin:abc@1234@192.168.0.66:554/cam/realmonitor?channel=1&subtype=1
                // let password2 = password.replaceAll("@", "%40")
                let password2 = password.replaceAll("@", "%40")

                // rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/cam/realmonitor?channel=${channelNumber}%38subtype=1`
                rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/cam/realmonitor?channel=${channelNumber}%38subtype=${liveStreamTypeNumber}`
            }

            if (cameraBrandName == "Dahua") {
                //forDahua
                let password2 = password.replaceAll("@", "%40")

                // rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/cam/realmonitor?channel=${channelNumber}%26subtype=1`
                rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/cam/realmonitor?channel=${channelNumber}%26subtype=${liveStreamTypeNumber}`
            }

            if (cameraBrandName == "DLink") {
                //forDLink
                let password2 = password.replaceAll("@", "%40")
                // RTSPURL rtsp://admin:Admin@123@122.170.105.219:554/avstream/channel=1/stream=0.sdp
                // rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/avstream/channel=${channelNumber}/stream=1.sdp`
                rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/avstream/channel=${channelNumber}/stream=${liveStreamTypeNumber}.sdp`
            }
            if (cameraBrandName == "UnV") {
                // let password2 = password//.replaceAll("@", "%40");
                let password2 = password.replaceAll("@", "%40")

                if (CCTVTypeID == "4") {
                    // rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/address/media/video${channelNumber}`;
                    if(liveStreamTypeNumber != ""){
                        liveStreamTypeNumber = parseInt(liveStreamTypeNumber) + 1;
                    }
                    if(playbackStreamTypeNumber != ""){
                        playbackStreamTypeNumber = parseInt(playbackStreamTypeNumber) + 1;
                    }
                    rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/media/video${liveStreamTypeNumber}`;
                } else {
                let password2 = password.replaceAll("@", "%40")

                    // rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/unicast/c${channelNumber}/s0/live`;
                    rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/unicast/c${channelNumber}/s${liveStreamTypeNumber}/live`;
                }
            }
            if (cameraBrandName == "Raysharp") {
                //forRe-Sharp
                // let password2 = password//.replaceAll("@", "%40")
                // =>rtsp://admin:admin123.@73.14.118.111:3311/rtsp/streaming?channel=01&subtype=0 ---new
                //rtsp://admin:admin123.@73.14.118.111:3311/rtsp/streaming?channel=01&subtype=1 --new
                let password2 = password.replaceAll("@", "%40")
                rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/rtsp/streaming?channel=${channelNumber}&subtype=${liveStreamTypeNumber}`;
                
                // RTSPURL //192.168.1.100:554/rtsp/streaming?channel=1&subtype=0&token=123" --old 
                // rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/rtsp/streaming?channel=${channelNumber}&subtype=0&token=123`;
                //rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/rtsp/streaming?channel=${channelNumber}&subtype=${liveStreamTypeNumber}&token=123`; --old
            }
            // if(CameraBrandId == 6){
            //     //forUnV
            //     let password2 = password//.replaceAll("@", "%40")
            //     // RTSPURL rtsp://admin:Admin@123@122.170.105.219:554/avstream/channel=1/stream=0.sdp
            //      rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}/address/media/video=${channelNumber}/video`
            // }

            if (cameraBrandName == "Hikvision-Hybrid") {
                //for hikvision-Hybrid
                // rtsp://admin:Admin@123@192.168.0.44:554/Streaming/Channels/102
                let password2 = password.replaceAll("@", "%40")

                // rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/Streaming/Channels/${channelNumber}`
                rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/Streaming/Channels/${channelNumber}${liveStreamTypeNumber}`
            }
            if (cameraBrandName == "Unknown") {
                rtspURL = unknownBrandUrl
            }
        }

        let validationError = "";
        
        if (CameraBrandId === undefined || CameraBrandId === '') { 
            showErrorToast('Please select brand name!'); 
            return 
        }
        if (privateIPAddress === undefined || privateIPAddress === null || privateIPAddress === '' || privateIPAddress.trim() === '') { 
            showErrorToast('Please enter private IP address!'); 
            return 
        }
        if (communicationPort === undefined || communicationPort === null || communicationPort === "") { 
            // showErrorToast('Please enter communication port.'); 
            // return 
            validationError += "communication port-Please enter communication port!,"
        }
        if (httpPort === undefined || httpPort === null || httpPort === "") { 
            // showErrorToast('Please enter Http port.'); 
            // return 
            validationError += "http port-Please enter Http port!,"
        }
        if (userName === undefined || userName === null || userName === '' || userName.trim() === '') { 
            showErrorToast('Please enter user name!'); 
            return 
        }
        if (password === undefined || password === null || password === '' || password.trim() === '') { 
            showErrorToast('Please enter password!'); 
            return 
        }
        if (port === undefined || port === null || port === '' || port.trim() === '') { 
            showErrorToast('Please enter port!'); 
            return 
        }
        if (channelNumber === undefined || channelNumber === null || channelNumber === '' || channelNumber.trim() === '') { 
            // showErrorToast('Please enter channel number!'); 
            // return 
            validationError += "channel number-Please enter channel number!,"
        }

        if (liveStreamTypeNumber === undefined || liveStreamTypeNumber === null || liveStreamTypeNumber === '') { 
            // showErrorToast('Please enter live stream type number!'); 
            // return 
            validationError += "live stream type-Please enter live stream type number!,"
        }

        if (playbackStreamTypeNumber === undefined || playbackStreamTypeNumber === null || playbackStreamTypeNumber === '') { 
            // showErrorToast('Please enter playback stream type number!'); 
            // return 
            validationError += "playback stream type-Please enter playback stream type number!,"
        }
        
        if (rtspURL === undefined || rtspURL === null || rtspURL == '' || rtspURL.trim() === '' || rtspURL.includes("undefined") ) { 
            // showErrorToast('RTSP url cannot be empty!'); 
            // return 
            validationError += "rtsp URL-RTSP url cannot be empty!,"
        } 
        if (cameraName === undefined || cameraName === null || cameraName === '' || cameraName.trim() === '') { 
            // showErrorToast('Please enter device name!'); 
            // return 
            validationError += "device name-Please enter device name!,"
        }
        if (siteId === undefined || siteId === null || siteId === '' || siteId === 0) { 
            showErrorToast('Please select site name!'); 
            return 
        }
        if (CCTVTypeID == undefined || CCTVTypeID == '' || CCTVTypeID == 0 || CCTVTypeID == "Entity Subtype*") {
            showErrorToast('Please select entity subtype!');
            return
        }

        // if (userUniqueId.trim === undefined || userUniqueId.trim === '') { showErrorToast('Parameter missing, please logout and login again!'); return }

        // if (CameraBrandId == 2) {
        //     rtspURL = rtspURL.replace('@', '%40')
        // }

        setInputValidationError(validationError);
        // if(!rtspValueChanged){
        //     if(CameraBrandId == 1){
        //         //for hikvision
        //         // rtsp://admin:Admin@123@192.168.0.44:554/Streaming/Channels/102
        //         let password2 = password.replaceAll("@", "%40")
        
        //          rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/Streaming/Channels/${channelNumber}`
        //     }
        
        //     if(CameraBrandId == 2){
        //         //for CP Plus
        //         // rtsp://admin:abc@1234@192.168.0.66:554/cam/realmonitor?channel=1&subtype=1
        //         // let password2 = password.replaceAll("@", "%40")
        
        //          rtspURL = `rtsp://${userName}:${password}@${privateIPAddress}:${port}/cam/realmonitor?channel=${channelNumber}%38subtype=1`
        //     }
        
        //     if(CameraBrandId == 4){
        //         //forDahua
        //         let password2 = password.replaceAll("@", "%40")
        
        //          rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/cam/realmonitor?channel=${channelNumber}%26subtype=1`
        //     }
        
        //     if(CameraBrandId == 5){
        //         //forDLink
        //         let password2 = password.replaceAll("@", "%40")
        //         // RTSPURL rtsp://admin:Admin@123@122.170.105.219:554/avstream/channel=1/stream=0.sdp
        //          rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}:${port}/avstream/channel=${channelNumber}/stream=1.sdp`
        //     }
        //     // if(CameraBrandId == 6){
        //     //     //forUnV
        //     //     let password2 = password//.replaceAll("@", "%40")
        //     //     // RTSPURL rtsp://admin:Admin@123@122.170.105.219:554/avstream/channel=1/stream=0.sdp
        //     //      rtspURL = `rtsp://${userName}:${password2}@${privateIPAddress}/address/media/video=${channelNumber}/video`
        //     // }
        // }
        
            // setParentObj(tempObj);
            setSavebtnClick(true)
            if (validationError != "") {
                return;
            }

        let arr = devicesmasterlist.cameraTypeMasterList.filter(item => item.brandName == selectedInterface.Interfacesubtypename);
        let temp = arr[0].brandId; 
        if (parentCameraID == 0) { //add new device
            var devObj = {
                "action": "add",
                "parentCameraID": parentCameraID,
                "userUniqueId": userUniqueId,
                "privateIPAddress": privateIPAddress,
                "cameraName": cameraName,
                "userName": userName,
                "password": password,
                "port": port,
                "channelNumber": channelNumber,
                // "playbackChannelNumber": playbackChannelNumber,
                "liveStreamTypeNumber": liveStreamTypeNumber,
                "playbackStreamTypeNumber": playbackStreamTypeNumber,
                "rtspURL": rtspURL,
                "componentType": "Web",
                "systemDeviceId": "",
                "fcmToken": fcmToken,
                "dateTime": datetime,
                "location": "",
                "siteId": siteId,
                // "cameraBrandId": CameraBrandId,
                "cameraBrandId": temp,
                "panelMasterID": null,
                "hlsStreamingURL": null,
                "fcmToken": null,
                "deviceTypeID": 1,
                "communicationPort": communicationPort,
                "httpPort": httpPort,
                "isTranscoded": EnableTranscoding,
                "isPlaybackTranscoded": EnablePlaybackTranscoding,
                "isTwoWayEnabled": Enabletwowaydevice,
                "interfaceId" : selectedInterface.InterfaceId,
                "CCTVTypeID" : CCTVTypeID,
                "isHooterEnabledInterface" : isHooterEnabledInterface,
                "timeZoneId": timeZoneIdDevice,
                "isPTZ": isPTZ,
                "isPlaybackUTC": isPlaybackUTC,
                "identifierName": null,
                "parentDeviceId": null
                }
                setIsSubmitting(true);
            //showWarningToast('Taking time to publish the stream, please wait for a moment')

            //SaveDeviceDetails(devObj).then((resp) => {
            deviceconfigcrud(devObj).then((resp) => {
                if (resp && resp.status === "Ok") {
                    // setSelectedDevice(deviceObj)
                    getdeviceslistdata()
                    let deviceObject = {
                        "ParentCameraID": 0,
                        "cameraName": '',
                        "privateIPAddress": selectedInterface.InterfaceIP,
                        "SiteID": selectedInterface.siteid,
                        "userName": selectedInterface.UserName,
                        "password": selectedInterface.Password,
                        "port": selectedInterface.InterfacePort,
                        "channelNumber": '',
                        // "playbackChannelNumber": '',
                        "liveStreamTypeNumber": '',
                        "playbackStreamTypeNumber": '',
                        "rTSPURL": '',
                        "location": '',
                        "PanelMasterId": 0,
                        "CameraBrandId": temp,
                        "communicationPort": '',
                        "httpPort": '',
                        "isTranscoded": 0,
                        "isPlaybackTranscoded": 0,
                        "isTwoWayEnabledDevice": Enabletwowaydevice,
                        "CCTVTypeID": selectedInterface.EntityTypeID,
                        "TimeZoneIdDevice": selectedInterface.TimeZoneId,
                        "isPTZ": 0,
                        "isPlaybackUTC": 0,
                        "identifierName": ""
                    }
                    setSelectedDevice(deviceObject);
                    setRtspValueChanged(false);
                    showSuccessToast(resp.message)
                    setIsSubmitting(false);
                }
                else {
                    setIsSubmitting(false);
                    showErrorToast(resp.message)
                }
            })

        }
        else if (parentCameraID && parentCameraID != 0 && parentCameraID != undefined) {
            var devObj = {
                "action": "update",
                "deviceId": parentCameraID,
                "userUniqueId": userUniqueId,
                "privateIPAddress": privateIPAddress,
                "cameraName": cameraName,
                "userName": userName,
                "password": password,
                "port": port,
                "channelNumber": channelNumber,
                "liveStreamTypeNumber": liveStreamTypeNumber,
                "playbackStreamTypeNumber": playbackStreamTypeNumber,
                "rtspURL": rtspURL,
                "componentType": "Web",
                "systemDeviceId": "",
                "fcmToken": null,
                "dateTime": datetime,
                "location": "",
                "siteId": siteId,
                "cameraBrandId": CameraBrandId,
                "panelMasterID": null,
                "hlsStreamingURL": null,
                "deviceTypeID": 1,
                "dataRetention": null,
                "communicationPort": communicationPort,
                "httpPort": httpPort,
                "isTranscoded": EnableTranscoding,
                "isPlaybackTranscoded": EnablePlaybackTranscoding,
                "isTwoWayEnabled": Enabletwowaydevice,
                "interfaceId" : selectedInterface.InterfaceId,
                "CCTVTypeID" : CCTVTypeID,
                "timeZoneId": timeZoneIdDevice,
                "isPTZ": isPTZ,
                "isPlaybackUTC": isPlaybackUTC,
                "identifierName": null,
                "parentDeviceId": null
            }
            setIsSubmitting(true);
            deviceconfigcrud(devObj).then((resp) => {
                if (resp) {
                    if (resp.status === "Ok") {
                        getdeviceslistdata()
                        setRtspValueChanged(false);
                        showSuccessToast(resp.message)
                        setIsDeviceEdit(false);
                        setIsSubmitting(false);
                    }
                    else {
                        setIsSubmitting(false);
                        showErrorToast(resp.message)
                    }
                }

            })
        }
    }

    const onSaveACS = async (data,parentCameraID) => {
        console.log("onSaveACS", data);
        let privateIPAddress = data.privateIPAddress
        let cameraName = data.cameraName
        let userName = data.userName
        let password = data.password
        let port = data.port
        let channelNumber = data.channelNumber
        let liveStreamTypeNumber = data.liveStreamTypeNumber
        let playbackStreamTypeNumber = data.playbackStreamTypeNumber
        let rtspURL = data.rTSPURL
        let siteId = data.SiteID
        let CameraBrandId = data.CameraBrandId
        let communicationPort = data.communicationPort
        let httpPort = data.httpPort
        let timeZoneIdDevice = parseInt(data.TimeZoneIdDevice)
        let isPTZ = data.isPTZ
        let isPlaybackUTC = data.isPlaybackUTC
        let identifierName = data.identifierName

        let myCurrentDatetime = new Date();
        let datetime = ConvertDate(myCurrentDatetime)
        let userUniqueId = loggedInUser.UserUniqueID || loggedInUser.userUniqueID
        console.log("onSaveACS", devicesmasterlist.panelTypeMasterList)
        let arr = devicesmasterlist.panelTypeMasterList.filter(item => item.panelName == data.InterfaceSubType);
        console.log("onSaveACS", arr)
        let temp = arr[0].panelMasterID; 

        let validationError = "";

        if(cameraName == "" || cameraName == null || cameraName == undefined || cameraName.trim() == ""){
            validationError += "device name-Please enter device name!,"
        }

        if(identifierName === undefined || identifierName === null || identifierName === "" || identifierName.trim() === ""){
            validationError += "identifier name-Please enter identifier name!,"
        }

        setInputValidationError(validationError);
        setSavebtnClick(true);
        if (validationError != "") {
            return;
        }

        let tempDeviceTypeId = 5;
        if(acsDevicesList == null){
            tempDeviceTypeId = 4;
        }

        console.log("onSaveACS", acsDevicesList)
        if(parentCameraID == 0){
            var devObj = {
                "action": "add",
                "parentCameraID": parentCameraID,
                "userUniqueId": userUniqueId,
                "privateIPAddress": privateIPAddress,
                "cameraName": cameraName,
                "userName": userName,
                "password": password,
                "port": port,
                "channelNumber": null,
                // "playbackChannelNumber": playbackChannelNumber,
                "liveStreamTypeNumber": liveStreamTypeNumber,
                "playbackStreamTypeNumber": playbackStreamTypeNumber,
                "rtspURL": null,
                "componentType": "Web",
                "systemDeviceId": "",
                "fcmToken": fcmToken,
                "dateTime": datetime,
                "location": "",
                "siteId": siteId,
                // "cameraBrandId": CameraBrandId,
                "cameraBrandId": null,
                "panelMasterID": null,
                "hlsStreamingURL": null,
                "deviceTypeID": tempDeviceTypeId,
                "communicationPort": communicationPort,
                "httpPort": httpPort,
                "isTranscoded": 0,
                "isPlaybackTranscoded": 0,
                "isTwoWayEnabled": 0,
                "interfaceId" : selectedInterface.InterfaceId,
                "CCTVTypeID" : 0,
                "isHooterEnabledInterface" : isHooterEnabledInterface,
                "timeZoneId": timeZoneIdDevice,
                "isPTZ": isPTZ,
                "isPlaybackUTC": isPlaybackUTC,
                "identifierName": identifierName,
                "parentDeviceId": acsDevicesList == null ? null : acsDevicesList[0].ParentCameraID
                }
                console.log("onSaveACS", devObj)
                setIsSubmitting(true);
                deviceconfigcrud(devObj).then((resp) => {
                    if (resp && resp.status === "Ok") {
                        // setSelectedDevice(deviceObj)
                        getdeviceslistdata()
                        let deviceObject = {
                            "ParentCameraID": 0,
                            "cameraName": '',
                            "privateIPAddress": selectedInterface.InterfaceIP,
                            "SiteID": selectedInterface.siteid,
                            "userName": selectedInterface.UserName,
                            "password": selectedInterface.Password,
                            "port": selectedInterface.InterfacePort,
                            "channelNumber": '',
                            // "playbackChannelNumber": '',
                            "liveStreamTypeNumber": '',
                            "playbackStreamTypeNumber": '',
                            "rTSPURL": '',
                            "location": '',
                            "PanelMasterId": temp,
                            "CameraBrandId": temp,
                            "communicationPort": '',
                            "httpPort": '',
                            "isTranscoded": 0,
                            "isPlaybackTranscoded": 0,
                            "isTwoWayEnabledDevice": 0,
                            "CCTVTypeID": 0,
                            "TimeZoneIdDevice": selectedInterface.TimeZoneId,
                            "isPTZ": 0,
                            "isPlaybackUTC": 0,
                            "identifierName": ""
                        }
                        setSelectedDevice(deviceObject);
                        showSuccessToast(resp.message)
                        setIsSubmitting(false);
                    }
                    else {
                        setIsSubmitting(false);
                        showErrorToast(resp.message)
                    }
                })
        }else{
            let tempId;
            let deviceTypeTemp;
            if(parentCameraID == acsDevicesList[0].ParentCameraID){
                tempId = null
                deviceTypeTemp = 4
            }else{
                tempId = acsDevicesList[0].ParentCameraID
                deviceTypeTemp = 5
            }
            var devObj = {
                "action": "update",
                "deviceId": parentCameraID,
                "userUniqueId": userUniqueId,
                "privateIPAddress": privateIPAddress,
                "cameraName": cameraName,
                "userName": userName,
                "password": password,
                "port": port,
                "channelNumber": null,
                "liveStreamTypeNumber": liveStreamTypeNumber,
                "playbackStreamTypeNumber": playbackStreamTypeNumber,
                "rtspURL": null,
                "componentType": "Web",
                "systemDeviceId": "",
                "fcmToken": null,
                "dateTime": datetime,
                "location": "",
                "siteId": siteId,
                "cameraBrandId": null,
                "panelMasterID": null,
                "hlsStreamingURL": null,
                "deviceTypeID": deviceTypeTemp,
                "dataRetention": null,
                "communicationPort": communicationPort,
                "httpPort": httpPort,
                "isTranscoded": 0,
                "isPlaybackTranscoded": 0,
                "isTwoWayEnabled": 0,
                "interfaceId" : selectedInterface.InterfaceId,
                "CCTVTypeID" : 0,
                "timeZoneId": timeZoneIdDevice,
                "isPTZ": isPTZ,
                "isPlaybackUTC": isPlaybackUTC,
                "identifierName": identifierName,
                "parentDeviceId": tempId
            }
            console.log("onSaveACS", devObj);
            setIsSubmitting(true);
            deviceconfigcrud(devObj).then((resp) => {
                if (resp) {
                    if (resp.status === "Ok") {
                        getdeviceslistdata()
                        setRtspValueChanged(false);
                        showSuccessToast(resp.message)
                        setIsDeviceEdit(false);
                        setIsSubmitting(false);
                    }
                    else {
                        setIsSubmitting(false);
                        showErrorToast(resp.message)
                    }
                }

            })
        }

    }

    const onDeviceSelection = (device) => {
        try {
            if (device) {
                console.log("onDeviceSelection", device);
                setSelectedDevice(device)
                setAssociateCamSecVisibility(true)
                setFirstSensor(device)
                setBulkUpload(false)
                setIsSingleOrBulkSelection('single_device')
                setselectedZone(zoneObj)
                setIsDeviceEdit(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onAddNewDevice = () => {
        let deviceObject = {
            "ParentCameraID": 0,
            "cameraName": '',
            "privateIPAddress": '',
            "SiteID": 0,
            "userName": '',
            "password": '',
            "port": '',
            "channelNumber": '',
            "liveStreamTypeNumber": '',
            "playbackStreamTypeNumber": '',
            "rTSPURL": '',
            "location": '',
            "PanelMasterId": 0,
            "CameraBrandId": 0,
            "communicationPort" : '',
            "httpPort" : '',
            "isTranscoded" : 0,
            "isPlaybackTranscoded" : 0,
            "isTwoWayEnabledDevice" : 0,
            "CCTVTypeID" : 0

        }
        setSelectedDevice(deviceObject)
        setAssociateCamSecVisibility(false)
    }
    //tej 17112021 E

    const onDeleteSiteGroup = (e, siteGroup) => {
        e.stopPropagation()
        onSiteGroupSelection(siteGroup)
        Swal.fire({
            customClass: {
                cancelButton: "site-group-cancel-button",
                confirmButton: "site-group-cancel-button delete-site-group-confirm-button"
            },
            html: '<span class="custom-title-class-site-group">Delete ' + siteGroup.groupTitle + ' ?</span><br/>' +
                '<span class="custom-text-class-site-group">You cant undo this action.</span>' +
                '<div class="  swal-sitegroup-warning">' + '<span class="site-group-warning-head">' +
                '<img class="m-2" src="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/alert-warning.svg"/>' +
                '<span class="site-group-warning-head-title"> Warning </span></span><br/>' +
                '<span class="site-group-warning-msg">Deleting site group will also delete site group reference with sites and field responders!</span>' +
                '</div>',
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonColor: '#027aaa',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete Site Group',
            allowOutsideClick: false,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                var obj = {
                    "action": "deleteSiteGroup",
                    "siteGroupID": siteGroup.siteGroupID
                }
                userGroupAndSiteGroupCRUD(obj).then((resp) => {
                    if (resp && resp.status == "Ok") {
                        getsiteGroupList()
                        showSuccessToast(resp.message)
                        // Swal.fire({ text: resp.message })
                    } else {
                        showErrorToast('Error occurred while deleting the site group!')
                        // Swal.fire({ text: 'Error occurred while deleting the site group!' })
                    }
                })
            }
        })
    }
    //#region tej end

    //#region Camera Bulk upload
    const OnAddBulkDevicesClick = (event, importedFileHeaders) => {
        if (selectedCamHeaders && importedFileData && importedFileHeaders) {
            var brandHead = selectedCamHeaders['brand']
            var ipHead = selectedCamHeaders['ipaddress']
            var communicationportHead = selectedCamHeaders['communicationport']
            var httpportHead = selectedCamHeaders['httpport']
            var branchHead = selectedCamHeaders['branch']
            var usernameHead = selectedCamHeaders['username']
            var passwordHead = selectedCamHeaders['password']
            var portHead = selectedCamHeaders['port']
            var channelnumberHead = selectedCamHeaders['channelnumber']
            // var playbackchannelnumberHead = selectedCamHeaders['playbackchannelnumber']
            var livestreamtypeHead = selectedCamHeaders['livestreamtype']
            var playbackstreamtypeHead = selectedCamHeaders['playbackstreamtype']
            var devicenameHead = selectedCamHeaders['devicename']
            var enablelivetranscodingHead = selectedCamHeaders['enablelivetranscoding']
            var enableplaybacktranscodingHead = selectedCamHeaders['enableplaybacktranscoding']
            var enabletwowaydeviceHead = selectedCamHeaders['enabletwowaydevice']
            // var interfacenameHead = selectedCamHeaders['interfacename']
            var CCTVTypeHead = selectedCamHeaders['cctvtype']
            var timeZoneHead =selectedCamHeaders['timezone']
            var ptzHead=selectedCamHeaders['ptz']

            if (brandHead === null || branchHead == "") { Swal.fire({ text: 'Please select brand name header' }); return }
            if (ipHead === null || ipHead == "") { Swal.fire({ text: 'Please select IP address header' }); return }
            if (communicationportHead === null || communicationportHead == "") { Swal.fire({ text: 'Please select Communication port header' }); return }
            if (httpportHead === null || httpportHead == "") { Swal.fire({ text: 'Please select http port header' }); return }
            if (branchHead === null || branchHead == "") { Swal.fire({ text: 'Please select branch name header' }); return }
            if (usernameHead === null || usernameHead == "") { Swal.fire({ text: 'Please select user name header' }); return }
            if (devicenameHead === null || devicenameHead == "") { Swal.fire({ text: 'Please select device name header' }); return }

            if (passwordHead === null || passwordHead == "") { Swal.fire({ text: 'Please select password header' }); return }
            if (portHead === null || portHead == "") { Swal.fire({ text: 'Please select port header' }); return }
            if (channelnumberHead === null || channelnumberHead == "") { Swal.fire({ text: 'Please select channel number header' }); return }
            // if (playbackchannelnumberHead === null) { Swal.fire({ text: 'Please select playback channel number header' }); return }
            if (livestreamtypeHead === null || livestreamtypeHead === "") { Swal.fire({ text: 'Please select live stream type header' }); return }
            if (playbackstreamtypeHead === null || playbackstreamtypeHead == "") { Swal.fire({ text: 'Please select playback stream type header' }); return }
            if (enablelivetranscodingHead === null || enablelivetranscodingHead == "") {Swal.fire({ text: 'Please select enable live transcoding header' })}
            if (enableplaybacktranscodingHead === null || enableplaybacktranscodingHead == "") {Swal.fire({ text: 'Please select enable playback transcoding header' })}
            // if (interfacenameHead === null) { Swal.fire({ text: 'Please select interface name' }); return; }
            if (CCTVTypeHead === null || cctvTypeData == "") { Swal.fire({ text: 'Please select cctv type' }); return; }
            if (enabletwowaydeviceHead === null || enabletwowaydeviceHead == "") { Swal.fire({ text: 'Please select two way commmuniacation type' }); return; }
            if (ptzHead === null || ptzHead == "") { Swal.fire({ text: 'Please select enable ptz Header' }); return; }
            if(MULTI_TIME_ZONE){
            if (timeZoneHead=== null || timeZoneHead == "") { Swal.fire({ text: 'Please select timezone ' }); return; }
           }
            
            var deviceDataObj = []
            let newDeviceDataObj = []
            let iserror = false;
            let isBrandNameAbsent = false;
            Object.keys(importedFileData).map((item, index) => {
                var element = importedFileData[item]
                let liveStreamType = ""
                let playbackStreamType = "";                
                if(element[0].trim().toLowerCase() == "hikvision" || element[0].trim().toLowerCase() == "hikvision-hybrid"){
                    if(element[7].toLowerCase() == "main"){
                        liveStreamType = "01"
                    }else if(element[7].toLowerCase() == "sub"){
                        liveStreamType = "02"
                    }else if(element[7].toLowerCase() == "ter"){
                        liveStreamType = "03"
                    }

                    if(element[8].toLowerCase() == "main"){
                        playbackStreamType = "01"
                    }else if(element[8].toLowerCase() == "sub"){
                        playbackStreamType = "02"
                    }else if(element[8].toLowerCase() == "ter"){
                        playbackStreamType = "03"
                    }

                }else{
                    if(element[7].toLowerCase() == "main"){
                        liveStreamType = "0"
                    }else if(element[7].toLowerCase() == "sub"){
                        liveStreamType = "1"
                    }else if(element[7].toLowerCase() == "ter"){
                        liveStreamType = "2"
                    }

                    if(element[8].toLowerCase() == "main"){
                        playbackStreamType = "0"
                    }else if(element[8].toLowerCase() == "sub"){
                        playbackStreamType = "1"
                    }else if(element[8].toLowerCase() == "ter"){
                        playbackStreamType = "2"
                    }
                }
let templength = MULTI_TIME_ZONE ? 18 : 17
                if (element && element.length == templength) {
                    if (/^\d+$/.test(element[6])) {
                    }
                    else {
                        iserror = true
                    }
                    var rtspURL = null

                    if (element[0] && element[0].trim().toLowerCase() == 'hikvision') {
                        rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/Streaming/Channels/" + element[6] + liveStreamType
                    }
                    else if (element[0] && element[0].toLowerCase() == 'cp plus') {
                        // rtspURL = "rtsp://" + element[3] + ":" + element[4].replace("@", "%40") + "@" + element[1] + ":" + element[5] + "/cam/realmonitor?channel=" + element[6] + "%38subtype=" + element[7]
                        rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/cam/realmonitor?channel=" + element[6] + "%38subtype=" + liveStreamType 
                    }
                    else if (element[0] && element[0].toLowerCase() == 'dahua') {
                        rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/cam/realmonitor?channel=" + element[6] + "%26subtype=" + liveStreamType 
                    }
                    else if (element[0] && element[0].toLowerCase() == 'sparsh') {
                        rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/avstream/channel=" + element[6] + "/stream=" +liveStreamType+".sdp"
                    }
                    else if (element[0] && element[0].toLowerCase() == 'dlink') {
                        //RTSP=>    rtspURL = `rtsp://${userName}:${password}@${privateIPAddress}:${port}/avstream/channel=${channelNumber}/stream=0.sdp`

                        rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/avstream/channel=" + element[6] + "/stream=" +liveStreamType+".sdp"
                    }
                    else if (element[0] && element[0].toLowerCase() === 'unv') {
                        if (selectedCamHeaders['cctvtype'] == "4") {
                            //rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/address/media/video=" + element[6] + "/video";
                            // rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/address/media/video" + element[6];
                            if(liveStreamType != ""){
                                liveStreamType = parseInt(liveStreamType) + 1;
                            }
                            if(playbackStreamType != ""){
                                playbackStreamType = parseInt(playbackStreamType) + 1;
                            }
                            rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/media/video" + liveStreamType;
                        }
                        else {
                            // rtspURL = "rtsp://" + element[3] + ":" + element[4] + "@" + element[1] + ":" + element[5] + "/unicast/c" + element[6] + "/s0/live";
                            rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/unicast/c" + element[6] + "/s" + liveStreamType+ "/live";
                        }
                        // rtsp://192.168.1.100:554/rtsp/streaming?channel=1&subtype=0&token=123
                    }
                    else if (element[0] && element[0].toLowerCase() == 'raysharp') {
                       // rtspURL = "rtsp://" + element[3] + ":" + element[4] + "@" + element[1] + ":" + element[5] + "/rtsp/streaming?channel=" + element[6] + "&subtype=0"; --new
                       //rtspURL = "rtsp://" + element[3] + ":" + element[4] + "@" + element[1] + ":" + element[5] + "/rtsp/streaming?channel=" + element[6] + "&subtype=1", --new

                       rtspURL = "rtsp://" + element[3] + ":" + element[4] + "@" + element[1] + ":" + element[5] + "/rtsp/streaming?channel=" + element[6] + "&subtype=" + liveStreamType;

                        // rtspURL = "rtsp://" + element[3] + ":" + element[4] + "@" + element[1] + ":" + element[5] + "/rtsp/streaming?channel=" + element[6] + "&subtype=0&token=123";--old
                        //rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/rtsp/streaming?channel=" + element[6] + "&subtype=" + liveStreamType +"&token=123";
                    }
                    else if (element[0] && element[0].trim().toLowerCase() == 'hikvision-hybrid') {
                        rtspURL = "rtsp://" + element[3] + ":" + element[4].replaceAll("@", "%40") + "@" + element[1] + ":" + element[5] + "/Streaming/Channels/" + element[6] + liveStreamType
                    }
                    else {
                        isBrandNameAbsent = true;
                    }
                    var objToAppend = {
                        "brandName": element[0],
                        "privateIPAddress": element[1],
                        "communicationPort": element[2],
                        "userName": element[3],
                        "password": element[4],
                        "port": element[5],
                        "channelNumber": element[6],
                        "liveStreamTypeNumber": liveStreamType,
                        "playbackStreamTypeNumber": playbackStreamType,
                        "cameraName": element[9],
                        "siteName": element[10],
                        "isTranscoded": element[11],
                        "isPlaybackTranscoded": element[12],
                        "rtspURL": rtspURL,
                        "cctvtype": element[13],
                        "httpPort": element[14],
                        "isTwoWayEnabled": element[15],
                        "ptz":element[16]

                    }
                    if(MULTI_TIME_ZONE){
                        objToAppend.timezone = element[17]  
                    }
                    deviceDataObj.push(objToAppend)
                    let newObjToAppend = {
                        "brandName": element[0],
                        "privateIPAddress": element[1],
                        "communicationPort": element[2],
                        "userName": element[3],
                        "password": element[4],
                        "port": element[5],
                        "channelNumber": element[6],
                        "liveStreamTypeNumber": liveStreamType,
                        "playbackStreamTypeNumber": playbackStreamType,
                        "cameraName": element[9],
                        "siteName": element[10],
                        "isTranscoded": element[11],
                        "isPlaybackTranscoded": element[12],
                        "cctvtype": element[13],
                        "httpPort": element[14],
                        "isTwoWayEnabled": element[15],
                        "rtspURL": rtspURL,
                        "ptz":element[16]
                          }
                    if(MULTI_TIME_ZONE){
                        newObjToAppend.timezone = element[17]  
                    }
                    newDeviceDataObj.push(newObjToAppend);
                }
            })
            if (iserror) {
                ErrorToast("In Channel Number only numbers are allowed");
                return
            }
            if (isBrandNameAbsent) {
                showErrorToast("Please provide a valid brand name in the uploaded file!!");
                return
            }
            var createdtime = ConvertDateToUTC(new Date())
            var objToPass = {
                "action": "cameraBulkImport",
                "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID,
                "userID": userid,
                "createdOn": createdtime,
                "fcmToken": fcmToken,
                "data": deviceDataObj
            }
            let newObjToPass = {
                "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID,
                "userID": userid,
                "createdOn": createdtime,
                "data": newDeviceDataObj
            }

    /* -------------------------- Bulk Upload New API4 -------------------------- */

            bulkUploadCamera(newObjToPass).then((resp) => {
                if (resp && resp.Status == "success") {
                    setClearImportedCamHeaders(prevState => !prevState);
                    setSelectedCamHeaders([])
                    setDeviceBulkUploadResponse(resp.Data);
                    getdeviceslistdata();
                    setImportedFileData(null);
                    showSuccessToast(resp.Message);
                } else {
                    setDeviceBulkUploadResponse(resp.Data);
                    showErrorToast('Device bulk upload failed!')
                }
            })
        }
        else {
            let camHeadObj = {
                brand: null,
                ipaddress: null,
                branch: null,
                username: null,
                password: null,
                port: null,
                channelnumber: null,
                livestreamtype: null,
                playbackstreamtype: null,
                devicename: null,
                communicationport: null,
                httpport: null,
                enablelivetranscoding: null,
                enableplaybacktranscoding: null,
                enabletwowaydevice: null,
                cctvtype: null,
                ptz:null
            }
            if(MULTI_TIME_ZONE){
                camHeadObj.timezone = null 
            }
            setSelectedCamHeaders(camHeadObj)
            setImportedFileData(null)
            showWarningToast('Imported file headers cannot be empty!')
        }
    }
    //#endregion Camera Bulk upload

    //#region panel bulk upload
    const onPanelBulkUpload = (event, importedFileHeaders) => {
        if (selectedHeaders && importedFileData && importedFileHeaders) {

            var brandHead = selectedHeaders['brand']
            var ipHead = selectedHeaders['ipaddress']
            var branchHead = selectedHeaders['branch']
            var name = selectedHeaders['name']
            var zoneTypeHead = selectedHeaders['zonetype']
            var zoneNumberHead = selectedHeaders['zonenumber']
            var zoneNameHead = selectedHeaders['zonename']
            var interfaceNameHead = selectedHeaders['interfacename']
            var interfacePortHead = selectedHeaders['portnumber']
            var interfaceuserNameHead = selectedHeaders['interfaceusername']
            var interfacePasswordHead = selectedHeaders['interfacepassword']
            var timezone = selectedHeaders['timezone']


            if (brandHead === null) { showErrorToast('Please select brand name header!'); return }
            if (ipHead === null) { showErrorToast('Please select IP address header!'); return }
            if (branchHead === null) { showErrorToast('Please select branch name header!'); return }
            if (name === null) { showErrorToast('Please select panel name header!'); return }

            if (zoneTypeHead === null) { showErrorToast('Please select zone type header!'); return }
            if (zoneNumberHead === null) { showErrorToast('Please select zone number header!'); return }
            if (zoneNameHead === null) { showErrorToast('Please select zone name header!'); return }
            // if (interfaceNameHead === null) { showErrorToast('Please select interface name header!'); return }
            if (interfacePortHead === null) { showErrorToast('Please select port number header!'); return }
            if (interfaceuserNameHead === null) { showErrorToast('Please select interface username header!'); return }
            if (interfacePasswordHead === null) { showErrorToast('Please select interface passoword header!'); return }
            if(MULTI_TIME_ZONE){
                if(timezone === null) { showErrorToast('Please select timezone!'); return }
            }

            var tempError = "";
            var panelDataObj = []
            Object.keys(importedFileData).map((item, index) => {
                var tempError1 = "";
                var objToAppend = {
                    "brand": importedFileData[item][0],
                    "ipAddress": importedFileData[item][1],
                    "branch": importedFileData[item][2],
                    "name": importedFileData[item][3],
                    "zoneType": importedFileData[item][4],
                    "zoneName": importedFileData[item][5],
                    "zoneNumber": importedFileData[item][6],
                    // "interfaceName": importedFileData[item][7],
                    "portNumber": importedFileData[item][7],
                    "interfaceUsername": importedFileData[item][8],
                    "interfacePassword": importedFileData[item][9],
                }
                if(MULTI_TIME_ZONE){
                    objToAppend.timezone = importedFileData[item][10]
                }
                panelDataObj.push(objToAppend)
                if (importedFileData[item][0] === "" || importedFileData[item][1] === "" || importedFileData[item][2] === "" || importedFileData[item][3] === "" || importedFileData[item][4] === "" || importedFileData[item][5] === "" || importedFileData[item][6] === "" || importedFileData[item][7] === "" || importedFileData[item][8] || importedFileData[item][9] || 
                    importedFileData[item][6] < 1 || importedFileData[item][6] > 256
                ) {
                    tempError1 += ``

                    if (importedFileData[item][0] === "") { tempError1 += "Brand cannot be blank." }
                    if (importedFileData[item][1] === "") { tempError1 += "IP Address cannot be blank." }
                    if (importedFileData[item][2] === "") { tempError1 += "Branch cannot be blank." }
                    if (importedFileData[item][3] === "") { tempError1 += "Name cannot be blank." }
                    if (importedFileData[item][4] === "") { tempError1 += "Zone Type cannot be blank." }
                    if (importedFileData[item][5] === "") { tempError1 += "Zone Name cannot be blank." }
                    if (importedFileData[item][6] === "") { tempError1 += "Zone Number cannot be blank." }
                    if (importedFileData[item][6] < 1 || importedFileData[item][6] > 256) { tempError1 += "Please enter a valid zone number! In the range of 1 to 256." }
                    // if (importedFileData[item][7] === "") { tempError1 += "Interface Name cannot be blank." }
                    if (importedFileData[item][7] === "") { tempError1 += "Port number cannot be blank." }
                    if (importedFileData[item][8] === "") { tempError1 += "Interface username cannot be blank." }
                    if (importedFileData[item][9] === "") { tempError1 += "Interface password cannot be blank." }
                    if(MULTI_TIME_ZONE){
                        if (importedFileData[item][10] === "") { tempError1 += "Timezone cannot be blank." }
                    }

                    // const isValid = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(keyValue);

                    const interfacePattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
                    if (interfacePattern.test(importedFileData[item][1]) == false) {
                        tempError1 += "Please enter Valid interface IP!."
                    }

                    const panelNamePattern = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(importedFileData[item][3]);
                    if (!panelNamePattern) {
                        tempError1 += "Please enter Valid Panel name!."
                      }

                    // const zoneNumberPattern = new RegExp("/^[1-9]\d{0,2}$|256$/").test(importedFileData[item][6]);
                    // if (!zoneNumberPattern) {
                    //     tempError1 += "Please enter a valid zone number! In the range of 1 to 256."
                    // }

                    const zoneNamePattern = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(importedFileData[item][5]);
                    if (!zoneNamePattern) {
                        tempError1 += "Please enter Valid Zone name!."
                      }


                      const portNumberPattern = new RegExp("[0-9]").test(importedFileData[item][7]);
                    if (!portNumberPattern) {
                        tempError1 += "Please enter Valid Port number!."
                      }

                    tempError += tempError1
                    if(MULTI_TIME_ZONE){
                        importedFileData[item][11] = tempError1
                    }else{
                        importedFileData[item][10] = tempError1
                    }
                }

            })
            if (tempError !== "") {
                setPanelBulkUploadError(importedFileData)
                showWarningToast("Please check the error details in the given link and correct data in the CSV file.");
                return;
            }

            var createdtime = ConvertDateToUTC(new Date())
            var objToPass = {
                "action": "panelBulkImport",
                "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID,
                "userID": userid,
                "createdOn": createdtime,
                "fcmToken": fcmToken,
                "data": panelDataObj
            }
            // Dot Net API for Panel Bulk Upload.
            let newobjToPass = {
                "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID,
                "userID": userid,
                "createdOn": createdtime,
                "data": panelDataObj
            }

            bulkUploadPanel(newobjToPass).then((resp) => {
                if (resp && resp.Status === "success") {
                    setClearImportedPanelHeaders(prevState => !prevState)
                    setPanelBulkUploadResponse(resp.Data);
                    showSuccessToast(resp.Message);
                }
                else {
                    setPanelBulkUploadResponse(resp.Data);
                    showWarningToast("Please check the error details in the given link and correct data in the CSV file.")
                }
            })
        }
        else {
            let panelHeadObj = {
                brand: null,
                ipaddress: null,
                branch: null,
                zonetype: null,
                zonenumber: null,
                zonename: null,
                portnumber: null,
                interfaceusername:null,
                interfacepassword : null            
            };
            if(MULTI_TIME_ZONE){
                panelHeadObj.timezone = null
            }
            setSelectedHeaders(panelHeadObj)
            setImportedFileData(null)
            showWarningToast('Imported file headers cannot be empty!')
        }

        if (importedFileData) {
            Object.keys(importedFileData).map((item, index) => {
                console.log("importedFileData", importedFileData[item])

            })
        }

        if (selectedHeaders) {
            Object.keys(selectedHeaders).map((item, index) => {
                console.log("selectedHeaders", selectedHeaders[item])

            })
        }

        // var headArray=[]
        if (event) {

            Object.keys(event).map((item, index) => {
                console.log("header data", event[item])

            })
        }
    }
    //#endregion panel bul import

    const onAcsBulkUpload = (event, importedFileHeaders) => {
        console.log("onAcsBulkUpload", event)
        console.log("onAcsBulkUpload", importedFileHeaders)
        console.log("onAcsBulkUpload", importedFileData)
        console.log("onAcsBulkUpload", selectedAcsHeaders)

        if(selectedAcsHeaders && importedFileData && importedFileHeaders){
            let brandHead = selectedAcsHeaders['brand']
            let branchHead = selectedAcsHeaders['branch']
            let ipHead = selectedAcsHeaders['ipaddress']
            let interfacePortHead = selectedAcsHeaders['portnumber']
            let interfaceuserNameHead = selectedAcsHeaders['username']
            let interfacePasswordHead = selectedAcsHeaders['password']
            let controllername = selectedAcsHeaders['controllername']
            let deviceNameHead = selectedAcsHeaders['devicename']
            let identifierHead = selectedAcsHeaders['identifier']
            let timezone = selectedAcsHeaders['timezone']

            if (brandHead === null) { showErrorToast('Please select brand name header!'); return }
            if (branchHead === null) { showErrorToast('Please select branch name header!'); return }
            if (ipHead === null) { showErrorToast('Please select IP address header!'); return }
            if (interfacePortHead === null) { showErrorToast('Please select port number header!'); return }
            if (interfaceuserNameHead === null) { showErrorToast('Please select username header!'); return }
            if (interfacePasswordHead === null) { showErrorToast('Please select passoword header!'); return }
            if (controllername === null) { showErrorToast('Please select controllername header!'); return }
            if (deviceNameHead === null) { showErrorToast('Please select devicename header!'); return }
            if (identifierHead === null) { showErrorToast('Please select identifiername header!'); return }
        
            if(MULTI_TIME_ZONE){
                if(timezone === null) { showErrorToast('Please select timezone!'); return }
            }

            let tempError = "";
            let panelDataObj = []
            Object.keys(importedFileData).map((item, index) => {
                var tempError1 = "";
                var objToAppend = {
                    "brand": importedFileData[item][0],
                    "branch": importedFileData[item][1],
                    "ipaddress": importedFileData[item][2],
                    "portnumber": importedFileData[item][3],
                    "username": importedFileData[item][4],
                    "password": importedFileData[item][5],

                    "controllername": importedFileData[item][6],
                    "devicename": importedFileData[item][7],
                    "identifier": importedFileData[item][8],
                }
                if(MULTI_TIME_ZONE){
                    objToAppend.timezone = importedFileData[item][9]
                }
                panelDataObj.push(objToAppend)
                if (importedFileData[item][0] === "" || importedFileData[item][1] === "" || importedFileData[item][2] === "" || importedFileData[item][3] === "" || importedFileData[item][4] === "" || importedFileData[item][5] === "" || importedFileData[item][6] === "" || importedFileData[item][7] === "" || importedFileData[item][8]
                ) {
                    tempError1 += ``

                    if (importedFileData[item][0] === "") { tempError1 += "Brand cannot be blank." }
                    if (importedFileData[item][1] === "") { tempError1 += "Branch cannot be blank." }
                    if (importedFileData[item][2] === "") { tempError1 += "IP Address cannot be blank." }
                    if (importedFileData[item][3] === "") { tempError1 += "Port number cannot be blank." }
                    if (importedFileData[item][4] === "") { tempError1 += "Username cannot be blank." }
                    if (importedFileData[item][5] === "") { tempError1 += "Password cannot be blank." }
                    if (importedFileData[item][6] === "") { tempError1 += "Controller name cannot be blank." }
                    if (importedFileData[item][7] === "") { tempError1 += "Device name cannot be blank." }
                    if (importedFileData[item][8] === "") { tempError1 += "Identifier cannot be blank." }
                    if(MULTI_TIME_ZONE){
                        if (importedFileData[item][9] === "") { tempError1 += "Timezone cannot be blank." }
                    }

                    // const isValid = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(keyValue);

                    const interfacePattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
                    if (interfacePattern.test(importedFileData[item][2]) == false) {
                        tempError1 += "Please enter Valid interface IP!."
                    }

                    const portNumberPattern = new RegExp("[0-9]").test(importedFileData[item][3]);
                    if (!portNumberPattern) {
                        tempError1 += "Please enter Valid Port number!."
                      }

                    const controllerName = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(importedFileData[item][6]);
                    if (!controllerName) {
                        tempError1 += "Please enter valid acs controller name!."
                    }

                    const acsName = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(importedFileData[item][7]);
                    if (!acsName) {
                        tempError1 += "Please enter valid acs device name!."
                    }

                    const identifierName = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(importedFileData[item][8]);
                    if (!identifierName) {
                        tempError1 += "Please enter valid acs identifier name!."
                    }


                    tempError += tempError1
                    if(MULTI_TIME_ZONE){
                        importedFileData[item][10] = tempError1
                    }else{
                        importedFileData[item][9] = tempError1
                    }
                }
            })   

            if(tempError != ""){
                setAcsBulkUploadError(importedFileData)
                showWarningToast("Please check the error details in the given link and correct data in the CSV file.");
                return;
            }
            var createdtime = ConvertDateToUTC(new Date())
            let newobjToPass = {
                "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID,
                "userID": userid,
                "createdOn": createdtime,
                "data": panelDataObj
            }
            bulkUploadACS(newobjToPass).then((resp) => {
                if (resp && resp.Status === "success") {
                    setClearImportedAcsHeaders(prevState => !prevState)
                    setAcsBulkUploadResponse(resp.Data);
                    showSuccessToast(resp.Message);
                    getdeviceslistdata();
                }
                else {
                    setAcsBulkUploadResponse(resp.Data);
                    showWarningToast("Please check the error details in the given link and correct data in the CSV file.")
                }
            })
        }
        else {
            let panelHeadObj = {
                brand: null,
                branch: null,
                ipaddress: null,
                portnumber: null,
                username: null,
                password: null,
                controllername: null,
                devicename: null,
                identifier: null,                
            };
            if(MULTI_TIME_ZONE){
                panelHeadObj.timezone = null
            }
            setSelectedAcsHeaders(panelHeadObj)
            setImportedFileData(null)
            showWarningToast('Imported file headers cannot be empty!')
        }
    }

    //#region associate camera device to sensor
    const onSensorSelection = (sensor, devicedt) => {
        try {
            if (sensor) {
                setSelectedSensor(sensor)
                var obj1 = {
                    "action": "getAssociatedCamerasList",
                    "deviceId": devicedt.ParentCameraID,
                    "idsPanelZoneTypeID": sensor.iDSPanelZoneTypeID,
                    "userUniqueId": null,
                    "clientId": devicedt.clientId,
                    "branchId": devicedt.BranchID
                }
                deviceconfigcrud(obj1).then((resps) => {
                    if (resps && resps.status === "Ok") {
                        setAssociatedDevices(null)

                        if (resps.message && resps.message.length > 0) {
                            const selDevList = []
                            for (let i = 0; i < resps.message.length; i++) {
                                const device = resps.message[i];
                                if (device.isSelected == true) {
                                    selDevList.push(device)
                                }
                            }
                            if (selDevList.length > 0) {
                                setSelectedItems(selDevList.map(item => item.deviceId));
                            }
                            else {
                                setSelectedItems([])
                            }
                            setAssociatedDevices(resps.message)
                        }
                        else {
                            setSelectedItems([])
                        }
                    }
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onAssociate = (selectedDevice) => {
        if(selectedItems.length > 4){
            ErrorToast(`Maximum only 4 cameras can be associated, you have associated ${selectedItems.length} cameras`)
            return;
        }
        var createdtime = ConvertDateToUTC(new Date())
        var obj = {
            "action": "associateCamerasToSensor",
            "associatedCameraIds": selectedItems,
            "deviceId": selectedDevice.ParentCameraID,
            "idsPanelZoneTypeId": selectedSensor.iDSPanelZoneTypeID,
            "createdBy": userid,
            "createdOn": createdtime,
            "userUniqueId": null,
            "isHooterEnabledInterface": selectedDevice.isHooterEnabledInterface
        }
        deviceconfigcrud(obj).then((resp) => {
            if (resp && resp.status === "Ok") {
                showSuccessToast(resp.message)
            }
            else {
                showErrorToast('Error occurred while associating the device!')
            }
        })
    }

    const setFirstSensor = (device) => {
        if (device.zonesList && device.zonesList.length > 0) {
            const firstZone = device.zonesList[0]
            if (firstZone) {
                onSensorSelection(firstZone, device)
            }
        }
    }
    //#endregion

    //#region delete camera
    //#region delete device S
    async function checkForAlertsAndDelDevice(item) {
        console.log("checkForAlertsAndDelDevice", item)
        try {
            if(item.DeviceTypeID == 3 || item.DeviceTypeID == 4 || item.DeviceTypeID == 5){
                // Check if the device is the first in the acs device list.
                console.log("checkForAlertsAndDelDevice", acsDevicesList)
                if(item.ParentCameraID == acsDevicesList[0].ParentCameraID && acsDevicesList.length > 1){
                    // It means it is the first acs device and there are other devices also that are present.
                    Swal.fire({
                        title: "Unable to delete",
                        text: "Kindly delete the other acs devices present in the list before deleting the first one.",
                        icon: "warning",
                        showCancelButton: true,
                        showConfirmButton: true,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false
                    }).then((result) => {

                    })
                    return
                }
            }
            checkalertassociated(item.ParentCameraID).then((resp) => {
                if (resp) {
                    if (resp.status == "Ok") {
                        Swal.fire({
                            title: "Delete Device Permanently",
                            text: "Deleting device will also delete all alerts data associated with this device permanently, do you really want to continue deleting?",
                            icon: "warning",
                            showCancelButton: true,
                            showConfirmButton: true,
                            confirmButtonText: 'Continue',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                var myCurrentDatetime = new Date();
                                var _datetime = ConvertDate(myCurrentDatetime);
                                var obj = {
                                    "action": "delete",
                                    "fcmToken": fcmToken,
                                    "streamName": item.StreamName,
                                    "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID,
                                    "dateTimeNow": _datetime
                                }
                                let returnData = deviceconfigcrud(obj)
                                if (returnData != undefined) {
                                    returnData.then(resp => {
                                        if (resp.status == "Ok") {
                                            getdeviceslistdata()
                                            showSuccessToast('Device deleted successfully')
                                            setIsDeviceEdit(true)
                                        }
                                        else {
                                            showErrorToast('Something went wrong, please try again later!')
                                        }
                                    })
                                }
                            }
                        });
                    } else if (resp.message == "Alert Not Found") {
                        delCamDevicePermanently(item)
                    } else {
                        Swal.fire({ text: resp.message })
                    }
                }

            })
        } catch (error) {
            console.log(error)
        }
    }

    async function delCamDevicePermanently(item) {
        console.log("delCamDevicePermanently", item);
        if (item != undefined) {
            if (item != null) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover this device details in future!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok',
                    dangerMode: true,
                    allowOutsideClick: false,
                }).then((willMove) => {
                    if (willMove.isConfirmed) {
                        var myCurrentDatetime = new Date();
                        var _datetime = ConvertDate(myCurrentDatetime);
                        var obj = {
                            "action": "delete",
                            "fcmToken": fcmToken,
                            "streamName": item.StreamName,
                            "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID,
                            "dateTimeNow": _datetime
                        }
                        let returnData = deviceconfigcrud(obj)
                        if (returnData != undefined) {
                            returnData.then(resp => {
                                if (resp.status == "Ok") {
                                    let temp = 0, otherTemp = 0;
                                    if (selectedInterface.InterfacetypeId == 1) {
                                        let arr = devicesmasterlist.cameraTypeMasterList.filter(item => item.brandName == selectedInterface.Interfacesubtypename);
                                        temp = arr[0].brandId;
                                    } else {
                                        let arr = devicesmasterlist.panelTypeMasterList.filter(item => item.panelName == selectedInterface.Interfacesubtypename);
                                        otherTemp = arr[0].panelMasterID
                                    }
                                    let deviceObject = {
                                        "ParentCameraID": 0,
                                        "cameraName": "",
                                        "privateIPAddress": selectedInterface.InterfaceIP,
                                        "SiteID": selectedInterface.siteid,
                                        "userName": selectedInterface.UserName,
                                        "password": selectedInterface.Password,
                                        "port": selectedInterface.InterfacePort,
                                        "channelNumber": '',
                                        // "playbackChannelNumber": '',
                                        "liveStreamTypeNumber": '',
                                        "playbackStreamTypeNumber": '',
                                        "rTSPURL": '',
                                        "location": '',
                                        "PanelMasterId": otherTemp,
                                        "CameraBrandId": temp,
                                        "communicationPort": '',
                                        "httpPort": '',
                                        "isTranscoded": 0,
                                        "isPlaybackTranscoded": 0,
                                        "isTwoWayEnabledDevice": 0,
                                        "CCTVTypeID":  selectedInterface.EntityTypeID,
                                        "TimeZoneIdDevice": selectedInterface.TimeZoneId,
                                        "isPTZ": 0,
                                        "isPlaybackUTC": 0,
                                        "identifierName": ""
                                    }
                                    setSelectedDevice(deviceObject);
                                    // setSelectedDevice(deviceObj)
                                    setIsDeviceEdit(true)
                                    if(item.DeviceTypeID == 3){
                                        showSuccessToast("ACS device deleted successfully.")
                                    }else{
                                        showSuccessToast(resp.message)
                                    }
                                    getdeviceslistdata(); //refresh device list
                                    sessionStorage.removeItem('Page_Refresh_State');
                                }
                                else {
                                    showErrorToast('Something went wrong, please try again later!')
                                    // Swal.fire({ text: 'Something went wrong, please try again later!' })
                                }
                            })
                        }
                    }
                });
            }
        }
    }
    //#region delete device E
    //#endregion

    //#endregion
    //#region Side effects
    const ChangesConfigTab = () => {
        if (history.location.state != undefined) {
            if (history.location.state.from == "Workflow Page") {
                setInitialTemplate(sidebarList.alertTemplate)
                onSubPageNavigate(sidebarList.alertTemplate.values[1])
                setSelectedMenu("Alert Template");
                setSelectedSubMenu("Manage Workflow");
            }
        }
    }

    //#endregion

    //#region SubPage Navigation
    const onSubPageNavigate = (section) => {
        //tej 17112021 S
        if (section == "Manage Site Groups") {

            if (siteGroups && siteGroups.length > 0) {
                setDefaultSelSiteGroup(siteGroups[0])
            }
        }
        if (section == "FR Assignment") { //User groups

            if (siteGroups && siteGroups.length > 0) {
                setDefaultSelFRSiteGroup(siteGroups[0])
            }
        }
        if (section == "Site Configuration") {
            // setSelectedSite(siteObj)
            let tempSiteObj = {
                "SiteID": 0,
                "SiteName": '',
                "BranchID": '',
                "SiteAddress": '',
                "clientID": 0
            }
            //            setSelectedSite(tempSiteObj);
            if ((selectedSite && selectedSite.SiteID == 0) || isItemClick == false || isItemClick == undefined) {
                setERTSectionVisible(false)
                // setSelectedSite(siteObj)
                // setIsSiteEdit(true)
                // alert('hey')
                onSelectSite(0, false)
                SendMixpanelEvents(section)
                return
            }

        }

        if (section == "Add Alert Template") {
            if (isItemClick == false || isItemClick == undefined) {
                setSelectedTemplate(templateObj)
                setIsAlertTemplateEdit(true)
            }
        }

        if (section == "Add User") {
            if (isItemClick == false || isItemClick == undefined) {
                // onSelectUser(0, false)
                setSelectedUser(userObj)
                setIsUserEdit(true)
            }
        }


        if (section == "Manage Devices") {

            setSelectedDevice(deviceObj)
            setBulkDevToUpload([])

        }

        if (section == "Manage Workflow") {
            // setIsAlertTemplateEdit(true)
        }
        //tej 17112021 E

        setPageState(section)
        SendMixpanelEvents(section)

    }

    const SendMixpanelEvents = (selectedconfig) => {
        try {
            switch (selectedconfig) {
                case "Site Configuration":
                    mixpanel.track("Clicks on Site Configuration Templates")
                    break;
                case "Manage Devices":
                    mixpanel.track("Clicks on Add Devices")
                    break;
                case "Add Alert Template":
                    mixpanel.track("Clicks on Add Alert Template")
                    break;
                case "Manage Workflow":
                    mixpanel.track("Clicks on AManage Workflow Template")
                    break;
                case "Add User":
                    mixpanel.track("Clicks on Add User")
                    break;
                case "Manage Privileges":
                    mixpanel.track("Clicks on Manage Privileges")
                    break;
                default:
                    break
            }
        } catch (error) {
            console.log(error)
        }
    }
    //#endregion

    const onMoveNext = (step) => {
        if (step == "review") { mixpanel.track("Clicks on Review Configuration") }
        if (step == "notification") {
            setInitialTemplate(sidebarList[step])
            setSelectedMainMenu(sidebarList[step].name)
            setIsNotifFormEnabled(true);
            setIsEmptyForm(true);      
        }
        else if(step == "prePostSetting"){
            setInitialTemplate(sidebarList[step])
            setSelectedMainMenu(sidebarList[step].name)
            setIsNotifFormEnabled(true);
            setIsEmptyForm(true);
        }
        else if(step == "emailSetting"){
            setInitialTemplate(sidebarList[step])
            setSelectedMainMenu(sidebarList[step].name)
            setIsNotifFormEnabled(true);
            setIsEmptyForm(true);
        }
        else if(step == "panelSetting"){
            setInitialTemplate(sidebarList[step])
            setSelectedMainMenu(sidebarList[step].name)
            setIsNotifFormEnabled(true);
            setIsEmptyForm(true);
        }
        else if(step == "soundSetting"){
            setInitialTemplate(sidebarList[step])
            setSelectedMainMenu(sidebarList[step].name)
            setIsNotifFormEnabled(true);
            setIsEmptyForm(true);
        }
         else {
            let abc = sidebarList[step]?.values?.filter(x => location.pathname.includes(x.replace(/\s/g, "")?.toLowerCase()))[0];
            setInitialTemplate(sidebarList[step])
            setSelectedMainMenu(sidebarList[step]?.name) //RupeshG
            setSelectedSubMenu(abc ? abc : sidebarList[step].values[0])
            onSubPageNavigate(abc ? abc : sidebarList[step].values[0])
        }
    }

    const onSelectUser = async (user, isUseItemSelected) => {
        setPageState('Add User')
        // setIsItemClick(isUseItemSelected)
        if (isUserEdit) {
            if (userRef.current != undefined) {
                if (userRef.current.isDataChanged()) {
                    await Swal.fire({
                        title: 'Are you sure?',
                        text: "Do you want to cancel the changes made?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#027aaa',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Leave!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            selectUser(user)
                        }
                    })
                }
            } else { selectUser(user) }
        } else {
            selectUser(user)
        }
    }

    const selectUser = (user) => {
        //for new site
        if (user.UserID === 0) {
            setSelectedUser(userObj)
            setIsUserEdit(true)
            return
        }
        console.log("user",user)

        //for already present user
        if (user !== undefined) {
            let tempUserObj = {
                AlertInnerCount: 0,
                AlertOuterCount: 0,
                DeviceCount: '',
                Email: user.Email,
                FirstName: user.FullName,
                LastName: '',
                OperatorCount: 0,
                Password: '',
                Phone: user.PhoneNumber,
                PrivilegeGrpID: 0,
                PrivilegeGrpTitle: '',
                ProfileImgPath: '',
                ProfileName: '',
                RoleName: user.RoleName,
                UserID: user.UserId,
                UserName: user.Username,
                UserRoleID: user.RoleId,
                isMobileLoginAllowed: user.IsMobileLoginAllowed,
                isWebLoginAllowed: user.IsWebLoginAllowed,
                dataAccessGroupId: user.DataAccessGroupName,
                isEnabled: !user.IsEnabled,
                EmailConfirmed: user.EmailConfirmed,
                TimeZoneId:user.TimeZoneID
            }

            setSelectedUser(tempUserObj);
            setSelectedDataAccessGroupId(user.DataAccessGroupId);
            setIsUserEdit(false)
            setIsItemClick(true)
        }
    }

    // check for phone nos with already present users
    const checkPhoneNoAlreadyExists = (phoneno, userid) => {
        let isfound = false
        return false;
        if (users != null) {
            let userWithSamePhoneNo = users.find(x => x.Phone.trim() == phoneno && x.UserID != userid)
            if (userWithSamePhoneNo != undefined) { isfound = true }
        }
        return isfound
    }

    const onSaveUser = async (data, user) => {
        console.log("onSaveUser", data);
        setButtonDisable(true)
        let validationError = "";

        if (data.FirstName.trim() == "") {
            validationError += "name-Please enter user name!," 
            // showErrorToast('Please enter user name!'); 
            // return 
        }
        if (data.Email.trim() == "") { 
            validationError += "email-Please enter the email!,"
            // showErrorToast('Please enter the email!'); 
            // return 
        } else {
            const emailRegex = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "gm");
            const isValidEmail = emailRegex.test(data.Email.trim());
            if (!isValidEmail) { 
                validationError += "email-Please enter a valid email id!,"
                // showErrorToast("Please enter a valid email id"); 
                // return 
            }
        }
        // const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
        if (data.Phone.trim() == "") { 
            validationError += "contact-Please enter the contact number!,"
            // showErrorToast('Please enter the contact number!'); 
            // return 
        } else {
            if (!IsPhoneNoValid(data.Phone)) { 
                validationError += "contact-Please enter a valid contact number!,"
                // showErrorToast('Please enter a valid contact number!'); 
                // return 
            }
        }
        // if (checkPhoneNoAlreadyExists(data.Phone, user.UserID)) { showErrorToast('User already exist with same contact number!'); return }
        if (data.UserRoleID == 0) { 
            validationError += "userrole-Please select a role!,"
            // showErrorToast("Please select a role!"); 
            // return; 
        }
        if(MULTI_TIME_ZONE){
            if (data.TimeZoneId == 0) { 
                validationError += "timezone-Please select a Timezone!,"
                // showErrorToast("Please select a role!"); 
                // return; 
            }
        }
        
        if (data.Password.trim() == "") {
            validationError += "password-Please enter the password!,"
            // showErrorToast('Please enter the password!'); 
            // return 
        } else {
            if (isNotValidPassword && user.UserID === 0) {
                validationError += "valid password-Please enter a valid password!," 
                // showErrorToast("Please enter a valid password"); 
                // return 
            }
        }
        
        if (selectedDataAccessGroupId == null && (data.dataAccessGroupId == "" || data.dataAccessGroupId == null)) { 
            validationError += "access role-Please select a data access role!,"
            // showErrorToast('Please select a data access role '); 
            // return 
        }

        setInputValidationError(validationError);
            // setParentObj(tempObj);
            setSavebtnClick(true)
            if (validationError != "") {
                setButtonDisable(false)
                return;
            }

        if (user.UserID === 0) {
                /* --------------------- Add User Block for Dot Net API --------------------- */
                try {
                    let obj = {
                        "userId": "",
                        "username": data.Email,
                        "fullName": data.FirstName,
                        "email": data.Email,
                        "password": data.Password,
                        "phoneNumber": data.Phone,
                        "isMobileLoginAllowed": data.isMobileLoginAllowed,
                        "isWebLoginAllowed": data.isWebLoginAllowed,
                        "dataAccessGroupId": selectedDataAccessGroupId,
                        "roleId": parseInt(data.UserRoleID),
                        "isEnabled": !data.isEnabled,
                        "dataAccessGroupName": data.dataAccessGroupId,
                        "EmailConfirmed": "",
                        "TimeZoneId":parseInt(data.TimeZoneId),
                    }
                    let res = await createNewUser(obj);
                    if (res.Status === "success" || res.status === "Ok") {
                        const db = firebase.database();
                        const ref = db.ref();

                        var emailId = data.Email.replaceAll(".", "*");
                        var domain = BASE_URL + ",0";

                        var userinstance1 = {};
                        userinstance1["" + emailId + ""] = domain;

                        // Add new data
                        const new_data = {
                            userinstance: userinstance1,
                        };

                        // Append the new data to the existing data
                        try {
                            ref.child('userinstance').update(new_data.userinstance);
                        } catch (error) {
                            console.log('Error:', error);
                        }

                        getUsers()
                        showSuccessToast(res.Message || res.message);
                        setSelectedDataAccessGroupId(null);
                        setPageState("FR Assignment")
                        onSubPageNavigate("FR Assignment")
                        setSelectedSubMenu("FR Assignment")
                        getsiteGroupList();
                        let resObj = {
                            "Email": data.Email,
                            "Password": data.Password
                        }
                        let resp1 = await registerEmailUser(resObj);
                        if (resp1.status == "Ok") {
                            console.log("Firebase login successful.");
                        } else {
                            console.log("Firebase login not successful.");
                        }
                    } else {
                        showErrorToast(res.Message || res.message);
                    }
                } catch (error) {
                    showErrorToast(error)
                }
            } else {
                if ((isNotValidPassword && changePassword) || (isNotValidPassword && isPassUpdated)) {
                    showErrorToast("Please enter a valid password"); return;
                }
                /* -------------------- Update User Block for Dot Net API ------------------- */
                let userObj = {
                    "userId": "",
                    "username": data.Email,
                    "fullName": data.FirstName,
                    "email": data.Email,
                    "password": data.Password || "Test@1234",
                    "phoneNumber": data.Phone,
                    "isMobileLoginAllowed": data.isMobileLoginAllowed,
                    "isWebLoginAllowed": data.isWebLoginAllowed,
                    "dataAccessGroupId": selectedDataAccessGroupId,
                    "roleId": parseInt(data.UserRoleID),
                    "isEnabled": !data.isEnabled,
                    "dataAccessGroupName": data.dataAccessGroupId,
                    "EmailConfirmed": "",
                    "TimeZoneId":parseInt(data.TimeZoneId)
                }
                let res = await updateUser(userObj);
                if (res.Status == "success") {
                    setButtonDisable(false)
                    showSuccessToast(res.Message)
                    getUsers()
                    onSelectUser({ UserID: 0 })
                    getsiteGroupList();
                    setSelectedDataAccessGroupId(null);
                } else {
                    setButtonDisable(false)
                    showErrorToast(res.Message)
                }
            }                   
    }

    const onDeleteUser = (e, user) => {
        e.stopPropagation()
        var fieldResMsg = '<span class="custom-title-class-site-group">Delete ' + user.UserName + ' ?</span><br/>' +
            '<span class="custom-text-class-site-group">You cant undo this action.</span>' +
            '<div class="  swal-sitegroup-warning">' + '<span class="site-group-warning-head">' +
            '<img class="m-2" src="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/alert-warning.svg"/>' +
            '<span class="site-group-warning-head-title"> Warning </span></span><br/>' +
            '<span class="site-group-warning-msg">Deleting Field Responder will also delete corresponding Site Group reference!</span>' +
            '</div>'

        Swal.fire({
            customClass: {
                cancelButton: "site-group-cancel-button",
                confirmButton: "site-group-cancel-button delete-site-group-confirm-button"
            },

            html: user.UserRoleID && (user.UserRoleID == 4) ? '<span class="custom-title-class-site-group">Delete ' + user.UserName + ' ?</span><br/>' +
                '<span class="custom-text-class-site-group">You cant undo this action.</span>' +
                '<div class="  swal-sitegroup-warning">' + '<span class="site-group-warning-head">' +
                '<img class="m-2" src="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/alert-warning.svg"/>' +
                '<span class="site-group-warning-head-title"> Warning </span></span><br/>' +
                '<span class="site-group-warning-msg">Deleting Field Responder will also delete corresponding Site Group reference!</span>' +
                '</div>' : '<span class="custom-title-class-site-group">Delete ' + user.UserName + ' ?</span><br/>' +
                '<span class="custom-text-class-site-group">You cant undo this action.</span>' +
            '</div>'
            ,
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonColor: '#027aaa',
            cancelButtonColor: '#d33',
            confirmButtonText: user.UserRoleID && (user.UserRoleID == 4) ? 'Delete Field Responder' : 'Delete User',
            allowOutsideClick: false,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser(user.UserID).then((resp) => {
                    if (resp.status == "Ok") {
                        // props.AdduserOnSubmit()
                        onSelectUser({ UserID: 0 })
                        getUsers()
                        showSuccessToast(resp.message)
                        setSelectedSubMenu("Add User")
                        // Swal.fire({ text: resp.message })
                    } else {
                        Swal.fire({ text: 'Error occurred while deleting the user!' })
                    }
                })
            }
        })
    }

    const AddMixpanelEvent = (SeverityID, Headline) => {
        try {
            var severity_name = ""
            if (severities != null) {
                var sevrityname = severities.find(x => x.SeverityId == SeverityID)
                if (sevrityname) {
                    severity_name = sevrityname.SeverityTitle
                }
            }
            var createdtime = ConvertDateToUTC(new Date())
            mixpanel.track("Clicks on Add Alert Templates", {
                "AlertTemplateName": Headline,
                "Severity": severity_name,
                "CreatedOn": createdtime
            })
        } catch (error) {
            console.log(error)
        }
    }

    const onDeleteTemplate = async (e, id) => {
        e.stopPropagation()
        await Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete the template?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#027aaa',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                var obj = {
                    "action": "deleteTemplate",
                    "deviceTypeId": 0,
                    "alertTemplateId": id
                }
                AlertTemplateOperations(obj, id, "deleteTemplate")
            }
        })
    }

    const deleteAlertTemplate = () => {
        setButtonDisable(true);
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete the template?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#027aaa',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteAlertTemplate2(alertTemplateObj.templateId)
                .then(res=>{
                    if (res.Status == "success") {
                        setTemplateDelete(true)
                        setAlertTemplateObj(newAlertTemplateObj);
                        setAddNewClickedAlert(true);
                        setIsEmptyFormAlert(true);
                        setIsAlertTempFormEnabled(true);
                        setSelectedTemplate(templateObj)
                        setAlertTemplateObj(newAlertTemplateObj);
                        setIsAlertTemplateEdit(true)
                        setIsStore(false);
                        setActivityLogShow(false);
                        getEventTemplatesList()
                        setButtonDisable(false);
                    }else{
                        setButtonDisable(false);
                    }
                })
            }else{
                setButtonDisable(false);
            }
        })
    }

    let history = useHistory()

    //#region WF preview
    const handleWFPreviewClose = () => {
        setShowWF(false)
    }

    const onPreviewWF = (id) => {
        let wf = workflows.find(x => x.workflowID == id)
        if (wf != undefined) {
            setSelectedWF(wf)
            setShowWF(true)
        }
    }
    //#endregion

    //#region On Add new site group
    const onAddNewSiteGroup = async () => {
        let id = findMaxSiteGrouopId()
        let obj = {
            "action": "addSiteGroup",
            "groupTitle": `Unnamed Site Group (${id++})`,
            "createdBy": loggedInUser.UserID || loggedInUser.userId,
            "createdOn": ConvertDate(new Date())
        }
        await userGroupAndSiteGroupCRUD(obj).then((resp) => {
            if (resp.status == "Ok") {
                getsiteGroupList()
            } else {
                Swal.fire("Error while adding the site group")
            }
        })
    }

    const onUpdateSiteGroup = async (groupname, id) => {
        //check for same group name
        let obj = {
            "action": "updateSiteGroup",
            "siteGroupID": id,
            "groupTitle": groupname,
            "updatedBy": loggedInUser.UserID || loggedInUser.userId,
            "updatedOn": ConvertDate(new Date())
        }
        return await userGroupAndSiteGroupCRUD(obj).then((resp) => {
            if (resp.status == "Ok") {
                getsiteGroupList()
                Swal.fire({ text: resp.message })
            } else {
                Swal.fire({ text: resp.message })
            }
            return resp
        })
    }

    const selectedSiteTypeClick = (type) => {
        setselectedSiteTypeList(type)
    }

    const findMaxSiteGrouopId = () => {
        var arr = siteGroups
        if (arr && arr.length > 0) {
            var result = arr.reduce(function (acc, obj) {
                return (acc > obj.siteGroupID) ? acc : obj.siteGroupID;//acc + obj.siteGroupID;
            }, 0);
            return result
        }
    }
    //#endregion

    //#region User group add and update
    const onAddNewUserGroup = async () => {
        let id = findMaxUserGrouopId()
        let obj = {
            "action": "addUserGroup",
            "groupTitle": `Unnamed User Group (${id++})`,
            "createdBy": loggedInUser.UserID || loggedInUser.userId,
            "createdOn": ConvertDate(new Date())
        }
        await userGroupAndSiteGroupCRUD(obj).then((resp) => {
            if (resp.status == "Ok") {
                siteGroupOnClick(selectedSiteGroup)
            } else {
                Swal.fire("Error while adding the user group")
            }
        })
    }
    const onUpdateUserGroup = async (groupname, id) => {
        let obj = {
            "action": "updateUserGroup",
            "userGroupID": id,
            "groupTitle": groupname,
            "updatedBy": loggedInUser.UserID || loggedInUser.userId,
            "updatedOn": ConvertDate(new Date())
        }
        return await userGroupAndSiteGroupCRUD(obj).then((resp) => {
            if (resp.status == "Ok") {
                getsiteGroupList()
                Swal.fire({ text: resp.message })
            } else {
                Swal.fire({ text: resp.message })
            }
            return resp
        })
    }

    const findMaxUserGrouopId = () => {
        var arr = userGroups
        if (arr && arr.length > 0) {
            var result = arr.reduce(function (acc, obj) {
                return (acc > obj.userGroupID) ? acc : obj.userGroupID;
            }, 0);
            return result
        }
    }
    //#endregion

    const isZoneTypePresent = (str) => {
        return devicesmasterlist?.zoneTypeMasterList.some(item => item.zoneType.toLowerCase() === str)
    }

    const OnAddIDSPanel = async (data, parentCameraID, action, zoneNo) => {
        try {
            let myCurrentDatetime = new Date();
            let datetime = ConvertDate(myCurrentDatetime)
            let userUniqueId = loggedInUser.UserUniqueID || loggedInUser.userUniqueID
            if (action == "addPanelDevice" || action == "updatePanelDevice") {
                let panelname = ''
                if (data.cameraName) { panelname = data.cameraName.trim() }
                if (data.PanelMasterId == 0 || data.PanelMasterId == undefined || data.PanelMasterId == '') { showErrorToast('Select brand name!') }
                else if (panelname == "" || panelname == undefined || panelname == '') { showErrorToast("Enter panel name!") }
                else if (data.privateIPAddress == "" || data.privateIPAddress == '' || data.privateIPAddress == undefined) { showErrorToast("Enter IP address!") }
                else if (data.SiteID == 0) { showErrorToast('Select branch name!') }
                else {
                    var devObj = {
                        "action": action,
                        "panelMasterID": data.PanelMasterId,
                        "userUniqueId": userUniqueId,
                        "privateIp": data.privateIPAddress,
                        "panelName": panelname,
                        "dateTime": datetime,
                        "siteId": data.SiteID,
                        "deviceId": parentCameraID,
                        "interfaceId": selectedInterface.InterfaceId
                    }
                }
            } else if (action == "addPanelZones") {
                if (data.iDSPanelZoneTypeID == 0 || data.iDSPanelZoneTypeID == undefined) { showErrorToast("Please select zone type!") }
                else if (data.ZoneNo < 1 || data.ZoneNo > 256) { showErrorToast("Please enter a valid zone number! In the range of 1 to 256.") }
                else if (data.ZoneName == "") { showErrorToast("Please enter a zone name!") }
                else {
                    var devObj = {
                        "action": action,
                        "userUniqueId": userUniqueId,
                        "dateTime": datetime,
                        "iDSPanelZoneTypeID": data.iDSPanelZoneTypeID,
                        "zoneName": data.ZoneName,
                        "zoneNo": data.ZoneNo,
                        "deviceId": parentCameraID
                    }
                }
            } else if (action == "addPanelZoneTypeMaster") {
                if (data.trim() == "") {
                    showErrorToast("Enter zone type!")
                    return
                }
                let givenZoneType = data.trim().toLowerCase()
                if (isZoneTypePresent(givenZoneType)) {
                    showErrorToast("Zone Type already exists!")
                    return
                }
                var devObj = {
                    "action": action,
                    "userUniqueId": userUniqueId,
                    "dateTime": datetime,
                    "zoneType": data.trim()
                }
            } else if (action == "deletePanel") {
                var devObj = null
                await Swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to delete panel device",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#027aaa',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        devObj = {
                            "action": action,
                            "deviceId": parentCameraID,
                            "userUniqueId": userUniqueId
                        }
                        let deviceObj = {
                            "ParentCameraID": 0,
                            "SiteID": 0,
                        }
                        setSelectedDevice(deviceObj)
                        setIsDeviceEdit(true);
                    }
                })
            } else if (action == "deleteZone") {
                var devObj = null
                await Swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to delete zone device",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#027aaa',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        devObj = {
                            "action": action,
                            "deviceId": parentCameraID,
                            "idsPanelZoneTypeID": data,
                            "userUniqueId": userUniqueId,
                            "zoneNo": zoneNo
                        }
                        setselectedZone(zoneObj)
                    }
                })
            }
            else if (action == "updatePanelZone") {
                if (data.iDSPanelZoneTypeID == 0 || data.iDSPanelZoneTypeID == undefined) { showErrorToast("Please select zone type!") }
                else if (data.ZoneNo < 1 || data.ZoneNo > 256) { showErrorToast("Please enter a valid zone number! In the range of 1 to 256.") }
                else if (data.ZoneName == "") { showErrorToast("Please enter a zone name!") }
                else {
                    var devObj = {
                        "action": action,
                        "userUniqueId": userUniqueId,
                        "dateTime": datetime,
                        "userID": loggedInUser.UserID || loggedInUser.userId,
                        "idsPanelZoneTypeID": data.iDSPanelZoneTypeID,
                        "zoneName": data.ZoneName,
                        "zoneNo": data.ZoneNo,
                        "deviceId": parentCameraID,
                        "idsPanelZonesID": data.idsPanelZonesID
                    }
                }
            }
            if (devObj == null || devObj == undefined) { return }
            deviceconfigcrud(devObj).then((resp) => {
                if (resp && resp.status === "Ok") {
                    if (action == 'addPanelDevice') {
                        if (resp && resp.deviceId) {
                            let deviceObj = {
                                "ParentCameraID": 0,
                                "cameraName": '',
                                "privateIPAddress": '',
                                "SiteID": 0,
                                "userName": '',
                                "password": '',
                                "port": '',
                                "channelNumber": '',
                                "liveStreamTypeNumber": '',
                                "playbackStreamTypeNumber": '',
                                "rTSPURL": '',
                                "location": '',
                                "PanelMasterId": 0,
                                "CameraBrandId": 0,
                                "communicationPort" : '',
                                "httpPort" : '',
                                "isTranscoded" : '',
                                "isPlaybackTranscoded" : '',
                                "isTwoWayEnabledDevice" : '',
                                "CCTVTypeID": 0
                            }
                            setSelectedDevice(deviceObj)
                        }
                        setAssociateCamSecVisibility(true)
                        window.scrollTo({ top: 600, left: 0, behavior: 'smooth' });
                        getdeviceslistdata('addPanelDevice')
                    }
                    else{
                        setIsDeviceEdit(false);
                    }

                    if (action == "addPanelZones") {
                        let updatedObj = {
                            iDSPanelZoneTypeID: data.iDSPanelZoneTypeID,
                            idsPanelZonesID: resp.data[0],
                            zoneName: data.ZoneName,
                            zoneNo: data.ZoneNo,
                        }
                        setselectedZone(updatedObj)
                        getidsmasterslist();
                    }
                    getdeviceslistdata('addPanelZones')
                    if (action == "deletePanel") {
                        setAssociateCamSecVisibility(false)
                    }
                    if (action == "addPanelZoneTypeMaster") { getidsmasterslist() }
                    showSuccessToast(resp.message)
                }
                else {
                    showErrorToast(resp.message)
                    console.error(resp)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    //#endregion

    const AlertTemplateOperations = async (data, parentCameraID, action) => {
        try {
            var obj = null
            if (action == "getAlertTemplateList") {
                obj = {
                    "action": action,
                    "deviceTypeId": 0
                }
            } else if (action == "addCameraDeviceTemplate" ||
                action == "updateCameraDeviceTemplate" ||
                action == "addPanelTemplate" ||
                action == "updatePanelTemplate" ||
                action == "addZoneTemplate" ||
                action == "updateZoneTemplate" ||
                action == "deleteTemplate"
            ) {
                obj = data
            }
            if (obj == null || obj == undefined) { return }
            await Alerttemplatecrud(obj).then((resp) => {
                if (resp && resp.status === "Ok") {
                    if (action == "getAlertTemplateList") {
                        if (resp.message.length > 0) {
                            setEventTemplates(resp.message)
                            setFilteredEventTemplates(resp.message)
                            const updatedAlertTemp = resp.message.find(x => x.AlertTemplateId == parentCameraID) //here parentCameraID is nothing but alert template id
                            if (updatedAlertTemp) {
                                setSelectedTemplate(updatedAlertTemp)
                            }
                            return
                        }
                    } else if (action == "addCameraDeviceTemplate" || action == "addPanelTemplate" || action == "addZoneTemplate") {
                        AlertTemplateOperations(null, 0, "getAlertTemplateList")
                        onSubPageNavigate('Manage Workflow')
                        setSelectedSubMenu('Manage Workflow')
                    } else if (action == "updateCameraDeviceTemplate" || action == "updatePanelTemplate" || action == "updateZoneTemplate") {
                        AlertTemplateOperations(null, resp.AlertTemplateId, "getAlertTemplateList")
                        setIsAlertTemplateEdit(false)
                    } else if (action == "deleteTemplate") {
                        AlertTemplateOperations(null, 0, "getAlertTemplateList")
                        setIsAlertTemplateEdit(true)
                        setSelectedTemplate(templateObj)
                        getWorkflows()
                    }
                    showSuccessToast(resp.message)
                }
                else {
                    if (resp && resp.message && resp.message != "No template found") {
                        // showErrorToast(resp.message)
                    } else {
                        // showErrorToast("Something went wrong, please try again later.")
                    }

                }

            })
        } catch (error) {
            console.log(error)
        }
    }

    const removeDevice = (alertTemplateId, deviceId) => {
        removeDeviceFromTemp(alertTemplateId, deviceId)
            .then((res) => {
                if(res.Status == "success"){
                    setDevTemClicked(true)
                    setDisplayButtons(false);
                }
            }).catch(err => console.log(err));
    }

    const onSubmitDeviceTemplate = (data, siteSelected, deviceSelected,selectedItem, sevenDaysData, customData) => {
        let { Description,
            AlertCodeId,
            FullTime,
            TemplateName,
            SeverityId,
            ClientId,
            DeviceTypeId,
            IDSPanelZoneTypeId,
            SiteId,
            DeviceId,
            WorkflowId,
            AlertTemplateId,
            IsAlertFlag,
            NoAlert,
            Custom,
            UserID
        } = data
        setButtonDisable(true)
        let deviceListArr = deviceSelected.map((item) => {
            let obj = {
                DeviceId: item.value,
                IDSPanelZoneTypeId: IDSPanelZoneTypeId.toString(),
                AlertTemplateId: "",
                SiteId: "323"
            }
            return obj;
        })
        if(selectedItem == "") { showErrorToast('Please select a schedule type!'); setButtonDisable(false); setAlertSaveBtnClicked(false); return }
        let isAlertFlag = "1";
        if (selectedItem == "Custom") {
            isAlertFlag = "0";

            for(let i=0; i<sevenDaysData.length; i++){
                if(sevenDaysData[i].isEnabled && sevenDaysData[i].dayResource.length == 0){
                    showErrorToast('Please do some schedule selection for the day which you have enabled the scheduling'); 
                    setButtonDisable(false)
                    setAlertSaveBtnClicked(false);
                    return;
                }
            }

            let isCustomFilled = sevenDaysData.every((item) => item.dayResource.length == 0);
            if(isCustomFilled && customData.length == 0){
                showErrorToast('Please do some selection in the repeat custom schedule section'); 
                setButtonDisable(false)
                setAlertSaveBtnClicked(false); 
                return   
            }
            if(customData.length > 0){
                let isCustomDataFilled = customData.some((item) => item.dayResource.length == 0);
                if(isCustomDataFilled){
                    showErrorToast('Please do some selection in the once on a date custom schedule section'); 
                    setButtonDisable(false)
                    setAlertSaveBtnClicked(false); 
                    return   
                }    
            }
        }
        let schedulerObject = {
            "sevendays": sevenDaysData,
            "custom": customData
        }
        let stringifyObj = JSON.stringify(schedulerObject);

        let obj = {
            //action: UpdateAlertTemplateApI,
            "AlertTemplateId": alertTemplateObj.templateId,
            "TemplateName": TemplateName,
            "DeviceTypeId": DeviceTypeId,
            "ClientId": ClientId,
            "AlertCodeId": AlertCodeId,
            "Description": Description,
            "CreatedOn": "",
            "CreatedBy": "",
            "ModifiedOn": "",
            "ModifiedBy": localStorage.getItem('UserID'),
            "LstDeviceAlertTemplateConfig":  deviceListArr,
            "SkipDBValidation": true,
            "IsAlertFlag": isAlertFlag,
            "ScheduleMetadata": stringifyObj
        };
        if(deviceSelected.some(i => i.value.includes(selTempDevice?.Id))){
            // Alert Popup that you want to remove the device from the alert template
            if(deviceSelected.length == 1){
                UpdateAlertTemplateApI(obj).then((resp)=>{
                    if(resp.Status == "success"){
                        showSuccessToast(resp.Message)
                        setIsAlertUpdated(true)
                        setIsAlertTempFormEnabled(false)
                        setAlertSaveBtnClicked(false)
                        setIsAlertTemplateEdit(false)
                        setButtonDisable(false)
                    }else{
                        showErrorToast(resp.Message);
                        setAlertSaveBtnClicked(false)
                        setButtonDisable(false)
                    }
                  }).catch(err => console.log(err))
                return;
            }
            // Ask do you want to add this template for this particular device or apply the same to all other devices.
            Swal.fire({
                title: 'Note',
                text: 'Do you want to apply this template changes for all devices or only this device.',
                icon: 'warning',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonColor: '#027aaa',
                // cancelButtonColor: '#d33',
                confirmButtonText: 'Yes apply for all',
                denyButtonText: 'No only this device'
            }).then((result) => {
                if(result.isConfirmed){
                    // call the updateAlertTemplate API over here.
                      UpdateAlertTemplateApI(obj).then((resp)=>{
                        if(resp.Status == "success"){
                            showSuccessToast(resp.Message)
                            setIsAlertUpdated(true)
                            setIsAlertTempFormEnabled(false)
                            setAlertSaveBtnClicked(false)
                            setIsAlertTemplateEdit(false)
                            setButtonDisable(false)
                        }else{
                            showErrorToast(resp.Message);
                            setAlertSaveBtnClicked(false)
                            setButtonDisable(false)
                        }
                      }).catch(err => console.log(err))

                }else if(result.isDenied){
                    let tempDeviceListArr = [
                        {
                            DeviceId: selTempDevice?.Id,
                            IDSPanelZoneTypeId: IDSPanelZoneTypeId.toString(),
                            AlertTemplateId: "",
                            SiteId: "323"
                        }
                    ]
                    let tempObj = {
                        "AlertTemplateId": alertTemplateObj.templateId,
                        "TemplateName": TemplateName,
                        "DeviceTypeId": DeviceTypeId,
                        "ClientId": ClientId,
                        "AlertCodeId": AlertCodeId,
                        "Description": Description,
                        "CreatedOn": "",
                        "CreatedBy": localStorage.getItem('UserID'),
                        "ModifiedOn": "",
                        "ModifiedBy": localStorage.getItem('UserID'),
                        "LstDeviceAlertTemplateConfig":  tempDeviceListArr,
                        "SkipDBValidation": true,
                        "IsAlertFlag": isAlertFlag,
                        "ScheduleMetadata": stringifyObj
                    }
                    seperateAlertTemplateByDevice(tempObj).then((resp) => {
                        if(resp.Status == "success"){
                            showSuccessToast(resp.Message)
                            setIsAlertUpdated(true)
                            setIsAlertTempFormEnabled(false)
                            setAlertSaveBtnClicked(false)
                            setIsAlertTemplateEdit(false)
                            setDevTemClicked(true)
                            setDisplayButtons(false); 
                            setButtonDisable(false)
                            getAlertTemplateByDeviceId(selTempDevice?.Id) 
                        }else{
                            showErrorToast(resp.Message);
                            setAlertSaveBtnClicked(false);
                            setButtonDisable(false)
                        }
                    }).catch(err => console.log(err));
                }
            }).catch(err => console.log(err))
        }else{
            if(deviceSelected.length > 0){
                Swal.fire({
                    title: 'Note',
                    text: 'Do you want to create a new template for this device or just remove the device from the existing alert template.',
                    icon: 'warning',
                    showCancelButton: true,
                    showDenyButton: true,
                    confirmButtonColor: '#027aaa',
                    // cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes create new',
                    denyButtonText: 'No just remove'
                }).then((result) => {
                    if(result.isConfirmed){
                        // Call splitAlertTemplateByDevice API to create a new template.
                        splitAlertTemplateByDevice(alertTemplateObj.templateId, selTempDevice.Id,localStorage.getItem('UserID')).then((res) => {
                            if(res.Status == "success"){
                                showSuccessToast(res.Message)
                                setDevTemClicked(true)
                                setDisplayButtons(false); 
                                getAlertTemplateByDeviceId(selTempDevice?.Id) 
                                setButtonDisable(false)  
                            }else{
                                showErrorToast(false);
                                setButtonDisable(false)
                            }
                        }).catch(err => console.log(err))
                    }else if(result.isDenied){
                        // Call UpdateAlertTemplate API To Remove The Device from alert template
                        UpdateAlertTemplateApI(obj).then((resp)=>{
                            if(resp.Status == "success"){
                                showSuccessToast(resp.Message)
                                setIsAlertUpdated(true)
                                setIsAlertTempFormEnabled(false)
                                setAlertSaveBtnClicked(false)
                                setIsAlertTemplateEdit(false)
                                setDevTemClicked(true)
                                setDisplayButtons(false); 
                                setButtonDisable(false)
                                if(deviceTemplateList.length == 1){
                                    GetAlertTemplateList("site_list", 1, "","");
                                    if(alertTemplateList[0].Id == selTempDevice?.Id){
                                        getAlertTemplateByDeviceId(alertTemplateList[1].Id);
                                        setSelTempDevice(alertTemplateList[1]);    
                                    }else{
                                        getAlertTemplateByDeviceId(alertTemplateList[0].Id);
                                        setSelTempDevice(alertTemplateList[0]);
                                    }
                                }else{
                                    getAlertTemplateByDeviceId(selTempDevice?.Id) 
                                }  
                            }else{
                                showErrorToast(resp.Message);
                                setButtonDisable(false)
                                setAlertSaveBtnClicked(false)
                            }
                          }).catch(err => console.log(err))
    
                    }
                }).catch(err => console.log(err))
            }else{
                Swal.fire({
                    title: 'Note',
                    text: 'You cannot remove the device from the template as it is the only device, you can only update its schedule',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonColor: '#027aaa',
                    // cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    if(result.isConfirmed){
                        setAlertSaveBtnClicked(false)
                        setButtonDisable(false)
                    }else{
                        setAlertSaveBtnClicked(false)
                        setButtonDisable(false)
                    }
                }).catch(err => console.log(err));    
            }

        }
        setAlertSaveBtnClicked(false);
        setButtonDisable(false)
        // alert("Do you want to update the alert template");
    }

    const onSubmitTemplate = async (data, siteSelected, deviceSelected,selectedItem, sevenDaysData, customData) => {
        setButtonDisable(true);
        let { Description,
            AlertCodeId,
            FullTime,
            TemplateName,
            SeverityId,
            ClientId,
            DeviceTypeId,
            IDSPanelZoneTypeId,
            SiteId,
            DeviceId,
            WorkflowId,
            AlertTemplateId,
            IsAlertFlag,
            NoAlert,
            Custom,
            UserID
            
        } = data

        let fullTime = isFullTime


        var fth = ""
        var ftm = ""
        var tth = ""
        var ttm = ""
        if (fromTime.getHours().toString().length == 1) fth = "0" + fromTime.getHours().toString()
        else fth = fromTime.getHours().toString()

        if (fromTime.getMinutes().toString().length == 1) ftm = "0" + fromTime.getMinutes().toString()
        else ftm = fromTime.getMinutes().toString()

        if (toTime.getHours().toString().length == 1) tth = "0" + toTime.getHours().toString()
        else tth = toTime.getHours().toString()

        if (toTime.getMinutes().toString().length == 1) ttm = "0" + toTime.getMinutes().toString()
        else ttm = toTime.getMinutes().toString()

        if (!isFullTime) {
            if (!validateTime(fromTime, toTime)) {
                showErrorToast('From time should be smaller than to time!')
                // Swal.fire({ text: 'From time should be smaller than to time.' })
                return;
            }
        }

        let validationError = "";
        if (data.TemplateName === "" || data.TemplateName.trim() == "") { 
            // showErrorToast('Please enter template name!'); 
            // setAlertSaveBtnClicked(false); 
            // return 
            validationError += "template name-Please enter template name!,";
        }
        if (DeviceTypeId == "" ||  DeviceTypeId == 0) { 
            // showErrorToast('Please select the device type!'); 
            // setAlertSaveBtnClicked(false); 
            // return 
            validationError += "device type-Please select the device type!,";
        }
        if (AlertCodeId == "" || AlertCodeId == 0) { 
            // showErrorToast('Please select the alert type!'); 
            // setAlertSaveBtnClicked(false); 
            // return 
            validationError += "alert type-Please select the alert type!,";
        }
        if (ClientId == "" || ClientId == 0) { 
            // showErrorToast('Please select the customer name!'); 
            // setAlertSaveBtnClicked(false); 
            // return 
            validationError += "customer name-Please select the customer name!,";
        }

        // if (SiteId == 0) { showErrorToast('Please select a site!'); return }
        if (siteSelected == "" || siteSelected.length == 0) { 
            // showErrorToast('Please select a site!'); 
            // setAlertSaveBtnClicked(false); 
            // return 
            validationError += "site-Please select a site!,";
        }
        if (deviceSelected == "" || deviceSelected.length == 0) { 
            // showErrorToast('Please select a device!'); 
            // setAlertSaveBtnClicked(false); 
            // return 
            validationError += "device-Please select a device!,";
        }

        let _type = devicesmasterlist.deviceTypeList.find(x => x.deviceTypeID == DeviceTypeId)
        if (_type) {
            if (_type.deviceType == "Sensor") {
                if (IDSPanelZoneTypeId == 0 || IDSPanelZoneTypeId === '' || IDSPanelZoneTypeId === null) { 
                    // showErrorToast('Please select the sensor type!'); 
                    // setAlertSaveBtnClicked(false); 
                    // return 
                    validationError += "sensor-Please select the sensor type!,";
                }
            }
        }

        setInputValidationError(validationError);
        setSavebtnClick(true);
        if(validationError != ""){
            setButtonDisable(false);
            setAlertSaveBtnClicked(false);
            return;
        }

        // if (DeviceId == 0) { showErrorToast('Please select a device!'); return }
        let deviceListArr = deviceSelected.map((item) => {
            let obj = {
                DeviceId: item.value,
                IDSPanelZoneTypeId: IDSPanelZoneTypeId.toString(),
                AlertTemplateId: "",
                SiteId: "323"
            }
            return obj;
        })  
        if(selectedItem == "") { showErrorToast('Please select a schedule type!'); setButtonDisable(false); setAlertSaveBtnClicked(false); return }
        let isAlertFlag = "1";
        
        // if (selectedItem == "Custom" && !isStore) {
        if (selectedItem == "Custom") {
        isAlertFlag = "0";

            for(let i=0; i<sevenDaysData.length; i++){
                if(sevenDaysData[i].isEnabled && sevenDaysData[i].dayResource.length == 0){
                    showErrorToast('Please do some schedule selection for the day which you have enabled the scheduling'); 
                    setButtonDisable(false);
                    setAlertSaveBtnClicked(false);
                    return;
                }
            }

            let isCustomFilled = sevenDaysData.every((item) => item.dayResource.length == 0);
            if(isCustomFilled && customData.length == 0){
                showErrorToast('Please do some selection in the repeat custom schedule section'); 
                setButtonDisable(false);
                setAlertSaveBtnClicked(false); 
                return   
            }
            if(customData.length > 0){
                let isCustomDataFilled = customData.some((item) => item.dayResource.length == 0);
                if(isCustomDataFilled){
                    showErrorToast('Please do some selection in the once on a date custom schedule section'); 
                    setButtonDisable(false);
                    setAlertSaveBtnClicked(false); 
                    return   
                }    
            }
        }    
        
        let schedulerObject = {
            "sevendays": sevenDaysData,
            "custom": customData
        }
        let stringifyObj = JSON.stringify(schedulerObject);
        if (alertTemplateObj.templateId == "") {
            //add
            let obj = {
              "AlertTemplateId": "",
              "TemplateName": TemplateName,
              "DeviceTypeId": DeviceTypeId,
              "ClientId": ClientId,
              "AlertCodeId": AlertCodeId,
              "Description": Description,
              "CreatedOn": "",
              "CreatedBy":localStorage.getItem('UserID'),
              "ModifiedOn": "",
              "ModifiedBy": "",
              "LstDeviceAlertTemplateConfig": deviceListArr,
              "SkipDBValidation": true,
              "IsAlertFlag": isAlertFlag,
              "ScheduleMetadata": stringifyObj
            };
            AddAlertTemplateApI(obj).then((resp) => {
                if(resp.Status == "success"){
                    showSuccessToast(resp.Message)
                    let tObj = {
                        templateId : "",
                        templateName: "",
                        templateClientId: "0",
                        templateSiteId: [],
                        templateDeviceTypeId: "0",
                        templateDeviceSubTypeId: "0",
                        templateDevice: [],
                        templateAlertType: "0",
                        scheduleType: "NA",
                        schedulerObj : {},
                        tempSelectedSites:[],
                        tempSelectedDevices:[],
                    
                    }
                    setAlertTemplateObj(tObj);
                    setIsAlertUpdated(true)
                    setAlertSaveBtnClicked(false);
                    getEventTemplatesList()
                    setButtonDisable(false);
                }else{
                    showErrorToast(resp.Message);
                    setAlertSaveBtnClicked(false);
                    setButtonDisable(false);
                }             
            });
          }  else{
            //update
            let obj = {
                //action: UpdateAlertTemplateApI,
                "AlertTemplateId": alertTemplateObj.templateId,
                "TemplateName": TemplateName,
                "DeviceTypeId": DeviceTypeId,
                "ClientId": ClientId,
                "AlertCodeId": AlertCodeId,
                "Description": Description,
                "CreatedOn": "",
                "CreatedBy": "",
                "ModifiedOn": "",
                "ModifiedBy": localStorage.getItem('UserID'),
                "LstDeviceAlertTemplateConfig":  deviceListArr,
                "SkipDBValidation": true,
                "IsAlertFlag": isAlertFlag,
                "ScheduleMetadata": stringifyObj
              };
              UpdateAlertTemplateApI(obj).then((resp)=>{
                if(resp.Status == "success"){
                    showSuccessToast(resp.Message)
                    setIsAlertUpdated(true)
                    setIsAlertTempFormEnabled(false)
                    setAlertSaveBtnClicked(false)
                    setIsAlertTemplateEdit(false)
                    getEventTemplatesList()
                    setButtonDisable(false);
                }else{
                    showErrorToast(resp.Message);
                    setAlertSaveBtnClicked(false)
                    setButtonDisable(false);
                }
              })
          }     
    }
    
    return (
        <>
            <ConfigSideBar list={initialTemplate}
                onNavigate={onSubPageNavigate}
                setIsItemClick={setIsItemClick}
                selectedSubMenu={selectedSubMenu}
                setSelectedSubMenu={setSelectedSubMenu}
                onMoveNext={onMoveNext}
                setSelectedMenu={setSelectedMenu}
                selectedMenu={selectedMenu}
                selectedMainMenu={selectedMainMenu}
                setSelectedMainMenu={setSelectedMainMenu}
                setSelectedSite={setSelectedSite}
                setAlertTemplateObj={setAlertTemplateObj}
                setIsAlertTemplateEdit={setIsAlertTemplateEdit}
                setIsAlertTempFormEnabled={setIsAlertTempFormEnabled}
                setIsEmptyFormAlert={setIsEmptyFormAlert}
                setDeviceTemplateList={setDeviceTemplateList}
                setDevTemClicked={setDevTemClicked}
                setSelTempDevice={setSelTempDevice}
                setDisplayButtons={setDisplayButtons}
                isDarkTheme={isDarkTheme}
                setActivityLogShow={setActivityLogShow}
                setInterfaceDataList={setInterfaceDataList}
                setPageState={setPageState}
            />
            <div
                className={!collapsed ? "mainContent" : "collapseMainContent"}
            >
                {
                    showWF &&
                    selectedWF &&
                    <WFPreview
                        show={showWF}
                        onClose={handleWFPreviewClose}
                        workflowName={selectedWF.workflowName}
                        elements={selectedWF.metadata.elements}
                        nodesDraggable={false} />
                }
                <div className="MainContentMargin">
                    <ConfigHeader
                        selectedSubMenu={selectedSubMenu}
                        selectedMenu={selectedMenu}
                        nodes={nodes}
                        isNodeSelected={isNodeSelected}
                        selectedNode={selectedNode}
                        disableAdd={disableAdd}
                        setDisableAdd={setDisableAdd}
                        editMode={editMode}
                        fetchPayload={fetchPayload}
                        setEditMode={setEditMode}
                        setIsNodeSelected={setIsNodeSelected}
                        setAddNewClicked={setAddNewClicked}
                        addNewClicked={addNewClicked}
                        setIsNotifFormEnabled={setIsNotifFormEnabled}
                        setInEditMode={setInEditMode}
                        setShowEditIcon={setShowEditIcon}
                        showEditIcon={showEditIcon}
                        inEditMode={inEditMode}
                        setIsEmptyForm={setIsEmptyForm}
                        isEmptyForm={isEmptyForm}
                        isNotifFormEnabled={isNotifFormEnabled}
                        setNotifSaveBtnClicked={setNotifSaveBtnClicked}
                        setNotifDeleteBtnClicked={setNotifDeleteBtnClicked}
                        isAlertTempFormEnabled={isAlertTempFormEnabled}
                        isEmptyFormAlert={isEmptyFormAlert}
                        setIsEmptyFormAlert={setIsEmptyFormAlert}
                        setIsAlertTempFormEnabled={setIsAlertTempFormEnabled}
                        setAddNewClickedAlert={setAddNewClickedAlert}
                        setSelectedTemplate={setSelectedTemplate}
                        setIsAlertTemplateEdit={setIsAlertTemplateEdit}
                        setAlertSaveBtnClicked={setAlertSaveBtnClicked}
                        setAlertDeleteBtnClicked={setAlertDeleteBtnClicked}
                        deleteAlertTemplate={deleteAlertTemplate}
                        setAlertTemplateObj={setAlertTemplateObj}
                        displayButtons={displayButtons}
                        setIsStore={setIsStore}
                        deviceTemplateList={deviceTemplateList}
                        selTempDevice={selTempDevice}
                        activityLogShow={activityLogShow}
                        setActivityLogShow={setActivityLogShow}
                        getAlertTemplateById={getAlertTemplateById}
                        alertTemplateObj={alertTemplateObj}
                        DeleteAlertTemplate2 = {DeleteAlertTemplate2}
                        buttonDisable={buttonDisable}
                        setButtonDisable={setButtonDisable}
                    />
                    {/* <SetupWizardProgress onMoveNext={onMoveNext} selectedTemplate={initialTemplate} /> */}
                    {
                        initialTemplate.name == "Site Management" &&
                        <SiteTemplateStep
                            pageState={pageState}
                            setPageState={setPageState}
                            setSelectedSite={setSelectedSite}
                            setSelectedDevice={setSelectedDevice}
                            onSaveSite={onSaveSite}
                            selectedSite={selectedSite}
                            deviceslist={deviceslist}
                            cameradeviceslist={cameradeviceslist}
                            paneldeviceslist={paneldeviceslist}
                            onMoveNext={onMoveNext}
                            sites={sites}
                            onSelectSite={onSelectSite}
                            onDeleteSite={deleteSite}
                            isSiteEdit={isSiteEdit}
                            setIsSiteEdit={setIsSiteEdit}
                            siteRef={siteRef}
                            sitesToBind={sitesToBind}
                            siteGroups={siteGroups}
                            selectedSiteGroup={selectedSiteGroup}
                            onSiteGroupSelection={onSiteGroupSelection}
                            selectedSites={selectedSites}
                            setSelectedSites={setSelectedSites}
                            updateSitesToSiteGroup={updateSitesToSiteGroup}
                            onDeleteSiteGroup={onDeleteSiteGroup}
                            onAddNewSiteGroup={onAddNewSiteGroup}
                            onUpdateSiteGroup={onUpdateSiteGroup}
                            selectedSiteTypeList={selectedSiteTypeList}
                            setselectedSiteTypeList={setselectedSiteTypeList}
                            onSearchChange={onSearchChange}
                            filteredSites={filteredSites}
                            filteredSiteGroups={filteredSiteGroups}
                            setFilteredSites={setFilteredSites}
                            selectedDevice={selectedDevice}
                            OnSaveDevice={OnSaveDevice}
                            onDeviceSelection={onDeviceSelection}
                            onAddNewDevice={onAddNewDevice}
                            isDeviceEdit={isDeviceEdit}
                            setIsDeviceEdit={setIsDeviceEdit}
                            devicesmasterlist={devicesmasterlist}
                            OnAddIDSPanel={OnAddIDSPanel}
                            getdeviceslistdata={getdeviceslistdata}
                            clients={clients}
                            getCliets={getCliets}
                            country={country}
                            countryState={countryState}
                            siteTypes={siteTypes}
                            setSiteTypes={setSiteTypes}
                            setCountryState={setCountryState}
                            ertLevels={ertLevels}
                            siteERTOnClick={siteERTOnClick}
                            selectedERT={selectedERT}
                            onAddERTMemberClick={onAddERTMemberClick}
                            addNewERTMemberClick={addNewERTMemberClick}
                            emeContactList={emeContactList}
                            onDeleteERT={onDeleteERT}
                            isERTSectionVisible={isERTSectionVisible}
                            setcameradeviceslist={setcameradeviceslist}
                            setpaneldeviceslist={setpaneldeviceslist}
                            onSensorSelection={onSensorSelection}
                            associatedDevices={associatedDevices}
                            onAssociate={onAssociate}
                            selectedSensor={selectedSensor}
                            associateCamSecVisibility={associateCamSecVisibility}
                            setParentIsEncoding={setParentIsEncoding}
                            setParentIsPlaybackEncoding={setParentIsPlaybackEncoding}
                            setParentIsEnablingTwoWayDevice={setParentIsEnablingTwoWayDevice}
                            parentIsEnablingTwoWayDevice={parentIsEnablingTwoWayDevice}
                            setParentIsEnablingTwoWayInterface={setParentIsEnablingTwoWayInterface}
                            parentIsEnablingPTZ={parentIsEnablingPTZ}
                            setParentIsEnablingPTZ={setParentIsEnablingPTZ}
                            parentIsEnablingUTCPlayback={parentIsEnablingUTCPlayback}
                            setParentIsEnablingUTCPlayback={setParentIsEnablingUTCPlayback}
                            parentIsEnablingTwoWayInterface={parentIsEnablingTwoWayInterface}
                            setParentIsEnablingInternalHooter={setParentIsEnablingInternalHooter}
                            setParentIsEnablingExternalHooter={setParentIsEnablingExternalHooter}
                            setParentPanelIsEnablingHooter={setParentPanelIsEnablingHooter}
                            setParentIsEnablingPlaybackTimeStampsToUTC={setParentIsEnablingPlaybackTimeStampsToUTC}
                            parentIsEnablingPlaybackTimeStampsToUTC={parentIsEnablingPlaybackTimeStampsToUTC}
                            parentIsEnablingPlaybackLongDateFormat={parentIsEnablingPlaybackLongDateFormat}
                            setParentIsEnablingPlaybackLongDateFormat={setParentIsEnablingPlaybackLongDateFormat}
                            parentIsEnablingInternalHooter={parentIsEnablingInternalHooter}
                            parentIsEnablingExternalHooter={parentIsEnablingExternalHooter}
                            parentPanelIsEnablingHooter={parentPanelIsEnablingHooter}
                            setBulkDevToUpload={setBulkDevToUpload}
                            bulkDevToUpload={bulkDevToUpload}
                            OnAddBulkDevicesClick={OnAddBulkDevicesClick}
                            selectedHeaders={selectedHeaders} //panel bulk upload
                            onPanelBulkUpload={onPanelBulkUpload}
                            setSelectedHeaders={setSelectedHeaders}
                            bulkUpload={bulkUpload}
                            setBulkUpload={setBulkUpload}
                            isSingleOrBulkSelection={isSingleOrBulkSelection}
                            setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                            clearImportedPanelHeaders={clearImportedPanelHeaders}
                            checkForAlertsAndDelDevice={checkForAlertsAndDelDevice}
                            selectedZone={selectedZone}
                            setselectedZone={setselectedZone}
                            userOptions={userOptions}
                            onUserRoleChange={onUserRoleChange}
                            selectedUserRole={selectedUserRole}
                            setSelectedUserRole={setSelectedUserRole}
                            siteUserFieldsVisibility={siteUserFieldsVisibility}
                            setSiteUserFieldsVisibility={setSiteUserFieldsVisibility}
                            selectedSiteTypeClick={selectedSiteTypeClick}
                            importedFileHeaders={importedFileHeaders}
                            setImportedFileHeaders={setImportedFileHeaders}
                            importedPanelHeaders={importedPanelHeaders}
                            setImportedPanelHeaders={setImportedPanelHeaders}
                            selectedCamHeaders={selectedCamHeaders}
                            setSelectedCamHeaders={setSelectedCamHeaders}
                            clearImportedCamHeaders={clearImportedCamHeaders}
                            deviceBulkUploadResponse={deviceBulkUploadResponse}
                            setImportedFileData={setImportedFileData}
                            panelBulkUploadResponse={panelBulkUploadResponse}
                            setPanelBulkUploadResponse={setPanelBulkUploadResponse}
                            panelBulkUploadError={panelBulkUploadError}
                            setPanelBulkUploadError={setPanelBulkUploadError}
                            setDeviceBulkUploadResponse={setDeviceBulkUploadResponse}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            isSubmitting={isSubmitting}
                            interfaceTypeData = {interfaceTypeData}             
                            cctvTypeData = {cctvTypeData}             
                            interfaceSubTypeData = {interfaceSubTypeData}  
                            interfaceEntitySubTypeData = {interfaceEntitySubTypeData}
                            setInterfaceSubTypeData={setInterfaceSubTypeData}    
                            interfaceDataList = {interfaceDataList}  
                            onInterfaceFormSubmit = {onInterfaceFormSubmit}
                            siteFormObj={siteFormObj}
                            setSiteFormObj={setSiteFormObj}
                            setInputValidationError={setInputValidationError}
                            validationError={inputValidationError}
                            savebtnClick={savebtnClick}
                            setSavebtnClick={setSavebtnClick}
                            onSelectInterface={onSelectInterface}
                            selectedInterface={selectedInterface}
                            setSelectedInterface={setSelectedInterface}
                            onDeleteInterface={deleteInterface}
                            isInterfaceEdit={isInterfaceEdit}
                            setIsInterfaceEdit={setIsInterfaceEdit}
                            getInterfaceData={getInterfaceData}
                            setAssociateCamSecVisibility={setAssociateCamSecVisibility}
                            setInterfaceDataList={setInterfaceDataList}
                            rtspValueChanged = {rtspValueChanged}
                            setRtspValueChanged = {setRtspValueChanged}
                            panelZoneType = {panelZoneType}
                            setPanelZoneType={setPanelZoneType}
                            isDarkTheme={isDarkTheme}
                            parentIsEncoding={parentIsEncoding}
                            parentIsPlaybackEncoding={parentIsPlaybackEncoding}
                            setUnknownBrandUrl = {setUnknownBrandUrl}
                            buttonDisable={buttonDisable}
                            timezone={timezone}
                            setTimeZone={setTimeZone}
                            acsDevicesList={acsDevicesList}
                            setAcsDevicesList={setAcsDevicesList}
                            onSaveACS={onSaveACS}
                            selectedAcsHeaders={selectedAcsHeaders}
                            setSelectedAcsHeaders={setSelectedAcsHeaders}
                            clearImportedAcsHeaders={clearImportedAcsHeaders}
                            setClearImportedAcsHeaders={setClearImportedAcsHeaders}
                            acsBulkUploadResponse={acsBulkUploadResponse}
                            setAcsBulkUploadResponse={setAcsBulkUploadResponse}
                            acsBulkUploadError={acsBulkUploadError}
                            setAcsBulkUploadError={setAcsBulkUploadError}
                            importedAcsHeaders={importedAcsHeaders}
                            setImportedAcsHeaders={setImportedAcsHeaders}
                            onAcsBulkUpload={onAcsBulkUpload}
                            setSelectedSubMenu={setSelectedSubMenu}
                            />
                    }
                    {
                        initialTemplate.name == "Alert Template" &&
                        <>
                            <AlertTemplateStep
                                pageState={pageState}
                                setPageState={setPageState}
                                selectedTemplate={selectedTemplate}
                                onSubmitTemplate={onSubmitTemplate}
                                onSubmitDeviceTemplate={onSubmitDeviceTemplate}
                                alertCodes={alertCodes}
                                isFullTime={isFullTime}
                                setIsFullTime={setIsFullTime}
                                fromTime={fromTime}
                                setFromTime={setFromTime}
                                toTime={toTime}
                                setToTime={setToTime}
                                severities={severities}
                                selectedSeverity={selectedSeverity}
                                setSelectedSeverity={setSelectedSeverity}
                                onSelectTemplate={onSelectTemplate}
                                onDeleteTemplate={onDeleteTemplate}
                                workflows={workflows}
                                onFinish={onMoveNext}
                                onPreviewWF={onPreviewWF}
                                isAlertTemplateEdit={isAlertTemplateEdit}
                                setIsAlertTemplateEdit={setIsAlertTemplateEdit}
                                alertTemplateRef={alertTemplateRef}
                                filteredEventTemplates={filteredEventTemplates}
                                onSearchChange={onSearchChange}
                                devicesmasterlist={devicesmasterlist}
                                devicetypeid={devicetypeid}
                                setdevicetypeid={setdevicetypeid}
                                setSiteList={setSiteList}
                                siteList={siteList}
                                deviceList={deviceList}
                                setDeviceList={setDeviceList}
                                getSiteList={getSiteList}
                                getDeviceList ={getDeviceList}
                                GetAlertTemplateList={GetAlertTemplateList}
                                alertTemplateList={alertTemplateList}
                                addNewClickedAlert={addNewClickedAlert}
                                alertDeleteBtnClicked={alertDeleteBtnClicked}
                                alertSaveBtnClicked={alertSaveBtnClicked}
                                getAlertTemplateById={getAlertTemplateById}
                                getAlertTemplateByDeviceId={getAlertTemplateByDeviceId}
                                alertTemplateObj={alertTemplateObj}
                                PanelZoneType={PanelZoneType}
                                deviceTemplateList={deviceTemplateList}
                                devTemClicked={devTemClicked}
                                setDevTemClicked={setDevTemClicked}
                                totalTemplateCount={totalTemplateCount}
                                totalDeviceCount={totalDeviceCount}
                                isAlertUpdated={isAlertUpdated}
                                setIsAlertUpdated={setIsAlertUpdated}
                                selTempDevice={selTempDevice}
                                setSelTempDevice={setSelTempDevice}
                                setDeviceTemplateList={setDeviceTemplateList}
                                getCustomerData={getCustomerData}
                                customerData={customerData}
                                setDisplayButtons={setDisplayButtons}
                                isAlertTempFormEnabled={isAlertTempFormEnabled}
                                isStore={isStore}
                                clients={clients}
                                sites={sites}
                                activityLogShow={activityLogShow}
                                setActivityLogShow={setActivityLogShow}
                                isDarkTheme={isDarkTheme}
                                setAlertTemplateObj={setAlertTemplateObj}
                                setAddNewClickedAlert={setAddNewClickedAlert}
                                setIsEmptyFormAlert={setIsEmptyFormAlert}
                                setIsAlertTempFormEnabled={setIsAlertTempFormEnabled}
                                setIsStore={setIsStore}
                                getZonesAT={getZonesAT}
                                zoneList={zoneList}
                                templateDelete = {templateDelete}
                                savebtnClick={savebtnClick}
                                setSavebtnClick={setSavebtnClick}
                                setInputValidationError={setInputValidationError}
                                validationError={inputValidationError}
                                buttonDisable={buttonDisable}
                                setSelectedSubMenu={setSelectedSubMenu}
                            />
                        </>
                    }
                    {
                        initialTemplate.name == "Users" &&
                        <UserTemplateStep
                            pageState={pageState}
                            setPageState={setPageState}
                            users={users}
                            setSelectedDataAccessGroupId={setSelectedDataAccessGroupId}
                            userGroups={userGroups}
                            groups={groups}
                            onSaveUser={onSaveUser}
                            selectedUser={selectedUser}
                            onSelectUser={onSelectUser}
                            onDeleteUser={onDeleteUser}
                            setIsNotValidPassword={setIsNotValidPassword}
                            setChangePassword={setChangePassword}
                            setIsPassUpdated={setIsPassUpdated}
                            isPassUpdated={isPassUpdated}
                            onMoveNext={onMoveNext}
                            isUserEdit={isUserEdit}
                            setIsUserEdit={setIsUserEdit}
                            siteRef={siteRef}
                            usersToBind={usersToBind}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            onAddSelectedUsers={onAddSelectedUsers}
                            onAddNewUserGroup={onAddNewUserGroup}
                            onUpdateUserGroup={onUpdateUserGroup}
                            filteredUsers={filteredUsers}
                            onSearchChange={onSearchChange}
                            selectedUserTypeList={selectedUserTypeList}
                            setselectedUserTypeList={setselectedUserTypeList}
                            filteredUserGroups={filteredUserGroups}
                            selectedSiteTypeList={selectedSiteTypeList}
                            setselectedSiteTypeList={setselectedSiteTypeList}
                            siteGroups={siteGroups}
                            siteGroupOnClick={siteGroupOnClick}
                            selectedSiteGroup={selectedSiteGroup}
                            workflows={workflows}
                            nodes={nodes}
                            setNodes={setNodes}
                            onLoad={onLoad}
                            editMode={editMode}
                            setEditMode={setEditMode}
                            isNodeSelected={isNodeSelected}
                            setIsNodeSelected={setIsNodeSelected}
                            setSelectedNode={setSelectedNode}
                            selectedNode={selectedNode}
                            disableAdd={disableAdd}
                            setDisableAdd={setDisableAdd}
                            onNavigate={onSubPageNavigate}
                            setIsItemClick={setIsItemClick}
                            setSelectedSubMenu={setSelectedSubMenu}
                            isDarkTheme={isDarkTheme}
                            savebtnClick={savebtnClick}
                            setSavebtnClick={setSavebtnClick}
                            setInputValidationError={setInputValidationError}
                            validationError={inputValidationError}
                            buttonDisable={buttonDisable}
                            setButtonDisable={setButtonDisable}
                            setTimeZone={setTimeZone}
                            timezone={timezone}
                        />
                    }
                    {
                        initialTemplate.name == "Notification Setting" &&
                        <NotificationContainer
                            setAddNewClicked={setAddNewClicked}
                            addNewClicked={addNewClicked}
                            isNotifFormEnabled={isNotifFormEnabled}
                            setIsNotifFormEnabled={setIsNotifFormEnabled}
                            setIsEmptyForm={setIsEmptyForm}
                            notifSaveBtnClicked={notifSaveBtnClicked}
                            notifDeleteBtnClicked={notifDeleteBtnClicked}
                            setNotifSaveBtnClicked={setNotifSaveBtnClicked}
                            setNotifDeleteBtnClicked={setNotifDeleteBtnClicked}
                            isDarkTheme={isDarkTheme}
                            buttonDisable={buttonDisable}
                            setButtonDisable={setButtonDisable}
                        />
                    }
                    {
                        initialTemplate.name == "Alert Pre Post Setting" &&
                        <ProPostSetting 
                            isDarkTheme={isDarkTheme}
                            devicesmasterlist={devicesmasterlist}
                            clients={clients}
                            savebtnClick={savebtnClick}
                            setSavebtnClick={setSavebtnClick}
                            setInputValidationError={setInputValidationError}
                            validationError={inputValidationError}
                            setClients={setClients}
                            buttonDisable={buttonDisable}
                            setButtonDisable={setButtonDisable}
                        />
                    }
                     {
                        initialTemplate.name == "Email Setting" &&
                        <EmailSetting 
                            isDarkTheme={isDarkTheme}
                            buttonDisable={buttonDisable}
                        />
                    }
                    {
                        initialTemplate.name == "Panel Setting" && 
                        <PanelSetting 
                            isDarkTheme={isDarkTheme}
                            buttonDisable={buttonDisable}
                            onSearchChange={onSearchChange}
                        />
                    }
                    {
                        initialTemplate.name == "Sound Setting" &&
                        <SoundSetting 
                            isDarkTheme={isDarkTheme}
                            getAllfromParent={getAllfromParent}
                            buttonDisable={buttonDisable}
                            setButtonDisable={setButtonDisable}
                        />
                    }
                    {
                        initialTemplate.name == "Review" &&
                        <>
                            <ReviewStep
                                sites={sites}
                                deviceslist={deviceslist}
                                eventTemplates={eventTemplates}
                                users={users}
                                pageState={pageState}
                            />
                        </>
                    }
                </div>
            </div>
        </>
    )
}

const SetupWizardProgress = ({ onMoveNext, selectedTemplate }) => {
    return <div className="col-lg-12">
        <div className="SiteButtonDiv">
            {
                Object.keys(sidebarList).map((item, index) => {
                    return (
                        <>
                            {
                                selectedTemplate.name == sidebarList[item].name ?
                                    <button className="SiteButton">
                                        <span className="SiteButtonText">{sidebarList[item].name}</span>
                                    </button> :
                                    <button className='config-templateprogress' onClick={() => onMoveNext(item)}>
                                        {sidebarList[item].name}
                                    </button>
                            }
                            {Object.keys(sidebarList).length - 1 !== index && <p className="TemplateProgress">-------</p>}
                        </>
                    )
                })
            }
        </div>
    </div>
}