import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerIds: "",
  siteIds: "",
};

const HMDashboardFeatures = createSlice({
  name: "HMDashboardPage",
  initialState,
  reducers: {
    //#region Set individual objects
    setCustomerIds(state, action) {
      return {
        ...state,
        customerIds: action.payload,
      };
    },
    setSiteIds(state, action) {
      return {
        ...state,
        siteIds: action.payload,
      };
    },
    //#endregion

    //Reset to initialState
    resetHMDashboardStates() {
      return {
        ...initialState,
      };
    },
  },
});

export const HMDashboardActions = HMDashboardFeatures.actions;
export default HMDashboardFeatures.reducer;
