import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    modalVisibilityState: {
        showSavedFilterSaveModal: false,
        showSavedFilterDeleteModal: false,
    },
}

const modalFeatures = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        //#region Toggle modal visibility
        showSavedFilterSaveModal(state, action) {
            return {
                ...state,
                modalVisibilityState: {
                    ...state.modalVisibilityState,
                    showSavedFilterSaveModal: action.payload
                }
            }
        },

        showSavedFilterDeleteModal(state, action) {
            return {
                ...state,
                modalVisibilityState: {
                    ...state.modalVisibilityState,
                    showSavedFilterDeleteModal: action.payload
                }
            }
        },

        //#endregion
    }
});

export const modalActions = modalFeatures.actions;
export default modalFeatures.reducer;