import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Form, OverlayTrigger, Row } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';
import { BackArrow } from '../../assets/images';

export default function ConfigureAction({
    setSelectedNode,
    selectedNode,
    masters,
    onSave
}) {
  //#region useState start
  const [responseOptions, setResponseOptions] = useState([])
  const [displayAction, setDisplayAction] = useState("0")
  //#endregion useState end

  //#region useEffect start
  useEffect(() => {
      if (selectedNode != null) {
          // console.warn('selectedNode.data.nodeId', selectedNode.data)
          let nodeData = masters.nodeMasterList.find(node =>
              node.templateNodesMstID == selectedNode.data.nodeId)

          setValue('action', nodeData.data)
          if (nodeData.rasaData != null) {
              if (nodeData.rasaData.buttons.length == 0) {
                  setResponseOptions([])
                  setDisplayAction("2")
                  setValue('displayActionAs', "2")
              }
              else {
                  getResponseActions()
                  setDisplayAction("3")
                  setValue('displayActionAs', "3")
              }
          } else {
              setResponseOptions([])
              setDisplayAction("2")
              setValue('displayActionAs', '2')
          }
      }
  }, [selectedNode])
  //#endregion useEffect end

  const { register, handleSubmit, reset, getValues, setValue, control } = useForm({
      defaultValues: { ...selectedNode.data.otherProps }
  });

  const onSubmit = data => {
      data.displayActionAs = displayAction
      let nodeData = masters.nodeMasterList.find(node =>
          node.templateNodesMstID == selectedNode.data.nodeId)

      //for Buttons
      let buttons = []
      //#region unused
      if (displayAction == 3) {
          responseOptions.map(option => {
              let buttonFound = nodeData.rasaData.buttons.find(button => button.title == option.title)
              if (buttonFound != undefined) {
                  let button = {
                      ...buttonFound
                  }
                  buttons.push(button)
              }
          })
      }

      data.buttons = buttons

      selectedNode.data.otherProps = {
          ...selectedNode.data.otherProps,
          ...data,
          btnResponse: nodeData.rasaData.btnResponse,
          responseTime: nodeData.rasaData.responseTime,
          nodeUserID: nodeData.nodeUserID,
          botMessage: nodeData.rasaData.botMessage
      }
      onSave(selectedNode)
  }

  const handleDisplayActionChange = (e) => {
      setDisplayAction(e)
      if (e == 3) getResponseActions()
  }

  const getResponseActions = () => {
      let nodeData = masters.nodeMasterList.find(node =>
          node.templateNodesMstID == selectedNode.data.nodeId)

      if (nodeData.rasaData == null) {
          console.warn("node's rasaData is null")
          return
      }

      setResponseOptions(nodeData.rasaData.buttons)
  }

  return <>
      <form onSubmit={handleSubmit(onSubmit)}>
          <div className="OperationAccordionBgDiv">
              <div id="accordion" className="accordion">
                  <div className="mb-0">
                      <div className="collapsed AccordionActionCardHeader" data-toggle="collapse" href="#collapseOne">
                          <a onClick={() => setSelectedNode(null)}>
                              <img src={BackArrow} alt="" />
                          </a>
                          <p className="ActionText pl-2">Action Related to Operation</p>
                      </div>
                      <div id="collapseOne" className="show" data-parent="#accordion">
                          <p className="SelectActionP">Action</p>
                          <Form.Control
                              className="col-md-12 workflow-action-input-box"
                              name="action"
                              {...register}
                              defaultValue={selectedNode.data.otherProps.action}
                              onChange={e => setValue('action', e.target.value)}
                              disabled
                              as="textarea"
                          />
                          <div hidden>
                              <p className="SelectActionP mt-1"> Display Action As</p>
                              <Form.Check
                                  style={{ left: "0", position: "relative" }}
                                  label={"Info message"}
                                  name="displayActionAs"
                                  {...register("displayActionAs")}
                                  type="radio"
                                  value="2"
                                  onChange={e => handleDisplayActionChange(e.target.value)}
                                  disabled />{""}
                              <Form.Check
                                  style={{ left: "0", position: "relative" }}
                                  name="displayActionAs"
                                  {...register("displayActionAs")}
                                  label={"Decision to make"}
                                  type="radio"
                                  value="3"
                                  onChange={e => handleDisplayActionChange(e.target.value)}
                                  disabled />
                          </div>
                          {
                              displayAction == 3 &&
                              <div hidden>
                                  <p className="SelectActionP">Responses for Question</p>
                                  {responseOptions &&
                                      responseOptions.length != 0 &&
                                      <div
                                          as={Row}
                                          className="col-md-6 inline mr-0">
                                          {
                                              responseOptions.map((option) => {
                                                  return <div className="col m-1">
                                                      <Form.Check
                                                          name="responseOptions"
                                                          {...register}
                                                          type="checkbox"
                                                          label={option.title}
                                                          value={option.title}
                                                          checked={true}
                                                          disabled />
                                                  </div>
                                              })
                                          }
                                      </div>}
                              </div>
                          }
                          <div className="CheckboxDiv" hidden>
                              <div>
                                  <input id="Mandatory" className="Custom_checkbox_Square" name="Mandatory" type="checkbox" checked="" />
                                  <label htmlFor="Mandatory" className="Custom_checkbox_Square_label">Mandatory</label>
                              </div>
                              <div className="ml-5">
                                  <input id="Floating_Step" className="Custom_checkbox_Square" name="Floating_Step" type="checkbox" />
                                  <label htmlFor="Floating_Step" className="Custom_checkbox_Square_label">Floating Step</label>
                              </div>
                          </div>
                          <hr hidden />
                          <div hidden>
                              <p className="SelectActionP" hidden>Select Contact Level</p>
                              <select className="form-control Select_Action">
                                  <option>Cognybot</option>
                                  <option>Cognybot1</option>
                              </select>
                              <p className="SelectActionP mt-4 mb-1" hidden>Response for Question</p>
                              <div>
                                  <input id="L1" className="checkbox-custom" name="L1" type="checkbox" />
                                  <label htmlFor="L1" className="checkbox-custom-label">L1</label>
                              </div>
                              <div>
                                  <input id="L2" className="checkbox-custom" name="L2" type="checkbox" />
                                  <label htmlFor="L2" className="checkbox-custom-label">L1- Alternate 1</label>
                              </div>
                              <div>
                                  <input id="L3" className="checkbox-custom" name="L3" type="checkbox" />
                                  <label htmlFor="L3" className="checkbox-custom-label">L1- Alternate 2</label>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div className="ActionTimeDiv" hidden>
              <div>
                  <input id="Action" className="Custom_checkbox_Square" name="Action" type="checkbox" />
                  <label htmlFor="Action" className="Custom_checkbox_Square_label">
                      <span className="ActionTimeText">Action to be Completed Within</span>
                  </label>
              </div>
              <div className="MinsText">
                  <div className="NumberView">
                      <input type="text" className="SiteNameTextBox" value="5" />
                  </div>
                  <div className="NumberView">
                      <select className="form-control Select_Action">
                          <option>Mins</option>
                          <option>Mins1</option>
                      </select>
                  </div>
                  <p className="IfNotText">If not,</p>
              </div>
          </div>
          <div className="EscalationAccordionBgDiv" hidden>
              <div id="accordion" className="accordion">
                  <div className="mb-0">
                      <div className="collapsed AccordionActionCardHeader" data-toggle="collapse" href="#collapseTwo">
                          <a>
                              <p className="ActionText">Escalation Matrix</p>
                          </a>
                      </div>
                      <div id="collapseTwo" className="show" data-parent="#accordion" >
                          <p className="SelectActionP">Select Contact</p>
                          <select className="form-control Select_Action mb-3">
                              <option>L1 - Alternate 1</option>
                              <option>L1 - Alternate 2</option>
                          </select>

                          <p className="SelectActionP">Mode of Communication</p>
                          <select className="form-control Select_Action">
                              <option>Call</option>
                              <option>Call1</option>
                          </select>

                          <div>
                              <label htmlFor="Action" className="Custom_checkbox_Square_label mt-3">
                                  <span className="ActionTimeText1">
                                      Action to be Completed Within
                                  </span>
                              </label>
                          </div>
                          <div className="MinsText">
                              <div className="NumberView">
                                  <input type="text" className="SiteNameTextBox" value="2" />
                              </div>
                              <div className="NumberView">
                                  <select className="form-control Select_Action">
                                      <option>Mins</option>
                                      <option>Mins1</option>
                                  </select>
                              </div>
                          </div>
                          <hr />
                          <p className="SelectActionP">Select Contact</p>
                          <select className="form-control Select_Action mb-3">
                              <option>L1 - Alternate 2</option>
                              <option>L1 - Alternate 3</option>
                          </select>

                          <p className="SelectActionP">Mode of Communication</p>
                          <select className="form-control Select_Action">
                              <option>Call</option>
                              <option>Call1</option>
                          </select>

                          <div className="AddMore">
                              <a href="#" className="UserAddPlus">Add More +</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="AddMore workflowPage">
              <button className="NextMoveButton">
                  <span className="ViewUpdate">Save</span>
              </button>
          </div>
          <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
          />
      </form>
  </>
}
