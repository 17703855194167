import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startDate: "",
  endDate: "",
  selectedStartDate: "",
  selectedEndDate: "",
  defaultEndDate: 15,
  timerInputState: {
    timeString: "",
  },
};

const playbackFeature = createSlice({
  name: "playback",
  initialState,
  reducers: {
    setSelectedStartDate(state, action) {
      return {
        ...state,
        selectedStartDate: action.payload,
      };
    },
    setSelectedEndDate(state, action) {
      return {
        ...state,
        selectedEndDate: action.payload,
      };
    },
    setTimerInputTimeString(state, action) {
      return {
        ...state,
        timerInputState: {
          ...state.timerInputState,
          timeString: action.payload,
        },
      };
    },

    setStartDate(state, action) {
      return {
        ...state,
        startDate: action.payload,
      };
    },

    setEndDate(state, action) {
      return {
        ...state,
        endDate: action.payload,
      };
    },

    setDefaultEndDate(state, action) {
      return {
        ...state,
        defaultEndDate: action.payload,
      };
    },

    //Reset All states
    resetPlaybackState() {
      return {
        ...initialState,
      };
    },
  },
});

export const playbackActions = playbackFeature.actions;
export default playbackFeature.reducer;
