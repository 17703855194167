import { useEffect, useState } from 'react';

export const useDataAccessPagination = (limitArg, currentPageNoArg, totalPagesArg, pageCounterArg) => {
    const [limit, setLimit] = useState(limitArg);
    const [currentPageNo, setCurrentPageNo] = useState(currentPageNoArg);
    const [totalPages, setTotalPages] = useState(totalPagesArg);
    const [pageCounter, setPageCounter] = useState(pageCounterArg);
    const [disable, setDisable] = useState(false);
    const [maxPageAllowed, setMaxPageAllowed] = useState(0);
    // const pageClickTwo = (i) => {
    //     setCurrentPageNo(i);
    // }

    // useEffect(()=>{
    //   setCurrentPageNo(1);
    //   setPageCounter(1);
    // },[])

    useEffect(() => {
      let count = 0;
      for(let i=0; i < totalPages; i=i+limitArg){
        count = count + 1;
      }
      setMaxPageAllowed(count);
    },[totalPages])

    const prevClick = () => {
        if(pageCounter == 1) {
          setPageCounter(1);
          if(currentPageNo == 1){
            setCurrentPageNo(1)
          }else{
            setCurrentPageNo(currentPageNo - 1);
          }
        }
        else {
          let min = (pageCounter * 3) - 2
          let max = (pageCounter - 1) * 3;
          if(min == currentPageNo){
          setCurrentPageNo(max); 
          setPageCounter(pageCounter-1)
          }else{
          setCurrentPageNo(currentPageNo - 1)       
          }
          // setCurrentPageNo(max); 
          // setPageCounter(pageCounter-1)
        }
      }
    
      const nextClick = () => {
        let currentMax = pageCounter * 3;
        //This case is if it is the third page
        if(currentMax == currentPageNo){
        let max = (pageCounter + 1) * 3;
        let start = max - 2;
        if(start > maxPageAllowed){
          return;
        }
        setCurrentPageNo(start)
        setPageCounter(pageCounter + 1);
        }
        //This case is if it the first or second page.
        else{
          let c = currentPageNo + 1;
          if(c <= maxPageAllowed){
            setCurrentPageNo(c);
          }
          if(currentMax > maxPageAllowed){
            return;
          }
          // setCurrentPageNo(currentPageNo+1);
          // setPageCounter(pageCounter);
        }
      }

      return [ nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter ]
}