import { BASE_CORE_URL } from "../config";
import { ErrorToast } from "../CustomHooks/ErrorToast";
import { SuccessToast } from "../CustomHooks/SuccessToast";
import { filterActions } from "../redux/features/shared/filter";
import { modalActions } from "../redux/features/shared/modal";

export const fetchSavedFiltersList = (pType) => async (dispatch) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };
        let loggedInUser = JSON.parse(localStorage.getItem('user'));
        let userUniqueId = loggedInUser?.UserUniqueID;
        let url = BASE_CORE_URL + "Device/GetAllFilter" + `?pType=${pType}&userUniqueId=${userUniqueId}`;

        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success") {
            // console.log("saved filter list => ", response?.Data)
            const DDLData = response?.Data?.map(item => {
                const { FilterId, FilterName, FilterValues } = item;
                return { filterName: FilterName, filterId: parseInt(FilterId), filterValues: FilterValues }
            })
            dispatch(filterActions.setSavedFiltersList(DDLData));
        }
    }
    catch (ex) {
        console.log(ex);
    }
}

export const saveFilter = (pName, pType, filterText) => async (dispatch) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };
        let loggedInUser = JSON.parse(localStorage.getItem('user'));
        let userUniqueId = loggedInUser?.UserUniqueID;
        let url = BASE_CORE_URL + "Device/SaveFilter" + `?pName=${pName}&pType=${pType}&userUniqueId=${userUniqueId}&filterText=${filterText}`;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success") {
            SuccessToast(response?.Message)
            console.log("saved filter response => ", response);
            dispatch(fetchSavedFiltersList(pType))
        }else if (response?.Status === "failed") {
            ErrorToast(response?.Message)
        }
    }
    catch (ex) {
        console.log(ex);
    }
}

export const deleteSavedFilter = (pId) => async (dispatch) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };
        let loggedInUser = JSON.parse(localStorage.getItem('user'));
        let userUniqueId = loggedInUser?.UserUniqueID;
        let url = BASE_CORE_URL + "Device/DeleteFilter" + `?pId=${pId}`;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success") {
            SuccessToast(response?.Message)
            console.log("saved filter response => ", response);
            dispatch(modalActions.showSavedFilterDeleteModal(false));
            dispatch(filterActions.resetSavedFilter());

        }else if(response?.Status === "failed"){
            ErrorToast(response?.Message)
        }
    }
    catch (ex) {
        console.log(ex);
    }
}

export const fetchClientList = () => async (dispatch) => {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    let uniqueId = loggedInUser.UserUniqueID  
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };
        let url = BASE_CORE_URL + "Cameras/GetClientsList?uniqueId=" + uniqueId;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success" && response?.Data?.length > 0) {
            const DDLData = response?.Data?.map(item => {
                const { ClientId, ClientName } = item;
                return { label: ClientName, value: parseInt(ClientId) }
            })
            dispatch(filterActions.setCustomer(DDLData));
        }
    }
    catch (ex) {
        console.log(ex);
    }
}

export const fetchSiteList = (clientIds) => async (dispatch) => {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    let uniqueId = loggedInUser.UserUniqueID  
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };

        let url = BASE_CORE_URL + "Cameras/GetSiteList" + `?ClientId=${clientIds}&uniqueId=${uniqueId}`;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success" && response?.Data?.length > 0) {
            const DDLData = response?.Data?.map(item => {
                const { SiteID, SiteName } = item;
                return { label: SiteName, value: parseFloat(SiteID) }
            })
            dispatch(filterActions.setSite(DDLData));
        }
    }
    catch (ex) {

    }
};

export const fetchInterfaceSubTypeList = (siteIds) => async (dispatch) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };
        let url = BASE_CORE_URL + "Cameras/GetInterfaceSubtype" + `?SiteID=${siteIds}`;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success" && response?.Data?.length > 0) {
            const DDLData = response?.Data?.map(item => {
                const { InterfacesubtypeId, Interfacesubtypename } = item;
                return { label: Interfacesubtypename, value: parseInt(InterfacesubtypeId) }
            })
            dispatch(filterActions.setInterfaceSubType(DDLData));
        }
    }
    catch (ex) {
        console.log(ex);
    }
};

export const fetchInterfaceList = (interfaceSubTypeIds, siteIds) => async (dispatch) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken')
            },
        };

        let url = BASE_CORE_URL + "Cameras/GetInterfaceNameList" + `?InterfacesubtypeId=${interfaceSubTypeIds}&SiteID=${siteIds}`;
        const response = await fetch(url, requestOptions).then(res => res.json());

        if (response?.Status === "success" && response?.Data?.length > 0) {
            const DDLData = response?.Data?.map(item => {
                const { InterfaceId, InterfaceName } = item;
                return { label: InterfaceName, value: parseFloat(InterfaceId) }
            })
            dispatch(filterActions.setInterface(DDLData));
        }
    }
    catch (ex) {
        console.log(ex);
    }
};