import React from "react";
import "../../pages/HealthMonitoring/HealthDisplaySite.css";
import {
    SearchIcon,
  } from "../../assets/images";

const CameraHealthDetails = () => {
  return (
    <div>
      <div className="card-body p-0">
        <table
          className="table table-text-ext table-fixed report-table healthmonitoringTable"
          id="alert-activity-report"
        >
          <thead className="ReportTableHeader ReportTableHeadReport">
            <tr>
              <th>
                <p>Device Name</p>
                <div className="SearchTextDiv StatusSearchText">
                  <input
                    className="SearchViewInput"
                    type="text"
                    placeholder="Search"
                  />
                  <img className="EyeIcon" alt="Search-icon" src={SearchIcon} />
                </div>
              </th>
              <th>
                <p>Current Status</p>
                <div className="SearchTextDiv StatusSearchText">
                  <select
                    required
                    className="SiteNameTextBox ArrowSelect HMTableSelect"
                  >
                    <option key="0" disabled={false} value="0">
                      - Select Data -
                    </option>
                  </select>
                </div>
              </th>
              <th>
                <p>Last Update</p>
                <div className="SearchTextDiv StatusSearchText">
                  <input
                    className="SearchViewInput"
                    type="text"
                    placeholder="Search"
                  />
                  <img className="EyeIcon" alt="Search-icon" src={SearchIcon} />
                </div>
              </th>
              <th>
                <p>Issue</p>
                <div className="SearchTextDiv StatusSearchText">
                  <input
                    className="SearchViewInput"
                    type="text"
                    placeholder="Search"
                  />
                  <img className="EyeIcon" alt="Search-icon" src={SearchIcon} />
                </div>
              </th>
              <th>
                <p>IP Address</p>
                <div className="SearchTextDiv StatusSearchText">
                  <input
                    className="SearchViewInput"
                    type="text"
                    placeholder="Search"
                  />
                  <img className="EyeIcon" alt="Search-icon" src={SearchIcon} />
                </div>
              </th>
              <th>
                <p>Port</p>
                <div className="SearchTextDiv StatusSearchText">
                  <select
                    required
                    className="SiteNameTextBox ArrowSelect HMTableSelect"
                  >
                    <option key="0" disabled={false} value="0">
                      - Select Data -
                    </option>
                  </select>
                </div>
              </th>
              <th>
                <p>Channel No.</p>
                <div className="SearchTextDiv StatusSearchText">
                  <select
                    required
                    className="SiteNameTextBox ArrowSelect HMTableSelect"
                  >
                    <option key="0" disabled={false} value="0">
                      - Select Data -
                    </option>
                  </select>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="devices-tablename">Cam One</td>
              <td className="online-color">Online</td>
              <td>401</td>
              <td>Tesco</td>
              <td>400077</td>
              <td>Mumbai</td>
              <td>Ghatkopar</td>
            </tr>
            <tr>
              <td className="devices-tablename">Cam One</td>
              <td className="offline-color">Offline</td>
              <td>401</td>
              <td>Tesco</td>
              <td>400077</td>
              <td>Mumbai</td>
              <td>Ghatkopar</td>
            </tr>
            <tr>
              <td className="devices-tablename">Cam One</td>
              <td className="online-color">Online</td>
              <td>401</td>
              <td>Tesco</td>
              <td>400077</td>
              <td>Mumbai</td>
              <td>Ghatkopar</td>
            </tr>
            <tr>
              <td className="devices-tablename">Cam One</td>
              <td className="offline-color">Offline</td>
              <td>401</td>
              <td>Tesco</td>
              <td>400077</td>
              <td>Mumbai</td>
              <td>Ghatkopar</td>
            </tr>
          </tbody>
        </table>

        {/* {props.list.length == 0 && <NoReportFound />} */}
      </div>

      <div class="horizontal-Line"></div>
      <div>
        <div className="viewTimelineMsg">
         {/*  <img src={Information} alt="information icon" /> */}
          <h5>Select a device from table to view timeline.</h5>
          {/* <LineChart /> */}
        </div>
      </div>
    </div>
  );
};

export default CameraHealthDetails;
