import React, { useState, useEffect } from 'react';

export default function CustomUrlPage() {
  const [iframeSrc, setIframeSrc] = useState(''); 
  const customURL = sessionStorage.getItem('customURL');
  
  useEffect(() => {
    if (customURL && isValidURL(customURL)) {
      setIframeSrc(customURL);
    } else {
      
    }
  }, [customURL]);

  const isValidURL = (str) => {
    return str.startsWith('http://') || str.startsWith('https://');
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
  };

  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  };

  return (
    <div className="MainContentMargin" style={containerStyle}>
      <div id="iframeContainer" style={{ width: '100%', height: '500px' }}>
        <iframe
          src={iframeSrc}
          title="Embedded Content"
          style={iframeStyle}
        ></iframe>
      </div>
    </div>
  );
}
