import { useEffect, useState } from "react";

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [audio, setAudio] = useState(null)
  const [audioSize, setAudioSize] = useState(null)
  var timeout_status = 0;

  useEffect(() => {
    try {
      // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      setAudioSize(null)
      recorder.start();
    } else {
      recorder.stop();
      recorder.stream.getTracks().forEach(track => track.stop());
      setRecorder(null)
    }

    // Obtain the audio when ready.
    const handleData = e => {
      let data = URL.createObjectURL(e.data)
      setAudio(e.data)
      setAudioURL(data);
      setAudioSize(e.data.size)
    };

    const removeData = () => {
      setAudio(null);
      setAudioSize(null);
      setAudioURL("");
    }

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", removeData);
    } catch (error) {
      console.error("recorder error",error)
    }
    
  }, [recorder, isRecording]);


  
  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    window.clearTimeout(timeout_status);
    setIsRecording(false);
  };

  return [audio, audioURL, isRecording, audioSize, startRecording, stopRecording];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default useRecorder;
