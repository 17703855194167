import { BASE_CORE_URL } from "../config";
import { HM_STATUS_API } from "../config";

//#region  to get the Health monitoring Devicestatus table data
export const getHealthMonitorData = async (
  pageNumber,
  uniqueId,
  searchText,
  clientIds,
  siteIds
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authcode: localStorage.getItem("authCode"),
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };
  let url =
    BASE_CORE_URL +
    "HealthMonitoringDashboard/DeviceStatusDetails?userUniqueId=" +
    uniqueId +
    "&pageNumber=" +
    pageNumber +
    "&searchText=" +
    searchText +
    "&clientIds=" +
    clientIds +
    "&siteIds=" +
    siteIds;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log("getHealthMonitorData", data);
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};
//#endregion

//#region  to get the Health Monitoring Dashboard Widget Data
export const AllWidgetData = async (userUniqueID, clientIds, siteIds) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authcode: localStorage.getItem("authCode"),
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };
  let url =
    BASE_CORE_URL +
    "HealthMonitoringDashboard/AllWidgetData?userUniqueId=" +
    userUniqueID +
    "&clientIds=" +
    clientIds +
    "&siteIds=" +
    siteIds;
  return await fetch(url, requestOptions)
    .then((resp) => {
      console.log("test", resp);
      return resp.json();
    })
    .then(function (data) {
      console.log("test", data);
      return data;
    })
    .catch(function (error) {
      console.error("AllWidgetData () Exception", error);
    });
};
//#endregion

//#region  to get the Health Monitoring table single device data
export const getSelectedDeviceHealthData = async (
  uniqueId,
  deviceId,
  deviceStatusType,
  fromDateTime,
  toDateTime,
  pageNumber,
  searchText
) => {
  let fromDateTimeTemp = fromDateTime.replaceAll(":", "%3A");
  fromDateTimeTemp = fromDateTimeTemp.replace(" ", "%20");
  let toDateTimeTemp = toDateTime.replace(" ", "%20");
  toDateTimeTemp = toDateTimeTemp.replaceAll(":", "%3A");

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authcode: localStorage.getItem("authCode"),
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };
  let url =
    BASE_CORE_URL +
    "HealthMonitoringDashboard/SingleDeviceStatusDetails?userUniqueId=" +
    uniqueId +
    "&deviceId=" +
    deviceId +
    "&deviceStatusType=" +
    deviceStatusType +
    "&fromDateTime=" +
    fromDateTime +
    "&toDateTime=" +
    toDateTime +
    "&pageNumber=" +
    pageNumber +
    "&searchText=" +
    searchText;
  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};
//#endregion

//#region  to get the Health Monitoring table single device data export (downaload)
export const getSingleDeviceStatusDetails = async (
  uniqueId,
  deviceId,
  deviceType,
  fromDateTime,
  toDateTime,
  pageNumber,
  searchText
) => {
  let fromDateTimeTemp = fromDateTime.replaceAll(":", "%3A");
  fromDateTimeTemp = fromDateTimeTemp.replace(" ", "%20");
  console.log("getSingleDeviceStatusDetails", fromDateTimeTemp);
  let toDateTimeTemp = toDateTime.replace(" ", "%20");
  toDateTimeTemp = toDateTimeTemp.replaceAll(":", "%3A");
  console.log("getSingleDeviceStatusDetails", toDateTimeTemp);

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authcode: localStorage.getItem("authCode"),
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };

  let url =
    BASE_CORE_URL +
    "HealthMonitoringDashboard/SingleDeviceStatusDetails?userUniqueId=" +
    uniqueId +
    "&deviceId=" +
    deviceId +
    "&deviceStatusType=" +
    deviceType +
    "&fromDateTime=" +
    fromDateTime +
    "&toDateTime=" +
    toDateTime +
    "&pageNumber=" +
    pageNumber +
    "&searchText=" +
    searchText +
    "&isExport=true";
  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};
//#endregion

//#region  to get the Health monitoring dashboard widget Camera Tampering For Today (to view details)
export const getCameraTampAlerts = async (
  uniqueId,
  pageNumber,
  searchText,
  clientIds,
  siteIds
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authcode: localStorage.getItem("authCode"),
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };

  let url =
    BASE_CORE_URL +
    "HealthMonitoringDashboard/CameraBlockAlertDetails?userUniqueId=" +
    uniqueId +
    "&pageNumber=" +
    pageNumber +
    "&searchText=" +
    searchText +
    "&clientIds=" +
    clientIds +
    "&siteIds=" +
    siteIds;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((err) => console.log(err));
};
//#endregion

//#region to get the Health Monitoring single device  Barchart Data
export const getBarChartDataFromAPI = async (
  uniqueId,
  deviceId,
  deviceType,
  fromDateTime,
  toDateTime,
  deviceStatusType
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authcode: localStorage.getItem("authCode"),
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };
  console.log(
    "getBarChartDataFromAPI",
    deviceId,
    deviceType,
    fromDateTime,
    toDateTime,
    deviceStatusType
  );
  let url =
    BASE_CORE_URL +
    "HealthMonitoringDashboard/GetBarChartData?userUniqueId=" +
    uniqueId +
    "&deviceId=" +
    deviceId +
    "&deviceType=" +
    deviceType +
    "&fromDateTime=" +
    fromDateTime +
    "&toDateTime=" +
    toDateTime +
    "&deviceStatusType=" +
    deviceStatusType;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((err) => console.log(err));
};

//#region to get the Health Monitoring based on SiteId
export const getHealthMonitoringSiteID = async (siteId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authcode: localStorage.getItem("authCode"),
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };
  let url = HM_STATUS_API + "Adaptor/GetStatusDevice?siteId=" + siteId;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log("getHealthMonitoringSiteID", data);
      return data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

//#endregion
