import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const WidgetSkeleton = () => {
  return (
    <div className='StatusValueDiv'>
        <Skeleton className='HMWidgetLabelSkeleton'/>
        <Skeleton className='HMWidgetValueSkeleton'/>
    </div>
  )
}
