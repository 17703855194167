import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";

import { BackArrowDevice } from '../../../../assets/images'

import { Row, OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import Papa from 'papaparse';
import { CSVLink } from "react-csv";

import SelectHeaderCommon from './SelectHeaderCommon';
import SelectHeaderEmpty from './SelectHeaderEmpty';
import { ACS_BULK_UPLOAD_LINK, MULTI_TIME_ZONE, PANEL_BULK_UPLOAD_LINK } from '../../../../config';

const AcsBulkUpload = ({
    selectedHeaders,
    onPanelBulkUpload,
    setSelectedHeaders,
    clearImportedPanelHeaders,
    setImportedFileData,
    panelBulkUploadResponse,
    setPanelBulkUploadResponse,
    panelBulkUploadError,
    setPanelBulkUploadError,
    setBulkUpload,
    setIsSingleOrBulkSelection,
    importedPanelHeaders,
    setImportedPanelHeaders,
    onAcsBulkUpload
}) => {

    const { register, handleSubmit } = useForm({
        defaultValue: { ...selectedHeaders },
    });

    const hiddenPanelFileInput = React.useRef(null);

    //#region useState start
    const [defaultBrand, setDefaultBrand] = useState(null);
    const [defaultBranch, setDefaultBranch] = useState(null);
    const [defaultIp, setDefaultIp] = useState(null);
    const [defaultPortNumber, setDefaultPortNumber] = useState(null);
    const [defaultUsername, setDefaultUsername] = useState(null);
    const [defaultPassword, setDefaultPassword] = useState(null);
    const [defaultControllerName, setDefaultControllerName] = useState(null);
    const [defaultDeviceName, setDefaultDeviceName] = useState(null);
    const [defaultIdentifierName, setDefaultIdentifierName] = useState(null);
    const [defaultTimezone, setDefaultTimezone] = useState(null);


    const [totalDevices, setTotalDevices] = useState(null);
    const [failedDevices, setFailedDevices] = useState(null);
    const [successDevices, setSuccessDevices] = useState(null);
    const [panelImportedFileName, setPanelImportedFileName] = useState(null)
    const [faildListToDownload, setFaildListToDownload] = useState(null)
    const [sucessListToDownload, setSucessListToDownload] = useState(null)
    const [errorListToDownload, setErrorListToDownload] = useState(null)

    //#endregion 

        //#region useEffect start
        useEffect(() => {
            if (importedPanelHeaders) {
                var brand = importedPanelHeaders.find((x) => x == "brand");
                if (brand) {
                    setDefaultBrand(brand);
                } else {
                    brand = null;
                }
    
                var branch = importedPanelHeaders.find((x) => x == "branch");
                if (branch) {
                    setDefaultBranch(branch);
                } else {
                    branch = null;
                }

                var ip = importedPanelHeaders.find((x) => x == "ipaddress");
                if (ip) {
                    setDefaultIp(ip);
                } else {
                    ip = null;
                }

                var portnumber = importedPanelHeaders.find((x) => x == "portnumber");
                if (portnumber) {
                    setDefaultPortNumber(portnumber);
                } else {
                    portnumber = null;
                }

                var username = importedPanelHeaders.find((x) => x == "username");
                if (username) {
                    setDefaultUsername(username);
                } else {
                    username = null;
                }
    
                var password = importedPanelHeaders.find((x) => x == "password");
                if (password) {
                    setDefaultPassword(password);
                } else {
                    password = null;
                }

                var controllername = importedPanelHeaders.find((x) => x == "controllername");
                if (controllername) {
                    setDefaultControllerName(controllername);
                } else {
                    controllername = null;
                }

                var devicename = importedPanelHeaders.find((x) => x == "devicename");
                if (devicename) {
                    setDefaultDeviceName(devicename);
                } else {
                    devicename = null;
                }
    
                var identifier = importedPanelHeaders.find((x) => x == "identifier");
                if (identifier) {
                    setDefaultIdentifierName(identifier);
                } else {
                    identifier = null;
                }
                    
                var timezone = importedPanelHeaders.find((x) => x == "timezone");
                if(timezone){
                    setDefaultTimezone(timezone);
                }else{
                    timezone = null;
                }
    
                let acsHeadObj = {
                    brand: brand,
                    branch: branch,
                    ipaddress: ip,
                    portnumber: portnumber,
                    username: username,
                    password: password,
                    controllername: controllername,
                    devicename: devicename,
                    identifier: identifier,
                };
                if(MULTI_TIME_ZONE){
                    acsHeadObj.timezone = timezone
                }
                setSelectedHeaders(acsHeadObj);
            }
        }, [importedPanelHeaders]);
    
        useEffect(() => {
            setImportedPanelHeaders(null);
            hiddenPanelFileInput.current.value = null;
        }, [clearImportedPanelHeaders]);
    
        useEffect(() => {
            if (panelBulkUploadResponse) {
                console.log("panelBulkUploadResponse", panelBulkUploadResponse)
                const panelFailedLst = panelBulkUploadResponse.AcsFailedList;
    
                if (panelFailedLst && panelFailedLst.length > 0) {
                    let failedList = []
                    // if (sensorFailedLst.length > 0) failedList = failedList.concat(sensorFailedLst)
                    if (panelFailedLst.length > 0) {
                        for (let i = 0; i < panelFailedLst.length; i++) {
                            // for (let j = 0; j < panelFailedLst[i].sensorLst.length; j++) {
                            let temp = {};
    
                            temp['branch'] = panelFailedLst[i]['branch']
                            temp['brand'] = panelFailedLst[i]['brand']
                            temp['message'] = panelFailedLst[i]['message']
                            temp['ipaddress'] = panelFailedLst[i]['ipaddress']
                            temp['devicename'] = panelFailedLst[i]['devicename']
                            temp['controllername'] = panelFailedLst[i]['controllername']
                            temp['identifier'] = panelFailedLst[i]['identifier']
                            temp['status'] = panelFailedLst[i]['status']
                            // temp['interfaceName'] = panelFailedLst[i]['interfaceName']
                            temp['portnumber'] = panelFailedLst[i]['portnumber']
                            temp['username'] = panelFailedLst[i]['username']
                            temp['password'] = panelFailedLst[i]['password']
                            

                            if(MULTI_TIME_ZONE){
                                temp['timezone'] = panelFailedLst[i]['timezone']
                            }
                            failedList.push(temp)
                            // }
                        }
                    }
                    var failedDevList = failedList.length;
                    if (failedDevList && failedDevList !== undefined) {
                        setFailedDevices(failedDevList)
                    }
    
                    const newList = []
                    if(MULTI_TIME_ZONE){
                        newList.push(["brand", "branch", "ipaddress", "portnumber", "username", "password", "controllername", "devicename", "identifier", "timezone", "errorcause"])
                    }else{
                        newList.push(["brand", "branch", "ipaddress", "portnumber", "username", "password", "controllername", "devicename", "identifier", "errorcause"])
                    }
                    for (let i = 0; i < failedList.length; i++) {
                        const element = failedList[i];
                        // newList.push([element["brand"], element["panelIp"], element["branch"], element["panelName"], element['sensorLst']["zoneType"], element['sensorLst']["zoneName"], element['sensorLst']["zoneNumber"], element['interfaceName'], element["message"]])
                        if(MULTI_TIME_ZONE){
                            newList.push([element["brand"], element["branch"], element["ipaddress"], element["portnumber"], element["username"], element["password"], element["controllername"], /* element['interfaceName'],  */
                                element["devicename"], element["identifier"], element["timezone"],
                                element["message"]])
                        }else{
                            newList.push([element["brand"], element["branch"], element["ipaddress"], element["portnumber"], element["username"], element["password"], element["controllername"], /* element['interfaceName'],  */
                                element["devicename"], element["identifier"],
                                element["message"]])    
                        }
                    }
    
                    if (newList && newList.length > 1) {
                        setFaildListToDownload(newList)
                    }
                } //end
    
                /* success starts */
    
                // RUPESH PANEL BULK UPLOAD SUCCESS RESPONSE
    
                /* -------------- To be commented for bulk upload success data -------------- */
    
                // const sensorSuccessList = panelBulkUploadResponse['sensorSuccessList']
                // const panelSuccessList = panelBulkUploadResponse['panelSuccessList']
                // let successListTemp = [];
    
                // if ((sensorSuccessList && sensorSuccessList.length > 0) || (panelSuccessList && panelSuccessList.length > 0)) {
                //   if(sensorSuccessList.length > 0){
                //     successListTemp = successListTemp.concat(sensorSuccessList);
                //   }
                //   if(panelSuccessList.length > 0){
                //     for(let i=0; i<panelSuccessList.length; i++){
                //       for(let j=0; j<panelSuccessList[i].sensorLst.length; i++){
                //         let temp = {}
                //         temp['branch'] = panelSuccessList[i]['branch']
                //         temp['brand'] = panelSuccessList[i]['brand']
                //         temp['deviceID'] = panelSuccessList[i]['deviceID']
                //         temp['message'] = panelSuccessList[i]['message']
                //         temp['panelIp'] = panelSuccessList[i]['panelIp']
                //         temp['panelName'] = panelSuccessList[i]['panelName']
                //         temp['status'] = panelSuccessList[i]['status']
                //         temp['sensorLst'] = {
                //           'zoneName': panelSuccessList[i].sensorLst[j]['zoneName'],
                //           'zoneNumber': panelSuccessList[i].sensorLst[j]['zoneNumber'],
                //           'zoneType': panelSuccessList[i].sensorLst[j]['zoneType']
                //         }
                //         successListTemp.push(temp)
                //       }
                //     }
                //   }
                // }
                // let successDevList = successListTemp.length;
                // var successDevList = panelBulkUploadResponse && panelBulkUploadResponse['sensorSuccessList'] && panelBulkUploadResponse['sensorSuccessList'].length
    
    
                const panelSuccessList = panelBulkUploadResponse.AcsSuccessList
    
                if (panelSuccessList && panelSuccessList.length > 0) {
                    let successList = []
    
                    if (panelSuccessList.length > 0) {
                        for (let i = 0; i < panelSuccessList.length; i++) {
                            let temp = {}
                            temp['branch'] = panelSuccessList[i]['branch']
                            temp['brand'] = panelSuccessList[i]['brand']
                            // temp['deviceID'] = panelSuccessList[i]['deviceID']
                            temp['message'] = panelSuccessList[i]['message']
                            temp['ipaddress'] = panelSuccessList[i]['ipaddress']
                            temp['devicename'] = panelSuccessList[i]['devicename']
                            temp['controllername'] = panelSuccessList[i]['controllername']
                            temp['identifier'] = panelSuccessList[i]['identifier']
                            temp['status'] = panelSuccessList[i]['status']
                            // temp['interfaceName'] = panelSuccessList[i]['interfaceName']
                            temp['portnumber'] = panelSuccessList[i]['portnumber']
                            temp['username'] = panelSuccessList[i]['username']
                            temp['password'] = panelSuccessList[i]['password']
                            temp['timezone'] = panelSuccessList[i]['timezone']
                            successList.push(temp);
                        }
                    }
                    var successDevList = successList.length;
    
                    if (successDevList && successDevList !== undefined) {
                        setSuccessDevices(successDevList)
                    }
    
                    const newList2 = []
                    if(MULTI_TIME_ZONE){
                        newList2.push(["brand", "branch", "ipaddress", "portnumber", "username", "password", "controllername", "devicename", "identifier", "timezone", "reason"])
                    }else{
                        newList2.push(["brand", "branch", "ipaddress", "portnumber", "username", "password", "controllername", "devicename", "identifier", "reason"])
                    }
                    for (let i = 0; i < panelSuccessList.length; i++) {
                        const element = panelSuccessList[i];
                        if(MULTI_TIME_ZONE){
                            newList2.push([element["brand"], element["branch"], element["ipaddress"], element["portnumber"], element["username"], element["password"], element["controllername"], /* element['interfaceName'],  */
                            element["devicename"], element["identifier"], element["timezone"],
                            element["message"]])
                        }else{
                            newList2.push([element["brand"], element["branch"], element["ipaddress"], element["portnumber"], element["username"], element["password"], element["controllername"], /* element['interfaceName'],  */
                            element["devicename"], element["identifier"],
                            element["message"]])    
                        }
                    }
    
                    if (newList2 && newList2.length > 1) {
                        setSucessListToDownload(newList2)
                    }
                }
    
                /* success ends */
            }
        }, [panelBulkUploadResponse]);
    
        useEffect(() => {
            const fetchData = async () => {
                if (panelBulkUploadError.length > 0) {
                    let newList;
                    if(MULTI_TIME_ZONE){
                        newList = [["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", /* "interfacename", */ "portnumber", "interfaceusername", "interfacepassword", "timezone", "errorcause"]];
                    }else{
                        newList = [["brand", "ipaddress", "branch", "name", "zonetype", "zonename", "zonenumber", /* "interfacename", */ "portnumber", "interfaceusername", "interfacepassword", "errorcause"]];
                    }
                    for (let i = 0; i < panelBulkUploadError.length; i++) {
                        const element = panelBulkUploadError[i];
                        newList.push(element);
                    }
                    if (newList && newList.length >= 1) {
                        setErrorListToDownload(newList);
                    }
                }
            };
    
            fetchData();
        }, [panelBulkUploadError]);
        //#endregion useEffect end
    
        const handleUploadCSVClick = (event) => {
            event.preventDefault()
            setErrorListToDownload(null)
            hiddenPanelFileInput.current.click();
        };
    
        const handleUploaderChange = (event) => {
            console.log("handleUploaderChange", event);
            event.preventDefault()
            setImportedFileData(null);
            console.log("handleUploaderChange", event.target.files);
            Array.from(event.target.files)
                .filter(
                    (file) =>
                        file.type === "text/csv" || file.type === "application/vnd.ms-excel"
                )
                .forEach(async (file) => {
                    console.log("handleUploaderChange", file);
                    let acsHeadObj = {
                        brand: null,
                        branch: null,
                        ipaddress: null,
                        portnumber: null,
                        username: null,
                        password: null,
                        controllername: null,
                        devicename: null,
                        identifier: null,                        
                    };
                    if(MULTI_TIME_ZONE){
                        acsHeadObj.timezone= null;
                    }
                    console.log("handleUploaderChange", acsHeadObj);
                    setSelectedHeaders(acsHeadObj);
                    const text = await file.text();
                    //*********tej 27-02-2023********* */
                    // parse the CSV data into an array of arrays using PapaParse
                    const parsedData = Papa.parse(text).data;
                    // filter out empty or comma-separated rows from the parsed data
                    const result = parsedData.filter(row => {
                        return row.join('').trim() !== '';
                    });
                    // format the filtered data back into CSV format using PapaParse
                    // const filteredCsv = Papa.unparse(filteredData);
                    //****************** */
                    setTotalDevices(null)
                    setPanelImportedFileName(null)
                    setImportedPanelHeaders(null)
                    setImportedFileData(null)
                    setFailedDevices(null)
                    setSuccessDevices(null)
    
                    // const text = await file.text();
                    // const result = parse(text, { header: true });
                    console.log("handleUploaderChange", result);
                    const actualData = result.slice(1, result.length);
                    setTotalDevices(actualData.length)
                    setPanelImportedFileName(file['name'])
                    setPanelBulkUploadResponse([])
                    setPanelBulkUploadError([])
                    console.log("handleUploaderChange", actualData);
                    if (result && actualData) {
                        setImportedPanelHeaders(result[0]);
                        const dataToUpload = actualData;
                        setImportedFileData(dataToUpload);
                    }
                });
        };
    
        const currentDate = new Date().toLocaleDateString().replace(/\//g, "-");
        const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    
    

    return (
        <>
            <div>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            Go to back
                        </Tooltip>
                    }
                >
                    <button className="DataAccessEdit" onClick={() => {
                        setBulkUpload(false);
                        setIsSingleOrBulkSelection("single_device");
                    }}>
                        <img src={BackArrowDevice} alt="back arrow icon" className="EditedIcon" />
                    </button>
                </OverlayTrigger>
            </div>
            <div style={{ width: "75%", margin: "0 auto" }}>
                <div className="bulk-upload-msg-text" style={{ textAlign: "right", margin: "0px" }}>Click <a
                    // href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/PanelBulkUploadSample.csv"
                    //href="https://astrostaticfilesbucket.s3.ap-south-1.amazonaws.com/Panel-Bulk-Upload-SampleFile.csv"
                    href={ACS_BULK_UPLOAD_LINK}
                    download
                >
                    here
                </a> to download template</div>
                <div className="BulkUploadBrowseDiv">
                    <input
                        className="BulkUploadInput"
                        value={panelImportedFileName}
                        disabled={true}
                    />
                    <button className="DataAccessEdit" style={{ width: "100px" }} onClick={handleUploadCSVClick}>
                        <span className="ViewUpdate">Browse</span>
                    </button>
                </div>
            </div>
            
            <div style={{ marginTop: "15px", display: "flex", flexDirection: "column" }}>
                <div className="DeviceList">
                    <form
                        id="hook-form"
                        onSubmit={handleSubmit((e) =>
                            onPanelBulkUpload(e, importedPanelHeaders)
                        )}
                    >
                        <div className="DeviceTextBox DeviceListBox">
                            <Row className="header-width" style={{ margin: "0 auto" }}>
                                <div className="Add_Devices add-devices-heading">
                                    <p>Match the headers</p>
                                </div>
                            </Row>
                            <div className="lst-section-width">
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Brand Name<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultBrand && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultBrand={setDefaultBrand}
                                                label="brand"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultBrand && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultBrand}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="brand"
                                                setDefaultBrand={setDefaultBrand}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Branch<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultBranch && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultBranch={setDefaultBranch}
                                                label="branch"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultBranch && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultBranch}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="branch"
                                                setDefaultBranch={setDefaultBranch}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>
                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            IP Address<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultIp && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultIp={setDefaultIp}
                                                label="ipaddress"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultIp && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultIp}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="ipaddress"
                                                setDefaultIp={setDefaultIp}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Port Number<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultPortNumber && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultPortNumber={setDefaultPortNumber}
                                                label="portnumber"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultPortNumber && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultPortNumber}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="portnumber"
                                                setDefaultPortNumber={setDefaultPortNumber}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Interface Username<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultUsername && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultInterfaceUsername={setDefaultUsername}
                                                label="username"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultUsername && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultUsername}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="username"
                                                setDefaultInterfaceUsername={setDefaultUsername}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Interface Password<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultPassword && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultInterfacePassword={setDefaultPassword}
                                                label="interfacepassowrd"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultPassword && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultPassword}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="interfacepassowrd"
                                                setDefaultInterfacePassword={setDefaultPassword}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Controller Name<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultControllerName && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultControllerName={setDefaultControllerName}
                                                label="controllername"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultControllerName && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultControllerName}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="controllername"
                                                setDefaultControllerName={setDefaultControllerName}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Device Name<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultDeviceName && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultDeviceName={setDefaultDeviceName}
                                                label="devicename"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultDeviceName && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultDeviceName}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="devicename"
                                                setDefaultDeviceName={setDefaultDeviceName}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                <Row className="lst-header-width">
                                    <div className="column">
                                        <p className="SiteNameText BulkUploadTextName ml-2">
                                            Identifier Name<span style={{ color: "red" }}> *</span>
                                        </p>
                                    </div>
                                    <div className="column1">
                                        {!defaultControllerName && (
                                            <SelectHeaderEmpty
                                                importedFileHeaders={importedPanelHeaders}
                                                setDefaultIdentifierName={setDefaultIdentifierName}
                                                label="identifier"
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                        {defaultControllerName && (
                                            <SelectHeaderCommon
                                                defaultVal={defaultIdentifierName}
                                                importedFileHeaders={importedPanelHeaders}
                                                label="identifier"
                                                setDefaultIdentifierName={setDefaultIdentifierName}
                                                selectedHeaders={selectedHeaders}
                                            />
                                        )}
                                    </div>
                                </Row>

                                {
                                    MULTI_TIME_ZONE
                                        ?
                                        <Row className="lst-header-width">
                                            <div className="column">
                                                <p className="SiteNameText BulkUploadTextName ml-2">
                                                    Timezone<span style={{ color: "red" }}> *</span>
                                                </p>
                                            </div>
                                            <div className="column1">
                                                {!defaultTimezone && (
                                                    <SelectHeaderEmpty
                                                        importedFileHeaders={importedPanelHeaders}
                                                        setDefaultTimezone={setDefaultTimezone}
                                                        label="timezone"
                                                        selectedHeaders={selectedHeaders}
                                                    />
                                                )}
                                                {defaultTimezone && (
                                                    <SelectHeaderCommon
                                                        defaultVal={defaultTimezone}
                                                        importedFileHeaders={importedPanelHeaders}
                                                        label="timezone"
                                                        setDefaultTimezone={setDefaultTimezone}
                                                        selectedHeaders={selectedHeaders}
                                                    />
                                                )}
                                            </div>
                                        </Row>
                                        : null
                                }

                            </div>
                            <div
                                className="BulkText1 AlignBulk"
                            >
                                <input
                                    type="file"
                                    multiple={false}
                                    ref={hiddenPanelFileInput}
                                    onChange={(e) => handleUploaderChange(e)}
                                    style={{ display: "none" }}
                                    value=""
                                />
                            </div>

                            <p style={{ textAlign: "center" }}>

                                {

                                    panelImportedFileName && totalDevices && panelBulkUploadResponse &&
                                    !(panelBulkUploadResponse['AcsSuccessList'] || panelBulkUploadResponse['AcsFailedList']) &&
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={<Tooltip>{panelImportedFileName}</Tooltip>}>
                                        <span className="bulk-upload-msg-text">({totalDevices} Devices)</span>
                                    </OverlayTrigger>
                                }

                                {panelBulkUploadResponse && failedDevices && failedDevices != 0 && totalDevices &&
                                    <span className="bulk-upload-msg-text template-name">
                                        <CSVLink filename={`Acs Failed List_${currentDate}_${currentTime}.csv`} className="mendat-fields" data={faildListToDownload}>{failedDevices} /
                                            {totalDevices} Failed Uploads</CSVLink> </span>

                                }


                                {panelBulkUploadError && panelImportedFileName && errorListToDownload &&
                                    <span className="bulk-upload-msg-text template-name">
                                        <CSVLink filename={`Acs Error List_${currentDate}_${currentTime}.csv`} className="mendat-fields" data={errorListToDownload}>Error List</CSVLink> </span>
                                }

                                {panelBulkUploadResponse && successDevices && successDevices != 0 && totalDevices &&
                                    <span className="bulk-upload-msg-text template-name">
                                        <CSVLink filename={`Acs Success List_${currentDate}_${currentTime}.csv`} className="mendat-fields" data={sucessListToDownload}>{successDevices} /
                                            {totalDevices} Uploads Successful</CSVLink> </span>
                                }

                            </p>
                        </div>
                    </form>
                </div>
                <div style={{ textAlign: "center", marginTop: "25px" }}>
                {
                    true &&
                    (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                            onClick={handleSubmit((e) => {
                                onAcsBulkUpload(e, importedPanelHeaders);
                            })}
                        >
                            <span className="ViewUpdate">Upload</span>
                        </button>
                    )
                }
            </div>
            </div>
            
        </>
    );
}

export default AcsBulkUpload