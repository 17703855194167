import React, { memo, useCallback } from "react";

const HealthListView = ({
  eventKey,
  setShow,
  show,
  data,
  selectedItem,
  isNotPointer,
  isSensor,
  showFour,
}) => {
  // console.log("eventKey => ", eventKey)
  console.count("HealthListView component render count: ");
  const handelItemClick = useCallback(() => {
    selectedItem(data);
  }, [data]);
  return (
    <tr
      onClick={
        eventKey.toLowerCase() === "Sensor".toLowerCase() || eventKey.toLowerCase() === "Non-Integrated-Devices".toLowerCase()
          ? null
          : handelItemClick
      }
    >
      <td style={{ cursor: isNotPointer ? "default" : "pointer" }}>
        {data?.CustomerName}
      </td>
      <td style={{ cursor: isNotPointer ? "default" : "pointer" }}>
        {data.SiteName}
      </td>
      <td style={{ cursor: isNotPointer ? "default" : "pointer" }}>
        {data.InterfaceName}
      </td>
      <td style={{ cursor: isNotPointer ? "default" : "pointer" }}>
        {isSensor ? data.ZoneName : data.DeviceName}
      </td>
      {showFour ? (
        <>
          <td style={{ cursor: isNotPointer ? "default" : "pointer" }}>
            {data.DeviceType}
          </td>
          <td style={{ cursor: isNotPointer ? "default" : "pointer" }}>
            {data.Abbreviation}
          </td>
        </>
      ) : (
        <>
          <td
            style={{ cursor: isNotPointer ? "default" : "pointer" }}
            className={
              data.DeviceStatus == "Online" ||
              data.DeviceStatus == "Normal" ||
              data.DeviceStatus == "Zone Restoral"
                ? "online-color"
                : data.DeviceStatus == "Zone Alert" ||
                  data.DeviceStatus == "Offline" ||
                  data.DeviceStatus == "Faulty"
                ? "offline-color"
                : ""
            }
          >
            {data.DeviceStatus}
          </td>
          <td style={{ cursor: isNotPointer ? "default" : "pointer" }}>
            {data.Abbreviation}
          </td>
          <td style={{ cursor: isNotPointer ? "default" : "pointer" }}>
            {data.StatusDateTime}
          </td>
        </>
      )}
    </tr>
  );
};

export default memo(HealthListView);
