
//#region lib
import { useEffect, useState, useRef } from 'react'
import "rsuite/dist/rsuite.min.css";
//#endregion lib

//#region custom hooks 
import { WarningToast } from '../../CustomHooks/WarningToast'
import { THIRTY_MINUTES } from "../../navigation/CONSTANTS";
import CameraCard from "./CameraCard";
import CameraTileSkeleton from "./CameraTileSkeleton";
//#endregion

const CameraTileComponent = ({
  cameraUI,
  isCheckBoxVisible,
  setIsDatePickerAccessAllowed,
  isMultiPlay,
  cameraMasterList,
  isLoading,
  isChecked,
  setIsChecked,
  setIsSingleDevPlaybackBtnClick,
  singleDevPlaybackClick,
  setIsDatePickerVisible,
  exportStartTime,
  exportEndTime,
  selectedTimeZone,
  setSelectedTimeZone,
  offSetMintues,
  setOffSetMinutes
}) => {
  //#region useState start
  const [time, setTime] = useState(Date.now());
  const [selectedCams, setSelectedCams] = useState(0);
  // const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  //#endregion useState end

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isMultiPlay == false) {
        setTime(Date.now())
      }
    }, THIRTY_MINUTES); // set the interval to 30 mins
    return () => clearInterval(intervalId); // clear the interval on unmount
  }, []);

  const onCheckBoxOnClick = (e) => {
    const chkStatus = e.target.checked
    var selDevLst = []
    if (cameraUI && cameraUI.length > 0) {
      const selectedCamera = cameraUI.find(x => x.ParentCameraID == e.target.id)
      const selMasterDataCam = cameraMasterList.find(x => x.ParentCameraID == e.target.id)
      selDevLst = cameraMasterList.filter(x => x.isChecked === true)
      if (selDevLst.length >= 4 && chkStatus == true) {
        WarningToast('Only four devices are allowed to select at a time!')
        e.preventDefault();
        return
      }
      else {
        setIsChecked(true)
      }
      setSelectedCams(selDevLst.length)
      if (selectedCamera && selectedCamera != undefined) {
        selectedCamera.isChecked = chkStatus
        selMasterDataCam.isChecked = chkStatus
        if(selDevLst.length == 0 && chkStatus ){
          // setSelectedTimeZone(selectedCamera.BrandName);
          setSelectedTimeZone(selectedCamera.Abbreviation);
          setOffSetMinutes(selectedCamera.OffsetMinutes);
        }
        if(selDevLst.length == 1 && !chkStatus){
          setSelectedTimeZone(null);
          setOffSetMinutes(330);
        }
      }
      const currentSelDevLst = cameraMasterList.filter(x => x.isChecked === true)
      if (currentSelDevLst.length > 1) {
        setIsDatePickerAccessAllowed(true)
      }
      else {
        setIsDatePickerAccessAllowed(false)
      }
    }
  }

  const onSingleDevPlaybackHandleClick = (dev) => {
    setIsSingleDevPlaybackBtnClick(true)
    setIsDatePickerVisible(true)
    singleDevPlaybackClick(dev)
  }

  return (
    <>
      {isLoading ?
        <>
          {
            Array.from(Array(selectedCams + 1)).map(() => (
              <CameraTileSkeleton></CameraTileSkeleton>
            ))
          }
        </>
        :
        cameraUI && cameraUI.length > 0 &&
        cameraUI.map((item) => (
          <CameraCard item={item} time={time} key={item.ParentCameraID} isMultiPlay={isMultiPlay} isCheckBoxVisible={isCheckBoxVisible} onSingleDevPlaybackHandleClick={onSingleDevPlaybackHandleClick} isChecked={isChecked} onCheckBoxOnClick={onCheckBoxOnClick} exportStartTime={exportStartTime} exportEndTime={exportEndTime} selectedTimeZone={selectedTimeZone} />
        ))
      }
    </>
  )
}

export default CameraTileComponent;