import React from 'react'

import { RetailStore2, RetailStore2White } from '../../../assets/images';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const AlertTemplateList = ({ list, getAlertTemplateById, setDisplayButtons, isDarkTheme }) => {
    return (
        <div>
            <>
                <div className='ml-4 mt20' >
                    <p className='templatedevicename'>Template List</p>
                </div>
                <div className='Notification-form'>
                    <div className='mt-3'>
                        {
                            list.map((item) => (
                                <div className='OneList' key={item.AlertTemplateId} onClick={() => {
                                    getAlertTemplateById(item.AlertTemplateId);
                                    setDisplayButtons(true);
                                }}>
                                    <p className="ListofText">
                                        {item.TemplateName}
                                        <span className="SiteNameText"> - {item.AlertAssociated}</span>
                                    </p>
                                    <div>
                                        {
                                            item.IsMobileAlert == "True" ?
                                                (
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip>
                                                                {item.SiteMode}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        {
                                                            isDarkTheme ?
                                                                <img src={RetailStore2} alt='retailstore icon' style={{ height: "15px" }} />
                                                                :
                                                                <img src={RetailStore2White} alt='retailstore icon' style={{ height: "15px" }} />
                                                        }
                                                    </OverlayTrigger>
                                                )
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </>
        </div>
    )
}
