import { BASE_CORE_URL } from "../config";

export const GetmultiTimeZone = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
      },
    };
    let url = BASE_CORE_URL + "MultiTimeZone/GetTimeZone";
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        console.error("GetTimeZone () Exception: ", error);
      });
  
  }