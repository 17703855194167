import React from 'react'

const CustomerReportList = ({item}) => {
  return (
      <tr>
          <td>{item.CustomerName}</td>
          {/* <td>{item.SiteName}</td> */}
          <td>{item.OpenCount}</td>
          <td>{item.AcknowledgedCount}</td>
          <td>{item.ClosedCount}</td>
          <td>{item.TotalCount}</td>
          {/* <td>{item.Abbreviation}</td> */}
        </tr>
  )
}

export default CustomerReportList
