import React, { useEffect, useRef, useState } from 'react'

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip'
import { OverlayTrigger } from 'react-bootstrap';

import { ErrorToast } from '../../../CustomHooks/ErrorToast';
import { WarningToast } from '../../../CustomHooks/WarningToast';

const CustomScheduler = React.forwardRef((props, ref) => {
    let { isAlertTemplateEdit, sevenDaysRef, resource, sevenDaysData, setSevenDaysData, workflows, severities, gettingData, setGettingData, isStore } = props;

    const isFirstRender = useRef(true);

    //#region useState start
    const [days, setDays] = useState([
        { id: 0, hour: 0, selected: false, backgroundColor: "none", hourLabel: "00", severity: "", workflow: "", hourLabelFull: "00:00", severityName: "", workflowName: "" },
        { id: 1, hour: 1, selected: false, backgroundColor: "none", hourLabel: "01", severity: "", workflow: "", hourLabelFull: "01:00", severityName: "", workflowName: "" },
        { id: 2, hour: 2, selected: false, backgroundColor: "none", hourLabel: "02", severity: "", workflow: "", hourLabelFull: "02:00", severityName: "", workflowName: "" },
        { id: 3, hour: 3, selected: false, backgroundColor: "none", hourLabel: "03", severity: "", workflow: "", hourLabelFull: "03:00", severityName: "", workflowName: "" },
        { id: 4, hour: 4, selected: false, backgroundColor: "none", hourLabel: "04", severity: "", workflow: "", hourLabelFull: "04:00", severityName: "", workflowName: "" },
        { id: 5, hour: 5, selected: false, backgroundColor: "none", hourLabel: "05", severity: "", workflow: "", hourLabelFull: "05:00", severityName: "", workflowName: "" },
        { id: 6, hour: 6, selected: false, backgroundColor: "none", hourLabel: "06", severity: "", workflow: "", hourLabelFull: "06:00", severityName: "", workflowName: "" },
        { id: 7, hour: 7, selected: false, backgroundColor: "none", hourLabel: "07", severity: "", workflow: "", hourLabelFull: "07:00", severityName: "", workflowName: "" },
        { id: 8, hour: 8, selected: false, backgroundColor: "none", hourLabel: "08", severity: "", workflow: "", hourLabelFull: "08:00", severityName: "", workflowName: "" },
        { id: 9, hour: 9, selected: false, backgroundColor: "none", hourLabel: "09", severity: "", workflow: "", hourLabelFull: "09:00", severityName: "", workflowName: "" },
        { id: 10, hour: 10, selected: false, backgroundColor: "none", hourLabel: "10", severity: "", workflow: "", hourLabelFull: "10:00", severityName: "", workflowName: "" },
        { id: 11, hour: 11, selected: false, backgroundColor: "none", hourLabel: "11", severity: "", workflow: "", hourLabelFull: "11:00", severityName: "", workflowName: "" },
        { id: 12, hour: 12, selected: false, backgroundColor: "none", hourLabel: "12", severity: "", workflow: "", hourLabelFull: "12:00", severityName: "", workflowName: "" },
        { id: 13, hour: 13, selected: false, backgroundColor: "none", hourLabel: "13", severity: "", workflow: "", hourLabelFull: "13:00", severityName: "", workflowName: "" },
        { id: 14, hour: 14, selected: false, backgroundColor: "none", hourLabel: "14", severity: "", workflow: "", hourLabelFull: "14:00", severityName: "", workflowName: "" },
        { id: 15, hour: 15, selected: false, backgroundColor: "none", hourLabel: "15", severity: "", workflow: "", hourLabelFull: "15:00", severityName: "", workflowName: "" },
        { id: 16, hour: 16, selected: false, backgroundColor: "none", hourLabel: "16", severity: "", workflow: "", hourLabelFull: "16:00", severityName: "", workflowName: "" },
        { id: 17, hour: 17, selected: false, backgroundColor: "none", hourLabel: "17", severity: "", workflow: "", hourLabelFull: "17:00", severityName: "", workflowName: "" },
        { id: 18, hour: 18, selected: false, backgroundColor: "none", hourLabel: "18", severity: "", workflow: "", hourLabelFull: "18:00", severityName: "", workflowName: "" },
        { id: 19, hour: 19, selected: false, backgroundColor: "none", hourLabel: "19", severity: "", workflow: "", hourLabelFull: "19:00", severityName: "", workflowName: "" },
        { id: 20, hour: 20, selected: false, backgroundColor: "none", hourLabel: "20", severity: "", workflow: "", hourLabelFull: "20:00", severityName: "", workflowName: "" },
        { id: 21, hour: 21, selected: false, backgroundColor: "none", hourLabel: "21", severity: "", workflow: "", hourLabelFull: "21:00", severityName: "", workflowName: "" },
        { id: 22, hour: 22, selected: false, backgroundColor: "none", hourLabel: "22", severity: "", workflow: "", hourLabelFull: "22:00", severityName: "", workflowName: "" },
        { id: 23, hour: 23, selected: false, backgroundColor: "none", hourLabel: "23", severity: "", workflow: "", hourLabelFull: "23:00", severityName: "", workflowName: "" },
    ])
    const [isUpdating, setIsUpdating] = useState(false);
    const [counting, setCounting] = useState(0);
    const [isUpdatingDiv, setIsUpdatingDiv] = useState([]);
    const [ogStartValue, setOgStartValue] = useState("");
    const [ogEndValue, setOgEndValue] = useState("");
    const [startValue, setStartValue] = useState("");
    const [endValue, setEndValue] = useState("");
    const [show, setShow] = useState(false);
    const [severity, setSeverity] = useState("");
    const [workflowSel, setWorkflowSel] = useState("");
    const [severityName, setSeverityName] = useState("")
    const [workflowName, setWorkflowName] = useState("");
    const [countTwo, setCountTwo] = useState(0);
    const [disableDates, setDisableDates] = useState(false);
    //#endregion useState end

    //#region useEffect start
    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    useEffect(() => {
        if (gettingData) {
            return;
        }
        if (!isFirstRender.current) {
            updateMaster();
        }
    }, [counting]);

    useEffect(() => {
        let individualData = sevenDaysData.filter((item) => item?.day == resource);
        let tempArr = individualData[0]?.dayResource
        // New
        let arr = [
            { id: 0, hour: 0, selected: false, backgroundColor: "none", hourLabel: "00", severity: "", workflow: "", hourLabelFull: "00:00", severityName: "", workflowName: "" },
            { id: 1, hour: 1, selected: false, backgroundColor: "none", hourLabel: "01", severity: "", workflow: "", hourLabelFull: "01:00", severityName: "", workflowName: "" },
            { id: 2, hour: 2, selected: false, backgroundColor: "none", hourLabel: "02", severity: "", workflow: "", hourLabelFull: "02:00", severityName: "", workflowName: "" },
            { id: 3, hour: 3, selected: false, backgroundColor: "none", hourLabel: "03", severity: "", workflow: "", hourLabelFull: "03:00", severityName: "", workflowName: "" },
            { id: 4, hour: 4, selected: false, backgroundColor: "none", hourLabel: "04", severity: "", workflow: "", hourLabelFull: "04:00", severityName: "", workflowName: "" },
            { id: 5, hour: 5, selected: false, backgroundColor: "none", hourLabel: "05", severity: "", workflow: "", hourLabelFull: "05:00", severityName: "", workflowName: "" },
            { id: 6, hour: 6, selected: false, backgroundColor: "none", hourLabel: "06", severity: "", workflow: "", hourLabelFull: "06:00", severityName: "", workflowName: "" },
            { id: 7, hour: 7, selected: false, backgroundColor: "none", hourLabel: "07", severity: "", workflow: "", hourLabelFull: "07:00", severityName: "", workflowName: "" },
            { id: 8, hour: 8, selected: false, backgroundColor: "none", hourLabel: "08", severity: "", workflow: "", hourLabelFull: "08:00", severityName: "", workflowName: "" },
            { id: 9, hour: 9, selected: false, backgroundColor: "none", hourLabel: "09", severity: "", workflow: "", hourLabelFull: "09:00", severityName: "", workflowName: "" },
            { id: 10, hour: 10, selected: false, backgroundColor: "none", hourLabel: "10", severity: "", workflow: "", hourLabelFull: "10:00", severityName: "", workflowName: "" },
            { id: 11, hour: 11, selected: false, backgroundColor: "none", hourLabel: "11", severity: "", workflow: "", hourLabelFull: "11:00", severityName: "", workflowName: "" },
            { id: 12, hour: 12, selected: false, backgroundColor: "none", hourLabel: "12", severity: "", workflow: "", hourLabelFull: "12:00", severityName: "", workflowName: "" },
            { id: 13, hour: 13, selected: false, backgroundColor: "none", hourLabel: "13", severity: "", workflow: "", hourLabelFull: "13:00", severityName: "", workflowName: "" },
            { id: 14, hour: 14, selected: false, backgroundColor: "none", hourLabel: "14", severity: "", workflow: "", hourLabelFull: "14:00", severityName: "", workflowName: "" },
            { id: 15, hour: 15, selected: false, backgroundColor: "none", hourLabel: "15", severity: "", workflow: "", hourLabelFull: "15:00", severityName: "", workflowName: "" },
            { id: 16, hour: 16, selected: false, backgroundColor: "none", hourLabel: "16", severity: "", workflow: "", hourLabelFull: "16:00", severityName: "", workflowName: "" },
            { id: 17, hour: 17, selected: false, backgroundColor: "none", hourLabel: "17", severity: "", workflow: "", hourLabelFull: "17:00", severityName: "", workflowName: "" },
            { id: 18, hour: 18, selected: false, backgroundColor: "none", hourLabel: "18", severity: "", workflow: "", hourLabelFull: "18:00", severityName: "", workflowName: "" },
            { id: 19, hour: 19, selected: false, backgroundColor: "none", hourLabel: "19", severity: "", workflow: "", hourLabelFull: "19:00", severityName: "", workflowName: "" },
            { id: 20, hour: 20, selected: false, backgroundColor: "none", hourLabel: "20", severity: "", workflow: "", hourLabelFull: "20:00", severityName: "", workflowName: "" },
            { id: 21, hour: 21, selected: false, backgroundColor: "none", hourLabel: "21", severity: "", workflow: "", hourLabelFull: "21:00", severityName: "", workflowName: "" },
            { id: 22, hour: 22, selected: false, backgroundColor: "none", hourLabel: "22", severity: "", workflow: "", hourLabelFull: "22:00", severityName: "", workflowName: "" },
            { id: 23, hour: 23, selected: false, backgroundColor: "none", hourLabel: "23", severity: "", workflow: "", hourLabelFull: "23:00", severityName: "", workflowName: "" },
        ]
        if (tempArr?.length > 0) {
            for (let i = 0; i < tempArr.length; i++) {
                let startIndex = days.findIndex(x => x.hourLabel == tempArr[i].monitoringStartTime.slice(0, 2))
                let endIndex = days.findIndex(x => x.hourLabel == tempArr[i].monitoringEndTime.slice(0, 2));
                let severity = tempArr[i].severityId;
                let baC = "6fa8dc"
                if (severity == "1") {
                    baC = "#DD2021";
                } else if (severity == "2") {
                    baC = "#D06841";
                } else if (severity == "3") {
                    baC = "#DAA925";
                } else if (severity == "4") {
                    baC = "#83BB2B"
                } else {
                    baC = "#83BB2B"
                }
                if (endIndex == 0) {
                    for (let j = startIndex; j <= 23; j++) {
                        arr[j].selected = true;
                        arr[j].severity = severity;
                        arr[j].workflow = tempArr[i].workflowId;
                        arr[j].backgroundColor = baC
                    }
                } else {
                    for (let j = startIndex; j < endIndex; j++) {
                        arr[j].selected = true;
                        arr[j].severity = severity;
                        arr[j].workflow = tempArr[i].workflowId;
                        arr[j].backgroundColor = baC
                    }
                }
            }
            setDays(arr);
        } else {
            setDays([
                { id: 0, hour: 0, selected: false, backgroundColor: "none", hourLabel: "00", severity: "", workflow: "", hourLabelFull: "00:00", severityName: "", workflowName: "" },
                { id: 1, hour: 1, selected: false, backgroundColor: "none", hourLabel: "01", severity: "", workflow: "", hourLabelFull: "01:00", severityName: "", workflowName: "" },
                { id: 2, hour: 2, selected: false, backgroundColor: "none", hourLabel: "02", severity: "", workflow: "", hourLabelFull: "02:00", severityName: "", workflowName: "" },
                { id: 3, hour: 3, selected: false, backgroundColor: "none", hourLabel: "03", severity: "", workflow: "", hourLabelFull: "03:00", severityName: "", workflowName: "" },
                { id: 4, hour: 4, selected: false, backgroundColor: "none", hourLabel: "04", severity: "", workflow: "", hourLabelFull: "04:00", severityName: "", workflowName: "" },
                { id: 5, hour: 5, selected: false, backgroundColor: "none", hourLabel: "05", severity: "", workflow: "", hourLabelFull: "05:00", severityName: "", workflowName: "" },
                { id: 6, hour: 6, selected: false, backgroundColor: "none", hourLabel: "06", severity: "", workflow: "", hourLabelFull: "06:00", severityName: "", workflowName: "" },
                { id: 7, hour: 7, selected: false, backgroundColor: "none", hourLabel: "07", severity: "", workflow: "", hourLabelFull: "07:00", severityName: "", workflowName: "" },
                { id: 8, hour: 8, selected: false, backgroundColor: "none", hourLabel: "08", severity: "", workflow: "", hourLabelFull: "08:00", severityName: "", workflowName: "" },
                { id: 9, hour: 9, selected: false, backgroundColor: "none", hourLabel: "09", severity: "", workflow: "", hourLabelFull: "09:00", severityName: "", workflowName: "" },
                { id: 10, hour: 10, selected: false, backgroundColor: "none", hourLabel: "10", severity: "", workflow: "", hourLabelFull: "10:00", severityName: "", workflowName: "" },
                { id: 11, hour: 11, selected: false, backgroundColor: "none", hourLabel: "11", severity: "", workflow: "", hourLabelFull: "11:00", severityName: "", workflowName: "" },
                { id: 12, hour: 12, selected: false, backgroundColor: "none", hourLabel: "12", severity: "", workflow: "", hourLabelFull: "12:00", severityName: "", workflowName: "" },
                { id: 13, hour: 13, selected: false, backgroundColor: "none", hourLabel: "13", severity: "", workflow: "", hourLabelFull: "13:00", severityName: "", workflowName: "" },
                { id: 14, hour: 14, selected: false, backgroundColor: "none", hourLabel: "14", severity: "", workflow: "", hourLabelFull: "14:00", severityName: "", workflowName: "" },
                { id: 15, hour: 15, selected: false, backgroundColor: "none", hourLabel: "15", severity: "", workflow: "", hourLabelFull: "15:00", severityName: "", workflowName: "" },
                { id: 16, hour: 16, selected: false, backgroundColor: "none", hourLabel: "16", severity: "", workflow: "", hourLabelFull: "16:00", severityName: "", workflowName: "" },
                { id: 17, hour: 17, selected: false, backgroundColor: "none", hourLabel: "17", severity: "", workflow: "", hourLabelFull: "17:00", severityName: "", workflowName: "" },
                { id: 18, hour: 18, selected: false, backgroundColor: "none", hourLabel: "18", severity: "", workflow: "", hourLabelFull: "18:00", severityName: "", workflowName: "" },
                { id: 19, hour: 19, selected: false, backgroundColor: "none", hourLabel: "19", severity: "", workflow: "", hourLabelFull: "19:00", severityName: "", workflowName: "" },
                { id: 20, hour: 20, selected: false, backgroundColor: "none", hourLabel: "20", severity: "", workflow: "", hourLabelFull: "20:00", severityName: "", workflowName: "" },
                { id: 21, hour: 21, selected: false, backgroundColor: "none", hourLabel: "21", severity: "", workflow: "", hourLabelFull: "21:00", severityName: "", workflowName: "" },
                { id: 22, hour: 22, selected: false, backgroundColor: "none", hourLabel: "22", severity: "", workflow: "", hourLabelFull: "22:00", severityName: "", workflowName: "" },
                { id: 23, hour: 23, selected: false, backgroundColor: "none", hourLabel: "23", severity: "", workflow: "", hourLabelFull: "23:00", severityName: "", workflowName: "" },
            ])
        }
        let c = countTwo + 1;
        setCountTwo(c);
    }, [sevenDaysData]);
    //#endregion useEffect end

    //#region functions start

    const handleShow = (e, item) => {
        setGettingData(false);
        let elementId = item.selected;
        let severityId = item.severity;
        let workflowId = item.workflow;
        if (elementId) {
            if (isStore) {
                setDisableDates(true)
            } else {
                setDisableDates(false)
            }
            setIsUpdating(true);
            //It is already selected element.
            let newStart = item.id;
            let newEnd = item.id + 1;
            let selectedSeverity = "";
            let selectedWorkflow = "";
            let sampleArr = [];
            // First we need to check the left part 
            for (let i = item.id; i >= 0; i--) {
                if (days[i].selected && days[i].severity == severityId && days[i].workflow == workflowId) {
                    newStart = days[i].id;
                    selectedSeverity = days[i].severity;
                    selectedWorkflow = days[i].workflow
                } else {
                    break;
                }
            }

            // Then we need to check the right part.
            for (let j = item.id + 1; j <= 23; j++) {
                if (days[j].selected && days[j].severity == severityId && days[j].workflow == workflowId) {
                    newEnd = days[j].id + 1;
                    selectedSeverity = days[j].severity;
                    selectedWorkflow = days[j].workflow
                } else {
                    break;
                }
            }
            for (let k = newStart; k < newEnd; k++) {
                sampleArr.push(days[k].id);
            }
            setIsUpdatingDiv(sampleArr);
            if (newStart > 9) {
                setStartValue(`${days[newStart].hourLabel}:00`);
                setOgStartValue(`${days[newStart].hourLabel}:00`);
            } else {
                setStartValue(`0${newStart}:00`)
                setOgStartValue(`0${newStart}:00`)
            }
            if (newEnd > 9 && newEnd != 24) {
                setEndValue(`${parseInt(days[newEnd - 1].hourLabel) + 1}:00`)
                setOgEndValue(`${parseInt(days[newEnd - 1].hourLabel) + 1}:00`)
            } else if (newEnd == 24) {
                setEndValue("00:00")
                setOgEndValue("00:00")
            } else {
                setEndValue(`0${parseInt(newEnd)}:00`)
                setOgEndValue(`0${parseInt(newEnd)}:00`)
            }
            setSeverity(selectedSeverity);
            setWorkflowSel(selectedWorkflow);
            setShow(true);
        }
        else {
            if (isStore) {
                setDisableDates(true)
            } else {
                setDisableDates(false)
            }
            if (item.id == 23) {
                setStartValue(`23:00`)
                setOgStartValue(`23:00`)
                setEndValue(`00:00`)
                setOgEndValue(`00:00`)
                setShow(true);
                return;
            }
            if (item.id > 9) {
                setStartValue(`${item.hourLabel}:00`);
                setOgStartValue(`${item.hourLabel}:00`);
                setEndValue(`${parseInt(item.hourLabel) + 1}:00`)
                setOgEndValue(`${parseInt(item.hourLabel) + 1}:00`)
            } else if (item.id == 9) {
                setStartValue("09:00");
                setOgStartValue("09:00");
                setEndValue("10:00");
                setOgEndValue("10:00");
            }
            else {
                setStartValue(`${item.hourLabel}:00`)
                setOgStartValue(`${item.hourLabel}:00`)
                setEndValue(`0${parseInt(item.id) + 1}:00`)
                setOgEndValue(`0${parseInt(item.id) + 1}:00`)
            }
            setShow(true);
        }
    }

    const handleClose = () => {
        setShow(false);
        setStartValue("");
        setEndValue("");
        setSeverity("");
        setWorkflowSel("");
        setIsUpdating(false);
        setIsUpdatingDiv([]);
    }

    const startTimeHandler = (e) => {
        let hour = e.target.value.split(':')[0]
        e.target.value = `${hour}:00`
        setStartValue(e.target.value);
    }

    const endTimeHandler = (e) => {
        let hour = e.target.value.split(':')[0]
        e.target.value = `${hour}:00`
        setEndValue(e.target.value);
    }

    const saveChanges = () => {
        if (severity == "" || severity == "0") {
            ErrorToast("Please select a severity!")
            return;
        }
        if (workflowSel == "" || workflowSel == "0") {
            ErrorToast("Please select a workflow!")
            return;
        }
        let baC = "6fa8dc"
        if (severity == "1") {
            baC = "#DD2021";
        } else if (severity == "2") {
            baC = "#D06841";
        } else if (severity == "3") {
            baC = "#DAA925";
        } else if (severity == "4") {
            baC = "#83BB2B"
        } else {
            baC = "#83BB2B"
        }
        let startString = parseInt(startValue.slice(0, 2));
        let endString = parseInt(endValue.slice(0, 2));
        if (startString > endString && endString != 0) {
            WarningToast("Invalid Time Selection")
            return;
        }

        //Updating Logic Start
        if (isUpdating) {
            if ((ogStartValue < startValue || (ogEndValue > endValue && endValue != "00:00")) || (ogEndValue == "00:00" && endValue <= "23:00" && endValue != "00:00")) {
                let ogStartString = parseInt(ogStartValue.slice(0, 2));
                let ogEndString = parseInt(ogEndValue.slice(0, 2));
                let arr = days;
                if (ogStartValue < startValue) {
                    for (let i = ogStartString; i < startString; i++) {
                        arr[i].selected = false;
                        arr[i].backgroundColor = "none";
                        arr[i].severity = "";
                        arr[i].workflow = ""
                        arr[i].severityName = "";
                        arr[i].workflowName = ""
                    }
                }
                if (ogEndValue > endValue) {
                    for (let i = endString; i < ogEndString; i++) {
                        arr[i].selected = false;
                        arr[i].backgroundColor = "none";
                        arr[i].severity = "";
                        arr[i].workflow = ""
                        arr[i].severityName = "";
                        arr[i].workflowName = ""
                    }
                }

                if (ogEndValue == "00:00" && endValue <= "23:00") {
                    for (let i = endString; i <= 23; i++) {
                        arr[i].selected = false;
                        arr[i].backgroundColor = "none";
                        arr[i].severity = "";
                        arr[i].workflow = ""
                        arr[i].severityName = "";
                        arr[i].workflowName = ""
                    }
                }

                if (endString == 0) {
                    let tempEndS = 23;
                    for (let i = startString; i <= tempEndS; i++) {
                        arr[i].selected = true;
                        arr[i].backgroundColor = baC;
                        arr[i].severity = severity;
                        arr[i].workflow = workflowSel
                        arr[i].severityName = severityName;
                        arr[i].workflowName = workflowName
                    }
                    let c = counting + 1;
                    setCounting(c);
                    setDays(arr);
                    setShow(false);
                    setIsUpdatingDiv([]);
                    setIsUpdating(false);
                    setSeverity("");
                    setWorkflowSel("");
                    return;
                } else {
                    for (let i = startString; i < endString; i++) {
                        arr[i].selected = true;
                        arr[i].backgroundColor = baC;
                        arr[i].severity = severity;
                        arr[i].workflow = workflowSel
                        arr[i].severityName = severityName;
                        arr[i].workflowName = workflowName
                    }
                    let c = counting + 1;
                    setCounting(c);
                    setDays(arr);
                    setShow(false);
                    setIsUpdatingDiv([]);
                    setIsUpdating(false);
                    setSeverity("");
                    setWorkflowSel("");
                    return;
                }
            }
            if (startValue == "23:00" && endValue == "00:00") {
                let arr = days;
                arr[arr.length - 1].selected = true;
                arr[arr.length - 1].backgroundColor = baC;
                arr[arr.length - 1].severity = severity;
                arr[arr.length - 1].severityName = severityName;
                arr[arr.length - 1].workflowName = workflowName
                let c = counting + 1;
                setCounting(c);
                setDays(arr);
                setShow(false);
                setIsUpdatingDiv([]);
                setIsUpdating(false);
                setSeverity("");
                setWorkflowSel("");
                return;
                // }
            }
            let tempArr = days;
            if (endValue == "00:00") {
                let tempEndString = 23;
                for (let j = startString; j <= tempEndString; j++) {
                    let isStartPresent = tempArr.some((item) => item.id == j && item.selected);
                    if (isStartPresent && !isUpdatingDiv.includes(j)) {
                        ErrorToast("The event is overlapping");
                        setIsUpdatingDiv([]);
                        setIsUpdating(false);
                        setShow(false);
                        return;
                    }
                }

            } else {
                for (let j = startString; j < endString; j++) {
                    let isStartPresent = tempArr.some((item) => item.id == j && item.selected);
                    if (isStartPresent && !isUpdatingDiv.includes(j)) {
                        ErrorToast("The event is overlapping");
                        setIsUpdatingDiv([]);
                        setIsUpdating(false);
                        setShow(false);
                        return;
                    }
                }
            }
            //05
            let arr = days;
            if (endString == 0) {
                let tempEndS = 23;
                for (let i = startString; i <= tempEndS; i++) {
                    arr[i].selected = true;
                    arr[i].backgroundColor = baC;
                    arr[i].severity = severity;
                    arr[i].workflow = workflowSel
                    arr[i].severityName = severityName;
                    arr[i].workflowName = workflowName
                }
                let c = counting + 1;
                setCounting(c);
                setDays(arr);
                setShow(false);
                setIsUpdatingDiv([]);
                setIsUpdating(false);
                setSeverity("");
                setWorkflowSel("");
                return;
            } else {
                for (let i = startString; i < endString; i++) {
                    arr[i].selected = true;
                    arr[i].backgroundColor = baC;
                    arr[i].severity = severity;
                    arr[i].workflow = workflowSel
                    arr[i].severityName = severityName;
                    arr[i].workflowName = workflowName
                }
                let c = counting + 1;
                setCounting(c);
                setDays(arr);
                setShow(false);
                setIsUpdatingDiv([]);
                setIsUpdating(false);
                setSeverity("");
                setWorkflowSel("");
                return;
            }
        }
        //Updating Logic End

        // Creating Logic Start
        if (startValue == "23:00") {
            if (days[23].selected) {
                ErrorToast("The event is overlapping");
                setShow(false);
                return;
            } else {
                let arr = days;
                arr[arr.length - 1].selected = true;
                arr[arr.length - 1].backgroundColor = baC;
                arr[arr.length - 1].severity = severity;
                arr[arr.length - 1].workflow = workflowSel
                arr[arr.length - 1].severityName = severityName;
                arr[arr.length - 1].workflowName = workflowName
                let c = counting + 1;
                setCounting(c);
                setDays(arr);
                setShow(false);
                setSeverity("");
                setWorkflowSel("");
                return;
            }
        }

        let tempArr = days;
        if (endValue == "00:00") {
            let tempEndString = 23;
            for (let j = startString; j <= tempEndString; j++) {
                let isStartPresent = tempArr.some((item) => item.id == j && item.selected);
                if (isStartPresent) {
                    ErrorToast("The event is overlapping");
                    setShow(false);
                    return;
                }
            }

        } else {
            for (let j = startString; j < endString; j++) {
                let isStartPresent = tempArr.some((item) => item.id == j && item.selected);
                if (isStartPresent) {
                    ErrorToast("The event is overlapping");
                    setShow(false);
                    return;
                }
            }
        }
        //05
        let arr = days;

        if (endString == 0) {
            let tempEndS = 23;
            for (let i = startString; i <= tempEndS; i++) {
                arr[i].selected = true;
                arr[i].backgroundColor = baC;
                arr[i].severity = severity;
                arr[i].workflow = workflowSel
                arr[i].severityName = severityName;
                arr[i].workflowName = workflowName
            }
            let c = counting + 1;
            setCounting(c);
            setDays(arr);
            setShow(false);
            setSeverity("");
            setWorkflowSel("");
        } else {
            for (let i = startString; i < endString; i++) {
                arr[i].selected = true;
                arr[i].backgroundColor = baC;
                arr[i].severity = severity;
                arr[i].workflow = workflowSel
                arr[i].severityName = severityName;
                arr[i].workflowName = workflowName
            }
            let c = counting + 1;
            setCounting(c);
            setDays(arr);
            setShow(false);
            setSeverity("");
            setWorkflowSel("");
        }
        // Creating Logic End
    }

    const updateMaster = () => {
        let data = generateJson();
        let arr = sevenDaysData;
        let sampleArr = arr.map((item) => {
            let obj;
            if (item.day == resource) {
                obj = {
                    ...item,
                    dayResource: data
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })
        setSevenDaysData(sampleArr);
    }

    const generateJson = () => {
        let arr = [];
        for (let i = 0; i < days.length; i++) {
            let obj = {}
            let startTime = ""
            let endTime = ""
            let extraEndtime = ""
            let severity = ""
            let workflow = ""
            if (days[i].selected) {
                startTime = days[i].hourLabelFull;
                severity = days[i].severity
                workflow = days[i].workflow;
                for (let j = i; j < days.length; j++) {
                    extraEndtime = days[j].hourLabelFull;
                    if (days[j].selected && severity == days[j].severity && workflow == days[j].workflow) {
                        endTime = days[j].hourLabelFull;
                        continue;
                    } else {
                        i = j - 1;
                        break;
                    }
                }
                obj = {
                    monitoringStartTime: startTime,
                    monitoringEndTime: endTime == "23:00" ? "00:00" : extraEndtime,
                    severityId: severity,
                    workflowId: workflow
                }
                arr.push(obj);
                if (endTime == "23:00") {
                    return arr;
                }
            }
        }
        return arr;
    }

    const clearChanges = () => {
        let startString = parseInt(startValue.slice(0, 2));
        let endString = parseInt(endValue.slice(0, 2));
        if (startString > endString && endString != 0) {
            WarningToast("Invalid Time Selection")
            return;
        }
        let arr = days;
        let tempEndString = endString;
        if (endString == 0) {
            tempEndString = 24;
        }
        for (let i = startString; i < tempEndString; i++) {
            arr[i].selected = false;
            arr[i].backgroundColor = "none";
            arr[i].severity = "";
            arr[i].workflow = ""
            arr[i].severityName = "";
            arr[i].workflowName = ""
        }
        let c = counting + 1;
        setCounting(c);
        setDays(arr);
        setShow(false);
        setIsUpdating(false);
        setIsUpdatingDiv([]);
        setSeverity("");
        setWorkflowSel("");
    }

    //#endregion functions end

    return (
        <div key={countTwo} className='ScheduleBGDiv'>
            <div className='SliderDiv'>
                {
                    days.map((item) => (
                        <OverlayTrigger
                            key={item.id}
                            placement='top'
                            overlay={
                                item.selected ?
                                    (
                                        <Tooltip>
                                            {
                                                <div>
                                                    <span>Severity: {severities?.find((i) => i.SeverityId == item.severity).SeverityTitle}</span>
                                                    <br />
                                                    <span>Workflow: {workflows?.find((i) => i.workflowID == item.workflow).workflowName}</span>
                                                </div>
                                            }
                                        </Tooltip>
                                    )
                                    :
                                    (
                                        <span></span>
                                    )
                            }
                        >
                            <div
                                draggable={true}
                                id={`Slide_${item.id}`} key={item.id} className='TimeDiv' style={{ backgroundColor: item.backgroundColor, cursor: 'pointer' }}
                                onClick={(e) => {
                                    if (!isAlertTemplateEdit || (!item.selected && isStore)) {
                                        return;
                                    } else {
                                        handleShow(e, item)
                                    }
                                }}
                            >
                            </div>
                        </OverlayTrigger>
                    ))
                }
            </div>

            <div>
                <ul className='CustomTime'>
                    {
                        days.map((item) => (
                            <li key={item.id}>{item.hourLabel}</li>
                        ))
                    }
                </ul>
            </div>
            <Modal show={show} onHide={handleClose} size="lg" centered backdrop="static" className='ModelTimeline'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className='TimerSelectionDiv' style={{ marginRight: "18px" }}>
                            <p className="SiteNameText mb-1">From Time</p>
                            <input className="MessageTextBox1" disabled={disableDates} value={startValue} onChange={(e) => startTimeHandler(e)} type="time" step={3600} />
                        </div>
                        <div className='TimerSelectionDiv'>
                            <p className="SiteNameText mb-1">To Time</p>
                            <input className="MessageTextBox1" disabled={disableDates} value={endValue} onChange={(e) => endTimeHandler(e)} type="time" step={3600} />
                        </div>
                        <div className="NotificationTextBox">
                            <p className="SiteNameText mb-1">Severity</p>
                            <select className="MessageTextBox1" placeholder="Select Severity"
                                value={severity}
                                onChange={(e) => {
                                    setSeverity(e.target.value)
                                    setSeverityName(e.target.name)
                                }}  >
                                <option key="0" value="0">
                                    Select Severity
                                </option>
                                {
                                    severities?.map((item) => (
                                        <option key={item.SeverityId} value={item.SeverityId} name={item.SeverityTitle}>
                                            {item.SeverityTitle}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="NotificationTextBox">
                            <p className="SiteNameText mb-1">Workflow Name</p>
                            <select className="MessageTextBox1" placeholder="Select Workflow Name"
                                value={workflowSel}
                                onChange={(e) => {
                                    setWorkflowSel(e.target.value)
                                    setWorkflowName(e.target.options[e.target.selectedIndex].text)
                                }}  >
                                <option key="0" value="0">
                                    Select Workflow Name
                                </option>
                                {
                                    workflows?.map((item) => (
                                        <option key={item.workflowID} name={item.workflowName} value={item.workflowID}>
                                            {item.workflowName}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </Form>

                    <button className="DataAccessEdit mt-1" style={{ width: "460px", height: "40px" }} onClick={saveChanges}>
                        <span className="ViewUpdate ml-1" style={{ fontSize: "0.975rem" }}>Apply</span>
                    </button>
                    {
                        isUpdating && !isStore
                            ? <button className="DataAccessEdit mt-1" style={{ width: "100px", height: "40px" }} onClick={clearChanges}>
                                <span className="ViewUpdate ml-1" style={{ fontSize: "0.975rem" }}>Clear</span>
                            </button>
                            : null
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
})

export default CustomScheduler