import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AlertGenericImg, AlertGenericImg1 } from '../../assets/images'

export default function AlertGrid({ id, alert, onAddAlertChat, handleChange }) {
    let history = useHistory()
    /* region useState start */
    const [isLoading, setIsLoading] = useState(false)
    /* region useState start */
    
    return (
        <div className={`GridMain ${alert.severity?.toLowerCase()}-bb`}>
            {/* <img src={cognyfloLogo} /> */}
            
            <div className='GridBgImg'>
             <div className='GridAlertTypeImage'>
             {alert.snapshotURL ?  <img src={alert.snapshotURL} alt='snapshot image'
                //  onClick={()=>handleChange(true,alert.snapshotURL)} 
                onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=AlertGenericImg1;
            }}
             />: <AlertGenericImg/>}
             </div>
             <div className='AlertTypeText'>
             <p className="OperateBy MB2" style={{color: "white"}}>Type:
                    <span className="OperateName ml-1" style={{color: "white"}}>{alert.alertType}</span>
                </p>
             </div>
            </div>
            <div className="TitlesOfGrid">
            
                <p className="TitleGrid">ID:  {id}</p>
                <p className="OperateBy MB2">Site:
                    <span className="OperateName ml-1">{alert.siteName}</span>
                </p>
                <p className="OperateBy MB2">Customer:
                    <span className="OperateName ml-1">{alert.customerName}</span>
                </p>
                
                <p className="OperateBy ReportTableFontBold MB2">Device:
                    <span className="OperateName ml-1">{alert.deviceName}</span>
                </p>
                <p className="OperateBy MB2">ACK By:
                    <span className="OperateName ml-1">{alert.ackbyName? alert.ackbyName : "--"}</span>
                </p>
                
                <p className="OperateBy ReportTableFontBold MB2">Date & Time:
                    <span className="OperateName ml-1">{alert.reqActualTime} {alert.abbreviation? "("+alert.abbreviation+")" : ""}</span>
                </p>
                <p className="OperateBy MB5">Status:
                    <span className="OperateName ml-1">{alert.status}</span>
                </p>
                <div className="ButtonsDivAlert">
                    <button className="AlertViewButton m-1" onClick={(e) => { 
                        // AddMixpanelEvent(id)
                        history.push(`/alertdetails/${id}`)
                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); 
                    }}><p>View Alert</p></button>

                    {
                        (alert.status != "Open")
                            ?
                            <button className="SelectionAlertViewButton m-1" onClick={() => { 
                                // AddMixpanelEvent(id)
                                history.push(`/alertreport/${id}`) 
                            }}>
                                <p>Report</p>
                            </button>
                            :
                            <button
                                disabled={isLoading}
                                className="SelectionAlertViewButton m-1"
                                onClick={(e) => {
                                    // AddMixpanelEvent(id)
                                    setIsLoading(true)
                                    // e.target.setAttribute("disabled", true);
                                    onAddAlertChat(id).then((res) => {
                                        setIsLoading(false)

                                    })
                                }}>
                                <p>Chatflow</p>
                            </button>
                    }
                </div>
            </div>
        </div>
    )
}
