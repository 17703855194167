import React from 'react'
import { useHistory } from 'react-router-dom'
import InlineEditItem from '../../../../components/InlineEditListItem/InlineEditItem';
import { CONFIG_ALERTTEMPLATE } from '../../../../navigation/CONSTANTS';

const AddSiteGroup = ({
    siteGroups,
    onFinish,
    sitesToBind,
    selectedSiteGroup,
    onSiteGroupSelection,
    selectedSites,
    setSelectedSites,
    onAddSelectedSites,
    onDeleteSiteGroup,
    onAddNewSiteGroup,
    onUpdateSiteGroup,
}) => {

    let history = useHistory();

    const handleChange = (index) => (event) => {
        const selSite = sitesToBind.find((x) => x.SiteID == event.target.id);
        if (selSite) {
            if (event.target.checked == true) {
                selSite.isSelected = true;
            } else {
                selSite.isSelected = false;
            }
        }
        setSelectedSites({
            ...selectedSites,
            [event.target.id]: event.target.checked,
        });
    };

    return (
        <>
            <div className="LeftAddSide">
                <div className="LeftAddSideHeight">
                    <div className="ManageSite ManageSiteGroup-container  ">
                        <h1 className="MainHeaderName mb-0">1. Manage Site Group</h1>
                        <div className="ManageSiteDiv">
                            <div className="DevicesHeader">
                                <p className="mb-0" style={{ width: "40%" }}>
                                    Site Group
                                    <br />
                                </p>
                                <p className="mb-0">
                                    Site List
                                    <br />
                                </p>
                            </div>

                            <div className="site-group">
                                <div className="site-group-list">
                                    {siteGroups &&
                                        siteGroups.map((item) => {
                                            return (
                                                <InlineEditItem
                                                    key={item.siteGroupID}
                                                    name={item.groupTitle}
                                                    selectedItem={item}
                                                    id={item.siteGroupID}
                                                    selectedItemId={selectedSiteGroup?.siteGroupID}
                                                    onClick={() => onSiteGroupSelection(item)}
                                                    onSave={onUpdateSiteGroup}
                                                    onDelete={onDeleteSiteGroup}
                                                />
                                            );
                                        })}
                                </div>
                                <div className="AddMore" style={{ float: "left" }}>
                                    {/*   <a style={{ cursor: "not-allowed", color: "gray", pointerEvents: "revert" }}
                      title="Not Permitted"
                      href="#"
                      className="UserAddPlus mt-1"
                      onClick={() => onAddNewSiteGroup()}
                    >
                      Create Site Group +
                    </a> */}
                                </div>
                            </div>
                            <div
                                className="SiteList site-list-container"
                                style={{ borderBottom: "1px solid #e6e6e6" }}
                            >
                                <div className="sitegrp-sitelist">
                                    <form style={{ height: "100%" }}>
                                        {sitesToBind ?
                                            sitesToBind.map((value, index, array) => {
                                                return (
                                                    <div className="selection-list-group cameraList"
                                                        key={value.SiteID}>
                                                        <input
                                                            id={value.SiteID}
                                                            className="checkbox_custom"
                                                            type="checkbox" disabled
                                                            key={value.SiteID}
                                                            onChange={handleChange(index)}
                                                            name={value.SiteName}
                                                            defaultChecked={
                                                                value.isSelected == true ? true : false
                                                            }
                                                        />
                                                        <p className="ListofText">{value.SiteName}</p>
                                                    </div>
                                                );
                                            }) : <div className='NoFRMsg'>
                                            No Sites added to this site group.
                                        </div>
                                        }
                                    </form>
                                </div>
                            </div>

                            <div className="text-right" style={{ textAlign: "end" }}>
                                <button
                                    className="NextMoveButton ManageSiteGroup-NextButton"
                                    // onClick={() => onFinish("alertTemplate")}
                                    onClick={() => history.push(CONFIG_ALERTTEMPLATE)}
                                >
                                    <span className="ViewUpdate">Next</span>
                                </button>
                                <button
                                    className="NextMoveButton ManageSiteGroup-AddSelectedButton" disabled
                                    title="Not Permitted"
                                    onClick={() => onAddSelectedSites()}
                                >
                                    <span className="ViewUpdate">Add Selected</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddSiteGroup