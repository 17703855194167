import React, { useEffect, useState } from 'react'

import { CustomContactRemove } from '../../assets/images';

const CustomContactForm = ({ item, isNotifFormEnabled, onDeleteClick, setEnableAdd, mainCustomerList, setMainCustomerList, customContact, setCustomContact }) => {

    //region useState start
    const [name, setName] = useState(item.Name);
    const [designation, setDesignation] = useState(item.Designation);
    const [mobileNo, setMobileNo] = useState(item.ContactNumber);
    const [email, setEmail] = useState(item.ContactEmail);
    //region useState end

    //region useEffect start
    useEffect(() => {
        if (name === "" || mobileNo === "" || email === "") {
            setEnableAdd(true);
        } else {
            setEnableAdd(false);
        }
    }, [name, mobileNo, email]);

    useEffect(() => {
        setName(item.Name);
        setDesignation(item.Designation);
        setMobileNo(item.ContactNumber);
        setEmail(item.ContactEmail);
    }, [item]);

    useEffect(() => {
        let arr = mainCustomerList;
        let objIndex = arr.findIndex(obj => obj.Id == item.Id);
        arr[objIndex].Name = name;
        setMainCustomerList(arr);
        setCustomContact(arr);
    }, [name])

    useEffect(() => {
        let arr = mainCustomerList;
        let objIndex = arr.findIndex(obj => obj.Id == item.Id);
        arr[objIndex].Designation = designation;
        setMainCustomerList(arr);
        setCustomContact(arr);
    }, [designation])

    useEffect(() => {
        let arr = mainCustomerList;
        let objIndex = arr.findIndex(obj => obj.Id == item.Id);
        arr[objIndex].ContactNumber = mobileNo;
        setMainCustomerList(arr);
        setCustomContact(arr);
    }, [mobileNo])

    useEffect(() => {
        let arr = mainCustomerList;
        let objIndex = arr.findIndex(obj => obj.Id == item.Id);
        arr[objIndex].ContactEmail = email;
        setMainCustomerList(arr);
        setCustomContact(arr);
    }, [email])

    //region useEffect end

    const nameRegex = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isValid = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(keyValue);
        if (!isValid) {
            event.preventDefault();
            return;
        }
    };

    const numberRegex = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isValid = new RegExp("[0-9]").test(keyValue);
        if (!isValid || event.target.value.length >= 10) {
            event.preventDefault();
            return;
        }
    };

    //Keeping this code for future reference
    const emailRegex = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        // let emailRegexPattern = /^[a-zA-Z0-9\\-]+(\.[a-zA-Z0-9\\-]+)*@[a-zA-Z0-9\\-]+(\.[a-zA-Z0-9\\-]+)*$/;
        // let emailRegexPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const isValid = new RegExp("/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}/").test(keyValue);
        if (!isValid) {
            event.preventDefault();
            return;
        }
    };

    const mobileHandler = (e) => {
        setMobileNo(e.target.value);
    }

    const emailHandler = (e) => {
        setEmail(e.target.value);
    }

    const nameHandler = (e) => {
        setName(e.target.value);
    }

    return (
        <>
            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                <div className="grid-container Notificationgrid-container grid-container--fit customContact" style={{ marginBottom: "0px" }}>
                    <div className="NotificationTextBox">
                        <p className="SiteNameText">Contact Name</p>
                        <input
                            type='text'
                            className="MessageTextBox1"
                            placeholder="Enter Name here"
                            disabled={!isNotifFormEnabled}
                            value={name}
                            onChange={(e) => nameHandler(e)}
                            onKeyPress={nameRegex}
                        />
                    </div>
                    <div className="NotificationTextBox">
                        <p className="SiteNameText">
                            Position/Designation <sup>(Optional)</sup>
                        </p>
                        <input
                            type='text'
                            className="MessageTextBox1"
                            placeholder="Enter Position/Designation"
                            disabled={!isNotifFormEnabled}
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                            onKeyPress={nameRegex}
                        />
                    </div>
                    <div className="NotificationTextBox">
                        <p className="SiteNameText">Contact Number</p>
                        <input
                            type='number'
                            className="MessageTextBox1"
                            placeholder="Enter contact number"
                            disabled={!isNotifFormEnabled}
                            value={mobileNo}
                            onChange={(e) => mobileHandler(e)}
                            onKeyPress={numberRegex}
                            maxLength={10}
                            minLength={10}
                        />
                    </div>
                    <div className="NotificationTextBox">
                        <p className="SiteNameText">Contact Email</p>
                        <input
                            type='email'
                            className="MessageTextBox1"
                            placeholder="Enter contact email"
                            disabled={!isNotifFormEnabled}
                            value={email}
                            onChange={(e) => emailHandler(e)}
                            required
                        // onKeyPress={emailRegex}
                        />
                    </div>
                </div>
                <div>
                    <button
                        type='button'
                        className="deleteCustomContact"
                        disabled={!isNotifFormEnabled}
                        onClick={(e) => {
                            onDeleteClick(e, item.Id);
                        }}
                    >
                        <span className="ViewUpdate">
                            <img src={CustomContactRemove} alt='remove icon' />
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default CustomContactForm