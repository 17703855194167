import React from 'react'

export default function Player() {
    return (
        <div>
            <video type="application/x-vlc-plugin" pluginspage="http://www.videolan.org"
                target="rtsp://admin:123456@192.168.0.127:554/Streamings/Channels/1"
                id="vlc" />
            <object 
                classid="clsid:9BE31822-FDAD-461B-AD51-BE1D1C159921" 
                codebase="http://download.videolan.org/pub/videolan/vlc/last/win32/axvlc.cab" 
                style="display:none;">
                
            </object>
        </div>
    )
}
