import React from 'react'

const OperatorAlertList = ({ item }) => {
    return (
        <tr>
            <td>{item.FullName}</td>
            <td>{item.AcknowledgedCount}</td>
            <td>{item.ClosedCount}</td>
            <td>{item.TotalCount}</td>
        </tr>
    )
}

export default OperatorAlertList
