import React, { useEffect, useState,useContext } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import AlertsContainer from './AlertsContainer';
import {Context} from '../../Context/AlertContext'
import AlertMainContainer from './AlertMainContainer';

export default function AllAlerts(props) {
    // Alert list
    const [extremeAlertLst, setExtremeAlertLst] = useState(0)
    const [highAlertLst, setHighAlertLst] = useState(0)
    const [moderateAlertLst, setmoderateAlertLst] = useState(0)
    const [lowAlertsLst, setLowAlertsLst] = useState(0)

    let LoggedInUser = JSON.parse(localStorage.getItem('user'))

    // const [allAlerts, setAllAlerts] = useState(0)
    const [allAlerts, setAllAlerts] = useContext(Context)
    // useEffect(async () => {
    //     if (props.alertList && props.alertList.length > 0) {
    //         Filterlist(props.alertList)
    //     }
    // }, [props.alertList])

    useEffect(() => {
        (async () => {
       
            if (props.alertList && props.alertList.length > 0) {
                Filterlist(props.alertList)
            }
       
         })();
       
         }, [props.alertList])


    useEffect(() => {
        (async () => {
            try{
                if(allAlerts.length > props.alertcounts.TotalOpen){
                    props.ResetAlertCounts()
                }
            }catch(error){
                console.error(error)
            }
         })();
       
         }, [allAlerts])

    function Filterlist(lst){
        var newAlertsList = lst.filter(x => x.closedBy == 0)
            if (newAlertsList) {
                var extremeAlerts = filterAlertList(newAlertsList, "Extreme")
                if (extremeAlerts) {
                    setExtremeAlertLst(extremeAlerts)
                }
                var highAlerts = filterAlertList(newAlertsList, "High")
                if (highAlerts) {
                    setHighAlertLst(highAlerts)
                }
                var moderateAlerts = filterAlertList(newAlertsList, "Moderate")
                if (moderateAlerts) {
                    setmoderateAlertLst(moderateAlerts)
                }
                var lowAlerts = filterAlertList(newAlertsList, "Low")
                if (lowAlerts) {
                    setLowAlertsLst(lowAlerts)
                }

                setAllAlerts(newAlertsList)
            }
    }

    function filterAlertList(alertList, severity) {
        var newList = alertList.filter(x => x.severity == severity)
        return newList
    }

    function SetNewList(lst,selectedSeverityTab){
        try{
            if(selectedSeverityTab == "all"){
                setAllAlerts(lst)
            }else if(selectedSeverityTab == "extreme"){
                setExtremeAlertLst(lst)
            }else if(selectedSeverityTab == "high"){
                setHighAlertLst(lst)
            }else if(selectedSeverityTab == "moderate"){
                setmoderateAlertLst(lst)
            }else if(selectedSeverityTab == "low"){
                setLowAlertsLst(lst)
            }
        }catch(error){
            console.error(error)
        }
    }

    return (
        <div>
            {/* <AlertsContainer
                extremeAlertLst={extremeAlertLst}
                highAlertLst={highAlertLst}
                moderateAlertLst={moderateAlertLst}
                lowAlertsLst={lowAlertsLst}
                allAlerts={allAlerts}
                alertSearch={props.alertSearch}
                setAlertSearch={props.setAlertSearch}
                activeSeverity={props.activeSeverity}
                setActiveSeverity={props.setActiveSeverity}
                // extremeAlertCount={props.alertcounts.Extreme}
                extremeAlertCount={props && props.alertcounts && props.alertcounts.Extreme !== undefined ?(props.alertcounts.Extreme): 0}
                // highAlertCount={props.alertcounts.High}
                highAlertCount={props && props.alertcounts && props.alertcounts.High !== undefined ?(props.alertcounts.High): 0}
                // moderateAlertCount={props.alertcounts.Moderate}
                moderateAlertCount={props && props.alertcounts && props.alertcounts.Moderate !== undefined ?(props.alertcounts.Moderate): 0}
                // lowAlertCount={props.alertcounts.Low}
                lowAlertCount={props && props.alertcounts && props.alertcounts.Low !== undefined ?(props.alertcounts.Low): 0}
                // AllAlertCount={props.alertcounts.TotalOpen}
                AllAlertCount={props && props.alertcounts && props.alertcounts.TotalOpen !== undefined ?(props.alertcounts.TotalOpen): 0}
                onAddAlertChat={props.onAddAlertChat}
                // onAddAlertChat={props && props.alertcounts && props.alertcounts.onAddAlertChat !== undefined ?(props.alertcounts.onAddAlertChat): 0}
                SetNewList={SetNewList}
                selectedSubMenu={props.selectedSubMenu}
                alertChildWindow={props.alertChildWindow}
                setAlertChildWindow={props.setAlertChildWindow}
                /> */}

            <AlertMainContainer 
            selectedSubMenu={props.selectedSubMenu}
            childWindow={props.childWindow}
            setChildWindow={props.setChildWindow}
            isDarkTheme={props.isDarkTheme}
            onAddAlertChat={props.onAddAlertChat}
            alertChildWindow={props.alertChildWindow}
            setAlertChildWindow={props.setAlertChildWindow}
            />
        </div>
    )
}
