
//#region lib
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker, DatePicker } from "rsuite";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Popup from 'reactjs-popup';
import PopupCard from './PopupCard';
import { addMinutes, subMinutes, differenceInMinutes } from 'date-fns';

//#endregion lib

//#region react in built
import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
//#endregion react in built


//#region  images
import { MosaicIcon, PlayBackWhite, NewSearchIcon, LiveView, calendar, DateTimeArrow } from "../../assets/images"

//#endregion images

//#region API's
import { Getdeviceslistdata } from "../../services/configurationServices";
import { getMultipleDevicePlaybackDetails } from "../../services/cameraService"
import { getstreamingurl } from "../../services/chatFlowService"; //get Single device playback url by rover
//#endregionAPI's

//#region components
import CameraTileComponent from './CameraTileComponent'
import Pagination from '../../components/Pagination/Pagination'
import { ConvertDate, ConvertDateTwo, frontDateConverter, multiTimeZoneHelper } from '../../utils/utils'
import { WarningToast } from '../../CustomHooks/WarningToast'
import { ErrorToast } from '../../CustomHooks/ErrorToast'
import SingleCamPlaybackComponent from "./SingleCamPlaybackComponent";
import NoCamerasPresent from "./NoCamerasPresent";
import { CLOUD_STREAMING, IS_WEBRTC, MULTI_TIME_ZONE } from "../../config";
import { MOSAIC } from "../../navigation/CONSTANTS";
//#endregion components

//Rupali test
export default function CameraViewPage({ childWindow,
    setChildWindow }) {
    const popupRef = useRef();
    const searchRef = useRef(null)

    //#region useState start
    const [cameraMasterList, setCameraMasterList] = useState([])
    const [filteredCameraList, setFilteredCameraList] = useState([])
    const [cameraUI, setCameraUI] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(12)
    const [totalCount, setTotalCount] = useState(0)
    const [isSearch, setIsSearch] = useState(false)
    const [selectedTimeZone, setSelectedTimeZone] = useState(null);
    const [offSetMintues, setOffSetMinutes] = useState(330);
    //#endregion Live streaming states

    //#region Playback states 
    const [isPlaybackBtnView, setIsPlaybackBtnView] = useState(true)
    const [isCheckBoxVisible, setIsCheckBoxVisible] = useState(false)
    const [multiPlaybackList, setMultiPlaybackList] = useState([])
    const [isDatePickerAccessAllowed, setIsDatePickerAccessAllowed] = useState(false)
    const [isMultiPlay, setIsMultiPlay] = useState(false)
    const [dateString, setDateString] = useState('');
    const [timeString, setTimeString] = useState("");
    const [date, setDate] = useState(new Date());
    const [deviceplayIDList, setdeviceplayIDList] = useState([]);
    const [blankDate, setBlankDate] = useState("");
    const [isLoading, setIsLoading] = useState(true)
    const [isChecked, setIsChecked] = useState(false)
    const [startDateStringMultiplay, setStartDateStringMultiplay] = useState("");
    const [endDateStringMultiplay, setEndDateStringMultiplay] = useState("");
    const [isSingleDevPlaybackBtnClick, setIsSingleDevPlaybackBtnClick] = useState(false)
    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)
    const [isSingleDevPlaybackView, setIsSingleDevPlaybackView] = useState(false)
    const [selectedSingleDeviceForPlayback, setSelectedSingleDeviceForPlayback] = useState([])
    const [isSearchResult, setIsSearchResult] = useState(true)
    const [exportStartTime, setExportStartTime] = useState("");
    const [exportEndTime, setExportEndTime] = useState("");
    const [startDateString, setStartDateString] = useState("");
    const [endDateString, setEndDateString] = useState("");


    //#endregion Playback states
    const { afterToday, allowedMaxDays, combine } = DateRangePicker;
    const [counter, setCounter] = useState(0);
    const [timeStringMain, setTimeStringMain] = useState("+15 min");
    const [differenceTime, setDifferenceTime] = useState(15);
    //#region  use effect 
    useEffect(() => {
        getDeviceCollection() // get master dev list from db
    }, []);

    // Same Page Persists after Reload
    useEffect(() => {
        const savedPageState = window.sessionStorage.getItem("Page_Refresh_State");
        if (savedPageState) {
            setCurrentPage(JSON.parse(savedPageState));
        }
    }, []);

    // Save page state on change
    useEffect(() => {
        window.sessionStorage.setItem("Page_Refresh_State", JSON.stringify(currentPage));
    }, [currentPage]);
    // Page Persists ends

    //   Search no results found
    useEffect(() => {
        if (totalCount === 0 || totalCount === undefined) {
            setIsSearchResult(false)
        } else {
            setIsSearchResult(true)
        }
    }, [totalCount])

    useEffect(() => {
        let d
        let e
        if(MULTI_TIME_ZONE){
            d = multiTimeZoneHelper(offSetMintues)
            e = subMinutes(d, 15);
            setDate(e);
        }else{
            d = new Date();
            e = subMinutes(d, 15);
            setDate(e)
        }
        // d = subMinutes(d, 15);
        let temp = ConvertDate(e);
        let temp_date = temp.slice(0, 10).replace(/-/g, '_');
        setDateString(temp_date);
        // let timeStringMain = "";
        // if (timeString === "") {
        //   let blankArr = blankDate.split("_");
        //   for (let i = 0; i < blankArr.length; i++) {
        //     if (blankArr[i].length == 1) {
        //       blankArr[i] = "0" + blankArr[i].toString();
        //     }
        //   }
        //   let blankStr = blankArr.join("_");
        //   timeStringMain = blankStr;
        // } else {
        //   timeStringMain = timeString
        // }
        // if (timeStringMain.split('_')[2].toString().length == 1) {
        //   timeStringMain += "0";
        // }
        // let tempTwo = temp_date + '_' + timeStringMain;
        // let arr = tempTwo.split("_");
        // let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        // let s = ConvertDate(SD);
        // setStartDateString(s);
      }, [offSetMintues, selectedTimeZone]);

    //#endregion use effect 
    const dateChange = (v, e) => {
        let d = ConvertDate(v);
        let temp = d.slice(0, 10).replace(/-/g, '_');
        setDateString(temp);
        setDate(v);
    }
    const getDataFromChild = (data) => {
        let timeStringMain = "";

        if (data === "") {
            let blankArr = blankDate.split("_");
            for (let i = 0; i < blankArr.length; i++) {
                if (blankArr[i].length == 1) {
                    blankArr[i] = "0" + blankArr[i].toString();
                }
            }
            let blankStr = blankArr.join("_");
            timeStringMain = blankStr;
        } else {
            timeStringMain = data;
        }
        let temp = dateString + '_' + timeStringMain;
        let arr = temp.split("_");
        // ['2023', '04', '14', '10', '47', '24']
        let FD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        let currentTime = new Date();
        const difference = differenceInMinutes(currentTime, FD);
        if (difference < 15 && difference > 0) {
            WarningToast("Selected Time is less than 15 min !")
            timeStringMain = difference + " min ";
            setDifferenceTime(parseInt(difference))
            setTimeStringMain(timeStringMain);
        } else {
            setDifferenceTime(15);
            setTimeStringMain("15 min");
        }
        setTimeString(data);
    }

    const singleDevPlaybackClick = useCallback((dev) => {
        setIsPlaybackBtnView(false)
        setSelectedSingleDeviceForPlayback(dev)
    }, [isSingleDevPlaybackBtnClick]);

    //#region API calls
    const getDeviceCollection = () => {
        try {
            Getdeviceslistdata().then((resp) => {
                // For Showing only cameras we re filtering this out 1- camera and 2 - ids panel
                let cameraList = resp;
                if (cameraList && cameraList.length > 0) {
                    setCameraMasterList(cameraList);
                    setFilteredCameraList(cameraList)
                    setCameraUI(cameraList)
                    setTotalCount(cameraList && cameraList.length)
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const ShowErrorToast = (msg) => {
        toast.error(msg, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };
    //#endregion API Calls

    //** currentTableData is used for the list to be shown on the selected page
    const currentTableData = useMemo(() => {

        let firstPageIndex = (currentPage - 1) * pageSize;
        let lastPageIndex = firstPageIndex + pageSize;
        if (isSearch == false) {

            let pageList = cameraMasterList?.slice(firstPageIndex, lastPageIndex);
            setCameraUI(pageList)
            return pageList
        }
        else {
            if (filteredCameraList && filteredCameraList.length <= pageSize) {
                firstPageIndex = 0
                lastPageIndex = firstPageIndex + pageSize;
                setCurrentPage(1)
            }

            let pageList = filteredCameraList?.slice(firstPageIndex, lastPageIndex);
            setCameraUI(pageList)
            return pageList
        }


    }, [currentPage, cameraMasterList, pageSize, filteredCameraList, isSearch, isPlaybackBtnView]);

    function onSearchChange(searchTxt) {
        var searchText = searchTxt

        if (searchText) {
            setIsSearch(true)
            var filteredLstBySearch = cameraMasterList.filter(x => (x.CameraName && x.CameraName.toLowerCase().includes(searchText.toLowerCase())) ||
                (x.Location && x.Location.toLowerCase().includes(searchText.toLowerCase())) ||
                (x.SiteName && x.SiteName.toLowerCase().includes(searchText.toLowerCase())))
            setFilteredCameraList(filteredLstBySearch)
            setTotalCount(filteredLstBySearch && filteredLstBySearch.length)
        }
        else {
            setIsSearch(false)
            setTotalCount(cameraMasterList && cameraMasterList.length)

        }
        setCurrentPage(1)
    }

    const onSyncReplayHandleClick = (e) => {
        setIsPlaybackBtnView(false)
        setIsDatePickerVisible(true)
        setIsCheckBoxVisible(true)
        setIsMultiPlay(false)

        if (isSearch === true) {
            onSearchChange('')
            searchRef.current.value = '';
        }
        else {
            setCurrentPage(1)
        }
    }
    const onLiveHandleClick = (e) => {
        setIsPlaybackBtnView(true)
        setIsCheckBoxVisible(false)
        setIsSingleDevPlaybackBtnClick(false)
        setIsDatePickerVisible(false) // only get true on sync replay and single dev playback icon click
        setIsSingleDevPlaybackView(false) // to hide datepicker on live view

        if (cameraMasterList && cameraMasterList.length > 0) {
            const selectedCameraList = cameraMasterList.filter(x => x.isChecked == true)
            if (selectedCameraList && selectedCameraList.length > 0) {
                for (let i = 0; i < selectedCameraList.length; i++) {
                    const dev = selectedCameraList[i];
                    dev.isChecked = false

                }
                setCameraUI(cameraMasterList)
            }
        }
        setIsMultiPlay(false)
        if (isSearch === true) {
            onSearchChange('')
            searchRef.current.value = '';
        }
        else {
            setCurrentPage(1)
        }

    }
    const onPlayback = () => {
        setIsLoading(true);
        popupRef.current.close()
        let timeStringMain = "";
        if (timeString === "") {
            let blankArr = blankDate.split("_");
            for (let i = 0; i < blankArr.length; i++) {
                if (blankArr[i].length == 1) {
                    blankArr[i] = "0" + blankArr[i].toString();
                }
            }
            let blankStr = blankArr.join("_");
            timeStringMain = blankStr;
        } else {
            timeStringMain = timeString
        }
        if (timeStringMain.split('_')[2].toString().length == 1) {
            timeStringMain += "0";
        }
        let temp = dateString + '_' + timeStringMain;
        let arr = temp.split("_");
        let FD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        setDate(FD);
        let currentTime = new Date();
        if(MULTI_TIME_ZONE){
            currentTime = multiTimeZoneHelper(offSetMintues)
        }
        if (FD > currentTime) {
            ShowErrorToast('The selected time is  greater from the current time!')
            setIsLoading(false);
            return;
        }
        let fromDateTime = ConvertDate(FD);
        fromDateTime = fromDateTime.slice(0, -2) + "00";

        setStartDateStringMultiplay(fromDateTime);
        let TD = addMinutes(FD, differenceTime);
        let toDateTime = ConvertDate(TD);
        toDateTime = toDateTime.slice(0, -2) + "00";
        setEndDateStringMultiplay(toDateTime);


        const selectedDeviceList = cameraMasterList.filter(x => x.isChecked === true)

        if (selectedDeviceList && selectedDeviceList.length > 0) {
            const deviceplayIDList = []
            for (let i = 0; i < selectedDeviceList.length; i++) {
                const device = selectedDeviceList[i];
                deviceplayIDList.push(device['ParentCameraID'])
            }
            if (IS_WEBRTC == "1") {
                let tempselectedDeviceList = selectedDeviceList;
                let devToUpdate;
                let rtspUrl = [];
                setExportStartTime(fromDateTime.replace(/[ :-]/g, '_'));
                setExportEndTime(toDateTime.replace(/[ :-]/g, '_'));
                for (let i = 0; i < deviceplayIDList.length; i++) {
                    let obj = {
                        "action": "StartPlaybackUrl",
                        "startTime": fromDateTime.replace(/[ :-]/g, '_'),
                        "endTime": toDateTime.replace(/[ :-]/g, '_'),
                        "deviceId": deviceplayIDList[i],
                        "alertId": ""
                    }
                    rtspUrl.push(obj);
                }
                Promise.all(
                    rtspUrl.map((item) => getstreamingurl(item))
                ).then((res) => {
                    for (let i = 0; i < res.length; i++) {
                        devToUpdate = tempselectedDeviceList.find(x => x.ParentCameraID == deviceplayIDList[i]);
                        devToUpdate.playbackURL = res[i].rtspURL;
                    }
                    setMultiPlaybackList(tempselectedDeviceList);
                    setIsLoading(false)
                })
            } else {
                if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
                {
                    let startTime = fromDateTime.replace(/[ :-]/g, '_');
                    let endTime = toDateTime.replace(/[ :-]/g, '_');

                    let splitStartTime = startTime && startTime.split('_')
                    let splitEndTime = endTime && endTime.split('_')


                    for (let j = 0; j < deviceplayIDList.length; j++) {
                        const devToUpdatetemp = deviceplayIDList[j]
                        const devToUpdate = selectedDeviceList.find(x => x.ParentCameraID == devToUpdatetemp)
                        let rtspUrlPlayback = ""
                        //CamUserName, CamPassword, ChannelNum, CameraBrandId, UserID, CamStreamGuid, PrivateIPAddress, CamPort, DeviceId, CameraBrandId
                        //
                        let streamName = devToUpdate.camUserName + "_" + devToUpdate.StreamName + "_" + startTime + "_" + endTime
                        let startTimeNw = ""
                        let endTimeNw = ""
                        //rtspURL = ""
                        let channelno = devToUpdate.ChannelNum
                        // channelno = channelno(-1)
                        // channelno = channelno + "1"
                        // print(channelno)
                        if (devToUpdate.CameraBrandId == 1) { //hikvision
                            startTimeNw = splitStartTime[0] + splitStartTime[1] + splitStartTime[2] + "T" + splitStartTime[3] + splitStartTime[4] + splitStartTime[5]
                            endTimeNw = splitEndTime[0] + splitEndTime[1] + splitEndTime[2] + "T" + splitEndTime[3] + splitEndTime[4] + splitEndTime[5]
                        }
                        else if (devToUpdate.CameraBrandId == 2 || devToUpdate.CameraBrandId == 4) { //cp plus
                            startTimeNw = startTime
                            endTimeNw = endTime
                        }
                        else if (devToUpdate.CameraBrandId == 3 || devToUpdate.CameraBrandId == 5) { //Sparsh & //Dlink
                            startTimeNw = splitStartTime[0] + splitStartTime[1] + splitStartTime[2] + "_" + splitStartTime[3] + splitStartTime[4] + splitStartTime[5]
                            endTimeNw = splitEndTime[0] + splitEndTime[1] + splitEndTime[2] + "_" + splitEndTime[3] + splitEndTime[4] + splitEndTime[5]
                        }
                        //CamUserName, CamPassword, ChannelNum, CameraBrandId, UserID, CamStreamGuid, PrivateIPAddress, CamPort, DeviceId, CameraBrandId
                        if (devToUpdate.CameraBrandId == 1) {
                            rtspUrlPlayback = "rtsp://" + devToUpdate.camUserName + ":" + devToUpdate.camPassword + "@" + devToUpdate.privateIPAddress + ":" + devToUpdate.camPort + "/Streaming/tracks/" + devToUpdate.channelNum + "?starttime=" + startTimeNw + ".000&amp;endtime=" + endTimeNw + ".000"

                        }
                        else if (devToUpdate.CameraBrandId == 2 || devToUpdate.CameraBrandId == 4) {
                            rtspUrlPlayback = "rtsp://" + devToUpdate.camUserName + ":" + devToUpdate.camPassword + "@" + devToUpdate.privateIPAddress + ":" + devToUpdate.camPort + "/cam/playback?channel=" + devToUpdate.channelNum + "&starttime=" + startTimeNw + "&endtime=" + endTimeNw
                        }
                        else if (devToUpdate.CameraBrandId == 3) {
                            rtspUrlPlayback = "rtsp://" + devToUpdate.privateIPAddress + ":" + devToUpdate.camPort + "/user=" + devToUpdate.camUserName + "&password=" + devToUpdate.camPassword + "&channel=" + devToUpdate.channelNum + "&stream=rec0&starttime=" + startTimeNw + "&endtime=" + endTimeNw + ".sdp"
                        }
                        else if (devToUpdate.CameraBrandId == 5) {
                            // rtsp=> rtsp://192.168.200.162:554/user=admin&password=&channel=1&stream=rec0&starttime=20231106_092600&endtime=20231006_093740.sdp
                            // rtspUrlPlayback = "rtsp://" + devToUpdate.privateIPAddress + ":" + devToUpdate.camPort + "/user=" + devToUpdate.camUserName + "&password=" + devToUpdate.camPassword + "&channel=" + devToUpdate.channelNum + "&stream=rec0&starttime=" + startTimeNw + "&endtime=" + endTimeNw + ".sdp"
                            rtspUrlPlayback = "rtsp://" + devToUpdate.privateIPAddress + ":" + devToUpdate.camPort + "/user=" + devToUpdate.camUserName + "&password=" + devToUpdate.camPassword + "&channel=" + devToUpdate.channelNum + "&stream=rec0&starttime=" + startTimeNw + "&endtime=" + endTimeNw + ".sdp"
                        }
                        //let baseUrl = "http://localhost:8080"
                        let baseUrl = "http://localhost:3000"
                        let rtspurl = rtspUrlPlayback
                        //get cam guid
                        let temp_date = ConvertDateTwo(new Date())
                        let camguid = devToUpdate.StreamName + temp_date
                        //do post req to base url
                        let actbaseUrl = baseUrl + "/start"
                        let IsTrancoding = false
                        if (devToUpdate.isTranscoded == 1)
                            IsTrancoding = true

                        fetch(actbaseUrl, {
                            method: 'POST',
                            body: JSON.stringify({
                                uri: rtspurl,
                                alias: camguid,
                                isencode: IsTrancoding
                            }),
                            headers: {
                                'Content-type': 'application/json; charset=UTF-8',
                            },
                        }).then((res) => res.json())
                            .then((data) => {
                                baseUrl = baseUrl + data['uri']
                                // 
                                devToUpdate.playbackURL = baseUrl
                                //item.
                            })
                            .catch((err) => {
                                console.log("check data error", err.message);
                            });
                        //url as rtspurl and alis as camguid
                    }
                    setMultiPlaybackList(selectedDeviceList)
                    setIsLoading(false)
                }
                else {
                    setdeviceplayIDList(deviceplayIDList)
                    var devObj = {
                        action: "getMultipleDevPlaybackList",
                        userUniqueId: "",
                        startTime: fromDateTime.replace(/[ :-]/g, '_'),
                        endTime: toDateTime.replace(/[ :-]/g, '_'),
                        deviceIdLst: deviceplayIDList,
                    };
                    getMultipleDevicePlaybackDetails(devObj).then((resp) => {
                        if (resp && resp.status == "Ok") {
                            if (resp.message) {
                                for (let j = 0; j < resp.message.length; j++) {
                                    const dev = resp.message[j];
                                    const devToUpdate = selectedDeviceList.find(x => x.ParentCameraID == dev.deviceID)
                                    if (dev && dev != "") {

                                        devToUpdate.playbackURL = dev["playbackURL"]
                                    }
                                }
                                setMultiPlaybackList(selectedDeviceList)
                                setIsLoading(false)
                            }
                            else {
                                ErrorToast('Something went wrong! Please try again later.')
                            }
                        }
                    });
                }
            }
        }
    }
    const onHandleDateTimeOkClick = (e) => {
        try {
            setIsLoading(true)
            let fromdate = new Date(e);
            let todate = new Date(e)
            todate = todate.setMinutes(todate.getMinutes() + 15);
            todate = new Date(todate)
            let fromDateTime = ConvertDate(fromdate)
            setStartDateStringMultiplay(fromDateTime);
            let toDateTime = ConvertDate(todate)
            setEndDateStringMultiplay(toDateTime);
            let currentTime = new Date();
            const difference = differenceInMinutes(currentTime, fromdate);
            if (difference < 15 && difference > 0) {
                todate = new Date();
                toDateTime = ConvertDate(todate)
                setEndDateStringMultiplay(toDateTime);
                WarningToast("Selected Time is less than 15 min !")
            }
            if (fromdate > currentTime || difference == 0) {
                ShowErrorToast('The selected time is greater than the current time!');
                return;
            }
            else {
                if (isSingleDevPlaybackBtnClick == false) {
                    setIsMultiPlay(true)
                    setIsDatePickerVisible(false)
                    let d = new Date();
                    let temp = ConvertDate(d);
                    let temp_date = temp.slice(0, 10).replace(/-/g, '_');
                    setDateString(temp_date);
                    if (fromDateTime && toDateTime) {
                        if (cameraMasterList) {
                            const selectedDeviceList = cameraMasterList.filter(x => x.isChecked === true)
                            if (selectedDeviceList && selectedDeviceList.length > 0) {
                                const deviceIDList = []
                                for (let i = 0; i < selectedDeviceList.length; i++) {
                                    const device = selectedDeviceList[i];
                                    deviceIDList.push(device['ParentCameraID'])
                                }
                                if (IS_WEBRTC == "1") {
                                    let tempselectedDeviceList = selectedDeviceList;
                                    let devToUpdate;
                                    let rtspUrl = [];
                                    setExportStartTime(fromDateTime.replace(/[ :-]/g, '_'));
                                    setExportEndTime(toDateTime.replace(/[ :-]/g, '_'));
                                    for (let i = 0; i < deviceIDList.length; i++) {
                                        let obj = {
                                            "action": "StartPlaybackUrl",
                                            "startTime": fromDateTime.replace(/[ :-]/g, '_'),
                                            "endTime": toDateTime.replace(/[ :-]/g, '_'),
                                            "deviceId": deviceIDList[i],
                                            "alertId": ""
                                        }
                                        rtspUrl.push(obj);
                                    }

                                    Promise.all(
                                        rtspUrl.map((item) => getstreamingurl(item))
                                    ).then((res) => {
                                        for (let i = 0; i < res.length; i++) {
                                            devToUpdate = tempselectedDeviceList.find(x => x.ParentCameraID == deviceIDList[i]);
                                            devToUpdate.playbackURL = res[i].rtspURL;
                                        }
                                        setMultiPlaybackList(tempselectedDeviceList);
                                        setIsLoading(false)
                                    })
                                } else {
                                    if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
                                    {
                                        let startTime = fromDateTime.replace(/[ :-]/g, '_');
                                        let endTime = toDateTime.replace(/[ :-]/g, '_');
                                        let splitStartTime = startTime && startTime.split('_')
                                        let splitEndTime = endTime && endTime.split('_')
                                        for (let j = 0; j < deviceIDList.length; j++) {
                                            const devToUpdatetemp = deviceIDList[j]
                                            const devToUpdate = selectedDeviceList.find(x => x.ParentCameraID == devToUpdatetemp)
                                            let rtspUrlPlayback = ""
                                            let startTimeNw = ""
                                            let endTimeNw = ""
                                            //rtspURL = ""
                                            if (devToUpdate.CameraBrandId == 1) { //hikvision
                                                startTimeNw = splitStartTime[0] + splitStartTime[1] + splitStartTime[2] + "T" + splitStartTime[3] + splitStartTime[4] + splitStartTime[5]
                                                endTimeNw = splitEndTime[0] + splitEndTime[1] + splitEndTime[2] + "T" + splitEndTime[3] + splitEndTime[4] + splitEndTime[5]
                                            }
                                            else if (devToUpdate.CameraBrandId == 2 || devToUpdate.CameraBrandId == 4) { //cp plus
                                                startTimeNw = startTime
                                                endTimeNw = endTime
                                            }
                                            else if (devToUpdate.CameraBrandId == 3 || devToUpdate.CameraBrandId == 5) { //Sparsh & Dlink
                                                startTimeNw = splitStartTime[0] + splitStartTime[1] + splitStartTime[2] + "_" + splitStartTime[3] + splitStartTime[4] + splitStartTime[5]
                                                endTimeNw = splitEndTime[0] + splitEndTime[1] + splitEndTime[2] + "_" + splitEndTime[3] + splitEndTime[4] + splitEndTime[5]
                                            }
                                            //CamUserName, CamPassword, ChannelNum, CameraBrandId, UserID, CamStreamGuid, PrivateIPAddress, CamPort, DeviceId, CameraBrandId
                                            if (devToUpdate.CameraBrandId == 1) {
                                                rtspUrlPlayback = "rtsp://" + devToUpdate.camUserName + ":" + devToUpdate.camPassword + "@" + devToUpdate.privateIPAddress + ":" + devToUpdate.camPort + "/Streaming/tracks/" + devToUpdate.channelNum + "?starttime=" + startTimeNw + ".000&amp;endtime=" + endTimeNw + ".000"
                                            }
                                            else if (devToUpdate.CameraBrandId == 2 || devToUpdate.CameraBrandId == 4) {
                                                rtspUrlPlayback = "rtsp://" + devToUpdate.camUserName + ":" + devToUpdate.camPassword + "@" + devToUpdate.privateIPAddress + ":" + devToUpdate.camPort + "/cam/playback?channel=" + devToUpdate.channelNum + "&starttime=" + startTimeNw + "&endtime=" + endTimeNw
                                            }
                                            else if (devToUpdate.CameraBrandId == 3) {
                                                rtspUrlPlayback = "rtsp://" + devToUpdate.privateIPAddress + ":" + devToUpdate.camPort + "/user=" + devToUpdate.camUserName + "&password=" + devToUpdate.camPassword + "&channel=" + devToUpdate.channelNum + "&stream=rec0&starttime=" + startTimeNw + "&endtime=" + endTimeNw + ".sdp"
                                            }
                                            else if (devToUpdate.CameraBrandId == 5) {
                                                rtspUrlPlayback = "rtsp://" + devToUpdate.privateIPAddress + ":" + devToUpdate.camPort + "/user=" + devToUpdate.camUserName + "&password=" + devToUpdate.camPassword + "&channel=" + devToUpdate.channelNum + "&stream=rec0&starttime=" + startTimeNw + "&endtime=" + endTimeNw + ".sdp"
                                            }
                                            let baseUrl = "http://localhost:3000"
                                            let rtspurl = rtspUrlPlayback
                                            let temp_date = ConvertDateTwo(new Date())
                                            let camguid = devToUpdate.StreamName + temp_date
                                            //do post req to base url
                                            let actbaseUrl = baseUrl + "/start"
                                            let IsTrancoding = false
                                            if (devToUpdate.isTranscoded == 1)
                                                IsTrancoding = true

                                            fetch(actbaseUrl, {
                                                method: 'POST',
                                                body: JSON.stringify({
                                                    uri: rtspurl,
                                                    alias: camguid,
                                                    isencode: IsTrancoding
                                                }),
                                                headers: {
                                                    'Content-type': 'application/json; charset=UTF-8',
                                                },
                                            }).then((res) => res.json())
                                                .then((data) => {
                                                    baseUrl = baseUrl + data['uri']
                                                    devToUpdate.playbackURL = baseUrl
                                                })
                                                .catch((err) => {
                                                    console.log("check data error", err.message);
                                                });
                                        }
                                        setMultiPlaybackList(selectedDeviceList)
                                        setIsLoading(false)
                                    }
                                    else {
                                        setdeviceplayIDList(deviceIDList)
                                        var devObj = {
                                            action: "getMultipleDevPlaybackList",
                                            userUniqueId: "",
                                            startTime: fromDateTime.replace(/[ :-]/g, '_'),
                                            endTime: toDateTime.replace(/[ :-]/g, '_'),
                                            deviceIdLst: deviceIDList,
                                        };
                                        getMultipleDevicePlaybackDetails(devObj).then((resp) => {
                                            if (resp && resp.status == "Ok") {
                                                if (resp.message) {
                                                    for (let j = 0; j < resp.message.length; j++) {
                                                        const dev = resp.message[j];
                                                        const devToUpdate = selectedDeviceList.find(x => x.ParentCameraID == dev.deviceID)
                                                        if (dev && dev != "") {
                                                            devToUpdate.playbackURL = dev["playbackURL"]
                                                        }
                                                    }
                                                    setMultiPlaybackList(selectedDeviceList)
                                                    setIsLoading(false)
                                                }
                                                else {
                                                    ErrorToast('Something went wrong! Please try again later.')
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                            else {
                                WarningToast('Please select at least two devices to get playback.')
                            }
                        }
                    }
                    else {
                        ErrorToast('Please select from date and to date!')
                    }
                }
                else {
                    setIsSingleDevPlaybackView(true)
                    if (selectedSingleDeviceForPlayback && selectedSingleDeviceForPlayback != null) {
                        var obj = {
                            "action": "StartPlaybackUrl",
                            "startTime": fromDateTime.replace(/[ :-]/g, '_'),
                            "endTime": toDateTime.replace(/[ :-]/g, '_'),
                            "deviceId": selectedSingleDeviceForPlayback['ParentCameraID'],
                            "alertId": ""
                        }
                        getstreamingurl(obj).then((resp) => {
                            if (resp) {
                                if (resp.status == "Ok") {
                                    selectedSingleDeviceForPlayback.playbackURL = resp.message
                                    setIsLoading(false)
                                }
                            }
                        })
                    }
                }
            }
        } catch (error) {
            console.error("onHandleDateTimeOkClick", error)
        }
    }
    const handleDatePickerOnClick = (e) => {

        if (isSingleDevPlaybackBtnClick == false) {
            const selDeviceList = cameraMasterList.filter(x => x.isChecked == true)
            if (selDeviceList && selDeviceList.length < 2) {
                WarningToast('Please select minimum two devices for sync replay!')
            }
            else {
                setIsDatePickerAccessAllowed(true)
            }
        }
        else {
            setIsDatePickerAccessAllowed(true)
        }
    }
    const newSubmitBtn = () => {
        if (isSingleDevPlaybackBtnClick == false) {
            const selDeviceList = cameraMasterList.filter(x => x.isChecked == true)
            if (selDeviceList && selDeviceList.length < 2) {
                WarningToast('Please select minimum two devices for sync replay!')
                return;
            }
            else {
                setIsDatePickerAccessAllowed(true)
            }

            // setLoading(true);
            popupRef.current.close()
            let timeStringMain = "";
            if (timeString === "") {
              let blankArr = blankDate.split("_");
              for (let i = 0; i < blankArr.length; i++) {
                if (blankArr[i].length == 1) {
                  blankArr[i] = "0" + blankArr[i].toString();
                }
              }
              let blankStr = blankArr.join("_");
              timeStringMain = blankStr;
            } else {
              timeStringMain = timeString
            }
            if (timeStringMain.split('_')[2].toString().length == 1) {
              timeStringMain += "0";
            }
            let temp = dateString + '_' + timeStringMain;
            temp = temp.slice(0, -2) + "00";
            let arr = temp.split("_");
            let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
            setDate(SD);
            setIsLoading(true)
            let a = ConvertDate(SD);
            setStartDateStringMultiplay(a);
            let currentTime = new Date();
            if(MULTI_TIME_ZONE){
                currentTime = multiTimeZoneHelper(offSetMintues)
            }
            if (differenceTime == 0) {
              ErrorToast('The selected time is  greater from the current time!')
              return;
            }
            if (SD > currentTime && differenceTime > 0) {
              ErrorToast('The selected time is  greater from the current time!')
            //   setLoading(false);
              return;
            }
            setStartDateString(a);
            let ED = addMinutes(SD, differenceTime);
            let toDateTime = ConvertDate(ED);
            setEndDateStringMultiplay(toDateTime);
            toDateTime = toDateTime.slice(0, -2) + "00";
            setIsMultiPlay(true)
            if (temp && toDateTime) {
                if (cameraMasterList) {
                    const selectedDeviceList = cameraMasterList.filter(x => x.isChecked === true)
                    if (selectedDeviceList && selectedDeviceList.length > 0) {
                        const deviceIDList = []
                        for (let i = 0; i < selectedDeviceList.length; i++) {
                            const device = selectedDeviceList[i];
                            deviceIDList.push(device['ParentCameraID'])
                        }
                        if (IS_WEBRTC == "1") {
                            let tempselectedDeviceList = selectedDeviceList;
                            let devToUpdate;
                            let rtspUrl = [];
                            // setExportStartTime(fromDateTime.replace(/[ :-]/g, '_'));
                            // setExportEndTime(toDateTime.replace(/[ :-]/g, '_'));
                            for (let i = 0; i < deviceIDList.length; i++) {
                                let obj = {
                                    "action": "StartPlaybackUrl",
                                    "startTime": temp,
                                    "endTime": toDateTime.replace(/[ :-]/g, '_'),
                                    "deviceId": deviceIDList[i],
                                    "alertId": ""
                                }
                                rtspUrl.push(obj);
                            }

                            Promise.all(
                                rtspUrl.map((item) => getstreamingurl(item))
                            ).then((res) => {
                                for (let i = 0; i < res.length; i++) {
                                    devToUpdate = tempselectedDeviceList.find(x => x.ParentCameraID == deviceIDList[i]);
                                    devToUpdate.playbackURL = res[i].rtspURL;
                                }
                                setMultiPlaybackList(tempselectedDeviceList);
                                setIsLoading(false)
                                setIsDatePickerVisible(false)

                            })
                        }
                    }
                }
            
        }
        }
    }
    // let myWindow;
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    let windowFeatures = `menubar=no, fullscreen=yes, location=no, toolbar=no, scrollbars=yes, resizable=yes, status=no, width=${windowWidth}}, height=${windowHeight}`

    function openChildWindow() {
        let newChildWindow
        if (childWindow == null || childWindow.name == "") {
            newChildWindow = window.open(MOSAIC, "mosaicWindow", "_blank", windowFeatures)
            setChildWindow(newChildWindow);
            sessionStorage.setItem("mosaicWindowKey", "mosaicWindow")
        } else {
            childWindow.focus()
        }
    }
    useEffect(() => {
        // Listen for the beforeunload event in the parent window
        const beforeUnloadListener = () => {
            if (childWindow) {
                sessionStorage.removeItem("mosaicWindowKey")
                childWindow.close();
            }
        };

        window.addEventListener('beforeunload', beforeUnloadListener);
        return () => {
            // Cleanup: Remove the event listener when the parent component unmounts
            window.removeEventListener('beforeunload', beforeUnloadListener);
        };

    }, [childWindow]);

    useEffect(() => {
        let intervalId
        if (childWindow) {
            intervalId = setInterval(() => {
                setCounter(prevCounter => prevCounter + 1);
                if (sessionStorage.getItem('mosaicWindowKey') == null || sessionStorage.getItem('mosaicWindowKey') === undefined || sessionStorage.getItem('mosaicWindowKey') === '') {
                    childWindow.close();
                }
            }, 100); // 100 milliseconds (0.1 seconds)
        }

        return () => {
            clearInterval(intervalId); // Clean up the interval on unmount
        }
    }, [childWindow]);

    return (
        <div className="MainContentMargin">
            <div className="row m-0 p-0">
                <div className="col-lg-12 dashboardHeadingInnerDiv">
                    <div className="cameramainheader">
                        <h4>
                            Cameras {isSingleDevPlaybackView == true ? `(1)` : isMultiPlay == true ? cameraMasterList &&
                                `(${cameraMasterList.filter(x => x.isChecked == true).length})` : `(${totalCount})`}

                        </h4>
                        {isMultiPlay == true || isSingleDevPlaybackView == true ? <></> :
                            <div className="ml-3">
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalCount}
                                    pageSize={pageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                    pageName='camera'
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        }
                    </div>
                    <div className="rightcameradiv">
                        {isSingleDevPlaybackView == true || isMultiPlay == true ? <></> :
                            <div className="cam-search mt-0">
                                <>
                                    <span class="changeColor">
                                        <NewSearchIcon
                                            onClick={() => {
                                                if (!isSearch)
                                                    ErrorToast('Please enter the name of the camera, location, or site.')
                                            }}
                                        />
                                    </span>
                                    <input className="SearchViewInput" type="search" ref={searchRef} onChange={e => onSearchChange(e.target.value)} placeholder="Search" />
                                </>
                            </div>
                        }
                        <div className="mosaicdiv">
                            {(isPlaybackBtnView && isPlaybackBtnView == true) || isMultiPlay == true ?
                                <button className="playbackbuttonNewUI" onClick={() => { onSyncReplayHandleClick() }}>
                                    <img src={PlayBackWhite} className="playbackicon" alt="playback icon" />
                                    <p className="playbackbuttonButtonText">Sync Replay</p>
                                </button> :
                                <button className="playbackbuttonNewUI" onClick={() => { onLiveHandleClick() }}>
                                    <img src={LiveView} className="playbackicon" alt="playback icon" />
                                    <p className="playbackbuttonButtonText">Live View</p>
                                </button>
                            }
                        </div>
                        <div className="">
                            <button
                                className="mosaicbuttonNewUI"
                                onClick={() => {
                                    openChildWindow()
                                }}
                            >
                                <img className="playbackicon" src={MosaicIcon} alt="mosaic icon" />
                                <p className="playbackbuttonButtonText">
                                    Mosaic
                                </p>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="HeightScrollCameraGrid">
                    {
                        isDatePickerVisible && !isSingleDevPlaybackView ?
                            <div className="col-lg-12 pr-1 mt-2">
                                {/* <div className="playbackcalendersection">

                                    <div className="calenderdiv1">
                                        <DatePicker
                                            readOnly={isDatePickerAccessAllowed && isDatePickerAccessAllowed == true ? false : true}
                                            onClick={(e) => { handleDatePickerOnClick(e) }}
                                            format="dd-MM-yyyy HH:mm:ss"
                                            disabledDate={combine(allowedMaxDays(180), afterToday())} // 180 days confirmed with SL sir
                                            ranges={[
                                                {
                                                    value: new Date()
                                                }
                                            ]}
                                            style={{ width: 260 }}
                                            onOk={(e) => { onHandleDateTimeOkClick(e) }}
                                        />
                                    </div>
                                </div> */}

                                <div className='playback__datepickers'>
                                        <form className='singleInputDiv'>
                                            <input value={""}
                                                className='singleInput'
                                                placeholder="Select playback start time"
                                            />
                                            <Popup
                                                ref={popupRef}
                                                onOpen={() => { }}
                                                trigger={<img src={calendar} alt="calender" style={{ cursor: "pointer" }} />}
                                            >
                                                <PopupCard dateChange={dateChange} date={date} getDataFromChild={getDataFromChild} onSubmitBtn={newSubmitBtn} setBlankDate={setBlankDate} timeStringMain={timeStringMain} mode="Playback" />
                                            </Popup>
                                        </form>
                                        {/* <img src={DateTimeArrow} alt='DateTimeArrow' />
                                        <p id='endDateStringSinglePlayback'>{frontDateConverter(endDateString)}</p> */}
                                    </div>
                            </div> :
                            <></>
                    }

                    {
                        cameraUI && isMultiPlay &&
                        <div className="col-lg-12 pr-1 mt-2 mb-2">
                            <div className='playback__datepickers'>
                                <form className='singleInputDiv'>
                                    <input value={frontDateConverter(startDateStringMultiplay)}
                                        className='singleInput'
                                    />
                                    <Popup
                                        ref={popupRef}
                                        trigger={
                                            <img src={calendar} alt="calender" style={{ cursor: "pointer" }} />
                                        }
                                    >
                                        <PopupCard dateChange={dateChange} date={date} getDataFromChild={getDataFromChild}
                                            onSubmitBtn={onPlayback}
                                            setBlankDate={setBlankDate} timeStringMain={timeStringMain} mode="Playback" />
                                    </Popup>
                                </form>
                                <img src={DateTimeArrow} alt='DateTimeArrow' />
                                <p id='endDateStringSinglePlayback'>{frontDateConverter(endDateStringMultiplay)}</p>
                            </div>
                        </div>
                    }
                    <div className="col-lg-12 pr-1">
                        {isSearchResult ? (
                            isSingleDevPlaybackView == false ?
                                (<div className={isMultiPlay ? "camera-list" : "camera-list"} >
                                    {
                                        isPlaybackBtnView && isPlaybackBtnView == true ?
                                            // live view
                                            (cameraUI &&
                                                (<CameraTileComponent
                                                    cameraUI={cameraUI}
                                                    isCheckBoxVisible={isCheckBoxVisible}
                                                    setIsSingleDevPlaybackBtnClick={setIsSingleDevPlaybackBtnClick}
                                                    isSingleDevPlaybackBtnClick={isSingleDevPlaybackBtnClick}
                                                    singleDevPlaybackClick={singleDevPlaybackClick}
                                                    setIsDatePickerVisible={setIsDatePickerVisible}
                                                    selectedTimeZone={selectedTimeZone}
                                                    setSelectedTimeZone={setSelectedTimeZone}
                                                    offSetMintues={offSetMintues}
                                                    setOffSetMinutes={setOffSetMinutes}
                                                />))
                                            :
                                            //Playback view    
                                            (cameraUI && isMultiPlay == false ?
                                                (<CameraTileComponent
                                                    cameraUI={cameraUI}
                                                    isCheckBoxVisible={isCheckBoxVisible}
                                                    setIsDatePickerAccessAllowed={setIsDatePickerAccessAllowed}
                                                    cameraMasterList={cameraMasterList}
                                                    isChecked={isChecked}
                                                    setIsChecked={setIsChecked}
                                                    selectedTimeZone={selectedTimeZone}
                                                    setSelectedTimeZone={setSelectedTimeZone}
                                                    offSetMintues={offSetMintues}
                                                    setOffSetMinutes={setOffSetMinutes}
                                                />)
                                                :
                                                (<CameraTileComponent
                                                    cameraUI={multiPlaybackList}
                                                    isMultiPlay={isMultiPlay}
                                                    isLoading={isLoading}
                                                    exportStartTime={exportStartTime}
                                                    exportEndTime={exportEndTime}
                                                    selectedTimeZone={selectedTimeZone}
                                                    setSelectedTimeZone={setSelectedTimeZone}
                                                    offSetMintues={offSetMintues}
                                                    setOffSetMinutes={setOffSetMinutes}
                                                />))

                                    }
                                </div>)
                                : (<SingleCamPlaybackComponent
                                    singleDevPlaybackClick={singleDevPlaybackClick}
                                    selectedSingleDeviceForPlayback={selectedSingleDeviceForPlayback}
                                    isLoading={isLoading}
                                />
                                )
                        )
                            :
                            (<div>
                                <NoCamerasPresent />
                            </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}