import { BASE_CORE_URL, BASE_URL } from './../config/index'

//#region to get the dashboard details
export const GetDashboardDetails = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "getdashboarddetails";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getdashboarddetails() Exception: ", error);
    });
}
//#endregion 

//#region to get the system health device count 
export const SystemHealthDevicesCountDash = async (userUniqueID) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  }
  let url = BASE_CORE_URL + "Dashboard/SystemHealth?userUniqueId=" + userUniqueID;
  return await fetch(url, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error(" SystemHealth () Exception", error);
    });
}
//#endregion

//#region - to get the  data of dashboard Side bar Customurl list (Test,etc.,)
export const MenuMasterListAPI = async () => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken'),
      },
    };
    let url = BASE_CORE_URL + "Dashboard/GetCustomURLList";

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("MenuMasterListAPI() Exception:", error);
    return null; // or handle the error as needed
  }
};
//#endregion