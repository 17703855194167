import React, { useEffect, useState } from 'react'
import FilterTool from './FilterTool';
import { DatePicker, DateRangePicker } from 'rsuite';
import Skeleton from 'react-loading-skeleton';
import { Pagination } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { ConvertDate, multiTimeZoneHelper } from '../../utils/utils';
import { MULTI_TIME_ZONE } from '../../config';
import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination';
import { differenceInDays, subDays, subMonths } from 'date-fns';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import Swal from 'sweetalert2';
import { SearchIcon, SearchIconWhite } from '../../assets/images';
import { getOperatorAlertCountExport, getOperatorAlertReport } from '../../services/reportService';
import OperatorAlertList from './OperatorAlertList';


const TODAY = "TD";
const YESTERDAY = "YD";
const SEVENDAY = "7D";
const ONEMONTH = "1M";
const NONE = "NONE";

const OperatorWiseAlertReport = (props) => {
    const [activeTab, setActiveTab] = useState(TODAY)
    const [fromDate, setFromDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [inputDate, setInputDate] = useState([]);
    const [displayStartDate, setDisplayStartDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [searchString, setSearchString] = useState("");
    const [siteIds, setSiteIds] = useState("");
    const [customerIds, setCustomerIds] = useState("");
    const [count, setCount] = useState(0);
    const [resetClicked, setResetClicked] = useState(false);
    const [initialDate, setInitialDate] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [totalDevices, setTotalDevices] = useState(0);
    const [data, setData] = useState([]);
    const [fullNameSearch, setFullNameSearch] = useState("");
    const [acknowledgedCountSearch, setAcknowledgedCountSearch] = useState("");
    const [closedCountSearch, setClosedCountSearch] = useState("");
    const [totalCountSearch, setTotalCountSearch] = useState("");
    const [abbreviation, setAbbreviation] = useState("");
    const [typingStart, setTypingStart] = useState(false);
    const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);
    //useEffect Block Start

    useEffect(() => {
        if (resetClicked) {
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            getOperatorAlertReportData(loggedInUser.UserUniqueID, fromDate, endDate, 1, searchString, customerIds, siteIds);
            setCurrentPageNo(1)
            setResetClicked(false);
        }
    }, [resetClicked])

     useEffect(() => {
        if (siteIds != "" || customerIds != "") { 
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            getOperatorAlertReportData(loggedInUser.UserUniqueID, fromDate, endDate, 1, searchString, customerIds, siteIds);
            setCurrentPageNo(1)
        }
    }, [siteIds, customerIds])

    useEffect(() => {
        if (fromDate === "" || endDate === "") {
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            let start = getFirstDate();
            let end = getSecondDate();
            let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
            setFromDate(startDate);
            let endCurrDate = ConvertDate(end);
            setEndDate(endCurrDate)
            displayDateSet(startDate, endCurrDate);
            getOperatorAlertReportData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString, customerIds, siteIds);
            setAbbreviation(loggedInUser.Abbreviation)
        } else {
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            getOperatorAlertReportData(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo, searchString, customerIds, siteIds);
            setAbbreviation(loggedInUser.Abbreviation)
        }
        let tempDate = getFirstDate();
        console.log("TempDate", tempDate)
        let c = count + 1;
        setCount(c);
        setInitialDate([subMonths(tempDate, 1), tempDate]);

    }, [currentPageNo]);

    useEffect(() => {
        if (fullNameSearch.length > 0) {
            setTypingStart(true);
        }
        if (fullNameSearch === "" && typingStart) {
            searchListDetails()
        }
    }, [fullNameSearch])

    //useEffect Block End

    const getOperatorAlertReportData = (uniqueId, startDate, endDate, pageNumber, searchString, customerIds, siteIds) => {
        try {
            setTableLoading(true);
            getOperatorAlertReport(uniqueId, startDate, endDate, pageNumber, searchString, customerIds, siteIds)
                .then((res) => {
                    if (res.Status == "success") {
                        setData(res.Data.ReportOperatorAlertCountModels);
                        setTotalDevices(parseInt(res.Data.Total));
                        setTotalPages(parseInt(res.Data.Total));
                        setTableLoading(false);
                    } else {
                        setData([]);
                        setTotalDevices(0);
                        setTotalPages(0);
                        setTableLoading(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setTableLoading(false);
                })
        } catch (error) {

        }
    }

    const displayDateSet = (startDate, endCurrDate) => {
        let one = startDate.split(" ");
        let tempOne = `${one[0].split("-")[2]}-${one[0].split("-")[1]}-${one[0].split("-")[0]}`;
        setDisplayStartDate(`${tempOne} ${one[1]}`);
        let two = endCurrDate.split(" ");
        let tempTwo = `${two[0].split("-")[2]}-${two[0].split("-")[1]}-${two[0].split("-")[0]}`;
        setDisplayEndDate(`${tempTwo} ${two[1]}`);
    }

    const getFirstDate = () => {
        let d;
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        if (MULTI_TIME_ZONE) {
            d = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
        } else {
            d = new Date();
        }
        return d;
    }

    const getSecondDate = () => {
        let e;
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        if (MULTI_TIME_ZONE) {
            e = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
        } else {
            e = new Date();
        }
        return e;
    }

    const today = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSet(startDate, endCurrDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        getOperatorAlertReportData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
        setCurrentPageNo(1)
        setActiveTab(TODAY)
    }
    const yesterday = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 1));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(subDays(new Date(end.setHours(24, 0, 0, 0)), 1));
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSet(startDate, endCurrDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        getOperatorAlertReportData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
        setCurrentPageNo(1)
        setActiveTab(YESTERDAY);
    }
    const sevenDay = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 6));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSet(startDate, endCurrDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        getOperatorAlertReportData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
        setCurrentPageNo(1)
        setActiveTab(SEVENDAY)
    }
    const oneMonth = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subMonths(new Date(start.setHours(0, 0, 0, 0)), 1));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSet(startDate, endCurrDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        getOperatorAlertReportData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
        setCurrentPageNo(1)
        setActiveTab(ONEMONTH);
    }


    const onHandleEventDateTimeOkClick = (e) => {
        const [startDate, endDate] = [e[0], e[1]];
        if (startDate && endDate) {
            const daysDifference = differenceInDays(endDate, startDate);
            if (daysDifference > 30) {
                ErrorToast('Maximum Date range cannot exceeded 30 days.');
                return;
            }
        }
        let sDate = ConvertDate(e[0]);
        setFromDate(sDate);
        let eDate = ConvertDate(e[1]);
        setEndDate(eDate);
        setInputDate([e[0], e[1]])
        displayDateSet(sDate, eDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        getOperatorAlertReportData(loggedInUser.UserUniqueID, sDate, eDate, 1, searchString, customerIds, siteIds);
        setCurrentPageNo(1)
        setActiveTab(NONE);
    }


    const convertToCSV = (objArray) => {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','

                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    const exportEventHandler = () => {
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        getOperatorAlertCountExport(loggedInUser.UserUniqueID, fromDate, endDate, 1, searchString, customerIds, siteIds)
            .then((res) => {
                if (res.Status == "success") {
                    let stemp = res.Data;
                    if (res.Data?.length == 0) {
                        Swal.fire({ text: 'No records available to export.' })
                        return
                    }
                    let header = {
                        OperatorName: "Operator Name",
                        AcknowledgedCount: "Acknowledged Count",
                        ClosedCount: "Closed Count",
                        TotalCount: "Total Count",
                    }
                    stemp.unshift(header);
                    console.log("stemp", stemp)
                    let jsonObject = JSON.stringify(stemp);
                    let csv = convertToCSV(jsonObject);
                    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    let link = document.createElement("a");
                    let fileN = "Operator Alert Report.csv"
                    if (link.download !== undefined) {
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", fileN);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
                else{
                    Swal.fire({ text: "No records available to export." });
                    return;
                  }
            })
            .catch(err => {
                console.log(err);
            })
    }


    const PaginationComponent = (currentPageNo) => {
        let numbers = [];
        if (maxPageAllowed - 2 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 2}
                    active={maxPageAllowed - 2 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
                >
                    {maxPageAllowed - 2}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else if (maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else {
            for (let i = currentPageNo; i < currentPageNo + 3; i++) {
                if (i <= maxPageAllowed) {
                    numbers.push(
                        <Pagination.Item
                            key={i}
                            active={i == currentPageNo}
                            onClick={() => setCurrentPageNo(i)}
                        >
                            {i}
                        </Pagination.Item>
                    )
                }
            }
        }
        return numbers;
    }

    const getSearchDataReports = (searchText) => {
        try {
            setTableLoading(true);
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            getOperatorAlertReport(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo, searchText, customerIds, siteIds)
                .then((res) => {
                    if (res.Status == "success") {
                        setData(res.Data.ReportOperatorAlertCountModels);
                        setTotalDevices(parseInt(res.Data.Total));
                        setTotalPages(parseInt(res.Data.Total));
                        setCurrentPageNo(1)
                        setPageCounter(1)
                        setTableLoading(false);
                    }
                    else {
                        setData([]);
                        setTotalDevices(0);
                        setTotalPages(0);
                        setCurrentPageNo(1)
                        setPageCounter(1)
                        setTableLoading(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        } catch (error) {

        }
    }

    const searchListDetails = (data, type) => {
        let obj = {
            "operatorname": fullNameSearch
        }
        let stringText = JSON.stringify(obj);
        let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";")
        setSearchString(tempStringText);
        getSearchDataReports(tempStringText);
    }

    return (
        <div className='MainContentMargin'>
            <div className='col-lg-12 HMHeader mt-3 mb-3'>
                <div className="NameHeading1" style={{ width: "100%" }}>
                    <p className="config-tool-head" style={{ float: "left" }}>
                        Reports &gt; {`Operator Alert Report`}
                    </p>
                    <div style={{ display: "flex", alignItems: "flex-start", float: "right" }}>
                        {/* <CustomerTool /> */}
                        <FilterTool isDarkTheme={props.isDarkTheme} setSiteIds={setSiteIds} setCustomerIds={setCustomerIds} setResetClicked={setResetClicked} />
                        <div style={{ display: "grid", gridAutoFlow: "column", gridGap: "10px", alignItems: "center" }}>
                            <button onClick={today} type="button" className={`${activeTab == TODAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>TD</button>
                            <button onClick={yesterday} type="button" className={`${activeTab == YESTERDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>YD</button>
                            <button onClick={sevenDay} type="button" className={`${activeTab == SEVENDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>7D</button>
                            <button onClick={oneMonth} type="button" className={`${activeTab == ONEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>30D</button>
                            {/* <button onClick={threeMonth} type="button" className={`${activeTab == THREEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>90D</button> */}
                            <DateRangePicker
                                key={count}
                                className="mr-1"
                                format="dd-MM-yyyy HH:mm:ss"
                                cleanable={false}
                                editable={false}
                                defaultCalendarValue={initialDate}
                                value={inputDate}
                                ranges={[]}
                                placeholder="Select date range"
                                placement='bottomEnd'
                                onOk={(e) => { onHandleEventDateTimeOkClick(e) }}
                                disabledDate={current => {
                                    let d;
                                    if (MULTI_TIME_ZONE) {
                                        let loggedInUser = JSON.parse(localStorage.getItem('user'))
                                        d = multiTimeZoneHelper(loggedInUser.OffsetMinutes);
                                    } else {
                                        d = new Date();
                                    }
                                    return current && current > d;
                                }}
                            />

                            <button type="button" onClick={exportEventHandler} class="btn btn-outline-primary">Export</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="DeviceCurrentStatusDiv">
                <div className="DeviceTableHeader">
                    <span className='HeaderSpanHR'>Operator Alert Report</span>
                    <span className='mr-4'>
                        <span className='FromDateFontSize'>From : </span>{displayStartDate}
                        <span className='ml-3 FromDateFontSize'>To: </span>{displayEndDate}
                    </span>
                </div>

                <div className="card-body p-0">
                    <table
                        className="table table-text-ext table-fixed report-table EventReportTable mb-2"
                        id="event-activity-report"
                    >
                        <thead className="ReportTableHeader ReportTableHeadReport">
                            <tr>
                                <th>
                                    <p>Operator Name</p>
                                    <div className="SearchTextDiv StatusSearchText">
                                        <input
                                            className="SearchViewInput"
                                            type="search"
                                            placeholder="Search"
                                            value={fullNameSearch}
                                            onChange={(e) => setFullNameSearch(e.target.value)}
                                        />
                                        {
                                            props.isDarkTheme ?
                                                <img
                                                    className="EyeIcon"
                                                    alt="Search-icon"
                                                    src={SearchIcon}
                                                    onClick={() => searchListDetails()}
                                                /> :
                                                <img
                                                    className="EyeIcon"
                                                    alt="Search-icon"
                                                    src={SearchIconWhite} height="15"
                                                    onClick={() => searchListDetails()}
                                                />
                                        }
                                    </div>
                                </th>

                                <th style={{verticalAlign: "middle"}}>
                                    <p>Ack Alert Count (A)</p>
                                    <div className="SearchTextDiv StatusSearchText" style={{display: "none"}}>
                                        <input
                                            className="SearchViewInput"
                                            type="search"
                                            placeholder="Search"
                                            value={acknowledgedCountSearch}
                                            onChange={(e) => setAcknowledgedCountSearch(e.target.value)}
                                            disabled
                                        />
                                        {
                                            props.isDarkTheme ?
                                                <img
                                                    style={{
                                                        cursor: 'not-allowed'
                                                    }}
                                                    className="EyeIcon"
                                                    alt="Search-icon"
                                                    src={SearchIcon}
                                                // onClick={() => searchListDetails()}
                                                /> :
                                                <img
                                                    style={{
                                                        cursor: 'not-allowed'
                                                    }}
                                                    className="EyeIcon"
                                                    alt="Search-icon"
                                                    src={SearchIconWhite} height="15"
                                                // onClick={() => searchListDetails()}
                                                />
                                        }
                                    </div>
                                </th>

                                <th style={{verticalAlign: "middle"}}>
                                    <p>Close Alert Count (B)</p>
                                    <div className="SearchTextDiv StatusSearchText" style={{display: "none"}}>
                                        <input
                                            className="SearchViewInput"
                                            type="search"
                                            placeholder="Search"
                                            value={closedCountSearch}
                                            onChange={(e) => setClosedCountSearch(e.target.value)}
                                            disabled
                                        />
                                        {
                                            props.isDarkTheme ?
                                                <img
                                                    style={{
                                                        cursor: 'not-allowed'
                                                    }}
                                                    className="EyeIcon"
                                                    alt="Search-icon"
                                                    src={SearchIcon}
                                                // onClick={() => searchListDetails()}
                                                /> :
                                                <img
                                                    style={{
                                                        cursor: 'not-allowed'
                                                    }}
                                                    className="EyeIcon"
                                                    alt="Search-icon"
                                                    src={SearchIconWhite} height="15"
                                                // onClick={() => searchListDetails()}
                                                />
                                        }
                                    </div>
                                </th>
                                <th style={{verticalAlign: "middle"}}>
                                    <p>Total Alert Count (A+B)</p>
                                    <div className="SearchTextDiv StatusSearchText" style={{display: "none"}}>
                                        <input
                                            className="SearchViewInput"
                                            type="search"
                                            placeholder="Search"
                                            value={totalCountSearch}
                                            onChange={(e) => setTotalCountSearch(e.target.value)}
                                            disabled
                                            style={{
                                                cursor: 'not-allowed'
                                            }}
                                        />
                                        {
                                            props.isDarkTheme ?
                                                <img
                                                    style={{
                                                        cursor: 'not-allowed'
                                                    }}
                                                    className="EyeIcon"
                                                    alt="Search-icon"
                                                    src={SearchIcon}
                                                // onClick={() => searchListDetails()}
                                                /> :
                                                <img
                                                    className="EyeIcon"
                                                    alt="Search-icon"
                                                    src={SearchIconWhite} height="15"
                                                // onClick={() => searchListDetails()}
                                                />
                                        }
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableLoading
                                    ?
                                    (
                                        <tr onClick={() => setShow(!show)}>
                                            {
                                                <>
                                                    <td>
                                                        <Skeleton className="width110pct" />
                                                    </td>
                                                    <td>
                                                        <Skeleton className="width110pct" />
                                                    </td>
                                                    <td>
                                                        <Skeleton className="width110pct" />
                                                    </td>
                                                    <td>
                                                        <Skeleton className="width110pct" />
                                                    </td>
                                                    
                                                </>
                                            }
                                        </tr>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                                data.length > 0
                                                    ?
                                                    data?.map((item) => (
                                                        <OperatorAlertList item={item}
                                                        />
                                                    ))
                                                    : <div className='noRecordFound'><p className='noRecordFoundText'>No Record Available.</p></div>
                                            }
                                        </>
                                    )
                            }
                        </tbody>
                        {
                            totalDevices == 0 || totalPages == 0 || totalPages <= limit
                                ? <div className="PaginationDiv mb-2 mt-2">
                                    <h5 className='PageNumbers'>{totalDevices == 0 ? 0 : 1} - {totalDevices}</h5>
                                    <span>of</span>
                                    <h5 className='PageNumbers'>{totalDevices}</h5>
                                    <Pagination>
                                        <Pagination.Prev disabled />
                                        <Pagination.Item active disabled>{1}</Pagination.Item>
                                        <Pagination.Next disabled />
                                    </Pagination>
                                </div>
                                :
                                <div className="PaginationDiv mb-2 mt-2">
                                    <h5 className="PageNumbers">
                                        {
                                            totalDevices == 0 ? 0
                                                : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                                        -
                                        {
                                            (currentPageNo * limit) > (totalDevices)
                                                ? (totalDevices)
                                                : currentPageNo * limit
                                        }
                                    </h5>
                                    <span>of</span>
                                    <h5 className="PageNumbers">{totalDevices}</h5>

                                    <Pagination>
                                        <Pagination.Prev onClick={prevClick} disabled={currentPageNo == 1} />
                                        {
                                            PaginationComponent(currentPageNo)
                                        }
                                        <Pagination.Next onClick={nextClick} disabled={currentPageNo == maxPageAllowed} />
                                    </Pagination>
                                </div>
                        }
                    </table>
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default OperatorWiseAlertReport
