import { Pagination } from "react-bootstrap";

const PaginationComponent = (currentPageNo, maxPageAllowed, setCurrentPageNo) => {
    let numbers = [];
    if (maxPageAllowed - 2 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2))) {
        numbers.push(
            <Pagination.Item
                key={maxPageAllowed - 2}
                active={maxPageAllowed - 2 == currentPageNo}
                onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
            >
                {maxPageAllowed - 2}
            </Pagination.Item>)
        numbers.push(
            <Pagination.Item
                key={maxPageAllowed - 1}
                active={maxPageAllowed - 1 == currentPageNo}
                onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
            >
                {maxPageAllowed - 1}
            </Pagination.Item>)
        numbers.push(
            <Pagination.Item
                key={maxPageAllowed}
                active={maxPageAllowed == currentPageNo}
                onClick={() => setCurrentPageNo(maxPageAllowed)}
            >
                {maxPageAllowed}
            </Pagination.Item>)
    }
    else if (maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))) {
        numbers.push(
            <Pagination.Item
                key={maxPageAllowed - 1}
                active={maxPageAllowed - 1 == currentPageNo}
                onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
            >
                {maxPageAllowed - 1}
            </Pagination.Item>)
        numbers.push(
            <Pagination.Item
                key={maxPageAllowed}
                active={maxPageAllowed == currentPageNo}
                onClick={() => setCurrentPageNo(maxPageAllowed)}
            >
                {maxPageAllowed}
            </Pagination.Item>)
    }
    else {
        for (let i = currentPageNo; i < currentPageNo + 3; i++) {
            if (i <= maxPageAllowed) {
                numbers.push(
                    <Pagination.Item
                        key={i}
                        active={i == currentPageNo}
                        onClick={() => setCurrentPageNo(i)}
                    >
                        {i}
                    </Pagination.Item>
                )
            }
        }
    }
    return numbers;
}

export default PaginationComponent
