
//#region lib
import ReactPlayer from "react-player";
import { Row, Col } from 'react-bootstrap'
//#endregion lib

import SingleCameraTileSkeleton from "./SingleCameraTileSkeleton";

const SingleCamPlaybackComponent = ({
    selectedSingleDeviceForPlayback,
    isLoading
}) => {
    return (
        <>
            {
                isLoading && isLoading == true ?
                    <SingleCameraTileSkeleton />
                    :
                    <div className="single-cam-playback-main">
                        <div className="single-cam-playback-sub"  >
                            <ReactPlayer
                                key={selectedSingleDeviceForPlayback.ParentCameraID}
                                muted
                                itemID={"video-" + selectedSingleDeviceForPlayback.ParentCameraID}
                                url={selectedSingleDeviceForPlayback.playbackURL}
                                playing={true}
                                style={{ borderRadius: "13px", width: "100%", height: "100%" }}
                                controls
                                autoPlay
                                className="single-cam-playback-player"
                            />
                            <Row className="m-0 pt-3">
                                <Col lg={9}>
                                    <p className="CameraNameText1 camera-name cam-name">{selectedSingleDeviceForPlayback.CameraName}</p>
                                    <p className="LocationNameText camera-name location-name">
                                        Location: <span>{selectedSingleDeviceForPlayback.SiteName}</span>{" "}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
            }
        </>
    )
}

export default SingleCamPlaybackComponent