import React from 'react'
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import Chatbot from "react-chatbot-kit";
//import "../ReactChatbot/Chatbotcomponentstyle.css" //'./Chatbotcomponentstyle.css'

export default function ChatBotcomponent(props){
    return(
        <Chatbot
            config={props.Configurations}
            messageParser={MessageParser}
            messageHistory={props.MessHistory}
            actionProvider={ActionProvider}
        />
    )
}