import React from 'react'

const CustomerSiteList = ({item}) => {
  return (
      <tr>
          <td>{item.ClientName}</td>
          <td>{item.SiteName}</td>
          <td>{item.OpenCount}</td>
          <td>{item.AcknowledgedCount}</td>
          <td>{item.ClosedCount}</td>
          <td>{item.TotalCount}</td>
      </tr>           
  )
}

export default CustomerSiteList
