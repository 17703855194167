import React, { memo } from 'react'

import { Handle } from 'react-flow-renderer';

export default memo(({ data}) => {
    return (
        <div className="default-node" >
            <Handle type="target"
                position="top"
                className="handle"
            />
            <div>{data.label}</div>
            <Handle type="source"
                position="bottom"
                className="handle"
                />
        </div>
    );
})
