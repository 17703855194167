import React, { useEffect, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'
import { AlertIconDetails, FullscreenIcon, AttachEvidenceIcon, InfoBlue, SoundOff, SoundOn } from '../../assets/images'

import { getAlertDetailsById } from "../../services/index";
import { getalertactivitylogs, TwoWayCommunicationRequest } from "../../services/alertAuthService"

// import { AlertGrid } from './AlertsGridView';
import AlertGrid from './AlertGrid';
import DefaultNode from '../Workflow/DecisisonNode'
import WFPreview from '../Workflow/WFPreview';
import AlertEvidenceComponent from "./AlertEvidenceComponent"

import Swal from 'sweetalert2'
import ReactFlow from 'react-flow-renderer';
import { mixpanel } from '../../utils/MixPanelUtil'
import { ALERTDETAILS } from '../../navigation/CONSTANTS';

export default function AlertDetails({ onAddAlertChat, hootWay, setHootWay, setSelectedSubMenu, setCctvTypeName, setCamUserName, setCamPassword, setBrandName }) {

    //useState Block Start
    const [singleAlertDet, setSingleAlertDet] = useState(null)
    const [topFiveSiteAlerts, setTopFiveSiteAlerts] = useState(null)
    const [showFullscreen, setShowFullscreen] = useState(false)
    const [ismodalOpen, setismodalOpen] = useState(false)
    const [isSoundOn, setIsSoundOn] = useState(false)
    const [activitylogs, setactivitylogs] = useState(null)
    //useState Block End

    let history = useHistory()
    //change below code to get alertid
    let match1 = useRouteMatch(ALERTDETAILS) //to fetch id from url
    let alertId = match1.params['id']

    //useEffect Block Start
    
    // useEffect(() => {
    //     const fetchAlertDetails = async () => {
    //         try {
    //             mixpanel.time_event("Session of Alert Detail Page");
    //             mixpanel.track("Clicks on Alert Detail");

    //             const resp = await getAlertDetailsById(alertId);

    //             if (resp.status === "Ok") {
    //                 if (resp.message && resp.message.length > 0) {
    //                     setSingleAlertDet(resp.message[0]);
    //                     mixpanel.track("Session of Alert Detail Page", {
    //                         'ConversationId': `AlertID_${resp.message[0].alertId}_UserID_${resp.message[0].assignedTo}`,
    //                         'AlertTemplate': resp.message[0].alertTemplate,
    //                         'AlertId': resp.message[0].alertId,
    //                         'Severity': resp.message[0].severity
    //                     });
    //                 }
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };

    //     fetchAlertDetails();
    // }, [alertId]);

    /* Rupesh's Revised code useEffect async above code refactored*/
    useEffect(() => {
        const fetchAlertData = async () => {
            if (alertId && alertId != null) {
                try {
                    await Promise.all([GetAlertDetails(), Getalertactivitylogs()]);
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchAlertData();
    }, [alertId]);

    //useEffect Block End

    const GetAlertDetails = async () => {
        try {
            await getAlertDetailsById(alertId).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        if (resp.message && resp.message.length > 0) {
                            setSingleAlertDet(resp.message[0])
                            setCctvTypeName(resp.message[0].cctvtype)
                            setCamUserName(resp.message[0].camUserName)
                            setCamPassword(resp.message[0].camPassword)
                            setBrandName(resp.message[0].brandName)
                            if (resp.message[0].status == "Closed") {
                                setSelectedSubMenu('myalerts')
                            } else {
                                setSelectedSubMenu('allalerts')
                            }
                        }
                    }
                    if (resp.topFiveAlerts) {
                        setTopFiveSiteAlerts(resp.topFiveAlerts)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const Getalertactivitylogs = async () => {
        try {
            let obj = {
                "alertId": alertId
            }
            getalertactivitylogs(obj).then((resp) => {
                if (resp.status == "Ok") {
                    setactivitylogs(resp.message.activityLog)
                } else if (resp.status === "Failed") {
                    //alert(resp.message)
                    return;
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const handleClose = () => {
        setShowFullscreen(false)
    }

    const handleevidenceModalClose = () => {
        setismodalOpen(false)
    }
    const OnReferenceAlertClick = (alertid) => {
        setismodalOpen(false)
        history.push(`/alertdetails/${alertid}`)
    }

    const AddMixpanelEvent = async (id) => {
        try {
            await getAlertDetailsById(id).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        if (resp.message && resp.message.length > 0) {
                            setSingleAlertDet(resp.message[0])
                        }
                        mixpanel.track("Clicks on Similar Alerts", {
                            'ConversationId': "AlertID_" + resp.message[0].alertId + "_UserID_" + resp.message[0].assignedTo,
                            'AlertTemplate': resp.message[0].alertTemplate,
                            'AlertId': resp.message[0].alertId,
                            'Severity': resp.message[0].severity
                        });
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
        //mixpanel.track("Clicks on Similar Alerts", { "AlertId": id })
    }

    function Openchatroom() {
        try {
            if (singleAlertDet) {
                if (singleAlertDet.workflowId != null) {
                    let isItDisabled = true;
                    let user_id = localStorage.getItem('UserID');
                    if (singleAlertDet.assignedTo != null && singleAlertDet.assignedTo == user_id) {
                        isItDisabled = false
                    }
                    let tempObj = {
                        alertId: singleAlertDet.alertId,
                        twoWayStatus: true,
                        hooterStatus: true,
                        btnDisabled: isItDisabled,
                        deviceId: singleAlertDet.deviceId
                    }
                    setHootWay([...hootWay, tempObj]);
                    history.push(`/chatflow/${singleAlertDet.alertId}`)
                } else {
                    Swal.fire({ text: "Workflow not configured for " + singleAlertDet.alertTemplate })
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const TwoWayCommunationReq = async (privateIpAddress, communicationPort, action, camUserName, camPassword, brandName) => {
        try {
            // if (obj) {
            TwoWayCommunicationRequest(privateIpAddress, communicationPort, action, camUserName, camPassword, brandName)
                .then((resp) => {

                    return resp
                })
            //}
        } catch (error) {
            console.error(error)
        }
    }

    function TwoWayCommClick(status, alertDetails) {
        if (status && status == 'on') {
            setIsSoundOn(true)
            var reqResp = TwoWayCommunationReq(alertDetails.privateIpAddress, alertDetails.communicationPort, 'start', alertDetails.camUserName, alertDetails.camPassword, alertDetails.brandName)
            return reqResp
        }
        if (status && status == 'off') {
            setIsSoundOn(false)
            if (alertDetails) {
                var reqResp = TwoWayCommunationReq(alertDetails.privateIpAddress, alertDetails.communicationPort, 'stop', alertDetails.camUserName, alertDetails.camPassword, alertDetails.brandName)
            }

        }
    }

    return (
        <>
            {ismodalOpen &&
                <AlertEvidenceComponent
                    ismodalOpen={ismodalOpen}
                    handleClose={handleevidenceModalClose}
                    AlertDetails={singleAlertDet}
                    OnEvidenceAttached={GetAlertDetails}
                    OnReferenceAlertClick={OnReferenceAlertClick}
                />
            }
            {singleAlertDet &&
                <div className='MainContentMargin'>
                    <div className="row col-lg-12 p-0" style={{ margin: "0 auto" }}>
                        {
                            showFullscreen &&
                            <WFPreview
                                show={showFullscreen}
                                onClose={handleClose}
                                workflowName={singleAlertDet.workflowName}
                                elements={singleAlertDet.metadata.elements}
                                nodesDraggable={false} />
                        }
                        <div className="col-lg-12 pr-0 mt-2">
                            <div className="NameHeading1">
                                <p>Alerts</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="alert-icon">
                                <div className={`alert-icon-bg ${singleAlertDet.severity.toLowerCase()}-bg manage-config-align`} >
                                    <img src={AlertIconDetails} />
                                </div>
                            </div>
                            <span className="alert-title">Alert Id: {singleAlertDet.alertId}</span>
                            <div className="alert-label-value">
                                <span className="alert-label">Operated By</span>
                                <span className="alert-value">{singleAlertDet.userName}</span>
                            </div>
                            <div className="alert-label-value">
                                <span className="alert-label">Branch</span>
                                <span className="alert-value">{singleAlertDet.branchId}</span>
                            </div>
                            {
                                singleAlertDet.zoneNo ?
                                <div className="alert-label-value">
                                    <span className="alert-label">Zone No</span>
                                    <span className="alert-value">{singleAlertDet.zoneNo}</span>
                                 </div>
                                 :
                                 null
                            }
                            {
                                singleAlertDet.zoneName &&
                                <div className="alert-label-value">
                                    <span className="alert-label">Zone Name</span>
                                    <span className="alert-value">{singleAlertDet.zoneName}</span>
                                </div>
                            }

                            <div className="alert-label-value">
                                <span className="alert-label">Alert Timezone</span>
                                <span className="alert-value">{singleAlertDet.Abbreviation}</span>
                            </div>
                            
                            <div className="alert-label-value">
                                <span className="alert-label">Two Way Communication</span>
                                {isSoundOn && <img alt='twoway communication' height={18} width={18} src={SoundOn} onClick={() => TwoWayCommClick('off', singleAlertDet)} />}
                                {!isSoundOn && <img alt='twoway communication' height={18} width={18} src={SoundOff} onClick={() => TwoWayCommClick('on', singleAlertDet)} />}
                            </div>

                            <div className="ButtonsAttachedView1">
                                {
                                    (singleAlertDet.alertStatus == "Closed")
                                        ?
                                        <>
                                            <button className="AttachEvidencesButton btn mr-3" onClick={() => {
                                                setismodalOpen(true)
                                                try {
                                                    mixpanel.track("Clicks on Attach Evidences", {
                                                        "ConversationId": "AlertID_" + singleAlertDet.alertId + "_UserID_" + singleAlertDet.assignedTo,
                                                        "AlertTemplate": singleAlertDet.alertTemplate,
                                                        "AlertId": singleAlertDet.alertId,
                                                        "Severity": singleAlertDet.severity
                                                    })
                                                } catch (error) {
                                                    console.error(error)
                                                }
                                            }}>
                                                <p>Attach Evidences
                                                    {/* <i className="fas fa-paperclip"></i> */}
                                                    <img className="pl-1" src={AttachEvidenceIcon} alt='attach icon' />
                                                </p>
                                            </button>
                                            <button className="view-chatroom-btn" onClick={() => { history.push(`/alertreport/${singleAlertDet.alertId}`) }}>
                                                <span>
                                                    Alert Report
                                                </span>
                                            </button>
                                        </>
                                        :
                                        <button className="view-chatroom-btn" onClick={() => Openchatroom()}>
                                            <span>
                                                Chatflow
                                            </span>
                                        </button>
                                }
                            </div>
                        </div>

                        {/* New design start here */}
                        <div className='col-lg-12 p-0 hgtWorkFlowDiv'>
                            <div className="col-lg-12 padR padB">
                                <div className="AlertStatusType">
                                    <div className="AlertStatus">
                                        <div className="AlertStatus3">
                                            <p>Status</p>
                                            <p>{singleAlertDet.status}</p>
                                        </div>
                                        <div className="AlertStatus3">
                                            <p>Device</p>
                                            <p>{singleAlertDet.deviceName}</p>
                                        </div>
                                        <div className="AlertStatus3">
                                            <p>Customer</p>
                                            <p>{singleAlertDet.CustomerName}</p>
                                        </div>
                                        <div className="AlertStatus3">
                                            <p>Site</p>
                                            <p>{singleAlertDet.site}</p>
                                        </div>
                                        <div className="AlertStatus3">
                                            <p>Severity</p>
                                            <p>{singleAlertDet.severity}</p>
                                        </div>
                                        <div className="AlertStatus3">
                                            <p>Alert Type</p>
                                            <p>{singleAlertDet.alertType}</p>
                                        </div>
                                        <div className="AlertStatus3">
                                            <p>Actual Time</p>
                                            <p>{singleAlertDet.actualTime}</p>
                                        </div>
                                        <div className="AlertStatus3">
                                            <p>Received Time</p>
                                            <p>{singleAlertDet.receivedTime}</p>
                                        </div>
                                        <div className="AlertStatus3">
                                            <p>Modified On</p>
                                            <p>{singleAlertDet.modifiedOn}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-lg-12 p-0 pt-1" style={{ margin: "0 auto" }}>
                                <div className="col-lg-6 pr-2">
                                    <div className="AlertActivityLog">
                                        <div className="activityLogTab">
                                            <span className="activityLogBtn">Activity Log</span>
                                        </div>

                                        <div className='verticalLine'>
                                            <ul className="pl-0 activity-list">
                                                {
                                                    activitylogs &&
                                                    activitylogs.length > 0 &&
                                                    (activitylogs.map((item) => (
                                                        <li className="step completed" key={item.id}>
                                                            <div class="LogSteps">
                                                                <span className="activity-time">
                                                                    {item.Time}
                                                                </span>
                                                                <span className="activity-desc">
                                                                    {item.Message}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    )))
                                                }
                                                {
                                                    (!activitylogs || activitylogs.length == 0) &&
                                                    <div className="noDataAccessMsg">
                                                        <div>
                                                            <img
                                                                className="informationIcon"
                                                                src={InfoBlue}
                                                                alt="Info"
                                                            />
                                                        </div>
                                                        <div style={{ width: "100%" }}>
                                                            <h3 className="No-Activitylog-Present">
                                                                No activity log present. Please process the alert for activity log.
                                                            </h3>
                                                        </div>
                                                    </div>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 pl-2">
                                    <div className="processFlow">
                                        {
                                            singleAlertDet &&
                                            singleAlertDet.metadata &&
                                            <WorkflowPreviewAlert
                                                metadata={singleAlertDet.metadata}
                                                setShowFullscreen={setShowFullscreen} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 related-site-alerts-container" style={{ padding: "0", paddingLeft: "7px" }}>
                                <div className="related-alerts-head">
                                    <span>Related Site Alerts</span>
                                </div>
                                {/* //latest 5 alerts of same site. not extreme alerts */}
                                <div className='MainAlertsFridDiv'>
                                    {
                                        topFiveSiteAlerts &&
                                        topFiveSiteAlerts?.map(item =>
                                            <AlertGrid
                                                key={item.alertId}
                                                id={item.alertId}
                                                alert={item}
                                                onAddAlertChat={onAddAlertChat}
                                                AddMixpanelEvent={AddMixpanelEvent} />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


const WorkflowPreviewAlert = ({
    metadata,
    setShowFullscreen
}) => {
    const [data, setData] = useState([]);
    // let data = JSON.parse(JSON.stringify(metadata))

    useEffect(() => {
        let temp = JSON.parse(JSON.stringify(metadata));
        let filteredData = temp.elements.map((item) => {
            console.log("finalFlowData", item)
            if(item.type == "default" && item.data.label.includes("ERT")){
                console.log("tempFinalFlowData", item.data.label)
                item.data.label = item.data.label.replaceAll("ERT", "FR")
                console.log("tempFinalFlowData", item.data.label)
                return item;
            }else{
                return item;
            }
        });
        setData(filteredData);
        // setData(JSON.parse(JSON.stringify(metadata)))
    }, [metadata]);

    return <div style={{ height: "90%" }}>
        <div className="fullscreen-btn" onClick={() => setShowFullscreen(true)}>
            <img src={FullscreenIcon} alt='fullscreen icon' />
        </div>
        <ReactFlow
            elements={data}
            nodeTypes={nodeTypes}
            nodesDraggable={false}>
        </ReactFlow>
    </div>
}

const nodeTypes = {
    default: DefaultNode
};
