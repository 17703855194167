var db;
var openRequest = indexedDB.open('cognyflow-localstorage', 1);

openRequest.onupgradeneeded = function(e) {
  var db = e.target.result;
  if (!db.objectStoreNames.contains('alertOperations')) {
    db.createObjectStore("alertOperations",{keyPath: 'alertId'})
  }
  if (!db.objectStoreNames.contains('alertNotAckList')) {
    db.createObjectStore("alertNotAckList",{keyPath: 'alertId'})
  }
  if (!db.objectStoreNames.contains('alertErtCount')) {
    db.createObjectStore("alertErtCount",{keyPath: 'alertId'})
  }
  if (!db.objectStoreNames.contains('alertCloseMessage')) {
    db.createObjectStore("alertCloseMessage",{keyPath: 'alertId'})
  }
};
openRequest.onsuccess = function(e) {
  db = e.target.result;
};
openRequest.onerror = function(e) {
  console.dir(e);
};


const CheckIndexedDBSupported = () => {
    if (!('indexedDB' in window)) {
        return false
    }else{
        return true
    }
}

//#region Opened chat window operations

const AddAlertRecord = (alertData) => {
    try{
        var transaction = db.transaction(['alertOperations'], 'readwrite')
        var alerttxn = transaction.objectStore('alertOperations')
        var request = alerttxn.put(alertData);
        request.onerror = function(e) {
        };
        request.onsuccess = function(e) {
        };
        //AddChatWindowOpenedTime(alertData.alertId,notifytimer,escallatetimer)
    }catch(error){
        console.error("AddAlertRecord Exception: => ",error)
    }
}

const DeleteAlertRecord = (id) => {
    try{
        var transaction = db.transaction(['alertOperations'], 'readwrite');
        var alertOperations = transaction.objectStore('alertOperations');

        var request = alertOperations.delete(id);

        request.onerror = function(e) {
        };
        request.onsuccess = function(e) {
        };
    }catch(error){
        console.error("DeleteAlertRecord Exception: => ",error)
    }
}

const deleteAllRecord = () => {
    try {
        var transaction = db.transaction(['alertOperations'], 'readwrite');
        var alertOperations = transaction.objectStore('alertOperations');

        let request = alertOperations.clear();

        request.onerror = function(e) {
        };
        request.onsuccess = function(e) {
        };

    } catch (error) {
        console.error("DeleteAllRecord Exception: => ",error)
    }
}

const GetAllAlertRecord = () => {
    try{
        var transaction = db.transaction(['alertOperations'], 'readwrite')
        var alerttxn = transaction.objectStore('alertOperations')
        var lst = alerttxn.getAll()       
        
        return lst
    }catch(error){
        console.error("GetAllAlertRecord Exception: => ",error)
    }
}

//#endregion

const AddChatWindowOpenedTime = (alertId,notifytimer,escallatetimer) => {
    try{
        var transaction = db.transaction(['alertNotAckList'], 'readwrite')
        var alerttxn = transaction.objectStore('alertNotAckList')
        
        var alertobj = {
            "alertId": alertId,
            "notifytimer":notifytimer,
            "escallatetimer":escallatetimer,
            "alertWindowOpenTime": ConvertDate(new Date())
        }
        var request = alerttxn.put(alertobj);
        request.onerror = function(e) {
        };
        request.onsuccess = function(e) {
        };
    }catch(error){
        console.error("AddChatWindowOpenedTime Exception: => ",error)
    }
}
const DeleteChatWindowOpenedTime = (id) => {
    try{
        var transaction = db.transaction(['alertNotAckList'], 'readwrite');
        var alertOperations = transaction.objectStore('alertNotAckList');

        var request = alertOperations.delete(id);

        request.onerror = function(e) {
        };
        request.onsuccess = function(e) {
        };
    }catch(error){
        console.error("DeleteChatWindowOpenedTime Exception: => ",error)
    }
}
const GetAlertNotAckRecord = () => {
    try{
        var transaction = db.transaction(['alertNotAckList'], 'readwrite')
        var alerttxn = transaction.objectStore('alertNotAckList')
        var lst = alerttxn.getAll()
        return lst
    }catch(error){
        console.error("GetAlertNotAckRecord Exception: => ",error)
    }
}

//#region ERT count func

const AddERTCount = (alertId,count,ertescallateimer) => {
    try{
        var transaction = db.transaction(['alertErtCount'], 'readwrite')
        var alerttxn = transaction.objectStore('alertErtCount')
        
        var alertobj = {
            "alertId": alertId,
            "count":count,
            "ertescallateimer":ertescallateimer
        }
        var request = alerttxn.put(alertobj);
        request.onerror = function(e) {
        };
        request.onsuccess = function(e) {
        };
    }catch(error){
        console.error("AddERTCount Exception: => ",error)
    }
}
const DeleteERTCount = (id) => {
    try{
        var transaction = db.transaction(['alertErtCount'], 'readwrite');
        var alertOperations = transaction.objectStore('alertErtCount');

        var request = alertOperations.delete(id);

        request.onerror = function(e) {
        };
        request.onsuccess = function(e) {
        };
    }catch(error){
        console.error("DeleteERTCount Exception: => ",error)
    }
}
const GetERTCount = () => {
    try{
        var transaction = db.transaction(['alertErtCount'], 'readwrite')
        var alerttxn = transaction.objectStore('alertErtCount')
        var lst = alerttxn.getAll()
        return lst
    }catch(error){
        console.error("GetERTCount Exception: => ",error)
    }
}

//#endregion

//#region Alert close obj

const Addcloseobject = (alertId,obj) => {
    try{
        var transaction = db.transaction(['alertCloseMessage'], 'readwrite')
        var alerttxn = transaction.objectStore('alertCloseMessage')
        
        var alertobj = {
            "alertId": alertId,
            "alertObject":obj
        }
        var request = alerttxn.put(alertobj);
        request.onerror = function(e) {
        };
        request.onsuccess = function(e) {
        };
    }catch(error){
        console.error("Addcloseobject Exception: => ",error)
    }
}
const Deletecloseobject = (id) => {
    try{
        var transaction = db.transaction(['alertCloseMessage'], 'readwrite');
        var alertOperations = transaction.objectStore('alertCloseMessage');

        var request = alertOperations.delete(id);

        request.onerror = function(e) {
        };
        request.onsuccess = function(e) {
        };
    }catch(error){
        console.error("Deletecloseobject Exception: => ",error)
    }
}
const Getcloseobject = () => {
    try{
        var transaction = db.transaction(['alertCloseMessage'], 'readwrite')
        var alerttxn = transaction.objectStore('alertCloseMessage')
        var lst = alerttxn.getAll()
        return lst
    }catch(error){
        console.error("Getcloseobject Exception: => ",error)
    }
}

//#endregion

function ConvertDate(date) {
    var _smonth = date.getMonth() + 1
    var sdates = date.getFullYear() + "-" + ('0' + _smonth).slice(-2) + "-" + ('0' + date.getDate()).slice(-2)
    var stime = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
    var finalDate = sdates + " " + stime
    return finalDate
}

export{
    CheckIndexedDBSupported,
    AddAlertRecord,
    DeleteAlertRecord,
    GetAllAlertRecord,
    AddChatWindowOpenedTime,
    GetAlertNotAckRecord,
    DeleteChatWindowOpenedTime,
    AddERTCount,
    DeleteERTCount,
    GetERTCount,
    Addcloseobject,
    Deletecloseobject,
    Getcloseobject,
    db,
    deleteAllRecord,
}