import React from 'react'
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";


export default function HealthIntegratedChart({ isDarkTheme, nonIntegratedDevicesCount, systemHealthDevicesOnlineCount, systemHealthDevicesOfflineCount }) {

    const totalIntegrated = systemHealthDevicesOnlineCount + systemHealthDevicesOfflineCount;

    const data={
        labels: ['Integrated', 'Non-Integrated'],
        datasets: [
            {
                // Label for bars
                label: "Total Count",
                // Data or value of your each variable
                data: [Number(systemHealthDevicesOnlineCount) + Number(systemHealthDevicesOfflineCount), nonIntegratedDevicesCount],
                // data: [Number(systemHealthDevicesOnlineCount) + Number(systemHealthDevicesOfflineCount), nonIntegratedDevicesCount],
                // Color of each bar
                backgroundColor: 
                    ["#0B70D1", "#747474"],
                // Border color of each bar
                borderColor: ["#0B70D1", "#747474"],
                borderWidth: 0.5,
                barThickness: 20,
                categoryPercentage: 3.0, 
                barPercentage: 0.98,
            },
        ],
    }

    // const data = {
    //     labels: ['Integrated', 'Non-Integrated'],
    //     datasets: [
    //         // {
    //         //     label: "Online",
    //         //     data: [systemHealthDevicesOnlineCount, 0],
    //         //     backgroundColor: "#7BB526",
    //         //     barThickness: 30,
    //         //     barPercentage: 0.3,
    //         //     borderRadius: 3,
    //         // },
    //         // {
    //         //     label: "Offline",
    //         //     data: [systemHealthDevicesOfflineCount, 0],
    //         //     backgroundColor: "#F32C22",
    //         //     barThickness: 30,
    //         //     barPercentage: 0.3,
    //         //     borderRadius: 3,
    //         // },
    //         {
    //             label: "Integrated",
    //             data: [Number(systemHealthDevicesOnlineCount) + Number(systemHealthDevicesOfflineCount), 0],
    //             backgroundColor: "#F32C22",
    //             barThickness: 30,
    //             barPercentage: 0.3,
    //             borderRadius: 3,
    //         },
    //         {
    //             label: "NA",
    //             data: [0, nonIntegratedDevicesCount],
    //             backgroundColor: "#9D9D9D",
    //             barThickness: 30,
    //             barPercentage: 0.3,
    //             borderRadius: 3,
    //         },
    //     ]
    // }

    // const option = {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     interaction: {
    //         mode: 'index',
    //     },
    //     plugins: {
    //         legend: {
    //             display: false,
    //         },
    //         datalabels: {
    //             display: true,
    //             color: "black",
    //             // align: "end",
    //             // anchor: "end",
    //             font: { size: "14" }
    //           },
    //         tooltip: true,
    //     },
    //     scales: {
    //         x: {
    //             stacked: true,
    //             border: {
    //                 display: true,
    //                 color: isDarkTheme ? '#e6e6e6' : "white",
    //             },
    //             grid: {
    //                 display: false,
    //             },
    //             title: {
    //                 display: true,
    //                 color: isDarkTheme ? "#000000" : "#ffffff",
    //             },
    //             ticks: {
    //                 color: isDarkTheme ? "" : "#bababa",
    //             },
    //         },
    //         y: {
    //             stacked: true,
    //             border: {
    //                 display: true,
    //                 color: isDarkTheme ? '#e6e6e6' : "white",
    //             },
    //             beginAtZero: true,
    //             title: {
    //                 display: true,
    //                 color: isDarkTheme ? "#000000" : "#ffffff",
    //             },
    //             grid: {
    //                 display: false,
    //             },
    //             ticks: {
    //                 color: isDarkTheme ? "" : "#bababa",
    //             },
    //         },

    //     },

    // }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
        },
        layout: {
            padding: {
                // left: 50,
                // right: 50,
                top: 10,
                // bottom: 50
            }
        },
        plugins: {
          datalabels: {
            display: true,
            color: isDarkTheme ? "#000000" : "#ffffff",
            align: "top",
            anchor: "end",
            font: { size: "15" }
          },
          legend: {
            display: false
          }
        },
        scales: {
                    x: {
                        stacked: true,
                        border: {
                            display: true,
                            color: isDarkTheme ? '#e6e6e6' : "white",
                        },
                        grid: {
                            display: false,
                        },
                        title: {
                            display: true,
                            color: isDarkTheme ? "#000000" : "#ffffff",
                        },
                        ticks: {
                            color: isDarkTheme ? "" : "#bababa",
                        },
                    },
                    y: {
                        beginAtZero: true,
                        stacked: true,
                        border: {
                            display: true,
                            color: isDarkTheme ? '#e6e6e6' : "white",
                        },
                        beginAtZero: true,
                        title: {
                            display: true,
                            color: isDarkTheme ? "#000000" : "#ffffff",
                        },
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: isDarkTheme ? "" : "#bababa",
                        },
                    },
        
                },
      };


    return (
        // <div 
        // style={{ width: "100%", float: "right" }}
        // >
            <Bar
                // height="100px"
                data={data}
                options={options}
                plugins={[ChartDataLabels]}
                key={`${systemHealthDevicesOnlineCount}_${isDarkTheme}`}
            />
        // </div>
    )
}
