import React, { useState, useContext, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { ReactComponent as BellIcon } from '../../assets/images/Bell.svg'
import '../../pages/Notification/NotificationLightTheme.scss'
import '../../pages/Notification/NotificationDarkTheme.scss'

import { getAlertDetailsById } from "../../services/index"

import { Drawer } from 'rsuite';

import { Context } from '../../Context/AlertContext'
import { ConversationStateContext } from '../../Context/ConversationStateContext'
import { messaging } from '../../services/firebase'
import { getAll, getertmessage, updateStatus } from "../../utils/NotificationUtil"
import { mixpanel } from '../../utils/MixPanelUtil'
import NotificationList from './NotificationList';
import { ALLALERTS } from '../../navigation/CONSTANTS'
import { GetNotification,GetNotificationRead } from '../../services/userAuthServices'
import NotificationItem from './NotificationItem'


export default function NotificationIcon({
    iconref,
    setIsMenuOpen,
    settabopen
}) {

    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState([])
    const [sortedNots, setSortedNots] = useState([])
    const [count, setCount] = useState(0)
    
    const [notificationsList, setNotificationList] = useState([])

    const [alertState, setAlertState] = useContext(Context)
    const { conversationstate, setconversationState } = useContext(ConversationStateContext)

    const history = useHistory()
    const location = useLocation();
    

    useEffect(() => {
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'visible') {
            // Make API call when tab becomes active
            setTimeout(() => {
                console.log('This will run after 1 second');
                fetchData();
              }, 1000);
           
          }
        };
    
        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        // Clean up the event listener on component unmount
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      
    }, [notifications]);

    const fetchData = () => {
        try {
          getNotificationList(false);
          console.log('fetch notification'); // Handle the response data as needed
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    const onBellIconClick = () => {
        try {
            mixpanel.track("Clicks on Notification Pop Up")
        } catch (error) {
            console.error(error)
        }
        setIsMenuOpen(oldState => !oldState);
        setOpen(true);
    }

    const mapNots = (snapshot) => {
        let temp = snapshot.val();
        console.log("mapNots", temp);
        let newNots = [];
        for (let notification in temp) {
            newNots.push({
                key: notification,
                value: temp[notification]
            });
        }
        setNotifications(prev => prev = newNots);
    }

    const ertmess = (snapshot) => {
        try {
            let temp = snapshot.val();
            setconversationState([temp])
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const temp = notifications.sort((a, b) => {
            console.log("notificationskey", a, b);
            // return Date.parse(b.value.time) - Date.parse(a.value.time)
            return b.key.localeCompare(a.key)
        })
        console.log("SortedNotif", temp);
        setSortedNots(temp)

        const count = temp.reduce((counter, obj) => obj.value.status === 'unread' ? counter += 1 : counter, 0); // 6 
        setCount(count);
    }, [notifications])


    messaging.onMessage((payload) => {
        if (payload != undefined && payload != null) {
            if (payload?.data?.body != undefined) {
                var IsAlertNotification = payload.data.body.toLowerCase().includes("alert")
                if (IsAlertNotification == true) {
                    refreshAlertList(payload.data.body)
                }
            }

        }
    })

    //refresh alert list on new notification arrival (only alert notifications)
    function refreshAlertList(notificationmessage) { //tejasvini old app->17-03-2021 new app->07-10-2021    
        if (location != undefined && location != null) {
            if (location.pathname != undefined && location.pathname != null) {
                if (location.pathname === ALLALERTS) {
                    var alertid = notificationmessage.split("AlertID: ")
                    const data = getAlertDetailsById(alertid[1])
                    if (data != undefined) {
                        data.then(resp => {
                            if (resp != undefined) {
                                if (resp.status == "Ok") {
                                    try {
                                        if (alertState) {
                                            if (resp.message.length > 0) {
                                                var newalert = alertState.find(x => x.alertId == resp.message[0].alertId)
                                                if (newalert) { return }
                                                var newalertobj = NewAlertObject(resp.message[0])
                                                setAlertState([newalertobj, ...alertState])
                                            }
                                        }
                                    } catch (error) {
                                        console.error(error)
                                    }
                                } else {
                                    console.log(resp.message)
                                }
                            }
                        })
                    }
                }
            }
        }
    }

    const NewAlertObject = (alertobj) => {
        try {
            var alertobj = {
                "alertId": alertobj.alertId,
                "alertSource": alertobj.alertSource,
                "reqActualTime": alertobj.actualTime,
                "severity": alertobj.severity,
                "actualTime": alertobj.actualTime,
                "closedBy": alertobj.closedBy,
                "closedOn": alertobj.closedOn,
                "userName": alertobj.userName,
                "branchId": alertobj.branchId,
                "workflowName": alertobj.workflowName,
                "status": alertobj.status,
                "alertType": alertobj.alertType,
                "receivedTime": alertobj.receivedTime,
                "modifiedOn": alertobj.modifiedOn,
                "metadata": alertobj.metadata,
                "siteName": alertobj.site,
                "alertCode": alertobj.alertCode,
                "deviceId": alertobj.deviceId,
                "assignedTo": alertobj.assignedTo,
                "zoneType": alertobj.zoneType,
                "deviceTypeID": alertobj.deviceTypeID,
                "cameraSource": alertobj.cameraSource
            }
            return alertobj
        } catch (error) {
            console.error("NewAlertObject Error: ", error)
            return null
        }
    }

    const NavigatetoAlertDetailsPage = (mess, key) => {
        try {
            updateStatus(key, 'read')
            settabopen(false)
            var alertid = mess.split("AlertID: ")[1]
            history.push(`/alertdetails/${alertid}`)
        } catch (error) {
            console.error(error)
        }
    }

    const markAsRead = (key) => {
        updateStatus(key, 'read');
    }

    const getNotificationList = (showNotify) => {
        console.log(localStorage.getItem('user'));
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        GetNotification(loggedInUser?.UserUniqueID)
            .then((res) => {
                if (res.Status == "success") {
                    console.log("GetNotification", res.Data);
                    setNotificationList(res.Data);
                    console.log('Notification Count: ',res.Data.length);
                    document.getElementsByClassName('badge')[0].innerText=(res.Data.length>99)?'99+':res.Data.length;
                }   
                else    
                {
                    setNotificationList([]);
                }
                if (showNotify) {
                    setIsMenuOpen(oldState => !oldState);
                    setOpen(true);
                }
            }).catch(err => console.log(err));
    }

   

   

    console.log("SetNotification " + notificationsList);


    return (
        <div className="topNotification" ref={iconref}>
            <a onClick={() => getNotificationList(true)}>
                <span className="badge badge-pill badge-danger ml-1 mt-1">{(notificationsList.length>99)?'99+':notificationsList.length}</span>
                <BellIcon style={{ cursor: "pointer" }} height={20} className='BellIconStyle'>
                </BellIcon>
            </a>

            <Drawer open={open} onClose={() => setOpen(false)} size='xs' className='NotificationDrawer'>
                <Drawer.Header>
                    <Drawer.Title>
                        <div className='Not_Header'>
                            <p>Notification(<span className='badgenotify'>{notificationsList.length}</span>) </p>
                            <p style={{ color: "#0b70d1", textDecoration: "underline" }}><span className='badgeUnread'>{notificationsList.length}</span> unread</p>
                        </div>

                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <div className='NotificationListHgtDiv'>
                        {
                            notificationsList?.map((item) => (
                               
                                    <NotificationItem   item={item}  />
                                    
                            ))
                        }
                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
    )
}
