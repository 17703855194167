import React, { useState, useEffect } from 'react'
import { Filter, SearchIcon, SearchIconWhite } from '../../assets/images';
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal";
import { GetPanelFilterData } from '../../services/configurationServices';

//Note: This filter component is the clone of existing "FilterTool" component with extra filteration logic added. It is temporary solution and will be replaced by new modular filter component.
const FilterToolCopy = ({ isDarkTheme, setSiteIds, setCustomerIds, setResetClicked, setAlertTypeIds }) => {
    const [showModal, setShowModal] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);
    const [siteCount, setSiteCount] = useState(0);
    const [customerSearch, setCustomerSearch] = useState("");
    const [siteSearch, setSiteSearch] = useState("");
    const [masterCustomerList, setmasterCustomerList] = useState([]);
    const [masterSiteList, setmasterSiteList] = useState([]);
    const [filtercustomerList, setFilterCustomerList] = useState([]);
    const [filterSiteList, setFilterSiteList] = useState([]);
    const [customerSelectAll, setCustomerSelectAll] = useState(false);
    const [siteSelectAll, setSiteSelectAll] = useState(false);

    const [alertTypeSelectAll, setAlertTypeSelectAll] = useState(false);
    const [alertTypeCount, setalertTypeCount] = useState(0);
    const [filterAlertTypeList, setFilterAlertTypeList] = useState([]);
    const [masterAlertTypeList, setmasterAlertTypeList] = useState([]);
    const [alertTypeSearch, setAlertTypeSearch] = useState("")






    useEffect(() => {
        if (showModal && filterCount == 0) {
            GetTemplateDataToFilter("all", "")
            setCustomerSelectAll(false);
            setSiteSelectAll(false);
            setAlertTypeSelectAll(false);
            setCustomerSearch("");
            setSiteSearch("");
            setAlertTypeSearch("");
        }
    }, [showModal]);

    const GetTemplateDataToFilter = (pType, pSelectedIds) => {
        try {
            console.log("checking", GetTemplateDataToFilter)

            GetPanelFilterData(pType, pSelectedIds).then((resp) => {
                if (resp != undefined) {
                    if (resp.Status == "success") {
                        if (pType == "customer") {
                        } else if (pType == "site") {

                        } else if (pType == "alertType") {

                        } else {
                            customerIsChecked(resp.Data.LstClient);
                            siteIsChecked(resp.Data.LstSite);
                            alertIsChecked(resp?.Data?.LstAlertType);
                        }
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }


    const searchPanelFilterHandler = (e, type) => {
        let searchText = e.target.value;
        if (type == "Customer") {
            setCustomerSearch(searchText);
            let arr = masterCustomerList.filter((item) => item.Item2.toLowerCase().includes(searchText.toLowerCase()));
            let isChecked = true;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].isChecked == true) {
                    continue;
                } else {
                    isChecked = false;
                    break;
                }
            }
            setCustomerSelectAll(isChecked);
            setFilterCustomerList(arr);
            setCustomerCount(arr.length);
        } else if (type == "Site") {
            setSiteSearch(searchText);
            let arr = masterSiteList.filter((item) => item.Item2.toLowerCase().includes(searchText.toLowerCase()));
            let isChecked = true;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].isChecked == true) {
                    continue;
                } else {
                    isChecked = false;
                    break;
                }
            }
            setSiteSelectAll(isChecked);
            setFilterSiteList(arr);
            setSiteCount(arr.length);
        } else if (type === "Alert Type") {
            setAlertTypeSearch(searchText)
            let isChecked = true;
            let arr = masterAlertTypeList.filter((item) => item.Item2.toLowerCase().includes(searchText.toLowerCase()));
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].isChecked == true) {
                    continue;
                } else {
                    isChecked = false;
                    break;
                }
            }
            setAlertTypeSelectAll(isChecked);
            setFilterAlertTypeList(arr);
            setalertTypeCount(arr.length)
        }
    }

    const handleCustomerSelectAll = () => {
        const tempArr = filtercustomerList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: !customerSelectAll
            }
            return obj;
        })

        let customerOne = masterCustomerList.map((item) => {
            let objcustomer;
            if (customerSelectAll) {
                if (!item.Item2.toLowerCase().includes(customerSearch.toLowerCase())) {
                    objcustomer = {
                        ...item
                    }
                } else {
                    objcustomer = {
                        ...item,
                        isChecked: false
                    }
                }
            } else {
                if (!item.Item2.toLowerCase().includes(customerSearch.toLowerCase())) {
                    objcustomer = {
                        ...item,
                    }
                } else {
                    objcustomer = {
                        ...item,
                        isChecked: true
                    }
                }
            }
            return objcustomer;
        })

        setCustomerSelectAll(!customerSelectAll);
        setFilterCustomerList(tempArr);
        setmasterCustomerList(customerOne);
        let customerArr = customerOne.filter((item) => item.isChecked).map((i) => i.Item1);
        let clientIds = customerArr.join(',');
        if (customerArr.length != 0) {
            GetPanelFilterData("customer", clientIds).then((resp) => {
                if (resp.Status == "success") {
                    siteIsChecked(resp.Data.LstSite);
                    setSiteSelectAll(false);
                    // alertIsChecked(resp?.Data?.LstAlertType);
                }
            })
        }

    };

    const customerCheckHandler = (e, id) => {
        let arr = filtercustomerList.map((item) => item?.Item1 === id ? { ...item, isChecked: !item.isChecked } : item
            //  {
            //     let obj;
            //     if (item.Item1 == id) {
            //         obj = {
            //             ...item,
            //             isChecked: !item.isChecked
            //         }
            //     } else {
            //         obj = {
            //             ...item
            //         }
            //     }
            //     return obj;
            // }
        );

        let masterCustomer = masterCustomerList.map((item) => item?.Item1 === id ? { ...item, isChecked: !item.isChecked } : item
            //  {
            //     let obj;
            //     if (item.Item1 == id) {
            //         obj = {
            //             ...item,
            //             isChecked: !item.isChecked
            //         }
            //     } else {
            //         obj = {
            //             ...item
            //         }
            //     }
            //     return obj;
            // }
        );

        let customerArr = arr.filter((item) => item.isChecked).map((i) => i.Item1);
        let clientIds = customerArr.join(',');
        if (customerArr.length != 0) {
            GetPanelFilterData("customer", clientIds).then((resp) => {
                if (resp.Status == "success") {
                    siteIsChecked(resp.Data.LstSite);
                    setSiteSelectAll(false);
                    // alertIsChecked(resp?.Data?.LstAlertType);
                }
            })
        }
        else {
            GetPanelFilterData("all", "").then((resp) => {
                if (resp.Status == "success") {
                    siteIsChecked(resp.Data.LstSite);
                    setSiteSelectAll(false);
                    // alertIsChecked(resp?.Data?.LstAlertType);
                }
            })
        }
        setmasterCustomerList(masterCustomer);
        setFilterCustomerList(arr);

    }

    const siteIsChecked = (arr) => {
        const site = arr.map((item, index) => {
            let obj = {
                ...item,
                isChecked: false
            }
            return obj;
        })
        setmasterSiteList(site);
        setFilterSiteList(site);
        setSiteCount(site.length)
    }

    const handleSiteSelectAll = () => {

        const tempArr = filterSiteList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: !siteSelectAll
            }
            return obj;
        })
        let siteOne = masterSiteList.map((item) => {
            let objsite;
            if (siteSelectAll) {
                if (!item.Item2.toLowerCase().includes(siteSearch.toLowerCase())) {
                    objsite = {
                        ...item
                    }
                } else {
                    objsite = {
                        ...item,
                        isChecked: false
                    }
                }
            } else {
                if (!item.Item2.toLowerCase().includes(siteSearch.toLowerCase())) {
                    objsite = {
                        ...item
                    }
                } else {
                    objsite = {
                        ...item,
                        isChecked: true
                    }
                }
            }
            return objsite;
        })
        setSiteSelectAll(!siteSelectAll);
        setFilterSiteList(tempArr);
        setmasterSiteList(siteOne);
    };

    const siteCheckHandler = (e, id) => {
        let arr = filterSiteList.map((item) => item?.Item1 === id ? { ...item, isChecked: !item.isChecked } : item
            // {
            //     let obj;
            //     if (item.Item1 == id) {
            //         obj = {
            //             ...item,
            //             isChecked: !item.isChecked
            //         }
            //     } else {
            //         obj = {
            //             ...item
            //         }
            //     }
            //     return obj;
            // }
        );
        let masterSite = masterSiteList.map((item) => item?.Item1 === id ? { ...item, isChecked: !item.isChecked } : item
            // {
            //     let obj;
            //     if (item.Item1 == id) {
            //         obj = {
            //             ...item,
            //             isChecked: !item.isChecked
            //         }
            //     } else {
            //         obj = {
            //             ...item
            //         }
            //     }
            //     return obj;
            // }
        );
        setmasterSiteList(masterSite);
        setFilterSiteList(arr);
    }

    const applyFilterAll = () => {
        let count = 0;
        let isCustomerChecked = masterCustomerList.some((item) => item.isChecked);
        let isSiteChecked = masterSiteList.some((item) => item.isChecked);
        let isAlertTypeChecked = masterAlertTypeList.some(item => item.isChecked);
        if (isCustomerChecked) {
            count++;
        }
        if (isSiteChecked) {
            count++;
        }
        if (isAlertTypeChecked) {
            count++;
        }
        setFilterCount(count);

        let customer = masterCustomerList.filter((item) => item.isChecked).map((i) => i.Item1);
        let clientIds = customer.join(',');

        let site = masterSiteList.filter((item) => item.isChecked).map((i) => i.Item1);
        let siteIds = site.join(',');

        let alertType = masterAlertTypeList.filter(item => item.isChecked).map(i => i.Item1);
        let alertTypeIds = alertType.join(',');

        console.log("FilterText", clientIds)
        console.log("FilterText", siteIds)
        setCustomerIds(clientIds);
        setSiteIds(siteIds);
        setAlertTypeIds(alertTypeIds);
        const fileterTextstring = `customer:${clientIds};site:${siteIds}`;
        // setPanelfilterText(fileterTextstring);
        // getPanelList(1, panelListSearch, fileterTextstring)
        setShowModal(false)
    }

    const filterClearAll = () => {
        GetTemplateDataToFilter("all", "")
        setCustomerSearch("")
        setSiteSearch("")
        setCustomerSelectAll(false);
        setSiteSelectAll(false);
        // setPanelfilterText("");
        const tempcustomer = masterCustomerList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: false
            }
            return obj;
        })
        setmasterCustomerList(tempcustomer);
        setFilterCustomerList(tempcustomer);

        const tempsite = masterSiteList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: false
            }
            return obj;
        })
        setmasterSiteList(tempsite);
        setFilterSiteList(tempsite);
        setFilterCount(0)
        setSiteIds("");
        setCustomerIds("");
        setAlertTypeIds("")
        setResetClicked(true);

        const tempalerttype = masterAlertTypeList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: false
            }
            return obj;
        })
        setmasterAlertTypeList(tempalerttype)
        setFilterAlertTypeList(tempalerttype);
        setFilterCount(0);
        // setShowModal(false);
        // getPanelList(1, panelListSearch, "")
    }

    const customerIsChecked = (arr) => {
        const customer = arr.map((item, index) => {
            let obj = {
                ...item,
                isChecked: false
            }
            return obj;
        })
        setmasterCustomerList(customer);
        setFilterCustomerList(customer);
        setCustomerCount(customer.length);
    }

    const alertIsChecked = (arr) => {
        const alert_type = arr.map((item, index) => {
            let obj = {
                ...item,
                isChecked: false
            }
            return obj;
        })
        setmasterAlertTypeList(alert_type);
        setFilterAlertTypeList(alert_type);
        setalertTypeCount(alert_type.length)
    }

    const handleAlertTypeSelectAll = () => {

        const tempArr = filterAlertTypeList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: !alertTypeSelectAll
            }
            return obj;
        })
        let alertTypeOne = masterAlertTypeList.map((item) => {
            let objalertType;
            if (alertTypeSelectAll) {
                if (!item.Item2.toLowerCase().includes(alertTypeSearch.toLowerCase())) {
                    objalertType = {
                        ...item
                    }
                } else {
                    objalertType = {
                        ...item,
                        isChecked: false
                    }
                }

            } else {
                if (!item.Item2.toLowerCase().includes(alertTypeSearch.toLowerCase())) {
                    objalertType = {
                        ...item,
                    }
                } else {
                    objalertType = {
                        ...item,
                        isChecked: true
                    }
                }
            }
            return objalertType;
        })
        setAlertTypeSelectAll(!alertTypeSelectAll);
        setFilterAlertTypeList(tempArr);
        setmasterAlertTypeList(alertTypeOne)
    };


    const alertTypeCheckHandler = (e, id) => {
        let arr = filterAlertTypeList.map((item) => item?.Item1 === id ? { ...item, isChecked: !item.isChecked } : item
            //      {
            //         let obj;
            //         if(item.Item1 == id) {
            //             obj = {
            //                 ...item,
            //                 isChecked: !item.isChecked
            //             }
            //         } else {
            //             obj = {
            //                 ...item
            //             }
            //         }
            //     return obj;
            // }
        );
        let masterAlertType = masterAlertTypeList.map((item) => item?.Item1 === id ? { ...item, isChecked: !item.isChecked } : item
            //     {
            //             let obj;
            //             if(item.Item1 == id) {
            //                 obj = {
            //                     ...item,
            //                     isChecked: !item.isChecked
            //                 }
            //             } else {
            //                 obj = {
            //                     ...item
            //                 }
            //             }
            //     return obj;
            // }
        );
        setmasterAlertTypeList(masterAlertType);
        setFilterAlertTypeList(arr);
    }

    return (
        <>
            <div style={{ display: "grid", gridAutoFlow: "column", gridGap: "10px", alignItems: "center", marginRight: '1rem' }}>
                <button className="AlertFilter"
                    onClick={() => { setShowModal(true) }}
                >
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>
                                filter
                            </Tooltip>
                        }
                    >
                        <>
                            <div className="badge badge-pill badge-danger" style={{ top: "2px", right: "4px", minWidth: "25px", color: "white" }}>
                                <p className='filteractivetext' style={{ color: "#fff", fontSize: '12px' }}>
                                    {filterCount}
                                </p>
                            </div>
                            <img src={Filter} style={{ height: "15px", float: "left" }} className="camera-icon-height" alt='Alert filter icon' />
                        </>
                    </OverlayTrigger>
                </button>

            </div>
            <Modal
                size='lg'
                show={showModal}
                onHide={() => setShowModal(false)}
                className='panelFilterModalClass'
                backdrop="static"
                style={{ height: '100%' }}
            >
                <Modal.Header closeButton style={{ borderBottom: "none" }}>
                    <Modal.Title className='pb-0'>
                        {
                            <span>Filter</span>
                        }
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="">
                        <div className="row justify-content-center">
                            {/* <div className='grid-container filterContainer grid-container--fit mb-0'> */}
                            <div className="NotificationTextBox mt-0 col-6">
                                <p className="SiteNameText mb-1">
                                    Customer Name
                                </p>
                                <div className='filterinnerboxdiv'>
                                    <div>
                                        <div className="SelectionListWithSelectAll">
                                            <input
                                                className="SearchViewInput1"
                                                type="search"
                                                placeholder="Search"
                                                value={customerSearch}
                                                onChange={(e) => searchPanelFilterHandler(e, "Customer")}
                                            />
                                            {
                                                isDarkTheme ?
                                                    <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                                    <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                            }
                                        </div>
                                    </div>
                                    {
                                        customerCount > 0
                                            ?
                                            <div className='dataAccessListItem mt-2'
                                            >
                                                <input type="checkbox"
                                                    checked={customerSelectAll}
                                                    onChange={handleCustomerSelectAll}
                                                />
                                                <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                                    onClick={handleCustomerSelectAll}
                                                >
                                                    Select all <span>{customerCount}</span>
                                                </p>
                                            </div>
                                            : null
                                    }
                                    <div className='HgtFilterBoxList'>

                                        {
                                            filtercustomerList.map((item) => (
                                                <div key={item.Item1} className="dataAccessListItem"
                                                >
                                                    <input
                                                        checked={item.isChecked}
                                                        type="checkbox"
                                                        onChange={(e) => customerCheckHandler(e, item.Item1)}
                                                    />
                                                    <p className="AllTextwithValue"
                                                        onClick={(e) => customerCheckHandler(e, item.Item1)}>
                                                        {item.Item2}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>


                            </div>
                            <div className="NotificationTextBox mt-0 col-6 ">
                                <p className="SiteNameText mb-1">
                                    Site
                                </p>

                                <div className='filterinnerboxdiv'>
                                    <div>
                                        <div className="SelectionListWithSelectAll">
                                            <input
                                                className="SearchViewInput1"
                                                type="search"
                                                placeholder="Search"
                                                value={siteSearch}
                                                onChange={(e) => searchPanelFilterHandler(e, "Site")}
                                            />
                                            {
                                                isDarkTheme ?
                                                    <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                                    <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                            }
                                        </div>
                                    </div>
                                    {
                                        siteCount > 0
                                            ?
                                            <div className='dataAccessListItem mt-2'>
                                                <input type="checkbox"
                                                    checked={siteSelectAll}
                                                    onChange={handleSiteSelectAll}
                                                />
                                                <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                                    onClick={handleSiteSelectAll}
                                                >
                                                    Select all <span>{siteCount}</span>
                                                </p>
                                            </div>
                                            : null
                                    }
                                    <div className='HgtFilterBoxList'>

                                        {
                                            filterSiteList.map((item) => (
                                                <div key={item.Item1} className="dataAccessListItem">
                                                    <input checked={item.isChecked}
                                                        type="checkbox"
                                                        onChange={(e) => siteCheckHandler(e, item.Item1)}
                                                    />
                                                    <p className="AllTextwithValue"
                                                        onClick={(e) => siteCheckHandler(e, item.Item1)}>
                                                        {item.Item2}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}

                            {/* <div className="grid-container filterContainer grid-container--fit mb-0"> */}

                            <div className="NotificationTextBox mt-1 col-6">
                                <p className="SiteNameText mb-1">
                                    Alert Type
                                </p>

                                <div className='filterinnerboxdiv'>
                                    <div>
                                        <div className="SelectionListWithSelectAll">
                                            <input
                                                className="SearchViewInput1"
                                                type="search"
                                                placeholder="Search"
                                                value={alertTypeSearch}
                                                onChange={(e) => searchPanelFilterHandler(e, "Alert Type")}
                                            />
                                            {
                                                isDarkTheme ?
                                                    <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                                    <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                            }
                                        </div>
                                    </div>
                                    {
                                        alertTypeCount > 0
                                            ?
                                            <div className='dataAccessListItem mt-2'>
                                                <input type="checkbox"
                                                    checked={alertTypeSelectAll}
                                                    onChange={handleAlertTypeSelectAll}
                                                />
                                                <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                                    onClick={handleAlertTypeSelectAll}>
                                                    Select all <span>{alertTypeCount}</span>
                                                </p>
                                            </div>
                                            : null
                                    }
                                    <div className='HgtFilterBoxList'>
                                        {
                                            filterAlertTypeList.map((item) => (
                                                <div key={item.Item1} className="dataAccessListItem">
                                                    <input checked={item.isChecked} type="checkbox"
                                                        onChange={(e) => alertTypeCheckHandler(e, item.Item1)}
                                                    />
                                                    <p className="AllTextwithValue"
                                                        onClick={(e) => alertTypeCheckHandler(e, item.Item1)}>
                                                        {item.Item2}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* </div> */}
                        </div>
                        <div style={{ textAlign: 'center', display: "flex", justifyContent: "center" }} className='filterbuttonsdiv'>
                            <button className='DataAccessEdit' style={{ width: "100px" }}
                                onClick={applyFilterAll}
                            >
                                <span className='ViewUpdate'>Apply</span>
                            </button>

                            <button className='DataAccessEdit' style={{ width: "100px" }}
                                onClick={filterClearAll}
                            >
                                <span className='ViewUpdate'>Reset</span>

                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FilterToolCopy
