import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Row, Col, OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";

const AddZoneForm = React.forwardRef((props, ref) => {
  let { selectedZone, devicesmasterlist, OnAddIDSPanel, selectedDevice, isDeviceEdit } = {
    ...props,
  };

  const { register, handleSubmit, getValues, setValue } = useForm();
  const [addzontype, setaddzontype] = useState(false);
  const [zonetype, setzonetype] = useState("");

  useEffect(() => {
    if (selectedZone && selectedZone != null) {
      setValue("iDSPanelZoneTypeID", selectedZone.iDSPanelZoneTypeID) ||
        setValue("ZoneNo", selectedZone.zoneNo) ||
        setValue("ZoneName", selectedZone.zoneName) ||
        setValue("idsPanelZonesID", selectedZone.idsPanelZonesID);
    }
  }, [selectedZone]);
  return (
    <>
      <form>
        <div className="panel-camera-list">
          <div style={{ marginLeft: "10px" }}>
            <div>
              <Row style={{ marginTop: "25px" }}>
                <Col lg={6}></Col>
                <Col lg={6}>
                  {
                    !addzontype
                      ? <div className="zoneadditiontextDiv" >
                        <p
                          className="zoneTypeAdditiontext"
                          onClick={true ? () => setaddzontype(!addzontype) : null}
                        >
                          + Add Zone Type
                        </p>
                      </div>
                      : null
                  }
                </Col>
              </Row>
            </div>
            {addzontype && (
              <div style={{ display: "flex", margin: "0px 0px 10px 0px" }}>
                <input
                  onChange={(e) => setzonetype(e.target.value)}
                  type="text"
                  className="SiteNameTextBox"
                  placeholder="ex: Fire sensor"
                />

                <button className="addZoneBtn manageZones-addZone-btn"
                  onClick={() => {
                    OnAddIDSPanel(
                      zonetype,
                      selectedDevice.ParentCameraID,
                      "addPanelZoneTypeMaster"
                    );
                    setaddzontype(false);
                  }}
                >
                  <span className="ViewUpdate">Add</span>
                </button>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Close</Tooltip>}
                >
                  <p
                    className="zoneTypeCloseButton"
                    onClick={() => {
                      setzonetype("");
                      setaddzontype(false);
                    }}
                  >
                    x
                  </p>
                </OverlayTrigger>
              </div>
            )}
            <select
              {...register("iDSPanelZoneTypeID")}
              className="SiteNameTextBox"
              placeholder="Select Zone Type"
              defaultValue={selectedZone.iDSPanelZoneTypeID}
              onChange={(e) => setValue("iDSPanelZoneTypeID", e.target.value)}
            >
              <option key="0" disabled={false} value="0">Zone Type*</option>
              {devicesmasterlist.zoneTypeMasterList &&
                devicesmasterlist.zoneTypeMasterList.map((site) => (
                  <option value={site.iDSPanelZoneTypeID} key={site.idsPanelZonesID}>
                    {site.zoneType}
                  </option>
                ))}
            </select>

            <input
              type="Number"
              placeholder="Zone Number* ex: 256"
              {...register("ZoneNo")}
              className="SiteNameTextBox"
              min={1}
              max={256}
              onInput={(event) => {
                let inputValue = event.target.value;
                if (inputValue.length > 1 && inputValue[0] === "0") {
                  inputValue = parseInt(inputValue, 10).toString();
                }
                event.target.value = inputValue;
                if (inputValue.length > 2) {
                  event.target.value = inputValue.slice(0, 3);
                }
              }}
              onKeyDown={(event) => { if (event.key === ".") { event.preventDefault(); } }}
              defaultValue={selectedZone.zoneNo}
            />
            <input
              maxLength={50}
              placeholder="Zone Name* ex: Fire alert"
              {...register("ZoneName")}
              className="SiteNameTextBox"
              defaultValue={selectedZone.zoneName}
            />
          </div>
        </div>
        <div className="m-2 text-right" style={{ textAlign: "end" }}>
          {selectedZone.zoneNo == "" ? (
            <button
              className="NextMoveButton m-3 manageZones-addZone-btn"
              onClick={handleSubmit((e) =>
                OnAddIDSPanel(e, selectedDevice.ParentCameraID, "addPanelZones")
              )}
            >
              <span className="ViewUpdate">Add Zone</span>
            </button>
          ) : (
            <button
              className="NextMoveButton m-3 manageZones-updateZone-btn"
              onClick={handleSubmit((e) =>
                OnAddIDSPanel(e, selectedDevice.ParentCameraID, "updatePanelZone")
              )}
            >
              <span className="ViewUpdate">Update Zone</span>
            </button>
          )}
        </div>
      </form>
    </>
  );
});

export default AddZoneForm