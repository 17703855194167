import React, { useEffect, useState } from 'react'
// import List from '../../assets/images/List.png'
import { List, Grid, Search, Cross, NewSearchIcon, AlertListViewActive, AlertListViewInactive, AlertGridViewInactive, AlertGridViewActive, Filter, RefreshIcon, MosaicIcon } from '../../assets/images';
// import 'bootstrap/dist/css/bootstrap.css'
import AlertsGridView from './AlertsGridView';
import AlertListView from './AlertsListView';
import { mixpanel } from '../../utils/MixPanelUtil'
import { useLocation } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import { getAlertList, FilterAlert } from "../../services/alertAuthService";
import Swal from 'sweetalert2'
import AlertMosaicView from './AlertMosaicView';
import { HIDE_STUFF, MULTI_TIME_ZONE } from '../../config';
import { ALERT_NEW_VIEW, ALLALERTS, MOSAIC, MYALERTS } from '../../navigation/CONSTANTS';
import FilterToolExtended from '../../components/Filter/FilterToolExtended';
import FilterToolRewamped from '../../components/Filter/FilterToolRewamped';
import FilterByCutomer from '../../components/Filter/FilterByComponents/FilterByCutomer';
import FilterBySite from '../../components/Filter/FilterByComponents/FilterBySite';
import { DatePicker, DateRangePicker } from 'rsuite';
import FilterToolCopy from '../../components/Filter/FilterToolCopy';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { differenceInDays, subDays, subMonths } from 'date-fns';
import { ConvertDate, multiTimeZoneHelper } from '../../utils/utils';
import FilterByDays from '../../components/Filter/FilterByComponents/FilterByDays';


const TODAY = "TD";
const YESTERDAY = "YD";
const SEVENDAY = "7D";
const ONEMONTH = "1M";
const THREEMONTH = "3M";
const NONE = "NONE";

export default function AlertsContainer({
    extremeAlertLst,
    highAlertLst,
    moderateAlertLst,
    lowAlertsLst,
    allAlerts,
    onAddAlertChat,
    SetNewList,
    extremeAlertCount,
    highAlertCount,
    moderateAlertCount,
    lowAlertCount,
    AllAlertCount,
    uniqueId,
    alertSearch,
    setAlertSearch,
    activeSeverity,
    setActiveSeverity,
    selectedSubMenu,
    isDarkTheme,
    alertChildWindow,
    setAlertChildWindow
}) {
    let lastselectedtab = "extreme"
    const location = useLocation()

    // For Toggling between list and grid views
    const [isActive, setIsActive] = useState("gridview");
    const [isListView, setIsListView] = useState(null)
    const [selectedSeverityTab, setSelectedSeverityTab] = useState('all')

    const [tempextremeAlertLst, settempextremeAlertLst] = useState([])
    const [temphighAlertLst, settemphighAlertLst] = useState([])
    const [tempmoderateAlertLst, settempmoderateAlertLst] = useState([])
    const [templowAlertsLst, settemplowAlertsLst] = useState([])
    const [tempAllAlert, setTempAllAlert] = useState([])
    const [resetInfiniteScroll, setResetInfiniteScroll] = useState(0);

    const [resetClicked, setResetClicked] = useState(false);
    const [alertTypeIds, setAlertTypeIds] = useState("");
    const [siteIds, setSiteIds] = useState("");
    const [customerIds, setCustomerIds] = useState("");
    const [activeTab, setActiveTab] = useState(TODAY);
    const [searchString, setSearchString] = useState("");
    const [initialDate, setInitialDate] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [inputDate, setInputDate] = useState([]);
    const [displayStartDate, setDisplayStartDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState("");

    const [selectedtablist, setselectedtablist] = useState([])
    const [alertsearchtxt, setalertsearchtxt] = useState("")
    const [count, setCount] = useState(0);
    const [abbreviation, setAbbreviation] = useState("");





    console.log("alertChildWindow", alertChildWindow)


    let LoggedInUser = JSON.parse(localStorage.getItem('user'));


    console.log("customerIds => ", customerIds);
    console.log("siteIds => ", siteIds);
    console.log("alertTypeIds => ", alertTypeIds);

    const getFirstDate = () => {
        let d;
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        if (MULTI_TIME_ZONE) {
            d = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
        } else {
            d = new Date();
        }
        return d;
    }

    const getSecondDate = () => {
        let e;
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        if (MULTI_TIME_ZONE) {
            e = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
        } else {
            e = new Date();
        }
        return e;
    }

    //Once user click on gridview or list view it should not change throughout the application until and unless user cannot change it again


    useEffect(() => {
        if (fromDate !== "" || endDate !== "") {
            GettingAlertList(selectedSeverityTab, fromDate, endDate);
        }
    }, [fromDate, endDate]);

    useEffect(() => {
        if (resetClicked) {
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            // getData(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo, searchString, customerIds, siteIds);
            GettingAlertList(selectedSeverityTab, fromDate, endDate);
            setResetClicked(false);
        }
        if (customerIds !== "" || siteIds !== "" || alertTypeIds !== "") {
            GettingAlertList(selectedSeverityTab, fromDate, endDate);
        }
    }, [resetClicked, customerIds, siteIds, alertTypeIds])

    useEffect(() => {
        if (fromDate === "" || endDate === "") {
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            let start = getFirstDate();
            let end = getSecondDate();
            let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
            setFromDate(startDate);
            let endCurrDate = ConvertDate(end);
            setEndDate(endCurrDate)
            displayDateSetters(startDate, endCurrDate);
            //   getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString, customerIds, siteIds);
            GettingAlertList(selectedSeverityTab);

            setAbbreviation(loggedInUser.Abbreviation)
        } else {
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            //   getData(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo, searchString, customerIds, siteIds);
            GettingAlertList(selectedSeverityTab);

            setAbbreviation(loggedInUser.Abbreviation)
        }
        let tempDate = getFirstDate();
        console.log("TempDate", tempDate)
        let c = count + 1;
        setCount(c);
        setInitialDate([subMonths(tempDate, 1), tempDate]);

    }, []);

    useEffect(() => {
        setAlertSearch(false);
        var alertView = localStorage.getItem("IsListView")
        if (alertView) {
            if (alertView == 'true') { setIsListView(true) }
            else if (alertView == 'false') { setIsListView(false) }
            else { setIsListView(false) }
        }
        else { setIsListView(true) }
    }, [])

    useEffect(() => {
        if(location.pathname === ALLALERTS){
            mixpanel.time_event("Session of Alert Docker Page")
            mixpanel.track("Clicks on Alert Docker")
        }else if(location.pathname === MYALERTS){
            mixpanel.time_event("Session of Closed Alert Page")
            mixpanel.track("Clicks on Closed Alert")
        }

        return () => {
            var lasttab=localStorage.getItem("SelectedtAlertTab")
            if(location.pathname === ALLALERTS){
                EndMixpanelEventTimer(lasttab,"Session of Alert Docker Page")
            }else if(location.pathname === MYALERTS){
                EndMixpanelEventTimer(lasttab,"Session of Closed Alert Page")
            }
            localStorage.removeItem("SelectedtAlertTab")
        }
    }, [])

    useEffect(() => {
        settempextremeAlertLst(extremeAlertLst)
    }, [extremeAlertLst])
    useEffect(() => {
        settemphighAlertLst(highAlertLst)
    }, [highAlertLst])
    useEffect(() => {
        settempmoderateAlertLst(moderateAlertLst)
    }, [moderateAlertLst])
    useEffect(() => {
        settemplowAlertsLst(lowAlertsLst)
    }, [lowAlertsLst])
    useEffect(() => {
        setTempAllAlert(allAlerts)
    }, [allAlerts])

    useEffect(() => {
        setActiveSeverity(selectedSeverityTab);
    }, [selectedSeverityTab]);

    //#region Search impl s
    function onSearchChange(e) {
        // e.preventDefault()
        var searchText = e.target.value
        if (searchText) {

            if (selectedSeverityTab == "all") {
                if (allAlerts) {
                    var filteredLstBySearch = allAlerts.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        setTempAllAlert(filteredLstBySearch)
                    } else {
                        setTempAllAlert(allAlerts)
                    }
                }
            }
            if (selectedSeverityTab == "extreme") {
                if (extremeAlertLst) {
                    var filteredLstBySearch = extremeAlertLst.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        settempextremeAlertLst(filteredLstBySearch)
                    } else {
                        settempextremeAlertLst(extremeAlertLst)
                    }
                }
            }
            if (selectedSeverityTab == "high") {
                if (highAlertLst) {
                    var filteredLstBySearch = highAlertLst.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        settemphighAlertLst(filteredLstBySearch)
                    } else {
                        settemphighAlertLst(highAlertLst)
                    }
                }
            }
            if (selectedSeverityTab == "moderate") {
                if (moderateAlertLst) {
                    var filteredLstBySearch = moderateAlertLst.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        settempmoderateAlertLst(filteredLstBySearch)
                    } else {
                        settempmoderateAlertLst(moderateAlertLst)
                    }
                }
            }
            if (selectedSeverityTab == "low") {
                if (lowAlertsLst) {
                    var filteredLstBySearch = lowAlertsLst.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        settemplowAlertsLst(filteredLstBySearch)
                    } else {
                        settemplowAlertsLst(lowAlertsLst)
                    }
                }
            }

        }
        else {
            // setFilteredCamDeviceList(cameraDeviceList)
            settempextremeAlertLst(extremeAlertLst)
            settemphighAlertLst(highAlertLst)
            settempmoderateAlertLst(moderateAlertLst)
            settemplowAlertsLst(lowAlertsLst)
            setTempAllAlert(allAlerts)
        }
    }
    //#endregion Search impl e

    //#region Mixpanel events functions
    const AddMixpanelEvent = (severity) => {
        try {
            //mixpanel.time_event("Session of Alert Docker Page")
            if(location.pathname === ALLALERTS){
                EndMixpanelEventTimer(selectedSeverityTab,"Session of Alert Docker Page")
                mixpanel.time_event("Session of Alert Docker Page")

            }else if(location.pathname === MYALERTS){
                EndMixpanelEventTimer(selectedSeverityTab,"Session of Closed Alert Page")
                mixpanel.time_event("Session of Closed Alert Page")

            }
        } catch (error) {
            console.error(error)
        }
    }
    const EndMixpanelEventTimer = (selectedSeverityTab, eventname) => {
        try {
            switch (selectedSeverityTab) {
                case "extreme":
                    mixpanel.track(eventname, { "Severity": "Extreme" })
                    break;
                case "high":
                    mixpanel.track(eventname, { "Severity": "High" })
                    break;
                case "moderate":
                    mixpanel.track(eventname, { "Severity": "Moderate" })
                    break;
                case "low":
                    mixpanel.track(eventname, { "Severity": "Low" })
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    //#endregion

    //#region Infinite scroll


    const [scrollStart, setScrollStart] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                GettingAlertList(selectedSeverityTab)
                setalertsearchtxt("")
                document.getElementById("Alertid_txt").value = ""
            } catch (error) {
                console.error(error)
            }
        })();

    }, [uniqueId, selectedSeverityTab])

    const GettingAlertList = async (tab, startDate, toDate) => {
        try {
            var apiaction = ""
            if(location.pathname == MYALERTS) { apiaction = "getClosedAlerts" }
            else { apiaction = "getAlertList" }

            var obj = {
                "action": apiaction,
                "severity": tab,
                "userUniqueId": LoggedInUser.UserUniqueID || LoggedInUser.userUniqueID,
                "startIndex": 0,
                "numberOfElements": 50,
                "authCode": localStorage.getItem('authCode'),
                "clientIds": customerIds,
                "siteIds": siteIds,
                "alertTypeIds": alertTypeIds,
                "fromDateTime": startDate,
                "toDateTime": toDate
            }
            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        SetNewList(resp.message, tab)
                        setselectedtablist(resp.message)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const fetchMoreData = () => {
        try {
            var startindex = 50
            setAlertSearch(true);
            if (selectedSeverityTab == "all") {
                if (tempAllAlert.length == AllAlertCount) { return }
                SortingList(tempAllAlert, startindex, selectedSeverityTab)
            } else if (selectedSeverityTab == "extreme") {
                if (extremeAlertLst.length == extremeAlertCount) { return }
                SortingList(extremeAlertLst, startindex, selectedSeverityTab)
            } else if (selectedSeverityTab == "high") {
                if (highAlertLst.length == highAlertCount) { return }
                SortingList(highAlertLst, startindex, selectedSeverityTab)
            } else if (selectedSeverityTab == "moderate") {
                if (moderateAlertLst.length == moderateAlertCount) { return }
                SortingList(moderateAlertLst, startindex, selectedSeverityTab)
            } else if (selectedSeverityTab == "low") {
                if (lowAlertsLst.length == lowAlertCount) { return }
                SortingList(lowAlertsLst, startindex, selectedSeverityTab)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const SortingList = async (templist, index, tab) => {
        try {
            var currentindex = index
            if (templist.length < currentindex) {
                currentindex = templist.length - 1
            } else {
                currentindex = templist.length
            }

            var apiaction = ""
            if(location.pathname == MYALERTS) { apiaction = "getClosedAlerts" }
            else { apiaction = "getAlertList" }

            var obj = {
                "action": apiaction,
                "severity": tab,
                "userUniqueId": LoggedInUser.UserUniqueID || LoggedInUser.userUniqueID,
                "startIndex": currentindex,
                "numberOfElements": index,
                "authCode": localStorage.getItem('authCode'),
                "clientIds": customerIds,
                "siteIds": siteIds,
                "alertTypeIds": alertTypeIds,
                "fromDateTime": fromDate,
                "toDateTime": endDate
            }
            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        SetNewList(templist.concat(resp.message), tab)
                        setselectedtablist(templist.concat(resp.message))
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    //#endregion

    // useEffect(() => {
    //     if(alertsearchtxt === ""){
    //         if(selectedSeverityTab == "all"){
    //             setTempAllAlert([]);
    //             setTempAllAlert(allAlerts)
    //         }else if(selectedSeverityTab == "extreme"){
    //             settempextremeAlertLst(extremeAlertLst)
    //         }else if(selectedSeverityTab == "high"){
    //             settemphighAlertLst(highAlertLst)
    //         }else if(selectedSeverityTab == "moderate"){
    //             settempmoderateAlertLst(moderateAlertLst)
    //         }else if(selectedSeverityTab == "low"){
    //             settemplowAlertsLst(lowAlertsLst)
    //         }
    //     }
    // }, [alertsearchtxt]);

    useEffect(() => {
        if (alertsearchtxt.trim() === "") {
            GettingAlertList(selectedSeverityTab, fromDate, endDate)
            setSelectedSeverityTab(selectedSeverityTab)
            setalertsearchtxt("")
            setAlertSearch(false);
            document.getElementById("Alertid_txt").value = ""
        }
    }, [alertsearchtxt]);

    const setSearchedList = (lst, selectedSeverityTab) => {
        try {
            if (selectedSeverityTab == "all") {
                setTempAllAlert(lst)
            } else if (selectedSeverityTab == "extreme") {
                settempextremeAlertLst(lst)
            } else if (selectedSeverityTab == "high") {
                settemphighAlertLst(lst)
            } else if (selectedSeverityTab == "moderate") {
                settempmoderateAlertLst(lst)
            } else if (selectedSeverityTab == "low") {
                settemplowAlertsLst(lst)
            }
        } catch (error) {
            console.error(error)
        }
    }



    async function SearchAlert() {
        var alertid = alertsearchtxt.trim()
        if (alertid == "") {
            Swal.fire({ text: 'Please enter alert id!' })
            // alert("Please enter alert id!")
            return
        }
        var obj = {
            "userId": LoggedInUser.UserID,
            "searchInput": alertid
        }
        await FilterAlert(obj).then((resp) => {
            if (resp.status == "Ok") {
                if (resp.message) {
                    let lst = [];
                    if(location.pathname === MYALERTS && resp.message?.status === "Closed"){
                        lst.push(resp.message)
                        SetNewList(lst, selectedSeverityTab)
                        // setSearchedList(lst,selectedSeverityTab)
                    } else if (location.pathname === '/allalerts' && resp.message?.status !== "Closed") {
                        setAlertSearch(true);
                        lst.push(resp.message)
                        SetNewList(lst, selectedSeverityTab)
                        // setSearchedList(lst,selectedSeverityTab)
                    }
                    else {
                        Swal.fire({
                            text: 'Alert id not present',
                            confirmButton: 'Ok',
                        }).then(result => result.isConfirmed && ResetSearchAlert());
                    }
                }
            }
            else if (resp.status == "Failed" && resp.message == "Alert not found!") {
                SetNewList([], selectedSeverityTab)
                Swal.fire({
                    text: "Alert not found!",
                    // title: 'Do you want to save the changes?',
                    // showDenyButton: true,
                    // showCancelButton: true,
                    confirmButtonText: 'Ok',
                    // denyButtonText: `Don't save`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        ResetSearchAlert()
                        //   Swal.fire('Saved!', '', 'success')
                    }
                    // else if (result.isDenied) {
                    //   Swal.fire('Changes are not saved', '', 'info')
                    // }
                })
                // Swal.fire({text:'Alert not found!'})

            }
        })
    }
    function ResetSearchAlert() {
        var alertid = alertsearchtxt.trim()
        if (alertid == "") { return }
        GettingAlertList(selectedSeverityTab, fromDate, endDate)
        setSelectedSeverityTab(selectedSeverityTab)
        setalertsearchtxt("")
        setAlertSearch(false);
        document.getElementById("Alertid_txt").value = ""
    }

    const filteredAlerts = (givenlist, searchedText) => {
        return givenlist.filter(x => x.alertId.toString().includes(searchedText));
    }

    const searchHandler = (e) => {
        let s = e.target.value.toString();
        setalertsearchtxt(e.target.value);
        if (selectedSeverityTab === 'all') {
            setTempAllAlert(filteredAlerts(allAlerts, s))
        } else if (selectedSeverityTab === 'extreme') {
            setTempAllAlert(filteredAlerts(extremeAlertLst, s))
        } else if (selectedSeverityTab === 'high') {
            setTempAllAlert(filteredAlerts(highAlertLst, s))
        } else if (selectedSeverityTab === 'moderate') {
            setTempAllAlert(filteredAlerts(moderateAlertLst, s))
        } else {
            setTempAllAlert(filteredAlerts(lowAlertsLst, s))
        }
    }

    const handleScroll = (e) => {
        if (e.currentTarget.scrollTop === 0) {
            // alert("on top")
            setAlertSearch(false);
            let c = resetInfiniteScroll + 1;
            setResetInfiniteScroll(c);
        } else {
            // setAlertSearch(true);
         }
        if(e.currentTarget.scrollTop > 300){
            setScrollStart(true);
        }else{
            setScrollStart(false);
        }
    }

    const filterToolProps = {
        filterTitle: "Alert List Filter",
        filterModalBody: [
            {
                component: () => (<FilterByCutomer isDarkTheme={isDarkTheme} />)
            },
            {
                component: () => (<FilterBySite isDarkTheme={isDarkTheme} />)
            },
            {
                component: () => (<FilterBySite isDarkTheme={isDarkTheme} />)
            },
            {
                component: () => (<DatePicker isDarkTheme={isDarkTheme} />)
            },
        ]
    }


    const today = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        // getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString, customerIds, siteIds);
        GettingAlertList(selectedSeverityTab, startDate, endCurrDate);
        setActiveTab(TODAY)
    }

    const sevenDay = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 6));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        // getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString, customerIds, siteIds);
        GettingAlertList(selectedSeverityTab, startDate, endCurrDate);
        setActiveTab(SEVENDAY)
    }

    const yesterday = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 1));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(subDays(new Date(end.setHours(24, 0, 0, 0)), 1));
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        // getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString, customerIds, siteIds);
        GettingAlertList(selectedSeverityTab, startDate, endCurrDate);
        setActiveTab(YESTERDAY);
    }

    const oneMonth = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subMonths(new Date(start.setHours(0, 0, 0, 0)), 1));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        // getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString, customerIds, siteIds);
        GettingAlertList(selectedSeverityTab, startDate, endCurrDate);
        setActiveTab(ONEMONTH);
    }

    const threeMonth = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subMonths(new Date(start.setHours(0, 0, 0, 0)), 3));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setEndDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        console.log('threeMonth', customerIds);
        console.log('threeMonth', siteIds);
        // getData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString, customerIds, siteIds);
        GettingAlertList(selectedSeverityTab, startDate, endCurrDate);
        setActiveTab(THREEMONTH);
    }

    const displayDateSetters = (startDate, endCurrDate) => {
        let one = startDate.split(" ");
        let tempOne = `${one[0].split("-")[2]}-${one[0].split("-")[1]}-${one[0].split("-")[0]}`;
        setDisplayStartDate(`${tempOne} ${one[1]}`);
        let two = endCurrDate.split(" ");
        let tempTwo = `${two[0].split("-")[2]}-${two[0].split("-")[1]}-${two[0].split("-")[0]}`;
        setDisplayEndDate(`${tempTwo} ${two[1]}`);
    }

    const onHandleDateTimeOkClick = (e) => {
        const [startDate, endDate] = [e[0], e[1]];
        if (startDate && endDate) {
            const daysDifference = differenceInDays(endDate, startDate);
            if (daysDifference > 30) {
                ErrorToast('Maximum Date range cannot exceeded 30 days.');
                return;
            }
        }
        let sDate = ConvertDate(e[0]);
        setFromDate(sDate);
        let eDate = ConvertDate(e[1]);
        setEndDate(eDate);
        setInputDate([e[0], e[1]])
        displayDateSetters(sDate, eDate);
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        // getData(loggedInUser.UserUniqueID, sDate, eDate, 1, "", customerIds, siteIds);
        GettingAlertList(selectedSeverityTab, sDate, eDate);
        setActiveTab(NONE);
    }

    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    let windowFeatures = `menubar=no, fullscreen=yes, location=no, toolbar=no, scrollbars=yes, resizable=yes, status=no, width=${windowWidth}}, height=${windowHeight}`

    const openChildWindow = () => {
        let newChildWindow;
        if (alertChildWindow == null || alertChildWindow.name == "") {
            newChildWindow = window.open(ALERT_NEW_VIEW, "mosaicWindow", "_blank", windowFeatures)
            setAlertChildWindow(newChildWindow);
            sessionStorage.setItem("mosaicWindowKey", "mosaicWindow")
        } else {
            alertChildWindow.focus()
        }   
    }

    return (
        <div className='MainContentMargin'>
            <div className="col-lg-12" style={{ margin: "0px auto", padding: "0px" }}>
                <div className="col-lg-12 SecondDivSearch dashboardHeadingInnerDiv">
                    <div className="cameramainheader"><h4>Alerts</h4></div>
                    <div className='row'>
                        {/* <FilterToolExtended
                            wrappedComponentProps={{
                                isDarkTheme: isDarkTheme,
                                setSiteIds,
                                setCustomerIds,
                                setResetClicked
                            }}
                        /> */}
                        {/* <FilterToolRewamped {...filterToolProps} /> */}
                        <button
                        onClick={() => {
                            openChildWindow()
                        }}
                        // className="mosaicbuttonNewUI"
                        >
                            {/* <img className="playbackicon" src={MosaicIcon} alt="mosaic icon" /> */}
                            {/* <p className="playbackbuttonButtonText"> */}
                            New Alert View
                            {/* </p> */}
                        </button>
                        <FilterToolCopy isDarkTheme={isDarkTheme} setSiteIds={setSiteIds} setCustomerIds={setCustomerIds} setResetClicked={setResetClicked} setAlertTypeIds={setAlertTypeIds} />

                        <FilterByDays
                            fromDate={fromDate}
                            setFromDate={setFromDate}
                            toDate={endDate}
                            setToDate={setEndDate}
                            visibleDays={true}
                            visibleDateRangePicker={true}
                        />
                        {/* <DateRangePicker
                            // key={healthCount}
                            className="mx-2 my-2"
                            format="dd-MM-yyyy HH:mm:ss"
                            cleanable={false}
                            editable={false}
                            defaultCalendarValue={initialDate}
                            value={inputDate}
                            ranges={[]}
                            placeholder="Select date range"
                            placement='bottomEnd'
                            onOk={(e) => { onHandleDateTimeOkClick(e) }}
                            disabledDate={current => {
                                let d;
                                if (MULTI_TIME_ZONE) {
                                    let loggedInUser = JSON.parse(localStorage.getItem('user'))
                                    d = multiTimeZoneHelper(loggedInUser.OffsetMinutes);
                                } else {
                                    d = new Date();
                                }
                                return current && current > d;
                            }}
                        /> */}
                        <div className="AlertSearchTextDiv">
                            <img className="EyeIcon" alt="search-icon" src={Search} onClick={() => SearchAlert()} />
                            <input id="Alertid_txt" className="SearchViewInput" type="number" min="0"
                                onChange={e => setalertsearchtxt(e.target.value)}
                                // onChange={searchHandler} 
                                placeholder="Alert ID" />
                            <img className="EyeIcon" alt="cross-icon" src={Cross} onClick={() => ResetSearchAlert()} />
                        </div>
                        {/* <div className="cam-search mt-0">     
                                <>
                                    <span class="changeColor">
                                        <NewSearchIcon
                                            onClick={() => {
                                                SearchAlert()
                                                // if (!isSearch)
                                                //     ErrorToast('Please enter the name of the camera, location, or site.')
                                            }}
                                        />
                                    </span>
                                    <input className="SearchViewInput" type="search"
                                    id="Alertid_txt" 
                                    // ref={searchRef} 
                                    onChange={e => setalertsearchtxt(e.target.value)} 
                                    placeholder="Alert ID" 
                                    />
                                </>
                        </div> */}
                        {/* <div className="UpdateButtonDiv">
                                <button className="UpdateActivityButton" data-toggle="modal" data-target="#activityUpdateModal">
                                    <span className="ViewUpdate">Activity Update</span>
                                    <img src={Update} />
                                </button>
                            </div> */}

                    </div>
                </div>

                <div className="col-lg-12  SeverityBar" style={{ marginTop: "7px", marginBottom: "7px" }}>
                    <div className="RedBorderDiv">
                        {
                            isActive === "mosaicview" ?
                                <>
                                    <div style={{ display: "flex", alignItems: "center", padding: "10px", width: "67%" }}>
                                        <div className='InputSelectionDropdown' style={{ width: "25%" }}>
                                            <div className="inputMainContainer w40dot75rem m-0" style={{ height: "30px" }}>
                                                <div className="inputTypeLabelContainer" style={{ height: "30px" }}>
                                                    {/* <label className='formFieldLabels'>Select Customer</label> */}
                                                    <select className="inputType ArrowSelectClass" style={{ lineHeight: "27px" }}>
                                                        <option key="0" disabled={false} value="0" id="0">Select Customer</option>
                                                    </select>
                                                    <p className="Error_P" style={{ float: "left" }}>
                                                        <span>
                                                            {/*  {countryError} */}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='InputSelectionDropdown' style={{ width: "25%" }}>
                                            <div className="inputMainContainer w40dot75rem m-0" style={{ height: "30px" }}>
                                                <div className="inputTypeLabelContainer" style={{ height: "30px" }}>
                                                    {/* <label className='formFieldLabels'>Select Site</label> */}
                                                    <select className="inputType ArrowSelectClass" style={{ lineHeight: "27px" }}>
                                                        <option key="0" disabled={false} value="0" id="0">Select Site</option>
                                                    </select>
                                                    <p className="Error_P" style={{ float: "left" }}>
                                                        <span>
                                                            {/*  {countryError} */}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='InputSelectionDropdown' style={{ width: "25%" }}>
                                            <div className="inputMainContainer w40dot75rem m-0" style={{ height: "30px" }}>
                                                <div className="inputTypeLabelContainer" style={{ height: "30px" }}>
                                                    {/* <label className='formFieldLabels'>Select Device Type</label> */}
                                                    <select className="inputType ArrowSelectClass" style={{ lineHeight: "27px" }}>
                                                        <option key="0" disabled={false} value="0" id="0">Select Device Type</option>
                                                    </select>
                                                    <p className="Error_P" style={{ float: "left" }}>
                                                        <span>
                                                            {/*  {countryError} */}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='InputSelectionDropdown' style={{ width: "25%" }}>
                                            <div className="inputMainContainer w40dot75rem m-0" style={{ height: "30px" }}>
                                                <div className="inputTypeLabelContainer" style={{ height: "30px" }}>
                                                    {/* <label className='formFieldLabels'>Select Alert Type</label> */}
                                                    <select className="inputType ArrowSelectClass" style={{ lineHeight: "27px" }}>
                                                        <option key="0" disabled={false} value="0" id="0">Select Alert Type</option>
                                                    </select>
                                                    <p className="Error_P" style={{ float: "left" }}>
                                                        <span>
                                                            {/*  {countryError} */}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='InputSelectionDropdownBtn'>

                                            <button class="AlertFilter" style={{ height: "32px" }}
                                            // onClick={toggleFilter}
                                            >
                                                <div class="badge badge-pill badge-danger" style={{ top: "2px", right: "4px", minWidth: "25px" }}>
                                                    <p class="filteractivetext">10</p>
                                                </div>
                                                <img src={Filter} style={{ height: "15px", float: "left" }} className="camera-icon-height" alt="Alert filter icon" />
                                            </button>

                                            <button class="AlertFilter ml-2" style={{ height: "32px" }}>
                                                <RefreshIcon height={15} />
                                            </button>

                                        </div>
                                    </div>
                                </>
                                :
                                <div className="SeverityTextDiv">
                                    <p
                                        className={selectedSeverityTab == "all" ? "active-all active-block active-text" : "inactive-text all"}
                                        onClick={() => setSelectedSeverityTab('all')}>
                                        <>All Alerts <span>({AllAlertCount})</span></>
                                    </p>
                                    <p
                                        className={selectedSeverityTab == "extreme" ? "active-extreme active-block active-text" : "inactive-text extreme"}
                                        onClick={() => {
                                            AddMixpanelEvent("Extreme")
                                            localStorage.setItem("SelectedtAlertTab", "extreme")
                                            setSelectedSeverityTab('extreme')
                                        }}>
                                        <>Extreme <span>({extremeAlertCount})</span></>
                                    </p>
                                    <p
                                        className={selectedSeverityTab == "high" ? "active-high active-block active-text" : "inactive-text high"}
                                        onClick={() => {
                                            AddMixpanelEvent("High")
                                            localStorage.setItem("SelectedtAlertTab", "high")
                                            setSelectedSeverityTab('high')
                                        }}>
                                        <>High <span>({highAlertCount})</span></>
                                    </p>
                                    <p
                                        className={selectedSeverityTab == "moderate" ? "active-medium active-block active-text" : "inactive-text medium"}
                                        onClick={() => {
                                            AddMixpanelEvent("Medium")
                                            localStorage.setItem("SelectedtAlertTab", "moderate")
                                            setSelectedSeverityTab('moderate')
                                        }}>
                                        <>Moderate <span>({moderateAlertCount})</span></>
                                    </p>
                                    <p
                                        className={selectedSeverityTab == "low" ? "active-low active-block active-text" : "inactive-text low"}
                                        onClick={() => {
                                            AddMixpanelEvent("Low")
                                            localStorage.setItem("SelectedtAlertTab", "low")
                                            setSelectedSeverityTab('low')
                                        }}>
                                        <>Low <span>({lowAlertCount})</span></>
                                    </p>
                                </div>
                        }


                        <div className="DownloadTextDiv mr-2">
                            {/* <button 
                            className={ isActive == "gridview" ? 'ListGridBtn' : 'ListGridActiveBtn' }
                                onClick={() => {
                                setIsActive("gridview")
                                localStorage.setItem("IsListView", true)
                                }}
                            >
                                <img src={isActive == "gridview" ? AlertGridViewActive  : AlertGridViewInactive} />
                            </button>
                            <button 
                            className={ isActive == "listview" ? 'ListGridBtn' : 'ListGridActiveBtn' }
                                onClick={() => {
                                setIsActive("listview")
                                localStorage.setItem("IsListView", false)
                                }}
                            >
                                <img src={isActive == "listview" ? AlertListViewActive : AlertListViewInactive} />
                            </button>

                            {
                                HIDE_STUFF ? 
                                selectedSubMenu === "allalerts" ? 
                            <button 
                            className={ isActive == "mosaicview" ? 'ListGridBtn' : 'ListGridActiveBtn' }
                                onClick={() => {
                                setIsActive("mosaicview")
                                // setIsAlertMosaicGridClick(true)
                                localStorage.setItem("IsListView", false)
                                }}
                            >
                                <img src={isActive == "mosaicview" ? AlertGridViewActive : AlertGridViewInactive} />
                            </button> : null
                            : null
                            } */}
                            <a href="#" style={{ display: (isListView ? 'block' : 'none') }} onClick={() => {
                                setIsListView(false)
                                localStorage.setItem("IsListView", false)
                            }}>
                                <p className="DownloadText"><span className="pe-1"><img src={List} alt='list view icon' /></span>List View</p>
                            </a>

                            <a href="#" style={{ display: (isListView ? 'none' : 'block') }} onClick={() => {
                                setIsListView(true)
                                localStorage.setItem("IsListView", true)
                            }}>
                                <p className="DownloadText"><span className="pe-1"><img src={Grid} alt='grid view icon' /></span>Grid View</p>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <div id="scrollableDiv" className="infinite-scroll-div" onScroll={handleScroll}> */}
                {/* <InfiniteScroll
                        key={resetInfiniteScroll}
                        dataLength={selectedtablist.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv"
                    > */}
                        {/* alert grid view start */}
                        <div id="divGridView" 
                        style={{ display: (isListView ? 'block' : 'none') }}
                        // style={{ display: (isActive == "gridview" ? 'block' : 'none') }}
                        >
                            <AlertsGridView
                                severity={selectedSeverityTab}
                                extremeAlertLst={tempextremeAlertLst}
                                highAlertLst={temphighAlertLst}
                                moderateAlertLst={tempmoderateAlertLst}
                                lowAlertsLst={templowAlertsLst}
                                allAlerts={tempAllAlert}
                                onAddAlertChat={onAddAlertChat}
                                resetInfiniteScroll={resetInfiniteScroll}
                                selectedtablist={selectedtablist}
                                fetchMoreData={fetchMoreData}
                                handleScroll={handleScroll}
                                alertSearch={alertSearch}
                                scrollStart={scrollStart}
                            />
                        </div>
                        {/* alert grid view end */}

                        {/* alert list view start */}
                        <div id="divListView" 
                        style={{ display: (isListView ? 'none' : 'block') }}
                        // style={{ display: (isActive == "listview" ? 'block' : 'none') }}
                        >
                            <AlertListView
                                severity={selectedSeverityTab}
                                extremeAlertLst={tempextremeAlertLst}
                                highAlertLst={temphighAlertLst}
                                moderateAlertLst={tempmoderateAlertLst}
                                lowAlertsLst={templowAlertsLst}
                                allAlerts={tempAllAlert}
                                onAddAlertChat={onAddAlertChat}
                                resetInfiniteScroll={resetInfiniteScroll}
                                selectedtablist={selectedtablist}
                                fetchMoreData={fetchMoreData}
                                handleScroll={handleScroll}
                                scrollStart={scrollStart}
                            />
                        </div>

                <div id="divMosaicView" style={{ display: (isActive == "mosaicview" ? 'block' : 'none') }}>
                    <AlertMosaicView />
                </div>
                {/* alert list view end */}
                {/* </InfiniteScroll> */}
                {/* </div> */}
            </div>
        </div>
    )
}