import React, { useEffect, useRef, useState } from 'react'

import { MosaicNoStream } from '../../assets/images';

import ReactPlayer from 'react-player';

import { useVideo } from '../../CustomHooks/useVideo';

import { CLOUD_STREAMING, NO_STREAM_INTERVAL_TIMER } from '../../config'

export default function ReactPlayerExt({ item, style, playerRef, time, currentPage, videoContainerRef }) {
    const { count, setCount, videoStartBuffering, videoStopBuffering, videoErrorHandler, getHeaders } = useVideo();
    //#region useState start
    const [playUrl, setPlayUrl] = useState('');
    const [bufferCheckState, setBufferCheckState] = useState(false);
    const [bufferViewState, setBufferViewState] = useState(true);
    //#endregion useState end

    const bufferRef = useRef(bufferCheckState)

    //#region useEffect start
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!bufferRef.current) {
                setBufferViewState(false)
            }
        }, NO_STREAM_INTERVAL_TIMER);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);
    useEffect(() => {
        let c = count + 1;
        setCount(c);
        if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
        {
            //   let baseUrl = "http://localhost:8080"
            let baseUrl = "http://localhost:3000"
            let tempbaseUrl = "http://localhost:3000";
            let rtspurl = item.RTSPURL
            //get cam guid
            let camguid = item.StreamName
            //do post req to base url
            let actbaseUrl = baseUrl + "/start"
            let IsTrancoding = false
            //   if(item.istranscdng==1)
            if (item.isTranscoded == 1)
                IsTrancoding = true
            fetch(actbaseUrl, {
                method: 'POST',
                body: JSON.stringify({
                    uri: rtspurl,
                    alias: camguid,
                    isencode: IsTrancoding
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }).then((res) => res.json())
                .then((data) => {
                    baseUrl = baseUrl + data['uri']
                    setPlayUrl(baseUrl)
                    //item.
                })
                .catch((err) => {
                });

            return () => {
                let stopbaseUrl = tempbaseUrl + "/stop";
                fetch(stopbaseUrl, {
                    method: "POST",
                    body: JSON.stringify({
                        alias: camguid
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    }
                })
                    .then((res) => res.json())
                    .then((data) => {
                    })
                    .catch((err) => {
                    })
            }
            //url as rtspurl and alis as camguid
        } else {
            setPlayUrl(item.StreamingURL)
        }
    }, [time, currentPage]);
    //#endregion useEffect end
    return (
        bufferViewState ?
            <>
                <ReactPlayer
                    ref={videoContainerRef}
                    itemID={"video-" + item.StreamName}
                    key={count}
                    height={style.height}
                    width={style.width}
                    id={"video-" + item.StreamName}
                    url={playUrl}
                    playing={true}
                    style={{ backgroundColor: "#000000" }}
                    className="mosaic"
                    controls
                    autoPlay
                    config={{
                        file: {
                            attributes: {
                                crossorigin: 'anonymous',
                                disablePictureInPicture: true, // hide pip
                                controlsList: 'nodownload noplaybackrate',
                                onContextMenu: (e) => e.preventDefault(),
                            },
                            tracks: [],
                            forceVideo: true,
                            forceAudio: true,
                            http: {
                                headers: getHeaders(),
                            },
                        },
                    }}
                    onError={(e) => videoErrorHandler(e, item.CameraName)}
                    onBuffer={(e) => videoStartBuffering(e, item.CameraName)}
                    onBufferEnd={(e) => videoStopBuffering(e, item.CameraName)}
                />
            </> :
            <div className="no-stream-found">
                <img
                    src={MosaicNoStream}
                    alt="Video"
                    className="w-100 image-bg mosaic-nostream-img"
                />
            </div>
    )
}
