import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ABOUTTERMS, HOMEPAGE, TERMSANDCONDITION, } from "../../navigation/CONSTANTS";
import { LogoutDotNet } from "../../services/userAuthServices";
import { getMasterTermsDate, getTermsCondition, saveTermsCondition, } from "../../services/termsConditionService";
import { SuccessToast } from "../../CustomHooks/SuccessToast";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import { VERSION } from "../../config";
import { logOutMain } from "../../utils/utils";

export default function Termscondition(props) {
  // region useState Start 
  let location = useLocation();
  let history = useHistory();
  const [EulaData, setEulaLogs] = useState(null);
  const [EulaMasterDate, setEulaMasterDate] = useState(null)
  const divRef = useRef();
  const [userHasReviewed, setUserHasReviewed] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [value, setValue] = useState(false);
  //region useState  end

  function onInput(e) {
    setValue(e.target.checked);
    // getClientIP();
  }
  // region useEffect start 
  useEffect(() => {
    getLocalIPAddress()
      .then(ip => {
        setIpAddress(ip);
      })
      .catch(error => console.log(`getLocalIP ${error}`));
  }, []);

  useEffect(() => {
    let UserID = JSON.parse(localStorage.getItem('UserID'));
    if (location?.pathname === ABOUTTERMS) {
      if (UserID) {

        getMasterTermsDate(UserID).then(resp => {
          if (resp?.Status.toLowerCase() === "success".toLowerCase()) {
            setEulaMasterDate(resp?.Data);
          }
          else {
            ErrorToast(resp?.Message);
          }
        });

        getTermsCondition(UserID).then(resp => {
          if (resp?.Status.toLowerCase() === "success".toLowerCase()) {
            setEulaLogs(resp?.Data)
          }
          else {
            ErrorToast(resp?.Message);
          }
        });
      }
    }
    else if (location?.pathname === TERMSANDCONDITION) {
      if (UserID) {

        getMasterTermsDate(UserID).then(resp => {
          if (resp?.Status.toLowerCase() === "success".toLowerCase()) {
            setEulaMasterDate(resp?.Data);
          }
        });

        getTermsCondition(UserID).then(resp => {
          if (resp?.Status.toLowerCase() === "success".toLowerCase()) {
            setEulaLogs(resp?.Data);
            if (resp?.Data?.EulaAcceptanceFlag === "1") {
              let { from } = { from: { pathname: HOMEPAGE } }
              history.replace(from);
            }
          }
        });
      }
    }
  }, []);

  // region useEffect end

  //region function start
  const isNear = (value, target, threshold) => {
    const difference = Math.abs(value - target);
    return difference <= threshold;
  };

  const scrollTracker = (e) => {
    console.log(
      `${e.target.scrollHeight} - ${e.target.scrollTop} = ${e.target.scrollHeight - e.target.scrollTop
      } `
    );
    console.log(`${e.target.clientHeight}`);
    // if (scrollTop + clientHeight >= scrollHeight) {
    // let bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (
      isNear(e.target.scrollHeight - e.target.scrollTop, e.target.clientHeight, 5
      )
    )
      setUserHasReviewed(true);
  };

  const getLocalIPAddress = async () => {
    const rtcConn = new RTCPeerConnection();
    rtcConn.createDataChannel('');
    const offer = await rtcConn.createOffer();
    await rtcConn.setLocalDescription(offer);

    return new Promise((resolve, reject) => {
      rtcConn.onicecandidate = (event) => {
        if (event.candidate) {
          const candidate = event.candidate.candidate;
          const ipRegex = /([0-9]{1,3}\.){3}[0-9]{1,3}/;
          const ipAddress = candidate.match(ipRegex);
          if (ipAddress) {
            resolve(ipAddress[0]);
            rtcConn.close();
          }
        } else {
          reject('No ICE candidates found');
        }
      };
    });
  };

  const handleSubmit = () => {
    let userId = JSON.parse(localStorage.getItem("UserID"));
    let data = {
      EulaLink: "",
      UserID: `${userId}`,
      Version: VERSION,
      MachineIP: ipAddress,
      MachineName: "",
      Enable: "1",
      EulaAccept: value ? "1" : "0",
    };

    saveTermsCondition(data).then((resp) => {
      if (resp?.Status.toLowerCase() === "success".toLowerCase()) {
        SuccessToast(resp.Message);
        let { from } = location.state || { from: { pathname: HOMEPAGE } };
        history.replace(from);
      } else {
        ErrorToast(resp?.Message);
      }
    });
  };

  const getClientIP = () => {
    // Example code to get the client IP address using WebRTC
    window.RTCPeerConnection =
      window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
    var pc = new RTCPeerConnection({ iceServers: [] }),
      noop = function () { };
    pc.createDataChannel(""); // create a bogus data channel
    pc.createOffer(pc.setLocalDescription.bind(pc), noop); // create offer and set local description
    pc.onicecandidate = function (ice) {
      if (ice && ice.candidate && ice.candidate.candidate) {
        var myIP = /([0-9]{1,3}(\\\\.[0-9]{1,3}){3})/.exec(
          ice.candidate.candidate
        )[1];
        console.log("Your IP Address:", myIP);
        pc.onicecandidate = noop;
      }
    };
  };

  const logout = async () => {
    try {
      LogoutDotNet().then((user) => {
        history.push("/login");
        logOutMain()
        // localStorage.removeItem("authToken");
        // localStorage.removeItem("authCode");
        // localStorage.removeItem("user");
        // // localStorage.removeItem('user');
        // localStorage.removeItem("userPassword");
        // localStorage.removeItem("UserID");
        // localStorage.removeItem("sessionExpired");
        // localStorage.removeItem("hootWayData");
        // // localStorage.removeItem('theme');
        // sessionStorage.removeItem("Page_Refresh_State");
        // sessionStorage.removeItem("mosaicWindowKey");
        // localStorage.removeItem("PlaySound");
        // deleteAllRecord();
        // childWindow.close();
      });
    } catch (error) {
      console.error({ error });
    }
  };
  //region function end
  return (
    <>
      <div
        className={`MainContentMargin ${location?.pathname === TERMSANDCONDITION
          ? "termsContainerAfteLogin"
          : ""
          }`}
      >
        <div
          className="row col-lg-12"
          style={{
            margin: "0 auto",
            padding: "25px",
            height: `${location?.pathname === TERMSANDCONDITION ? "100vh" : ""
              }`,
          }}
        >
          {location?.pathname === ABOUTTERMS && (
            <>
              <div className="col-lg-12 dashboardHeadingInnerDiv">
                <div className="NameHeading1">
                  <p>Terms and Conditions</p>
                </div>
              </div>
            </>
          )}
          <div
            className={`col-lg-12 ${location?.pathname === ABOUTTERMS
              ? "ReportAccordionMainContainer"
              : ""
              }  termsContainer`}
          >
            <p className="DateAgreeText">
              Agreement Date - {EulaMasterDate ?? null}
            </p>
            <div className="hgtTermsContainerBorder">
              <div
                className="hgtTermsContainer"
                ref={divRef}
                onScroll={scrollTracker}
              >
                <>
                  <>
                    <meta
                      httpEquiv="Content-Type"
                      content="text/html; charset=windows-1252"
                    />
                    <meta
                      name="Generator"
                      content="Microsoft Word 15 (filtered)"
                    />
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Mangal;\n\tpanose-1:0 0 4 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Garamond;\n\tpanose-1:2 2 4 4 3 3 1 1 8 3;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\np.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:0cm;\n\tmargin-left:36.0pt;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\np.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:0cm;\n\tmargin-left:36.0pt;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\np.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:0cm;\n\tmargin-left:36.0pt;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\np.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:0cm;\n\tmargin-left:36.0pt;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nspan.s1\n\t{mso-style-name:s1;}\n.MsoPapDefault\n\t{line-height:115%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n',
                      }}
                    />

                    <>
                      <div className="WordSection1">
                        <p
                          className="MsoNormal"
                          align="center"
                          style={{ marginLeft: "28.0pt", textAlign: "center" }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              END USER LICENCE AGREEMENT
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{ marginLeft: "21.3pt", textAlign: "justify" }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              &nbsp;
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              1.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              INTRODUCTION
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span className="s1">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              1.1.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </span>
                          <span className="s1">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif',
                                letterSpacing: ".15pt"
                              }}
                            >
                              This End User License Agreement ("<b>Agreement</b>") constitutes a
                              contract between Intellve Solutions Private Limited
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              letterSpacing: ".15pt"
                            }}
                          >
                            ("<b>Intellve / We / Us</b>") on one hand
                            <span className="s1">
                              , and the entity named on the Order Form ("<b>Licensee / You</b>") on
                              the other hand.
                            </span>
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span className="s1">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              1.2.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </span>
                          <span className="s1">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif',
                                letterSpacing: ".15pt"
                              }}
                            >
                              By clicking "I Agree", "Accept" or other similar button, or by
                              installing, accessing or using the Software, the Licensee expressly
                              acknowledges, accepts and agrees to enter into this Agreement, having
                              read and understood the terms and conditions hereunder and agreeing to
                              comply with and be legally bound by the same. Access or use of the
                              Software by the Licensee through any of its employees, contractors or
                              authorised agents shall constitute acceptance of the terms of this
                              Agreement by the Licensee.
                            </span>
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span className="s1">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              1.3.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </span>
                          <span className="s1">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif',
                                letterSpacing: ".15pt"
                              }}
                            >
                              This Agreement is being stored, sent and received in an electronic
                              form, and is thus an electronic record in terms of the Information
                              Technology Act, 2000.
                            </span>
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            1.4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span className="s1">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif',
                                letterSpacing: ".15pt"
                              }}
                            >
                              Intellve is a private limited company registered under the
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Companies Act, 1956 and having its registered office at 408, Manratna
                            Business Park, Jn of Tilak Road &amp; Derasar Lane, Ghatkopar East,
                            Mumbai 400077
                          </span>
                          <span className="s1">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif',
                                letterSpacing: ".15pt"
                              }}
                            >
                              . The expression "<b>Intellve</b>", as used in this Agreement shall,
                              unless repugnant to the context thereof, be deemed to include
                              Intellve"s respective legal heirs, representatives, administrators,
                              permitted successors and assigns.
                            </span>
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              letterSpacing: ".15pt"
                            }}
                          >
                            1.5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span className="s1">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif',
                                letterSpacing: ".15pt"
                              }}
                            >
                              Intellve
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            and the Licensee are hereinafter referred individually as "<b>Party</b>"
                            and collectively as the "<b>Parties</b>". Capitalised terms used in this
                            Agreement shall have the meanings ascribed to them under Clause
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            14
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            of this Agreement.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            1.6.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            This Agreement supplements the Order Form issued by the Company and
                            agreed to by the Licensee for the provision of the license granted under
                            this Agreement. In case of any inconsistencies between this Agreement
                            and the Order Form, the provisions of this Agreement shall supersede the
                            provision in the Order Form, unless explicitly specified otherwise.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            1.7.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            If Licensee has purchased the License granted hereunder from a partner,
                            reseller or distributor authorized by Intellve ("<b>Distributor</b>"),
                            to the extent there is any conflict between this Agreement and the
                            agreement entered between Licensee and the respective Distributor,
                            including any purchase order ("<b>Purchase Agreement</b>"), this
                            Agreement shall prevail as between Licensee and Intellve. Any rights
                            granted to Licensee under the Purchase Agreement which are not contained
                            in this Agreement apply only in connection with such Distributor, and
                            Licensee must seek redress or realization or enforcement of such rights
                            solely against such Distributor and not against Intellve.
                          </span>
                        </p>
                        <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: "21.3pt" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              2.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              LICENCE AND CONDITIONS OF USE
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            2.1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Grant of Licence
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Subject to the payment of the Subscription Fees and compliance with
                            all other terms of this Agreement, Intellve hereby grants to Licensee a
                            limited, personal, non-exclusive, non-transferable, non-sub-licensable
                            and revocable licence to remotely access (i.e. on a SaaS basis) and/or
                            use the
                            <span >
                              Software during the Subscription Term, solely for the Licensee"s
                              internal business purposes ("<b>Licence</b>")
                            </span>
                            .
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            2.2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Licensee User Credentials
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Upon payment of the Subscription Fee, Intellve shall provide Licensee
                            with such number of login credentials as specified in the Order Form ("
                            <b>Licensee User Credentials</b>").
                            <a name="_xifngnpmlaks" />
                            <a name="_aw2d7mg1x76" /> The Software and the Licensee User Credentials
                            may be accessed or used only by the duly authorised personnel of the
                            Licensee (each, a "<b>User</b>"). Licensee shall be responsible for
                            safeguarding access to the Licensee User Credentials.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            2.3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Licensee shall, at its own expense, ensure the Software is used strictly
                            in accordance with this Agreement, Order Form
                            <span >
                              and the Documentation at all times
                            </span>
                            . Licensee shall be solely responsible in respect of all access of the
                            Software by using the Licensee User Credentials and all use of the
                            Software by any User. Licensee shall immediately report any unauthorised
                            use of the Software to Intellve.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            2.4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Administration
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - In order to access the Software, Licensee and/or its Users may be
                            required to set up an administrative account with Intellve ("
                            <b>Admin</b> <b>Account</b>"). Any action or communication conducted
                            through the Admin Account shall be deemed to be duly authorised by the
                            Licensee and shall be legally binding on the Licensee.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            2.5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Third-Party Software
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - The Licensee acknowledges that the Software may contain proprietary or
                            open-source software, files, libraries or components belonging to third
                            parties that may be distributed to Licensee and are subject to third
                            party license terms ("<b>Embedded Software</b>")." Any unauthorized use
                            of the Software may result in an infringement claim against the Licensee
                            by a third party. If there is a conflict between any open source license
                            and the terms of this Agreement, then the open source license terms
                            shall prevail but solely in connection with the related third party open
                            source software. Intellve makes no warranty or indemnity hereunder with
                            respect to any third party software.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            2.6.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif',
                                // color: "black"
                              }}
                            >
                              Conditions of Use
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >

                            - Licensee shall use the Software only for Personal Use and only in
                            object code format.
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Licensee shall not, and shall not allow any User or any third party to,
                            directly or indirectly:
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (a)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            copy, modify, translate, create derivative works of, make available or
                            distribute, publicly perform or display any part of the Software
                            (including by incorporation into its products), or use the Software to
                            develop any product or service that is the same as or
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            substantially
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >

                            similar to the Software;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (b)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            adapt, disassemble, decompile, decrypt, reproduce, reverse engineer,
                            extract or attempt to discover the source code or underlying ideas or
                            algorithms of any part of the Software
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            .
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (c)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            Engage in any Commercial Use of the Software or the License granted
                            hereunder
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            ;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (d)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            use
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            any
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >

                            "open source" or "copyleft software" in a manner that would require the
                            Intellve to disclose the source code of the Software to any third party;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (e)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            directly <span >or</span>
                            indirectly access or use any Embedded Software independently of the rest
                            of the Software;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (f)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            disclose the results of any testing or benchmarking of the Software to
                            any third party;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (g)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            use the Software in a manner that violates, infringes or circumvents any
                            applicable law or any rights of any third party, including but not
                            limited to, privacy rights, publicity rights or intellectual property
                            rights;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (h)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            circumvent, disable or otherwise interfere with security-related
                            features of the Software or features that enforce use limitations;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (i)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            export, make available or use the Software in any manner prohibited by
                            applicable laws; and/or
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (j)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            store or transmit any malicious code (i.e., software viruses, Trojan
                            horses, worms, malware or other computer instructions, devices, or
                            techniques that erase data or programming, infect, disrupt, damage,
                            disable, or shut down a computer system or any component of such
                            computer system) or other unlawful material in connection with the
                            Software.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{ marginLeft: "35.45pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              3.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              SUBSCRIPTION FEE
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            3.1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif',
                                // color: "black"
                              }}
                            >
                              The
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Subscription Fee for the Initial Subscription Term shall be as specified
                            in the Order Form. Intellve reserves the right to change the
                            Subscription Fee applicable to any Renewal Term with prior notice to the
                            Licensee.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965833">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              3.2.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              The Subscription Fee shall be paid by the Licensee in advance. To
                              maintain uninterrupted Licence, the Licensee must pay the Subscription
                              Fee for the subsequent Subscription Term at least 15 (fifteen) days
                              before the scheduled termination of the ongoing Subscription Term.
                              Failure to pay the Subscription Fees within the specified time shall
                              give Intellve the right to suspend or terminate the License and/or
                              this Agreement at the end of the then current Subscription Term.
                            </span>
                          </a>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            3.3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            Without
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            prejudice to Intellve"s rights under Clause
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            3.2
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            above, any amount not paid when required to be paid as per this
                            Agreement shall, until paid in full, accrue interest on a daily basis at
                            the rate of 1.5% (one and a half percent) per month or the highest
                            amount permitted by applicable law, whichever is lesser.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            3.4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            All <span >Subscription</span>
                            Fees paid by Licensee shall be non-refundable and free of any claims
                            <span >by the Licensee.</span>
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            3.5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            All <span >amounts</span>
                            payable under this Agreement shall be paid in Indian Rupees (INR),
                            unless otherwise specified in the Order Form.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            3.6.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            The Subscription Fees payable under this agreement does not include
                            local, state or central
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            sales, use, value-added, excise, personal property, goods and services,
                            or other similar taxes or duties and any such taxes shall be assumed and
                            paid by the Licensee as per Applicable Law.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            3.7.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            In case <span >Licensee</span>
                            has purchased the License via a Distributor, the License is subject to
                            the full payment of the applicable fees as per the terms agreed between
                            Distributor and Intellve. In case of default in payment by the
                            Distributor, Intellve has the right to suspend the License without any
                            liability to the Licensee. In such event, Licensee"s only recourse shall
                            be against the Distributor and not against Intellve.
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              4.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              TERM AND TERMINATION
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref40836556">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              4.1.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Subject
                            </span>
                          </a>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            to the terms of this Agreement, this Agreement and the Licence granted
                            hereunder shall begin on the
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            earlier of date of Licensee"s initial access to the Software or the
                            effective date mentioned on the Order Form
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >

                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            ("<b>Effective Date</b>").
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965726">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              4.2.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              The "<b>Initial Term</b>" of this Agreement shall be for a period of
                              12 (twelve) months from the Effective Date or such other period as may
                              be specified in the Order Form.
                            </span>
                          </a>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            The License shall automatically terminate at the end of the Initial
                            Term, unless extended by Licensee by making payment of the Subscription
                            Fee for the entire Renewal Term at least 15 days prior to the last date
                            of the Initial Term.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965740">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              4.3.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Every "<b>Renewal Term</b>" shall be for a period of 12 (twelve)
                              months or such other period as may be specified in the Licensee"s
                              Order Form.
                            </span>
                          </a>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            The License shall automatically terminate at the end of a Renewal Term,
                            unless extended by Licensee by making payment of the Subscription Fee
                            for a further Renewal Term at least 15 days prior to the last date of
                            the ongoing Renewal Term.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            4.4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Termination
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            by Intellve "
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (a)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Intellve may, at its option, terminate this Agreement upon any breach or
                            misrepresentation by Licensee with respect to this Agreement. If such
                            breach or misrepresentation is capable of being cured, then Intellve
                            shall provide the Licensee a cure period of 15 (fifteen) days from
                            notice of the breach. If such breach is not cured
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            within
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >

                            the aforesaid cure period, or if the breach is not capable of being
                            cured, then Intellve may terminate this Agreement immediately without
                            any further notice.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (b)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Intellve
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >

                            shall have the right to terminate this Agreement immediately upon
                            written notice if Licensee becomes insolvent; or makes a general
                            assignment for the benefit of creditors; or suffers or permits the
                            appointment of a receiver for its business or assets; or becomes subject
                            to any proceeding in bankruptcy or insolvency whether domestic or
                            foreign; or has been liquidated voluntarily or otherwise.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            4.5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Upon the expiry of a Subscription Term or earlier termination of this
                            Agreement, as applicable:
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (a)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            This Agreement and the License hereunder shall expire in its entirety
                            with respect to the Software
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            .
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (b)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            All obligations of Intellve under this Agreement shall cease.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (c)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            Licensee
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          ></span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            shall promptly cease all use of the Software.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (d)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Licensee
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            shall promptly pay to Intellve all amounts owed as of the effective date
                            of termination.
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              5.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              DATA PROTECTION
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            5.1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Intellve may need to collect and process such Personal Data of the Users
                            as may be necessary for the normal functioning of the Software (as per
                            the Documentation) or to maintain the security and integrity of the
                            Software. The Licensee undertakes to comply with all applicable privacy
                            and data protection laws, and to procure and provide such data and
                            informed consents as may be necessary for allowing Intellve to use and
                            process data as may be required for the performance of this Agreement.
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              6.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              REPRESENTATIONS AND WARRANTIES
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            6.1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Power and Authority &nbsp;
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Each Party does hereby, with respect to itself, represent, warrant and
                            undertake that:
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (a)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            the Party is duly incorporated and validly existing under the laws of
                            India, or the country of its incorporation, as the case may be;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (b)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            the Party has all appropriate and necessary consents and approvals
                            required to enter into this Agreement and to fulfil its obligations
                            hereunder;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (c)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            this
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >

                            Agreement has been duly executed and delivered by the Party and
                            constitutes, a legal, valid, and binding agreement enforceable against
                            the Party in accordance with its terms;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (d)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            the execution and delivery of this Agreement by the Party, and the
                            performance by the Party of its obligations under this Agreement does
                            not violate its charter documents, any Applicable Law, or breach or
                            constitute a default under any contract between such Party and any other
                            Person, or otherwise infringe upon the rights of any third party.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_mqz4ui37t8ho" />
                          <a name="_hbf6svedqmk5" />
                          <a name="_cpts7nxrrobj" />
                          <a name="_cbogchsmitto" />
                          <a name="_prnqzgif59cb" />
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            6.2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            The Licensee hereby represents and warrants that as on the date of this
                            Agreement, the Software has been demonstrated to and/or tested by the
                            Licensee to its complete satisfaction and that the Software operates and
                            functions to the Licensee"s complete satisfaction. The Licensee hereby
                            permanently and irrevocably waives any and all claims to the contrary.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            6.3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            The Licensee represents, warrants and covenants that all information
                            submitted to Intellve by the Licensee or any User, whether on the
                            Software or otherwise, is and will remain, complete and accurate.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965780">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              6.4.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                            <b>
                              <span
                                lang="EN-GB"
                                style={{
                                  fontSize: "12.0pt",
                                  lineHeight: "115%",
                                  fontFamily: '"Times New Roman",serif'
                                }}
                              >
                                Software Warranty -
                              </span>
                            </b>
                          </a>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >

                            Intellve warrants to Licensee that the Software will operate
                            substantially as described in the Documentation during the Subscription
                            Term ("<b>Software Warranty</b>").
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965407">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              6.5.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              If Licensee, during a Subscription Term, notifies Intellve in writing
                              of any breach of the Software Warranty, Intellve shall, at no
                              additional cost to the Licensee, within such period as may be
                              commercially reasonable for Intellve, make such corrections to the
                              Software as may be required to comply with the Software Warranty. The
                              remedy provided for under this Clause
                            </span>
                          </a>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            6.5
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            shall be Licensee"s sole remedy against Intellve in the event of any
                            breach of the Software Warranty and shall be available to the Licensee
                            only during a Subscription Term. This Software Warranty shall not apply
                            in case the Licensee is in breach of any provision of this Agreement.
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965521">
                            <b>
                              <span
                                lang="EN-GB"
                                style={{
                                  fontSize: "12.0pt",
                                  lineHeight: "115%",
                                  fontFamily: '"Times New Roman",serif'
                                }}
                              >
                                7.
                                <span style={{ font: '7.0pt "Times New Roman"' }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                              </span>
                            </b>
                            <b>
                              <span
                                lang="EN-GB"
                                style={{
                                  fontSize: "12.0pt",
                                  lineHeight: "115%",
                                  fontFamily: '"Times New Roman",serif'
                                }}
                              >
                                DISCLAIMER AND LIMITATION OF LIABILITY
                              </span>
                            </b>
                          </a>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            7.1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Except for the Software Warranty, Intellve disclaims and Licensee waives
                            all warranties on the Software to the maximum extent permissible under
                            Applicable Law, including, without limitation, all implied warranties of
                            merchantability, fitness for a particular purpose and non-infringement,
                            or any warranty that the operation of the Software shall be
                            uninterrupted or error-free or that all errors in the Software can or
                            will be corrected, and licenses the Software "as is" and "with all
                            faults". Licensee assumes all responsibility for the selection of the
                            Software to achieve Licensee"s intended results and for the
                            installation, use and results obtained from the Software.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            7.2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Licensee acknowledges and agrees that the use of computer products and
                            services entails a substantial risk of loss, damage, corruption, delay
                            or mis-transmission of data. Licensee further acknowledges and agrees
                            that industry standards dictate Licensee"s systematic use of products
                            that provide comprehensive back-up of data to prevent such loss.
                            Licensee assumes all risk of loss of data in any way related to or
                            resulting from Licensee's use of the Software.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            7.3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Intellve shall not be liable in any event for any incidental, special,
                            indirect or consequential damages or losses attributable to any breach
                            or alleged breach of this Agreement, even if advised of the possibility
                            of such damages or losses. This limitation of Intellve's liability shall
                            apply regardless of the form of action, whether in contract or tort,
                            including negligence. This limitation shall be applicable even if an
                            exclusive remedy available to Licensee under this Agreement fails to
                            achieve its essential purpose. Licensee understands that the
                            Subscription Fees charged by Intellve specifically reflects the
                            allocation of risk and exclusion of damages provided for under this
                            Agreement.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            7.4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Licensee acknowledges and agrees that it is highly advisable in all
                            circumstances to operate a test environment in which Licensee may test
                            the suitability of the Software for use with Licensee"s live use and
                            Intellve hereby disclaims any liability resulting from Licensee"s
                            failure to do so.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            7.5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Without prejudice to anything else under this Clause
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            7
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            , in no event shall Intellve"s liability under this Agreement, in the
                            aggregate for all claims with respect the Software, exceed [3 months] of
                            Subscription Fee of the then current Subscription Term .
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965864">
                            <b>
                              <span
                                lang="EN-GB"
                                style={{
                                  fontSize: "12.0pt",
                                  lineHeight: "115%",
                                  fontFamily: '"Times New Roman",serif'
                                }}
                              >
                                8.
                                <span style={{ font: '7.0pt "Times New Roman"' }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                              </span>
                            </b>
                            <b>
                              <span
                                lang="EN-GB"
                                style={{
                                  fontSize: "12.0pt",
                                  lineHeight: "115%",
                                  fontFamily: '"Times New Roman",serif'
                                }}
                              >
                                INTELLECTUAL PROPERTY RIGHTS
                              </span>
                            </b>
                          </a>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            8.1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Except for the limited license rights granted by Intellve to Licensee
                            under this Agreement, all rights, title, and interest in and to the
                            Software, and all trademarks, service marks, patents, copyrights, trade
                            secrets, or any developments, updates, or derivative works of any of the
                            foregoing, and any other intellectual property and proprietary rights in
                            or relating to the Software, are and shall remain the exclusive property
                            of Intellve and/or its licensors, as the case may be ("
                            <b>Intellectual Property Rights</b>"). Licensee shall not take any
                            actions that jeopardize Intellve"s or its licensors" Intellectual
                            Property Rights.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            8.2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            If Intellve receives any feedback (e.g. questions, comments, suggestions
                            or the like) regarding the Software (collectively, "<b>Feedback</b>"),
                            all rights, including intellectual property rights in such Feedback
                            shall belong exclusively to Intellve, and shall be considered Intellve"s
                            Confidential Information. Licensee hereby irrevocably and
                            unconditionally transfers and assigns to Intellve all intellectual
                            property rights it has in such Feedback and waives any and all moral
                            rights that Licensee may have in respect thereto. It is further
                            understood that use of Feedback, if any, may be made by Intellve at its
                            sole discretion, and that Intellve in no way shall be obliged to make
                            use of the Feedback.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            8.3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Any anonymous information, which is derived from the use of the Software
                            (i.e., metadata, aggregated and/or analytics information) which is not
                            personally identifiable information ("<b>Analytics Information</b>") may
                            be used for providing the Software, for development, and/or for
                            statistical purposes. Such Analytics Information is the exclusive
                            property of Intellve.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            8.4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            As between the Parties, Lincesee is, and shall be, the sole and
                            exclusive owner of all data and information inputted or uploaded to the
                            Software by Licensee ("<b>Licensee Data</b>").
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <a name="_yo9xditzks4p" />
                          <a name="_egou8qjg6q5v" />
                          <a name="_7kcxwxpian0s" />
                          <a name="_bomyxdk51v8s" />
                          <a name="_ips8dl66uulo" />
                          <a name="_rz33blsl2x9s" />
                          <a name="_hrmyd167co8u" />
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_qfcvs0xrrirg" />
                          <a name="_5i52fs6yh3e" />
                          <a name="_9ae8ie6vew19" />
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              9.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              CONFIDENTIALITY
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965700">
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              9.1.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              "<b>Confidential Information</b>" shall mean and include
                            </span>
                          </a>
                          <span
                            lang="EN-US"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            all proprietary information or material of the Discloser that has or
                            could have commercial, strategic or business value or other utility,
                            whether in tangible or intangible form, whenever and however disclosed,
                            including, but not limited to:
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (a)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            the Software, its features, Intellectual Property in the Software, or
                            any other information related to the Software;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (b)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            any trade secrets, proprietary documents, process, structure or
                            practices of Intellve;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (c)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            any pricing information, invoices, bills or communications related to
                            the Software or any related service;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (d)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            any algorithm, software, design, process, procedure, formula, source
                            code, object code, flow charts, databases, improvement, technology or
                            method;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (e)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            any concepts, reports, data, know-how, works-in-progress, designs,
                            development tools, specifications; and
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (f)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            any information that is specified as confidential by the Discloser.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-US"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            9.2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-US"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Information need not be novel, unique, patentable, copyrightable or
                            constitute a trade secret in order to be designated Confidential
                            Information.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            9.3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "Confidential Information" shall not include information or materials
                            that (a) are learned by the Receiver from another Person free of any
                            restriction and without a breach by such Person of any obligation it may
                            have to the Disclosing Party, (b) are or become publicly available
                            through no unauthorized act of the Receiver or any other Person, (c) are
                            independently developed by the Receiver without reference to or use of
                            any Confidential Information of the Discloser, or (d) are required to be
                            disclosed pursuant to applicable law, regulation, governmental
                            requirement, or court order; provided, however, that the Receiver shall
                            advise the Discloser of such required disclosure promptly upon learning
                            thereof to allow the Discloser appropriate opportunity to seek an
                            appropriate protective order or other judicial relief with respect to
                            such disclosure.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_kbfdare9izhg" />
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            9.4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Obligations of Confidentiality and Non-Use
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >

                            "
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            The Receiver of any Confidential Information shall use the Confidential
                            Information solely for and in connection with the performance of this
                            Agreement ("<b>Permitted Use</b>"). Except as strictly necessary for the
                            for the Permitted Use, the Receiver shall not disclose to any third
                            party or
                          </span>
                          <span
                            lang="EN-US"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            copy or reproduce in any way (including without limitation, store in any
                            computer or electronic system),
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            the whole or any
                          </span>
                          <span
                            lang="EN-US"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            part, summary or extract of any Confidential Information, or any
                            information derived therefrom.
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            The Receiver shall disclose
                          </span>
                          <span
                            lang="EN-US"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Confidential Information
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            only to such employees/consultants who have a bona fide need to know
                            such information for the Permitted Use.
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          ></span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            The receiver shall take all reasonable steps to prevent unauthorized
                            access to or use of the Discloser"s Confidential Information. As used
                            herein, "reasonable steps" means such steps that the Receiver takes or
                            would take to protect its own confidential or proprietary information,
                            which steps shall in any case be no less than those necessary to
                            exercise reasonable care.
                            <a name="_a8i0r3533euh" />
                            <a name="_7oqwfczcomq6" />
                            The Receiver shall notify the Discloser promptly if the Receiver learns
                            of an unauthorized disclosure or release of Discloser"s Confidential
                            Information.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            9.5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Upon the expiration or termination of this Agreement, the Receiver shall
                            return to the Discloser, or destroy, any of the Discloser"s Confidential
                            Information in the receiver"s possession or control.
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              10.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              INDEMNIFICATION
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Licensee shall indemnify, hold and keep harmless, and defend Intellve
                            and its directors, officers, employees, agents and contractors from and
                            against any and all Claims, damages, losses, fines, penalties or any
                            other costs attributable to or arising out of Licensee"s use of the
                            Software or any other matter relating to or arising out of this
                            Agreement.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{ marginLeft: "18.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              11.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              NOTICES
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Any notice, consent, or other communication permitted or required under
                            this Agreement, shall be in writing and may be delivered in person, by
                            mail, or by e-mail to the respective addresses of the Parties. Each
                            Party may change its address for notification purposes by giving the
                            other Party written notice of the new address and the date upon which it
                            shall become effective.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{ marginLeft: "18.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (a)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Notices to Intellve shall be sent to:
                          </span>
                        </p>
                        <table
                          className="MsoNormalTable"
                          border={0}
                          cellSpacing={0}
                          cellPadding={0}
                          width={567}
                          style={{ marginLeft: "20.9pt", borderCollapse: "collapse" }}
                        >
                          <tbody>
                            <tr style={{ height: "14.25pt" }}>
                              <td
                                width={151}
                                valign="top"
                                style={{
                                  width: "4.0cm",
                                  padding: "0cm 5.0pt 0cm 5.0pt",
                                  height: "14.25pt"
                                }}
                              >
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    Name:
                                  </span>
                                </p>
                              </td>
                              <td
                                width={416}
                                valign="top"
                                style={{
                                  width: "11.0cm",
                                  padding: "0cm 5.0pt 0cm 5.0pt",
                                  height: "14.25pt"
                                }}
                              >
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    Aayush Agarwal
                                  </span>
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "14.25pt" }}>
                              <td
                                width={151}
                                valign="top"
                                style={{
                                  width: "4.0cm",
                                  padding: "0cm 5.0pt 0cm 5.0pt",
                                  height: "14.25pt"
                                }}
                              >
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    Designation:
                                  </span>
                                </p>
                              </td>
                              <td
                                width={416}
                                valign="top"
                                style={{
                                  width: "11.0cm",
                                  padding: "0cm 5.0pt 0cm 5.0pt",
                                  height: "14.25pt"
                                }}
                              >
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    CEO
                                  </span>
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "56.25pt" }}>
                              <td
                                width={151}
                                valign="top"
                                style={{
                                  width: "4.0cm",
                                  padding: "0cm 5.0pt 0cm 5.0pt",
                                  height: "56.25pt"
                                }}
                              >
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    Postal Address:
                                  </span>
                                </p>
                              </td>
                              <td
                                width={416}
                                valign="top"
                                style={{
                                  width: "11.0cm",
                                  padding: "0cm 5.0pt 0cm 5.0pt",
                                  height: "56.25pt"
                                }}
                              >
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    408, Manratna Business Park,
                                  </span>
                                </p>
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    Jn of Tilak Road &amp; Derasar Lane, Ghatkopar East,
                                  </span>
                                </p>
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    Mumbai 400077
                                  </span>
                                </p>
                              </td>
                            </tr>
                            <tr style={{ height: "14.25pt" }}>
                              <td
                                width={151}
                                valign="top"
                                style={{
                                  width: "4.0cm",
                                  padding: "0cm 5.0pt 0cm 5.0pt",
                                  height: "14.25pt"
                                }}
                              >
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    E-mail:
                                  </span>
                                </p>
                              </td>
                              <td
                                width={416}
                                valign="top"
                                style={{
                                  width: "11.0cm",
                                  padding: "0cm 5.0pt 0cm 5.0pt",
                                  height: "14.25pt"
                                }}
                              >
                                <p
                                  className="MsoNormal"
                                  style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                                >
                                  <span
                                    lang="EN-GB"
                                    style={{
                                      fontSize: "12.0pt",
                                      lineHeight: "115%",
                                      fontFamily: '"Times New Roman",serif'
                                    }}
                                  >
                                    aayush.a@intellve.com
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (b)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Notices to Licensee may be provided on the Software or sent to the
                            address mentioned in the
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Order Form
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >

                            or to the address provided by Licensee through the Admin Account on the
                            Software.
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "18.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              12.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              GOVERNING LAW AND DISPUTE RESOLUTION
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            12.1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            This Agreement and the respective subject matter hereunder shall be
                            governed by and construed in accordance with Indian law without regard
                            to provisions governing conflicts of law.
                          </span>
                          <a name="_tjn8wh8qwiu9" />
                          <a name="_l77pflo4v6ee" />
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            12.2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Any controversy or dispute relating to or arising out of this Agreement
                            shall be exclusively heard in the courts situated in Mumbai, India and
                            the Parties consent to the exclusive jurisdiction of such courts to hear
                            any such disputes.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_9e15rs46zm27" />
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            12.3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            In case of any dispute arising out of, in connection with or in relation
                            to this Agreement the Parties shall first attempt to resolve such
                            disputes through mediation in good faith. In case the dispute is not
                            resolved through mediation with 30 (thirty) days of first written notice
                            of the dispute, the dispute shall be referred to arbitration governed by
                            the Indian Arbitration and Conciliation Act, 1996 (as amended from time
                            to time). The arbitration shall be conducted by a sole (1) arbitrator to
                            be appointed mutually by the Parties. The seat and venue of arbitration
                            shall be at Mumbai, India. The language of the arbitration shall be
                            English. The award of the arbitrator shall be binding on the Parties
                            subject to the applicable laws in force and the award shall be
                            enforceable in any competent court of law.
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965534">
                            <b>
                              <span
                                lang="EN-GB"
                                style={{
                                  fontSize: "12.0pt",
                                  lineHeight: "115%",
                                  fontFamily: '"Times New Roman",serif'
                                }}
                              >
                                13.
                                <span style={{ font: '7.0pt "Times New Roman"' }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                              </span>
                            </b>
                            <b>
                              <span
                                lang="EN-GB"
                                style={{
                                  fontSize: "12.0pt",
                                  lineHeight: "115%",
                                  fontFamily: '"Times New Roman",serif'
                                }}
                              >
                                MISCELLANEOUS
                              </span>
                            </b>
                          </a>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Entire Agreement
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - This Agreement, along with the Order Form, constitutes the entire
                            agreement between the Parties and supersedes any and all prior
                            agreements, communications and understandings with respect to the
                            subject matter hereof.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Survival
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >&nbsp;
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            Notwithstanding anything stated in this Agreement or elsewhere, the
                            provisions of Clause
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            7
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (<i>Disclaimer and Limitation of Liability</i>) through
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            14
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (<i>Definitions and Interpretation</i>) (both inclusive) shall survive
                            the termination of this Agreement.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Independent Contractors
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - In making and performing this Agreement, the Parties are acting and
                            shall act as independent contractors. Neither Party is, nor will be
                            deemed to be, an agent, legal representative, joint venture, or partner
                            of the other Party for any purpose. Neither Party shall have any
                            authority to represent, act for, or bind the other Party in any respect.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              No Third-Party Beneficiaries
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Except as expressly set forth in this Agreement, nothing in this
                            Agreement shall confer any rights upon any Person or entity other than
                            the Parties hereto and their respective successors and permitted
                            assigns.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_59qcga5wndib" />
                          <a name="_uq8d28ibtzcw" />
                          <a name="_38g0fryxrer1" />
                          <a name="_wcef6u5v3u2b" />
                          <a name="_k5mkyo5sprcb" />
                          <a name="_7nhk3ohuduo4" />
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Assignment
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - The Licensee shall not assign or transfer this Agreement, or delegate
                            any of its powers, rights, liabilities or obligations hereunder, without
                            the prior written consent of Intellve.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.6.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Amendments
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Intellve may amend or modify this Agreement by providing Licensee with
                            due notice of 30 (thirty) days. Any amendment to the Order Form shall be
                            effective only if agreed to in writing by duly authorised
                            representatives of both Parties.
                            <a name="_ins9mrfezxaq" />
                            <a name="_p5mxtafdoe4o" />
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.7.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Use of Name
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Licensee hereby grants to Intellve the right to use Licensee"s name
                            and logo in Intellve"s publicity, press releases, and/or promotions of
                            the Software and on Intellve"s web sites solely to indicate that
                            Licensee is a user of the Software.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_2yncjgltng0o" />
                          <a name="_1aodir70ymxl" />
                          <a name="_2z95gc6m9mx4" />
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.8.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Force Majeure
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Neither Party shall be liable for any failure or delay in its
                            performance under this Agreement due to any cause beyond their
                            reasonable control, whether natural or man-made, including, without
                            limitation, acts of God; acts of war or terrorism; unauthorized actions
                            of third parties; natural disasters or pandemics; governmental actions
                            or orders; equipment, telecommunications, power, or electrical failures;
                            civil disturbances; or labor disputes.
                            <a name="_ymd35d5s80m" />
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.9.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Savings
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Without prejudice to anything else stated in this Agreement,
                            Intellve"s shall not incur any liability for any failure to perform any
                            of its obligations under this Agreement to the extent Intellve"s
                            non-performance results from Licensee's actions or failure to perform
                            its responsibilities.
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_ch6gczsg9jvb" />
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.10.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Severability
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Any provisions of this Agreement that are determined to be invalid or
                            unenforceable by a court of competent jurisdiction shall be ineffective
                            to the extent of such invalidity or unenforceability, without rendering
                            invalid or unenforceable the remaining provisions of this Agreement. Any
                            such provisions of this Agreement that are determined to be invalid or
                            unenforceable shall be given effect to the fullest extent permitted by
                            law so as to best reflect the intention of the Parties in this
                            Agreement.
                            <a name="_tgev7ckp1gqv" />
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            13.11.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Waivers
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - No waiver of any right under this Agreement shall be effective unless
                            in writing. Unless expressly stated otherwise a waiver shall be
                            effective only in the circumstances and in respect of the breach or
                            default for which it is given, and shall not constitute or be construed
                            as a waiver of any subsequent breach or violation of that provision, or
                            as a waiver of any breach or violation of any other provision of this
                            Agreement. No delay or omission by any Party in exercising any right or
                            remedy provided by law or under this Agreement shall constitute a waiver
                            of such right or remedy. The single or partial exercise of a right or
                            remedy under this Agreement shall not preclude any other nor restrict
                            any further exercise of any right or remedy.
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <a name="_Ref157965595" />
                          <a name="_8i7wcuqaq0k3" />
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              14.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              DEFINITIONS AND INTERPRETATION
                            </span>
                          </b>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              14.1.
                              <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                            </span>
                          </b>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Definitions
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            - Capitalised terms used in this Agreement shall have the following
                            meanings unless the context requires otherwise:
                          </span>
                        </p>
                        <p className="MsoNormal">
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Claim</b>" means and includes any and all claims, demands,
                            investigations or causes of actions by third parties;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Commercial Use</b>" means the use of the Software on a commercial
                            basis for the benefit of a third party, and includes (i) the use of the
                            Software on a time sharing, remote job entry, service bureau, or other
                            commercial basis, and (ii) the sale, lease, rent, license, sub-license,
                            dissemination, publication, transfer, modification, adaptation or
                            translation of the Software, or creation of any derivative works based
                            on the Software;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Confidential Information</b>" shall have the meaning ascribed to the
                            term under Clause
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            9.1
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            of this Agreement;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Discloser</b>" shall mean the Party sharing or disclosing its
                            Confidential Information;
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Documentation</b>" means the published user manuals and other
                            documentation for the Software that Intellve makes available from time
                            to time;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Initial Term</b>" shall have the meaning ascribed to the term under
                            Clause
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            4.2
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            of this Agreement;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Intellectual Property Rights</b>" shall have the meaning ascribed to
                            the term under Clause
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            8
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            of this Agreement;
                          </span>
                        </p>
                        <p className="MsoNormal" style={{ textAlign: "justify" }}>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Person</b>" shall mean any natural person, limited or unlimited
                            liability company, corporation, partnership (whether limited or
                            unlimited), proprietorship, Hindu undivided family, trust, union,
                            association, Governmental Authority or any agency or political
                            subdivision thereof or any other entity that may be treated as a person
                            under Applicable Law;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Personal Use</b>" means the use of the Software only for the
                            internal business operations of Licensee and not, directly or
                            indirectly, for any Commercial Use;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Receiver</b>" shall mean the Party receiving Confidential
                            Information;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Renewal Term</b>" shall have the meaning ascribed to the term under
                            Clause
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            4.3
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            of this Agreement;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Software</b>" means Intellve"s MonitoringHub software, being a SAAS
                            software for remote monitoring of cameras and other devices. Unless
                            otherwise indicated, the term "Software" also includes the
                            Documentation.
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Software Warranty</b>" means and is limited to the express
                            warranties stated in Clause
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            6.4
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            of this Agreement;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraph"
                          style={{ marginLeft: "1.0cm", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            "<b>Subscription</b>
                            <b>Term</b>" shall mean either the Initial Term or a Renewal Term, as
                            the context may require;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{ marginLeft: "28.0pt", textAlign: "justify" }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpFirst"
                          style={{
                            marginLeft: "1.0cm",
                            textAlign: "justify",
                            textIndent: "-1.0cm"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            14.2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <b>
                            <span
                              lang="EN-GB"
                              style={{
                                fontSize: "12.0pt",
                                lineHeight: "115%",
                                fontFamily: '"Times New Roman",serif'
                              }}
                            >
                              Interpretation
                            </span>
                          </b>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            " In this Agreement, unless the context otherwise requires:
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (a)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            the terms "hereof", "herein", "hereby", "hereto" and derivative or
                            similar words refer to this entire Agreement or specified provisions of
                            this Agreement, as the case may be;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (b)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            the word "or" is not exclusive;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (c)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            references to this Agreement or any other document hereunder, shall be
                            construed as references to this Agreement or that document, as amended,
                            varied, novated, supplemented, renewed or replaced from time to time;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (d)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            words denoting the singular shall include the plural and words denoting
                            any gender shall include all genders;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (e)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            headings, subheadings, titles, subtitles to clauses, sub-clauses and
                            paragraphs are for information only and shall not form part of the
                            operative provisions of this Agreement hereto and shall be ignored in
                            construing the same;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (f)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            unless otherwise specified, references to days, months and years are to
                            calendar days, calendar months and calendar years, respectively;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (g)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            any reference to "writing" shall include printing, typing, lithography,
                            transmissions in electronic form (including e-mail) and other means of
                            reproducing words in visible form but shall exclude text messages via
                            mobile / smart phone;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (h)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            the words "include" and "including" are to be construed without
                            limitation;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (i)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            no provisions shall be interpreted in favour of, or against, any Party
                            by reason of the extent to which such Party or its counsel participated
                            in the drafting hereof or by reason of the extent to which any such
                            provision is inconsistent with any prior draft hereof;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (j)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            a Party or any other Person includes its successors in title, permitted
                            assigns and permitted transferees;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (k)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            references to Clauses are to clauses of this Agreement, all of which
                            form part of this Agreement;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (l)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            any payments to be made by a Party pursuant to the provisions of this
                            Agreement to any other Party, must be in immediately available cleared
                            funds;
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpMiddle"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            (m)<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            all approvals and/or consents to be granted by the Parties under this
                            Agreement shall be deemed to mean approvals and/or consents in writing;
                            and
                          </span>
                        </p>
                        <p
                          className="MsoListParagraphCxSpLast"
                          style={{
                            marginLeft: "49.65pt",
                            textAlign: "justify",
                            textIndent: "-21.3pt"
                          }}
                        >
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif',
                              // color: "black"
                            }}
                          >
                            (n)
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            time is of the essence in the performance of the Parties" respective
                            obligations; if any time period specified herein is extended, such
                            extended time shall also be of the essence.
                          </span>

                        </p>
                        <p className="MsoNormal">
                          <span
                            lang="EN-GB"
                            style={{
                              fontSize: "12.0pt",
                              lineHeight: "115%",
                              fontFamily: '"Times New Roman",serif'
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                      </div>
                    </>

                  </>
                </>
              </div>
              <div
                className="acceptliecenceBorder"
              // style={{
              //   height: location?.pathname === ABOUTTERMS ? '60px !important' : '40px'
              // }}
              >
                {/* style={{ display: !userHasReviewed ? "none" : "block" }} */}
                <div>
                  <input
                    type="checkbox"
                    className="checkbox_custom mt-0 mb-0 "
                    id="exampleCheck3"
                    disabled={
                      !userHasReviewed || location?.pathname === ABOUTTERMS
                    }
                    value={value}
                    checked={
                      (EulaData?.EulaAcceptanceFlag === "1" ? true : false) ||
                      value
                    }
                    onChange={onInput}
                  />
                  {/*  {!userHasReviewed ? "Disabled" : "Non disabled"}    */}
                  <label for="exampleCheck3">
                    I accept the terms and conditions
                  </label>
                  {location?.pathname === ABOUTTERMS && (
                    <>
                      <span
                        style={{
                          fontSize: "14px",
                          // }} className='text-start ml-2 font-weight-bold'>{new Date().toLocaleString('en-US', { timeZoneName: 'shortGeneric' })}</span>
                        }}
                        className="text-start ml-2 exampleCheck3 font-weight-bold"
                      >
                        {EulaData?.LogDateTime}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            {location?.pathname === TERMSANDCONDITION && (
              <>
                <div className="termsAcceptButton mt-2">
                  <button
                    className="DataAccessEdit"
                    disabled={!value}
                    style={
                      value
                        ? { backgroundColor: "#0b70d1" }
                        : { backgroundColor: "gray" }
                    }
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                  <button className="DataAccessEdit ml-2" onClick={logout}>
                    Logout
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

