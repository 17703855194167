import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { activateOutputAPI, deactivateOutputAPI } from '../../services/alertAuthService';
import { ActivateIcon, DeactivateIcon } from '../../assets/images';

const OutputComponent = ({ item, panelSetting, setOutputMessage, setOutputStatus, allDisable, setAllDisable }) => {

    const [disableActivate, setDisableActivate] = useState(false);
    const [disableDeactivate, setDisableDeactivate] = useState(false)

    const activateOutput = (id) => {
        console.log(id);
        setDisableActivate(true);
        setAllDisable(true)
        setOutputMessage(`Output - ${id} activation command sent`)
        setOutputStatus(true);
        activateOutputAPI(panelSetting.PrivateIPAddress, panelSetting.Port, panelSetting.CamUserName, panelSetting.CamPassword, panelSetting.BrandName, id)
      .then((res) => {
        console.log(res);
        setDisableActivate(false);
        setAllDisable(false)
        setOutputMessage(`Output - ${id} activation command sent - Completed`)
        // setOutputStatus(true);
      }).catch(err => {
        console.log(err);
        setDisableActivate(false);
        setAllDisable(false)
        setOutputMessage(`Output - ${id} activation command sent - Completed`)
        // setOutputStatus(true);
      })
    }

    const deactivateOutput = (id) => {
        console.log(id);
        setAllDisable(true)
        setDisableDeactivate(true);
        setOutputMessage(`Output - ${id} deactivation command sent`)
        setOutputStatus(true);
        deactivateOutputAPI(panelSetting.PrivateIPAddress, panelSetting.Port, panelSetting.CamUserName, panelSetting.CamPassword, panelSetting.BrandName, id)
      .then((res) => {
        console.log(res);
        setDisableDeactivate(false);
        setAllDisable(false)
        setOutputMessage(`Output - ${id} deactivation command sent - Completed`)
        // setOutputStatus(true);

      }).catch(err => {
        console.log(err);
        setDisableDeactivate(false);
        setAllDisable(false)
        setOutputMessage(`Output - ${id} deactivation command sent - Completed`)
        // setOutputStatus(true);
      })
    }


    return (
        <div class="card">
            <p style={{ textAlign: "center", marginBottom: "5px" }}>{item.text}</p>
            <div style={{ textAlign: "center" }}>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            Activate
                        </Tooltip>
                    }
                >
                    <button className='DataAccessEdit' disabled={allDisable} onClick={() => activateOutput(item.id, panelSetting)}>
                        {/* <span className='ViewUpdate'>A</span> */}
                        <img src={ActivateIcon} className="EditedIcon" />
                    </button>
                </OverlayTrigger>

                <OverlayTrigger
                    placemIP Addressent='bottom'
                    overlay={
                        <Tooltip>
                            Deactivate
                        </Tooltip>
                    }
                >
                    <button className='DataAccessEdit' disabled={allDisable} onClick={() => deactivateOutput(item.id, panelSetting)}>
                        {/* <span className='ViewUpdate'>D</span> */}
                        <img src={DeactivateIcon} className="EditedIcon" />
                    </button>
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default OutputComponent