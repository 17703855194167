import React from 'react'
import { BackArrow } from '../../assets/images'

export default function ActionsList({ actions, onDrag, selectedUser, setSelectedUser }) {
    return <div className="AccordionActionBgDiv">
    <div id="accordion" className="accordion">
        <div className="mb-0 wf-card">
            <div className="collapsed AccordionActionCardHeader" data-toggle="collapse" href="#collapseOne">
                <p className="ActionText">Action Related to</p>
            </div>
            <div id="collapseOne" className="collapse show" data-parent="#accordion" >
                {
                    selectedUser == null &&
                    <>
                        <div className="BgDiv user-type" onClick={() => setSelectedUser(1)}>
                            <p>Operator</p>
                        </div>
                        <div className="BgDiv user-type" onClick={() => setSelectedUser(2)}>
                            <p>Field Responder</p>
                        </div>
                    </>
                }
                {
                    selectedUser &&
                    <a onClick={() => setSelectedUser(null)}> <img src={BackArrow} alt="" /></a>
                }
                {
                    selectedUser &&
                    actions &&
                    actions.map(item => {
                        return <div
                            className="BgDiv"
                            key={item.templateNodesMstID}
                            onDragStart={(event) => onDrag(event, 'default')}
                            draggable
                            id={item.templateNodesMstID}>
                            <p>{item.data.replaceAll("ERT", "FR")}</p>
                        </div>
                    })}
            </div>
        </div>
    </div>
</div>
}
