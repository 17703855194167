import React, { useCallback } from "react";

import { EditQc } from "../../../../assets/images";

const AssociateCameraOnSensor = ({
    selectedDevice,
    onSensorSelection,
    associatedDevices,
    onAssociate,
    selectedSensor,
    selectedItems,
    setSelectedItems,
  }) => {
    // Handle individual item selection
    const handleSelect = useCallback(id => {
      // If item is already selected, remove it from the selection
      if (selectedItems.includes(id)) {
        setSelectedItems(selectedItems.filter(itemId => itemId !== id));
      } else {
        // Otherwise add it to the selection
        setSelectedItems([...selectedItems, id]);
      }
    }, [selectedItems]);
    //********************** */
  
    return (
      <>
        {
          selectedDevice && selectedDevice.zonesList && selectedDevice.zonesList.length > 0 &&
          <div className="association-main-section zone-to-camera">
            <h1 className="MainHeaderName mb-0">Associate Zones to Cameras</h1>
            <div>
              <div className="association-section-header">
                <p className="mb-0" style={{ width: "40%" }}>
                  Zones
                  <br />
                </p>
                <p className="mb-0">
                  Cameras
                  <br />
                </p>
              </div>
  
              <div className="sensors-section">
                <div className="sensors-list">
                  {selectedDevice &&
                    selectedDevice.zonesList &&
                    selectedDevice.zonesList.map((item) => {
                      return (
                        <div key={item.zoneNo}>
                          <div
                            className={`cameraList user-list-item ${selectedSensor &&
                              item.zoneNo ==
                              selectedSensor?.zoneNo &&
                              "selected-user"
                              }`}
                            onClick={() =>
                              onSensorSelection(item, selectedDevice)
                            }
                          >
                            <p className="mb-0">{item.zoneName}</p>
                            <div style={{ float: "right" }}>
                              <img
                                height={12}
                                width={12}
                                src={EditQc}
                                className="m-1"
                                alt="edit icon"
                              ></img>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
  
              <div className="panel-camera-list">
                {associatedDevices &&
                  <>
                    {associatedDevices.map(item => (
                      <div className="selection-list-group cameraList" key={item.deviceId} >
                        <input type="checkbox"
                          key={item.deviceId}
                          label={item.cameraName}
                          checked={selectedItems.includes(item.deviceId)}
                          onChange={() => handleSelect(item.deviceId)}
                        />
  
                        <p className="ListofText"
                          onClick={() => handleSelect(item.deviceId)}
                        >{item.cameraName}</p>
                      </div>
                    ))}
                  </>
                }
              </div>
  
              <div className="m-2 text-right" style={{ textAlign: "end" }}>
                <button
                  className="NextMoveButton m-3 associateZone-associate-btn"
                  onClick={() => onAssociate(selectedDevice)}
                >
                  <span className="ViewUpdate">Associate</span>
                </button>
              </div>
            </div>
          </div>
        }
  
      </>
    );
  };

export default AssociateCameraOnSensor