import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkelSinglePlayback = () => {
  return (
    <>
      <Skeleton
        style={{ borderRadius: "13px", width: "100%", height: "100%" }} />
    </>
  )
}

export default SkelSinglePlayback