import { BASE_CORE_URL } from "../config"

//#region Api Calling for the Saving the TermsCondition
export const saveTermsCondition = async (dataObj) => {
    let url = `${BASE_CORE_URL}TermsCondition/Add`;

    const requestOptions = {
        method: "POST",
        headers: { 
            "Content-Type": "application/json", 
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken'),
        },
        body: JSON.stringify(dataObj),
    };

    return await fetch(url, requestOptions).then(resp => resp.json()).then(data => data).catch(error => console.log("T&C save error ", error));
}
//#endregion

//#region Api Calling for the getting the termsCondition
export const getTermsCondition = async (userId) => {
    let url = `${BASE_CORE_URL}TermsCondition/GetTermsCondition/${userId}`;

    const requestOptions = {
        method: "GET",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
    };

    return await fetch(url, requestOptions).then(resp => resp.json()).then(data => data).catch(error => console.log("T&C fetch error ", error));
}
//#endregion

//#region Api Calling for the Getting the MasterTerams Date
export const getMasterTermsDate = async (userId) => {
    let url = `${BASE_CORE_URL}TermsCondition/GetEulaMasterDate/${userId}`;

    const requestOptions = {
        method: "GET",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
    };

    return await fetch(url, requestOptions).then(resp => resp.json()).then(data => data).catch(error => console.log("T&C fetch error ", error));
}
//#endregion