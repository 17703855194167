import React, { useEffect, useState } from "react"

import { MediaIconDisabled, DeleteShape, FileIcon, CameraIconEvidence, Search1Icon, MiceIcon, AlertBGIcon, CameraBGIcon, MiceBGIcon, MediaIcon } from '../../assets/images'

import { attachalertevidences, attachreferencealerts, deletealertevidences, createalertcomment } from "../../services/alertAuthService"
import { getAlertDetailsById } from "../../services/index";

import useRecorder from './UseRecorder.js'
import {ErrorToast} from '../../../src/CustomHooks/ErrorToast'
import {SuccessToast} from '../../../src/CustomHooks/SuccessToast'

import { Modal } from 'react-bootstrap'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Swal from 'sweetalert2'

import { ConvertDate } from '../../utils/utils'

// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
let timeout_status = 0

export default function AlertEvidenceComponent(props) {
    //useState Start Block
    const [isrecording, setisrecording] = useState(false)
    const [isFileSelected, setisFileSelected] = useState(false)
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFilename, setSelectedFilename] = useState("");
    const [evidencename, setevidencename] = useState('')
    const [evidencetype, setevidencetype] = useState('')
    const [evidenceurl, setevidenceurl] = useState([])
    const [evidencedocs, setevidencedocs] = useState([])
    const [isOpenmodal, setisOpenmodal] = useState(false)
    const [isimage, setisimage] = useState(false)
    const [isdoc, setisdoc] = useState(false)
    const [isVideo, setisVideo] = useState(false)
    const [alertremark, setalertremark] = useState("")
    const [tempevidencelist, settempevidencelist] = useState([])
    const [alertId, setAlertId] = useState('')
    const [attachDisabled, setAttachDisabled] = useState(false);
    // useState End Block 

    let LoggedInUser = JSON.parse(localStorage.getItem('user'))
    let isrecordingtxt = "Voice Notes"
    let [audio, audioURL, isRecording, audioSize, startRecording, stopRecording] = useRecorder();

    //useEffect Start Block
    useEffect(() => {
        setalertremark(props.AlertDetails.alertComment)
        if (props.AlertDetails.refAlertsIds != null) {
            var lst = props.AlertDetails.evidenceList
            var referencealertslist = props.AlertDetails.refAlertsIds.split(",")
            var reflst = []
            referencealertslist.map((item) => {
                var lstobj = {
                    "AttachedDocTitle": "Alert Id : " + item,
                    "DocumentURL": "Alert",
                    "IncReportDocsID": parseInt(item),
                    "CreatedBy": LoggedInUser.UserID,
                }
                reflst.push(lstobj)
            })

            lst = [...reflst, ...lst]
            settempevidencelist(lst)
        } else {
            settempevidencelist(props.AlertDetails.evidenceList)
        }
    }, [props])

    useEffect(() => {
        if (audio != null) {
            onAttachOperatorResponse(audio)
            setisrecording(false)
        }
    }, [audio])

    useEffect(() => {
        if (isrecording) startRecording()
        else stopRecording()
    }, [isrecording])

    //useEffect End Block

    //#region Attch evidence methods

    const handleChange = (e) => {
        //* for uploading the documents in the mozilla firefox
        //* e.path[0].files => Google Chrome
        //* e.target.files = > Mozilla firefox
        var filetarget = e.target == null ? e.path[0].files : e.target.files
        if (filetarget.length > 0) {
            if (filetarget[0].type == 'image/jpg' ||
                filetarget[0].type == 'image/png' ||
                filetarget[0].type == 'image/jpeg' ||
                filetarget[0].type == 'application/pdf' ||
                filetarget[0].type == 'text/plain' ||
                filetarget[0].type == 'video/mp4' ||
                filetarget[0].type == 'video/mov' ||
                filetarget[0].type == 'video/avi' ||
                filetarget[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                filetarget[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                if (filetarget[0].size < 5242880) {
                    setSelectedFile(filetarget[0]);
                    setSelectedFilename(filetarget[0].name)
                    setisFileSelected(true)
                } else {
                    Swal.fire({ text: 'File size should be less than 5MB.' })
                    return
                }
            }
            else {
                Swal.fire({ text: 'Please attach documents of the mentioned file types.' })
                return
            }
        }
    }

    const ChangeImageEvidenceFileHandler = (event) => {
        try {
            const fileSelector = document.createElement('input');
            fileSelector.setAttribute('type', 'file');
            fileSelector.setAttribute('accept', '.png,.jpg,.jpeg');
            //fileSelector.setAttribute('multiple', 'multiple');
            fileSelector.addEventListener("change", handleChange);
            fileSelector.click();
        } catch (error) {
            console.error(error)
        }
    }

    const ChangeVideoEvidenceFileHandler = (event) => {
        try {
            const fileSelector = document.createElement('input');
            fileSelector.setAttribute('type', 'file');
            fileSelector.setAttribute('accept', '.mp4,.mov,.avi,.wmv');
            fileSelector.addEventListener("change", handleChange);
            fileSelector.click();
        } catch(error) {
            console.error(error)
        }
    }

    const ChangeDocumentEvidenceFileHandler = (event) => {
        try {
            const fileSelector = document.createElement('input');
            fileSelector.setAttribute('type', 'file');
            //fileSelector.setAttribute('accept', '.pdf, .docx, .xlsx, .txt');
            fileSelector.setAttribute('accept', '.pdf, .docx, .txt');
            //fileSelector.setAttribute('multiple', 'multiple');
            fileSelector.addEventListener("change", handleChange);
            fileSelector.click();
        } catch (error) {
            console.error(error)
        }
    };

    const handleSubmission = (e) => {
        setAttachDisabled(true);
        const formData = new FormData();
        formData.append('File', selectedFile);
        onAddFileCallback(selectedFile)
    };

    const onAddFileCallback = (files) => {
        try {
            let newfile = files[files.length == 0 ? 0 : files.length - 1]
            let reader = new FileReader()
            reader.readAsDataURL(files);
            reader.onload = (e) => {
                Attachalertevidences(e.target.result, selectedFile.name)
                //return attachEvidence(e, selectedFile.name)
            }
        } catch (error) {
            console.error(error)
            setAttachDisabled(false);
        }
    }

    const Attachalertevidences = (e, fname) => {
        try {
            const fnames = fname.replace(/[^a-zA-Z0-9.]/g, '').toLowerCase();
            var existingevi = tempevidencelist.find(x => x.AttachedDocTitle.replace(/[^a-zA-Z0-9.]/g, '').toLowerCase() === fnames)
            if (existingevi) {
                Swal.fire({ text: 'File with same name already attached' })
                setAttachDisabled(true);
                return
            }
            var obj = {
                "evidenceFileName": fname,
                "alertId": props.AlertDetails.alertId,
                "userId": LoggedInUser.UserID,
                "base64String": e
            }
            attachalertevidences(obj).then((resp) => {
                if (resp.status === "Ok") {
                    setisFileSelected(false)
                    props.OnEvidenceAttached()
                    Swal.fire({ text: resp.message })
                    setAttachDisabled(false);
                }
                else {
                    console.error('Error while attaching the evidence ', resp.message)
                    Swal.fire({ text: resp.message })
                    setAttachDisabled(false);
                }
            })
        } catch (error) {
            console.error(error)
            setAttachDisabled(false);
        }
    }

    //#endregion

    //#region Voice notes



    const setLimit = () => {
        timeout_status = setTimeout(function () {
            //alert("10 seconds timeout")
            stopRecording()

            //onAttachOperatorResponse(audio)
            //setisrecording(!isrecording)
        }, 10000);
    }

    const RecordingStart = () => {
        try {
            //setisrecordingtxt("Recording...")
            isrecordingtxt = "Recording..."
            setisrecording(true)
            startRecording()
            setLimit()
        } catch (error) {
            console.error(error)
        }
    }

    const RecordingStop = () => {
        try {
            stopRecording()
        } catch (error) {
            console.error(error)
        }
    }

    const onAttachOperatorResponse = (data) => {
        var reader = new FileReader();
        reader.onload = function () {
            Attachalertevidences(reader.result, `OperatorResponse_${props.AlertDetails.alertId}_${new Date().getTime()}.mp3`)
        };
        reader.readAsDataURL(data);
    }

    //#endregion

    function EvidenceOnClick(title, url) {
        try {
            if (url == "Alert") {
                var alertid = title.split("Alert Id : ")
                props.OnReferenceAlertClick(alertid[1])
                return
            } else {
                setevidencename(title)
                setevidenceurl(url)
                setisOpenmodal(true)
                const docs = [
                    { uri: url }
                ]
                setevidenceurl(url)
                setevidencedocs(docs)
                var _type = title.split('.')[1]
                setevidencetype(_type)
                if (_type == "png" || _type == "jpeg") {
                    setisimage(true)
                    setisdoc(false)
                } else if (_type == "mp3" || _type == "mp4" || _type == "wav") {
                    setisimage(false)
                    setisdoc(false)
                }
                 else if (_type == "mp4" || _type == "mov" || _type == "avi") {
                    setisVideo(true)
                    setisimage(false)
                    setisdoc(false)
                }
                else {
                    setisimage(false)
                    setisdoc(true)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const ClosePreview = () => {
        setisOpenmodal(false)
    }

    //#region Alert attachment
    const onAttachAlert = () => {
        if (isNaN(alertId) || alertId.trim() === "") {
            Swal.fire({ text: 'Please enter valid alert ID, e.g. 3690.', confirmButtonColor: '#027aaa' })
            return;
        }

        if (alertId.trim() == props.AlertDetails.alertId) {
            Swal.fire({ text: 'Cannot attach an alert to itself.', confirmButtonColor: '#027aaa' })
            return;
        }

        if (isAlertAlreadyPresent(alertId)) {
            Swal.fire({ text: `Alert-${alertId} is already attached.` })
            return;
        }

        getAlertDetailsById(alertId).then((value) => {
            if (value.status == "Ok") {
                if (value.message.length > 0) {
                    let myCurrentDatetime = new Date();
                    let datetime = ConvertDate(myCurrentDatetime)
                    let attachAlertObj = {
                        "alertId": props.AlertDetails.alertId,
                        "userId": LoggedInUser.UserID,
                        "referenceAlert": alertId,
                        "alertTime": datetime
                    }
                    attachreferencealerts(attachAlertObj).then((attachedAlertResp) => {
                        if (attachedAlertResp.status == "Ok") {
                            setAlertId('')
                            props.OnEvidenceAttached()
                        } else if (attachedAlertResp.status === "Failed") {
                            Swal.fire({ text: attachedAlertResp.message })
                            return;
                        }
                    })
                } else {
                    Swal.fire({ text: 'Alert not found!', confirmButtonColor: '#027aaa' })
                    return;
                }
            }
            else if (value.status === "Failed") {
                Swal.fire({ text: value.message, confirmButtonColor: '#027aaa' })
                return;
            }
        })
    }

    const isAlertAlreadyPresent = (alertid) => {
        if (props.AlertDetails.refAlertsIds && props.AlertDetails.refAlertsIds.includes(alertid)) {
            return true;
        }
        return false;
    };
    
    //#endregion
    const DeleteEvidence = (item) => {
        try {
            Swal.fire({
                icon: 'question',
                title: 'Are you sure? Do you want to delete the evidence?',
                showCancelButton: true,
                confirmButtonText: `Delete`,
                confirmButtonColor: 'red',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    let obj = {
                        "attachedDocTitle": item.AttachedDocTitle,
                        "alertId": props.AlertDetails.alertId,
                        "userId": item.CreatedBy,
                        "evidenType": ""
                    }
                    if (item.AttachedDocTitle.includes("Alert Id : ")) {
                        var alertid = item.AttachedDocTitle.split("Alert Id : ")
                        obj.attachedDocTitle = alertid[1]
                        obj.evidenType = "ReferenceAlert"
                        obj.userId = item.CreatedBy
                    } else {
                        obj.evidenType = "Evidence"
                    }
                    deletealertevidences(obj).then((resp) => {
                        if (resp.status == "Ok") {
                            Swal.fire({
                                icon: 'success',
                                title: 'Evidence Deleted',
                            })
                            props.OnEvidenceAttached()
                        } else if (resp.status === "Failed") {
                            Swal.fire({ text: resp.message })
                            return;
                        }
                    })
                }
            })

        } catch (error) {
            console.error(error)
        }
    }

    const Addremarks = () => {
        try {
            var remark = alertremark.trim()
            if (remark == "") {
                ErrorToast("Please enter your Remarks/Comments!");
                return
            }
            let myCurrentDatetime = new Date();
            let datetime = ConvertDate(myCurrentDatetime)
            let obj = {
                "Comment": remark,
                "AlertID": props.AlertDetails.alertId,
                "CreatedOn": datetime,
                "CreatedBy": LoggedInUser.UserID
            }
            createalertcomment(obj).then((resp) => {
                if (resp.status == "Ok") {
                    props.OnEvidenceAttached()
                    SuccessToast(resp.message)
                } else if (resp.status === "Failed") {
                    ErrorToast(resp.message)
                    return;
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <Modal
                show={isOpenmodal}
                dialogClassName={"activityUpdateModel"}
                onHide={ClosePreview}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton style={{ padding: "0 1rem", borderBottom: "0" }}><span className="dashboard_configuration_templateName">{evidencename}</span></Modal.Header>
                <Modal.Body>
                    <div id="EvidenceViewContainer">
                        {/* {evidencetype === "png" || evidencetype === "jpeg" && */}
                        <div style={{ display: (isimage ? 'block' : 'none') }}>
                            <img
                                style={{ height: "500px", width: "465px" }}
                                src={evidenceurl}
                                alt="EvidenceImg"
                            />
                        </div>
                        {/* // } */}

                        <div style={{ display: (isdoc === true ? 'block' : 'none') }}>
                            {/* this part is creating issue just check it once u try to upload file. Commenting this area will show image. */}
                            {
                                (evidencetype === "pdf" || evidencetype === "txt") ?
                                    <iframe
                                        style={{ height: "500px", width: "465px" }}
                                        src={evidenceurl}
                                    />
                                    :
                                    <DocViewer
                                        config={{
                                            header: {
                                                disableHeader: true,
                                                disableFileName: true,
                                                retainURLParams: true
                                            }
                                        }}
                                        style={{ height: "500px", width: "465px" }}
                                        className="my-doc-viewer-style"
                                        pluginRenderers={DocViewerRenderers}
                                        documents={evidencedocs}
                                    />
                            }
                        </div>
                        {(evidencetype === "mp3" || evidencetype === "wav") &&
                            <div>
                                <audio src={evidenceurl} controls />
                            </div>
                        }
                        {(evidencetype === "mp4" || evidencetype === "mov" || evidencetype === "avi") &&
                            <div>
                                <video src={evidenceurl} controls height={"100%"} width={"100%"} />
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={props.ismodalOpen}
                onHide={props.handleClose}
                backdrop="static"
                // keyboard={false}
                className="alertevidenceModel"
            >
                <Modal.Header closeButton className="alrtevidencemodalheader">Attach Evidences</Modal.Header>
                <Modal.Body>
                    <div className="UploadFilesDiv">

                        <p className="UploadText">Upload Files</p>
                        <div className="mt-3 upload-options" style={{ textAlign: "center" }} >

                            <div className="DocBox" title="Only .pdf, .docx, .txt type files accepted" onClick={ChangeDocumentEvidenceFileHandler}>
                                <img src={FileIcon} type="file" name="file" alt="file icon"/>
                                <p className="DocText">Documents</p>
                                <p className="uploadTxt">(.pdf, .docx, .txt)</p>
                            </div>

                            <div className="ImageBox" title="Only .png, .jpg, .jpeg image formats accepted" onClick={ChangeImageEvidenceFileHandler}>
                                <img src={CameraIconEvidence} type="file" name="file" alt="camera icon"/>
                                {/* <input type="file" name="file" onChange={ChangeEvidencFileHandler} /> */}
                                <p className="DocText">Images</p>
                                <p className="uploadTxt">(.png, .jpg, .jpeg)</p>
                            </div>
                            {/* disabled */}
                            <div className="VideosBox" title="Only .mp4, .mov, .jpg,  video formats accepted" onClick={ChangeVideoEvidenceFileHandler}>
                                <MediaIconDisabled fill={'#bfbbbb'} />
                                <p className="DocText">Videos</p>
                                <p className="uploadTxt">(.mp4,.mov,.avi)</p>
                            </div>
                            <div className="VoiceBox" onClick={() => setisrecording(!isrecording)} >
                                <div className={isrecording && "Blink"}>
                                    <img src={MiceIcon} alt="audio icon"/>
                                </div>
                                <p className="DocText">{isrecordingtxt}</p>
                                <p className="uploadTxt">(.mp3)</p>
                            </div>
                            
                        </div>
                        <div className="Maxsize">Max file size: 5MB each</div>
                        {
                            isFileSelected &&
                            <div className="mt-2 attach-file-div">
                                <div className="UploadText" >
                                    <p className="selected-file-text">
                                        <span>
                                            <strong>Selected File :</strong>
                                        </span>
                                        <span className="ml-1" title={selectedFilename}>
                                            <>{selectedFilename}</>
                                        </span>
                                    </p>
                                </div>
                                <button className="AttachEvidencesBigButton"  onClick={() => handleSubmission()}>
                                    <p>Attach</p>
                                </button>
                            </div>
                        }
                        <div className="mt-2">

                            <p className="UploadText">Attach Alerts (optional)</p>
                            <div 
                            className="SelectionListWithSelectAll"
                            >
                                <input
                                    type="text"
                                    className="SearchViewInput1 searchNotification"
                                    placeholder="Search or copy/paste the Alert ID to find and attach alerts, e.g. 3690."
                                    value={alertId}
                                    onChange={e => setAlertId(e.target.value)}
                                />
                                <img className="icon-hover" alt="search icon" src={Search1Icon} onClick={() => onAttachAlert()} />
                            </div>
                        </div>
                        <div className="mt-3">
                            <p className="UploadText">Remarks</p>
                            <textarea
                                className="UploadTextarea"
                                name=""
                                rows="4"
                                cols="50" value={alertremark}
                                placeholder="Remarks, e.g. Unable to contact the Field Responder."
                                onChange={e => setalertremark(e.target.value)}></textarea>
                            <button className="AttachEvidencesBigButton" onClick={() => Addremarks()}>
                                <p>Save</p>
                            </button>
                        </div>
                        <div className="mt-3">
                            <p className="UploadText">Attached Evidences</p>
                            <span className="GreenText">{tempevidencelist.length} attachments</span>
                            <div className="AttachmentsDiv mt-2">
                                <div className="mainContentList">
                                    {
                                        tempevidencelist &&
                                        tempevidencelist.length > 0 &&
                                        (tempevidencelist.map((item) => (
                                            <div className="ListAttach" key={item.IncReportDocsID}>
                                                <div className="evidence-item" style={{ width: "80%" }}>
                                                    {
                                                        (item.AttachedDocTitle.includes(".png") ||
                                                            item.AttachedDocTitle.includes(".jpg") ||
                                                            item.AttachedDocTitle.includes(".jpeg"))
                                                            ?
                                                            <img id={"evidenceimg_" + item.IncReportDocsID} src={CameraBGIcon} alt="camera bg icon" />
                                                            :
                                                            (item.AttachedDocTitle.includes(".mp3") ||
                                                                item.AttachedDocTitle.includes(".wav"))
                                                                ?
                                                                <img id={"evidenceimg_" + item.IncReportDocsID} src={MiceBGIcon} alt="mice icon" />
                                                                :
                                                                (item.AttachedDocTitle.includes(".mp4") ||
                                                                 item.AttachedDocTitle.includes(".mov") ||
                                                                 item.AttachedDocTitle.includes(".avi"))
                                                                 ?
                                                                 <img id={"evidenceimg_" + item.IncReportDocsID} src={MediaIcon} alt="media icon"/>
                                                                 :
                                                                (item.AttachedDocTitle.includes("Alert Id : "))
                                                                    ?
                                                                    <img id={"evidenceimg_" + item.IncReportDocsID} src={AlertBGIcon} alt="alert icon" />
                                                                    :
                                                                    <img id={"evidenceimg_" + item.IncReportDocsID} height="20" width="22" style={{ marginRight: '0.1rem' }} src={FileIcon} alt="file icon" />
                                                    }
                                                    <p id={"txt_" + item.IncReportDocsID} className="ListAttchP ml-1 dashboard_configuration_templateName" onClick={() => EvidenceOnClick(item.AttachedDocTitle, item.DocumentURL)}>{item.AttachedDocTitle}</p>
                                                </div>
                                                <img id={"del_" + item.IncReportDocsID} className="icon-hover" src={DeleteShape} onClick={() => DeleteEvidence(item)} alt="delete icon"/>
                                            </div>
                                        )))
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}