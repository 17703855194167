import React from "react";

import { CancelWhiteIcon } from "../../assets/images";

import { Rnd } from "react-rnd";

import WebRTCPlayer from "../Cameras/WebRTCPlayer";
import { CHAT_CAM_CHANGES } from "../../config";

const ChatbotAssociateCamera = ({ openButton1, setOpenButton1, alertDetail, associateCam, setAssociateCam }) => {
  return (
    <>
      {openButton1 && (
        <Rnd
          default={{
            x: 650,
            y: 205,
            width: 600,
            height: 400,
          }}
          minWidth={650}
          minHeight={400}
          bounds="window"
          dragHandleClassName={"title"}
          enableResizing={true}
        >
          <div
            className="box"
            style={{ margin: 0, height: "100%", width: "100%" }}
          >
            <div
              className="title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 className="mb-0">Alert ID: {alertDetail.alertId}</h2>
              <button className="LinkButtonView" onClick={
                () => {
                  if (CHAT_CAM_CHANGES) {
                    let d = associateCam.map((item) => {
                      let obj;
                      if (item.alertId == alertDetail.alertId) {
                        obj = {
                          ...item,
                          display: false
                        }
                      } else {
                        obj = {
                          ...item
                        }
                      }
                      return obj;
                    });
                    setAssociateCam(d);
                  } else {
                    setOpenButton1(false)
                  }
                }

              }><img alt="Cancel" src={CancelWhiteIcon} /></button>
            </div>
            <div className="Associate-row">
              <div
                className={alertDetail?.associatedCamList?.length == 3 || alertDetail?.associatedCamList?.length == 4
                  ? "resize2x2"
                  : alertDetail?.associatedCamList?.length == 2
                    ? "resizeTwoView"
                    : "resizeOneView"
                }
              >

                {
                  alertDetail?.associatedCamList?.length == 0
                    ?
                    <>
                      <WebRTCPlayer
                        key={alertDetail.deviceId}
                        id={alertDetail.deviceId + "" + alertDetail.alertId}
                        devId={alertDetail.deviceId}
                        url={alertDetail.rtspUrl}
                        isEncode={alertDetail.isPlaybackTranscoded}
                        // isEncode={0}
                        item={alertDetail}
                        upperDivCss="HeightChildDiv"
                        // videoCss="player-responsive1"
                        videoCss="mosaic"
                        fullVideoCss="player-responsive-fullScreen"
                        buttonCss="cameraButtonsMainDiv"
                        actualTime={alertDetail.actualTime}
                        alertDetail={alertDetail}
                        playback={true}
                        prePostDisplay={true}
                        liveButton={true}
                        noStreamDivCss="HeightChildDiv"
                        exportCurrentVideoHide={true}
                        showPlaybackBtn={false}
                        associateCam={associateCam}
                        setAssociateCam={setAssociateCam}
                        showDirectToChat={true}
                      />
                    </>

                    :
                    alertDetail?.associatedCamList?.map((item) => (
                      <>
                        <WebRTCPlayer
                          key={item.associatedCameraId}
                          id={item.associatedCameraId + "" + alertDetail.alertId}
                          devId={item.associatedCameraId}
                          url={item.rtspUrl}
                          isEncode={item.isPlaybackTranscoded}
                          // isEncode={0}
                          item={item}
                          upperDivCss="AssoHieghtChildDiv"
                          videoCss="mosaic"
                          fullVideoCss="player-responsive-fullScreen"
                          buttonCss="cameraButtonsMainDiv"
                          noStreamDivCss="AssoHieghtChildDiv"
                          actualTime={alertDetail.actualTime}
                          alertDetail={alertDetail}
                          playback={true}
                          prePostDisplay={true}
                          liveButton={true}
                          exportCurrentVideoHide={true}
                          showPlaybackBtn={false}
                          associateCam={associateCam}
                          setAssociateCam={setAssociateCam}
                          showDirectToChat={true}
                        />
                      </>
                    ))
                }
              </div>
            </div>
          </div>
        </Rnd>
      )}
    </>
  );
};

export default ChatbotAssociateCamera;