import React, { useEffect, useState } from 'react'
import '../../../app.css'
import rigntimage from '../../../assets/images/signUpImage.png'
import rightTick from '../../../assets/images/rightTick.png'
import Success from '../../../assets/images/success.png'
import ReactDOM from 'react-dom';
import Step1Form from './Step1Form'
import Step2Form from './Step2Form'
import Step3Form from './Step3Form'
import { useHistory } from 'react-router-dom'
import { RegisterUser } from '../../../services/userAuthServices'
import { useAuth } from '../ProvideAuth'
import LeftArrow from '../../../assets/images/LeftArrow.png'

export default function Signup() {

    let history = useHistory();
    let auth = useAuth();

    const form1obj = {
        "FirstName": "",
        "LastName": "",
        //"Country":"India",
        "Zipcode": "",
        "City": "",
        "Address": "",
        "Phone1": ""
    }
    const form2obj = {
        "Email": "",
    }
    const [form1values, setform1values] = useState(form1obj)
    const [form2values, setform2values] = useState(form2obj)
    const [form3values, setform3values] = useState(null)

    const initialstep = {
        step: 1,
        inputValues: form1obj
    }
    const [stepstate, setstepstate] = useState(initialstep)

    const Formcomponent = (step) => {
        try {
            switch (step) {
                case 1:
                    return <Step1Form
                        initialValues={stepstate.inputValues}
                        OnSubmit={Onformsubmit} />
                case 2:
                    return <Step2Form
                        initialValues={stepstate.inputValues}
                        OnSubmit={Onformsubmit} />
                case 3:
                    return <Step3Form
                        initialValues={stepstate.inputValues}
                        OnSubmit={Onformsubmit} />
                case 4:
                    return <SignUpSuccessPage />
            }
        } catch (error) {
            console.error(error)
        }
    }

    const SignUpUser = async () => {
        try {
            var userobj = {
                "FirstName": form2values["FirstName"],
                "LastName": form2values["FirstName"],
                "UserName": form2values["FirstName"],
                "UPassword": form3values["UPassword"],
                "Email": form2values["Email"],
                "Phone1": null,
                "Phone2": null,
                "DOB": null,
                "City": form2values["City"],
                "Country": null,
                "DPName": null,
                "DPPath": null,
                "Zipcode": form2values["Zipcode"],
            }
            await auth.signup(form2values["Email"], form3values["UPassword"]).then(async (res) => {
               await RegisterUser(userobj).then((user) => {
                    if (user.status === "Ok") {
                    }
                })
            })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        try {
            Changeselectedstepstyle(stepstate.step)
            var ele = Formcomponent(stepstate.step)
            if (stepstate.step == 4) {
                ReactDOM.render(ele, document.getElementById("succesdiv"))
                SignUpUser()
            } else {
                ReactDOM.render(ele, document.getElementById("multistepform"))
            }

        } catch (error) {
            console.error(error)
        }
    }, [stepstate])

    const Changeselectedstepstyle = (id) => {
        try {
            if (id != 4) {
                document.getElementById("Button-" + id).setAttribute("class", "btn progressGreen btn-circle");
                document.getElementById("p-" + id).setAttribute("class", "progressGreenText");
            }
            if (id != 1) {
                document.getElementById("Button-1").setAttribute("class", "btn btn_defaultProgress btn-circle");
                document.getElementById("p-1").setAttribute("class", "");
            }
            if (id != 2) {
                document.getElementById("Button-2").setAttribute("class", "btn btn_defaultProgress btn-circle");
                document.getElementById("p-2").setAttribute("class", "");
            }
            if (id != 3) {
                document.getElementById("Button-3").setAttribute("class", "btn btn_defaultProgress btn-circle");
                document.getElementById("p-3").setAttribute("class", "");
            }
        } catch (error) {
            console.error(error)
        }
    }

    const ToggleSteps = (val) => {
        try {
            switch (val) {
                case 1:
                    Changeselectedstepstyle(1)
                    setstepstate({
                        step: 1,
                        inputValues: form1values
                    })
                    return
                case 2:
                    Changeselectedstepstyle(2)
                    setstepstate({
                        step: 2,
                        inputValues: form2values
                    })
                    return
                case 3:
                    Changeselectedstepstyle(3)
                    setstepstate({
                        step: 3,
                        inputValues: form3values
                    })
                    return
            }
        } catch (error) {
            console.error(error)
        }
    }

    const Onformsubmit = (formdata) => {
        try {
            switch (stepstate.step) {
                case 1:
                    Changeselectedstepstyle(1)
                    setstepstate({
                        step: 1 + 1,
                        inputValues: formdata
                    })
                    setform1values(formdata)
                    return
                case 2:
                    Changeselectedstepstyle(2)
                    setstepstate({
                        step: 2 + 1,
                        inputValues: formdata
                    })
                    setform2values(formdata)
                    //Changeselectedstepstyle(2)
                    return
                case 3:
                    Changeselectedstepstyle(3)
                    setstepstate({
                        step: 3 + 1,
                        inputValues: formdata
                    })
                    setform3values(formdata)
                    return
            }
        } catch (error) {
            console.error(error)
        }
    }

    const SignUpSuccessPage = () => (
        <>
            <img src={Success} />
            <p className="congratulationText">Congratulations!</p>
            <p className="successfulText">
                You have signed up successfully. we have sent you an email for verification.
                Wish you have a nice experience.
        </p>
            <button type="submit" className="signUpBtn" onClick={() => history.replace('/login')}>Sign In</button>
        </>
    )

    return (
        <div className="container-fluid p-0">
            <div className="signUpLeftSide">
                {/* <img src={leftimage} className="signUpmainLogo" /> */}
                <div className="signUpFormMain">
                    <div id="succesdiv" className="signUpFormCard">
                        <div className="signUpHeading signup-header">
                            <img className="cursor-ptr" src={LeftArrow} onClick={() => history.replace('/login')} />
                            <p className="signUpHeading m-0 ml-2">Create your account</p>
                        </div>
                        <div className="signUpProgress">
                            <div className="stepwizard">
                                <div className="stepwizard-row">
                                    <div className="stepwizard-step">
                                        <button id="Button-1" type="button" className="btn progressGreen btn-circle"><img src={rightTick} onClick={() => ToggleSteps(1)} /></button>
                                        <p id="p-1" className="progressGreenText">Step 1</p>
                                    </div>
                                    <div className="stepwizard-step">
                                        <button id="Button-2" type="button" className="btn btn_defaultProgress btn-circle"><img src={rightTick} onClick={() => ToggleSteps(2)} /></button>
                                        <p id="p-2">Step 2</p>
                                    </div>
                                    <div className="stepwizard-step">
                                        <button id="Button-3" type="button" className="btn btn_defaultProgress btn-circle" ><img src={rightTick} onClick={() => ToggleSteps(3)} /></button>
                                        <p id="p-3">Step 3</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="multistepform">

                        </div>
                    </div>
                </div>
            </div>
            <div className="signUpRightSide">
                <img src={rigntimage} />
            </div>
        </div>
    )
}