//login pages start
export const ROOT = "/";
export const LOGIN = "/login";
//login pages end

// alert pages start
export const ALERTS = "/alerts";
export const ALLALERTS = "/allalerts";
export const MYALERTS = "/myalerts";
export const ALERTDETAILS = "/alertdetails/:id";
export const ALERTGRIDVIEW = "/alertgridview";
//alert pages end

export const SIGNOUT = "/signout";
export const DASHBOARD = "/dashboard";
export const HOMEPAGE = "/homepage";
export const SIGNUP = "/signup";
export const FORGETPASSWORD = "/forgetpassword/:emailid";
export const VERIFICATION = "/verification/:email";
export const RESETPASSWORD = "/resetpassword/:emailid";
export const CONFIGURATION = "/configuration";
export const CONFIG_MANAGESITEGROUPS = "/configuration/managesitegroups";
export const CONFIG_ALERTTEMPLATE = "/configuration/addalerttemplate";
export const CONFIG_WORKFLOW = "/configuration/manageworkflow";
export const CONFIG_USER = "/configuration/adduser";
export const CONFIG_FR = "/configuration/frassignment";
export const CONFIG_DATACCESSGROUP = "/configuration/dataaccessgroup";
export const CONFIG_SOUND = "/configuration/sound";
export const CONFIG_PREPOST = "/configuration/prepost";
export const CONFIG_NOTIFICATION = "/configuration/notification";
export const ALLNOTIFICATIONS = "/allnotifications";
export const WORKFLOW = "/workflow/:id";
export const AUDITLOG = "/auditlog/:id";

//tej Reports S
export const REPORTS = "/alertactivity";
export const ALERTACTIVITYREPORT = "/alertactivityreport";
export const ALERTARMDISARMREPORT = "/alertarmdisarmreport";
export const EVENTACTIVITYREPORT = "/eventactivity";
export const PANELACTIVITY = "/panelactivity";
export const CUSTOMERACTIVITY = "/customeractivity";
export const CCTVACTIVITY = "/cctvactivity";
export const CUSTOMERSITEACTIVITY = "/customersiteactivity";
export const OPERATORALERTREPORT = "/operatoractivity";
export const DAILYHEALTHCHECKREPORT = "/dailyhealthcheckactivity";
export const PANELUPDOWNREPORT = "/panelupdownactivity";
export const ATMSITEREPORT = "/atmsiteactivity";
export const SAMPLEREPORT = "/sampleactivity";
export const AVAILABLERECORDINGREPORT = "/availblerecordingactivity"

//Reports E

//tej Camera S
export const CAMERAS = "/cameras/true";
export const CAMERA_SINGLE_PLAYBACK = "/playback";
export const SYNCREPLAY = "/syncreplay/:devicelist";
export const MOSAIC = "/mosaic";
export const ALERT_NEW_VIEW = "/alertnewview"
export const CAMERASOLD ="/oldcameras"
//Camera E

export const PROFILE = "/profile";
export const CHATFLOW = "/chatflow/:alertId";
export const ALERTREPORT = "/alertreport/:alertId";
export const DOWNLOAD = "/download";

export const PLAYER = '/player-test'

export const ONE_MINUTE = 60000;
export const FIVE_MINUTES = 5 * 60 * 1000;
export const TEN_MINUTES = 10 * 60 * 1000;
export const THIRTY_MINUTES = 30 * 60 * 1000;

export const HEALTHMONITORING = "/healthmonitoring";
export const DISPLAYSITE = "/healthmonitoring/SiteName";

export const NOTIFICATON = "/notification";

export const PAGE = "/page"

export const ABOUTMH = '/about';
export const ABOUTSUPPORT = '/support';
export const ABOUTTERMS = '/termscondition';

export const TERMSANDCONDITION = '/termsandcondition'

export const PANELCONTROL = '/panelcontrol';