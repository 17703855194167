import React, { useEffect, useState } from "react"

import { SendIcon, MikeIcon, AttachEvidenceChatIcon } from '../../assets/images';

import { attachalertevidences } from "../../services/alertAuthService"
import { addrasaconversationindb } from "../../services/chatFlowService";
import { ConvertDate } from '../../utils/utils'
import useRecorder from '../Alerts/UseRecorder'
import { ErrorToast } from "../../CustomHooks/ErrorToast";

export default function ChatflowEvidenceAttachment(props, item, chat) {
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))

    //#region useStart start
    const [alertremark, setalertremark] = useState("")
    const [isrecording, setisrecording] = useState(false)
    const[buttonDisable,setButtonDisable]=useState(false)
    //#endregion useState end

    let [audio, audioURL, isRecording, audioSize, startRecording, stopRecording] = useRecorder();

    //#region useEffect start
    useEffect(() => {
        if (audio != null) {
            onAttachOperatorResponse(audio)
            setisrecording(false)
        }
    }, [audio])

    useEffect(() => {
        if (isrecording) startRecording()
        else stopRecording()
    }, [isrecording])

    useEffect(() => {
        if (props.capturedImage) {
            let fileData = props.capturedImage;
            let fname = props.brandName + "_" + Date.now() + ".png";
            Attachalertevidences(fileData, fname);
        }
        return () => {
            //   second
        }
    }, [props.capturedImage])
    //#endregion useEffect end

    //#region functions start
    const ChangeEvidenceFileHandler = (event) => {
        try {
            const fileSelector = document.createElement('input');
            fileSelector.setAttribute('type', 'file');
            fileSelector.setAttribute('accept', '.pdf, .docx, .xlsx, .txt, .png, .jpg, .jpeg, .mp4, .mov, .avi, .wmv');
            //fileSelector.setAttribute('multiple', 'multiple');
            fileSelector.addEventListener("change", handleChange);
            fileSelector.click();
        } catch (error) {
            console.error(error)
        }
    };

    const handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            const file = files[0];
            const fileType = file.type.split('/')[0];
            const allowedFileTypes = ['image', 'application', 'text', 'video'];
            if (allowedFileTypes.includes(fileType)) {
                if (file.size <= 5242880) {
                    onAddFileCallback(file);
                } else {
                    console.log('File size should be less than 5MB.');
                }
            } else {
                console.log('Please Select Documents Or Images For Evidence.');
            }
        }
    }

    const onAddFileCallback = (files) => {
        try {
            let newfile = files[files.length == 0 ? 0 : files.length - 1]
            let reader = new FileReader()
            reader.readAsDataURL(files);
            reader.onload = (e) => {
                Attachalertevidences(e.target.result, files.name)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onAttachOperatorResponse = (data) => {
        var reader = new FileReader();
        reader.onload = function () {
            Attachalertevidences(reader.result, `OperatorResponse_${props.AlertID}_${new Date().getTime()}.mp3`)
        };
        reader.readAsDataURL(data);
    }

    const Attachalertevidences = (e, fname) => {
        setButtonDisable(true)
        try {
            var obj = {
                "evidenceFileName": fname,
                "alertId": props.AlertID,
                "userId": LoggedInUser.UserID,
                "base64String": e
            }
            attachalertevidences(obj).then((evidenceRes) => {
                if (evidenceRes.status === "Ok") {
                    setButtonDisable(false)
                    OnAttachedSuccess(evidenceRes)
                    props.scrollToBottom()
                    props.setCapturedImage(null);
                }
                else {
                    setButtonDisable(false)
                    // alert(evidenceRes.message)
                    ErrorToast(evidenceRes.message);
                }
            })
        } catch (error) {
            setButtonDisable(false)
            console.error(error)
        }
    }

    const OnAttachedSuccess = (evidenceRes) => {
        try {
            let d = props.getDate();
            var myCurrentDatetime = d;
            var _datetime = ConvertDate(myCurrentDatetime)
            var rasaconvobj = {
                "evidenceType": "img",
                "text": evidenceRes.documentUrl,
                "timestamp": _datetime,
                "type": "user"
            }
            var addindbobj = {
                "assignedTo": LoggedInUser.UserID,
                "rasaConversationID": props.ConversationId,
                "uploadType": "file",
                "evidence": rasaconvobj,
                "conversation": null,
                "userType": "operator",
                "createdOn": _datetime
            }
            addrasaconversationindb(addindbobj).then((resp) => {
                if (resp.status === "Ok") {
                    props.OnEvidenceAttached(evidenceRes)
                }
                else {
                    console.error('Error while saving evidence to databse ', resp.message)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const escapeQuotes = (input) => {
        // return input.replace(/"/g, '\\"').replace(/'/g, "\\'");
        return input.replaceAll("'", `''`).replaceAll('"', '\\"')
    }

    function escapeQuotesForJSON(input) {
        return input.replace(/\\/g, '\\\\')  // Escape backslashes
                    .replace(/"/g, '\\"')    // Escape double quotes
                    .replace(/'/g, "\\'");   // Escape single quotes
    }

    async function AddComments() {
        try {
            var remark = alertremark.trim()
            if (remark == "") {
                alert("Enter remark!")
                return
            }
            let d = props.getDate();
            var myCurrentDatetime = d;
            var _datetime = ConvertDate(myCurrentDatetime)
            var rasaconvobj = {
                "evidenceType": "comment",
                "text": "Comment :" + remark,
                "timestamp": _datetime,
                "type": "user"
            }
            addrasaconversationindb({
                "assignedTo": LoggedInUser.UserID,
                "rasaConversationID": props.ConversationId,
                "uploadType": "file",
                "evidence": rasaconvobj,
                "conversation": null,
                "userType": "operator",
                "createdOn": _datetime
            }).then((resp) => {
                console.log("COMMENTISSUE", resp)
                if (resp.status === "Ok") {
                    // document.getElementById("Commenttxt:" + props.AlertID).value = ""
                    console.log("COMMENTISSUE", props.AlertID);
                    setalertremark("");
                    var evidenceRes = { "documentUrl": "Comment :" + remark }
                    props.OnEvidenceAttached(evidenceRes)
                    props.scrollToBottom();
                }
                else {
                    console.error('Error while saving evidence to databse ', resp.message)
                }
            })
        } catch (error) {
            console.error("CinemaView", { error })
        }
    }

    //#endregion functions end

    return (
        <div className="chatFlowBoxContainer" style={{ justifyContent: "flex-start" }}>
            <input onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    AddComments()
                }
            }} 
            // id={"Commenttxt:" + props.AlertID} 
            value={alertremark} className="chatFlowWebChatBoxInput" type="text" placeholder="Enter comments here" onChange={e => setalertremark(e.target.value)} />
            <a className="cursor-ptr" onClick={() => AddComments()}>
                <img src={SendIcon} alt="send icon" height={20} width={20} />
            </a>
            <button
            className={`${buttonDisable?"LinkButtonView1 cursor-not-allowed ":"LinkButtonView1 cursor-ptr"}`}
             type="file" onClick={ChangeEvidenceFileHandler} disabled={buttonDisable}>
                <img src={AttachEvidenceChatIcon} alt="attach icon" />
            </button>
            <div className="chatFlowWebChatVoiceButton cursor-ptr" onClick={() => setisrecording(!isrecording)}>
                <img src={MikeIcon} alt="mike icon" className={isrecording && "Blink"} />
            </div>
        </div>
    )
}