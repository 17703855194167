import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { MosaicNoStream, MosaicHooterOn, MosaicFullscreen, MosaicExitFullscreen, MosaicHooterOff, MosaicTwowayStart, MosaicTwowayStop, MosaicSnapshot, Save, Video_Export } from '../../assets/images'

import { HooterOnOffRequest, TwoWayCommunicationRequest } from '../../services/alertAuthService';

import screenfull from 'screenfull'
import captureVideoFrame from "capture-video-frame"

import { IS_WEBRTC } from '../../config'
import WebRTCPlayer from '../Cameras/WebRTCPlayer'
import ReactPlayerExt from './ReactPlayerExt'

export default function IndividualMosaiCameras({ camera, gridType, height, width, playerRef, refreshTime, currentPage, item, setCount, count }) {
  //#region useState start
  const [isMosaicHooterOn, setIsMosaicHooterOn] = useState(true);
  const [isMosaicSoundOn, setIsMosaicSoundOn] = useState(true);
  const [isMosaicFullscreenClick, setIsMosaicFullscreenClick] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  //#endregion useState end

  //#region useRef start
  const videoContainerRef = useRef(null);
  const videoContainerParentRef = useRef(null);
  //#endregion useRef end

  let history = useHistory()

  //for Mosaic Hooter Implementation
  const HooterOnOffReq = async (privateIPAddress, camPort, action, panelBrand, CCTVType
      , user, password, BrandName) => {
      try {
          if (privateIPAddress) {
              HooterOnOffRequest(privateIPAddress, camPort, action, panelBrand, CCTVType
                  , user, password, BrandName).then((resp) => {
                      return resp
                  })
          }
      } catch (error) {
          console.error(error)
      }
  }
  function MosaicHooterClick(hooter, camera, isCloseBtn) {
      var resp = "";
      if (hooter && hooter == 'on') {
          setIsMosaicHooterOn(false)
          resp = HooterOnOffReq(camera.privateIPAddress, camera.camPort, 'on', camera.panelBrand, camera.CCTVType
              , camera.camUserName, camera.camPassword, camera.BrandName)
      }
      if (hooter && hooter == 'off') {
          setIsMosaicHooterOn(true)
          resp = HooterOnOffReq(camera.privateIPAddress, camera.camPort, 'off', camera.panelBrand, camera.CCTVType
              , camera.camUserName, camera.camPassword, camera.BrandName)
      }

  }

  // for Mosaic FullScreen Implementation
  //#region useEffect start
  useEffect(() => {
      if (screenfull.isEnabled) {
          screenfull.on('change', () => {
              console.log("CheckFullScreen", screenfull.isFullscreen);

          })
      }
  }, [screenfull]);

  useEffect(() => {
      document.addEventListener('fullscreenchange', exitHandler);
      function exitHandler() {
          if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
              setIsMosaicFullscreenClick(false);
              let c = count + 1;
              setCount(c);
          } else {
              console.log("Plain JS screenfull start");
          }
      }
      return () => {
          document.removeEventListener('fullscreenchange', exitHandler);
      }
  }, []);

  useEffect(() => {
      const handlePageChange = () => {
          TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
      };
      const unlisten = history.listen(handlePageChange);
      return () => {
          unlisten();
      }
  }, [history])
  //#endregion useEffect end

  //#region functions start
  const handleMosaicFullScreen = () => {
      screenfull.toggle(videoContainerParentRef.current)
      setIsMosaicFullscreenClick(!screenfull.isFullscreen);
      let c = count + 1;
      setCount(c);
  }

  /* Capture Frame */
  const handleCaptureFrame = (params) => {
      const frame = captureVideoFrame(params)
      setCapturedImage(frame.dataUri)
      var fileUrl = frame.dataUri;
      const popupWindow = window.open('', '_blank', 'width=auto,height=auto');
      popupWindow.document.open();
      popupWindow.document.write(`
      <html>
      <head>
      <title>Snapshot</title>
      <style>
      body, html {
        margin: 0; padding: 0; background: transparent; font-family: Segoe UI;
      }
      .maincontainer {
        display: flex;
        flex-flow: column;
        height: 100%;
      }
      .snapshottitlediv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #bdbcbc; 
        flex: 0 1 auto;
        padding: 0px 10px;
      }
      .DataAccessEdit {
        width: 32px;
        height: 32px;
        padding: 8px 7.6px 7.6px 8px;
        border-radius: 5px;
        background-color: #0b70d1;
        line-height: 10px;
        margin-left: 5px;
        border: 1px solid #0b70d1;
      }
      p {
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        margin: 0px 0px 0px 5px;
      }
      .snapshotimagediv {
        flex: 1 1 auto;
        display: grid;
        align-items: center;
      }
      .snapshotimagediv img {
        width: 100%;
        object-fit: contain;
        box-sizing: border-box;
        display: flex;
      }
      </style>
      </head>        
      <body>
      <div class="maincontainer">
      <div class="snapshottitlediv">
      <p>${camera.CameraName} Snapshot</p>
      <button class="DataAccessEdit" data-title="Download" title="Save" onclick="capturedFrameDownloadClick('${fileUrl}')">
      <img src=${Save} alt="download icon" data-title="Save" />
      </button>
      </div>
      <div class="snapshotimagediv">
      <img src="${fileUrl}" alt="Screenshot" />
      </div>
    </div>
      </body>
      <script>
      function capturedFrameDownloadClick(imageData){
        var fileUrl = imageData;
        fetch(fileUrl)
          .then(response => response.blob())
          .then(blob => {
            debugger
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: "image" });
            link.download = "test.png";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });              
      }
      </script>
      </html>`);
      popupWindow.document.close();
  };
  /* Capture Frame */

  //for Mosaic Two-way Implementation 

  const TwoWayCommunicationReq = async (privateIPAddress, CommunicationPort
      , action, user, password, BrandName) => {
      try {
          if (privateIPAddress && CommunicationPort
              && user && password) {
              TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
                  , action, user, password, BrandName).then((resp) => {
                      return resp
                  })
          } else {
              TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
                  , action, user, password, BrandName).then((resp) => {
                      return resp
                  })
          }
      } catch (error) {
          console.error(error)
      }
  }

  function MosaicTwoWayAudioClick(audio, camera, isCloseBtn) {
      var resp = "";
      if (audio && audio == 'start') {
          setIsMosaicSoundOn(false)
          TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
          resp = TwoWayCommunicationReq(camera.privateIPAddress, camera.CommunicationPort
              , 'start', camera.camUserName, camera.camPassword, camera.BrandName
          )
      }
      if (audio && audio == 'stop') {
          setIsMosaicSoundOn(true)
          resp = TwoWayCommunicationReq(camera.privateIPAddress, camera.CommunicationPort
              , 'stop', camera.camUserName, camera.camPassword, camera.BrandName
          )
      }
  }
  //#endregion functions end

  return <>
      {
          camera.RTSPURL != null ?
              <>
                  {
                      IS_WEBRTC == 0
                          ?
                          <div
                              ref={videoContainerParentRef}
                              className='HeightChildDiv' style={{ height: height, width: width, position: "relative" }}>
                              <ReactPlayerExt
                                  item={camera}
                                  style={{ height: height, width: width }}
                                  playerRef={playerRef}
                                  time={refreshTime}
                                  currentPage={currentPage}
                                  videoContainerRef={videoContainerRef}
                              />
                              <div className='mosaicButtonsMainDiv' style={{ width: "100%" }}>
                                  <div className={` ${gridType == "parent5x5" ? "parent5x5CameraName" : 'MosaicCameraName'}`}><p>{camera.CameraName}</p></div>
                                  <div className='mosaicbuttons'>
                                      <button data-title='SnapShot' className='MosaicButtonDiv'>
                                          <img style={{ padding: "2px", height: "24px", }}
                                              src={MosaicSnapshot}
                                              alt='Snapshot'
                                              onClick={() => { handleCaptureFrame(videoContainerRef.current.getInternalPlayer()) }}
                                          />
                                      </button>
                                      {
                                          camera.ShowTwoWay
                                              ? (
                                                  isMosaicSoundOn ?
                                                      <button data-title='TwowayStart' className='MosaicButtonDiv'>
                                                          <img style={{ padding: "2px" }}
                                                              src={MosaicTwowayStart}
                                                              alt="MosaicTwoWayStart"
                                                              onClick={() => MosaicTwoWayAudioClick('start', camera)}
                                                          />
                                                      </button>
                                                      :
                                                      <button data-title='TwowayStop' className='MosaicButtonDiv'>
                                                          <img style={{ padding: "2px" }}
                                                              alt="MosaicTwoWayStop"
                                                              src={MosaicTwowayStop}
                                                              onClick={() => MosaicTwoWayAudioClick('stop', camera)}
                                                          />
                                                      </button>
                                              )
                                              : null
                                      }
                                      {
                                          camera.ShowHooter ?
                                              (isMosaicHooterOn ?
                                                  <button data-title='HooterOn' className='MosaicButtonDiv'>
                                                      <img style={{ padding: "2px" }}
                                                          src={MosaicHooterOn}
                                                          alt="MosaicHooterClickOn"
                                                          onClick={() => MosaicHooterClick('on', camera)}
                                                      />
                                                  </button>
                                                  :
                                                  <button data-title='HooterOff' className='MosaicButtonDiv'>
                                                      <img style={{ padding: "2px" }}
                                                          src={MosaicHooterOff}
                                                          alt="MosaicHooterClickOff"
                                                          onClick={() => MosaicHooterClick('off', camera)}
                                                      />
                                                  </button>) : null
                                      }

                                      <button
                                          data-title={isMosaicFullscreenClick ? 'Exit Fullscreen' : 'Fullscreen'}
                                          className='MosaicButtonDiv'
                                          onClick={handleMosaicFullScreen}
                                      >
                                          {
                                              !isMosaicFullscreenClick ?
                                                  <img style={{ padding: "2px" }}
                                                      src={MosaicFullscreen}
                                                      alt="MosaicFullScreen"
                                                  />
                                                  :
                                                  <img style={{ padding: "2px" }}
                                                      src={MosaicExitFullscreen}
                                                      alt="MosaicExitFullScreen"
                                                  />
                                          }
                                      </button>

                                      <button data-title='Video Export' className='MosaicButtonDiv'>
                                          <img style={{ padding: "2px", height: "24px", }}
                                              src={Video_Export} alt="video Export Button"
                                          />
                                      </button>

                                  </div>
                              </div>
                          </div>
                          :
                          <WebRTCPlayer
                              key={camera.ParentCameraID}
                              id={camera.ParentCameraID + "1"}
                              url={camera.RTSPURL}
                              isEncode={camera.isTranscoded}
                              upperDivCss="HeightChildDiv"
                              videoCss="mosaic"
                              item={camera}
                              buttonCss="mosaicButtonsMainDiv"
                              noStreamDivCss="HeightChildDiv"
                              playback={true}
                              prePostDisplay={false}
                              liveButton={true}
                              exportCurrentVideoHide={true}
                              showPlaybackBtn={true}
                              showAlertCreation={true}
                              showHooterIcon={true}
                          />
                  }
              </>
              :
              <div className='HeightChildDiv' style={{ height: height, width: width }}>
                  <img src={MosaicNoStream} alt="mosaic" style={{ height: height, width: width, backgroundColor: '#000000' }} />
              </div>
      }
  </>
}
