import React from 'react'
import { WidgetSkeleton } from './WidgetSkeleton'
import HealthCounts from './HealthCounts'

const HealthCard = (props) => {
    let { cardName, imageName, loading, altImageName, bgColor, offlineCount, onlineCount,totalCount } = props;
    
  return (
    <div className="CardBoxWithBorder">
          <div className="CardBoxImage" style={{backgroundColor:bgColor}}>
              <img src={imageName} style={{height: "26px"}} alt={altImageName}/>
          </div>
          <div className='CardBoxText'>
            <div>
              <h4>{cardName}</h4>
            </div>

            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div className="StatusText">
                {
                  loading ? <WidgetSkeleton /> : <HealthCounts type={altImageName == "HDD status" ? "Normal" : "Online"} count={onlineCount} />
                }
                </div>
                <div className="StatusText">
                {
                  loading ? <WidgetSkeleton /> : <HealthCounts type={altImageName == "HDD status" ? "Faulty" : "Offline"} count={offlineCount} />
                }
                </div>
                <div className="StatusText">
                {
                  loading ? <WidgetSkeleton /> : <HealthCounts type="Total Devices" count={totalCount} />
                }
                </div>
            </div>
          </div>
    </div>
  )
}

export default HealthCard