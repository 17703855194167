import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { armPanelCommand, disarmPanelCommand, resetPanelCommand } from '../../services/alertAuthService';
import { ArmIcon, DisarmIcon, ResetIcon } from '../../assets/images';

const PartitionComponent = ({ item, panelSetting, setPartitionMessage, setPartitionStatus, allDisable, setAllDisable }) => {

    const [armDisable, setArmDisable] = useState(false)
    const [disArmDisable, setDisArmDisable] = useState(false)
    const [resetDisable, setResetDisable] = useState(false)

    const armPartition = (id, panelSetting) => {
        console.log(id);
        setArmDisable(true);
        setAllDisable(true)
        setPartitionMessage(`Partition - ${id} arm command sent`)
        setPartitionStatus(true);
        armPanelCommand(panelSetting.PrivateIPAddress, panelSetting.Port, panelSetting.CamUserName, panelSetting.CamPassword, panelSetting.BrandName, id)
      .then((res) => {
        console.log(res);
        setArmDisable(false);
        setAllDisable(false)
        setPartitionMessage(`Partition - ${id} arm command sent - Completed`)
        // setPartitionStatus(true);
      }).catch(err => {
        console.log(err);
        setArmDisable(false);
        setAllDisable(false)
        setPartitionMessage(`Partition - ${id} arm command sent - Completed`)
        // setPartitionStatus(true);
      })
    }
    
    const disArmPartition = (id, panelSetting) => {
        console.log(id);
        setDisArmDisable(true);
        setAllDisable(true)
        setPartitionMessage(`Partition - ${id} disarm command sent`)
        setPartitionStatus(true);
        disarmPanelCommand(panelSetting.PrivateIPAddress, panelSetting.Port, panelSetting.CamUserName, panelSetting.CamPassword, panelSetting.BrandName, id)
      .then((res) => {
        console.log(res);
        setDisArmDisable(false);
        setAllDisable(false)
        setPartitionMessage(`Partition - ${id} disarm command sent - Completed`)
        // setPartitionStatus(true);
      }).catch(err => {
        console.log(err);
        setDisArmDisable(false);
        setAllDisable(false)
        setPartitionMessage(`Partition - ${id} disarm command sent - Completed`)
        // setPartitionStatus(true);
      })
    }

    const resetPartition = (id, panelSetting) => {
        console.log(id);
        setResetDisable(true);
        setAllDisable(true)
        setPartitionMessage(`Partition - ${id} reset command sent`)
        setPartitionStatus(true);
        resetPanelCommand(panelSetting.PrivateIPAddress, panelSetting.Port, panelSetting.CamUserName, panelSetting.CamPassword, panelSetting.BrandName, id)
      .then((res) => {
        console.log(res);
        setResetDisable(false);
        setAllDisable(false)
        setPartitionMessage(`Partition - ${id} reset command sent - Completed`)
        // setPartitionStatus(true);
      })
      .catch(err => {
        setResetDisable(false);
        setAllDisable(false)
        setPartitionMessage(`Partition - ${id} reset command sent - Completed`)
        // setPartitionStatus(true);
        console.log(err);
      })
    }

    return (
        <div class="card" key={item.id}>
            {/* <div className='cardCheckbox'>
                                        <input type="checkbox" checked={item.isChecked} onChange={() => selectItemHandler(item.id, INPUT)} />
                                      </div> */}
            <p style={{ textAlign: "center", marginBottom: "5px" }}>{item.text}</p>
            <div style={{ textAlign: "center" }}>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            Arm
                        </Tooltip>
                    }
                >
                    <button className='DataAccessEdit' disabled={allDisable} onClick={() => armPartition(item.id, panelSetting)}>
                        {/* <span className='ViewUpdate'>A</span> */}
                        <img src={ArmIcon} className="EditedIcon" height={17} />
                    </button>
                </OverlayTrigger>

                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            DisArm
                        </Tooltip>
                    }
                >
                    <button className='DataAccessEdit' disabled={allDisable} onClick={() => disArmPartition(item.id, panelSetting)}>
                        {/* <span className='ViewUpdate'>D</span> */}
                        <img src={DisarmIcon} className="EditedIcon" />
                    </button>
                </OverlayTrigger>

                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            Reset
                        </Tooltip>
                    }
                >
                    <button className='DataAccessEdit' disabled={allDisable} onClick={() => resetPartition(item.id, panelSetting)}>
                        {/* <span className='ViewUpdate'>R</span> */}
                        <img src={ResetIcon} className="EditedIcon" />
                    </button>
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default PartitionComponent