import React, { useContext } from 'react'
import { Switch } from 'react-router-dom';

import {
    ALERTS, DASHBOARD, ALLALERTS, MYALERTS, ALERTDETAILS, ALLNOTIFICATIONS, REPORTS, ALERTARMDISARMREPORT, PROFILE, CAMERAS,
    CHATFLOW, ALERTREPORT, CAMERA_SINGLE_PLAYBACK, HEALTHMONITORING, DISPLAYSITE, EVENTACTIVITYREPORT, PAGE, DOWNLOAD, HOMEPAGE,
    ABOUTTERMS,
    PANELCONTROL,
    PANELACTIVITY,
    CUSTOMERACTIVITY,
    CCTVACTIVITY,
    CUSTOMERSITEACTIVITY,
    OPERATORALERTREPORT,
    DAILYHEALTHCHECKREPORT,
    PANELUPDOWNREPORT,
    ATMSITEREPORT,
    SAMPLEREPORT,
    ALERTGRIDVIEW,
    CAMERASOLD,
    AVAILABLERECORDINGREPORT
} from '../../navigation/CONSTANTS';
import PrivateRoute from '../../navigation/Auth/PrivateRoute';
import { DrawerContext } from '../../Context/DrawerContext';
import Sidebar from '../../components/Sidebar/Sidebar';
import DashboardContainer from '../Dashboard/DashboardContainer';
import ProfileContainer from '../Profile/ProfileContainer';
import AlertsContainer from '../Alerts/AlertsContainer';
import AllAlerts from '../Alerts/AllAlerts';
import MyAlerts from '../Alerts/MyAlerts';
import AlertDetails from '../Alerts/AlertDetails';
import AlertReportContainer from '../Alerts/AlertReportContainer'
import ChatflowPageContainer from '../Chatflow/ChatflowPageContainer';
import CameraViewPage from '../Cameras/CameraViewPage';
import SinglePlayback from '../Cameras/SinglePlayback';
import AllNotificationContainer from '../Notification/Allnotificationspage'
import HealthMonitoring from '../HealthMonitoring/HealthMonitoring';
import HealthDisplaySite from '../HealthMonitoring/HealthDisplaySite';
import DownloadHistory from '../DownloadHistory/DownloadHistory';
import { EventActivityReport } from '../Reports/EventActivityReport';
import AlertArmDisarmReport from '../Reports/AlertArmDisarmReport';
import AlertReportsContainer from '../Reports/AlertReportsContainer';
import { CAMERA_NEW, HIDE_STUFF } from '../../config';
import CustomUrlPage from '../Dashboard/CustomUrlPage';
import Termscondition from '../AboutMH/Termscondition';
import PanelSetting from '../PanelControl/PanelSetting';
import { DeviceEventActivityReports } from '../Reports/DeviceEventActivityReports';
import { CustomerActivityReports } from '../Reports/CustomerActivityReports';
import { CCTVActivityReport } from '../Reports/CCTVActivityReport';
import { CustomerSiteWiseReport } from '../Reports/CustomerSiteWiseReport';
import OperatorWiseAlertReport from '../Reports/OperatorWiseAlertReport';
import AlertsActivityReport from '../Reports/AlertsActivityReport';
import { CommonReport } from '../Reports/CommonReport';
import { CameraFilterPage } from '../CameraNewUI/CameraFilterPage';
import AlertMosaicView1 from '../Alerts/AlertMosaicView1';



export default function MainContent({
    alerts,
    onAddAlertChat,
    ResetAlertList,
    selectedTab,
    setSelectedTab,
    selectedSubMenu,
    setSelectedSubMenu,
    alertcounts,
    rootLoading,
    ResetAlertCounts,
    todaysAlertList,
    isSuperUser,
    uniqueId,
    cctvTypeName,
    camUserName,
    camPassword,
    brandName,
    hootWay,
    setHootWay,
    alertSearch,
    setAlertSearch,
    activeSeverity,
    setActiveSeverity,
    childWindow,
    setChildWindow,
    isDarkTheme,
    setIsDarkTheme,
    setAlertDetail,
    setOpenButton1,
    openButton1,
    activeCam,
    setActiveCam,
    setCctvTypeName,
    setCamUserName,
    setCamPassword,
    setBrandName,
    associateCam,
    setAssociateCam,
    checkEnableSound,
    alertChildWindow,
    setAlertChildWindow
}) {

    const { collapsed, setCollapsed } = useContext(DrawerContext);


    return <>
        <Sidebar
            isSuperUser={isSuperUser}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedSubMenu={selectedSubMenu}
            setSelectedSubMenu={setSelectedSubMenu}
            isDarkTheme={isDarkTheme} />
        <div className={!collapsed ? "mainContent" : "collapseMainContent"} >
            <Switch>
                <PrivateRoute path={ALERTS}>
                    <AlertsContainer onAddAlertChat={onAddAlertChat} setSelectedTab={setSelectedTab} uniqueId={uniqueId} alertSearch={alertSearch} setAlertSearch={setAlertSearch} activeSeverity={activeSeverity} setActiveSeverity={setActiveSeverity} alertChildWindow={alertChildWindow} setAlertChildWindow={setAlertChildWindow} />
                </PrivateRoute>
                
                <PrivateRoute path={ALERTGRIDVIEW}>
                    <AlertMosaicView1 
                        hootWay={hootWay}
                        setHootWay={setHootWay}
                        associateCam={associateCam}
                        setAssociateCam={setAssociateCam}
                        ResetAlertList={ResetAlertList}
                    />
                </PrivateRoute>
                
                {/* <PrivateRoute path={ALERTGRIDVIEW}>
                    <AlertMosaicView1 />
                </PrivateRoute> */}

                <PrivateRoute path={ALLALERTS}>
                    <AllAlerts alertList={alerts} selectedSubMenu={selectedSubMenu} onAddAlertChat={onAddAlertChat} setSelectedTab={setSelectedTab} alertcounts={alertcounts} ResetAlertCounts={ResetAlertCounts} alertSearch={alertSearch} setAlertSearch={setAlertSearch} activeSeverity={activeSeverity} setActiveSeverity={setActiveSeverity} childWindow={childWindow}
                        setChildWindow={setChildWindow} isDarkTheme={isDarkTheme} alertChildWindow={alertChildWindow} setAlertChildWindow={setAlertChildWindow} />
                </PrivateRoute>

                <PrivateRoute path={MYALERTS}>
                    <MyAlerts alertList={alerts} selectedSubMenu={selectedSubMenu} onAddAlertChat={onAddAlertChat} setSelectedTab={setSelectedTab} alertcounts={alertcounts} alertSearch={alertSearch} setAlertSearch={setAlertSearch} activeSeverity={activeSeverity} setActiveSeverity={setActiveSeverity} alertChildWindow={alertChildWindow} setAlertChildWindow={setAlertChildWindow} />
                </PrivateRoute>

                <PrivateRoute path={ALERTDETAILS}>
                    <AlertDetails setSelectedTab={setSelectedTab} onAddAlertChat={onAddAlertChat} hootWay={hootWay} setHootWay={setHootWay} setSelectedSubMenu={setSelectedSubMenu}
                        setCctvTypeName={setCctvTypeName} setCamUserName={setCamUserName} setCamPassword={setCamPassword} setBrandName={setBrandName}
                    />
                </PrivateRoute>

                <PrivateRoute path={DASHBOARD}>
                    <DashboardContainer alertList={alerts} setSelectedTab={setSelectedTab} alertcounts={alertcounts} todaysAlertList={todaysAlertList} rootLoading={rootLoading} onAddAlertChat={onAddAlertChat} isDarkTheme={isDarkTheme} />
                </PrivateRoute>

                <PrivateRoute path={HOMEPAGE}>
                    <DashboardContainer alertList={alerts} setSelectedTab={setSelectedTab} alertcounts={alertcounts} todaysAlertList={todaysAlertList} rootLoading={rootLoading} onAddAlertChat={onAddAlertChat} isDarkTheme={isDarkTheme} />
                </PrivateRoute>

                <PrivateRoute path={ALLNOTIFICATIONS}>
                    <AllNotificationContainer />
                </PrivateRoute>

                <PrivateRoute path={REPORTS}>
                    {/* <AlertReportsContainer isDarkTheme={isDarkTheme} /> */}
                    <AlertsActivityReport isDarkTheme={isDarkTheme} />
                </PrivateRoute>

                <PrivateRoute path={ALERTARMDISARMREPORT}>
                    <AlertArmDisarmReport />
                </PrivateRoute>

                <PrivateRoute path={EVENTACTIVITYREPORT}>
                    <EventActivityReport isDarkTheme={isDarkTheme} />
                </PrivateRoute>

                <PrivateRoute path={PANELACTIVITY}>
                    <DeviceEventActivityReports isDarkTheme={isDarkTheme} />
                </PrivateRoute>

                <PrivateRoute path={CUSTOMERACTIVITY}>
                    <CustomerActivityReports isDarkTheme={isDarkTheme} />
                </PrivateRoute>

                <PrivateRoute path={CUSTOMERSITEACTIVITY}>
                    <CustomerSiteWiseReport isDarkTheme={isDarkTheme} />
                </PrivateRoute>

                <PrivateRoute path={OPERATORALERTREPORT}>
                    <OperatorWiseAlertReport isDarkTheme={isDarkTheme} />
                </PrivateRoute>

                <PrivateRoute path={DAILYHEALTHCHECKREPORT}>
                    <CommonReport key={1} isDarkTheme={isDarkTheme} title={"Daily Report"} showDateButton={false} reportNo={"1"} showExportButton={true} exportFileName={"Daily Report"} isPivotParent={1} />
                </PrivateRoute>

                <PrivateRoute path={PANELUPDOWNREPORT}>
                    <CommonReport key={2} isDarkTheme={isDarkTheme} title={" Panel Up Down Report"} showDateButton={false} reportNo={"2"} showExportButton={true} exportFileName={"Panel Up Down Report"} isPivotParent={1} />
                </PrivateRoute>

                <PrivateRoute path={ATMSITEREPORT}>
                    <CommonReport key={3} isDarkTheme={isDarkTheme} title={" ATM Report"} showDateButton={true} reportNo={"3"} showExportButton={true} exportFileName={"ATM Report"} isPivotParent={0} />
                </PrivateRoute>

                {/*<PrivateRoute path={SAMPLEREPORT}>
                    <CommonReport key={4} isDarkTheme={isDarkTheme} title={" ATM Motion Report"} showDateButton={true} reportNo={"4"} showExportButton={true} exportFileName={"ATM Motion Report"} isPivotParent={0} />
                </PrivateRoute>*/}

                <PrivateRoute path={AVAILABLERECORDINGREPORT}>
                    <CommonReport key={4} isDarkTheme={isDarkTheme} title={"Available Recording Report"} showDateButton={true} reportNo={"4"} showExportButton={true} exportFileName={"Available Recording Report"} isPivotParent={1} />
                </PrivateRoute>

                <PrivateRoute path={CAMERAS}>
                        {
                            CAMERA_NEW
                            ? <CameraFilterPage childWindow={childWindow} setChildWindow={setChildWindow} isDarkTheme={isDarkTheme} /> 
                            : <CameraViewPage childWindow={childWindow}
                                setChildWindow={setChildWindow} />
                        }
                </PrivateRoute>
                <PrivateRoute path={CAMERASOLD}>
                    <CameraViewPage childWindow={childWindow}
                        setChildWindow={setChildWindow} />
                         
                </PrivateRoute>

                <PrivateRoute path={CAMERA_SINGLE_PLAYBACK}>
                    <SinglePlayback
                        setChildWindow={setChildWindow}
                    />
                </PrivateRoute>

                <PrivateRoute path={PROFILE}>
                    <ProfileContainer isDarkTheme={isDarkTheme} checkEnableSound={checkEnableSound} />
                </PrivateRoute>
                {
                    !HIDE_STUFF
                        ?
                        <PrivateRoute path={DOWNLOAD}>
                            <DownloadHistory isDarkTheme={isDarkTheme} />
                        </PrivateRoute>
                        : null
                }
                <PrivateRoute path={CHATFLOW}>
                    <ChatflowPageContainer ResetAlertList={ResetAlertList}
                        cctvTypeName={cctvTypeName}
                        camUserName={camUserName}
                        camPassword={camPassword}
                        brandName={brandName}
                        hootWay={hootWay}
                        setHootWay={setHootWay}
                        setAlertDetail={setAlertDetail}
                        setOpenButton1={setOpenButton1}
                        openButton1={openButton1}
                        activeCam={activeCam}
                        setActiveCam={setActiveCam}
                        setCctvTypeName={setCctvTypeName}
                        setCamUserName={setCamUserName}
                        setCamPassword={setCamPassword}
                        setBrandName={setBrandName}
                        associateCam={associateCam}
                        setAssociateCam={setAssociateCam}
                    />
                </PrivateRoute>

                <PrivateRoute path={ALERTREPORT}>
                    <AlertReportContainer />
                </PrivateRoute>

                <PrivateRoute path={HEALTHMONITORING} exact={true}>
                    <HealthMonitoring
                        isDarkTheme={isDarkTheme}
                    />
                </PrivateRoute>

                <PrivateRoute path={DISPLAYSITE} exact={true}>
                    <HealthDisplaySite />
                </PrivateRoute>

                <PrivateRoute path={ABOUTTERMS}>
                    <Termscondition />
                </PrivateRoute>

                <PrivateRoute path={PAGE}>
                    <CustomUrlPage />
                </PrivateRoute>

                <PrivateRoute path={PANELCONTROL}>
                    <PanelSetting
                        isDarkTheme={isDarkTheme}
                    />
                </PrivateRoute>
            </Switch>
        </div>
    </>
}
