import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    buttonVisibilityState: {
        visibleFilterBtn: true,
        visibleClearFilterBtn: true,
        visibleSaveFilterBtn: true,
        visibleSyncReplayBtn: true,
        visibleClearSyncReplayBtn: true,
        visibleViewMosaicBtn: true
    },
}

const buttonFeatures = createSlice({
    name: 'button',
    initialState,
    reducers: {
        //#region Toggle button visibility
        disableFilterBtn(state, action) {
            return {
                ...state,
                buttonVisibilityState: {
                    ...state.buttonVisibilityState,
                    visibleFilterBtn: action.payload
                }
            }
        },
        disableClearFilterBtn(state, action) {
            return {
                ...state,
                buttonVisibilityState: {
                    ...state.buttonVisibilityState,
                    visibleClearFilterBtn: action.payload
                }
            }
        },
        disableSaveFilterBtn(state, action) {
            return {
                ...state,
                buttonVisibilityState: {
                    ...state.buttonVisibilityState,
                    visibleSaveFilterBtn: action.payload
                }
            }
        },
        disableSyncReplayBtn(state, action) {
            return {
                ...state,
                buttonVisibilityState: {
                    ...state.buttonVisibilityState,
                    visibleSyncReplayBtn: action.payload
                }
            }
        },
        disableClearSyncReplayBtn(state, action) {
            return {
                ...state,
                buttonVisibilityState: {
                    ...state.buttonVisibilityState,
                    visibleClearSyncReplayBtn: action.payload
                }
            }
        },
        disableViewMosaicBtn(state, action) {
            return {
                ...state,
                buttonVisibilityState: {
                    ...state.buttonVisibilityState,
                    visibleViewMosaicBtn: action.payload
                }
            }
        },
        
        //Reset all buttons to their initial state
        resetAllButtons(state) {
            return {
                ...state,
                buttonVisibilityState: initialState.buttonVisibilityState
            }
        },
        //#endregion
    }
});

export const buttonActions = buttonFeatures.actions;
export default buttonFeatures.reducer;