import React, { useEffect, useState } from 'react'

import { AddNode, ArrowDown, ArrowUp, SearchIcon, SearchIconWhite } from "../../assets/images";

import { Accordion, Card } from "react-bootstrap";
import CustomContactForm from './CustomContactForm';

const CustomContact = ({
  customContact,
  onAddClick,
  isNotifFormEnabled,
  mainCustomerList,
  onDeleteClick,
  setEnableAdd,
  setMainCustomerList,
  setCustomContact,
  addNewClicked,
  isDarkTheme
}) => {

  //region useState start
  const [searchCustCont, setSearchCustCont] = useState("");
  const [customOpen, setCustomeOpen] = useState(false)
  //region useState end

  //region useEffect start
  useEffect(() => {
    if (addNewClicked && searchCustCont != "") {
      setSearchCustCont("");
    }
  }, [addNewClicked]);

  useEffect(() => {
    if (customContact.length == 0) {
      setEnableAdd(false);
    }
  }, [customContact]);
  //region useEffect end

  const searchHandler = (e) => {
    let s = e.target.value;
    let filteredCont = mainCustomerList.filter((x) => x.Name.toLowerCase().includes(s.toLowerCase()));
    setSearchCustCont(s);
    setCustomContact(filteredCont);
  }

  const uniqueKey = (data) => {
    return `${data.Id}_${data.Name}`;
  }

  return (
    <Accordion defaultActiveKey="0" style={{ marginTop: "20px" }}>
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="1" className='NotificationCardAccordion'
          onClick={() => setCustomeOpen(!customOpen)}
        >
          <p>Custom Contact</p>
          {
            !customOpen ?
              <img src={ArrowDown} alt="dropdown-arrow" /> :
              <img src={ArrowUp} alt='up arrow' />
          }
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body style={{ padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="SelectionListWithSelectAll customContactSearch">
                <input
                  className="SearchViewInput1 searchNotification"
                  type="search"
                  placeholder="Search"
                  value={searchCustCont}
                  onChange={(e) => searchHandler(e)}
                />
                {
                  isDarkTheme ?
                    <img src={SearchIcon} style={{ cursor: "pointer" }} alt='search icon' /> :
                    <img src={SearchIconWhite} height="15" style={{ cursor: "pointer" }} alt='search icon' />
                }
              </div>
              <button
                type="button"
                className="addCustomer"
                disabled={!isNotifFormEnabled}
                onClick={(e) => {
                  onAddClick(e);
                }}
                style={{ marginBottom: "10px" }}
              >
                <span className="ViewUpdate">
                  <img src={AddNode} alt='plus icon add node' />
                </span>
              </button>
            </div>
            <div className="HgtCheckBoxList" style={{ paddingRight: "5px" }}>
              {
                customContact.map((item) => (
                  <CustomContactForm
                    key={item.Id}
                    item={item} isNotifFormEnabled={isNotifFormEnabled} onDeleteClick={onDeleteClick} setEnableAdd={setEnableAdd} mainCustomerList={mainCustomerList} setMainCustomerList={setMainCustomerList} customContact={customContact} setCustomContact={setCustomContact} />
                ))
              }
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default CustomContact