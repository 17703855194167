import { Dropdown } from "react-bootstrap";
import { FilterClearIcon } from "../../assets/images";
import { isNotNullOrEmpty } from "../../utils/utils";

const DDLSingleSelect = (singleSelectProps) => {
    const {
        selected,
        valueRenderer,
        itemRenderer,
        labelledBy,
        emptyOptionLabel,
        handleClearSelectedClick,
        clearDisabled,
        options,
    } = singleSelectProps;

    return (
        <>
            <div className={selected ? "SavedFilterDiv ml-2 dropdownSelectionContainer" : "SavedFilterDiv ml-2"}>
                <Dropdown>
                    <Dropdown.Toggle variant="light">
                        {valueRenderer !== null ? valueRenderer() : labelledBy}
                    </Dropdown.Toggle>
                    {
                        !clearDisabled &&
                        <button
                            className="SavedFilterCancelBtn"
                            onClick={handleClearSelectedClick}
                        // disabled={clearDisabled}
                        ><img src={FilterClearIcon} /></button>
                    }

                    <Dropdown.Menu>
                        {
                            isNotNullOrEmpty(options) ?
                                options?.map((item, index) => (
                                    <>
                                        <Dropdown.Item className='p-0' key={index}>
                                            {itemRenderer !== null && itemRenderer(item)}
                                        </Dropdown.Item>
                                    </>
                                ))
                                :
                                <Dropdown.Item style={{ cursor: 'default' }}>
                                    <span>{emptyOptionLabel}</span>
                                </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default DDLSingleSelect;