import React from 'react'
import { useHistory } from 'react-router-dom'
import Skeleton from "react-loading-skeleton";

const DashboardWidget = ({ dashboardLoading, count, type, pointer, bgColor, imgIcon, redirectTo }) => {

    let history = useHistory()

    return (
        <div
            style={{ pointerEvents: pointer ? 'none' : 'auto' }}
            className="topKpiCard"
            onClick={(e) => {
                history.push(redirectTo)
            }
            }
        >
            <div className="AlertsCount">
                <div className='dashboard-card-content'>
                    <div className="dashboard-card-image" style={{ backgroundColor: bgColor }}>
                        <img className='dashboard-icon' src={imgIcon} alt="" />
                    </div>
                    <div className='dashbord-card-txt'>
                        <div>
                            {
                                dashboardLoading
                                    ? <Skeleton width={30} className="NumberText" />
                                    : <p className="NumberText">{count}</p>
                            }
                        </div>
                        <div>{
                            dashboardLoading
                                ? <Skeleton width={50} className="TextValue" />
                                : <p className="TextValue">{type}</p>
                        }
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardWidget