import React, { useState, useEffect, useRef } from 'react'

import { ClockIcon, LinkIcon, RemoveIcon, MosaicTwowayStop, MosaicTwowayStart, MosaicHooterOn, MosaicHooterOff, AssociateCamera , FullScreenChatWhite, CloseChatWhite} from '../../assets/images';

import AliceCarousel from 'react-alice-carousel';
import { ToastContainer } from 'react-toastify';

import ChatflowContainer from '../Chatflow/ChatflowContainer'
import { HooterOnOffRequest, TwoWayCommunicationRequest } from "../../services/alertAuthService"
import { WarningToast } from '../../CustomHooks/WarningToast';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { ASSOC_DIRECT_OPEN, CHAT_CAM_CHANGES, MULTI_TIME_ZONE } from '../../config';
import { mixpanel } from '../../utils/MixPanelUtil'
import WebRTCPlayer from '../Cameras/WebRTCPlayer';
import { addrasaconversationindb } from '../../services/chatFlowService';
import { ConvertDate, multiTimeZoneHelper } from '../../utils/utils';

export default function ChatbotContainer({ chatHeads, onCloseAlertChat, onMaximizeAlertChat, ResetAlertList, cctvTypeName, onChatOpen, hootWay, setHootWay, openButton1, setOpenButton1, setAlertDetail, associateCam, setAssociateCam, getUpdatedAlertDetails }) {
    console.log("chatHeads", chatHeads)
    const [singleAlertDet, setSingleAlertDet] = useState(null);

    return (
        <div className="col-lg-12 mt-3 pr-1 OverlayChat">
            {

                chatHeads &&
                chatHeads.map((chat, index) => {
                    return <ChatbotWidget
                        chat={chat}
                        key={chat.alertId}
                        onCloseAlertChat={onCloseAlertChat}
                        onMaximizeAlertChat={onMaximizeAlertChat}
                        ResetAlertList={ResetAlertList}
                        cctvTypeName={cctvTypeName}
                        onChatOpen={onChatOpen}
                        hootWay={hootWay}
                        setHootWay={setHootWay}
                        openButton1={openButton1}
                        setOpenButton1={setOpenButton1}
                        setAlertDetail={setAlertDetail}
                        associateCam={associateCam}
                        setAssociateCam={setAssociateCam}
                        getUpdatedAlertDetails={getUpdatedAlertDetails}
                    />
                })
            }
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

const ChatbotWidget = ({
    chat,
    onCloseAlertChat,
    onMaximizeAlertChat,
    ResetAlertList,
    cctvTypeName,
    onChatOpen,
    hootWay,
    setHootWay,
    openButton1,
    setOpenButton1,
    setAlertDetail,
    associateCam,
    setAssociateCam,
    getUpdatedAlertDetails
}) => {

    const cameraObject = {
        Abbreviation: "IST",
      BranchID: "888888",
      BrandName: "CP Plus",
      CCTVType: "NVR",
      CameraBrandId: 2,
      CameraName: "Cp Cam 2",
      CommunicationPort: 25001,
      DeviceTypeID: 1,
      IsPTZ: "0",
      Location: "Near Railway station, Ghatkopar",
      OffsetMinutes: 330,
      ParentCameraID: 1242,
      RTSPURL: "rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=2%38subtype=1",
      ShowHooter: true,
      ShowTwoWay: false,
      SiteID: 194,
      SiteName: "Intellve 102",
      StreamName: "2_ca1bbdda-3d92-43be-b4e1-5edc0643b074",
      StreamingURL: null,
      TimeZoneId: 1,
      camPassword: "2o2oImaging",
      camPort: "554",
      camUserName: "admin",
      channelNum: "2",
      httpPort: 80,
      isChecked: false,
      isPlaybackTranscoded: "0",
      isTranscoded: "0",
      playbackURL: null,
      privateIPAddress: "192.168.0.64"
    }

    let associatedCamList = [
        {
          "IsPTZ": "None",
          "ShowHooter": true,
          "ShowTwoWay": false,
          "associatedCameraId": 1243,
          "brandName": "CP Plus",
          "camPassword": "2o2oImaging",
          "camUserName": "admin",
          "cameraBrandId": 2,
          "cameraName": "Cp Cam 3",
          "cctvtype": "NVR",
          "channelNum": "3",
          "communicationPort": 25001,
          "deviceId": 1125,
          "hlsStreamingUrl": null,
          "idsPanelZoneTypeID": 4,
          "isPlaybackTranscoded": "0",
          "isTranscoded": "0",
          "privateIpAddress": "192.168.0.64",
          "rtspUrl": "rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=3%38subtype=1",
          "streamName": "fb70b9a6-6495-456d-a4ee-7e95034a7443",
          "zoneType": "Panic sensor"
        },
        {
          "IsPTZ": "0",
          "ShowHooter": true,
          "ShowTwoWay": false,
          "associatedCameraId": 1242,
          "brandName": "CP Plus",
          "camPassword": "2o2oImaging",
          "camUserName": "admin",
          "cameraBrandId": 2,
          "cameraName": "Cp Cam 2",
          "cctvtype": "NVR",
          "channelNum": "2",
          "communicationPort": 25001,
          "deviceId": 1125,
          "hlsStreamingUrl": null,
          "idsPanelZoneTypeID": 4,
          "isPlaybackTranscoded": "0",
          "isTranscoded": "0",
          "privateIpAddress": "192.168.0.64",
          "rtspUrl": "rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=2%38subtype=1",
          "streamName": "ca1bbdda-3d92-43be-b4e1-5edc0643b074",
          "zoneType": "Panic sensor"
        },
        {
          "IsPTZ": "1",
          "ShowHooter": false,
          "ShowTwoWay": true,
          "associatedCameraId": 1207,
          "brandName": "Hikvision",
          "camPassword": "Admin@123",
          "camUserName": "admin",
          "cameraBrandId": 1,
          "cameraName": "Hik Cam ip 41",
          "cctvtype": "Camera",
          "channelNum": "1",
          "communicationPort": 8000,
          "deviceId": 1125,
          "hlsStreamingUrl": null,
          "idsPanelZoneTypeID": 4,
          "isPlaybackTranscoded": "1",
          "isTranscoded": "1",
          "privateIpAddress": "192.168.0.41",
          "rtspUrl": "rtsp://admin:Admin%40123@192.168.0.41:554/Streaming/Channels/101",
          "streamName": "cbe46c4a-f598-4494-bc18-7e9564805f96",
          "zoneType": "Panic sensor"
        }
      ]

    const items = [
        <div className="item" data-value="1">
            <div className="chatflow">
                              <WebRTCPlayer
                              key={cameraObject.deviceId}
                              id={cameraObject.deviceId + "34"}
                              devId={cameraObject.deviceId}
                              url={cameraObject.rtspUrl}
                              isEncode={cameraObject.isTranscoded}
                              item={cameraObject}
                              actualTime={cameraObject.actualTime}
                              alertDetail={cameraObject}
                              upperDivCss="cameraHoverMainDiv"
                              videoCss="player-responsive1"
                              //videoCss="chatbotliveplayer"
                              fullVideoCss="player-responsive-fullScreen"
                              buttonCss="cameraButtonsMainDiv"
                              noStreamDivCss="no-stream-found"
                            //   playback={false}
                            //   prePostDisplay={false}
                            //   liveButton={true}
                            //   exportCurrentVideoHide={true}
                            //   showPlaybackBtn={false}
                            //   hideTwoWay={false}
                            //   imageToChat={AttachalertevidencesParent}
                            //   attachEvidence={attachEvidence}
                            //   showDirectToChat={true}
                            //   showHooterIcon={true}
                              // buttonDisable={buttonDisable}
                              />
                          </div>
        </div>,
        <div className="item" data-value="2">
        <div className="chatflow">
                          <WebRTCPlayer
                          key={cameraObject.deviceId}
                          id={cameraObject.deviceId + "34"}
                          devId={cameraObject.deviceId}
                          url={cameraObject.rtspUrl}
                          isEncode={cameraObject.isTranscoded}
                          item={cameraObject}
                          actualTime={cameraObject.actualTime}
                          alertDetail={cameraObject}
                          upperDivCss="cameraHoverMainDiv"
                          videoCss="player-responsive1"
                          //videoCss="chatbotliveplayer"
                          fullVideoCss="player-responsive-fullScreen"
                          buttonCss="cameraButtonsMainDiv"
                          noStreamDivCss="no-stream-found"
                        //   playback={false}
                        //   prePostDisplay={false}
                        //   liveButton={true}
                        //   exportCurrentVideoHide={true}
                        //   showPlaybackBtn={false}
                        //   hideTwoWay={false}
                        //   imageToChat={AttachalertevidencesParent}
                        //   attachEvidence={attachEvidence}
                        //   showDirectToChat={true}
                        //   showHooterIcon={true}
                          // buttonDisable={buttonDisable}
                          />
                      </div>
        </div>,
        <div className="item" data-value="3">
        <div className="chatflow">
                          <WebRTCPlayer
                          key={cameraObject.deviceId}
                          id={cameraObject.deviceId + "34"}
                          devId={cameraObject.deviceId}
                          url={cameraObject.rtspUrl}
                          isEncode={cameraObject.isTranscoded}
                          item={cameraObject}
                          actualTime={cameraObject.actualTime}
                          alertDetail={cameraObject}
                          upperDivCss="cameraHoverMainDiv"
                          videoCss="player-responsive1"
                          //videoCss="chatbotliveplayer"
                          fullVideoCss="player-responsive-fullScreen"
                          buttonCss="cameraButtonsMainDiv"
                          noStreamDivCss="no-stream-found"
                        //   playback={false}
                        //   prePostDisplay={false}
                        //   liveButton={true}
                        //   exportCurrentVideoHide={true}
                        //   showPlaybackBtn={false}
                        //   hideTwoWay={false}
                        //   imageToChat={AttachalertevidencesParent}
                        //   attachEvidence={attachEvidence}
                        //   showDirectToChat={true}
                        //   showHooterIcon={true}
                          // buttonDisable={buttonDisable}
                          />
        </div>
        </div>
      ];

      const renderDotsItem = ({ isActive }) => {
        return isActive ? 'x' : 'o';
      };
      
      const renderPrevButton = ({ isDisabled }) => {
        return <span style={{ opacity: isDisabled ? '0.5' : 1 }}>&lt;</span>;
      };
      
      const renderNextButton = ({ isDisabled }) => {
        return <span style={{ opacity: isDisabled ? '0.5' : 1 }}>&gt;</span>;
      };

    //region useStart start
    const [open, setOpen] = useState(false)
    const [isHooterOn, setIsHooterOn] = useState(true);
    const [isSoundOn, setIsSoundOn] = useState(true);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [imageData, setImageData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [updateChatflow, setUpdateChatflow] = useState(0);
//   const [associateData, setAssociateData] = useState(chat.associatedCamList); 
const [associateData, setAssociateData] = useState([]); 

    //region useState end

    const messagesEndRef = useRef(null);

    //region useEffect start
    useEffect(() => {
        scrollToBottom();
    }, []);

    // useEffect(() => {
    //     console.log("TOGGLECHAT", chat.isOpen);
    //     setOpen(chat.isOpen);
    // }, [chat])

    useEffect(() => {
        if (ASSOC_DIRECT_OPEN) {
            ToggleChatWindow(chat.alertId);
            handleDragOpenTwo();
        }
    }, []);

    useEffect(() => {
        try {
            mixpanel.track("Chatroom - Alert Docker", {
                'ConversationId': "AlertID_" + chat.alertId + "_UserID_" + chat.assignedTo,
                'AlertTemplate': chat.alertTemplate,
                'AlertId': chat.alertId,
                'Severity': chat.severity
            })
            mixpanel.time_event("Chatroom Duration")
        } catch (error) {
            console.error(error)
        }
    }, [])

    useEffect(() => {
        console.log("AssociatedData", chat.associatedCamList);
        setAssociateData(chat.associatedCamList);
    }, [chat])

    useEffect(() => {
        if (hootWay != null) {
            let arr = hootWay?.filter((item) => item.alertId == chat.alertId);
            let htStatus = arr[0]?.hooterStatus;
            let twoWayStatus = arr[0]?.twoWayStatus;
            let disabledBtn = arr[0]?.btnDisabled;
            setIsHooterOn(htStatus);
            setIsSoundOn(twoWayStatus);
            setIsBtnDisabled(disabledBtn);
        }
    }, [hootWay]);

    //region useEffect end

    const ToggleChatWindow = (alertId) => {
        try {
            setOpen(!open)
            onChatOpen(alertId)
        } catch (error) {
            console.error(error)
        }
    }
    const CloseChatWindow = (e, chat) => {
        try {
            e.stopPropagation();
            onCloseAlertChat(chat)
            if (CHAT_CAM_CHANGES) {
                let arr = associateCam.filter((item) => item.alertId != chat.alertId)
                setAssociateCam(arr)
            }
            let isPresent = localStorage.getItem('hooterData');
            if(isPresent){
                let arr = JSON.parse(localStorage.getItem('hooterData'));
                let filteredArr = arr.filter((item) => item.alertId != chat.alertId);
                if(filteredArr.length == 0){
                    localStorage.removeItem('hooterData');
                  }else{
                    localStorage.setItem('hooterData', JSON.stringify(filteredArr));
                  }
            }

            //if(open == true){
            mixpanel.track("Chatroom Duration", {
                "ConversationId": "AlertID_" + chat.alertId + "_UserID_" + chat.assignedTo,
                "AlertTemplate": chat.alertTemplate,
                "AlertId": chat.alertId,
                "Severity": chat.severity
            })
            //}
        } catch (error) {
            console.error(error)
        }
    }

    function HooterClick(hooter, chat, isCloseBtn) {
        var resp = null
        if (hooter && hooter == 'on') {
            // let isAlreadySelected = hootWay.some((item) => item.hooterStatus === false);
            // if(isAlreadySelected){
            //     WarningToast("Hooter already enabled for another alert, close it and then start this.");
            //     return;
            // }
            setIsHooterOn(false)
            if (!isCloseBtn) {
                let tempArr = hootWay.map((item) => {
                    let obj;
                    if (item.alertId == chat.alertId) {
                        obj = {
                            ...item,
                            hooterStatus: false
                        }
                    } else {
                        obj = {
                            ...item
                        }
                    }
                    return obj;
                })
                setHootWay(tempArr);
            }
            resp = HooterOnOffReq(chat.privateIpAddress, chat.port, 'on', chat.panelBrand, cctvTypeName, chat.camUserName, chat.camPassword, chat.brandName)
        }
        if (hooter && hooter == 'off') {
            setIsHooterOn(true)
            if (!isCloseBtn) {
                let tempArr = hootWay.map((item) => {
                    let obj;
                    if (item.alertId == chat.alertId) {
                        obj = {
                            ...item,
                            hooterStatus: true
                        }
                    } else {
                        obj = {
                            ...item
                        }
                    }
                    return obj;
                })
                setHootWay(tempArr);
            }
            resp = HooterOnOffReq(chat.privateIpAddress, chat.port, 'off', chat.panelBrand, cctvTypeName, chat.camUserName, chat.camPassword, chat.brandName)
        }
        // if (resp && resp != null) {
        //     Swal.fire({text:resp})
        // }
    }

    function TwoWayAudioClick(audio, chat, isCloseBtn) {
        var resp = null;
        var resp1 = "";
        if (audio && audio == 'start') {
            let isAlreadySelected = hootWay.some((item) => item.twoWayStatus === false);
            if (isAlreadySelected) {
                WarningToast("Two way talk already enabled for another alert, close it and then start this.");
                return;
            }
            setIsSoundOn(false)
            if (!isCloseBtn) {
                let tempArr = hootWay.map((item) => {
                    let obj;
                    if (item.alertId == chat.alertId) {
                        obj = {
                            ...item,
                            twoWayStatus: false
                        }
                    } else {
                        obj = {
                            ...item
                        }
                    }
                    return obj;
                })
                setHootWay(tempArr);
            }
            resp1 = TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
            resp = TwoWayCommunicationReq(chat.privateIpAddress, chat.communicationPort, 'start', chat.camUserName, chat.camPassword, chat.brandName)
        }
        if (audio && audio == 'stop') {
            setIsSoundOn(true)
            if (!isCloseBtn) {
                let tempArr = hootWay.map((item) => {
                    let obj;
                    if (item.alertId == chat.alertId) {
                        obj = {
                            ...item,
                            twoWayStatus: true
                        }
                    } else {
                        obj = {
                            ...item
                        }
                    }
                    return obj;
                })
                setHootWay(tempArr);
            }
            resp = TwoWayCommunicationReq(chat.privateIpAddress, chat.communicationPort, 'stop', chat.camUserName, chat.camPassword, chat.brandName)
        }
    }


    const TwoWayCommunicationReq = async (privateIpAddress, communicationPort, action, user, password, brandName) => {
        try {
            if (privateIpAddress && communicationPort && user && password) {
                TwoWayCommunicationRequest(privateIpAddress, communicationPort, action, user, password, brandName).then((resp) => {
                    return resp
                })
            }
            else {
                TwoWayCommunicationRequest(privateIpAddress, communicationPort
                    , action, user, password, brandName).then((resp) => {

                        return resp
                    })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const HooterOnOffReq = async (privateIpAddress, port, action, panelBrand, cctvTypeName, user, password, brandName) => {
        try {
            if (privateIpAddress) {
                HooterOnOffRequest(privateIpAddress, port, action, panelBrand, cctvTypeName, user, password, brandName).then((resp) => {
                    return resp
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
        }, 1000)
    }
    const handleDragOpen = () => {
        if (openButton1) {
            ErrorToast("Kindly close the existing associate camera window, to open a new one.");
        } else {
            setOpenButton1(true)
            setAlertDetail(chat);
        }
    }

    const handleDragOpenTwo = () => {
        if (openButton1) {
            if (!ASSOC_DIRECT_OPEN) {
                ErrorToast("Kindly close the existing associate camera window, to open a new one.");
            }
        } else {
            setOpenButton1(true)
            setAlertDetail(chat);
        }
    }
    const getDate = () => {
        let d;
        if (MULTI_TIME_ZONE) {
            let offsetTemp = chat.OffsetMinutes != null ? chat.OffsetMinutes : 330
            d = multiTimeZoneHelper(offsetTemp);
        } else {
            d = new Date();
        }
        return d
      }
    const AttachalertevidencesParent = (e, fileName) => {
        setImageData(e);
        setFileName(fileName);
      }

      const attachEvidence = (text) => {
        let d = getDate();
        let myCurrentDatetime = d;
        let _datetime = ConvertDate(myCurrentDatetime)
        let rasaconvobj = {
            "evidenceType": "comment",
            "text": `${text}.`,
            "timestamp": _datetime,
            "type": "user"
        }
        let LoggedInUser = JSON.parse(localStorage.getItem('user'))
        let ConversationId = "AlertID_" + chat.alertId + "_UserID_" + localStorage.getItem("UserID")
        addrasaconversationindb({
            "assignedTo": LoggedInUser.UserID,
            "rasaConversationID": ConversationId,
            "uploadType": "file",
            "evidence": rasaconvobj,
            "conversation": null,
            "userType": "operator",
            "createdOn": _datetime
        }).then((resp) => {
            if (resp.status === "Ok") {
                let temp = updateChatflow + 1;
                setUpdateChatflow(temp);
            }
        }).catch(err => console.log(err));
      }

      const returnItems = (arr) => {
        console.log("returnItems", arr);
        console.log("returnItems", chat);
        return chat.associatedCamList.map((item, index) => (
          <div className="item" data-value={index}>
          <div className="chatflow">
              <WebRTCPlayer
                key={item.associatedCameraId}
                id={item.associatedCameraId + "87"}
                devId={item.associatedCameraId}
                url={item.rtspUrl}
                // url="rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=3%38subtype=1"
                isEncode={item.isTranscoded}
                actualTime={chat.actualTime}
                alertDetail={chat}
                item={item}
                upperDivCss="cameraHoverMainDiv"
                videoCss="player-responsive1"
                //videoCss="chatbotliveplayer"
                fullVideoCss="player-responsive-fullScreen"
                buttonCss="cameraButtonsMainDiv"
                noStreamDivCss="no-stream-found"
      
                playback={false}
                prePostDisplay={false}
                liveButton={true}
                exportCurrentVideoHide={true}
                showPlaybackBtn={false}
                hideTwoWay={false}
                imageToChat={AttachalertevidencesParent}
                attachEvidence={attachEvidence}
                showDirectToChat={true}
                showHooterIcon={true}
                // buttonDisable={buttonDisable}
              />
            </div>
        </div>
        ))
      }

      console.log("CHATDATA", chat)
    return (
        <>
            {/* <div className="ChatOver ChatBox" style={{display:"none"}}>
                <div>
                    <div className="chatFlowMobileHeader" style={!open ? { height: "60px" } : { height: "65px" }}>
                        <div className="chatFlowMobileHeaderTop" onClick={() => ToggleChatWindow(chat.alertId)}>
                            <div className="HeaderFireAlert">
                                <p className="chatFlowMobileAlertTypeText FireAlertTextp">Alert Id: {chat.alertId}</p>
                                <div className={`SeverityBGDiv ${chat.severity.toLowerCase()}-bg`}>
                                    <span className="ExtremeValue">
                                        {chat.severity}
                                    </span>
                                </div>
                                <div className="chatFlowClockDiv ml-0" hidden>
                                    <img className="chatFlowClockClass" src={ClockIcon} />
                                    <p className="chatFlowMobileAlertTime">01:59</p>
                                </div>
                                <div className="LinkButtonDiv">
                                    <button className="LinkButtonView" onClick={(event) => {
                                        event.stopPropagation()
                                        onMaximizeAlertChat(chat.alertId)
                                    }
                                    }>
                                        <img src={LinkIcon} />
                                    </button>
                                    <button className="LinkButtonView" onClick={(e) => {
                                        CloseChatWindow(e, chat)
                                        HooterClick('off', chat, true)
                                        TwoWayAudioClick('stop', chat, true)
                                    }
                                    }>
                                        <img src={RemoveIcon} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div className={`chatFlowMobileHeaderBottom ${open ? "panel-collapse" : "panel-collapse panel-close"}`} style={{ display: "none"}}>
                            <div className='ChatbotTextButtonDiv'>
                                <div>
                                    <p className="chatFlowMobileAlertBtoomText">{chat.alertType}</p>
                                </div>
                                <div>
                                    <p className="chatFlowMobileDetailText">{chat.site} | {chat.actualTime} {chat.Abbreviation? "("+chat.Abbreviation+")" : ""} |  {chat.deviceName}</p>
                                </div>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                {
                                    !true
                                        ?
                                        <button
                                            data-title='Associate Camera'
                                            onClick={() => handleDragOpen()}
                                            className='AssociateCamera AssociateDataTitle'>
                                            <img
                                                style={{ height: "22px" }}
                                                alt="Twoway"
                                                src={AssociateCamera}
                                            />
                                        </button>
                                        : null
                                }
                                {
                                    false
                                        ?
                                        <>
                                            <span>
                                                {isSoundOn &&
                                                    <button
                                                        disabled={isBtnDisabled}
                                                        data-title='TwowayStart'
                                                        className='TwoWayAudioToggle'>
                                                        <img
                                                            style={{ padding: "2px", height: "22px" }}
                                                            alt="Twoway Start"
                                                            src={MosaicTwowayStart}
                                                            onClick={() => TwoWayAudioClick('start', chat)} />
                                                    </button>
                                                }
                                                {!isSoundOn &&
                                                    <button
                                                        disabled={isBtnDisabled}
                                                        data-title='TwowayStop'
                                                        className='TwoWayAudioToggle'>
                                                        <img
                                                            style={{ padding: "2px", height: "22px" }}
                                                            src={MosaicTwowayStop}
                                                            alt="Twoway"
                                                            onClick={() => TwoWayAudioClick('stop', chat)} />
                                                    </button>
                                                }
                                            </span>
                                            <span>
                                                {isHooterOn &&
                                                    <button disabled={isBtnDisabled} data-title='HooterOn' className='TwoWayAudioToggle'>
                                                        <img style={{ padding: "1px", height: "26px", paddingBottom: "1px" }}
                                                            src={MosaicHooterOn}
                                                            alt="Hooter on"
                                                            onClick={() => HooterClick('on', chat)} />
                                                    </button>}
                                                {!isHooterOn &&
                                                    <button disabled={isBtnDisabled} data-title=' HooterOff' className='TwoWayAudioToggle'>
                                                        <img style={{ padding: "1px", height: "26px", paddingBottom: "1px" }}
                                                            src={MosaicHooterOff}
                                                            alt="Hooter off"
                                                            onClick={() => HooterClick('off', chat)} />
                                                    </button>}

                                            </span>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                        <div className={`chatFlowMobileHeaderBottom ${open ? "panel-collapse" : "panel-collapse panel-close"}`} style={{ display: "block" }} >
                            <div className="chatFlowMobileBtmOne">
                                <p className="chatFlowMobileAlertBtoomText">{chat.alertType}</p>
                            </div>

                            <div className='hooter-twoway-div' >
                                {
                                    !true
                                        ?
                                        <button
                                            data-title='Associate Camera'
                                            onClick={() => handleDragOpen()}
                                            className='AssociateCamera'>
                                            <img
                                                alt="Associate"
                                                style={{ height: "22px" }}
                                                src={AssociateCamera}
                                            />
                                        </button>
                                        : null
                                }
                            </div>

                            <div className="chatFlowMobileBtmTwo">
                                <p className="chatFlowMobileDetailText">{chat.site} | {chat.actualTime} {chat.Abbreviation? "("+chat.Abbreviation+")" : ""} |  {chat.deviceName}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`ChatDetailsDiv ${open ? "panel-collapse" : "panel-collapse panel-close"}`}>                
                    <div id={"Chatbotcomponentdiv:AlertID-" + chat.alertId}></div>
                    <div className='multi-chatbot-container-div'>
                        <div className="" style={{padding: "10px", margin: "0px"}}>
                            <AliceCarousel 
                                mouseTracking
                                // items={items}
                                // items={returnItems(associateData)}
                                renderDotsItem={renderDotsItem}
                                renderPrevButton={renderPrevButton}
                                renderNextButton={renderNextButton}
                            />
                            <div className="chatflow">
                         {
                            chat != null 
                            ?
                            <WebRTCPlayer
                            key={chat.deviceId}
                            id={chat.deviceId + "235"}
                            devId={chat.deviceId}
                            url={chat.rtspUrl}
                            isEncode={chat.isTranscoded}
                            item={chat}
                            actualTime={chat.actualTime}
                            alertDetail={chat}
                            upperDivCss="cameraHoverMainDiv"
                            videoCss="player-responsive1"
                            //videoCss="chatbotliveplayer"
                            fullVideoCss="player-responsive-fullScreen"
                            buttonCss="cameraButtonsMainDiv"
                            noStreamDivCss="no-stream-found"
                            playback={false}
                            prePostDisplay={false}
                             liveButton={true}
                             exportCurrentVideoHide={true}
                             showPlaybackBtn={false}
                             hideTwoWay={false}
                            imageToChat={AttachalertevidencesParent}
                            attachEvidence={attachEvidence}
                             showDirectToChat={true}
                            showHooterIcon={true}
                            // buttonDisable={buttonDisable}
                            />
                            : 
                            null} 
                            </div>
                        </div>
                        <ChatflowContainer
                            scrollToBottom={scrollToBottom}
                            AlertDetails={chat}
                            ConversationId={"AlertID_" + chat.alertId + "_UserID_" + localStorage.getItem('UserID')}
                            ResetAlertList={ResetAlertList}
                            hootWay={hootWay}
                            setHootWay={setHootWay}
                            isHooterOn={isHooterOn}
                            associateCam={associateCam}
                            setAssociateCam={setAssociateCam}
                            GetAlertDetails={getUpdatedAlertDetails}
                            updateChatflow={updateChatflow}
                            imageData={imageData}
                            fileName={fileName}
                        />
                        <div ref={messagesEndRef} />
                    </div>
                </div>
            </div> */}


            {/* New design start from here */}
            <div className="ChatOver ChatBox newChatboxDiv" >
                <div className='AlertFilterHeaderDiv'  onClick={() => ToggleChatWindow(chat.alertId)}>
                    <p className="chatFlowMobileAlertTypeText FireAlertTextp">Alert Id: {" "}<span>{chat.alertId}</span> </p>
                    <div className={`SeverityBGDiv ${chat.severity.toLowerCase()}-bg`}>
                        <span className="ExtremeValue">
                            {chat.severity}
                        </span>
                    </div>
                    <div className="chatFlowClockDiv ml-0" hidden>
                        <img className="chatFlowClockClass" src={ClockIcon} />
                        <p className="chatFlowMobileAlertTime">01:59</p>
                    </div>
                    <div className="LinkButtonDiv">
                        <button className="LinkButtonView" onClick={(event) => {
                            event.stopPropagation()
                            onMaximizeAlertChat(chat.alertId)
                        }
                        }>
                            <img src={FullScreenChatWhite} />
                        </button>
                        <button className="LinkButtonView" onClick={(e) => {
                            CloseChatWindow(e, chat)
                            HooterClick('off', chat, true)
                            TwoWayAudioClick('stop', chat, true)
                        }
                        }>
                            <img src={CloseChatWhite} />
                        </button>
                    </div>
                </div>

                <div className={`commonBorderDiv ${open ? "" : "panel-collapse panel-close"}`}>
                       
                        <div className={`chatFlowMobileHeaderBottom`} style={{ display: "flex" }} >
                            <div className="chatFlowMobileBtmOne">
                                <p className="chatFlowMobileAlertBtoomText">{chat.alertType}</p>
                            </div>

                            <div className='hooter-twoway-div' >
                                {
                                    !true
                                        ?
                                        <button
                                            data-title='Associate Camera'
                                            onClick={() => handleDragOpen()}
                                            className='AssociateCamera'>
                                            <img
                                                alt="Associate"
                                                style={{ height: "22px" }}
                                                src={AssociateCamera}
                                            />
                                        </button>
                                        : null
                                }
                            </div>

                            <div className="chatFlowMobileBtmTwo">
                                <p className="chatFlowMobileDetailText">{chat.site} | {chat.actualTime} {chat.Abbreviation? "("+chat.Abbreviation+")" : ""} |  {chat.deviceName}</p>
                            </div>
                        </div>
                    <div className="" style={{padding: "10px", margin: "0px"}}>
                        {
                            chat.associatedCamList.length > 0
                            ?
                            <div>
                            <AliceCarousel 
                                mouseTracking
                                // items={items}
                                items={returnItems(associateData)}
                                renderDotsItem={renderDotsItem}
                                renderPrevButton={renderPrevButton}
                                renderNextButton={renderNextButton}
                            />
                            </div>
                            : null
                        }
                            
                        <div className="chatflow">
                            {
                                chat != null && chat.associatedCamList.length == 0 
                                ?
                                <WebRTCPlayer
                                key={chat.deviceId}
                                id={chat.deviceId + "349"}
                                devId={chat.deviceId}
                                url={chat.rtspUrl}
                                isEncode={chat.isTranscoded}
                                item={chat}
                                actualTime={chat.actualTime}
                                alertDetail={chat}
                                upperDivCss="cameraHoverMainDiv"
                                videoCss="player-responsive1"
                                //videoCss="chatbotliveplayer"
                                fullVideoCss="player-responsive-fullScreen"
                                buttonCss="cameraButtonsMainDiv"
                                noStreamDivCss="no-stream-found"
                                playback={false}
                                prePostDisplay={false}
                                liveButton={true}
                                exportCurrentVideoHide={true}
                                showPlaybackBtn={false}
                                hideTwoWay={false}
                                imageToChat={AttachalertevidencesParent}
                                attachEvidence={attachEvidence}
                                showDirectToChat={true}
                                showHooterIcon={true}
                                // buttonDisable={buttonDisable}
                                />
                                : 
                                null

                            } 
                        </div>
                    </div>

                    <div className='multi-chatbot-container-div'>
                    <div className='ChatScrollHgt'>
                        <ChatflowContainer
                            scrollToBottom={scrollToBottom}
                            AlertDetails={chat}
                            ConversationId={"AlertID_" + chat.alertId + "_UserID_" + localStorage.getItem('UserID')}
                            ResetAlertList={ResetAlertList}
                            hootWay={hootWay}
                            setHootWay={setHootWay}
                            isHooterOn={isHooterOn}
                            associateCam={associateCam}
                            setAssociateCam={setAssociateCam}
                            GetAlertDetails={getUpdatedAlertDetails}
                            updateChatflow={updateChatflow}
                            imageData={imageData}
                            fileName={fileName}
                        />
                        <div ref={messagesEndRef} />
                        </div>
                    </div>
                </div>
            </div>
            {/* New design end from here */}
        </>
    )

}