import { BASE_URL } from './../config/index'

//#region Api Calling for rassaAutoken
export const getrasaauthtoken = async (obj) => {

    let url = `${BASE_URL}getrasaauthtoken`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api caling for ERT add to chatroom
export const adderttochatroom = async (obj) => {

    let url = `${BASE_URL}adderttochatroom`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api calling for ERT message
export const getertmessages = async (obj) => {

    let url = `${BASE_URL}getertmessages`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api calling for addrasaconversationdb 
export const addrasaconversationindb = async (obj) => {

    let url = `${BASE_URL}addrasaconversationindb`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api Calling for conversationtemplate crud
export const conversationtemplatecrud = async (obj) => {

    let url = `${BASE_URL}conversationtemplatecrud`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api calling for update alert 
export const updatealert = async (obj) => {

    let url = `${BASE_URL}updatealert`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api calling for add alert request
export const addalertrequest = async (obj) => {

    let url = `${BASE_URL}addalertrequest`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api calling for nearbyresource in chatflow (police,hospitals,fire station)
export const nearbyresource = async (obj) => {

    let url = `${BASE_URL}nearbyresource`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api calling to make a calling for Add user to site Make call part 
export const makecall = async (obj) => {

    let url = `${BASE_URL}makecall`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api calling for streamingurl 
export const getstreamingurl = async (obj) => {

    let url = `${BASE_URL}getroverstreamingurl`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion

//#region Api calling for sendemail 
export const sendemail = async (obj) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "sendemail";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("sendemail response: ", error);
        });
}
//#endregion

//#region Api Calling for chatflow conversation
export const getRasaConversation = async (obj) => {
    let url = `${BASE_URL}getRasaConversation`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
//#endregion