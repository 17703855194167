import React, { useState, useEffect, useRef, memo } from "react";

import { SearchIcon, SearchIconWhite } from "../../assets/images";

import Skeleton from "react-loading-skeleton";
import { DatePicker } from "rsuite";
import Pagination from "react-bootstrap/Pagination";
import { Tab, Tabs } from "react-bootstrap";

import { getHealthMonitorData } from "../../services/healthMonitoringService";

import { useDataAccessPagination } from "../../CustomHooks/useDataAccessPagination";
import HealthModal from "./HealthModal";
import HealthListView from "./HealthListView";
import { ConvertDate } from "../../utils/utils";
import ListViewComponent from "../../components/Lists/ListViewComponent";
import PaginationComponent from "../../components/Lists/PaginationComponent";

const HealthTable = (props) => {
  let {
    tableLoading,
    setTableLoading,
    refreshButton,
    setRefreshButton,
    isDarkTheme,
    customerIds,
    siteIds,
  } = props;
  //region useState start
  const [totalDevices, setTotalDevices] = useState(0);
  const [healthData, setHealthData] = useState(null);
  const [customerSearch, setCustomerSearch] = useState("");
  const [deviceSearch, setDeviceSearch] = useState("");
  const [show, setShow] = useState(false);
  const [interfaceSearch, setInterfaceSearch] = useState("");
  const [siteSearch, setSiteSearch] = useState("");
  const [interfaceTypeSearch, setInterfaceTypeSearch] = useState("Camera");
  const [currentStatusSearch, setCurrentStatusSearch] = useState("");
  const [datetimeSearch, setDateTimeSearch] = useState("");
  const [totalString, setTotalString] = useState(
    "devicename:;interfacename:;sitename:;devicetype:Camera;devicestatus:"
  );
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [timer, setTimer] = useState(null);
  const [zoneNameSearch, setZoneNameSearch] = useState("");
  const [entityNameSearch, setEntityNameSerach] = useState("");
  const [timeZoneSearch, setTimeZoneSearch] = useState("");
  //region useState end

  //region useRef start
  const firstRender = useRef(false);
  const tabChanged = useRef(false);
  const currTab = useRef("");
  //region useRef end

  const [
    nextClick,
    prevClick,
    pageCounter,
    currentPageNo,
    limit,
    totalPages,
    setCurrentPageNo,
    setTotalPages,
    maxPageAllowed,
    setPageCounter,
  ] = useDataAccessPagination(10, 1, 10, 1);

  //region useEffect start
  useEffect(() => {
    if (firstRender.current) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        searchListDetails();
      }, 500);
      setTimer(newTimer);
    }
    firstRender.current = true;
  }, [
    customerSearch,
    deviceSearch,
    interfaceSearch,
    siteSearch,
    currentStatusSearch,
    zoneNameSearch,
    entityNameSearch,
    timeZoneSearch,
  ]);

  useEffect(() => {
    setTableLoading(true);
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    getHealthMonitorData(
      currentPageNo,
      loggedInUser.UserUniqueID,
      totalString,
      customerIds ?? "",
      siteIds ?? ""
    )
      .then((res) => {
        if (res.Status == "success") {
          setHealthData(res.Data.DeviceStatusModels);
          setTableLoading(false);
          setTotalPages(parseInt(res.Data.Total));
          setTotalDevices(parseInt(res.Data.Total));
        }
      })
      .catch((err) => {
        console.log(err);
        setTableLoading(false);
      });
  }, [currentPageNo, customerIds, siteIds]);

  useEffect(() => {
    if (refreshButton) {
      searchListDetails();
      setRefreshButton(false);
    }
  }, [refreshButton]);
  //region useEffect end

  //#region functions start

  const dateTimeHandler = (e) => {
    let selectedDate = ConvertDate(new Date(e)).replaceAll(":", "-");
    setDateTimeSearch(selectedDate);
    searchListDetails(selectedDate, "DATETIME");
  };

  const searchListDetails = (data, type) => {
    let tempInterfaceType = interfaceTypeSearch.replace("0", "");
    let tempCurrentStatus = currentStatusSearch.replace("0", "");
    let tempEntityData = entityNameSearch.replace("0", "");
    let tempDateTime = datetimeSearch;
    if (type == "InterfaceType") {
      tempInterfaceType = data.replace("0", "");
    } else if (type == "CurrentStatus") {
      tempCurrentStatus = data.replace("0", "");
    } else if (type == "DATETIME") {
      tempDateTime = data;
    }

    let obj = {
      customername: customerSearch,
      devicename: deviceSearch,
      interfacename: interfaceSearch,
      sitename: siteSearch,
      devicetype: tempInterfaceType,
      devicestatus: tempCurrentStatus,
      zonename: zoneNameSearch,
      entityname: tempEntityData,
      timezone: timeZoneSearch,
    };
    let stringText = JSON.stringify(obj);
    let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";");
    setTotalString(tempStringText);
    getHMData(tempStringText);
  };

  const searchListDetailsTabs = (data, type) => {
    let tempInterfaceType = interfaceTypeSearch.replace("0", "");
    let tempCurrentStatus = currentStatusSearch.replace("0", "");
    let tempDateTime = datetimeSearch;

    if (type == "InterfaceType") {
      tempInterfaceType = data.replace("0", "");
    } else if (type == "CurrentStatus") {
      tempCurrentStatus = data.replace("0", "");
    } else if (type == "DATETIME") {
      tempDateTime = data;
    }

    let obj = {
      customername: "",
      devicename: "",
      interfacename: "",
      sitename: "",
      devicetype: tempInterfaceType,
      devicestatus: "",
      zonename: "",
      entityname: "",
      timezone: "",
    };
    let stringText = JSON.stringify(obj);
    let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";");
    setTotalString(tempStringText);
    getHMData(tempStringText);
  };

  const getHMData = (tempStringText) => {
    setTableLoading(true);
    let loggedInUser = JSON.parse(localStorage.getItem("user"));
    getHealthMonitorData(
      currentPageNo,
      loggedInUser.UserUniqueID,
      tempStringText,
      customerIds ?? "",
      siteIds ?? ""
    )
      .then((res) => {
        if (res.Status == "success") {
          setHealthData(res.Data.DeviceStatusModels);
          setTableLoading(false);
          setTotalPages(parseInt(res.Data.Total));
          setTotalDevices(parseInt(res.Data.Total));
          setCurrentPageNo(1);
          setPageCounter(1);
        }
      })
      .catch((err) => {
        console.log(err);
        setTableLoading(false);
      });
  };

  const selectedItem = (data) => {
    setSelectedDevice(data);
    setShow(!show);
  };

  const interfaceTypeChange = (e) => {
    setInterfaceTypeSearch(e.target.value);
    searchListDetails(e.target.value, "InterfaceType");
  };

  const tabSwitchChange = (value) => {
    currTab.current = value;
    console.log("Current tab => ", value);
    setInterfaceTypeSearch(value);
    setDeviceSearch("");
    setSiteSearch("");
    setInterfaceSearch("");
    setCurrentStatusSearch("");
    setZoneNameSearch("");
    setEntityNameSerach("");
    setTimeZoneSearch("");
    searchListDetailsTabs(value, "InterfaceType");
    tabChanged.current = true;
  };
  const entitystatusChange = (e) => {
    setEntityNameSerach(e.target.value);
  };
  const currentStatusChange = (e) => {
    setCurrentStatusSearch(e.target.value);
  };

  //#endregion functions end

  //#region ListViewComponent props

  console.count("HealthTable component render count: ");

  const tabsHeader = [
    { eventKey: "Camera", title: "Camera" },
    { eventKey: "DVR-NVR", title: "DVR/NVR" },
    { eventKey: "Storage Device", title: "Storage Device" },
    { eventKey: "Panel", title: "Panel" },
    { eventKey: "Sensor", title: "Sensor" },
    { eventKey: "ACS Controller", title: "ACS Controller" },
    { eventKey: "ACS Reader", title: "ACS Reader" },
    { eventKey: "Non-Integrated-Devices", title: "Non Integrated Devices" },
  ];

  const listViewHeader = [
    {
      title: "Customer Name(s)",
      render: () => (
        <>
          <div className="SearchTextDiv StatusSearchText">
            <input
              className="SearchViewInput"
              type="search"
              placeholder="Search"
              value={customerSearch}
              onChange={(e) => setCustomerSearch(e.target.value)}
            />
            {isDarkTheme ? (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIcon}
                onClick={() => searchListDetails()}
              />
            ) : (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIconWhite}
                height="15"
                onClick={() => searchListDetails()}
              />
            )}
          </div>
        </>
      ),
    },
    {
      title: "Site(s)",
      render: () => (
        <>
          <div className="SearchTextDiv StatusSearchText">
            <input
              className="SearchViewInput"
              type="search"
              placeholder="Search"
              value={siteSearch}
              onChange={(e) => setSiteSearch(e.target.value)}
            />
            {isDarkTheme ? (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIcon}
                onClick={() => searchListDetails()}
              />
            ) : (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIconWhite}
                height="15"
                onClick={() => searchListDetails()}
              />
            )}
          </div>
        </>
      ),
    },
    {
      title: "Interface Name(s)",
      render: () => (
        <>
          <div className="SearchTextDiv StatusSearchText">
            <input
              className="SearchViewInput"
              type="search"
              placeholder="Search"
              value={interfaceSearch}
              onChange={(e) => setInterfaceSearch(e.target.value)}
            />
            {isDarkTheme ? (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIcon}
                onClick={() => searchListDetails()}
              />
            ) : (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIconWhite}
                height="15"
                onClick={() => searchListDetails()}
              />
            )}
          </div>
        </>
      ),
    },
    {
      title: "Device Name(s)",
      render: () => (
        <>
          <div className="SearchTextDiv StatusSearchText">
            <input
              className="SearchViewInput"
              type="search"
              placeholder="Search"
              value={deviceSearch}
              onChange={(e) => setDeviceSearch(e.target.value)}
            />
            {isDarkTheme ? (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIcon}
                onClick={() => searchListDetails()}
              />
            ) : (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIconWhite}
                height="15"
                onClick={() => searchListDetails()}
              />
            )}
          </div>
        </>
      ),
    },

    {
      title: "Current Status(s)",
      render: () => (
        <>
          <div className="SearchTextDiv StatusSearchText">
            <select
              required
              className="ArrowSelect HMTableSelect"
              value={currentStatusSearch}
              onChange={(e) => currentStatusChange(e)}
            >
              <option key="0" value="0">
                - Select Data -
              </option>
              <option key="1" value="Online">
                Online
              </option>
              <option key="2" value="Offline">
                Offline
              </option>
            </select>
          </div>
        </>
      ),
    },
    {
      title: "Timezone(s)",
      render: () => (
        <>
          <div className="SearchTextDiv StatusSearchText">
            <input
              className="SearchViewInput"
              type="search"
              placeholder="Search"
              value={timeZoneSearch}
              onChange={(e) => setTimeZoneSearch(e.target.value)}
            />
            {isDarkTheme ? (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIcon}
                onClick={() => searchListDetails()}
              />
            ) : (
              <img
                className="EyeIcon"
                alt="Search-icon"
                src={SearchIconWhite}
                height="15"
                onClick={() => searchListDetails()}
              />
            )}
          </div>
        </>
      ),
    },
    {
      title: "Last Update Date Time",
      render: () => (
        <>
          <div className="mt-1">
            <DatePicker
              disabled={true}
              format="dd-MM-yyyy HH:mm:ss"
              placement="autoVerticalEnd"
              onOk={(e) => dateTimeHandler(e)}
            />
          </div>
        </>
      ),
    },
  ];

  //#endregion
  return (
    <>
      <div className="DeviceCurrentStatusDiv">
        <Tabs
          defaultActiveKey="Camera"
          className="ml-2 mt-2 mr-2"
          onSelect={(k) => {
            tabSwitchChange(k);
          }}
        >
          {tabsHeader?.map(({ eventKey, title }) => (
            <Tab key={eventKey} eventKey={eventKey} title={title}>
              <ListViewComponent
                totalDevices={totalDevices}
                isDarkTheme={props?.isDarkTheme}
                isLoading={tableLoading}
                header={listViewHeader
                  .map((header) => {
                    // Adjust headers based on the current tab (eventKey)
                    if (
                      currTab.current.toLowerCase() ===
                      "Non-Integrated-Devices".toLowerCase()
                    ) {
                      // For other tabs, filter out "Current Status(s)" and "Last Update Date Time"
                      if (
                        header.title.toLowerCase() ===
                        "Current Status(s)".toLowerCase()
                      ) {
                        return {
                          ...header,
                          title: "Entity Subtype(s)",
                          render: () => (
                            <>
                              <div className="SearchTextDiv StatusSearchText">
                                <select
                                  required
                                  className="ArrowSelect HMTableSelect"
                                  value={entityNameSearch}
                                  onChange={(e) => entitystatusChange(e)}
                                >
                                  <option key="0" value="0">
                                    - Select Data -
                                  </option>
                                  <option key="1" value="DVR">
                                    DVR
                                  </option>
                                  <option key="2" value="NVR">
                                    NVR
                                  </option>
                                  <option key="3" value="Hybrid">
                                    Hybrid
                                  </option>
                                  <option key="4" value="Camera">
                                    Camera
                                  </option>
                                  <option key="5" value="Storage Device">
                                    Storage Device
                                  </option>
                                </select>
                              </div>
                            </>
                          ),
                        };
                      } else if (
                        header.title.toLowerCase() ===
                        "Last Update Date Time".toLowerCase()
                      ) {
                        return null;
                      } else {
                        return header;
                      }
                      // return header.title.toLowerCase() !== "Last Update Date Time".toLowerCase() ? header : null;
                    } else if (
                      currTab.current.toLowerCase() ===
                      "Storage Device".toLowerCase()
                    ) {
                      // Modify headers for Storage Device tab
                      if (
                        header.title.toLowerCase() ===
                        "Interface Name(s)".toLowerCase()
                      ) {
                        return { ...header, title: "Associate NVR/DVR" };
                      } else if (
                        header.title.toLowerCase() ===
                        "Current Status(s)".toLowerCase()
                      ) {
                        return {
                          ...header,
                          render: () => (
                            <>
                              <div className="SearchTextDiv StatusSearchText">
                                <select
                                  required
                                  className="ArrowSelect HMTableSelect"
                                  value={currentStatusSearch}
                                  onChange={(e) => currentStatusChange(e)}
                                >
                                  <option
                                    // hidden
                                    key="0"
                                    value="0"
                                  >
                                    - Select Data -
                                  </option>
                                  <option key="3" value="Normal">
                                    Normal
                                  </option>
                                  <option key="4" value="Faulty">
                                    Faulty
                                  </option>
                                </select>
                              </div>
                            </>
                          ),
                        };
                      } else {
                        return header;
                      }
                    } else if (
                      currTab.current.toLowerCase() === "Sensor".toLowerCase()
                    ) {
                      // Modify headers for Sensor tab
                      if (
                        header.title.toLowerCase() ===
                        "Device Name(s)".toLowerCase()
                      ) {
                        return { ...header, title: "Sensor Name(s)" };
                      } else if (
                        header.title.toLowerCase() ===
                        "Interface Name(s)".toLowerCase()
                      ) {
                        return { ...header, title: "Associate Panel(s)" };
                      } else if (
                        header.title.toLowerCase() ===
                        "Current Status(s)".toLowerCase()
                      ) {
                        return {
                          ...header,
                          title: "Sensor State(s)",
                          render: () => (
                            <>
                              <div className="SearchTextDiv StatusSearchText">
                                <select
                                  required
                                  className="ArrowSelect HMTableSelect"
                                  value={currentStatusSearch}
                                  onChange={(e) => currentStatusChange(e)}
                                >
                                  <option key="0" value="0">
                                    - Select Data -
                                  </option>
                                  <option key="3" value="Zone Alert">
                                    Zone Alert
                                  </option>
                                  <option key="1" value="Zone Restoral">
                                    Zone Restoral
                                  </option>
                                  <option key="2" value="No Information">
                                    No Information
                                  </option>
                                </select>
                              </div>
                            </>
                          ),
                        };
                      } else {
                        return header;
                      }
                    } else {
                      // For other tabs, filter out "Current Status(s)" and "Last Update Date Time"
                      return header; // Keep all headers for Non-Integrated-Devices tab
                    }
                  })
                  .filter((header) => header)}
                showModal={show}
                setShowModal={setShow}
                listComponent={HealthListView}
                listComponentProps={{
                  eventKey,
                  setShow,
                  show,
                  selectedItem,
                  showFour:
                    eventKey.toLowerCase() ===
                    "Non-Integrated-Devices".toLowerCase(),
                  isSensor: eventKey.toLowerCase() === "Sensor".toLowerCase(),
                  isNotPointer:
                    eventKey.toLowerCase() === "Sensor".toLowerCase() ||
                    eventKey.toLowerCase() === "ACS".toLowerCase() ||
                    eventKey.toLowerCase() ===
                      "Non-Integrated-Devices".toLowerCase(),
                }}
                data={healthData}
                paginationComponent={PaginationComponent}
                paginationComponentProps={{
                  nextClick,
                  prevClick,
                  pageCounter,
                  currentPageNo,
                  limit,
                  totalPages,
                  setCurrentPageNo,
                  setTotalPages,
                  maxPageAllowed,
                  setPageCounter,
                }}
              />
            </Tab>
          ))}
        </Tabs>
      </div>
      {show ? (
        <HealthModal
          show={show}
          setShow={setShow}
          selectedDevice={selectedDevice}
          isDarkTheme={isDarkTheme}
        />
      ) : null}
    </>
  );
};

export default memo(HealthTable);
