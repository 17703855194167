import React, { useEffect } from 'react'

import { useForm } from "react-hook-form";

import Select from "react-select";

const AddERTForm = React.forwardRef((props, ref) => {
    let {
      onSubmit,
      ertLevels,
      site,
      selectedERT,
      userOptions,
      onUserRoleChange,
      selectedUserRole,
      setSelectedUserRole,
      siteUserFieldsVisibility,
      isSiteEdit,
    } = { ...props };
    const { register, handleSubmit, setValue,getValues,formState: { errors } } = useForm({
      defaultValue: { ...selectedERT },
    });
  
    useEffect(() => {
      setValue("contactLevel", selectedERT.ContactLevel);
      setValue("designation", selectedERT.Designation);
      setValue("mobile", selectedERT.Phone);
      setValue("email", selectedERT.EmailID);
      setValue("isEmail", selectedERT.Email);
      setValue("emergencyContactID", selectedERT.EmergencyContactID);
      setValue("name", selectedERT.ContactName);
      setValue("siteID", selectedERT.SiteID);
  
      if (selectedERT.IsERT == true && selectedERT.IsCustomerSPOC == true) {
        setSelectedUserRole(userOptions);
      } else if (
        selectedERT.IsERT == true &&
        selectedERT.IsCustomerSPOC == false
      ) {
        const userOptions = [{ value: "ERTMember", label: "ERT Member" }];
        setSelectedUserRole(userOptions);
      } else if (
        selectedERT.IsERT == false &&
        selectedERT.IsCustomerSPOC == true
      ) {
        const userOptions = [{ value: "CustomerSPOC", label: "Customer SPOC" }];
        setSelectedUserRole(userOptions);
      } else {
        setSelectedUserRole([]);
      }
    }, [selectedERT]);
  
  
    const handleChange = (event) => {
      setValue("isEmail", event.target.checked);
    };
  
    // function regarding on email verification 
    const allowedEmailDomains = ["com", "net", "org", "in"];
  
    const isValidEmail = (email) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    };
  
    const isAllowedEmailDomain = (email) => {
      const domain = email.split(".").pop().toLowerCase();
      return allowedEmailDomains.includes(domain);
    };
  
    const validateEmail = (value) => {
      if (!value) {
        return "Email is required";
      }
      if (!isValidEmail(value)) {
        return "Invalid email address";
      }
      if (!isAllowedEmailDomain(value)) {
        return `Email should end with ${allowedEmailDomains.join(", ")}`;
      }
      return true;
    };
  
    return (
      <div
        className="SiteList ConfigUserToSite"
      >
        {/* rgr 30/1/23 */}
        <div className="configureUserForm-container">
          <form style={{ width: "100%" }}
            onSubmit={handleSubmit((e) =>
              onSubmit(e, site.SiteID, selectedUserRole))}
          >
            <Select
              isDisabled={!isSiteEdit}
              isMulti
              name="colors"
              options={userOptions}
              className="SiteNameTextBox px-0 border-0"
              hideSelectedOptions={false}
              value={selectedUserRole}
              onChange={(e) => onUserRoleChange(e)}
              placeholder="Select User Role*"
            />
  
            {siteUserFieldsVisibility && ( // by default form fields are not visible on site selection
              <>
                {selectedUserRole &&
                  ((selectedUserRole.find((x) => x.value == "CustomerSPOC") &&
                    selectedUserRole.find((x) => x.value == "ERTMember")) ||
                    selectedUserRole.find((x) => x.value == "ERTMember")) && (
                    <>
                      <select
                        {...register("contactLevel")}
                        className="SiteNameTextBox"
                        placeholder="Select Contact Level"
                      >
                        <option value="" selected disabled hidden >Contact Level*</option>
                        {ertLevels &&
                          ertLevels.map((val) => (
                            <option value={val.ertLevelId} key={val.level}>
                              {val.level}
                            </option>
                          ))}
                      </select>
                    </>
                  )}
                <input
                  {...register("designation")}
                  className="SiteNameTextBox"
                  placeholder="Designation* e.g. Manager"
                  disabled={!isSiteEdit}
                />
                <input
                  disabled={!isSiteEdit}
                  {...register("name")}
                  className="SiteNameTextBox"
                  placeholder="Name* e.g. Rupesh"
                />
  
                <p className="SiteNameText">
                  <input
                    className="checkbox_custom"
                    type="checkbox"
                    disabled={true}
                    defaultChecked={true}
                  />
                  Make Call <span className="mandat-field">*</span>
                </p>
                <input
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  type={"number"}
                  disabled={!isSiteEdit}
                  {...register("mobile")}
                  className="SiteNameTextBox"
                  placeholder="Mobile e.g. 9876543210"
                  min={"0"}
                  maxLength={"10"}
                />
  
                {selectedUserRole &&
                  ((selectedUserRole.find((x) => x.value == "CustomerSPOC") &&
                    selectedUserRole.find((x) => x.value == "ERTMember")) ||
                    selectedUserRole.find((x) => x.value == "ERTMember")) && (
                    <>
                      <label className="SiteNameText">
                        <input
                          id={selectedERT.EmergencyContactID}
                          className="checkbox_custom"
                          type="checkbox"
                          key={selectedERT.EmergencyContactID}
                          onChange={(e) => handleChange(e)}
                          defaultChecked={selectedERT.Email}
                        />
                        Configure Email
                      </label>
  
                      <input
                        {...register("email", {
                          validate: validateEmail,
                        })}
                        className="SiteNameTextBox"
                        placeholder="Email e.g. abc@xyz.com"
                      />
                      {errors.email && (
                        <p style={{ color: "red" }}>{errors.email.message}</p>
                      )}
                    </>
                  )}
                <div className="ButtonDiv addUserToSite-btn-div">
                  {selectedERT.EmergencyContactID == 0 ? (
                    <button
                      type="submit"
                      className="NextMoveButton addUserToSite-addMember-btn"
                      disabled={!isSiteEdit}
                    >
                      <span className="ViewUpdate">Add Member</span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="NextMoveButton addUserToSite-updateMember-btn"
                      disabled={!isSiteEdit}
                    >
                      <span className="ViewUpdate">Update Member</span>
                    </button>
                  )}
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    );
  });

export default AddERTForm