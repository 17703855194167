import React from 'react'

const FilterToolWithExtraProps = (WrappedComponent) => {
    return ({ wrappedComponentProps, extendedComponentProps }) => {
        return (
            <WrappedComponent {...wrappedComponentProps} />
        )
    }
}

export default FilterToolWithExtraProps
