import React, { memo } from 'react';
import { Pagination } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { SearchIcon, SearchIconWhite } from "../../assets/images";
import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination';
import PaginationComponent from './PaginationComponent';


const ListViewComponent = (props) => {
    console.count("ListViewComponent component render count: ")
    const { totalDevices, header, isDarkTheme, isLoading, setShowModal, showModal, data, selectedItem, listComponent: Component, listComponentProps: componentProps, paginationComponent: PaginationComponent, paginationComponentProps } = props;
    const { nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter } = paginationComponentProps;

    return (
        <>
            <div className="card-body p-0">
                <table
                    className="table table-text-ext table-fixed report-table healthmonitoringTable mb-2"
                    id="alert-activity-report"
                >
                    <thead>
                        <tr>
                            {
                                header && header.length > 0 && header?.map(item => (
                                    <>
                                        <th key={item?.title}>
                                            <p>{item?.title}</p>
                                            {
                                                item?.render()
                                            }
                                        </th>
                                    </>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody className='OnHoverMouseTableRow'>
                        {
                            isLoading ?
                                (
                                    <>
                                        {
                                            header && header.length > 0 &&
                                            header.forEach((item, index) => (
                                                <tr key={index} onClick={() => setShowModal(!showModal)}>
                                                    <td>
                                                        <Skeleton className="width70pct" />
                                                    </td>
                                                </tr>

                                            ))
                                        }
                                    </>
                                )
                                :
                                (
                                    <>
                                        {
                                            data && data.length > 0 ?
                                                (
                                                    data.map((item, index) => (
                                                        // <HealthListView key={item.DeviceId} setShowModal={setShowModal} showModal={showModal} data={item} selectedItem={selectedItem} />
                                                        <Component {...componentProps} key={index} data={item} />
                                                    ))
                                                )
                                                :
                                                (
                                                    <div class="no-report-found"><p className='noRecordFoundText'>No Record Available.</p></div>
                                                )
                                        }
                                    </>
                                )
                        }
                    </tbody>
                    {
                        totalPages === 0 || totalPages <= limit
                            ? <div className="PaginationDiv mb-2 mt-2">
                                <h5 className='PageNumbers'>{totalDevices === 0 ? 0 : 1} - {totalDevices}</h5>
                                <span>of</span>
                                <h5 className='PageNumbers'>{totalDevices}</h5>
                                <Pagination>
                                    <Pagination.Prev disabled />
                                    <Pagination.Item active disabled>{1}</Pagination.Item>
                                    <Pagination.Next disabled />
                                </Pagination>
                            </div>
                            :
                            <div className="PaginationDiv mb-2 mt-2">
                                <h5 className="PageNumbers">
                                    {
                                        totalDevices === 0 ? 0
                                            : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                                    -
                                    {
                                        (currentPageNo * limit) > (totalDevices)
                                            ? (totalDevices)
                                            : currentPageNo * limit
                                    }
                                </h5>
                                <span>of</span>
                                <h5 className="PageNumbers">{totalDevices}</h5>

                                <Pagination>
                                    <Pagination.Prev onClick={prevClick} disabled={currentPageNo === 1} />
                                    {
                                        PaginationComponent(currentPageNo, maxPageAllowed, setCurrentPageNo)
                                    }
                                    <Pagination.Next onClick={nextClick} disabled={currentPageNo === maxPageAllowed} />
                                </Pagination>
                            </div>
                    }
                </table>
            </div>
        </>
    )
}

export default memo(ListViewComponent);
