import React from 'react'
import { SearchIcon, SearchIconWhite } from '../../assets/images'
import { useSelector } from 'react-redux';
import { isNotNullOrEmpty } from '../../utils/utils';

const CameraSidePanelList = ({ data, handleCheckBoxChange, handleListItemClick, handleListSearchChange, listSearchText }) => {
    const cameraDetails = useSelector(state => state?.camera?.camera?.cameraDetails);

    return (
        <>
            <div className="m-2 HeaderSiteList">
                <input
                    id="Search_txt"
                    className="SearchViewInput1"
                    type="text"
                    value={listSearchText}
                    onChange={handleListSearchChange}
                    placeholder="Search Camera"
                />
                <div className="SearchTextDivConfigTool">
                    {true ? (
                        <img
                            className="EyeIcon"
                            src={SearchIcon}
                            alt="search icon"
                        />
                    ) : (
                        <img
                            className="EyeIcon"
                            src={SearchIconWhite}
                            height="15"
                            alt="search icon"
                        />
                    )}
                </div>
            </div>

            <div className="CameraFilterList">
                {
                    !isNotNullOrEmpty(data?.filter(item => item?.CameraName.toLowerCase()?.includes(listSearchText?.toLowerCase())))
                    &&
                    <div className="no-data-found">
                        <p>No Data Found.</p>
                    </div>
                }
                {
                    isNotNullOrEmpty(data) &&
                    data?.filter(item => item?.CameraName.toLowerCase()?.includes(listSearchText?.toLowerCase()))?.map(item => (
                        <div
                            // className={`OneList ${item?.isChecked ? "SelectedList" : ""}`} 
                            className={`OneList ${cameraDetails?.ParentCameraID == item.DeviceId ? "SelectedList" : ""}`}
                            onClick={(e) => handleListItemClick(e, item)}>
                            <p className="ListofText">
                                <span>{item?.CameraName}</span> - <span className="SiteNameText">{item?.SiteName}</span>
                            </p>
                            <div style={{ display: "flex", alignItems: "center" }} onClick={e => e.stopPropagation()}>
                                <div>
                                    <p className="SiteNameText m-0">{item?.Abbreviation}</p>
                                </div>
                                {item?.hasOwnProperty("isChecked") && (
                                    <div className="ml-3">
                                        <input type="checkbox" checked={item?.isChecked} onChange={(e) => {
                                            // e?.stopPropagation(); // Prevent the event from bubbling up to the parent
                                            handleCheckBoxChange(e, item);
                                        }} />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                }

            </div>
        </>
    )
}

export default CameraSidePanelList
