import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/RouterConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import { ProvideAuth } from "./navigation/Auth/ProvideAuth";
/* import "./app.css"; */
import "./app.scss";
import "./app.responsive.css";
import ConversationStateContext from "./Context/ConversationStateContext";
import AlertContext from "./Context/AlertContext";

//
function App() {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <ConversationStateContext>
          <AlertContext>
            <RouterConfig />
          </AlertContext>
        </ConversationStateContext>
      </BrowserRouter>
    </ProvideAuth>
  );
}

export default App;
