import React,{useState} from 'react';

const initialState = []

export const Context = React.createContext();

const AlertContext = ({children}) => {
    const [alertState, setAlertState] = useState(initialState)
    
    return(
        <Context.Provider value={[alertState,setAlertState]}>{children}</Context.Provider>
    );
}

export default AlertContext;