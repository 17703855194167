import React, { useState } from 'react'
import { DatePicker } from "rsuite";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from 'react-bootstrap/Container';

import AlertTemplateMessage from '../Configuration/AlertTemplate/AlertTemplateMessage';
import { Spinner } from 'react-bootstrap';
import { AlertCameraTilePng, AttachIcon, BotAvatarIcon, Cancel, ERTAvatarIcon, Filter, MikeIcon, RefreshIcon, SendIconBlue } from '../../assets/images';

export default function AlertMosaicView() {
    const [cameraFilterShow, setcameraFilterShow] = useState(true);
    const [alertcameraDetailShow, setalertcameraDetailShow] = useState(true);
    const toggleFilter = () => {
      setcameraFilterShow(!cameraFilterShow);
    };
  
    const showDetailsAlertCamera = () =>{
      setalertcameraDetailShow(!alertcameraDetailShow);
    }
  
    return (
      <div>
        {/* <Container fluid> */}
          <Row className="m-0">
            <Col lg={8} xs={12} md={6}>
            <div className="LeftContainer">
  
              <div className='MainCameraAlertDiv' style={{ display: cameraFilterShow ? "block" : "block" }}>          
                <div className='AlertDropdownHeaderDiv'>
                  {/* <div className='InputSelectionDropdown'>
                    <div className="inputMainContainer w40dot75rem">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>Select Customer</label>
                        <select className="inputType ArrowSelectClass">
                          <option key="0" disabled={false} value="0" id="0">Select Customer</option>
                        </select>
                        <p className="Error_P" style={{float: "left"}}>
                          <span> 
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div className='InputSelectionDropdown'>
                    <div className="inputMainContainer w40dot75rem">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>Select Site</label>
                        <select className="inputType ArrowSelectClass">
                          <option key="0" disabled={false} value="0" id="0">Select Site</option>
                        </select>
                        <p className="Error_P" style={{float: "left"}}>
                          <span> 
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div className='InputSelectionDropdown'>
                    <div className="inputMainContainer w40dot75rem">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>Select Device Type</label>
                        <select className="inputType ArrowSelectClass">
                          <option key="0" disabled={false} value="0" id="0">Select Device Type</option>
                        </select>
                        <p className="Error_P" style={{float: "left"}}>
                          <span> 
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div className='InputSelectionDropdown'>
                    <div className="inputMainContainer w40dot75rem">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>Select Alert Type</label>
                        <select className="inputType ArrowSelectClass">
                          <option key="0" disabled={false} value="0" id="0">Select Alert Type</option>
                        </select>
                        <p className="Error_P" style={{float: "left"}}>
                          <span> 
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div className='InputSelectionDropdownBtn'>
                    
                    <button class="AlertFilter" onClick={toggleFilter}>
                      <div class="badge badge-pill badge-danger"  style={{ top: "2px", right: "4px", minWidth: "25px" }}>
                        <p class="filteractivetext">10</p>
                      </div>
                      <img src={Filter} style={{ height: "15px", float: "left" }}  className="camera-icon-height" alt="Alert filter icon"/>
                    </button>

                    <button class="AlertFilter ml-2">
                      <RefreshIcon height={15} />
                      </button>
  
                  </div> */}
                </div>
  
                <div className='AlertCameraGridList'>
  
                  <div className='AlertCameraTileDiv' style={{display:"block"}} onClick={showDetailsAlertCamera}>
                    <img src={AlertCameraTilePng} style={{height:"90%", width:"100%", padding:"2px"}}/>
                    <progress value={null} />
                  </div>
  
                  <div className='AlertCameraTileDiv'>
                    {/* <p>No Alert To Display</p> */}
                    <div style={{display: "flex", flexDirection: "column"}}>
                    <Spinner style={{marginLeft: '4rem'}} animation="border" variant="primary" />
                      <span style={{padding: '1rem'}}>
                      Loading New Alert
                      </span>
                    </div>
                  </div>
  
  
                  
  
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                  <div className='AlertCameraTileDiv'>
                    <p>No Alert To Display</p>
                  </div>
                </div>
              </div>
  
              <div className='AlertCameraTileFilterBorderDiv' style={{ display: cameraFilterShow ? "none" : "block" }}>
                <div className='AlertFilterHeaderDiv'>
                  <p className='AlterfilterText'>Filter</p>
                  <button style={{backgroundColor:"unset"}} onClick={toggleFilter}>
                    <img className="EyeIcon" alt='cancel icon' src={Cancel} />
                  </button>
                </div>
                <div className='AlertFilterHgtDiv'>
                    <div className="inputMainContainer w40dot75rem mt35">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>Select Country</label>
                        <select className="inputType ArrowSelectClass">
                          <option key="0" disabled={false} value="0" id="0">Select Country</option>
                        </select>
                        <p className="Error_P" style={{float: "left"}}>
                          <span> 
                          {/*  {countryError} */}
                          </span>
                        </p>
                      </div>
                    </div>
  
                    <div className="inputMainContainer w40dot75rem mt35" >
                        <div className="inputTypeLabelContainer">
                          <label className='formFieldLabels'>Select Customer</label>
                          <select className="inputType ArrowSelectClass">
                            <option key="0" disabled={false} value="0">Select Customer</option>                               
                          </select>
                        </div>
                      <p className="Error_P" style={{float: "left"}}><span>
                      {/*  {customerError} */}
                        </span></p> 
                    </div>
  
                    <div className="inputMainContainer w40dot75rem mt35">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>Select State</label>
                        <select className="inputType ArrowSelectClass">
                          <option key="0" disabled={false} value="0">Select State</option>                              
                        </select>
                        <p className="Error_P" style={{float: "left"}}><span> 
                          {/* {stateError}  */}
                          </span></p>
                      </div>
                    </div>
  
                    <div className="inputMainContainer w40dot75rem mt35">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>Select City</label>
                        <select className="inputType ArrowSelectClass">
                          <option key="0" disabled={false} value="0">Select City</option>                              
                        </select>
                        <p className="Error_P" style={{float: "left"}}><span> 
                          {/* {cityError}  */}
                          </span></p>
                      </div>
                    </div>
  
                    <div className="inputMainContainer w40dot75rem mt35">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>Pin Code</label>
                          <input 
                            className="inputType"
                            placeholder="Pin Code"
                          />
                          <p className="Error_P" style={{float: "left"}}><span> 
                          {/*  {pincodeError} */}
                          </span></p>
                      </div>
                    </div>
  
                    <div className="inputMainContainer w40dot75rem mt35">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>From Date & Time</label>
                        <DatePicker format="dd-MM-yyyy HH:mm:ss" placement="autoVerticalEnd" />
                      </div>
                    </div>
  
                    <div className="inputMainContainer w40dot75rem mt35">
                      <div className="inputTypeLabelContainer">
                        <label className='formFieldLabels'>To Date & Time</label>
                        <DatePicker format="dd-MM-yyyy HH:mm:ss" placement="autoVerticalEnd" />
                      </div>
                    </div>
                </div>
                <div style={{textAlign:"center"}}>                
                  <button className="DataAccessEdit" style={{ width: "100px" }}>
                    <span className="ViewUpdate">Apply Filter</span>
                  </button>
                  <button className="DataAccessEdit" style={{ width: "100px" }} onClick={toggleFilter}>
                    <span className="ViewUpdate">Clear Filter</span>
                  </button>
                </div>
              </div>
  
            </div>
            </Col>
            <Col lg={4} xs={12} md={6}>       
                <div className="RightContainer">
                  <div style={{ display: alertcameraDetailShow ? "block" : "none" }}>
                    <div className='AlertFilterHeaderDiv'>
                      <p className='AlterfilterText'>No alert selected to operate</p>
                    </div> 
                    <div style={{ padding: "20px" }}>
                      <AlertTemplateMessage message={"Select an alert to process."} />
                    </div>
                  </div>
                  <div style={{ display: alertcameraDetailShow ? "none" : "block" }}>
                    <div className='AlertFilterHeaderDiv'>
                        <p className='AlterfilterText'>Alert ID:  
                        <span>
                          123456ABC
                        </span>
                        </p>
                    </div> 
                    <div style={{ padding: "10px" }}>
                      <div className='CameragridAssociateDiv'>
                      <div className="chatflow">
                              {/* <WebRTCPlayer /> */}
                        </div>
                      </div>
                      <div className='CameragridChatBotDiv'>
                          <div className='AlertCameraChatbotHgtDiv'>
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowTimeIconContainerBig">
                                    <img className="chatFlowChatTimeIconBig" src={BotAvatarIcon} />
                                </div>
                                <p className="chatFlowChatTimeIconTextBig">21-05-2024</p>
                            </div>
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowChatBoxBig" style={{background: "#E6E9F0"}}>
                                    <span className="chatFlowChatTextBig">Camera block alert generated.</span>
                                </div>
                            </div>
                            <div style={{display: "grid", justifyContent: "right"}}>
                                <div className="chatFlowBoxContainer">
                                    <p className="chatFlowChatTimeIconTextBig">21-05-2024</p>
                                    <div className="chatFlowTimeIconContainerBig">
                                        <img className="chatFlowChatTimeIconBig" src={ERTAvatarIcon} />
                                    </div>
                                </div>
                                <div className="chatFlowBoxContainer-bot">
                                    <div className="chatFlowChatBoxBig" style={{background: "#E6E9F0"}}>
                                        <p className="chatFlowChatTextBig">Alert acknowledged successfully</p>
                                    </div>
                                </div>
                            </div>
                            <div className="react-functional-chatbot-inner-div">
                                <div className="chatFlowBoxContainer-bot">
                                    <div className="chatFlowTimeIconContainerBig">
                                        <img className="chatFlowChatTimeIconBig" src={BotAvatarIcon} alt="Bot" />
                                    </div>
                                    <p className="chatFlowChatTimeIconTextBig">21-05-2024</p>
                                </div>
                                <div className="chatFlowBoxContainer-bot">
                                    <div className="chatFlowChatBoxBig" style={{background: "#E6E9F0"}}>
                                        <span className="chatFlowChatTextBig">Alert has already been acknowledged by another operator.</span>
                                    </div>
                                </div>
                            </div>
  
                            <div className="chatFlowBoxContainer">
                                  <p className="chatFlowChatTimeIconTextBig">21-05-2024</p>
                                  <div className="chatFlowTimeIconContainerBig">
                                      <img className="chatFlowChatTimeIconBig" src={BotAvatarIcon} />
                                  </div>
                              </div>
                              <div className="chatFlowBoxContainer">
                                  <div className="chatFlowChatBoxBig" style={{background: "#E6E9F0"}}>
                                      <p className="chatFlowChatTextBig">Great ! Please record the conversation with the fire brigade team and share.</p>
                                  </div>
                              </div>
                          </div>
                          <div className="chatbotCommentInput">
                            <div className='GridChatCommentInputDiv'>
                                <input
                                  id="Search_txt_site"
                                  className="chatbotInput"
                                  type="text"
                                  placeholder="Enter comments here"
                                />
                            </div>
                            <div className="AlertSendAttachMicButtons">
                              <button style={{backgroundColor:"transparent"}}>
                                  <img src={SendIconBlue} height={20} width={20} />
                              </button>
                              <button style={{backgroundColor:"transparent"}}>
                                  <img src={AttachIcon} className='ml-2' />
                              </button>
                              <button className="MikeBtn ml-2">
                                  <img src={MikeIcon}/>
                              </button>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
  
  
  
                </div>
            </Col>
          </Row>
        {/* </Container> */}
   </div>
    )
}
