import React, { useEffect } from 'react'
import TimerWidget from './TimerWidget'
import { useDispatch, useSelector } from 'react-redux'
import { playbackActions } from '../../redux/features/shared/playback';
import { MULTI_TIME_ZONE } from '../../config';
import { subMinutes, differenceInMinutes } from 'date-fns'
import { addMinutesToDate, formatJSDateObject, getMinutesDifference, multiTimeZoneHelper, replaceTimeToDate, subtractMinutes } from '../../utils/utils';
import { WarningToast } from '../../CustomHooks/WarningToast';

const TimerInput = () => {

    const dispatch = useDispatch();
    const playbackState = useSelector(state => state?.playback);
    const { startDate, timerInputState, defaultEndDate, endDate } = playbackState;
    const { timeString } = timerInputState;

    useEffect(() => {
        let timerStr = `${new Date(startDate).getHours()}_${new Date(startDate).getMinutes()}_${new Date(startDate).getSeconds()}`;
        dispatch(playbackActions.setTimerInputTimeString(timerStr));
    }, [startDate]);

    const handleTimerInputCB = (data, type) => {
        let arr = timeString.split("_");
        let temp = data.toString().length;
        let correctDigit = "";
        if (temp === 1) {
            correctDigit = "0" + data.toString();
        } else {
            correctDigit = data.toString();
        }

        if (type === "HOUR") {
            arr[0] = correctDigit;
        } else if (type === "MIN") {
            arr[1] = correctDigit;
        } else {
            arr[2] = correctDigit;
        }

        let tempStartDateObj = replaceTimeToDate(startDate, arr?.join("_"));




        let tempEndDateObj = subtractMinutes(endDate, defaultEndDate);
        if (tempStartDateObj > tempEndDateObj) {
            WarningToast("Selected time should be 15 minutes lesser than the current time!");
        }

        dispatch(playbackActions.setTimerInputTimeString(arr.join("_")));
        dispatch(playbackActions.setStartDate(tempStartDateObj.toString()));
    }

    return (
        <div className='popup_body' style={{ gap: "0.3rem" }}>
            <TimerWidget maxCount={23} handleTimerInputCB={handleTimerInputCB} type="HOUR"
                min="00"
                max="23"
                maxLength="2"
                defaultValue={new Date(startDate).getHours()}
            />
            :
            <TimerWidget maxCount={59} handleTimerInputCB={handleTimerInputCB} type="MIN"
                min="00"
                max="59"
                maxLength="2"
                defaultValue={new Date(startDate).getMinutes()}
            />
            :
            <TimerWidget maxCount={59} handleTimerInputCB={handleTimerInputCB} type="SEC"
                min="00"
                max="59"
                maxLength="2"
                defaultValue={new Date(startDate).getSeconds()}
            />
        </div>
    )
}

export default TimerInput
