import React, { useEffect, useState } from "react";

import { EditQc, DeleteQc } from '../../../../assets/images'

import AddZoneForm from "./AddZoneForm";

const ConfigurePanelZones = ({
  selectedDevice,
  devicesmasterlist,
  OnAddIDSPanel,
  setselectedZone,
  selectedZone,
  isDeviceEdit
}) => {
  let ZoneObj = {
    iDSPanelZoneTypeID: 0,
    zoneNo: "",
    zoneName: "",
    idsPanelZonesID: 0,
  };
  const [refreshZoneForm, setRefreshZoneForm] = useState(false);
  const [refreshZoneList, setRefreshZoneList] = useState(true);

  useEffect(() => {
    setRefreshZoneForm(true);
  }, [selectedZone]);

  useEffect(() => {
    setRefreshZoneList(true);
  }, [selectedDevice]);

  return (
    <>
      {
        selectedDevice && selectedDevice.panelMasterId && selectedDevice.panelMasterId != 0 &&
        <div className="association-main-section">
          <h1 className="MainHeaderName mb-0">Manage Zones</h1>
          <div>
            <div className="association-section-header">
              <p className="mb-0" style={{ width: "40%" }}>
                Zones
                <br />
              </p>
              <p className="mb-0">
                <br />
              </p>
            </div>

            <div className="sensors-section">
              <div className="sensors-list">
                {refreshZoneList &&
                  selectedDevice &&
                  selectedDevice.zonesList &&
                  selectedDevice.zonesList.map((item) => {
                    return (
                      <div key={item.zoneNo} >
                        <div
                          className={`cameraList user-list-item ${selectedZone &&
                            item.zoneNo ==
                            selectedZone?.zoneNo &&
                            "selected-user"
                            }`}
                          onClick={() => setselectedZone(item)}
                        >
                          <p className="mb-0">
                            {item.zoneName + " - " + item.zoneNo}
                          </p>
                          <div style={{ float: "right" }}>
                            <img
                              style={{ paddingRight: "10px" }}
                              onClick={() => {
                                {
                                  setselectedZone(item)
                                }
                              }}
                              src={EditQc}
                              alt="edit icon"
                              className="m-1"
                            />
                            <img
                              src={DeleteQc}
                              alt="delete icon"
                              onClick={() => {
                                OnAddIDSPanel(
                                  item.iDSPanelZoneTypeID,
                                  selectedDevice.ParentCameraID,
                                  "deleteZone",
                                  item.zoneNo
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="AddMore" style={{ float: "left" }} disabled={!isDeviceEdit}>
                <a
                  className="UserAddPlus mt-1"
                  onClick={() => setselectedZone(ZoneObj)}
                >
                  Add New Zone +
                </a>
              </div>
            </div>

            {!refreshZoneForm && (
              <AddZoneForm
                selectedZone={selectedZone}
                devicesmasterlist={devicesmasterlist}
                OnAddIDSPanel={OnAddIDSPanel}
                selectedDevice={selectedDevice}
                isDeviceEdit={isDeviceEdit}
              />
            )}
            {refreshZoneForm && (
              <AddZoneForm
                selectedZone={selectedZone}
                devicesmasterlist={devicesmasterlist}
                OnAddIDSPanel={OnAddIDSPanel}
                selectedDevice={selectedDevice}
                isDeviceEdit={isDeviceEdit}
              />
            )}
          </div>
        </div>
      }
    </>
  );
};

export default ConfigurePanelZones