import React from 'react'

function setSelectedHeader(e, props) {
    if (props.label == "brand") {
        props.selectedHeaders.brand = e.target.value;
        props.setDefaultBrand(e.target.value);
    }
    if (props.label == "ipaddress") {
        props.selectedHeaders.ipaddress = e.target.value;
        props.setDefaultIp(e.target.value);
    }
    if (props.label == "branch") {
        props.selectedHeaders.branch = e.target.value;
        props.setDefaultBranch(e.target.value);
    }
    if (props.label == "name") {
        props.selectedHeaders.name = e.target.value;
        props.setDefaultPanelName(e.target.value);
    }
    if (props.label == "zonetype") {
        props.selectedHeaders.zonetype = e.target.value;
        props.setDefaultZoneType(e.target.value);
    }
    if (props.label == "zonenumber") {
        props.selectedHeaders.zonenumber = e.target.value;
        props.setDefaultZoneNumber(e.target.value);
    }
    if (props.label == "zonename") {
        props.selectedHeaders.zonename = e.target.value;
        props.setDefaultZoneName(e.target.value);
    }
}

const SelectHeaderEmpty = (props) => {

    const onChange = (e, props) => {
        setSelectedHeader(e, props);
    };
    return (
        <>
            <select
                required={true}
                className="SiteNameTextBox mb-0"
                placeholder="Select Header"
                onChange={(e) => onChange(e, props)}
            >
                <option key="0" value="Select">
                    Select
                </option>
                {props.importedFileHeaders &&
                    props.importedFileHeaders.map((val) => (
                        <option value={val} key={val}>
                            {val}
                        </option>
                    ))}
            </select>
        </>
    );
};
export default SelectHeaderEmpty