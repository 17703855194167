import React from 'react';
import FilterToolWithExtraProps from './FilterToolWithExtraProps';
import FilterTool from '../../pages/Reports/FilterTool';

const EnhancedFilterToolComponent = FilterToolWithExtraProps(FilterTool);

const FilterToolExtended = ({ wrappedComponentProps, extendedComponentProps }) => {
    return (
        <>
            <EnhancedFilterToolComponent wrappedComponentProps={wrappedComponentProps} extendedComponentProps={extendedComponentProps} />
        </>
    )
}

export default FilterToolExtended
