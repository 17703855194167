import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';

const AddPanelDeviceForm = React.forwardRef((props, ref) => {
    let {
        sites,
        selectedDevice,
        devicesmasterlist,
        OnAddIDSPanel,
    } = { ...props };

    const { register, handleSubmit, getValues, setValue } = useForm();

    useEffect(() => {
        if (selectedDevice && selectedDevice != null) {
            setValue("PanelMasterId", selectedDevice.panelMasterId) ||
                setValue("SiteID", selectedDevice.SiteID) ||
                setValue("privateIPAddress", selectedDevice.privateIPAddress) ||
                setValue("communicationPort", selectedDevice.communicationPort) ||
                setValue("httpPort", selectedDevice.httpPort) ||
                setValue("cameraName", selectedDevice.CameraName);
        }
    }, [selectedDevice]);

    return (
        <div className="SiteList">
            <div>
                <form>
                    <div className="DeviceTextBox panel-singledev">
                        <select
                            {...register("PanelMasterId")}
                            className="SiteNameTextBox"
                            placeholder="Select Brand Name"
                            defaultValue={selectedDevice.panelMasterId}
                            onChange={(e) => setValue("PanelMasterId", e.target.value)}
                        >
                            <option key="0" disabled={false} value="">Brand Name*</option>
                            {devicesmasterlist &&
                                devicesmasterlist.panelTypeMasterList &&
                                devicesmasterlist.panelTypeMasterList.map((site) => (
                                    <option value={site.panelMasterID} key={site.panelMasterID}>
                                        {site.panelName}
                                    </option>
                                ))}
                        </select>
                        <input
                            type="text"
                            {...register("cameraName")}
                            className="SiteNameTextBox"
                            placeholder="Panel name* e.g. Thane panel"
                            defaultValue={selectedDevice.CameraName}
                        />
                        <input
                            {...register("privateIPAddress")}
                            className="SiteNameTextBox"
                            placeholder="IP address* e.g. 192.168.0.54"
                            defaultValue={selectedDevice.privateIPAddress}
                        />
                        <select
                            {...register("SiteID")}
                            className="SiteNameTextBox"
                            placeholder="Select Site"
                            defaultValue={selectedDevice.SiteID}
                            onChange={(e) => setValue("SiteID", e.target.value)}
                        >
                            <option key="0" disabled={false} value="0">Branch Name*</option>
                            {sites &&
                                sites.map((site) => (
                                    <option value={site.SiteID} key={site.SiteID}>
                                        {site.SiteName}
                                    </option>
                                ))}
                        </select>
                    </div>
                    {selectedDevice.ParentCameraID == 0 ? (
                        <div className="m-2 text-right" style={{ textAlign: "end" }}>
                            <button
                                className="NextMoveButton m-3 manageDevices-addPanel-btn"
                                onClick={handleSubmit((e) =>
                                    OnAddIDSPanel(
                                        e,
                                        selectedDevice.ParentCameraID,
                                        "addPanelDevice"
                                    )

                                )}
                            >
                                <span className="ViewUpdate">Add Panel</span>
                            </button>
                        </div>
                    ) : (
                        <div className="m-2 text-right" style={{ textAlign: "end" }}>
                            <button
                                className="NextMoveButton m-3 manageDevices-updatepanel-btn"
                                onClick={handleSubmit((e) =>
                                    OnAddIDSPanel(
                                        e,
                                        selectedDevice.ParentCameraID,
                                        "updatePanelDevice"
                                    )
                                )}
                            >
                                <span className="ViewUpdate">Update Panel</span>
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
});

export default AddPanelDeviceForm