import React from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { DeleteIcon, FilterClearIcon } from '../../assets/images'
import DDLSingleSelect from '../DDL/DDLSingleSelect'
import { isNotNullOrEmpty } from '../../utils/utils'

const FilterToolDDL = ({ filterList, visibleDDLSingleSelect, DDLSingleSelectProps, visibleDDLMultiSelect }) => {

    const valueRenderer = (selected, _options, filterProps) => (
        <>
            {selected?.length ? (
                <div style={{ width: "100%", display: "flex" }}>
                    {filterProps?.labelledBy}: {selected[0].label}{" "}
                    {selected.length > 1 &&
                        <span className="filterSelectedCount ml-2">{`+ ${selected.length - 1}`}</span>
                    }
                </div>
            ) : (
                <div>{filterProps?.labelledBy}</div>
            )}
        </>
    )

    return (
        <>
            {visibleDDLSingleSelect && <><DDLSingleSelect {...DDLSingleSelectProps} /><h4>OR</h4></>}
            {visibleDDLMultiSelect && isNotNullOrEmpty(filterList) && filterList?.map((filterProps, index) => (<><MultiSelect key={index} {...filterProps} valueRenderer={filterProps?.valueRenderer !== null ? filterProps?.valueRenderer : (selected, _options) => valueRenderer(selected, _options, filterProps)} /></>))}
        </>
    )
}

export default FilterToolDDL;
