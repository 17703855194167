import React, { useEffect } from "react";
import { CheckmarkSuccessCircle, DropArrow, SearchIcon, SearchIconWhite } from "../../../assets/images";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Modal, Row } from 'react-bootstrap'
import Swal from "sweetalert2";
import AlertTemplateMessage from "../AlertTemplate/AlertTemplateMessage";
import { addAlertPrePostDetails, getAlertPrePostList, getAlertTypelist, updateAlertPrePostDetails } from "../../../services/configurationServices";
import { toast } from "react-toastify";
import { SuccessToast } from "../../../CustomHooks/SuccessToast";

export const ProPostSetting = ({ isDarkTheme, clients, savebtnClick, setSavebtnClick, setInputValidationError, setClients, item,setButtonDisable,buttonDisable}) => {
  // region useState start 
  const [selectedBrandMaster, setSelectedBrandMaster] = useState(null);
  const [preLengthError, setPreLengthError] = useState('');
  const [postLengthError, setPostLengthError] = useState('')
  const [noOfDaysError, setNoOfDaysError] = useState('');
  const [prepostAlertTypeError, setPrepostAlertTypeError] = useState('');
  const [enableAlertPrePost, setEnableAlertPrePost] = useState(false);
  const [alertPreLength, setAlertPreLength] = useState("");
  const [alertPostLength, setAlertPostLength] = useState("");
  const [alertNoOfdays, setAlertNoOfDays] = useState("")
  const [alertPrePostId, setAlertPrePostId] = useState("")
  const [validationErrorState, setValidationErrorState] = useState("");
  const [isPrePostEdit, setIsPrePostEdit] = useState(false);
  const [clientname, setClientName] = useState("");
  const [alertTypeList, setAlertTypeList] = useState([])
  const [alertTypeSelected, setAlertTypeSelected] = useState([]);
  const [clientSearch, setClientSearch] = useState("")
  const [clientSelectAll, setClientSelectAll] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [clientList, setClientList] = useState(clients);
  const [mainClientList, setMainClientList] = useState(clients)
  //region useState  end

  // region useEffect start 
  useEffect(() => {
    let temp = selectedBrandMaster?.PrePostEnable == 1 ? true : false
    setEnableAlertPrePost(temp)
    setAlertPreLength(selectedBrandMaster?.PreTimeSec)
    setAlertPostLength(selectedBrandMaster?.PostTimeSec)
    setAlertNoOfDays(selectedBrandMaster?.RetentionPeriod)
    setAlertPrePostId(selectedBrandMaster?.PrePostID)
  }, [selectedBrandMaster])

  useEffect(() => {
    setClientList(clients);
    setMainClientList(clients);
  }, [clients]);

  useEffect(() => {
    if (prepostAlertTypeError != "") {
      setPrepostAlertTypeError("")
    }
  }, [alertTypeSelected])

  useEffect(() => {
    getAlertType()
  }, [])

  useEffect(() => {
    let templst = validationErrorState.split(",");
    for (let i = 0; i < templst.length; i++) {
      if (templst[i].includes("length-")) {
        setPreLengthError(templst[i].replace("length-", ""));
        continue
      }

      if (templst[i].includes("no of days-")) {
        setNoOfDaysError(templst[i].replace("no of days-", ""));
        continue
      }

      if (templst[i].includes("alert type-")) {
        setPrepostAlertTypeError(templst[i].replace("alert type-", ""));
        continue
      }

    }
  }, [validationErrorState]);

  // region useEffect end

  const clearPrepostFields = () => {
    setPreLengthError('');
    setPostLengthError('');
    setNoOfDaysError('');
    setPrepostAlertTypeError('');
  }

  const onSaveAlertPrePost = () => {
    setButtonDisable(true)
    let validationError = "";
    if (alertPreLength == '' || alertPreLength == null || alertPreLength == 0) {
      validationError += "preLength-Please enter alert prelength!,";
    }

    if (alertPreLength > 60 || alertPreLength < 0) {
      validationError += "preLength-alert prelength should be less than or equal to 60 sec!,";
    }
    if (alertPostLength == '' || alertPostLength == null || alertPostLength == 0) {
      validationError += "postLength-Please enter alert postlength!,";
    }
    if (alertPostLength > 60 || alertPostLength < 0) {
      validationError += "postLength-alert postlength should be less than or equal to 60 sec!,";
    }
    if (alertNoOfdays == '' || alertNoOfdays == null || alertNoOfdays == 0) {
      validationError += "no of days-Please enter alert No.of Days!,";
    }
    if (alertNoOfdays > 15 || alertNoOfdays < 0) {
      validationError += "no of days-alert no of days should be less than or equal to 15 days!,";
    }

    if (alertTypeSelected.length == 0) {
      validationError += "alert type-Please select atleast one alert type!,";
    }
    setValidationErrorState(validationError)
    let templst = validationError.split(",");
    for (let i = 0; i < templst.length; i++) {
      if (templst[i].includes("preLength-")) {
        setPreLengthError(templst[i].replace("preLength-", ""));
        continue
      }
      if (templst[i].includes("postLength-")) {
        setPostLengthError(templst[i].replace("postLength-", ""));
      }
      if (templst[i].includes("no of days-")) {
        setNoOfDaysError(templst[i].replace("no of days-", ""));
        continue
      }
      if (templst[i].includes("alert type-")) {
        setPrepostAlertTypeError(templst[i].replace("alert type-", ""));
        continue
      }
    }
    if (validationError != "") {
      setButtonDisable(false)
      return;
    }

    let temp = alertTypeSelected.map((item) => item.value).join(",");

    if (selectedBrandMaster?.PrePostID == "") {
      let addAlertPrePostDetailsObj = {
        "PrePostID": "0",
        "ClientId": selectedBrandMaster?.ClientId,
        "PrePostTimeSec": (parseInt(alertPreLength)) + (parseInt(alertPostLength)),
        "PreTimeSec": alertPreLength,
        "PostTimeSec": alertPostLength,
        "RetentionPeriod": alertNoOfdays,
        "PrePostEnable": enableAlertPrePost == true ? "1" : "0",
        "SelectedAlertTypes": temp
      }
      addAlertPrePostDetails(addAlertPrePostDetailsObj).then((resp) => {
        if (resp.Status == "success") {
          setButtonDisable(false)
          SuccessToast(resp.Message);
          setIsPrePostEdit(true);
        }
        else {
          setButtonDisable(false)
          showErrorToast(resp.Message)
          console.error(resp.Message)
        }
      })
    } else {
      let updateAlertPrePostDetailsObj = {
        "PrePostID": alertPrePostId,
        "ClientId": selectedBrandMaster?.ClientId,
        "PrePostTimeSec": (parseInt(alertPreLength)) + (parseInt(alertPostLength)),
        "PreTimeSec": alertPreLength,
        "PostTimeSec": alertPostLength,
        "RetentionPeriod": alertNoOfdays,
        "PrePostEnable": enableAlertPrePost == true ? "1" : "0",
        "SelectedAlertTypes": temp
      }
      updateAlertPrePostDetails(updateAlertPrePostDetailsObj).then((resp) => {
        if (resp.Status == "success") {
          setButtonDisable(false)
          SuccessToast(resp.Message);
          getAlertPrePostCancel(selectedBrandMaster?.ClientId)
          setIsPrePostEdit(true);
        }
        else {
          setButtonDisable(false)
          showErrorToast(resp.Message)
          console.error(resp.Message)
        }
      })
    }
  }

  const getAlertType = () => {
    try {
      getAlertTypelist().then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            let alerttypeArray = resp.Data.map((item) => ({
              value: item.AlertCodeId,
              label: item.AlertCode
            }))
            setAlertTypeList(alerttypeArray)
          } else {
            setAlertTypeList(null)
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getAlertPrePost = (clientID) => {
    try {
      getAlertPrePostList(clientID).then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            setSelectedBrandMaster(resp.Data)
            setIsPrePostEdit(true)
            let alettypearr = alertTypeList.filter(item => resp.Data.SelectedAlertTypes.includes(item.value))
            setAlertTypeSelected(alettypearr)
          } else {
            let obj = {
              "PrePostID": "",
              "ClientId": parseInt(clientID),
              "PrePostTimeSec": "",
              "PreTimeSec": "",
              "PostTimeSec": "",
              "RetentionPeriod": "",
              "PrePostEnable": "0",
              "SelectedAlertTypes": ""
            }
            setIsPrePostEdit(false)
            setSelectedBrandMaster(obj)
            setAlertTypeSelected([])
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getAlertPrePostCancel = (clientID) => {
    try {
      getAlertPrePostList(clientID).then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            setSelectedBrandMaster(resp.Data)
            setIsPrePostEdit(true)
          } else {
            let obj = {
              "PrePostID": "",
              "ClientId": parseInt(clientID),
              "PrePostTimeSec": "",
              "PreTimeSec": "",
              "PostTimeSec": "",
              "RetentionPeriod": "",
              "PrePostEnable": "0",
              "SelectedAlertTypes": ""
            }
            setSelectedBrandMaster(obj)
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onCancel = (e) => {
    e.stopPropagation()
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to cancel the changes made?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#027aaa",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Leave!"
    }).then((result) => {
      if (result.isConfirmed) {
        let temp = selectedBrandMaster?.PrePostEnable == 1 ? true : false
        setEnableAlertPrePost(temp)
        setAlertPreLength(selectedBrandMaster?.PreTimeSec)
        setAlertPostLength(selectedBrandMaster?.PostTimeSec)
        setAlertNoOfDays(selectedBrandMaster?.RetentionPeriod)
        setAlertPrePostId(selectedBrandMaster?.PrePostID)
        setIsPrePostEdit(true)
      }
    })
  }
  const showErrorToast = (msg) => {
    toast.error(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleClientSelectAll = () => {
    const tempArr = clients.map((item) => {
      let obj = {
        ...item,
        isChecked: !clientSelectAll
      }
      return obj;
    })

    let clientOne = clients.map((item) => {
      let objClient;
      if (clientSelectAll) {
        if (!item.clientName.toLowerCase().includes(clientSearch.toLowerCase())) {
          objClient = {
            ...item
          }
        } else {
          objClient = {
            ...item,
            isChecked: false
          }
        }
      } else {
        if (!item.className.toLowerCase().includes(clientSearch.toLowerCase())) {
          objClient = {
            ...item,
          }
        } else {
          objClient = {
            ...item,
            isChecked: true
          }
        }
      }
      return objClient;
    })
    setClientSelectAll(!clientSelectAll);
  }

  const searchFilterHandler = (e) => {
    let searchText = e.target.value;

    let arr = clients.filter((item) => item.clientName.toLowerCase().includes(searchText.toLowerCase()));
    let isChecked = true;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].isChecked == true) {
        continue;
      } else {
        isChecked = false;
        break;
      }
    }
    setClientSelectAll(isChecked);

  }

  const searchClientList = (e) => {
    setClientSearch(e.target.value);
    let tempArr = mainClientList.filter(x => x.clientName.toLowerCase().includes(e.target.value.toLowerCase()))
    setClientList(tempArr)
  }

  return (
    <div className="col-lg-12 mt-2">
      <div className="SiteMainContainer">
        <div className="SiteLeftContainer">
          <div className="m-2 HeaderSiteList">
            <input
              id="Search_txt"
              className="SearchViewInput1"
              type="text"
              placeholder="Search Customer"
              value={clientSearch}
              onChange={(e) => searchClientList(e)}
            />
            <div className="SearchTextDivConfigTool">
              {isDarkTheme ? (
                <img
                  className="EyeIcon"
                  src={SearchIcon}
                  alt="search icon"
                />
              ) : (
                <img
                  className="EyeIcon"
                  src={SearchIconWhite}
                  height="15"
                  alt="search icon"
                />
              )}
            </div>
          </div>
          <div className="NotificationList">
            {clientList &&
              (clientList.length > 0 ? (
                clientList.map((item) => {
                  return (
                    <div
                      // className="OneList"
                      className={
                        selectedBrandMaster?.ClientId === item.clientID
                          ? "OneList SelectedList"
                          : "OneList"
                      }
                      key={item.clientID}
                      onClick={(e) => {
                        e.stopPropagation();
                        getAlertPrePost(item.clientID)
                        setClientName(item.clientName)
                        clearPrepostFields()
                      }}
                    >
                      <p className="ListofText">{item.clientName}</p>
                    </div>
                  );
                })
              ) : (
                <div className="no-data-found">
                  <p>No Customer Found.</p>
                </div>
              ))}
            {!clientList && (
              <div className="no-data-found">No Customer List Available.</div>
            )}
          </div>
        </div>
        <div className="SiteRightContainer">
          {
            clientname == ""
              ?
              <div style={{ padding: "20px" }}>
                <AlertTemplateMessage message={"Select a customer to update alert pre-post setting."} />
              </div>
              :
              <>
                <form>
                  <div className="PrepostSettingForm PrePostSettingDiv">
                    <h4 className="prepostCustomername"> {clientname} </h4>
                    <div className="inputMainContainer w40dot75rem mb-0">
                      <div className="onlyforEnableCheckbox ml-0">
                        <input
                          type="checkbox"
                          id="exampleCheck3"
                          className="checkbox_custom"
                          style={{ marginLeft: "2px" }}
                          checked={enableAlertPrePost}
                          disabled={isPrePostEdit}
                          onChange={(e) => {
                            setEnableAlertPrePost(!enableAlertPrePost)
                          }}
                        />
                        <label className="" htmlFor="exampleCheck3">
                          Enable cloud storage for alert pre-post video
                        </label>
                      </div>
                    </div>
                    <div className="inputMainContainer w40dot75rem mt30">
                      <div className="inputTypeLabelContainer">
                        <label className="formFieldLabels">Pre Length</label>
                        <input
                          type="number"
                          className="inputType prepostinputwidth"
                          placeholder="Ex. 60 seconds"
                          value={alertPreLength}
                          disabled={isPrePostEdit}
                          onChange={(e) => {
                            setAlertPreLength(e.target.value)
                            setPreLengthError("")
                          }
                          }
                        />
                        <div
                          className="input-group-prepend mr-0"
                          style={{ float: "right" }}
                        >
                          <span
                            className="input-group-text"
                            id="validationTooltipUsernamePrepend"
                          >
                            Seconds
                          </span>
                        </div>
                        <p className="Error_P" style={{ float: "left" }}><span> {preLengthError}</span></p>
                      </div>
                    </div>
                    <div className="inputMainContainer w40dot75rem mt30">
                      <div className="inputTypeLabelContainer">
                        <label className="formFieldLabels">Post Length</label>
                        <input
                          type="number"
                          className="inputType prepostinputwidth"
                          placeholder="Ex. 60 seconds"
                          disabled={isPrePostEdit}
                          value={alertPostLength}
                          onChange={(e) => {
                            setAlertPostLength(e.target.value)
                            setPostLengthError("")
                          }
                          }
                        />
                        <div
                          className="input-group-prepend mr-0"
                          style={{ float: "right" }}
                        >
                          <span
                            className="input-group-text"
                            id="validationTooltipUsernamePrepend"
                          >
                            Seconds
                          </span>
                        </div>
                        <p className="Error_P" style={{ float: "left" }}><span> {postLengthError}</span></p>
                      </div>
                    </div>
                    <div className="inputMainContainer w40dot75rem mt30">
                      <div className="inputTypeLabelContainer">
                        <label className="formFieldLabels">Number of days</label>
                        <input
                          type="number"
                          className="inputType prepostinputwidth"
                          placeholder="Ex. 10 days"
                          disabled={isPrePostEdit}
                          value={alertNoOfdays}
                          onChange={(e) => {
                            setAlertNoOfDays(e.target.value)
                            setNoOfDaysError("")
                          }
                          }
                        />
                        <div
                          className="input-group-prepend mr-0"
                          style={{ float: "right", width: "84px ", display: "unset" }}
                        >
                          <span
                            className="input-group-text"
                            id="validationTooltipUsernamePrepend"
                          >
                            days
                          </span>
                        </div>
                        <p className="Error_P" style={{ float: "left" }}><span> {noOfDaysError} </span></p>
                      </div>
                    </div>
                    <div className="inputMainContainer w40dot75rem mt30">
                      <div className="inputTypeLabelContainer">
                        <label className="formFieldLabels">Alert Type</label>
                        <MultiSelect
                          options={alertTypeList}
                          disabled={isPrePostEdit}
                          value={alertTypeSelected}
                          onChange={setAlertTypeSelected}
                          className="prepostalerttype hgtdropdownfield"
                          overrideStrings={{
                            selectSomeItems: "Select alert type(s)",
                            allItemsAreSelected: "All alert are selected",
                          }}
                          ArrowRenderer={() => <DropArrow />}
                        />
                        <p className="Error_P" style={{ float: "left" }}><span> {prepostAlertTypeError} </span></p>
                      </div>
                    </div>
                  </div>
                </form>

                <div style={{ textAlign: "center" }}>

                  {
                    isPrePostEdit &&
                    (
                      <button
                        className="DataAccessEdit"
                        style={{ width: "100px" }}
                        onClick={() => {
                          setIsPrePostEdit(false);
                        }}
                      >
                        <span className="ViewUpdate">Edit</span>
                      </button>
                    )
                  }
                  {
                    selectedBrandMaster != null && !isPrePostEdit && alertPrePostId == "" &&
                    (
                      <button
                        className="DataAccessEdit"
                        style={{ width: "100px" }}
                        onClick={onSaveAlertPrePost}

                      >
                        <span className="ViewUpdate">Save</span>
                      </button>
                    )
                  }
                  {
                    selectedBrandMaster != null && !isPrePostEdit && !alertPrePostId == "" &&
                    (
                      <>
                        <button
                          className="DataAccessEdit"
                          style={{ width: "100px" }}
                          onClick={onSaveAlertPrePost}
                          disabled={buttonDisable}
                        >
                          <span className="ViewUpdate">Update</span>
                        </button>
                        <button
                          className="DataAccessEdit"
                          style={{ width: "100px" }}
                          onClick={(e) => onCancel(e)}
                        >
                          <span className="ViewUpdate">Cancel</span>
                        </button>
                      </>
                    )
                  }
                  {
                    selectedBrandMaster != null && !isPrePostEdit &&
                    <button
                      className="DataAccessEdit"
                      style={{ width: "100px", display: "none" }}
                      onClick={() => {
                        // setIsPrePostEdit(false);
                        setShowCopy(!showCopy)
                      }}
                    >
                      <span className="ViewUpdate">Copy</span>
                    </button>
                  }
                </div>
              </>
          }
        </div>
      </div>
      <Modal
        size="lg"
        show={showCopy}
        dialogClassName="modal-90w"
        backdrop="static"
        // className="AddCustomerModal"
        onHide={() => setShowCopy(false)}
      >

        <Modal.Header closeButton>
          <Modal.Title>Copy Paste Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="DeviceList">
            <div className="mb-3">
              <p className="SiteNameText mb-0">
                Copy From: <span style={{ fontWeight: "bold" }}>{clientname}</span>
              </p>
            </div>
            <div className="NotificationTextBox mt-0">
              <p className="SiteNameText mb-0">
                Paste To:
              </p>

              <div className='filterinnerboxdiv'>
                <div>
                  <div className="SelectionListWithSelectAll">
                    <input
                      className="SearchViewInput1"
                      type="search"
                      placeholder="Search"
                      value={clientSearch}
                      onChange={(e) => searchFilterHandler(e, "Customer")}
                    />
                    {
                      isDarkTheme ?
                        <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                        <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                    }
                  </div>
                </div>
                <div className='dataAccessListItem mt-2'>
                  <input type="checkbox"
                  // checked={customerSelectAll}
                  // onChange={handleClientSelectAll}
                  />
                  <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}

                  >
                    Select all <span>{"0"}</span>
                  </p>
                </div>
                {/* <div className='HgtFilterBoxList'>
                                                              {
                                                                clients?.filter(x => x.clientName != clientname).map((item) => (
                                                                  // if (item.clientName != clientname) {
                                                                    <div 
                                                                    key={item.clientID} 
                                                                    className="dataAccessListItem"
                                                                    onClick={(e) => customerCheckHandler(e, item.Item1)}
                                                                    >
                                                                                  <input
                                                                                      checked={item.isChecked}
                                                                                      type="checkbox"
                                                                                      />
                                                                                  <p className="AllTextwithValue">
                                                                                      {item.clientName}</p>
                                                                              </div>
                                                                ))
                                                              }
                                                                    
                                                            </div> */}
              </div>
            </div>
            <div className='filterbuttonsdiv'>
              <button className='DataAccessEdit ml-0' style={{ width: "100%" }}>
                <span className='ViewUpdate'>Apply Now</span>
              </button>
            </div>

            <div className="inputMainContainer w25dot75rem successMsg" style={{ display: "flex", alignItems: "center" }}>
              <img src={CheckmarkSuccessCircle} />
              <p className="closemarkErrorText">Setting pasted/applied to 9 customers</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
