import React from 'react'

const EventReportList = ({item}) => {
    return (
        <tr>
            <td>{item.ClientName}</td>
            <td>{item.SiteName}</td>
            <td>{item.DeviceName}</td>
            <td>{item.InterfaceName}</td>
            <td>{item.EventName}</td>
            <td>{item.Abbreviation}</td>
            <td>{item.StatusDateTime}</td>
        </tr>
    )
}

export default EventReportList