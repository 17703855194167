import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { playbackActions } from '../../redux/features/shared/playback';

const TimerWidget = ({ type, defaultValue, min, max, maxLength, handleTimerInputCB }) => {

    const [value, setValue] = useState(defaultValue);

    const onChange = (e) => {
        let val = e.target.value;
        if (val > parseInt(max)) {
            setValue(max)
        } else if (val < parseInt(min)) {
            setValue(0)
        } else {
            setValue(val);
        }
        handleTimerInputCB(val, type);
    }
    return (
        <div className='timerDiv'>
            <input className='timerDiv__input' type='number' value={value}
                onChange={onChange}
                min={min}
                max={max}
                maxLength={maxLength}
            />
        </div>
    )
}

export default memo(TimerWidget)
