import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
// import './Signup.css'

export default function Step2Form({ initialValues, OnSubmit }) {
    let { register, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: initialValues
    });

    const onSubmit = data => {
        OnSubmit(data)
    };

    return (
        <>
            <div className="signUpForm">
                <p className="ConfirmIdentity">Confirm your identity</p>
                <p className="confirmDescp">Before you can use your Intellve account, you must verify your email address</p>
                <Form >
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <input
                            type="Email"
                            className="form-control formField"
                            {...register("Email", {
                                required: true,
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Entered valid email format"
                                }
                            })}
                            defaultValue={initialValues.Email}
                        />
                    </Form.Group>
                    <button type="submit" className="sendCodeBtn" hidden>Verify email</button>
                </Form>
                <button onClick={handleSubmit(onSubmit)} className="signUpBtn" >Continue Step 2 of 3</button>
            </div>
        </>
    )
}