import React from 'react'

const CommonReportRowsList = ({ item, headers }) => {
  return (
    <tr>
      {
        headers.map((i) => (
          <td>
            {item[i.headerName]}
          </td>
        ))
      }
    </tr>
  )
}

export default CommonReportRowsList

