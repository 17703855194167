
import React, { useEffect, useState } from 'react'
import { DatePicker, DateRangePicker } from 'rsuite';
import { differenceInDays, subDays, subMonths } from 'date-fns';
import { ConvertDate, multiTimeZoneHelper } from '../../utils/utils';
import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination';
import { getEventExportStatus, getEventReport } from '../../services/reportService';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import Swal from 'sweetalert2';
import { Pagination } from 'react-bootstrap';
import { SearchIcon, SearchIconWhite } from '../../assets/images';
import Skeleton from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import DeviceEventReportList from './DeviceEventReportList';
import { MULTI_TIME_ZONE } from '../../config';
// import CustomerTool from './CustomerTool';
import FilterTool from './FilterTool';
// import CommonTable from './CommonTable';
const TODAY = "TD";
const YESTERDAY = "YD";
const SEVENDAY = "7D";
const ONEMONTH = "1M";
const THREEMONTH = "3M";
const NONE = "NONE";

export const DeviceEventActivityReports = (props) => {
  //useState Block Start 
  const [activeTab, setActiveTab] = useState(TODAY)
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inputDate, setInputDate] = useState([]);
  const [displayStartDate, setDisplayStartDate] = useState("");
  const [displayEndDate, setDisplayEndDate] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalDevices, setTotalDevices] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [customerSearch, setCustomerSearch] = useState("");
  const [deviceSearch, setDeviceSearch] = useState("");
  const [eventCodeSearch, setEventCodeSearch] = useState("");
  const [typingStart, setTypingStart] = useState(false);
  //const [clientSearch, setClientSearch] = useState("");
  const [deviceTypeSearch, setDeviceTypeSearch] = useState("");
  const [siteSearch, setSiteSearch] = useState("");
  const [eventDescriptionSearch, setEventDescriptionSearch] = useState("");
  const [eventTypeSearch, setEventTypeSearch] = useState("");
  const [timeZoneSearch, setTimeZoneSearch] = useState("");
  const [show, setShow] = useState(false);
  const [initialDate, setInitialDate] = useState([])
  const [count, setCount] = useState(0);
  const [abbreviation, setAbbreviation] = useState("");
  const [siteIds, setSiteIds] = useState("");
  const [customerIds, setCustomerIds] = useState("");
  const [resetClicked, setResetClicked] = useState(false);
  const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);
  //useState Block End 

  const getFirstDate = () => {
    let d;
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    if (MULTI_TIME_ZONE) {
      d = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
    } else {
      d = new Date();
    }
    return d;
  }

  const getSecondDate = () => {
    let e;
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    if (MULTI_TIME_ZONE) {
      e = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
    } else {
      e = new Date();
    }
    return e;
  }

  //useEffect Block Start

  useEffect(() => {
    if (resetClicked) {
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      getEventData(loggedInUser.UserUniqueID, fromDate, endDate, 1, searchString, customerIds, siteIds);
      setCurrentPageNo(1)
      setResetClicked(false);
    }
  }, [resetClicked])


  useEffect(() => {
    if (siteIds != "" || customerIds != "") {
      // Call API 
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      getEventData(loggedInUser.UserUniqueID, fromDate, endDate, 1, searchString, customerIds, siteIds);
      setCurrentPageNo(1)
    }
  }, [siteIds, customerIds])

  useEffect(() => {
    if (fromDate === "" || endDate === "") {
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      let start = getFirstDate();
      let end = getSecondDate();
      let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
      setFromDate(startDate);
      let endCurrDate = ConvertDate(end);
      setEndDate(endCurrDate)
      displayDateSet(startDate, endCurrDate);
      getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, currentPageNo, searchString, customerIds, siteIds);
      setAbbreviation(loggedInUser.Abbreviation)
    } else {
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      getEventData(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo, searchString, customerIds, siteIds);
      setAbbreviation(loggedInUser.Abbreviation)
    }
    let tempDate = getFirstDate();
    console.log("TempDate", tempDate)
    let c = count + 1;
    setCount(c);
    setInitialDate([subMonths(tempDate, 1), tempDate]);
  }, [currentPageNo]);

  useEffect(() => {
    if (customerSearch.length > 0) {
      setTypingStart(true);
    }
    if (customerSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [customerSearch])

  useEffect(() => {
    if (deviceTypeSearch.length > 0) {
      setTypingStart(true);
    }
    if (deviceTypeSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [deviceTypeSearch])

  useEffect(() => {
    if (deviceSearch.length > 0) {
      setTypingStart(true);
    }
    if (deviceSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [deviceSearch])

  useEffect(() => {
    if (siteSearch.length > 0) {
      setTypingStart(true);
    }
    if (siteSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [siteSearch])

  useEffect(() => {
    if (eventDescriptionSearch.length > 0) {
      setTypingStart(true);
    }
    if (eventDescriptionSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [eventDescriptionSearch])

  useEffect(() => {
    if (eventTypeSearch.length > 0) {
      setTypingStart(true);
    }
    if (eventTypeSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [eventTypeSearch])

  useEffect(() => {
    if (eventCodeSearch.length > 0) {
      setTypingStart(true);
    }
    if (eventCodeSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [eventCodeSearch])

  useEffect(() => {
    if (timeZoneSearch.length > 0) {
      setTypingStart(true);
    }
    if (timeZoneSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [timeZoneSearch])
  //useEffect Block End 
  const getEventData = (uniqueId, startDate, endDate, pageNumber, searchString, customerIds, siteIds) => {
    try {
      setTableLoading(true);
      getEventReport(uniqueId, startDate, endDate, pageNumber, searchString, customerIds, siteIds)
        .then((res) => {
          if (res.Status == "success") {
            setData(res.Data.EventReportModels);
            setTotalDevices(parseInt(res.Data.Total));
            setTotalPages(parseInt(res.Data.Total));
            setTableLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
          setTableLoading(false);
        })
    } catch (error) {

    }
  }
  const displayDateSet = (startDate, endCurrDate) => {
    let one = startDate.split(" ");
    let tempOne = `${one[0].split("-")[2]}-${one[0].split("-")[1]}-${one[0].split("-")[0]}`;
    setDisplayStartDate(`${tempOne} ${one[1]}`);
    let two = endCurrDate.split(" ");
    let tempTwo = `${two[0].split("-")[2]}-${two[0].split("-")[1]}-${two[0].split("-")[0]}`;
    setDisplayEndDate(`${tempTwo} ${two[1]}`);
  }
  const today = () => {
    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1)
    setActiveTab(TODAY)
  }
  const yesterday = () => {
    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 1));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(subDays(new Date(end.setHours(24, 0, 0, 0)), 1));
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1)
    setActiveTab(YESTERDAY);
  }
  const sevenDay = () => {
    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 6));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1)
    setActiveTab(SEVENDAY)
  }
  const oneMonth = () => {
    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(subMonths(new Date(start.setHours(0, 0, 0, 0)), 1));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1)
    setActiveTab(ONEMONTH);
  }
  const threeMonth = () => {
    let start = getFirstDate();
    let end = getSecondDate();
    let startDate = ConvertDate(subMonths(new Date(start.setHours(0, 0, 0, 0)), 3));
    setFromDate(startDate);
    let endCurrDate = ConvertDate(end);
    setEndDate(endCurrDate)
    setInputDate([])
    displayDateSet(startDate, endCurrDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getEventData(loggedInUser.UserUniqueID, startDate, endCurrDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1)
    setActiveTab(THREEMONTH);
  }
  const onHandleEventDateTimeOkClick = (e) => {
    const [startDate, endDate] = [e[0], e[1]];
    if (startDate && endDate) {
      const daysDifference = differenceInDays(endDate, startDate);
      if (daysDifference > 30) {
        ErrorToast('Maximum Date range cannot exceeded 30 days.');
        return;
      }
    }
    let sDate = ConvertDate(e[0]);
    setFromDate(sDate);
    let eDate = ConvertDate(e[1]);
    setEndDate(eDate);
    setInputDate([e[0], e[1]])
    displayDateSet(sDate, eDate);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getEventData(loggedInUser.UserUniqueID, sDate, eDate, 1, searchString, customerIds, siteIds);
    setCurrentPageNo(1)
    setActiveTab(NONE);
  }
  const convertToCSV = (objArray) => {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
  const exportEventHandler = () => {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getEventExportStatus(loggedInUser.UserUniqueID, fromDate, endDate, 1, searchString, customerIds, siteIds)
      .then((res) => {
        if (res.Status == "success") {
          let stemp = res.Data.EventReportModels;
          if (res.Data.EventReportModels.length == 0) {
            Swal.fire({ text: 'No records available to export.' })
            return
          }
          let header = {
            CustomerName: "Customer Name",
            SiteName: "Site Name",
            DeviceName: "Device Name",
            EventCode: "Event Code",
            DeviceType: "Device Type",
            EventType: "Event Type",
            EventDescription: "Event Description",
            Timezone: "Abbreviation",
            StatusDateTime: "Date Time"
          }
          stemp.unshift(header);
          stemp = stemp.map((item, i) => {
            delete item['TimeZoneID'];
            delete item['OffsetMinutes'];
            delete item['StatusDateTimeUTC'];
            return item;
          })
          console.log("stemp", stemp)
          let jsonObject = JSON.stringify(stemp);
          let csv = convertToCSV(jsonObject);
          let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          let link = document.createElement("a");
          let fileN = "Event Report.csv"
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileN);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
  }
  const getSearchDataReports = (searchText) => {
    try {
      setTableLoading(true);
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      getEventReport(loggedInUser.UserUniqueID, fromDate, endDate, currentPageNo, searchText, customerIds, siteIds)
        .then((res) => {
          if (res.Status == "success") {
            setData(res.Data.EventReportModels);
            setTotalDevices(parseInt(res.Data.Total));
            setTotalPages(parseInt(res.Data.Total));
            setCurrentPageNo(1)
            setPageCounter(1)
            setTableLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
        })
    } catch (error) {

    }
  }
  const searchListDetails = (data, type) => {
    let obj = {
      "customername": customerSearch,
      "sitename": siteSearch,
      "devicename": deviceSearch,
      "alertcode": eventCodeSearch,
      "devicetype": deviceTypeSearch,
      "eventtype": eventTypeSearch,
      "eventdescription": eventDescriptionSearch,
      "timezone": timeZoneSearch
    }
    let stringText = JSON.stringify(obj);
    let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";")
    setSearchString(tempStringText);
    getSearchDataReports(tempStringText);
  }

  const PaginationComponent = (currentPageNo) => {
    let numbers = [];
    if (maxPageAllowed - 2 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2))) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 2}
          active={maxPageAllowed - 2 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
        >
          {maxPageAllowed - 2}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed}
          active={maxPageAllowed == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed)}
        >
          {maxPageAllowed}
        </Pagination.Item>)
    }
    else if (maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed}
          active={maxPageAllowed == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed)}
        >
          {maxPageAllowed}
        </Pagination.Item>)
    }
    else {
      for (let i = currentPageNo; i < currentPageNo + 3; i++) {
        if (i <= maxPageAllowed) {
          numbers.push(
            <Pagination.Item
              key={i}
              active={i == currentPageNo}
              onClick={() => setCurrentPageNo(i)}
            >
              {i}
            </Pagination.Item>
          )
        }
      }
    }
    return numbers;
  }

  return (
    <div className='MainContentMargin'>
      <div className='col-lg-12 HMHeader mt-3 mb-3'>
        <div className="NameHeading1" style={{ width: "100%" }}>
          <p className="config-tool-head" style={{ float: "left" }}>
            Reports &gt; {`Event Report`}
          </p>
          <div style={{ display: "flex", alignItems: "flex-start", float: "right" }}>
            {/* <CustomerTool /> */}
            <FilterTool isDarkTheme={props.isDarkTheme} setSiteIds={setSiteIds} setCustomerIds={setCustomerIds} setResetClicked={setResetClicked} />
            <div style={{ display: "grid", gridAutoFlow: "column", gridGap: "10px", alignItems: "center" }}>
              <button onClick={today} type="button" className={`${activeTab == TODAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>TD</button>
              <button onClick={yesterday} type="button" className={`${activeTab == YESTERDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>YD</button>
              <button onClick={sevenDay} type="button" className={`${activeTab == SEVENDAY ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>7D</button>
              <button onClick={oneMonth} type="button" className={`${activeTab == ONEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>30D</button>
              {/* <button onClick={threeMonth} type="button" className={`${activeTab == THREEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>90D</button> */}
              <DateRangePicker
                key={count}
                className="mr-1"
                format="dd-MM-yyyy HH:mm:ss"
                cleanable={false}
                editable={false}
                defaultCalendarValue={initialDate}
                value={inputDate}
                ranges={[]}
                placeholder="Select date range"
                placement='bottomEnd'
                onOk={(e) => { onHandleEventDateTimeOkClick(e) }}
                disabledDate={current => {
                  let d;
                  if (MULTI_TIME_ZONE) {
                    let loggedInUser = JSON.parse(localStorage.getItem('user'))
                    d = multiTimeZoneHelper(loggedInUser.OffsetMinutes);
                  } else {
                    d = new Date();
                  }
                  return current && current > d;
                }}
              />

              <button type="button" onClick={exportEventHandler} class="btn btn-outline-primary">Export</button>
            </div>
          </div>
        </div>
      </div>

      <div className="TabulerReportMainDiv">
        <div className="DeviceTableHeader">
          <span className='HeaderSpanHR'>Event Report</span>
          <span className='mr-4'>
            <span className='FromDateFontSize'>From : </span>{displayStartDate}
            <span className='ml-3 FromDateFontSize'>To: </span>{displayEndDate}
          </span>
        </div>

        <div className="card-body p-0">
          <div className='table-wrapper'>
          <table
            className="table mb-2"
            id="event-activity-report"
          >
            <thead className="ReportTableHeader ReportTableHeadReport">
              <tr>
                <th>
                  <p>Customer Name(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={customerSearch}
                      onChange={(e) => setCustomerSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th>
                  <p>Site Name(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={siteSearch}
                      onChange={(e) => setSiteSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th>
                  <p>Device Name(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={deviceSearch}
                      onChange={(e) => setDeviceSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th>
                  <p>Event Code(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={eventCodeSearch}
                      onChange={(e) => setEventCodeSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th>
                  <p>Device Type(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={deviceTypeSearch}
                      onChange={(e) => setDeviceTypeSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th>
                  <p>Event Type(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={eventTypeSearch}
                      onChange={(e) => setEventTypeSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th>
                  <p>Event Description(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={eventDescriptionSearch}
                      onChange={(e) => setEventDescriptionSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th>
                  <p>Timezone(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={timeZoneSearch}
                      onChange={(e) => setTimeZoneSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIcon}
                          onClick={() => searchListDetails()}
                        /> :
                        <img
                          className="EyeIcon"
                          alt="Search-icon"
                          src={SearchIconWhite} height="15"
                          onClick={() => searchListDetails()}
                        />
                    }
                  </div>
                </th>

                <th style={{verticalAlign: "middle"}}>
                  <p>Date Time</p>
                  <div className="mt-1" style={{display: "none"}}>
                    <DatePicker
                      disabled={true}
                      format="dd-MM-yyyy HH:mm:ss"
                      placement="autoVerticalEnd"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                tableLoading
                  ?
                  (
                    <tr onClick={() => setShow(!show)}>
                      {
                        <>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                        </>
                      }
                    </tr>
                  )
                  :
                  (
                    <>
                      {
                        data.length > 0
                          ?
                          data?.map((item) => (
                            <DeviceEventReportList item={item}
                            />
                          ))
                          : <div className='noRecordFoundTable'><p className='noRecordFoundTextTable'>No Record Available.</p></div>
                      }
                    </>
                  )
              }
            </tbody>
            </table>
          </div>
          
            {
              totalDevices == 0 || totalPages == 0 || totalPages <= limit
                ? <div className="PaginationDiv mr-2 mb-2 mt-2">
                  <h5 className='PageNumbers'>{totalDevices == 0 ? 0 : 1} - {totalDevices}</h5>
                  <span>of</span>
                  <h5 className='PageNumbers'>{totalDevices}</h5>
                  <Pagination>
                    <Pagination.Prev disabled />
                    <Pagination.Item active disabled>{1}</Pagination.Item>
                    <Pagination.Next disabled />
                  </Pagination>
                </div>
                :
                <div className="PaginationDiv mr-2 mb-2 mt-2">
                  <h5 className="PageNumbers">
                    {
                      totalDevices == 0 ? 0
                        : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                    -
                    {
                      (currentPageNo * limit) > (totalDevices)
                        ? (totalDevices)
                        : currentPageNo * limit
                    }
                  </h5>
                  <span>of</span>
                  <h5 className="PageNumbers">{totalDevices}</h5>

                  <Pagination>
                    <Pagination.Prev onClick={prevClick} disabled={currentPageNo == 1} />
                    {
                      PaginationComponent(currentPageNo)
                    }
                    <Pagination.Next onClick={nextClick} disabled={currentPageNo == maxPageAllowed} />
                  </Pagination>
                </div>
            }
          
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}


