import React, { useEffect } from 'react';
import { subDays } from "date-fns";
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { DateTimeArrow, PlayBackWhite } from '../../assets/images';
import { playbackActions } from '../../redux/features/shared/playback';
import { MULTI_TIME_ZONE } from '../../config';
import { addMinutesToDate, multiTimeZoneHelper, replaceTimeToDate, subtractMinutes } from '../../utils/utils';
import TimerInput from './TimerInput';

const Playback = ({ handlePlaybackClick, selectedDeviceTimeZoneOffsetMinutes }) => {

    const dispatch = useDispatch();
    const playbackState = useSelector(state => state?.playback);
    const { startDate, defaultEndDate, timerInputState, endDate } = playbackState;
    const { timeString } = timerInputState;

    useEffect(() => {
        // if (MULTI_TIME_ZONE) {
        // let user = JSON.parse(localStorage.getItem("user"));
        // let currDateTime = multiTimeZoneHelper(user?.OffsetMinutes);
        let currDateTime = multiTimeZoneHelper(selectedDeviceTimeZoneOffsetMinutes);
        let subtractedMinutesDate = subtractMinutes(currDateTime, defaultEndDate);
        dispatch(playbackActions.setEndDate(currDateTime.toString()));
        dispatch(playbackActions.setStartDate(new Date(subtractedMinutesDate).toString()));
        let timerStr = `${new Date(startDate).getHours()}_${new Date(startDate).getMinutes()}_${new Date(startDate).getSeconds()}`;
        dispatch(playbackActions.setTimerInputTimeString(timerStr));
        // } else {
        //     let subtractedMinutesDate = subtractMinutes(new Date().toString(), defaultEndDate);
        //     dispatch(playbackActions.setEndDate(new Date().toString()))
        //     dispatch(playbackActions.setStartDate(new Date(subtractedMinutesDate).toString()));
        //     let timerStr = `${new Date(startDate).getHours()}_${new Date(startDate).getMinutes()}_${new Date(startDate).getSeconds()}`;
        //     dispatch(playbackActions.setTimerInputTimeString(timerStr));
        // }

        // return () => {
        //     if (!selectedDeviceTimeZoneOffsetMinutes) {
        //         dispatch(playbackActions.resetPlaybackState());
        //     }
        // }
    }, []);


    const handleDateChange = (value) => {
        let startDate = replaceTimeToDate(value, timeString);
        dispatch(playbackActions.setStartDate(startDate.toString()));
    }

    return (
        <>
            {
                startDate?.length > 0 &&
                <>
                    <div className="popupDiv">
                        <div className="popup_card">
                            <p className="popup_header">Date and Time</p>
                            <Calendar
                                onChange={handleDateChange}
                                value={new Date(startDate)}
                                maxDate={new Date()}
                                minDate={subDays(new Date(), 180)}
                            />
                        </div>
                        <div className="popup_cardTimer">
                            <div className="popup_cardTimerRight">
                                <TimerInput />
                            </div>
                            <div className="popup_cardTimerRightArrow">
                                <img src={DateTimeArrow} alt="DateTimeArrow" />
                            </div>
                            <div className="popup_cardTimerRightTwo">
                                <p>+{defaultEndDate} min</p>
                            </div>
                        </div>
                    </div>

                    <div className="popup_link" onClick={handlePlaybackClick}>
                        <img
                            src={PlayBackWhite}
                            className="playbackicon mr-2"
                            alt="playback icon"
                        />
                        <span>Playback</span>
                    </div>
                </>
            }
        </>
    )
}

export default Playback
