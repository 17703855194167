import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MonitoringHubLogo, LoginImage, Eye, EyeMask, CheckmarkSuccessCircle, ClosemarkCircle } from "../../../assets/images";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ToastContainer } from "react-toastify";
import { resetPassword } from "../../../services/userAuthServices";
import { ErrorToast } from "../../../CustomHooks/ErrorToast";
import { LOGIN } from "../../CONSTANTS";
import { isCapitalCase, isMaxCharacter, isMinCharacter, isNumberInclude, isSpecialCharacter } from "../../../utils/utils";


export const ResetPassword = () => {
  //#region useState start
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setPassword] = useState("");
  const [emailAddress, setEmailAddress] = useState("abc@gmail.com");
  const [resetpasswordQueryString, setResetpasswordQueryString] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState('reset');
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  //#endregion useState end

  //#region react Hooks start
  const history = useHistory();
  //#endregion react Hooks end

  //#region useEffect start
  useEffect(() => {
    const url = window.location.href;
    const queryString = url.split('/').reverse()[0]
    const decodedURIComponentString = decodeURIComponent(queryString);
    var decodedString = atob(decodedURIComponentString);

    const separatedData = decodedString.split('##');
    setResetpasswordQueryString(separatedData[0])
    setEmailAddress(separatedData[1])

    const currentDateTime = new Date();
    const data3DateTime = new Date(separatedData[2].split(' ')[0].split('-')[1] + '-' + separatedData[2].split(' ')[0].split('-')[0] + '-' + separatedData[2].split(' ')[0].split('-')[2] + ' ' + separatedData[2].split(' ')[1]);

    const isValid = data3DateTime > currentDateTime;
    if (!isValid) {
      setIsButtonDisabled(true)
      setIsInputDisabled(true)
      setError('LINK EXPIRED')
      setMode('expire');
    } else {
      setMode('reset');
    }

    if (password === "" || password === null || password === undefined) {
      setIsButtonDisabled(true)
    }
  }, [])

  //#endregion useEffect end

  const handlePasswordChange = (event) => {
    let tempValue = event.target.value[event.target.value.length - 1];
    if (tempValue == " ") {
      ErrorToast('Space is not allowed!');
      return;
    }
    const newPassword = event.target.value.replace(" ", "");
    setPassword(newPassword);
    setIsButtonDisabled(
      !(
        newPassword.length > 0 && isCapitalCase(newPassword) && isSpecialCharacter(newPassword) &&
        isNumberInclude(newPassword) && isMinCharacter(newPassword) && isMaxCharacter(newPassword)
      )
    );
  };

  const ResetPasswordFunction = () => {
    let resetString = resetpasswordQueryString + "##" + emailAddress + "##" + password;
    let encodedResetString = btoa(resetString);
    let encodeURIComponentResetString = encodeURIComponent(encodedResetString)

    resetPassword(encodeURIComponentResetString)
      .then(resp => {
        if (resp.Status == "success") {
          setMode('success');
        } else {
          setMode('ok-fail');
        }
      })
      .catch(err => {
        setMode('error-fail');
      })
  }

  const togglePopover = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  const popover = (
    <Popover id="popover-basic" style={{ width: "200px" }}>
      <Popover.Content>
        <p className={isCapitalCase(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>One capital case
        </p>

        <p className={isSpecialCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>One special character
        </p>

        <p className={isNumberInclude(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>One number
        </p>

        <p className={isMinCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>Minimum 8 Character
        </p>

        <p className={isMaxCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>Maximum 15 Character
        </p>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="container-fluid forgot-pass-container" style={{ backgroundColor: "#fff" }} >
      <div className="row forgot-pass-row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ backgroundColor: "#F6F8FC" }} >
          <div className="imageWhiteBg">
            <MonitoringHubLogo height={"70px"} />
          </div>
          <div className="BigImage">
            <img src={LoginImage} alt="Login" />
          </div>
        </div>
        <div className="col-lg-6 ContentForm col-md-6 col-sm-12 col-xs-12 login-col-2">
          {
            mode == 'reset' &&
            <div className="WhiteFormSignIn">
              <div className="Content" id="resetpassworddiv">
                <p className="GM_msg">Reset Password</p>
                <p className="resetpassEmailText">for <span style={{ fontWeight: "bold" }} >{emailAddress}</span></p>

                <div className="inputMainContainer w25dot75rem">
                  <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
                    <div className="inputTypeLabelContainer">
                      <label className="formFieldLabels" htmlFor="password">New Password</label>
                      <input
                        className="inputType"
                        id="password"
                        type={passwordShown ? "text" : "password"}
                        placeholder="Ex. abc@123"
                        value={password}
                        onChange={(e) => handlePasswordChange(e)}
                        disabled={isInputDisabled}
                        onFocus={togglePopover}
                        onBlur={togglePopover}
                      />

                      <div className="EyeIconDiv">
                        {!passwordShown ? (
                          <img
                            className="EyeIcon"
                            src={Eye}
                            alt='Eye'
                            onClick={() => setPasswordShown(!passwordShown)}
                          />
                        ) : (
                          <EyeMask
                            height={18}
                            width={18}
                            onClick={() => setPasswordShown(!passwordShown)}
                          />
                        )}
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
                <div style={{ height: "10px" }}>
                  <p className="Error_P">
                    {
                      error && <span>{error}</span>
                    }
                  </p>
                </div>
                <button className="ResetPasswordButton"
                  onClick={() => ResetPasswordFunction()}
                  disabled={isButtonDisabled}
                  style={{ marginTop: "30px", fontWeight: "bold" }}
                >
                  <span className="Manage-Configuration Sign-In">Reset</span>
                </button>
              </div>
            </div>
          }

          {
            (mode == 'success' || mode == 'ok-fail' || mode == 'error-fail') &&
            <div className="WhiteFormSignIn">
              <div className="Content" id="resetpassworddiv">
                <p className="GM_msg">Reset Password</p>
                <p className="resetpassEmailText">for <span style={{ fontWeight: "bold" }} >{emailAddress}</span></p>

                <div className="inputMainContainer w25dot75rem" style={{ display: "flex", alignItems: "center" }}>
                  {
                    mode == 'success' &&
                    <>
                      <img src={CheckmarkSuccessCircle} />
                      <span className="closemarkErrorText">Your password has been successfully reset. You may now login in using your new password.</span>
                    </>
                  }
                  {
                    (mode == 'ok-fail' || mode == 'error-fail') &&
                    <>
                      <img src={ClosemarkCircle} alt="Close" />
                      <span className="closemarkErrorText">Password reset failed.</span>
                    </>
                  }
                </div>

                <button className="ResetPasswordButton" onClick={() => history.replace(LOGIN)} >
                  <span className="Manage-Configuration Sign-In">Go To Sign In</span>
                </button>
              </div>
            </div>
          }

          {
            mode == 'expire' &&
            <div className="WhiteFormSignIn">
              <div className="Content" id="resetpassworddiv">
                <p className="GM_msg">Reset Password</p>
                <p className="resetpassEmailText">for <span style={{ fontWeight: "bold" }} >{emailAddress}</span></p>

                <div className="inputMainContainer w25dot75rem" style={{ display: "flex", alignItems: "center" }}>
                  <img src={ClosemarkCircle} alt="Close" />
                  <span className="closemarkErrorText">Your link is no longer active. Create a new link and change the password.</span>
                </div>

                <button className="ResetPasswordButton">
                  <span className="Manage-Configuration Sign-In" onClick={() => history.replace(`/forgetpassword/${emailAddress}`)}>Go To Reset Password</span>
                </button>
              </div>
            </div>
          }
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
