import { configureStore } from '@reduxjs/toolkit';
import cameraReducers from './features/camera/reducers';
import filterReducers from './features/shared/filter';
import buttonReducers from './features/shared/button';
import modalReducers from './features/shared/modal';
import HMDashboardReducers from './features/HMDashboard/reducers';
import playbackReducers from './features/shared/playback';
export const store = configureStore({
    reducer: {
        camera: cameraReducers,
        HMDashboard: HMDashboardReducers,
        filter: filterReducers,
        button: buttonReducers,
        modal: modalReducers,
        playback: playbackReducers,
    }
})