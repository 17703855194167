import React from 'react'

const DashboardMiniWidget = ({ text, label, icon }) => {
    return (
        <div className="dashboard-alerts-widget-box">
            <div className="bottomKpiCard"
            >
                <div className="kpi2">
                    <div className="smallKpiText">
                        <p>{text}</p>
                        <p>{label}</p>
                    </div>
                    <div className="ovalDiv">
                        <img src={icon} alt="closed alert icon" className="alertkpiIcon ovalDiv-closedAlerts" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardMiniWidget