import React, { useState, useEffect } from 'react';
import { GetNotificationRead } from '../../services/userAuthServices';
import { NotificationOfflineInfo,NotificationOnlineInfo, AlertNotifExtreme, AlertNotifHigh, AlertNotifLow, AlertNotifMedium, HMNotifOnline, HmNotifOffline, HMNotificationOnline, HMNotificationOffline } from '../../assets/images'


const NotificationItem = ({ item }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [severityType, setSeverityType] = useState("");
   
    useEffect(() => {
        console.log("DATANOTIF", item)
        let temp = JSON.parse(item?.NotificationData.replaceAll('\n',' '));
        console.log("DATANOTIF", temp);
        // if(JSON.parse(item.NotificationData).severityId == "1"){
        //     setSeverityType("extreme")
        //    }else if(JSON.parse(item.NotificationData).severityId == "2"){
        //     setSeverityType("high")
        //    }else if(JSON.parse(item.NotificationData).severityId == "3"){
        //     setSeverityType("moderate")
        //    }else if(JSON.parse(item.NotificationData).severityId == "4"){
        //     setSeverityType("low")
        //    }else{
        //     setSeverityType("")
        //    }
        if(temp?.data.includes("Faulty") || temp?.data.includes("offline")){
            setSeverityType("offline")
           }else if(temp?.data.includes("Normal") || temp?.data.includes("online")){
            setSeverityType("online")
           }else  if(temp.severityId == "1"){
            setSeverityType("extreme")
           }else if(temp.severityId == "2"){
            setSeverityType("high")
           }else if(temp.severityId == "3"){
            setSeverityType("moderate")
           }else if(temp.severityId == "4"){
            setSeverityType("low")
           }else{
            setSeverityType("")
           }
        //    console.log("severityType"+severityType);
     }, [item]);

    const NotificationRead = (notificationId) => {
        GetNotificationRead(notificationId)
            .then((res) => {
                if (res.Status === "success") {
                    console.log("NotificationRead", res.Data);
                    setIsVisible(false);
                    // document.getElementsByClassName('badge')[0].innerText = parseInt(document.getElementsByClassName('badge')[0].innerText)-1;
                    document.getElementsByClassName('badgeUnread')[0].innerText = parseInt(document.getElementsByClassName('badgeUnread')[0].innerText)-1;
                    document.getElementsByClassName('badgenotify')[0].innerText = parseInt(document.getElementsByClassName('badgenotify')[0].innerText)-1;
                }
            }).catch(err => console.log(err));

    }

    return (
        <div className="NotificationListItem">
            <div className="ItemOnlineStatus">
            {
                severityType == "extreme"
                ? 
                <div className='AlertImgBg' style={{ backgroundColor: "#FFC3C3" }}>
                      <img className='AlertImgIcon' src={AlertNotifExtreme} alt="Notification Alert Red" />
                </div> 
                : severityType == "high"
                ? 
                <div className='AlertImgBg' style={{ backgroundColor: "#FFD0BF" }}>
                    <img className='AlertImgIcon' src={AlertNotifHigh} alt="Notification Alert Red" />
                </div>
                : severityType == "moderate"
                ? 
                <div className='AlertImgBg' style={{ backgroundColor: "#FFEFC4" }}>
                    <img className='AlertImgIcon' src={AlertNotifMedium} alt="Notification Alert Red" />
                </div>
                : severityType == "low"
                ? 
                <div className='AlertImgBg' style={{ backgroundColor: "#E7FFC3" }}>
                    <img className='AlertImgIcon' src={AlertNotifLow} alt="Notification Alert Red" />
                </div>
                : severityType == "online"
                ?
                <div className='AlertImgBg' 
                // style={{ backgroundColor: "#8BB0F4" }}
                // style={{ backgroundColor: "#7BB526" }}
                style={{ backgroundColor: "#E6FFC3" }}
                >
                    <img className='AlertImgIcon' 
                    // src={HMNotifOnline}
                    src={HMNotificationOnline} 
                    alt="Notification Alert Red" />
                </div>
                : severityType == "offline"
                ?
                <div className='AlertImgBg' 
                // style={{ backgroundColor: "#D3D3D3" }}
                // style={{ backgroundColor: "#F32C22" }}
                style={{ backgroundColor: "#FFC3C3" }}
                >
                    <img className='AlertImgIcon' 
                    // src={HmNotifOffline} 
                    src={HMNotificationOffline} 
                    alt="Notification Alert Red" />
                </div>
                : null
              }
                <div className="CameraStatusNotifyDiv">
                    <div className="NotificationTextDiv">
                        <p className="CameraStatusNameText">{JSON.parse(item?.NotificationData.replaceAll('\n',' ')).Title}</p>
                        <button onClick={() => NotificationRead(item.NotificationId)} className={"MoreBtn" + (isVisible ? 'style="display:block"' : ' style="display:none"')}>
                            <div className={(isVisible ? 'MarkStatusDiv' : '')}></div>
                        </button>
                    </div>
                    <div>
                        <p className="CameraStatusDetailsP">{JSON.parse(item?.NotificationData.replaceAll('\n',' ')).data }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationItem;