import mixpanel from 'mixpanel-browser';

mixpanel.init('3c70344f7a6a1154d4426da070bec5b9', {
    debug: true,
    loaded: function() {
        //mixpanel.track('loaded() callback works but is unnecessary');
        //alert("Mixpanel loaded successfully via Browserify/CommonJS");
        // console.log(mixpanel)
    }
});


export {
    mixpanel
}