import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function SingleCameraTileSkeleton() {
    return (
        <><div className="single-cam-playback-main">
        <div className="single-cam-playback-sub"  >
             < Skeleton
                    style={{ borderRadius: "13px", width: "100%", height: "100%" }}
                />
           
            <Skeleton className="mt-3 pt-3">
            </Skeleton>
        </div>
        </div>
        </>
    )
}

export default SingleCameraTileSkeleton