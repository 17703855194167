import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import '../../../app.css'
import Tooltip from 'react-bootstrap/Tooltip'
import { OverlayTrigger } from 'react-bootstrap'
import { MonitoringHubLogo, LeftArrow, LoginImage } from '../../../assets/images'
import { verificationLink } from '../../../services/userAuthServices'

export default function Verification({ match }) {
    //#region react hooks start
    const location = useLocation();
    var t = location.pathname.split('/')
    var emailid = t[t.length - 1].replace('"', '').replace('"', '')
    let history = useHistory();
    //#endregion react hooks end

    //#region useState start
    const [error, setError] = useState(null)
    const [isShowSuccessPage, setIsshowSuccessPage] = useState(false)
    const [isShowResetpasswordcomponent, setIsShowResetpasswordcomponent] = useState(true)
    //#endregion useState end

    const VerificationComponent = ({ error }) => {
        console.log("rupesh");
        const [email, setEmail] = useState(emailid)
        const emailIdChanged = (e) => {
            setEmail(e.target.value)
        }

        const sendMail = async () => {
            console.log("rupesh", email);
            if (email.trim() === '') {
                // setCommonError(null)
                setError('Please enter email')
                return
            }

            verificationLink(email).then((res) => {
                if (res.Status === "success") {
                    setIsshowSuccessPage(true)
                    setIsShowResetpasswordcomponent(false)
                }
                else {
                    setError(res.message)
                }
            }).catch((error) => {
                console.error(error)
            })

        }

        return <>
            <p className="ForgotPasswordName">
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            Back to Login
                        </Tooltip>
                    }
                >
                    <img src={LeftArrow} onClick={() => history.replace('/login')} />
                </OverlayTrigger>
                <span className="ml-3">Resend verification Link</span>
            </p>
            <p className="SelectSpaceLine mb-5">
                Verification  link has been sent to your registered email id. If you don't receive the link in the next 2 mins please go back & click on the Resend verification Link button again.
            </p>
            <button className="ResetPasswordButton" style={{ marginTop: "30px" }} onClick={() => sendMail()}>
                <span className="Manage-Configuration Sign-In">Send now</span>
            </button>
        </>
    }



    const restpage = () => {
        setIsShowResetpasswordcomponent(true)
        setIsshowSuccessPage(false)
        // ReactDOM.render(<Resetpasswordcomponent error={error}/>, document.getElementById("resetpassworddiv"))
    }

    const Successpage = () => (
        <div className="SuccessfulPasswordDiv">
            <p className="ForgotPasswordName">
                <span style={{ margin: "0px 0px 0px 5px" }} >Verification link sent successfully</span>
            </p>
            <p className="SelectSpaceLine mb-5">
                Verification reset link has been sent to your registered email id. If you don't receive the link in the next 2 mins please go back & click on the Resend verification Link button again.
            </p>
            <button className="ResetPasswordButton" onClick={() => history.replace('/login')}>
                <span className="Manage-Configuration Sign-In">Sign In</span>
            </button>
        </div>
    )

    return (
        <div className="container-fluid forgot-pass-container" style={{ backgroundColor: "#fff" }}>
            <div className="row forgot-pass-row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ backgroundColor: "#F6F8FC" }}>
                    <div className="imageWhiteBg">
                        <MonitoringHubLogo height={"70px"} />
                    </div>
                    <div className="BigImage">
                        <img src={LoginImage} />
                    </div>
                </div>
                <div className="col-lg-6 ContentForm col-md-6 col-sm-12 col-xs-12 login-col-2">
                    <div className="WhiteFormSignIn">
                        <div className="Content" id="resetpassworddiv">
                            {isShowSuccessPage && <Successpage />}
                            {isShowResetpasswordcomponent &&
                                < VerificationComponent error={error} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}