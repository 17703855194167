import React from "react"
import SendIcon from '../../assets/images/send.svg'

export default function ChatflowReasonCode({
    selectedReasonCode,
    ReasonCodeSendClick,
    setSelectedReasonCode
}) {
    const handleChange = event => {
        let reasonCodeObj = {
            "btnId": selectedReasonCode.btnId,
            "buttonProperties": selectedReasonCode.buttonProperties,
            "title": event.target.value
        }
        setSelectedReasonCode(reasonCodeObj);
    };
    return (
        <>
            <div className="chatFlowBoxContainer">
                <input id={selectedReasonCode.btnId} className="chatFlowWebChatBoxInput" type="text" value={selectedReasonCode.title}
                    onChange={handleChange}
                />
                <a className="cursor-ptr" style={{ marginRight: "15px" }} onClick={(e) => ReasonCodeSendClick(e)}>
                    <img src={SendIcon} alt="Send Icon" height={20} width={20} />
                </a>
            </div>
        </>
    )
}