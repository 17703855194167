import React from 'react'
import { useHistory } from 'react-router-dom'

import { AlertGenericImg, AlertGenericImg1 } from '../../assets/images'

export default function AlertList({ id, alert, onAddAlertChat,handleChange }) {
    let history = useHistory()
    
    return <div className="ListofAlerts">
        <div className="ListMain">
            <div style={{width: "11%"}}>
                {alert.snapshotURL ?  <img 
                src={alert.snapshotURL} 
                className='AlertSnapshotDbImg'
                alt='snapshot image'
                // onClick={()=>handleChange(true,alert.snapshotURL)}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=AlertGenericImg1;
            }} />: <AlertGenericImg className='AlertSnapshotgenericImg' 
            />}
            </div>
            {/* <img src={cognyfloLogo1} /> */}
            <div className="SituationTitleDiv">

                <p className="OperateBy">
                    {/* ID<br /> */}
                    <span className="OperateName">
                    {id}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                {/* <p className="OperateBy">
                    Severity<br />
                    <span className={`ReportTableAlertSeverity ${alert.severity?.toLowerCase()}`}>
                        {alert.severity}
                    </span>
                </p> */}
                  <p className="OperateBy">
                    {/* Site<br /> */}
                    <span className="OperateName">
                    {alert.siteName}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                {/* <p className="OperateBy">
                    Alert Source<br /> */}
                    {/* {alert.deviceTypeID && alert.deviceTypeID == 1?
                        <span className="OperateName">{alert.alertSource && alert.alertSource}</span> :
                        <span className="OperateName">{alert.zoneType && alert.zoneType}</span>} */}
                    {/* <span className="OperateName">
                        {alert.alertSource}
                    </span>
                </p> */}
               <p className="OperateBy">
                    {/* Customer<br /> */}
                    <span className="OperateName">
                    {alert.customerName}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
            <p className="OperateBy">
                    {/* Device<br /> */}
                    <span className="OperateName">
                    {alert.deviceName}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
            <p className="OperateBy">
                    {/* ACK By<br /> */}
                    <span className="OperateName">
                    {alert.ackbyName? alert.ackbyName : "--"}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                <p className="OperateBy">
                    {/* Date & Time<br /> */}
                    <span className="OperateName">
                        {alert.reqActualTime} {alert.abbreviation? "("+alert.abbreviation+")" : ""}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                {/* <p className="OperateBy">
                    Branch<br />
                    <span className="OperateName">
                        {alert.siteName}
                    </span>
                </p> */}
                 <p className="OperateBy">
                    {/* Status<br /> */}
                    <span className="OperateName">
                        {alert.status}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                <p className="OperateBy">
                    {/* Severity<br /> */}
                    {/* <span className="OperateName">
                        {alert.severity && alert.severity == "Extreme" &&
                            <span className="StatusBtn extreme_colour">{alert.severity}</span>
                        }
                        {alert.severity && alert.severity == "High" &&
                            <span className="StatusBtn high_colour">{alert.severity}</span>
                        }
                        {alert.severity && alert.severity == "Moderate" &&
                            <span className="StatusBtn medium_colour">{alert.severity}</span>
                        }
                        {alert.severity && alert.severity == "Low" &&
                            <span className="StatusBtn low_colour">{alert.severity}</span>
                        }
                    </span> */}
                    <span className="NewOperateName">
                        {alert.severity && alert.severity == "Extreme" &&
                            <p className="StatusBtn extreme_colour">{alert.severity}</p>
                        }
                        {alert.severity && alert.severity == "High" &&
                            <p className="StatusBtn high_colour">{alert.severity}</p>
                        }
                        {alert.severity && alert.severity == "Moderate" &&
                            <p className="StatusBtn medium_colour">{alert.severity}</p>
                        }
                        {alert.severity && alert.severity == "Low" &&
                            <p className="StatusBtn low_colour">{alert.severity}</p>
                        }
                    </span>
                </p>

            </div>
            <div className="OperateByDiv">
                <p className="OperateBy">
                    {/* Type<br /> */}
                    <span className="OperateName">
                        {alert.alertType}
                    </span>
                </p>
            </div>
            <div className="ChartsButtonDiv">
                {/* <button className="AlertViewButton">
                    <p>View Alert</p>
                </button> */}
                <button className="AlertViewButton m-1" onClick={(e) => { history.push(`/alertdetails/${id}`) }}><p>View Alert</p></button>
                {
                    (alert.closedBy != 0)
                    ?
                    <button className="SelectionAlertViewButton m-1" onClick={() => { history.push(`/alertreport/${id}`) }}>
                        <p>Report</p>
                    </button>
                    :
                    <button className="SelectionAlertViewButton m-1" onClick={(e) => { 
                        if (e.detail != 1) {
                            return
                        }
                        onAddAlertChat(id) 
                    }}>
                        <p>Chatflow</p>
                    </button>
                }
            </div>
        </div>
    </div>
}
