import React, { useEffect, useState } from "react"

import UserListComponent from "./UserListComponent"
import UserProfileDataComponent from "./UserProfileComponent"

import Swal from "sweetalert2";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useUsers } from "../../CustomHooks/useUsers"
import { useProfile } from "../../CustomHooks/useProfile"
import { changePassword, updateFirebaseUser, userSoundMode } from "../../services/userAuthServices";
import { SuccessToast } from "../../CustomHooks/SuccessToast";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import { GetUserProfileInfoDotNet } from "../../services/userManagementservice";
import { Eye, EyeMask } from "../../assets/images";

export default function ProfileContainer({ isDarkTheme,checkEnableSound }) {
    const [profileData, setProfileData] = useState({
        "FirstName": "",
        "Email": "",
        "UserID": "",
        "RoleName": ""
    })
    const [userRole, setUserRole] = useState("")

    const { users } = useUsers();
    const { profile, getprofiledata } = useProfile();
    const [password, setPassword] = useState("");
    const [canEdit, setCanEdit] = useState(false);
    const [enableSound, setEnableSound] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [isNotValidPassword, setIsNotValidPassword] = useState(true);
    const [userPasswordError, setUserPasswordError] = useState('');

    const [passwordShown, setPasswordShown] = useState(false);


    useEffect(() => {
        getSoundData();
    }, [])

    useEffect(() => {
        setProfileData(profile);
    }, [profile])

    useEffect(() => {
        if (profileData?.UserRoleID == 1 || profileData?.roleId == 1) {
            setUserRole("Root")
        } else if (profileData?.UserRoleID == 2 || profileData?.roleId == 2) {
            setUserRole("Admin")
        } else if (profileData?.UserRoleID == 3 || profileData?.roleId == 3) {
            setUserRole("Operator")
        }
    }, [profileData])

    const getSoundData = () => {
        let user = JSON.parse(localStorage.getItem('user'))
        console.log("temp", user)
        if(user != null || user != undefined){
            let temp = user.PlaySoundFlag == "1" ? true : false
            setEnableSound(temp);
        }
    }

    const isCapitalCase = (pass) => {
        const pattern = /[A-Z]/;
        return pattern.test(pass)
    }
    const isSpecialCharacter = (pass) => {
        const pattern = /[\W_]/;
        return pattern.test(pass)
    };

    const isNumberInclude = (pass) => {
        const pattern = /\d/;
        return pattern.test(pass)
    }
    const isMinCharacter = (pass) => {
        return pass.length >= 8;
    };
    const isMaxCharacter = (pass) => {
        return pass.length <= 15 && pass.length >= 1;
    };

    const popover = (
        <Popover id="popover-basic" style={{ width: "200px", transform: "translate(1090px, 207px)" }}>
            <Popover.Content>
                <p className={isCapitalCase(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>One capital case
                </p>

                <p className={isSpecialCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>One special character
                </p>

                <p className={isNumberInclude(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>One number
                </p>

                <p className={isMinCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>Minimum 8 Character
                </p>

                <p className={isMaxCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>Maximum 15 Character
                </p>
            </Popover.Content>
        </Popover>
    );
    const tempComponent = (
        <Popover>

        </Popover>
    )

    const onCancelEditing = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to cancel the changes made?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#027aaa",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Leave!"
          }).then((result) => {
            if (result.isConfirmed) {
                setCanEdit(false)
                setPassword("");
                getSoundData();
                setUserPasswordError("");
                setPasswordShown(false)
            }
          })
    }

    const updateProfileData = () => {
        setButtonDisable(true);
        if (password == ""){
            // ErrorToast("Kindly enter a password!")
            setUserPasswordError("Kindly enter a password!")
            setButtonDisable(false);
            return;
        }
        if(isNotValidPassword){
            // ErrorToast("Please enter a valid password!");
            setUserPasswordError("Please enter a valid password!")
            setButtonDisable(false);
            return;
        }
        if(password != ""){
            let userObj = JSON.parse(localStorage.getItem('user'));
            updateFirebaseUser(userObj.Email, password)
              .then((res) => {
                changePassword(profileData.UserID, localStorage.getItem('userPassword'), password)
                  .then((res) => {
                    if (res.Status == "success") {
                      localStorage.setItem('userPassword', password);
                      SuccessToast(res.Message)
                      setPassword("");
                      setCanEdit(false);
                      setButtonDisable(false);
                    } else {
                      ErrorToast(res.Message);
                      setPassword("");
                      setButtonDisable(false);
                    }
                  })
              })
              .catch(function (error) {
                console.error(error);
              });
        }

        // console.log("enableSound", enableSound)
        // let userId = JSON.parse(localStorage.getItem("UserID"))
        // let status = enableSound ? 1 : 0
        // userSoundMode(userId, status)
        // .then((res) => {
        //     if(res.Status == "success"){
        //         SuccessToast("Notification sound mode updated.")
        //         GetUserProfileInfoDotNet().then((res) => {
        //             localStorage.setItem('user', JSON.stringify(res.Data))
        //             setCanEdit(false);
        //             checkEnableSound()
        //         })
        //     }
        // });
        // Call API to update data related to user enable/disable sound notification for raised alert.
    }

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value.replace(" ", "");
        setPassword(newPassword);
        setIsNotValidPassword(!(
            newPassword.length > 0 &&
            isCapitalCase(newPassword) &&
            isSpecialCharacter(newPassword) &&
            isNumberInclude(newPassword) &&
            isMinCharacter(newPassword) &&
            isMaxCharacter(newPassword)
        ))            
    }

    return (
        <div className="row" style={{ margin: "0 auto" }}>
            <div className="col-lg-12 pr-0 mt-2 mb-2">
                <div className="NameHeading1">
                    <p>Profile </p>
                </div>
            </div>
            <div className="row col-lg-12 pl-4 pr-4" style={{ margin: "0 auto" }}>
                <div className="MainUserDetailsView" id="Userprofilediv">
                    <UserProfileDataComponent ProfileData={profileData} UserRole={userRole} isDarkTheme={isDarkTheme} />
                </div>
                <div className="MainUserActivity" id="Userlistdiv">
                    {/* <UserListComponent UsersList={users} Userscount={users.length} /> */}
                    <div className="ActivityView">
                    {/* <form> */}
                        <div className="PrepostSettingForm PrePostSettingDiv">
                        <h4 className="prepostCustomername"> Profile Detail </h4>
                        
                        <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer disableColor">
                                        <label className='formFieldLabels'>Email</label>
                                        <input
                                            className="inputType"
                                            placeholder="Email* e.g. rupesh@xyz.com"
                                            defaultValue={profileData?.Email}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <hr className="profileHorizontalLine"></hr>
                        <h6 className="prepostCustomername"> Change Password </h6>
                                <div className="inputMainContainer w40dot75rem mt20">
                                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={!canEdit ? tempComponent : popover}>
                                    <div className="inputTypeLabelContainer">
                                        <label className='formFieldLabels'>Password</label>
                                            <input
                                                autoComplete="off"
                                                name="Password"
                                                className="inputType"
                                                placeholder="Password"
                                                type={passwordShown ? "text" : "password"}
                                                disabled={!canEdit}
                                                value={password}
                                                onChange={(e) => {
                                                    handlePasswordChange(e)
                                                    setUserPasswordError("")
                                                }}
                                                // onChange={(e) => setPassword(e.target.value)}
                                                // {...register("Password")}
                                                // value={password}
                                                // defaultValue={user.Password}
                                                // onChange={(e) => {
                                                //     handlePasswordChange(e)
                                                //     setUserPasswordError('')
                                                // }}
                                                // disabled={!isUserEdit} 
                                                />

                                            <div className="EyeIconDiv">
                                                {!passwordShown ? (
                                                    <img
                                                        className={canEdit ? "EyeIcon" : "disableEyeIcon"}
                                                        src={Eye}
                                                        onClick={() =>
                                                            {if (!canEdit) {
                                                                return;
                                                            }
                                                                setPasswordShown(!passwordShown)} 
                                                    }
                                                    />
                                                ) : (
                                                    <EyeMask
                                                        height={18}
                                                        width={18}
                                                        onClick={() =>
                                                            {if (!canEdit) {
                                                                return;
                                                            }
                                                                setPasswordShown(!passwordShown)} 
                                                    }
                                                    />
                                                )}
                                            </div>
                                        <p className="Error_P" style={{ float: "left" }}><span> {userPasswordError} </span></p>
                                    </div>
                                        </OverlayTrigger>
                                </div>
                                {/* <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer" style={{height: "45px"}}>
                                        <div className="soundToggleDiv">
                                            <label>Enabled/Disabled Sound</label>
                                            <div className='togglecheckbox'>
                                                                            <label>
                                                                                <input disabled={!canEdit} checked={enableSound} onChange={() => setEnableSound(!enableSound)} type="checkbox" style={{ marginRight: "3px" }} />
                                                                                <span />
                                                                            </label>
                                                                        </div>
                                        </div>
                                    </div>
                                </div> */}
                        <div style={{ textAlign: "center", float: "left", marginTop: "10px" }}>
                        
                        {
                                    !canEdit
                                        ?
                                        <button type="submit" className="DataAccessEdit" style={{ width: "100px" }}
                                            onClick={() => setCanEdit(true)}
                                        >
                                            <span className="ViewUpdate">Edit</span>
                                        </button>
                                        :
                                        <>
                                            <button className="DataAccessEdit" style={{ width: "150px" }}
                                            onClick={updateProfileData}
                                            disabled={buttonDisable}
                                            >
                                                <span className="ViewUpdate">Change Password</span>
                                            </button>
                                            <button className="DataAccessEdit" style={{ width: "100px" }} type="submit"
                                                onClick={onCancelEditing}
                                            >
                                                <span className="ViewUpdate">Cancel</span>
                                            </button>
                                        </>
                                }
                    </div>
                        </div>
                    {/* </form> */}
                <div style={{ textAlign: "center" }}>
                    
                            {/* {
                                !canEdit
                                    ?
                                    <button type="submit" className="DataAccessEdit" style={{ width: "100px" }}
                                        onClick={() => setCanEdit(true)}
                                    >
                                        <span className="ViewUpdate">Edit</span>
                                    </button>
                                    :
                                    <>
                                        <button className="DataAccessEdit" style={{ width: "100px" }}
                                        onClick={updateProfileData}
                                        disabled={buttonDisable}
                                        >
                                            <span className="ViewUpdate">Update</span>
                                        </button>
                                        <button className="DataAccessEdit" style={{ width: "100px" }} type="submit"
                                            onClick={onCancelEditing}
                                        >
                                            <span className="ViewUpdate">Cancel</span>
                                        </button>
                                    </>
                            } */}
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}