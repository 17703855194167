import React, { useState, useEffect } from "react";

import { DropArrow, SearchIcon, SearchIconWhite } from "../../assets/images";

import { getSiteList, getDeviceTypeList } from "../../services/configurationServices";
import { addNotifs, deleteNotifs, getMentionsList, getNotifById, getNotifs, updateNotifs } from "../../services/notifService";

import { Mention, MentionsInput } from "react-mentions";
import { MultiSelect } from "react-multi-select-component";
import Swal from 'sweetalert2';

import mentionsInputStyles from "./mentionsInputStyles";
import mentionStyles from "./mentionStyles";
import RegisteredUsers from "./RegisteredUsers";
import CustomContact from "./CustomContact";
import { WarningToast } from "../../CustomHooks/WarningToast";
import { SuccessToast } from "../../CustomHooks/SuccessToast";
import { ErrorToast } from "../../CustomHooks/ErrorToast";

const REALTIME = "REALTIME";
const DAILY = "DAILY";
const HOURLY = "HOURLY";
const WEEKLY = "WEEKLY";
const MONTHLY = "MONTHLY";

const NotificationContainer = ({ addNewClicked, setAddNewClicked, isNotifFormEnabled, setIsNotifFormEnabled, notifSaveBtnClicked, notifDeleteBtnClicked, setNotifSaveBtnClicked, setNotifDeleteBtnClicked, setIsEmptyForm, isDarkTheme,setButtonDisable}) => {

  //region useState start
  const [selectedSiteTab, setSelectedSiteTab] = useState("realTime");
  const [notifList, setNotifList] = useState([]);
  const [mainNotifList, setMainNotifList] = useState([]);
  const [notifSearch, setNotifSearch] = useState("");
  const [notificationName, setNotificationName] = useState("");
  const [emergencyContact, setEmergencyContact] = useState(false);
  const [customContact, setCustomContact] = useState([{
    Id: 0,
    Name: "",
    Designation: "",
    ContactNumber: "",
    ContactEmail: ""
  }]);
  const [mentionList, setMentionList] = useState([]);
  //Email Notification Template State Values Start
  const [realTimeEnabled, setRealTimeEnabled] = useState(false);
  const [realTimeSubject, setRealTimeSubject] = useState("");
  const [mentionRealTimeSubject, setMentionRealTimeSubject] = useState("");
  const [realTimeMessage, setRealTimeMessage] = useState("");
  const [mentionRealTimeMessage, setMentionRealTimeMessage] = useState("");

  const [hourlyEnabled, setHourlyEnabled] = useState(false);
  const [mentionHourlySubject, setMentionHourlySubject] = useState("");
  const [mentionHourlyMessage, setMentionHourlyMessage] = useState("");

  const [dailyEnabled, setDailyEnabled] = useState(false);
  const [mentionDailySubject, setMentionDailySubject] = useState("");
  const [mentionDailyMessage, setMentionDailyMessage] = useState("");

  const [weeklyEnabled, setWeeklyEnabled] = useState(false);
  const [mentionWeeklySubject, setMentionWeeklySubject] = useState("");
  const [mentionWeeklyMessage, setMentionWeeklyMessage] = useState("");

  const [monthlyEnabled, setMonthlyEnabled] = useState(false);
  const [mentionMonthlySubject, setMentionMonthlySubject] = useState("");
  const [mentionMonthlyMessage, setMentionMonthlyMessage] = useState("");
  //Email Notification Template State Values End

  const [userList, setUserList] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [siteListString, setSiteListString] = useState("");
  const [mainCustomerList, setMainCustomerList] = useState([{
    Id: 0,
    Name: "",
    Designation: "",
    ContactNumber: "",
    ContactEmail: ""
  }]);
  const [count, setCount] = useState(0);
  const [enableAdd, setEnableAdd] = useState(false);
  const [siteSelected, setSiteSelected] = useState([]);
  const [deviceTypeSelected, setDeviceTypeSelected] = useState([]);
  const [eventCodeSelected, setEventCodeSelected] = useState([]);
  const [siteListData, setSiteListData] = useState([]);
  const [deviceTypeListData, setDeviceTypeListData] = useState([]);
  const [selectedNotifObj, setSelectedNotifObj] = useState(null);
  const [selectedAllRegUsers, setSelectedAllRegUsers] = useState(false);
  const [notificationNameError, setNotificationNameError] = useState('');
  const [notificationSiteError, setNotificationSiteError] = useState('');
  const [notificationDeviceTypeError, setNotificationDeviceTypeError] = useState('');
  const [notificationEventcodeError, setNotificationEventCodeError] = useState('');
  //region useState end

  const eventCodeListArray = [
    { label: "Online", value: "1" },
    { label: "Offline", value: "2" },
  ];

  const siteListArray = siteListData.map((site) => ({
    value: site.SiteId,
    label: site.SiteName,
  }));

  const deviceTypeListArray = deviceTypeListData.map((device) => ({
    value: device.DeviceTypeId,
    label: device.DeviceType,
  }));

  //region useEffect start
  useEffect(() => {
    getNotificationList();
  }, []);

  useEffect(() => {
    let temp = siteSelected.map((item) => item.value).join(",");
    let oldString = siteListString;
    let oldArr = siteListString.split(",");
    let newArr = temp.split(",");
    if (oldArr.length > newArr.length) {
      let diff = oldArr.filter(e => !newArr.includes(e));
      let anotherTempArr = selectedUsersList;
      for (let i = 0; i < diff.length; i++) {
        anotherTempArr = anotherTempArr.filter((item) => item.Item2 != diff[i]);
      }
      setSelectedUsersList(anotherTempArr);
    }
    setSiteListString(temp);
  }, [siteSelected])

  useEffect(() => {
    if (notifSaveBtnClicked) {
      setNotifSaveBtnClicked(false);
      //Call Function for adding
      addNotification();
    }
  }, [notifSaveBtnClicked]);

  useEffect(() => {
    if (!isNotifFormEnabled && selectedNotifObj === null) {
      clearInputFields();
    }
    if (!isNotifFormEnabled && selectedNotifObj !== null) {
      getNotificationById(selectedNotifObj);
      clearNotificationFields();
    }
  }, [isNotifFormEnabled]);

  useEffect(() => {
    if (notifDeleteBtnClicked) {
      //Call Function to delete the Noti
      deleteNotification();
    }
  }, [notifDeleteBtnClicked]);

  useEffect(() => {
    getMentionsList()
      .then((res) => {
        if (res.Status == "success") {
          setMentionList(res.Data);
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (addNewClicked) {
      //Empty all the fields(
      clearInputFields();
    }
  }, [addNewClicked]);

  useEffect(() => {
    getSiteListFn();
    getDeviceTypeListFn();
  }, [])
  //region useEffect end

  //region function start
  const clearNotificationFields = () => {
    setNotificationNameError('');
    setNotificationSiteError('');
    setNotificationDeviceTypeError('');
    setNotificationEventCodeError('');
  }

  const deleteNotification = () => {
    setButtonDisable(true)
    if (selectedNotifObj == null) {
      WarningToast("Kindly select a notification to delete it.")
      return;
    }
    try {
      Swal.fire({
        title: 'Are you sure?',
        //text: "Deleting this data access group will delete all the associations related to it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          let updateObj = {
            NotificationConfigurationID: selectedNotifObj.NotificationConfigurationID,
            NotificationtemplateName: notificationName,
            NotificationConfigurationDetail: {
              LstSites: [""],
              LstDeviecType: [""],
              LstEventCode: [""],
              LstNotificationTemplateEmail: [{
                Subject: "",
                Body: "",
                Type: "RealTime",
                IsEnabled: realTimeEnabled.toString()
              },
              {
                Subject: "",
                Body: "",
                Type: "Hourly",
                IsEnabled: hourlyEnabled.toString()
              },
              {
                Subject: "",
                Body: "",
                Type: "Daily",
                IsEnabled: dailyEnabled.toString()
              },
              {
                Subject: "",
                Body: "",
                Type: "Weekly",
                IsEnabled: weeklyEnabled.toString()
              },
              {
                Subject: "",
                Body: "",
                Type: "Monthly",
                IsEnabled: monthlyEnabled.toString()
              }],
              EmergencyContact: "",
              LstUserSite: [{
                Item1: "",
                Item2: ""
              }],
              LstCustomContact: [{
                Id: "",
                Name: "",
                Designation: "",
                ContactNumber: "",
                ContactEmail: ""
              }]
            }
          }
          deleteNotifs(updateObj)
            .then(res => {
              if (res.Status == "success") {
                setButtonDisable(false)
                SuccessToast(res.Message);
                getNotificationList();
                setNotifDeleteBtnClicked(false);
                clearInputFields();
                setIsNotifFormEnabled(true);
                setIsEmptyForm(true);
              }
            }).catch(err => console.log(err))
        } else {
          setButtonDisable(false)
          setNotifDeleteBtnClicked(false);
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  const addNotification = () => {
    setButtonDisable(true)
    let validationError = "";
    if (notificationName == "") {
      validationError += "notification name-Kindly enter a name for the template!,";
    }
    if (siteSelected.length === 0) {
      validationError += "notification site-Kindly select some sites!,";
    }
    let tempSites = siteSelected.map((item) => item.value);

    if (deviceTypeSelected.length === 0) {
      validationError += "notification device-Kindly select a type of device!,";
    }
    let tempDeviceTypes = deviceTypeSelected.map((item) => item.value);

    if (eventCodeSelected.length === 0) {
      validationError += "notification event-Kindly select a type of event!,";
    }

    let templst = validationError.split(",");
    for (let i = 0; i < templst.length; i++) {
      if (templst[i].includes("notification name-")) {
        setNotificationNameError(templst[i].replace("notification name-", ""));
        continue
      }
      if (templst[i].includes("notification site-")) {
        setNotificationSiteError(templst[i].replace("notification site-", ""));
        continue
      }
      if (templst[i].includes("notification device-")) {
        setNotificationDeviceTypeError(templst[i].replace("notification device-", ""));
        continue
      }
      if (templst[i].includes("notification event-")) {
        setNotificationEventCodeError(templst[i].replace("notification event-", ""));
        continue
      }
    }

    if (validationError != "") {
      setButtonDisable(false)
      return;
    }

    let tempEventType = eventCodeSelected.map((item) => item.value);

    if (realTimeEnabled && (mentionRealTimeSubject === "" || mentionRealTimeMessage === "")) {
      ErrorToast("Kindly fill the real time email notification template");
      setButtonDisable(false)
      return;
    }

    if (mentionRealTimeSubject === "" || mentionRealTimeMessage === "") {
      ErrorToast("Kindly fill the real time email notification template");
      setButtonDisable(false)
      return;
    }

    if (hourlyEnabled && (mentionHourlySubject === "" || mentionHourlyMessage === "")) {
      ErrorToast("Kindly fill the hourly email notification template");
      setButtonDisable(false)
      return;
    }

    if (dailyEnabled && (mentionDailySubject === "" || mentionDailyMessage === "")) {
      ErrorToast("Kindly fill the daily email notification template");
      setButtonDisable(false)
      return;
    }

    if (weeklyEnabled && (mentionWeeklySubject === "" || mentionWeeklyMessage === "")) {
      ErrorToast("Kindly fill the weekly email notification template");
      setButtonDisable(false)
      return;
    }

    if (monthlyEnabled && (mentionMonthlySubject === "" || mentionMonthlyMessage === "")) {
      ErrorToast("Kindly fill the monthly email notification template");
      setButtonDisable(false)
      return;
    }

    setEnableAdd(false);
    let lastForm = mainCustomerList[mainCustomerList.length - 1];
    let contactNameEntered = lastForm?.Name;
    let contactEmailEntered = lastForm?.ContactEmail;
    let contactNumberEntered = lastForm?.ContactNumber;
    const emailPattern = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;

    if (contactNameEntered && contactEmailEntered && contactNumberEntered) {
      if (enableAdd) {
        WarningToast("Please complete the contact details to add another");
      } else {
        if (emailPattern.test(contactEmailEntered)) {
          // Valid email logic
        } else {
          ErrorToast("Please enter email id properly");
          setButtonDisable(false)
          return;
        }
        if (contactNumberEntered.length !== 10) {
          ErrorToast("Please enter contact number properly");
          setButtonDisable(false)
          return;
        }
      }
    }

    let isEmptyFields = false;
    if (mainCustomerList.length === 1) {
      for (let x = 0; x < mainCustomerList.length; x++) {
        if ((mainCustomerList[x].Name == "" || mainCustomerList[x].ContactNumber == "" || mainCustomerList[x].ContactEmail == "")
          && !(mainCustomerList[x].Name == "" && mainCustomerList[x].ContactNumber == "" && mainCustomerList[x].ContactEmail == "" && mainCustomerList[x].Designation == "")) {
          ErrorToast('Kindly complete the custom contact details.');
          setButtonDisable(false)
          return;
        }
      }
    } else {
      for (let x = 0; x < mainCustomerList.length; x++) {
        if ((mainCustomerList[x].Name == "" || mainCustomerList[x].ContactNumber == "" || mainCustomerList[x].ContactEmail == "")) {
          ErrorToast('Kindly complete the custom contact details.');
          setButtonDisable(false)
          return;
        }
      }
    }

    let tempEmergencyContact;
    if (emergencyContact) {
      tempEmergencyContact = "yes"
    } else {
      tempEmergencyContact = "no"
    }

    let tempCustomContact = mainCustomerList.map((item) => {
      let obj = {
        ...item,
        Id: item.Id.toString(),
      }
      return obj;
    });

    if (selectedNotifObj === null) {
      // Add Part
      let obj = {
        NotificationtemplateName: notificationName.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
        NotificationConfigurationDetail: {
          LstSites: tempSites,
          LstDeviecType: tempDeviceTypes,
          LstEventCode: tempEventType,
          LstNotificationTemplateEmail: [{
            Subject: mentionRealTimeSubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionRealTimeMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "RealTime",
            IsEnabled: realTimeEnabled.toString()
          },
          {
            Subject: mentionHourlySubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionHourlyMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Hourly",
            IsEnabled: hourlyEnabled.toString()
          },
          {
            Subject: mentionDailySubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionDailyMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Daily",
            IsEnabled: dailyEnabled.toString()
          },
          {
            Subject: mentionWeeklySubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionWeeklyMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Weekly",
            IsEnabled: weeklyEnabled.toString()
          },
          {
            Subject: mentionMonthlySubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionMonthlyMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Monthly",
            IsEnabled: monthlyEnabled.toString()
          }],
          EmergencyContact: tempEmergencyContact,
          LstUserSite: selectedUsersList,
          LstCustomContact: tempCustomContact
        }
      }
      addNotifs(obj)
        .then(res => {
          if (res.Status == "success") {
            setButtonDisable(false)
            SuccessToast(res.Message);
            getNotificationList();
            clearInputFields();
            setNotifSaveBtnClicked(false);
          } else {
            setButtonDisable(false)
            ErrorToast(res.Message);
          }
        }).catch(err => console.log(err));
    } else {
      // Update Part
      let updateObj = {
        NotificationConfigurationID: selectedNotifObj.NotificationConfigurationID,
        NotificationtemplateName: notificationName.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
        NotificationConfigurationDetail: {
          LstSites: tempSites,
          LstDeviecType: tempDeviceTypes,
          LstEventCode: tempEventType,
          LstNotificationTemplateEmail: [{
            Subject: mentionRealTimeSubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionRealTimeMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "RealTime",
            IsEnabled: realTimeEnabled.toString()
          },
          {
            Subject: mentionHourlySubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionHourlyMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Hourly",
            IsEnabled: hourlyEnabled.toString()
          },
          {
            Subject: mentionDailySubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionDailyMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Daily",
            IsEnabled: dailyEnabled.toString()
          },
          {
            Subject: mentionWeeklySubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionWeeklyMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Weekly",
            IsEnabled: weeklyEnabled.toString()
          },
          {
            Subject: mentionMonthlySubject.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionMonthlyMessage.replaceAll("\\", "\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Monthly",
            IsEnabled: monthlyEnabled.toString()
          }],
          EmergencyContact: tempEmergencyContact,
          LstUserSite: selectedUsersList,
          LstCustomContact: tempCustomContact
        }
      }
      updateNotifs(updateObj)
        .then(res => {
          if (res.Status == "success") {
            setButtonDisable(false)
            SuccessToast(res.Message);
            getNotificationList();
            setIsNotifFormEnabled(false);
            setNotifSaveBtnClicked(false);
          } else {
            setButtonDisable(false)
            ErrorToast(res.Message);
          }
        }).catch(err => console.log(err));
    }
  }

  const getNotificationList = () => {
    getNotifs()
      .then(res => {
        if (res.Status == "success") {
          setNotifList(res.Data);
          setMainNotifList(res.Data);
        } else {
        }
      })
      .catch(err => console.log(err));
  }

  const getSiteListFn = () => {
    try {
      getSiteList().then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            setSiteListData(resp.Data)
          } else {
            setSiteListData([])
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getDeviceTypeListFn = () => {
    try {
      getDeviceTypeList().then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            setDeviceTypeListData(resp.Data)
          } else {
            setDeviceTypeListData([])
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const clearInputFields = () => {
    setNotificationName("");
    setSiteSelected([]);
    setSiteListString("");
    setDeviceTypeSelected([]);
    setEventCodeSelected([]);
    setRealTimeEnabled(false);
    setRealTimeSubject("");
    setMentionRealTimeSubject("");
    setRealTimeMessage("");
    setMentionRealTimeMessage("");
    setHourlyEnabled(false);
    setMentionHourlySubject("");
    setMentionHourlyMessage("");
    setDailyEnabled(false);
    setMentionDailySubject("");
    setMentionDailyMessage("");
    setWeeklyEnabled(false);
    setMentionWeeklySubject("");
    setMentionWeeklyMessage("");
    setMonthlyEnabled(false);
    setMentionMonthlySubject("");
    setMentionMonthlyMessage("");
    setEmergencyContact(false);
    setSelectedUsersList([]);
    setUserList([]);
    setCustomContact([{
      Id: 1,
      Name: "",
      Designation: "",
      ContactNumber: "",
      ContactEmail: ""
    }]);
    setMainCustomerList([{
      Id: 1,
      Name: "",
      Designation: "",
      ContactNumber: "",
      ContactEmail: ""
    }])
    setAddNewClicked(false);
    setSelectedNotifObj(null);
  }

  const subjectHandler = (e, type, newValue, newPlainTextValue) => {
    let tempString = newPlainTextValue;
    switch (type) {
      case REALTIME:
        setRealTimeSubject(e.target.value);
        setMentionRealTimeSubject(tempString);
        break;
      case HOURLY:
        setMentionHourlySubject(tempString)
        break;
      case DAILY:
        setMentionDailySubject(tempString)
        break;
      case WEEKLY:
        setMentionWeeklySubject(tempString)
        break;
      case MONTHLY:
        setMentionMonthlySubject(tempString)
        break;
      default:
        break;
    }
  }

  const messageHandler = (e, type, newValue, newPlainTextValue) => {
    let tempString = newPlainTextValue;
    switch (type) {
      case REALTIME:
        setRealTimeMessage(e.target.value);
        setMentionRealTimeMessage(tempString);
        break;
      case HOURLY:
        setMentionHourlyMessage(tempString)
        break;
      case DAILY:
        setMentionDailyMessage(tempString)
        break;
      case WEEKLY:
        setMentionWeeklyMessage(tempString)
        break;
      case MONTHLY:
        setMentionMonthlyMessage(tempString)
        break;
      default:
        break;
    }
  }

  const notifSelected = (item) => {
    if (item.NotificationConfigurationID == selectedNotifObj?.NotificationConfigurationID) {
      return;
    }
    if (selectedNotifObj != null && isNotifFormEnabled) {
      Swal.fire({
        title: 'Operation in Progress.',
        text: "You have started the operation for a notification, do you want to discard any changes and continue to the other notification.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          getNotificationById(item);
        }
      })
    } else {
      getNotificationById(item);
      setIsNotifFormEnabled(false);
      setIsEmptyForm(false);
    }
  }

  const getNotificationById = (item) => {
    getNotifById(item.NotificationConfigurationID)
      .then((res) => {
        if (res.Status == "success") {
          let item = res.Data;
          setSelectedNotifObj(res.Data);
          setNotificationName(item.NotificationtemplateName);
          let siteTempArr = siteListArray.filter(site => item.NotificationConfigurationDetail.LstSites.includes(site.value));
          setSiteSelected(siteTempArr);
          let deviceTypeArr = deviceTypeListArray.filter(device => item.NotificationConfigurationDetail.LstDeviecType.includes(device.value))
          setDeviceTypeSelected(deviceTypeArr);
          let eventCodeArr = eventCodeListArray.filter((event) => item.NotificationConfigurationDetail.LstEventCode.includes(event.value));
          setEventCodeSelected(eventCodeArr);
          //Block to set value to email alert template.
          //1.RealTime
          let realTimeArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "RealTime");
          if (realTimeArr.length > 0) {
            setRealTimeSubject(realTimeArr[0]?.Subject);
            setMentionRealTimeSubject(realTimeArr[0]?.Subject);
            setRealTimeMessage(realTimeArr[0]?.Body);
            setMentionRealTimeMessage(realTimeArr[0]?.Body);
            setRealTimeEnabled(String(realTimeArr[0]?.IsEnabled) == "true");
          } else {
            setRealTimeSubject("");
            setMentionRealTimeSubject("");
            setRealTimeMessage("");
            setMentionRealTimeMessage("");
            setRealTimeEnabled(false);
          }

          //2.Hourly
          let hourlyArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "Hourly");
          if (hourlyArr.length > 0) {
            setMentionHourlySubject(hourlyArr[0].Subject);
            setMentionHourlyMessage(hourlyArr[0].Body);
            setHourlyEnabled(String(hourlyArr[0].IsEnabled) == "true");
          } else {
            setMentionHourlySubject("");
            setMentionHourlyMessage("");
            setHourlyEnabled(false);
          }

          //3.Daily
          let dailyArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "Daily");
          if (dailyArr.length > 0) {
            setMentionDailySubject(dailyArr[0].Subject);
            setMentionDailyMessage(dailyArr[0].Body);
            setDailyEnabled(String(dailyArr[0].IsEnabled) == "true");
          } else {
            setMentionDailySubject("");
            setMentionDailyMessage("");
            setDailyEnabled(false);
          }

          //4.Weekly
          let weeklyArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "Weekly");
          if (weeklyArr.length > 0) {
            setMentionWeeklySubject(weeklyArr[0].Subject);
            setMentionWeeklyMessage(weeklyArr[0].Body);
            setWeeklyEnabled(String(weeklyArr[0].IsEnabled) == "true");
          } else {
            setMentionWeeklySubject("");
            setMentionWeeklyMessage("");
            setWeeklyEnabled(false);
          }

          //5.Monthly
          let monthlyArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "Monthly");
          if (monthlyArr.length > 0) {
            setMentionMonthlySubject(monthlyArr[0].Subject);
            setMentionMonthlyMessage(monthlyArr[0].Body);
            setMonthlyEnabled(String(monthlyArr[0].IsEnabled) == "true");
          } else {
            setMentionMonthlySubject("");
            setMentionMonthlyMessage("");
            setMonthlyEnabled(false);
          }

          // For Registered Users
          // setCustomContact(item.NotificationConfigurationDetail.LstCustomContact)
          if (item.NotificationConfigurationDetail.LstCustomContact.length == 0) {
            let arr = [{
              Id: 0,
              Name: "",
              Designation: "",
              ContactNumber: "",
              ContactEmail: ""
            }];
            setCount(0);
            setMainCustomerList(arr);
            setCustomContact(arr);
          } else {
            let initCount = item.NotificationConfigurationDetail.LstCustomContact[item.NotificationConfigurationDetail.LstCustomContact.length - 1].Id;
            setCount(initCount);
            setMainCustomerList(item.NotificationConfigurationDetail.LstCustomContact);
            setCustomContact(item.NotificationConfigurationDetail.LstCustomContact)
          }
          let getEmergencyContact = false;
          if (item.NotificationConfigurationDetail.EmergencyContact == "yes") {
            getEmergencyContact = true;
          }
          setEmergencyContact(getEmergencyContact);
          setSelectedUsersList(item.NotificationConfigurationDetail.LstUserSite);
          setIsNotifFormEnabled(false);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const onAddClick = (e) => {
    e.preventDefault();
    let lastForm = mainCustomerList[mainCustomerList.length - 1];
    let contactEmailEntered = lastForm?.ContactEmail;
    let contactNumberEntered = lastForm?.ContactNumber;
    const emailPattern = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;

    if (enableAdd) {
      WarningToast("Please complete the contact details to add another")
      return;
    } else {
      console.log("Button Clicked", count, contactEmailEntered, contactNumberEntered);
      if (contactEmailEntered != undefined && contactNumberEntered != undefined) {
        if (emailPattern.test(contactEmailEntered)) { } else {
          ErrorToast("Please enter email id properly")
          return;
        }
        if (contactNumberEntered.length != 10) {
          ErrorToast("Please enter contact number properly")
          return;
        }
      }
    }
    let c = count + 1;
    let tempObj = {
      Id: c,
      Name: "",
      Designation: "",
      ContactNumber: "",
      ContactEmail: ""
    }
    let tempArr = mainCustomerList
    tempArr.push(tempObj);
    setMainCustomerList(tempArr);
    setCustomContact(tempArr);
    setCount(c)
  }

  const onDeleteClick = (e, id) => {
    e.preventDefault();
    let temp = customContact.filter(item => item.Id == id);
    if (temp[0].Name === "" || temp[0].Designation === "" || temp[0].ContactNumber === "" || temp[0].ContactEmail === "") {
      setEnableAdd(false);
    }
    let arr = mainCustomerList.filter(item => item.Id != id);
    setMainCustomerList(arr);
    let arrTemp = customContact.filter(item => item.Id != id)
    setCustomContact(arrTemp);
  }

  const searchNotifList = (e) => {
    setNotifSearch(e.target.value);
    let tempArr = mainNotifList.filter(x => x.NotificationtemplateName.toLowerCase().includes(e.target.value.toLowerCase()))
    setNotifList(tempArr);
  }
  //region function end

  return (
    <>
      <div className="col-lg-12 mt-2">
        <div className="SiteMainContainer">
          <div className="SiteLeftContainer">
            <div className="m-2 HeaderSiteList">
              <input
                id="Search_txt"
                className="SearchViewInput1"
                type="text"
                placeholder="Search"
                value={notifSearch}
                onChange={(e) => searchNotifList(e)}
              />
              <div className="SearchTextDivConfigTool">
                {
                  isDarkTheme ?
                    <img
                      className="EyeIcon"
                      src={SearchIcon}
                      alt="search icon"
                    /> :
                    <img
                      className="EyeIcon"
                      src={SearchIconWhite} height="15"
                      alt="search icon"
                    />
                }
              </div>
            </div>
            <div className="NotificationList">
              {
                notifList && (
                  notifList?.length > 0 ?
                    notifList.map((item) => {
                      return (
                        <div
                          className={selectedNotifObj?.NotificationConfigurationID === item.NotificationConfigurationID ? "OneList SelectedList" : "OneList"}
                          key={item.NotificationConfigurationID}
                          onClick={(e) => {
                            e.stopPropagation();
                            notifSelected(item);
                            clearNotificationFields();
                          }}
                        >
                          <p className="ListofText">
                            {item.NotificationtemplateName}
                          </p>
                        </div>
                      );
                    }) : <div className="no-data-found"><p>No Notification Found.</p></div>
                )
              }
              {!notifList && (
                <div className="no-data-found">No Notification List Available.</div>
              )}
            </div>
          </div>
          <div className="SiteRightContainer">
            <form>
              <div className="Notification-form">
                <div className="inputMainContainer w40dot75rem mt30">
                  <div className="inputTypeLabelContainer">
                    <label className='formFieldLabels'>Name</label>
                    <input
                      className="inputType"
                      placeholder="Enter notification name here"
                      value={notificationName}
                      onChange={(e) => {
                        setNotificationName(e.target.value);
                        setNotificationNameError('')
                      }}
                      disabled={!isNotifFormEnabled}
                    />
                    <p className="Error_P" style={{ float: "left" }}><span> {notificationNameError} </span></p>
                  </div>
                </div>
                <div className="grid-container Notificationgrid-container grid-container--fit mt20">
                  <div className="inputMainContainer w40dot75rem">
                    <div className="inputTypeLabelContainer">
                      <label className='formFieldLabels'>Site</label>
                      <MultiSelect
                        disabled={!isNotifFormEnabled}
                        options={siteListArray}
                        value={siteSelected}
                        onChange={(e) => {
                          setSiteSelected(e);
                          setNotificationSiteError('')
                        }}
                        labelledBy={"Select"}
                        overrideStrings={{
                          selectSomeItems: "Select site",
                          allItemsAreSelected: "All sites are selected"
                        }}
                        ArrowRenderer={() =>
                          <DropArrow />
                        }
                      />
                      <p className="Error_P" style={{ float: "left" }}><span> {notificationSiteError} </span></p>
                    </div>
                  </div>
                  <div className="inputMainContainer w40dot75rem">
                    <div className="inputTypeLabelContainer">
                      <label className='formFieldLabels'>Device Type </label>
                      <MultiSelect
                        disabled={!isNotifFormEnabled}
                        options={deviceTypeListArray}
                        value={deviceTypeSelected}
                        onChange={(e) => {
                          setDeviceTypeSelected(e);
                          setNotificationDeviceTypeError('')
                        }}
                        labelledBy={"Select"}
                        overrideStrings={{
                          selectSomeItems: "Select device type",
                          allItemsAreSelected: "All devices are selected"
                        }}
                        ArrowRenderer={() =>
                          <DropArrow />
                        }
                      />
                      <p className="Error_P" style={{ float: "left" }}><span> {notificationDeviceTypeError} </span></p>
                    </div>
                  </div>

                  <div className="inputMainContainer w40dot75rem">
                    <div className="inputTypeLabelContainer">
                      <label className='formFieldLabels'>Event Code</label>
                      <MultiSelect
                        disabled={!isNotifFormEnabled}
                        options={eventCodeListArray}
                        value={eventCodeSelected}
                        onChange={(e) => {
                          setEventCodeSelected(e);
                          setNotificationEventCodeError('')
                        }}
                        labelledBy={"Select"}
                        overrideStrings={{
                          selectSomeItems: "Select event code type",
                          allItemsAreSelected: "All event codes are selected"
                        }}
                        ArrowRenderer={() =>
                          <DropArrow />
                        }
                      />
                      <p className="Error_P" style={{ float: "left" }}><span> {notificationEventcodeError} </span></p>
                    </div>
                  </div>
                </div>

                <p className="SiteNameText">Email Notification Template</p>
                <div className="NotificationEmailMsgBox">
                  <div className="EmailMsgBoxHeading">
                    <ul className="nav nav-pills">
                      <li className="EmailNotificationNavItem" disabled={!isNotifFormEnabled}>
                        <a className={
                          selectedSiteTab == "realTime"
                            ? "nav-link active bottom-three-sites"
                            : "inactive-text-dashboard bottom-three-sites"
                        }
                          onClick={() => setSelectedSiteTab("realTime")}
                        >
                          <div className="Notificationcheckwithlabel">
                            <input checked={realTimeEnabled}
                              onChange={(e) => setRealTimeEnabled(!realTimeEnabled)}
                              type="checkbox"
                              disabled={!isNotifFormEnabled} style={{ marginRight: "3px" }} />
                            <label style={{ margin: "0px" }}>Real Time</label>
                          </div>
                        </a>
                      </li>

                    </ul>
                  </div>
                  <div className="tab-content">
                    {selectedSiteTab && selectedSiteTab == "realTime" && (
                      <>
                        <div className="startDiv">
                          <MentionsInput
                            style={mentionsInputStyles}
                            singleLine
                            placeholder="Enter Subject here"
                            disabled={!isNotifFormEnabled}
                            value={realTimeSubject}
                            onChange={(e, newValue, newPlainTextValue) => subjectHandler(e, REALTIME, newValue, newPlainTextValue)}
                          >
                            <Mention data={mentionList} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                        <div className="EmailDivideLine"></div>
                        <div className="mentionDiv">
                          <MentionsInput
                            style={mentionsInputStyles}
                            placeholder="Enter message details here"
                            rows={5}
                            disabled={!isNotifFormEnabled}
                            value={realTimeMessage}
                            onChange={(e, newValue, newPlainTextValue) => messageHandler(e, REALTIME, newValue, newPlainTextValue)}
                          >
                            <Mention data={mentionList} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <RegisteredUsers selectedUsersList={selectedUsersList} setSelectedUsersList={setSelectedUsersList} isNotifFormEnabled={isNotifFormEnabled} userList={userList} setUserList={setUserList} siteListString={siteListString} selectedAllRegUsers={selectedAllRegUsers} setSelectedAllRegUsers={setSelectedAllRegUsers} isDarkTheme={isDarkTheme} />
                <CustomContact addNewClicked={addNewClicked} customContact={customContact} setCustomContact={setCustomContact} onAddClick={onAddClick} isNotifFormEnabled={isNotifFormEnabled} count={count} mainCustomerList={mainCustomerList} onDeleteClick={onDeleteClick} setEnableAdd={setEnableAdd} setMainCustomerList={setMainCustomerList} isDarkTheme={isDarkTheme} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationContainer;