import React from 'react'

export default function ActivityReportTable({ props }) {
    return <>
    <tr>
      <td>{props.AlertId}</td>
      <td>{props.ClientName}</td>
      <td className="ReportTableFontBold">{props.SiteName}</td>
      <td>{props.DeviceName}</td>
      <td>{props.DeviceType}</td>
      <td>{props.AlertType}</td>
      <td className={`ReportTableAlertSeverity ${props.Severity.toLowerCase()}`}>{props.Severity}</td>
      <td className="ReportTableFontBold">{props.Abbreviation}</td>
      <td>{props.ActucalTime}</td>
      <td>{props.AckOn}</td>
      <td>{props.OperatorName}</td>
      {/* <td className="ReportTableFontBold">{props.workflowName}</td> */}
      <td>{props.ClosedOn}</td>
      <td className="ReportTableFontBold">{props.TimeToCloseAlert && props.TimeToCloseAlert == 'NA' ? 'NA' : props.TimeToCloseAlert} </td> 
    </tr>

    {/* <tr>
      <td>{props.alertId}</td>
      <td className="ReportTableFontBold">{props.siteName}</td>
      <td>{props.alertSource}</td>
      <td>{props.alertType}</td>
      <td className={`ReportTableAlertSeverity ${props.severityTitle.toLowerCase()}`}>{props.severityTitle}</td>
      <td className="ReportTableFontBold">{props.abbreviation}</td>
      <td>{props.actualTime}</td>
      <td>{props.ackOn}</td>
      <td>{props.operatorName}</td>
      <td className="ReportTableFontBold">{props.workflowName}</td>
      <td>{props.closedOn}</td>
      <td className="ReportTableFontBold">{props.timeToCloseAlert && props.timeToCloseAlert == 'NA' ? 'NA' : props.timeToCloseAlert} </td> 
    </tr> */}
  </>
}
