import React from 'react'
import { InfoOrange } from '../../../assets/images'

const AlertTemplateMessage = ({message}) => {
  return (
    <div className="noDataAccessMsg schedulerinfomaindiv m-0">
        <div>
            <img 
            className="informationIcon infoorangeicon" 
            src={InfoOrange} 
            alt="Info"
            />
        </div>
        <div style={{ width: "100%" }}>
            <h3 className="No-Data-Access-Group schedulermessagetext">
            {message}
            </h3>
        </div>
    </div>
  )
}

export default AlertTemplateMessage