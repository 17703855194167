// export default {
//     control: {
//     //   backgroundColor: '#fff',
//     //   fontSize: 16,
//       // fontWeight: 'normal',
//         // border: '1px solid #bebebe',
//         // paddingLeft: '8px',
//         // paddingRight: '8px',
//         // // borderRadius: '8px',
//         // fontSize: '13px',
//         // textAlign: 'left',
//         // width: '100%',
//         // marginBottom: '15px'
//         // minHeight: 100,
//     },
//     '&multiLine': {
//       control: {
//         // fontFamily: 'monospace',
//         height: '100px',
//         // border: '1px solid #bebebe',
//         // paddingLeft: '8px',
//         // paddingRight: '8px',
//         borderRadius: '8px',
//         // fontSize: '13px',
//         // textAlign: 'left',
//         // width: '100%',
//         // marginBottom: '15px'
//       },
//       highlighter: {
//         // border: '1px solid #bebebe',
//         // paddingLeft: '8px',
//         // paddingRight: '8px',
//         // borderRadius: '8px',
//         // fontSize: '13px',
//         // textAlign: 'left',
//         // width: '100%',
//         // marginBottom: '15px'
//       },
//       input: {
//         // border: '1px solid #bebebe',
//         // paddingLeft: '8px',
//         // paddingRight: '8px',
//         // borderRadius: '8px',
//         // fontSize: '13px',
//         // textAlign: 'left',
//         // width: '100%',
//         // marginBottom: '15px'
//       },
//     },
//     '&singleLine': {
//     //   display: 'inline-block',
//     //   width: 180,
//     //   highlighter: {
//     //     padding: 1,
//     //     border: '2px inset transparent',
//     //   },
//     //   input: {
//     //     padding: 1,
//     //     border: '2px inset',
//     //   },
//     },
//     suggestions: {
//       list: {
//         // backgroundColor: 'white',
//         // border: '1px solid .rgba(0,0,0,0.15)',
//         // fontSize: 16,
//       },
//       item: {
//         // padding: '5px 15px',
//         // borderBottom: '1px solid rgba(0,0,0,0.15)',
//         // '&focused': {
//         //   backgroundColor: '#cee4e5',
//         // },
//       },
//     },
//   }

export default {
    control: {
      backgroundColor: 'transparent',
      fontSize: 16,
      // fontWeight: 'normal',
    },
    '&multiLine': {
      control: {
        fontFamily: 'monospace',
        minHeight: 50,  
        maxHeight: 118,
        padding: 5
      },
      highlighter: {
        // padding: 9,
        // border: '1px solid transparent',
      },
      input: {
        paddingLeft: 6,
        paddingTop: 2,
        // border: '1px solid silver',
        border: 'none',
        borderRadius: '8px',
        fontFamily: 'Arial',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        // lineHeight: 2,
        letterSpacing: 'normal',
        textAlign: 'left',
        overflow: "scroll",
        // maxHeight: "50px",
        // color: '#c5c5c5',
      },
    },
    '&singleLine': {
      display: 'inline-block',
      width: '100%',
      control: {
        fontFamily: 'monospace',
        minHeight: 15,
      },
      // width: 180,
      highlighter: {
        // padding: 1,
        // border: '2px inset transparent',
      },
      input: {
        // padding: 1,
        // border: '2px inset',
        paddingLeft: "8px",
        paddingRight: "8px",
        width: '100%',
        marginTop: '15px',
        border: 'none',
        borderRadius: '8px',
        fontFamily: 'Arial',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 2,
        letterSpacing: 'normal',
        textAlign: 'left',
      },
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 16,
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  }