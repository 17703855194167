import React from 'react'
import { Form } from "react-bootstrap";

const RegisteredUsersList = ({ item, isNotifFormEnabled, fullName, siteName, selectItemHandler }) => {
    return (
        <tr>
            <td style={{ width: "5%" }}>
                <Form.Check type='checkbox' checked={item.isItSelected} onChange={() => selectItemHandler(item.UserUniqueId, item.SiteId)} aria-label="option 1" disabled={!isNotifFormEnabled} />
            </td>
            <td>{fullName}</td>
            <td>{siteName}</td>
        </tr>

    )
}

export default RegisteredUsersList