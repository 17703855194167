import React, { memo, useEffect } from "react";
import WebRTCPlayer from "../Cameras/WebRTCPlayer";
import { useDispatch } from "react-redux";
import { playbackActions } from "../../redux/features/shared/playback";

const MultiplaybackView = ({ playbackList }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(playbackActions.resetPlaybackState());
    };
  }, []);

  return (
    <>
      <div className="mosaic-row">
        <div className="camerafilter2x2">
          {playbackList?.map((item) => (
            <>
              <WebRTCPlayer
                key={item.playbackURL}
                id={
                  item.ParentCameraID +
                  "0" +
                  (Math.floor(Math.random() * (1000 - 1 + 1)) + 1).toString()
                }
                url={item?.playbackURL}
                isEncode={item?.isTranscoded}
                item={item}
                upperDivCss="HeightChildDiv"
                videoCss="mosaic"
                // fullVideoCss="player-responsive-fullScreen"
                buttonCss="mosaicButtonsMainDiv"
                noStreamDivCss="HeightChildDiv"
                showName={true}
                // playback={true}

                // prePostDisplay={false}
                // liveButton={true}
                // isSinglePlayback={true}
                // videoExportDownload={() => onSubmitBtn()}
                // exportStartTime={() => exportStartTime()}
                // exportEndTime={() =>exportEndTime()}
                // secondsDiff={() =>differenceTimeSec()}
              />
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default memo(MultiplaybackView);
