
 export const registerServiceWorker = () => {

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
        .register('./firebase-messaging-sw.js',{
           // scope:'/'
        })
        .then(registration => {
            console.log('Service Worker registered with scope:', registration.scope);

            // Listen for messages from the service worker
            navigator.serviceWorker.addEventListener('message', event => {
                console.log('Message received from service worker:', event.data);

                if (event.data && event.data.messageType === 'push-received') {
                    handlePushMessage(event.data.data);
                }
            });
        })
        .catch(error => {
            console.error('Service Worker registration failed:', error);
        });
        
        function handlePushMessage(data) {
            // Update the DOM or perform other actions based on the push message data
            console.log('Push message received in main thread:'+ data);
            
            // Example: Update a notification area in the DOM
            const notificationArea = document.getElementById('notification-area');
            document.getElementsByClassName('badge')[0].innerText=String((parseInt(document.getElementsByClassName('badge')[0].innerText)>99)?'99+':parseInt(document.getElementsByClassName('badge')[0].innerText) + 1);
            if (notificationArea) {
                notificationArea.innerText = data.notification.body;
                document.getElementsByClassName('badge')[0].innerText=String((parseInt(document.getElementsByClassName('badge')[0].innerText)>99)?'99+':parseInt(document.getElementsByClassName('badge')[0].innerText) + 1);
            }
        }
    }

    
}
