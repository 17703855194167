import React, { useEffect, useState } from 'react'
import { MULTI_TIME_ZONE } from '../../../config';
import { ConvertDate, multiTimeZoneHelper } from '../../../utils/utils';
import { differenceInDays, subDays, subMonths } from 'date-fns';
import { ErrorToast } from '../../../CustomHooks/ErrorToast';
import { DateRangePicker } from 'rsuite';

const TODAY = "TD";
const YESTERDAY = "YD";
const SEVENDAY = "7D";
const ONEMONTH = "1M";
const THREEMONTH = "3M";
const NONE = "NONE";

const FilterByDays = ({ fromDate, setFromDate, toDate, setToDate, visibleDays, visibleDateRangePicker }) => {
    const [activeTab, setActiveTab] = useState(TODAY);
    const [displayStartDate, setDisplayStartDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [inputDate, setInputDate] = useState([]);
    const [initialDate, setInitialDate] = useState([]);
    const [abbreviation, setAbbreviation] = useState("");
    const [count, setCount] = useState(0);






    useEffect(() => {
        if (fromDate === "" || toDate === "") {
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            let start = getFirstDate();
            let end = getSecondDate();
            let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
            setFromDate(startDate);
            let endCurrDate = ConvertDate(end);
            setToDate(endCurrDate)
            displayDateSetters(startDate, endCurrDate);
            setAbbreviation(loggedInUser.Abbreviation)
        } else {
            let loggedInUser = JSON.parse(localStorage.getItem('user'))
            setAbbreviation(loggedInUser.Abbreviation)
        }
        let tempDate = getFirstDate();
        console.log("TempDate", tempDate)
        let c = count + 1;
        setCount(c);
        setInitialDate([subMonths(tempDate, 1), tempDate]);

    }, []);

    const getFirstDate = () => {
        let d;
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        if (MULTI_TIME_ZONE) {
            d = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
        } else {
            d = new Date();
        }
        return d;
    }

    const getSecondDate = () => {
        let e;
        let loggedInUser = JSON.parse(localStorage.getItem('user'))
        if (MULTI_TIME_ZONE) {
            e = multiTimeZoneHelper(loggedInUser.OffsetMinutes)
        } else {
            e = new Date();
        }
        return e;
    }

    const displayDateSetters = (startDate, endCurrDate) => {
        let one = startDate.split(" ");
        let tempOne = `${one[0].split("-")[2]}-${one[0].split("-")[1]}-${one[0].split("-")[0]}`;
        setDisplayStartDate(`${tempOne} ${one[1]}`);
        let two = endCurrDate.split(" ");
        let tempTwo = `${two[0].split("-")[2]}-${two[0].split("-")[1]}-${two[0].split("-")[0]}`;
        setDisplayEndDate(`${tempTwo} ${two[1]}`);
    }

    const today = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(new Date(start.setHours(0, 0, 0, 0)));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setToDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        setActiveTab(TODAY)
    }

    const sevenDay = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 6));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setToDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        setActiveTab(SEVENDAY)
    }

    const yesterday = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subDays(new Date(start.setHours(0, 0, 0, 0)), 1));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(subDays(new Date(end.setHours(24, 0, 0, 0)), 1));
        setToDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        setActiveTab(YESTERDAY);
    }

    const oneMonth = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subMonths(new Date(start.setHours(0, 0, 0, 0)), 1));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setToDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);

        setActiveTab(ONEMONTH);
    }

    const threeMonth = () => {
        let start = getFirstDate();
        let end = getSecondDate();
        let startDate = ConvertDate(subMonths(new Date(start.setHours(0, 0, 0, 0)), 3));
        setFromDate(startDate);
        let endCurrDate = ConvertDate(end);
        setToDate(endCurrDate)
        setInputDate([])
        displayDateSetters(startDate, endCurrDate);
        setActiveTab(THREEMONTH);
    }

    const onHandleDateTimeOkClick = (e) => {
        const [startDate, endDate] = [e[0], e[1]];
        if (startDate && endDate) {
            const daysDifference = differenceInDays(endDate, startDate);
            if (daysDifference > 30) {
                ErrorToast('Maximum Date range cannot exceeded 30 days.');
                return;
            }
        }
        let sDate = ConvertDate(e[0]);
        setFromDate(sDate);
        let eDate = ConvertDate(e[1]);
        setToDate(eDate);
        setInputDate([e[0], e[1]])
        displayDateSetters(sDate, eDate);
        setActiveTab(NONE);
    }

    return (
        <>
            <div style={{ display: "grid", gridAutoFlow: "column", gridGap: "10px", alignItems: "center" }}>
                {
                    visibleDays &&
                    <>
                        <button onClick={() => today()} type="button" className={`${activeTab == TODAY ? "col btn btn-outline-primary active" : "col btn btn-outline-primary"}`}>TD</button>
                        <button onClick={() => yesterday()} type="button" className={`${activeTab == YESTERDAY ? "col btn btn-outline-primary active" : "col btn btn-outline-primary"}`}>YD</button>
                        <button onClick={() => sevenDay()} type="button" className={`${activeTab == SEVENDAY ? "col btn btn-outline-primary active" : "col btn btn-outline-primary"}`}>7D</button>
                        <button onClick={() => oneMonth()} type="button" className={`${activeTab == ONEMONTH ? "col btn btn-outline-primary active" : "col btn btn-outline-primary"}`}>30D</button>
                        {/* <button onClick={threeMonth} type="button" className={`${activeTab == THREEMONTH ? "btn btn-outline-primary active" : "btn btn-outline-primary"}`}>90D</button> */}
                    </>
                }
                {
                    visibleDateRangePicker &&
                    <DateRangePicker
                        // key={healthCount}
                        className="mx-2 my-2"
                        format="dd-MM-yyyy HH:mm:ss"
                        cleanable={false}
                        editable={false}
                        defaultCalendarValue={initialDate}
                        value={inputDate}
                        ranges={[]}
                        placeholder="Select date range"
                        placement='bottomEnd'
                        onOk={(e) => { onHandleDateTimeOkClick(e) }}
                        disabledDate={current => {
                            let d;
                            if (MULTI_TIME_ZONE) {
                                let loggedInUser = JSON.parse(localStorage.getItem('user'))
                                d = multiTimeZoneHelper(loggedInUser.OffsetMinutes);
                            } else {
                                d = new Date();
                            }
                            return current && current > d;
                        }}
                    />
                }
            </div>
        </>
    )
}

export default FilterByDays
