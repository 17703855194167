/**
 * Context API used for Auth related information and methods.
 * I am keeping everything related to the authentication and PrivateRoute in this one file.
 */
import React, { createContext, useContext } from 'react';
import { useProvideAuth } from './UseAuth';
const AuthContext = createContext();

// Custom hook to access auth related data and methods.
// Most important hook to be used throughout
export function useAuth() {
  return useContext(AuthContext);
}

// Context API used for Auth related information and methods.

// Context Provider to wrap the whole app within and make auth information available.
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      { !auth.loading && children}
    </AuthContext.Provider>
  );
}


